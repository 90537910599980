import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { PapperBlock } from 'dan-components';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalInformation from '../../Ortak/ModalInformation';
import Outer from '../../../Templates/Outer';
import Grid from '@material-ui/core/Grid';
import Decoration from '../../../Templates/Decoration';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import styles from '../../MainPage/welcome-jss';
import javikurye from 'dan-images/javikurye_white.png';
import Calendar from 'react-calendar';
import {
    createSelector
} from 'reselect';
import {
    setPaketYoldaCourierListVisible, setPaketYoldaSettingsVisible, modalClosePaketYolda, setPaketYoldaNewReportVisible,
    getPaketYoldaUserPIN, setPaketYoldaPinVisible, setPaketYoldaMainPageUpdate, getOrderGeneralReport, setSystemUserHubConnection
} from '../../../../redux/modules/paketyolda';
import { trMoneyConvert } from '../../../../function/GeneralFunction';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import CourierList from '../Couriers/CourierList';
import Report from '../Report';
import Settings from '../Settings'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import {
    BarChart,
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    CartesianAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
    PieChart, Pie, Cell,
    Sector,
    LabelList,
    Line
} from 'recharts';
import MileageCharge from '../Settings/MileageCharge';

function CustomTooltip({ payload, label, active }) {
    if (active && payload != null) {
        return (
            <div style={{ backgroundColor: '#313131', borderRadius: 5, padding: 10 }}>
                <div style={{ display: 'flex', width: 200 }}>
                    <div style={{ color: 'white', width: '50%', textAlign: 'left' }}>{`Saat`}</div>
                    <div style={{ color: 'white', width: '50%', textAlign: 'right' }}>{`${label}`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ color: 'white', width: '50%', textAlign: 'left' }}>{`Tutarı`}</div>
                    <div style={{ color: 'white', width: '50%', textAlign: 'right' }}>{`${trMoneyConvert(payload[0].payload.TotalPrice,2)}`}</div>
                </div>
            </div>
        );
    }

    return null;
}

class PaketYoldaMainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { PIN: "" };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.IsPaketYoldaMainPageUpdate == this.props.IsPaketYoldaMainPageUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        if (this.props.systemUserHubConnection == null) {
            await this.props.setSystemUserHubConnection().then(() => { });
          }
        await this.handleFilterGeneral();
    }

    renderDataHour() {
        const { paketYoldaGeneralReportData } = this.props;

        var dataArray = [];
        paketYoldaGeneralReportData.HourDatas.map((n, i) => {
            dataArray.push({ Hour: n.Hour, TotalPrice: n.TotalPrice });
        })
        return dataArray;
    }

    renderColorfulLegendText(value, entry) {
        const { color } = entry;

        return <span style={{ color: 'white', fontSize: 12 }}>{value}</span>;
    }

    handleCloseApp = () => {
        cookie.remove('PaketYoldaId', { path: '/' });
        cookie.save('PaketYoldaLogin', false, { path: '/' });
        cookie.remove('PaketYoldaCenterId', { path: '/' });
        cookie.remove('PaketYoldaUsername', { path: '/' });
        cookie.remove('PaketYoldaUserId', { path: '/' });
        cookie.remove('Password', { path: '/' });
        reactLocalStorage.remove('py_string');

        if (cookie.load("IsRemember") === "false") {
            cookie.remove('PaketYoldaUsername', { path: '/' });
            cookie.remove('Password', { path: '/' });
        }
        this.props.history.push('/javikurye');
    };

    modalClose = () => {
        this.props.modalClosePaketYolda().then(() => { })
    };

    handleFocus = (event) => event.target.select();

    handleEventData = event => {
        this.setState({ PIN: event.target.value });
        this.props.setPaketYoldaMainPageUpdate().then(() => { })
    };

    renderPIN() {
        const { classes } = this.props;

        return (
            <Dialog
                open={this.props.paketYoldaPINLogin}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"PIN Giriş İşlemleri"}
                    <Typography className={classes.instructions} style={{ fontSize: 13, fontWeight: 500, color: 'white' }}>
                        {"Kritik İşlemler için yönetici PIN girişi yapmalısınız!"}
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={1} style={{ padding: '0 24px 24px', width: '100%' }}>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{"* PIN Kodu"}</InputLabel>
                                    <Input placeholder='1-9999 arasında değer giriniz' onFocus={this.handleFocus} type={"password"} value={this.state.PIN} onChange={this.handleEventData} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPaketYoldaPinVisible(false, "").then(() => { this.setState({ PIN: "" }) })} color="primary">
                        {"İPTAL"}
                    </Button>
                    <Button variant="contained" onClick={() => this.props.getPaketYoldaUserPIN(this.state.PIN, this.props.paketYoldaPINLoginType).then(() => { this.setState({ PIN: "" }) })} color="secondary">
                        {"GİRİŞ YAP"}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    async handleFilterGeneral() {
        const { paketYoldaGeneralReportfilter } = this.props;
        paketYoldaGeneralReportfilter.IsDay = true;
        await this.props.getOrderGeneralReport().then(() => { });
    }

    render() {
        const {
            classes,
            modalOpen,
            modalText,
            modalTitle,
            paketYoldaGeneralReportData
        } = this.props;

        return (
            <Outer leaf={true} style={{ position: 'relative' }}>
                {this.renderPIN()}
                <CourierList classes={classes} />
                <MileageCharge classes={classes} />
                <Report classes={classes} />
                {modalOpen ? <ModalInformation event={this.modalClose} open={modalOpen} title={modalTitle} text={modalText} /> : null}
                <Settings classes={classes} />
                <span style={{ display: 'flex', alignItems: 'center', color: 'white', fontSize: 16, fontWeight: 600, position: 'absolute', right: '3%', top: '2%' }}>
                    {cookie.load('PaketYoldaUsername')}
                    <Icon style={{ marginLeft: 10, fontSize: 30 }}>{"account_circle"}</Icon>
                </span>
                <Grid spacing={4} container style={{ marginTop: 25, height: '93vh', zIndex: 99, width: '95%' }}>
                    <Grid item md={8}>
                        <div style={{ marginBottom: 8, display: 'flex', width: '100%', height: 'calc(25% - 4px)' }}>
                            <div style={{ marginRight: 4, borderRadius: 10, position: 'relative', backgroundColor: '#424242', height: '100%', width: '50%' }}>
                                <Icon style={{ color: 'white', fontSize: 35, position: 'absolute', left: 15, top: 10 }}>{"sports_motorsports"}</Icon>
                                <div style={{ color: 'white', fontSize: 20, position: 'absolute', left: 55, top: 12 }}>
                                    {"Kurye"}
                                </div>
                                <div style={{ textAlign: 'end', position: 'absolute', right: 10, bottom: 10, display: 'grid' }}>
                                    <span style={{ fontSize: 20, color: 'white' }}>{"Dağıtımda : "}{paketYoldaGeneralReportData.C_Delivery}</span>
                                    <span style={{ fontSize: 20, color: 'white' }}>{"Molada : "}{paketYoldaGeneralReportData.C_OnBreak}</span>
                                    <span style={{ fontSize: 20, color: 'white' }}>{"Boşta : "}{paketYoldaGeneralReportData.C_Idle}</span>
                                </div>
                            </div>
                            <div style={{ marginRight: 4, borderRadius: 10, position: 'relative', backgroundColor: '#424242', height: '100%', width: '50%' }}>
                                <Icon style={{ color: 'white', fontSize: 35, position: 'absolute', left: 15, top: 10 }}>{"storefront"}</Icon>
                                <div style={{ color: 'white', fontSize: 20, position: 'absolute', left: 55, top: 12 }}>
                                    {"Adisyon"}
                                </div>
                                <div style={{ textAlign: 'end', position: 'absolute', right: 10, bottom: 10, display: 'grid' }}>
                                    <span style={{ fontSize: 20, color: 'white' }}>{"Bekleyen : "}{paketYoldaGeneralReportData.P_Waiting}</span>
                                    <span style={{ fontSize: 20, color: 'white' }}>{"Atamada : "}{paketYoldaGeneralReportData.P_Assignment}</span>
                                    <span style={{ fontSize: 20, color: 'white' }}>{"Dağıtımda : "}{paketYoldaGeneralReportData.P_Delivery}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginBottom: 8, display: 'flex', width: '100%', height: 'calc(15% - 4px)' }}>
                            <div style={{ marginRight: 4, borderRadius: 10, position: 'relative', backgroundColor: '#683E43', height: '100%', width: '33.33%' }}>
                                <div style={{ color: 'white', fontSize: 15, position: 'absolute', left: 15, top: 10, fontWeight:500 }}>
                                    {"Teslim Edilen Paket"}
                                </div>
                                <div style={{ textAlign: 'end', position: 'absolute', right: 10, bottom: 5, display: 'grid' }}>
                                    <span style={{ fontSize: 20, color: 'white' , fontWeight:600}}>{paketYoldaGeneralReportData.OrderCount}</span>
                                </div>
                            </div>
                            <div style={{ marginRight: 4, borderRadius: 10, position: 'relative', backgroundColor: '#344B71', height: '100%', width: '33.33%' }}>
                                <div style={{ color: 'white', fontSize: 15, position: 'absolute', left: 15, top: 10, fontWeight:500 }}>
                                    {"Ortalama Paket Tutarı"}
                                </div>
                                <div style={{ textAlign: 'end', position: 'absolute', right: 10, bottom: 5, display: 'grid' }}>
                                    <span style={{ fontSize: 20, color: 'white', fontWeight:600 }}>{trMoneyConvert(paketYoldaGeneralReportData.AvgAdditionPrice,2)}</span>
                                </div>
                            </div>
                            <div style={{ marginRight: 4, borderRadius: 10, position: 'relative', backgroundColor: '#2F4858', height: '100%', width: '33.33%' }}>
                                <div style={{ color: 'white', fontSize: 15, position: 'absolute', left: 15, top: 10, fontWeight:500 }}>
                                    {"Toplam Paket Tutarı"}
                                </div>
                                <div style={{ textAlign: 'end', position: 'absolute', right: 10, bottom: 5, display: 'grid' }}>
                                    <span style={{ fontSize: 20, color: 'white', fontWeight:600 }}>{trMoneyConvert(paketYoldaGeneralReportData.TotalPrice,2)}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: 'relative', marginTop: 8, borderRadius: 10, backgroundColor: '#424242', display: 'block', width: '100%', height: 'calc(60% - 4px)' }}>
                            <div style={{ color: 'white', padding: 5, display: 'flex', justifyContent: 'center' }}>{"Gün Bazında Saatlik Sipariş Grafiği"}</div>
                            <ResponsiveContainer>
                                <ComposedChart
                                    data={this.renderDataHour()}
                                    margin={{
                                        top: 15,
                                        right: 15, left: -20,
                                        bottom: 40
                                    }}
                                >
                                    <defs>
                                        <linearGradient id="colorUp" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor={"#fa8231"} stopOpacity={1} />
                                            <stop offset="95%" stopColor={"#fa8231"} stopOpacity={1} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis interval={0} angle={-45} textAnchor="end" dataKey="Hour" tickLine={false} label={{ size: 12 }} />
                                    <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
                                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                                    <CartesianAxis vertical={false} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend verticalAlign={"bottom"} formatter={this.renderColorfulLegendText} />
                                    <Bar stackId="a" name="Toplam Adisyon Tutarı" dataKey="TotalPrice" fillOpacity="1" fill="url(#colorUp)" />
                                </ComposedChart>
                            </ResponsiveContainer>
                            <div style={{ position: 'absolute', left: 10, bottom: 5 }}>
                                <IconButton style={{ backgroundColor: '#3e3e3e', borderRadius: 5, width: 35, height: 35 }} onClick={() => this.handleFilterGeneral()}>
                                    <Icon style={{ color: 'white' }}>{"refresh"}</Icon>
                                </IconButton>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4}>
                        <div style={{ display: 'block', width: '100%', height: '100%' }}>
                            <div style={{ height: '33.33%', width: '100%', paddingBottom: 8 }}>
                                <Button fullWidth onClick={() => this.props.history.push('/paketyoldapanel')} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#BE1D2D', color: 'white', borderRadius: 0, textTransform: 'capitalize' }}>
                                    <img style={{ height: 50 }} src={javikurye} />
                                    <Typography style={{ position: 'absolute', bottom: 5, left: 10, fontSize: 23, fontWeight:600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Operasyon Ekranı"}</Typography>
                                </Button>
                            </div>
                            <div style={{ height: '33.33%', width: '100%', display: 'flex', paddingBottom: 8 }}>
                                <div style={{ height: '100%', width: '50%', paddingRight: 4 }}>
                                    <Button fullWidth onClick={() => this.props.setPaketYoldaPinVisible(true, "report")} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#424242', color: 'white', borderRadius: 0, textTransform: 'capitalize' }}>
                                        <Icon style={{ fontSize: 45 }}>{"insights"}</Icon>
                                        <Typography style={{ position: 'absolute', bottom: 5, left: 10, fontSize: 16, fontWeight:600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Raporlar"}</Typography>
                                    </Button>
                                </div>
                                <div style={{ height: '100%', width: '50%', paddingLeft: 4 }}>
                                    <Button fullWidth onClick={() => this.props.setPaketYoldaPinVisible(true, "settings").then()} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#424242', color: 'white', borderRadius: 0, textTransform: 'capitalize' }}>
                                        <Icon style={{ fontSize: 45 }}>{"settings"}</Icon>
                                        <Typography style={{ position: 'absolute', bottom: 5, left: 10, fontSize: 16, fontWeight:600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Ayarlar"}</Typography>
                                    </Button>
                                </div>
                            </div>
                            <div style={{ height: '33.33%', width: '100%', display: 'flex' }}>
                                <div style={{ height: '100%', width: '50%', paddingRight: 4 }}>
                                    <Button fullWidth onClick={() => this.props.setPaketYoldaPinVisible(true, "courier").then()} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#424242', color: 'white', borderRadius: 0, textTransform: 'capitalize' }}>
                                        <Icon style={{ fontSize: 45 }}>{"two_wheeler"}</Icon>
                                        <Typography style={{ position: 'absolute', bottom: 5, left: 10, fontSize: 16 , fontWeight:600}} className={classes.buttonMenuTitle} variant="subtitle1">{"Kurye İşlemleri"}</Typography>
                                    </Button>
                                </div>
                                <div style={{ height: '100%', width: '50%', paddingLeft: 4 }}>
                                    <Button fullWidth onClick={this.handleCloseApp} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#424242', color: 'white', borderRadius: 0, textTransform: 'capitalize' }}>
                                        <Icon style={{ fontSize: 45 }}>{"logout"}</Icon>
                                        <Typography style={{ position: 'absolute', bottom: 5, left: 10, fontSize: 16, fontWeight:600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Çıkış"}</Typography>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </Outer >
        );
    }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').IsPaketYoldaMainPageUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    IsPaketYoldaMainPageUpdate: value(state, props),
    modalOpen: state.get('paketYoldaReducer').form_data.modalOpen,
    modalTitle: state.get('paketYoldaReducer').form_data.modalTitle,
    modalText: state.get('paketYoldaReducer').form_data.modalText,

    paketYoldaPINLoginType: state.get('paketYoldaReducer').paketYoldaPINLoginType,
    paketYoldaPINLogin: state.get('paketYoldaReducer').paketYoldaPINLogin,
    paketYoldaGeneralReportData: state.get('paketYoldaReducer').paketYoldaGeneralReportData,
    paketYoldaGeneralReportfilter: state.get('paketYoldaReducer').paketYoldaGeneralReportfilter,

    systemUserHubConnection: state.get('paketYoldaReducer').systemUserHubConnection
})

const mapDispatchToProps = {
    setPaketYoldaCourierListVisible,
    setPaketYoldaSettingsVisible,
    setPaketYoldaNewReportVisible,
    modalClosePaketYolda,
    getPaketYoldaUserPIN,
    setPaketYoldaPinVisible,
    setPaketYoldaMainPageUpdate,
    getOrderGeneralReport,
    setSystemUserHubConnection
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(PaketYoldaMainPage)));
