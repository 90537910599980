import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form/immutable';
import { Checkbox, TextField } from 'redux-form-material-ui';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AllInclusive from '@material-ui/icons/AllInclusive';
import Brightness5 from '@material-ui/icons/Brightness5';
import People from '@material-ui/icons/People';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import brand from 'dan-api/dummy/brand';
import logo from 'dan-images/logo.png';
import tr from 'dan-images/lang/tr-TR.png';
import en from 'dan-images/lang/en-EN.png';
import styles from './user-jss';
import { ContentDivider } from '../Divider';

// validation functions
const required = value => (value == null ? 'Required' : undefined);
const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email'
    : undefined
);

class LoginForm extends React.Component {
  state = {
    showPassword: false
  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      deco,
    } = this.props;
    const { showPassword } = this.state;
    return (
      <Paper className={classNames(classes.sideWrap, deco && classes.petal)}>
        <div className={classes.topBar}>
          <NavLink to="/" className={classes.brand}>
            <img src={logo} alt={brand.name} />
            {brand.name}
          </NavLink>
          <Button size="small" className={classes.buttonLink} component={NavLink} to="/register">
            <Icon className={classes.icon}>arrow_forward</Icon>
            Yeni Kullanıcı
          </Button>
        </div>
        <Typography variant="h4" className={classes.title} gutterBottom>
          Hoş Geldiniz
        </Typography>
        <Typography variant="caption" className={classes.subtitle} gutterBottom align="center">
          Bulut Restoran Yönetimi Çözümü
        </Typography>
        <div className={classes.socmedSideLogin} style={{ flexDirection:'row', padding: '10px 24px 1px' }}>
        <Button size="small" className={classes.buttonLink} component={NavLink} to="/register">
          <img src={tr} style={{ height:35 }} alt={brand.name} />
        </Button>
        <Button size="small" className={classes.buttonLink} component={NavLink} to="/register">
          <img src={en} style={{ height:35 }} alt={brand.name} />
        </Button>
        </div>
        <section className={classes.socmedSideLogin} style={{ padding: '0px 24px 1px' }}>
          <ContentDivider content="Kullanıcı Girişi" />
        </section>
        <section className={classes.pageFormSideWrap}>
          <form onSubmit={handleSubmit}>
            <div>
              <FormControl className={classes.formControl}>
                <Field
                  name="email"
                  component={TextField}
                  placeholder="Kullanıcı Adı"
                  label="Kullanıcı Adı"
                  required
                  validate={[required, email]}
                  className={classes.field}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <Field
                  name="password"
                  component={TextField}
                  type={showPassword ? 'text' : 'password'}
                  label="Şifre"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  required
                  validate={required}
                  className={classes.field}
                />
              </FormControl>
            </div>
            <div className={classes.optArea}>
              <FormControlLabel className={classes.label} control={<Field name="checkbox" component={Checkbox} />} label="Beni Hatırla" />
              <Button size="small" component={NavLink} to="/reset-password" className={classes.buttonLink}>Şifremi Unuttum</Button>
            </div>
            <div className={classes.btnArea}>
              <Button variant="contained" fullWidth color="primary" size="large" type="submit">
                Devam
                <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
              </Button>
            </div>
          </form>
        </section>
      </Paper>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
};

const LoginFormReduxed = reduxForm({
  form: 'immutableExample',
  enableReinitialize: true,
})(LoginForm);

export default withStyles(styles)(LoginFormReduxed);
