import React from 'react';
import {
  createSelector
} from 'reselect';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../datepicker.css';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import tr from 'date-fns/locale/tr';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { EmptyData } from 'dan-components';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TbPaginationActions from '../../Ortak/TbPaginationActions';
import AlertDialog from '../../Ortak/AlertDialog';
import { getParameter } from '../../../../function/GeneralFunction';
import CustomerAccount from '../Customer/CustomerAccount';
import MapClusterReport from './Maps/MapClusterReport';
import 'leaflet/dist/leaflet.css';
import cookie from 'react-cookies';
import { getCourrierList } from '../../../../redux/modules/addition';
import { RequestPaketYoldaReport, setPaketYoldaFilter } from '../../../../redux/modules/report';
registerLocale('tr', tr);

const ClickableInput = ({
  startDate, endDate, onClick, ...props
}) => (
  <Button
    fullWidth
    style={{
      borderRadius: 5, border: '1px solid rgba(255,255,255,0.32)', color: 'white', minHeight: 40
    }}
    variant="outlined"
    color="primary"
    {...props}
  >
    {(startDate == undefined ? '00.00.0000' : moment(startDate).format('DD.MM.YYYY')) + ' - ' + (endDate == undefined ? '00.00.0000' : moment(endDate).format('DD.MM.YYYY'))}
  </Button>
);

class PaketYoldaReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionComplete: false, toggleSelectionComplete: false, startDate: new Date(new Date().setHours(0, 0, 0, 0)), endDate: new Date(new Date().setHours(23, 59, 59, 999))
    };
  }

  async componentDidMount() {
    const TimeData = getParameter('BusinessWorkingHours');
    await this.setState({ startDate: undefined, endDate: undefined });
    const response = await this.props.getCourrierList().then(() => { });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if (nextProps.paketYoldaUpdate == this.props.paketYoldaUpdate) {
    // return false;
    // }
    return true;
  }

  handleDateChange = date => {
    if (!this.state.selectionComplete && !this.state.startDate) {
      // on first date selection, set the start date

      this.setState({ startDate: date });
      return;
    }

    if (!this.state.selectionComplete && this.state.startDate && !this.state.endDate) {
      // on second date selection, set the end date

      this.setState({ endDate: date, toggleSelectionComplete: true });

      // do stuff with date range

      return;
    }

    if (this.state.selectionComplete && this.state.startDate && this.state.endDate) {
      // on third date selection, begin selection of a new date range
      // reset the start date and clear the end date.

      this.setState({ startDate: date, endDate: undefined, toggleSelectionComplete: false });
    }
  };

  handleSelect = date => {
    // onChange is not fired if selecting same date - workaround to fire handleDateChange
    if (!this.state.selectionComplete && this.state.startDate && !this.state.endDate && this.sameDay(date, this.state.startDate)) {
      this.handleDateChange(date);
    }
  };

  sameDay = (d1, d2) => d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();

  handleFilter = async () => {
    const { paketYoldaFilter } = this.props;
    const { startDate, endDate } = this.state;
    paketYoldaFilter.startDate = startDate;
    paketYoldaFilter.endDate = endDate;
    await this.props.RequestPaketYoldaReport(paketYoldaFilter).then(() => { });
  };

  handleChangePaketYolda = name => event => {
    this.props.paketYoldaFilter[name] = event.target.value;
    this.props.setPaketYoldaFilter(this.props.paketYoldaFilter).then(() => { });
  };

  render() {
    const {
      classes, paketYoldaData, paketYoldaFilter, loading
    } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <Paper
        className={classes.root}
        elevation={0}
        style={{
          height: '100vh', borderRadius: 0, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 10
        }}
      >
        <Toolbar style={{
          marginBottom: 5, minHeight: 48, width: '100%', display: 'flex'
        }}
        >
          <Grid style={{ alignItems: 'center' }} container spacing={1}>
            <Grid item md={3}>
              <Typography
                style={{
                  marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                }}
                className={classes.flex}
              >
                {"Tarih Aralığı"}
              </Typography>
              <DatePicker
                selected={startDate}
                customInput={<ClickableInput startDate={startDate} endDate={endDate} />}
                onChange={this.handleDateChange}
                onSelect={this.handleSelect}
                selectsEnd={Boolean(startDate)}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd.MM.yyyy HH:mm:ss"
                selectsRange
                showTimeInput={false}
                withPortal
                shouldCloseOnSelect={false}
                locale="tr"
              >
                <div>
                  <Button fullWidth onClick={() => this.setState({ startDate: undefined, endDate: undefined })} style={{ backgroundColor: '#e74c3c', borderRadius: 3, textTransform: 'capitalize' }} variant="contained" color="primary">
                    {'Tarihi Sil'}
                  </Button>
                </div>
              </DatePicker>
            </Grid>
            <Grid item md={2}>
              <Typography
                style={{
                  marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                }}
                className={classes.flex}
              >
                Sipariş Kanalı
              </Typography>
              <Select
                fullWidth
                value={paketYoldaFilter.TableType}
                onChange={this.handleChangePaketYolda('TableType')}
                input={<Input name="smsTitle" id="age-helper" />}
              >
                <MenuItem value="All">Tümü</MenuItem>
                <MenuItem value="GetirYemek">GetirYemek</MenuItem>
                <MenuItem value="YemekSepetiGlobal">YemekSepeti</MenuItem>
                <MenuItem value="MigrosYemek">MigrosYemek</MenuItem>
                <MenuItem value="TrendyolYemek">TrendyolYemek</MenuItem>
                <MenuItem value="GoFody">GoFody</MenuItem>
                <MenuItem value="Call Center Paket">Call Center Paket</MenuItem>
                <MenuItem value="Paket">Telefon Paket</MenuItem>
                <MenuItem value="Paket Satış">İç Paket</MenuItem>
              </Select>
            </Grid>
            <Grid item md={2}>
              <Typography
                style={{
                  marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                }}
                className={classes.flex}
              >
                Kurye Seçimi
              </Typography>
              <Select
                fullWidth
                value={paketYoldaFilter.PackageUserId}
                onChange={this.handleChangePaketYolda('PackageUserId')}
                input={<Input name="smsTitle" id="age-helper" />}
              >
                <MenuItem value="00000000-0000-0000-0000-000000000000">Tümü</MenuItem>
                {
                  this.props.CourrierList.map((n, i) => <MenuItem value={n.Id}>{n.Name}</MenuItem>)
                }
              </Select>
            </Grid>
            <Grid item md={2} />
            <Grid item md={2} />
            <Grid item md={1}>
              <Button disabled={loading} fullWidth onClick={this.handleFilter} style={{ borderRadius: 3 }} variant="contained" color="primary">
                {'LİSTELE'}
                {(this.props.paketYoldaLoading ? <CircularProgress style={{ color: 'white', marginLeft: 10 }} disableShrink thickness={3} size={15} /> : null)}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        <MapClusterReport center={[parseFloat(cookie.load('Lat')), parseFloat(cookie.load('Lng'))]} markersData={paketYoldaData} />
      </Paper>
    );
  }
}

const valueSelector = (state, props) => state.get('reportReducer').paketYoldaUpdate;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  paketYoldaUpdate: value(state, props),
  paketYoldaData: state.get('reportReducer').paketYoldaData,
  paketYoldaLoading: state.get('reportReducer').paketYoldaLoading,
  CourrierList: state.get('additionReducer').form_data.CourrierList,
  paketYoldaFilter: state.get('reportReducer').paketYoldaFilter
});

const mapDispatchToProps = {
  RequestPaketYoldaReport,
  setPaketYoldaFilter,
  getCourrierList
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaketYoldaReport));
