import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../../CenterBrandPage/centerbrandpage-jss';
import {
    setPaketYoldaBusinessListVisible, setBusinessIsAutomaticSubmission, setPaketYoldaBrandRemove,
    setAutoComplatedValue,
    getBrandList,
    setAutoComplatedSelected,
    setPaketYoldaAutoSend,
    setIsPaketYoldaBrand
} from '../../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Autocomplete from 'react-autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


class BusinessList extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaBusinessListUpdate == this.props.paketYoldaBusinessListUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() { }

    handleChangeAutoSend = name => event => {
        this.props.setPaketYoldaAutoSend(event.target.checked).then(() => { })
    };

    render() {
        const { IsAutomaticSubmission, autoComplatedBrandList, autoComplatedValue, classes, paketYoldaBusinessListVisible, paketYoldaBusinessList } = this.props;

        return (
            <Dialog
                open={paketYoldaBusinessListVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'lg'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"JaviKurye Merkez İşlemleri"}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto', height: '90vh' }}>
                    <Grid container spacing={1}>
                        <Grid item md={7}>
                            <Autocomplete
                                value={autoComplatedValue}
                                inputProps={{ style: { height: 38, fontSize: 12, width: '100%', borderRadius: 0 }, id: 'states-autocomplete', placeholder: 'İşletme Arama' }}
                                wrapperStyle={{ position: 'relative', width: '100%' }}
                                items={autoComplatedBrandList}
                                getItemValue={item => item.Name}
                                onChange={(event, value) => this.props.setAutoComplatedValue(value).then(() => {
                                    this.props.getBrandList(value).then(() => { })
                                })}
                                onSelect={(value, item) => {
                                    this.props.setAutoComplatedSelected(item).then(() => { })
                                }}
                                renderMenu={children => (
                                    <div className="menu">
                                        {children}
                                    </div>
                                )}
                                renderItem={(item, isHighlighted) => (
                                    <div
                                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                        key={item.Id} >
                                        {item.Name}
                                    </div>
                                )}
                            />
                        </Grid>
                        <Grid item md={3} style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={IsAutomaticSubmission}
                                        onChange={this.handleChangeAutoSend("IsScales")}
                                    />
                                )}
                                label={"Otomatik Gönderim"}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <Button fullWidth style={{ marginTop: 2, borderRadius: 0 }} variant="contained" onClick={() => this.props.setIsPaketYoldaBrand().then(() => { })} color="secondary">
                                {"Ekle"}
                            </Button>
                        </Grid>
                    </Grid>
                    <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{'Sıra'}</TableCell>
                                <TableCell>{'İşletme Adı'}</TableCell>
                                <TableCell>{'Otomatik Gönderim'}</TableCell>
                                <TableCell align={"right"}>{'İşlemler'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paketYoldaBusinessList.map((n, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{n.BusinessName}</TableCell>
                                        <TableCell>
                                            <IconButton style={{ color: (n.IsAutomaticSubmission ? '#1abc9c' : '') }} onClick={() => this.props.setBusinessIsAutomaticSubmission(n.BrandId, n.PaketYoldaId, !n.IsAutomaticSubmission).then(() => { })}>
                                                <Icon>{(n.IsAutomaticSubmission ? 'check_box' : 'check_box_outline_blank')}</Icon>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Tooltip title={"Sil"}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                    onClick={() => (confirm('İşletmeyi silmek istediğinize emin misiniz?') ? this.props.setPaketYoldaBrandRemove(n.BrandId, n.PaketYoldaId).then(() => { }) : null)}>
                                                    <Icon>{"delete"}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPaketYoldaBusinessListVisible(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

BusinessList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaBusinessListUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaBusinessListUpdate: value(state, props),
    paketYoldaBusinessListLoading: state.get('paketYoldaReducer').paketYoldaBusinessListLoading,
    paketYoldaBusinessListVisible: state.get('paketYoldaReducer').paketYoldaBusinessListVisible,
    paketYoldaBusinessList: state.get('paketYoldaReducer').paketYoldaBusinessList,

    autoComplatedBrandList: state.get('paketYoldaReducer').autoComplatedBrandList,
    autoComplatedValue: state.get('paketYoldaReducer').autoComplatedValue,
    autoComplatedSelected: state.get('paketYoldaReducer').autoComplatedSelected,
    PaketYoldaId: state.get('paketYoldaReducer').PaketYoldaId,
    IsAutomaticSubmission: state.get('paketYoldaReducer').IsAutomaticSubmission
});

const mapDispatchToProps = {
    setPaketYoldaBusinessListVisible,
    setBusinessIsAutomaticSubmission,
    setPaketYoldaBrandRemove,

    setAutoComplatedValue,
    getBrandList,
    setAutoComplatedSelected,
    setPaketYoldaAutoSend,
    setIsPaketYoldaBrand
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(BusinessList)));
