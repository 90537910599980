import React from 'react';
import {
  createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Divider from '@material-ui/core/Divider';
import MaskedInput from 'react-text-mask';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DatePicker from 'react-datepicker';
import { getParameter, CapitalizeText } from '../../../../function/GeneralFunction';
import {
  setCustomerAddVisible, getNewCustomer, setCustomerAddUpdate, setCustomer, setBizimHesapInvoice
} from '../../../../redux/modules/customer';
import styles from '../../Settings/settings-jss';
import '../datepicker.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="₺"
      autocomplete="off"
    />
  );
}

class CustomerAdd extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFocus = (event) => event.target.select();

  handleSubmit(event) {
    event.preventDefault();
    this.props.setCustomer().then(() => { });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  handleChange = name => event => {
    const caret = event.target.selectionStart;
    const element = event.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    })

    if (event.target.value.length > 11 && name == "TaxNumber") {
      return
    }
    
    this.props.customerAdd[name] = (name == "Name" ? CapitalizeText(event.target.value) : event.target.value);
    this.props.setCustomerAddUpdate().then(() => { });
  };

  render() {
    const { classes, customerAdd, filterData } = this.props;

    if (customerAdd == null) {
      return null;
    }

    return (
      <Dialog
        open={this.props.customerAddVisible}
        fullWidth
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form style={{ display: 'contents' }} onSubmit={this.handleSubmit}>
          <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
            {(filterData.CustomerType == 0 ? "Müşteri" : (filterData.CustomerType == 4 ? "Personel" : (filterData.CustomerType == 1 ? "Tedarikçi" : "")))}{' İşlemleri'}
          </DialogTitle>
          <DialogContent style={{ zIndex: 1, marginTop: 20 }}>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <RadioGroup
                  aria-label="radio"
                  name="radio"
                  className={classes.group}
                  style={{ pointerEvents: 'none', display: 'inline', width: '100%', marginLeft: 10 }}
                  defaultValue={customerAdd.CustomerType}
                  onChange={this.handleChange('CustomerType')}
                >
                  <FormControlLabel disabled={true} value={"0"} control={<Radio />} label={"Müşteri"} />
                  <FormControlLabel disabled={true} value={"1"} control={<Radio />} label={"Tedarikçi"} />
                  <FormControlLabel disabled={true} value={"4"} control={<Radio />} label={"Personel"} />
                </RadioGroup>
              </Grid>
              <Grid item md={12}>
                <FormControl className={classes.formControl} style={{ margin: 0 }}>
                  <Typography
                    style={{
                      marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                    }}
                    className={classes.flex}
                  >
                    {"Adı Soyadı"}
                  </Typography>
                  <Input
                    value={customerAdd.Name}
                    onChange={this.handleChange('Name')}
                    id="Name"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl className={classes.formControl} style={{ margin: 0 }}>
                  <Typography
                    style={{
                      marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                    }}
                    className={classes.flex}
                  >
                    {"Telefon"}
                  </Typography>
                  <Input
                    value={customerAdd.Phone}
                    onChange={this.handleChange('Phone')}
                    id="Phone"
                    inputComponent={TextMaskCustom}
                    onFocus={this.handleFocus}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl className={classes.formControl} style={{ margin: 0 }}>
                  <Typography
                    style={{
                      marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                    }}
                    className={classes.flex}
                  >
                    {"Email"}
                  </Typography>
                  <Input
                    value={customerAdd.Email}
                    onChange={this.handleChange('Email')}
                    id="Email"
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl className={classes.formControl} style={{ margin: 0 }}>
                  <Typography
                    style={{
                      marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                    }}
                    className={classes.flex}
                  >
                    {"Vergi Dairesi"}
                  </Typography>
                  <Input
                    value={customerAdd.TaxAdministration}
                    onChange={this.handleChange('TaxAdministration')}
                    id="TaxAdministration"
                    onFocus={this.handleFocus}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl className={classes.formControl} style={{ margin: 0 }}>
                  <Typography
                    style={{
                      marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                    }}
                    className={classes.flex}
                  >
                    {"Vergi Numarası"}
                  </Typography>
                  <Input
                    value={customerAdd.TaxNumber}
                    onChange={this.handleChange('TaxNumber')}
                    id="TaxNumber"
                    onFocus={this.handleFocus}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl className={classes.formControl} style={{ margin: 0 }}>
                  <Typography
                    style={{
                      marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                    }}
                    className={classes.flex}
                  >
                    {"İndirim Oranı"}
                  </Typography>
                  {' '}
                  <Input
                    value={customerAdd.DiscountRate}
                    id="DiscountRate"
                    onFocus={this.handleFocus}
                    onChange={this.handleChange('DiscountRate')}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 10 }} />
          </DialogContent>
          <DialogActions>
            <Button disabled={(customerAdd.OrderId == "00000000-0000-0000-0000-000000000000" ? true : false)} style={{ marginRight: 5 }} onClick={() => this.props.setBizimHesapInvoice(customerAdd.OrderId).then(() => { })} color="default" variant="contained" autoFocus>
              {"BİZİMHESAP'A GÖNDER"}
            </Button>
            <Button style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }} color="primary" autoFocus onClick={() => this.props.setCustomerAddVisible(false).then(() => { })}>
              {'ÇIKIŞ'}
            </Button>
            <Button disabled={this.props.customerAddStatus} type="submit" style={{ marginRight: 5 }} color="secondary" variant="contained" autoFocus>
              {'KAYDET'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const valueSelector = (state, props) => state.get('customerReducer').customerAddUpdate;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  customerAddUpdate: value(state, props),
  customerAddVisible: state.get('customerReducer').customerAddVisible,
  customerAdd: state.get('customerReducer').customerAdd,
  filterData: state.get('customerReducer').filterData,
  customerAddStatus: state.get('customerReducer').customerAddStatus,
  filterData: state.get('customerReducer').filterData
});

const mapDispatchToProps = {
  setCustomerAddVisible,
  getNewCustomer,
  setCustomerAddUpdate,
  setCustomer,
  setBizimHesapInvoice
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(CustomerAdd)));
