import React from "react";
import L from "leaflet";
import './stylesPopup.css';

const style = {
    width: "100%",
    height: "100%",
    borderRadius: 0
};

class PaketYoldaMap extends React.Component {
    componentDidMount() {
        // create map
        this.map = L.map("map", {
            center: [38.6389114, 35.691706],
            zoom: 6,
            layers: [
                L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution:
                        ''
                })
            ]
        });

        this.layer = L.layerGroup().addTo(this.map);
        this.updateCourierMarkers(this.props.courier);
        this.updateOrderMarkers(this.props.order, this.props.BrandList);
    }


    componentDidUpdate({ IsUpdateCourier, IsUpdateOrder, PolygonJson, fullScreen }) {
        if (this.props.IsUpdateCourier !== IsUpdateCourier) {
            this.updateCourierMarkers(this.props.courier);
        }
        if (this.props.IsUpdateOrder !== IsUpdateOrder) {
            this.updateOrderMarkers(this.props.order, this.props.BrandList);
        }
        if (this.props.PolygonJson !== PolygonJson) {
            this.renderObjectOnMap(this.props.PolygonJson)
        }
        if (this.props.fullScreen !== fullScreen) {
            this.map.invalidateSize()
        }
    }

    renderObjectOnMap = (obj) => {
        if (obj == null) {
            this.map.eachLayer(function (layerData) {
                if (layerData.myTag === "myGeoJSON") {
                    layerData.remove();
                }
            });
        } else {
            this.map.eachLayer(function (layerData) {
                if (layerData.myTag === "myGeoJSON") {
                    layerData.remove();
                }
            });

            obj.map((n, i) => {
                var geoJsonObject = JSON.parse(n.PolygonJson);

                if (Array.isArray(geoJsonObject)) {
                    var coordinates = { type: "Polygon", coordinates: geoJsonObject };

                    const myStyle = {
                        "color": "#f7b731",
                        "weight": 4,
                        "opacity": 0.5
                    }

                    L.geoJson(coordinates, {
                        style: myStyle,
                        onEachFeature: (feature, layer) => {
                            this.layer.addLayer(layer)
                            layer.bindTooltip(n.RegionName, { permanent: true, direction: 'center' });
                            layer.myTag = "myGeoJSON";
                        }
                    })
                }
            })
        }
    }

    getInitials = function (string) {
        if (string != undefined) {
            if (string.indexOf(' ') > -1) {
                var names = string.split(' '),
                    initials = names[0];

                if (names.length > 1) {
                    initials += " " + names[names.length - 1].substring(0, 1).toUpperCase() + ".";
                }
                return initials;
            } else {
                return string.substring(0, 1).toUpperCase();
            }
        } else {
            return "";
        }
    };

    async updateCourierMarkers(courier) {
        if (courier != null) {
            var layer = this.layer;
            var layers = [];
            this.map.eachLayer(function (layer) {
                if (layer instanceof L.Marker)
                    if (layer.options.type == "courier")
                        layers.push(layer);

            });
            var style = 'style="background-color:' + (courier.InstantPacketCount == 0 ? '#424242' : (courier.InstantPacketCount > 0 && courier.IsInDelivery == false ? '#40739e' : 'rgba(142, 68, 173,1.0)')) + ';"';
            var stylePin = 'style="border-top:10px solid ' + (courier.InstantPacketCount == 0 ? '#424242' : (courier.InstantPacketCount > 0 && courier.IsInDelivery == false ? '#40739e' : 'rgba(142, 68, 173,1.0)')) + ';"';
            var icon = new L.DivIcon({
                className: 'my-div-icon',
                html: '<div ' + style + ' class="my-div-span_new"><div ' + stylePin + ' class="arrow-down"></div>' + this.getInitials(courier.CourierName) + '</div><div class="pulse"></div>',
                iconSize: [80, 30],
                iconAnchor: [40, 30]
            });

            if (courier != null) {
                if (layers.filter(x => x.options.name == courier.CourierId).length == 0) {
                    L.marker([courier.Lat, courier.Lng], {
                        zIndexOffset: 9999, type: "courier", name: courier.CourierId, icon: icon
                    }, { title: "" }).addTo(layer);
                } else {
                    layers.find(x => x.options.name == courier.CourierId).setLatLng([courier.Lat, courier.Lng]);
                    layers.find(x => x.options.name == courier.CourierId).setIcon(icon);
                }
            }
        }
    }

    compare(a, b) {
        const genreA = a.CreationTime;
        const genreB = b.CreationTime;

        let comparison = 0;
        if (genreA > genreB) {
            comparison = 1;
        } else if (genreA < genreB) {
            comparison = -1;
        }
        return comparison;
    }

    async updateOrderMarkers(order, brandlist) {
        var layer = this.layer;
        this.map.eachLayer(function (layerData) {
            if (layerData.options.type === "order" || layerData.options.type === "brand") {
                layerData.remove();
            }
        });

        var orderList = order.sort(this.compare).slice(0, 20);
        brandlist.map((n, i) => {
            if (orderList.filter(x => x.JaviPosBrandId == n.BrandId).length > 0) {
                var style = 'style="background-color: #227093;"';
                var stylePin = 'style="border-top:10px solid #227093;"';

                L.marker([n.Lat, n.Lng], {
                    zIndexOffset: (100 + i), type: "brand", name: n.JaviPosBrandId, icon: new L.DivIcon({
                        className: 'my-div-icon',
                        html: '<div ' + style + ' class="my-div-span-brand"><div ' + stylePin + ' class="arrow-down-order"></div><div class="arrow-down-text">' + n.BusinessName + '</div></div><div class="pulse"></div>',
                        iconSize: [80, 30],
                        iconAnchor: [40, 30]
                    })
                }, { title: "" }).addTo(layer);
            }
        });
        
        orderList.forEach((n, i) => {
            var style = 'style="background-color: #218c74;"';
            var stylePin = 'style="border-top:10px solid #218c74;"';

            L.marker([n.Lat, n.Lng], {
                zIndexOffset: (100 + i), type: "order", name: n.OrderId, icon: new L.DivIcon({
                    className: 'my-div-icon',
                    html: '<div ' + style + ' class="my-div-span-order"><div ' + stylePin + ' class="arrow-down"></div>' + n.AdditionOrder + '</div><div class="pulse"></div>',
                    iconSize: [40, 30],
                    iconAnchor: [20, 30]
                })
            }, { title: "" }).addTo(layer);

        });
    }

    render() {
        return <div class="mapDiv" id="map" style={style} />;
    }
}

export default PaketYoldaMap;
