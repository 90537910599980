import React from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import styles from '../../Settings/settings-jss';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    setStockTakingVisible, setStockTakingData, setStockTakingList
} from '../../../../redux/modules/stock';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

import DatePicker from "react-datepicker";
import "../datepicker.css";
import { getParameter } from '../../../../function/GeneralFunction';
import Autocomplete from 'react-autocomplete';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
const uuidv4 = require('uuid/v4');
import Divider from '@material-ui/core/Divider';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import classNames from 'classnames';
import CustomerAdd from '../Customer/CustomerAdd';
import CircularProgress from '@material-ui/core/CircularProgress';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            decimalScale={2}
            allowNegative={false}
            fixedDecimalScale={true}
            decimalSeparator="."
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    },
                });
            }}
            thousandSeparator
            autocomplete="off"
        />
    );
}

class StockTaking extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }

    handleFocus = (event) => event.target.select();

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.stockTakingUpdate == this.props.stockTakingUpdate) {
            return false;
        }
        return true;
    }

    handleChangeTaking = (i, name) => event => {
        const { stockTakingData } = this.props;
        var data = event.target.value;
        if (name == "TakingQuantity") {
            if(data.length == 0){
                data = 0;
            }
            stockTakingData[i]["TakingQuantity"] = data;
            stockTakingData[i]["Difference"] = stockTakingData[i].TakingQuantity - stockTakingData[i].RemaningQuantity;
        } else {
            stockTakingData[i]["IsSelected"] = !stockTakingData[i]["IsSelected"];
        }
        this.props.setStockTakingData(stockTakingData).then(() => { })
    };

    render() {
        const { classes, stockTakingVisible, stockTakingData, stockTakingLoading } = this.props;

        return (
            <Dialog
                open={stockTakingVisible}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ classes: { root: classes.dialogQuantityAddPaper } }}
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 5 }}>
                    {"Stok Sayım İşlemleri"}
                </DialogTitle>
                <DialogContent style={{ height: '100vh', zIndex: 1 }}>
                    <SnackbarContent style={{ backgroundColor: '#ffa000', borderRadius: 5, maxWidth: '100%', width: '100%', marginBottom: 5 }} className={classNames(classes.snackbar, messageStyles.bgSuccess)} message="Not: Seçili ürünlerde ki fark miktarları stok hareketlerinize giriş çıkış olarak yansıtılacaktır!" />
                    <Table>
                        <TableHead style={{ backgroundColor: '#212121' }}>
                            <TableRow>
                                <TableCell style={{ width: '10%' }} align="left">{"Seç"}</TableCell>
                                <TableCell style={{ width: '30%' }} align="left">{"Stok Adı"}</TableCell>
                                <TableCell style={{ width: '15%' }} align="center">{"Birim Adı"}</TableCell>
                                <TableCell style={{ width: '15%' }} align="center">{"Mevcut Miktar"}</TableCell>
                                <TableCell style={{ width: '15%' }} align="center">{"Sayım Miktarı"}</TableCell>
                                <TableCell style={{ width: '15%' }} align="center">{"Fark"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                stockTakingData.map((n, i) => {
                                    return (
                                        <TableRow hover key={i}>
                                            <TableCell align="left">
                                                <IconButton style={{ color: (n.IsSelected ? '#4CAF50' : '') }} onClick={this.handleChangeTaking(i, "IsSelected")}>
                                                    <Icon>{(n.IsSelected ? 'check_box' : 'check_box_outline_blank')}</Icon>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="left">{n.StockName}</TableCell>
                                            <TableCell align="center">{n.UnitName}</TableCell>
                                            <TableCell align="center">{n.RemaningQuantity}</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    value={n.TakingQuantity}
                                                    fullWidth
                                                    onChange={this.handleChangeTaking(i, "TakingQuantity")}
                                                    id="formatted-numberformat-input"
                                                    onFocus={event => { event.target.select() }}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">{n.Difference.toFixed(2)}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button disabled={stockTakingLoading} style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }} color="primary" autoFocus onClick={() => this.props.setStockTakingVisible(false).then(() => { })}>
                        {"ÇIKIŞ"}
                    </Button>
                    <Button disabled={stockTakingLoading} style={{ marginRight: 5 }} onClick={() => (confirm('Stok sayımları hareket olarak işlemek istediğinize emin misiniz?') ? this.props.setStockTakingList().then((n, i) => { }) : null)} color="secondary" variant="contained" autoFocus>
                        {(stockTakingLoading ? <CircularProgress style={{ marginRight: 10, color: 'white', backgroundColor: 'transparent' }} disableShrink thickness={5} size={20} /> : null)}
                        {"Stok Sayımını İŞLE"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('stockReducer').stockTakingUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    stockTakingUpdate: value(state, props),
    stockTakingVisible: state.get('stockReducer').stockTakingVisible,
    stockTakingData: state.get('stockReducer').stockTakingData,
    stockTakingLoading: state.get('stockReducer').stockTakingLoading
})

const mapDispatchToProps = {
    setStockTakingVisible,
    setStockTakingData,
    setStockTakingList
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(StockTaking)));