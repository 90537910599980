const styles = theme => ({
  input_in: {
    fontSize:40,
    border:'1px solid white',
    borderRadius:5,
    color:'white'
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '1.5em',
      zIndex:999
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '1px solid slategrey'
    }
  }
});

export default styles;
