import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../Settings/settings-jss';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Switch from 'react-switch';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage, injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
const uuidv4 = require('uuid/v4');
import Select from '@material-ui/core/Select';
import {
  subscriberPlanAmountUpdateModalVisibleChange,
  subscriberPlanUpdateAmountChange,
  sendPaymentLinkNameSurnameChange,
  sendPaymentLinkPhoneChange,
  sendPaymentLinkPriceChange,
  getSendMessageLink,
  getSubscriberAmountPlanUpdate
} from '../../../../redux/modules/admin';
import cookie from 'react-cookies';
import { createSelector } from 'reselect';
import moment from 'moment';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { PricingCard, ModuleCard } from 'dan-components';
import bgCover from 'dan-images/petal_bg.svg';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import MaskedInput from 'react-text-mask';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import paynet from 'dan-images/paynet.png';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SubscriberPlanAmountUpdateModal extends React.Component {
  handleChangeInvoice = (Name) => (event) => {
    if (Name == 'Price') {
      this.props.subscriberPlanUpdateAmountChange(event.target.value);
    }
  };

  closeModalButton() {
    this.props.subscriberPlanAmountUpdateModalVisibleChange(false, '', '', '00000000-0000-0000-0000-000000000000', '');
    this.props.subscriberPlanUpdateAmountChange('');
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.subscriberPlanAmountUpdateModalVisible}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.dialogPaperAbonelik } }}>
        <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {this.props.subscriberPlanUpdatePlanId=='00000000-0000-0000-0000-000000000000'?'Abonelik Tutarı Güncelle':'Ödeme Planının Tutarını Güncelle'}
        </DialogTitle>
        <DialogContent style={{ width: 400 }}>
          {this.props.modalsLoading == true ? (
            <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
              <CircularProgress
                style={{ color: '#fafafa' }}
                thickness={4}
                className={classes.progress}
              />
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                <div style={{ textAlign: 'left', fontSize: 14, color: 'white', paddingLeft: 10 }}>
                  {'Mevcut Tutar: ' +
                    '\n' +
                    parseInt(this.props.subscriberPlanUpdateOldAmount).toFixed(2)}
                </div>
                <Grid item md={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{'Tutar'}</InputLabel>
                    <Input
                      fullWidth
                      placeholder={'00,00'}
                      defaultValue={this.props.sendPaymentLinkPrice}
                      onChange={this.handleChangeInvoice('Price')}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContentText>
          )}
        </DialogContent>
        {this.props.modalsLoading == false ? (
          <DialogActions>
            <Button
              style={{ backgroundColor: '#e74c3c', color: 'white' }}
              onClick={() => this.closeModalButton()}
              color="primary">
              {'İptal'}
            </Button>
            <Button
              onClick={() => this.props.getSubscriberAmountPlanUpdate()}
              color="secondary"
              variant="contained"
              autoFocus>
              {'Güncelle'}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    );
  }
}

SubscriberPlanAmountUpdateModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) =>
  state.get('adminReducer').subscriberPlanAmountUpdateModalIsupdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  subscriberPlanAmountUpdateModalIsupdate: value(state, props),
  subscriberPlanAmountUpdateModalVisible: state.get('adminReducer')
    .subscriberPlanAmountUpdateModalVisible,
  modalsLoading: state.get('adminReducer').modalsLoading,
  subscriberPlanUpdateAmount: state.get('adminReducer').subscriberPlanUpdateAmount,
  subscriberPlanUpdateSubId: state.get('adminReducer').subscriberPlanUpdateSubId,
  subscriberPlanUpdateRefNo: state.get('adminReducer').subscriberPlanUpdateRefNo,
  subscriberPlanUpdatePlanId: state.get('adminReducer').subscriberPlanUpdatePlanId,
  subscriberPlanUpdateOldAmount: state.get('adminReducer').subscriberPlanUpdateOldAmount,
});

const mapDispatchToProps = {
  subscriberPlanAmountUpdateModalVisibleChange,
  subscriberPlanUpdateAmountChange,
  sendPaymentLinkNameSurnameChange,
  sendPaymentLinkPhoneChange,
  sendPaymentLinkPriceChange,
  getSendMessageLink,
  getSubscriberAmountPlanUpdate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(SubscriberPlanAmountUpdateModal)));
