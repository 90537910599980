import React from 'react';
import { connect } from 'react-redux';
import {
    withStyles
} from '@material-ui/core/styles';
import {
    createSelector
} from 'reselect';
import Button from '@material-ui/core/Button';
import {
    setCategoryListVisible, formDataSetStock, setProductCategory, setCategoryNew, setCategoryUpdate,
    setModalStock, setCategoryDelete, setSaveImageProduct
} from '../../../../redux/modules/stock';
import Typography from '@material-ui/core/Typography';
import { CapitalizeText } from '../../../../function/GeneralFunction';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { CirclePicker } from 'react-color';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
const uuidv4 = require('uuid/v4');
import AlertDialog from '../../Ortak/AlertDialog';


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = { categoryId: null }
    }

    shouldComponentUpdate(nextProps) {
        return true;
    }

    handleChangeCategory = name => event => {
        this.props.categoryadd[name] = (name == "Color" ? event.hex : CapitalizeText(event.target.value));
        this.props.formDataSetStock("categoryadd", this.props.categoryadd).then(() => { })
        this.props.setCategoryUpdate().then(() => { })
    };

    async categoryUpdate(Id) {
        let cate = this.props.categoryList.find(x => x.Id == Id);
        let categoryadd = {
            Id: cate.Id,
            Name: cate.Name,
            Order: cate.DisplayOrder,
            Color: cate.Color,
            TenantId: cate.TenantId,
            BrandId: cate.BrandId,
            IsUpdate: true
        };
        this.props.formDataSetStock("categoryadd", categoryadd).then(() => { })
    }

    async CategoryAdd() {
        if (this.props.categoryadd.Name.length == 0) {
            this.props.setModalStock("Kategori adı boş geçilemez!").then(() => { })
            return;
        }
        await this.props.setProductCategory().then(() => { });
        this.props.setCategoryUpdate().then(() => { })
    }

    onChangeImageFile = event => {
        const { categoryadd } = this.props;

        var Id = event.target.id;
        var dataFile = event.target.files[0];

        const fileExtension = dataFile.name.split(".").at(-1);
        const allowedFileTypes = ["png"];
        if (!allowedFileTypes.includes(fileExtension)) {
            this.props.setModalStock("Sadece .png resim formatı desteklenmektedir.").then(() => { })
            return;
        }

        var filesize = ((dataFile.size / 1024) / 1024).toFixed(4); // MB
        if (filesize > 2) {
            this.props.setModalStock("Görsel dosya boyutu maksimum 2mb olabilir").then(() => { })
            return;
        }
        if (!categoryadd.IsUpdate) {
            this.props.setModalStock("Önce kategoriyi kayıt etmeniz gerekmektedir.").then(() => { })
            return;
        }
        this.props.setSaveImageProduct(dataFile, Id, 3, 500, 500).then(() => { });
    };

    addDefaultSrc(ev) {
        ev.target.src = 'https://cdn.javipos.com/broken.png';
    }

    render() {
        const {
            classes,
            categoryadd,
            categoryAddButtonVisible,
            categoryList
        } = this.props;
        return (
            <Dialog
                open={this.props.categoryListVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'md'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Kategori İşlemleri"} {(categoryadd.IsUpdate ? "(Düzenleme)" : "(Yeni Ürün Ekleme)")}
                    <Typography className={classes.instructions} style={{ fontSize: 13, fontWeight: 500, color: 'white' }}>
                        {"Kategori Ekleme ve Düzenleme İşlemleri"}
                    </Typography>
                </DialogTitle>
                <Grid container spacing={1} style={{ padding: '0 24px 24px', width: '100%' }}>
                    <Grid item md={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                        <img onError={this.addDefaultSrc} style={{ height: 30, marginRight: 10 }} src={categoryadd.ImageUrl} />
                        <label htmlFor={categoryadd.Id}>
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                style={{ backgroundColor: 'transparent' }}
                            >{<Icon>{"add_a_photo"}</Icon>}
                            </Button>

                        </label>
                        <Tooltip title="Kategori görsel boyutu 500x500 ve .png formatında olmalıdır!">
                            <input id={categoryadd.Id} style={{ display: "none" }} type="file" onChange={this.onChangeImageFile} />
                        </Tooltip>
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            autoFocus
                            margin="none"
                            value={categoryadd.Name}
                            id="name"
                            label="Kategori Adı"
                            onChange={this.handleChangeCategory("Name")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            id="Order"
                            label="Sıralama"
                            value={categoryadd.Order || 1}
                            onChange={this.handleChangeCategory('Order')}
                            type="number"
                            style={{ minWidth: 150, marginBottom: 10 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <CirclePicker
                            width="100%"
                            name='color'
                            colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#009688", "#FF5722", "#FF9800", "#795548", "#CDDC39", "#8BC34A"]}
                            onChange={this.handleChangeCategory("Color")}
                            color={categoryadd.Color}
                        />
                    </Grid>

                </Grid>
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={1}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">{"Resim"}</TableCell>
                                        <TableCell align="left">{"Renk"}</TableCell>
                                        <TableCell align="left">{"Kategori Adı"}</TableCell>
                                        <TableCell align="left">{"Sıralama"}</TableCell>
                                        <TableCell align="right">{"İşlem"}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        categoryList.map((n, i) => {
                                            return (
                                                <TableRow key={n.Id}>
                                                    <TableCell align="left">
                                                        <img onError={this.addDefaultSrc} style={{ height: 30, marginRight: 10 }} src={n.ImageUrl} />
                                                    </TableCell>
                                                    <TableCell align="left">{<Icon style={{ fontWeight: 700, fontSize: 25, color: (n.Color) }}>{"lens"}</Icon>}</TableCell>
                                                    <TableCell align="left">{n.Name}</TableCell>
                                                    <TableCell align="left">{n.DisplayOrder}</TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="Kategori Düzenle">
                                                            <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5 }} onClick={() => this.categoryUpdate(n.Id)}>
                                                                <Icon>{"edit"}</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Kategori Sil">
                                                            <IconButton style={{ backgroundColor: '#3e3e3e' }} onClick={() => (confirm('Kategoriyi silmek istediğinize emin misiniz?') ? this.props.setCategoryDelete(n.Id).then(() => { }) : null)}>
                                                                <Icon>{"delete_outline"}</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={categoryAddButtonVisible ? true : false} variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setCategoryListVisible(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                    <Button style={{ backgroundColor: '#2196F3' }} variant="contained" onClick={() => this.props.formDataSetStock("categoryadd", { "Id": "", "Name": "", "Order": 1, "Color": "#2196f3", "IsUpdate" : false }).then(() => {
                        this.props.setCategoryUpdate().then(() => { })
                    })} color="secondary">
                        {"Yeni Kategori"}
                    </Button>
                    <Button disabled={categoryAddButtonVisible ? true : false} variant="contained" onClick={() => this.CategoryAdd()} color="secondary">
                        {"Kaydet"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('stockReducer').categoryListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    categoryListUpdate: value(state, props),
    categoryListVisible: state.get('stockReducer').categoryListVisible,
    categoryadd: state.get('stockReducer').form_data.categoryadd,
    categoryAddButtonVisible: state.get('stockReducer').form_data.categoryAddButtonVisible,
    categoryList: state.get('stockReducer').form_data.categoryList,

    categoryDeleteModal: state.get('stockReducer').form_data.categoryDeleteModal
})

const mapDispatchToProps = {
    setCategoryListVisible,
    formDataSetStock,
    setProductCategory,
    setCategoryNew,
    setCategoryUpdate,
    setModalStock,
    setCategoryDelete,
    setSaveImageProduct
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Category);
