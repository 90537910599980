import React from 'react';
import { connect } from 'react-redux';
import {
  withStyles
} from '@material-ui/core/styles';
import {
  createSelector
} from 'reselect';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import cookie from 'react-cookies';
import TimerIcon from '@material-ui/icons/Timer';
import Close from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Ionicon from 'react-ionicons';
import { setPrinterSocketNotificationVisible } from '../../redux/modules/addition';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';

const styles = ({
  SnackbarRoot: {
    bottom: 50,
  }
});

class SocketNotificationPrinter extends React.Component {
  constructor(props){
    super(props);
  }

  shouldComponentUpdate(nextProps){
      return true;
    }

    modalPrinterSocketClose = () => {
      this.props.setPrinterSocketNotificationVisible(false,"","").then (()=>{})
    };

    printerSocketConnectionOpen = () => {
      window.location.reload(true);
    };

    render() {
      const {
        classes
      } = this.props;
      return (
        <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.props.IsPrinterSocketNotificationVisible}
          >
          <div style={{ backgroundColor:(this.props.IsPrinterSocketNotificationType == "error" ? "#E53935" : (this.props.IsPrinterSocketNotificationType == "warning" ? "#FFB300" : "#0097A7")), padding:10, borderRadius:10, color:'white', paddingLeft:20 }}>
            <span style={{ verticalAlign: 'text-top', fontWeight:'500' }}>{this.props.IsPrinterSocketNotificationText}</span>
            {
              (this.props.IsPrinterSocketNotificationType == "error" ?
              <IconButton
                key="reflesh_button"
                style={{ marginLeft:10, borderRadius:10, backgroundColor:'white' }}
                aria-label="Close"
                color="inherit"
                onClick={this.printerSocketConnectionOpen}
              >
                <span style={{ fontSize:15, color:'black' }}>{'Sayfayı Yenile'}</span>
              </IconButton> : null)
            }
            <IconButton
              key="close_button"
              style={{ marginRight:10, marginLeft:20 }}
              aria-label="Close"
              color="inherit"
              onClick={this.modalPrinterSocketClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          </Snackbar>
      );
    }
}

const valueSelector = (state, props) => state.get('additionReducer').IsPrinterSocketNotificationUpdate;
  const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
  IsPrinterSocketNotificationUpdate : value(state, props),
  IsPrinterSocketNotificationVisible : state.get('additionReducer').IsPrinterSocketNotificationVisible,
  IsPrinterSocketNotificationText : state.get('additionReducer').IsPrinterSocketNotificationText,
  IsPrinterSocketNotificationType: state.get('additionReducer').IsPrinterSocketNotificationType
})

const mapDispatchToProps = {
  setPrinterSocketNotificationVisible
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(withStyles(styles)(SocketNotificationPrinter));
