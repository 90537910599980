import React from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import styles from './track-style-jss';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import BarcodeReader from 'react-barcode-reader';
import NumPad from 'react-numpad';
import {
    setPaketYoldaOrderUpdate, setOrderCanceled, setOrderUnLoad, setPaketYoldaCourierVisible
} from '../../../redux/modules/paketyolda';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getPackageAdditionDetail } from '../../../redux/modules/addition';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import PackageAdditionDetail from '../PackagePage/PackageAdditionDetail';

class PaketYoldaOrderList extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaOrderListUpdate == this.props.paketYoldaOrderListUpdate) {
            return false;
        }
        return true;
    }

    async handlePackageOrderItemClick(OrderId, IsSelected) {
        const { paketYoldaOrderList } = this.props;
        paketYoldaOrderList.find(x => x.OrderId == OrderId).IsSelected = !IsSelected;
        this.props.setPaketYoldaOrderUpdate().then(() => { });
    }

    async handlePackageOrderRedirect(OrderId) {
        const { paketYoldaOrderList } = this.props;
        paketYoldaOrderList.find(x => x.OrderId == OrderId).IsSelected = true;
        this.props.setPaketYoldaOrderUpdate().then(() => { });
        this.props.setPaketYoldaCourierVisible(true).then(() => { });
    }

    handleClickPopover = event => {
        this.props.getPackageAdditionDetail(event.currentTarget.id, event.currentTarget);
    };

    compare(a, b) {
        const genreA = a.CreationTime;
        const genreB = b.CreationTime;

        let comparison = 0;
        if (genreA > genreB) {
            comparison = 1;
        } else if (genreA < genreB) {
            comparison = -1;
        }
        return comparison;
    }

    render() {
        const { classes, paketYoldaOrderStatus, paketYoldaOrderList, paketYoldaBusinessList } = this.props;

        return (
            <div style={{ width: '100%', overflowY: 'auto', height: '100%', paddingBottom:10 }}>
                <PackageAdditionDetail classes={classes} />
                <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 5 }}>
                    <TableBody>
                        {
                            paketYoldaOrderList.filter(x => x.OrderStatus == paketYoldaOrderStatus).sort(this.compare).map((n, i) => {
                                return (
                                    <TableRow hover key={i}>
                                        <TableCell style={{ padding: 7, fontSize: 12, lineHeight: 1.5, width: '15%' }} align="left">
                                            {"Adisyon No : "}<span style={{ color: '#fed330', fontWeight: 500 }}>{n.AdditionId}{"  -  "}{n.AdditionOrder}</span>
                                            <br />
                                            {"Geliş : "}{moment(n.CreationTime).add(n.DeliveryTime, 'minutes').format('HH:mm')}{" - "}<span style={{ paddingRight: 5, paddingLeft: 5, backgroundColor: ((n.StandbyTime) <= 20 ? "rgba(0,119,181 ,1)" : ((n.StandbyTime) <= 30 && (n.StandbyTime) >= 20 ? "rgb(232, 111, 98)" : ((n.StandbyTime) <= 40 && (n.StandbyTime) >= 30 ? "rgb(232, 21, 31)" : "rgba(175,6,6 ,1)"))), borderRadius: 3, fontSize: 13, color: 'white', fontWeight: 600 }}>{n.StandbyTime}{"dk"}</span>
                                            <br />
                                            {"Teslim : "}<span style={{ backgroundColor: (n.IsStatusForAssignment ? '#e74c3c' : (n.isScheduled ? '#cd6133': '')), borderRadius: 3, fontSize: 11, color: 'white', fontWeight: 500, paddingLeft: 5, paddingRight: 5  }}>{(n.IsStatusForAssignment ? "Hazırlandı Durumu Bekliyor" : (n.isScheduled ? n.PreparationTime + "dk " + "Süreli" : "Hemen"))}</span>
                                        </TableCell>
                                        <TableCell style={{ padding: 7, fontSize: 12, lineHeight: 1.5, width: '55%' }} align="left">
                                            <span style={{ backgroundColor: '#218c74', borderRadius: 3, fontSize: 14, color: 'white', fontWeight: 600, paddingLeft: 5, paddingRight: 5 }}>{n.RestName}</span>{"  -  "}{n.CustomerName}
                                            <br />
                                            <span style={{ fontSize: 11 }}>{n.AdresssText}</span>
                                            <span style={{ fontSize: 11, marginLeft:10 }}>{n.AdditionDescription}</span>
                                        </TableCell>
                                        <TableCell style={{ padding: 7, fontSize: 11, lineHeight: 1.5, width: '15%' }} align="left">
                                            {"Durum : "}{(n.OrderStatus == 0 ? "Beklemede" : (n.OrderStatus == 1 ? "Kurye Atandı" : "Yolda"))}
                                            <br />
                                            {"Atama : "}<span style={{ color: '#45aaf2', fontWeight: 600 }}>{(n.CourierName.length == 0 ? "Bekleniyor" : (n.IsRoutingType == 0 ? "Otomatik" : (n.IsRoutingType == 1 ? "Mobil Uygulama" : "Operator")))}</span>
                                            <br />
                                            {"Kurye : "}<span style={{ color: '#D980FA', fontWeight: 600, fontSize: 13 }}>{n.CourierName}</span>
                                        </TableCell>
                                        <TableCell style={{ padding: 7, fontSize: 12, lineHeight: 1.5, width: '15%' }} align="right">
                                            <Tooltip title={"Adisyon Detayı"}>
                                                <IconButton
                                                    id={n.OrderId}
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                    onClick={this.handleClickPopover}>
                                                    <Icon>{"receipt_long"}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                (paketYoldaOrderStatus == 0 ?
                                                    <Tooltip title={"Kurye Atama"}>
                                                        <IconButton
                                                            style={{ display: (n.StandbyTime < n.PreparationTime ? 'none' : ''), backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                            onClick={() => this.handlePackageOrderRedirect(n.OrderId)}>
                                                            <Icon>{"two_wheeler"}</Icon>
                                                        </IconButton>
                                                    </Tooltip> : null)
                                            }
                                            {
                                                (paketYoldaOrderStatus != 0 ?
                                                    <Tooltip title={"Geri Al"}>
                                                        <IconButton
                                                            style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                            onClick={() => (confirm('Adisyonu geri almak istediğinize emin misiniz?') ? this.props.setOrderUnLoad(n.Id).then(() => { }) : null)}>
                                                            <Icon>{"redo"}</Icon>
                                                        </IconButton>
                                                    </Tooltip> : null)
                                            }
                                            <Tooltip title={"İptal Et"}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                    onClick={() => (confirm('Adisyonu iptal etmek istediğinize emin misiniz?') ? this.props.setOrderCanceled(n.Id).then(() => { }) : null)}>
                                                    <Icon style={{ color: '#e74c3c' }}>{"delete"}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                (n.OrderStatus == 0 ?
                                                    <IconButton style={{ display: (n.StandbyTime < n.PreparationTime ? 'none' : ''), backgroundColor: '#3e3e3e' }} onClick={() => this.handlePackageOrderItemClick(n.OrderId, n.IsSelected)}>
                                                        <Icon style={{ color: (n.IsSelected ? "#16a085" : "white") }}>{(n.IsSelected ? "check_box" : "check_box_outline_blank")}</Icon>
                                                    </IconButton>
                                                    : null)
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                <div style={{ fontSize: 12, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>

            </div>
        );
    }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaOrderListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaOrderListUpdate: value(state, props),
    paketYoldaOrderList: state.get('paketYoldaReducer').paketYoldaOrderList,
    paketYoldaOrderStatus: state.get('paketYoldaReducer').paketYoldaOrderStatus,
    paketYoldaBusinessList: state.get('paketYoldaReducer').paketYoldaBusinessList,
    paketYoldaOrderCanceledVisible: state.get('paketYoldaReducer').paketYoldaOrderCanceledVisible,
    paketYoldaOrderUnLoadVisible: state.get('paketYoldaReducer').paketYoldaOrderUnLoadVisible,
    selectedOrderId: state.get('paketYoldaReducer').selectedOrderId,
    paketYoldaOrderStatus: state.get('paketYoldaReducer').paketYoldaOrderStatus
})

const mapDispatchToProps = {
    setPaketYoldaOrderUpdate,
    setOrderCanceled,
    setOrderUnLoad,
    getPackageAdditionDetail,
    setPaketYoldaCourierVisible
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(PaketYoldaOrderList)));