exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Retrieves a color value from the $material-colors Sass map\n * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/\n *\n * @function\n * @param {string} color-name             - quoted, lower-case, dasherized color\n *                                          name (e.g. 'pink', 'amber')\n * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.\n *                                          '200', 'a100')\n */\n.izFGxJRZcMySZQ7snmrQd {\n  position: relative;\n  background: #FFF;\n  padding: 16px;\n  max-height: 450px;\n  overflow: auto;\n}\n@media (max-width: 600px) {\n  .izFGxJRZcMySZQ7snmrQd {\n    padding: 15px 10px;\n  }\n}\n\n._1mEKk-Y__yBPHRnG4NxaUB {\n  background: #f5f5f5;\n  position: relative;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  text-align: right;\n  padding: 8px 24px;\n}\n._1mEKk-Y__yBPHRnG4NxaUB button {\n  margin-right: 5px;\n}", ""]);

// exports
exports.locals = {
	"bodyForm": "izFGxJRZcMySZQ7snmrQd",
	"buttonArea": "_1mEKk-Y__yBPHRnG4NxaUB"
};