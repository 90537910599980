import axios from 'axios';
var base64 = require('base-64');
const uuidv4 = require('uuid/v4');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { reactLocalStorage } from 'reactjs-localstorage';

export const EXPENSE_ERROR = 'expense_error';
export const EXPENSE_GLOBAL_FORM_DATA_SET = 'expense_global_form_data_set';
export const EXPENSELIST_VISIBLE = 'expenselist_visible';
export const EXPENSELIST_UPDATE = 'expenselist_update';
export const EXPENSELIST_LOADING = 'expenselist_loading';

export const EXPENSEADD_VISIBLE = 'expenseadd_visible';
export const EXPENSEADD_UPDATE = 'expenseadd_update';


export const EXPENSEADD_CLEAR = 'expenseadd_clear';
export const EXPENSEADD_GET = 'expenseadd_get';

export const EXPENSECUSTOMER_SET = 'expensecustomer_set';

let initialState = {
    form_data: {
        modalOpen: false,
        modalTitle: "",
        modalText: ""
    },
    expenseListUpdate: false,
    expenseListVisible: false,
    filterDataExpense: {
        sortColumnName: "ExpenseDatetime",
        sortOrder: "asc",
        totalRecord: 0,
        currentPage: 1,
        rowsPerPage: 50,
        searchText: "",
        BrandId: "",
        TenantId: "",
        totalPage: 0,
        List: [],
        paymentList: []
    },
    expenseListLoading: false,
    expenseAddVisible: false,
    expenseAddUpdate: false,
    expenseAdd: {
        Id: "00000000-0000-0000-0000-000000000000",
        ExpenseType: "",
        Detail: "",
        ExpenseDatetime: new Date(new Date().setHours(0, 0, 0, 0)),
        CreationDatetime: new Date(new Date().setHours(0, 0, 0, 0)),
        Amount: 0,
        Username: "",
        TenantId: "",
        BrandId: "",
        CustomerId: "00000000-0000-0000-0000-000000000000"
    },
    customerList: []
}

export async function ExpenseGet(Id) {
    return async (dispatch,getState) => {
        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Expense/ExpenseGet?Id=' + Id,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                var json = JSON.parse(response.data.responseData);
                if (response.data.isStatus) {
                    json.ExpenseDatetime = new Date(json.ExpenseDatetime);
                    json.CreationDatetime = new Date(json.CreationDatetime);
                    dispatch({ type: EXPENSEADD_GET, payload: json });
                }
            })
            .catch(function (error) {

            });
    }
}

export async function getPersonal() {
    return async (dispatch,getState) => {
        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Expense/getPersonal?BrandId=' + cookie.load("BrandId"),
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                var json = JSON.parse(response.data.responseData);
                if (response.data.isStatus) {
                    dispatch({ type: EXPENSECUSTOMER_SET, payload: json });
                }
            })
            .catch(function (error) {

            });
    }
}

export async function ExpenseDelete(Id) {
    return async (dispatch,getState) => {
        const { filterDataExpense } = getState().get('expenseReducer');

        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Expense/ExpenseDelete?Id=' + Id,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getExpenseList(filterDataExpense));
                }
            })
            .catch(function (error) {

            });
    }
}

export async function postExpenseAdd() {
    return async (dispatch,getState) => {
        const { expenseAdd, filterDataExpense } = getState().get('expenseReducer');

        expenseAdd.BrandId = cookie.load("BrandId");
        expenseAdd.TenantId = cookie.load("TenantId");
        expenseAdd.Username = cookie.load("UserName");

        if (expenseAdd.ExpenseType == "Personel" || expenseAdd.ExpenseType == "Kurye") {
            if (expenseAdd.CustomerId == "00000000-0000-0000-0000-000000000000") {
                dispatch(setExpenseModal("Personel seçmeniz gerekmektedir."))
                return;
            }
        } else {
            expenseAdd.CustomerId = "00000000-0000-0000-0000-000000000000";
        }

        await axios({
            method: 'POST',
            url: brand.urlSocket + 'api/Expense/ExpenseAdd',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: expenseAdd,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(setExpenseClear());
                    dispatch(getExpenseList(filterDataExpense));
                    dispatch(setExpenseAddVisible());

                } else {
                    console.log(response)
                }
            })
            .catch(function (error) {

            });
    }
}

export async function setExpenseAddVisible() {
    return async (dispatch,getState) => {
        dispatch({
            type: EXPENSEADD_VISIBLE
        });
    };
}

export async function getExpenseList(filterDataExpense) {
    return async (dispatch,getState) => {
        dispatch({ type: EXPENSELIST_LOADING });
        filterDataExpense.BrandId = cookie.load("BrandId");
        filterDataExpense.TenantId = cookie.load("TenantId");
        
        await axios({
            method: 'POST',
            url: brand.urlSocket + 'api/Expense/getExpenseList',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: filterDataExpense,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);

                    dispatch({
                        type: EXPENSELIST_UPDATE,
                        data: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: EXPENSELIST_LOADING });
    };
}

export async function setExpenseListVisible(visible) {
    return async (dispatch,getState) => {
        if (visible) {
            var filterDataExpense = {
                sortColumnName: "ExpenseDatetime",
                sortOrder: "asc",
                totalRecord: 0,
                currentPage: 1,
                searchText: "",
                BrandId: "",
                totalPage: 0,
                List: []
            }
            dispatch(getExpenseList(filterDataExpense));
        }
        dispatch({
            type: EXPENSELIST_VISIBLE,
            open: visible
        });
    };
}

export async function setExpenseAdd() {
    return async (dispatch,getState) => {
        dispatch({
            type: EXPENSEADD_UPDATE
        });
    };
}

export async function setExpenseClear() {
    return async (dispatch,getState) => {
        dispatch({
            type: EXPENSEADD_CLEAR
        });
    };
}

export async function formDataSetExpense(key, val) {
    return async (dispatch,getState) => {
        dispatch({
            type: EXPENSE_GLOBAL_FORM_DATA_SET,
            payload: { key, val }
        });
    };
}

export async function setExpenseModal(text) {
    return async (dispatch,getState) => {
        dispatch({
            type: EXPENSE_ERROR,
            payload: text
        });
    };
}

const expenseReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXPENSECUSTOMER_SET:
            return { ...state, customerList: action.payload }
        case EXPENSELIST_LOADING:
            return { ...state, expenseListLoading: !state.expenseListLoading }
        case EXPENSELIST_VISIBLE:
            return { ...state, expenseListVisible: action.open }
        case EXPENSEADD_UPDATE:
            return { ...state, expenseAdd: state.expenseAdd, expenseAddUpdate: !state.expenseAddUpdate }
        case EXPENSELIST_UPDATE:
            return { ...state, filterDataExpense: action.data, expenseListUpdate: !state.expenseListUpdate }
        case EXPENSEADD_VISIBLE:
            return {
                ...state, expenseAddVisible: !state.expenseAddVisible, expenseAdd: (!state.expenseAddVisible == true ?
                    {
                        Id: "00000000-0000-0000-0000-000000000000",
                        ExpenseType: "",
                        Detail: "",
                        ExpenseDatetime: new Date(),
                        CreationDatetime: new Date(new Date().setHours(0, 0, 0, 0)),
                        Amount: 0,
                        Username: "",
                        TenantId: "",
                        BrandId: "",
                        CustomerId: "00000000-0000-0000-0000-000000000000"
                    } : state.expenseAdd)
            }
        case EXPENSEADD_GET:
            return {
                ...state, expenseAddVisible: !state.expenseAddVisible, expenseAdd: action.payload
            }
        case EXPENSEADD_CLEAR:
            return {
                ...state, expenseAdd: {
                    Id: "00000000-0000-0000-0000-000000000000",
                    ExpenseType: "",
                    Detail: "",
                    ExpenseDatetime: new Date(new Date().setHours(0, 0, 0, 0)),
                    CreationDatetime: new Date(new Date().setHours(0, 0, 0, 0)),
                    Amount: 0,
                    Username: "",
                    TenantId: "",
                    BrandId: "",
                    CustomerId: "00000000-0000-0000-0000-000000000000"
                }, expenseAddUpdate: !state.expenseAddUpdate
            }
        case EXPENSE_ERROR:
            return {
                ...state, form_data: {
                    ...state.form_data,
                    modalOpen: true,
                    modalText: action.payload,
                    modalTitle: "Uyarı"
                }
            }
        case EXPENSE_GLOBAL_FORM_DATA_SET:
            return {
                ...state,
                form_data: {
                    ...state.form_data,
                    [action.payload.key]: action.payload.val
                }
            }
        default:
            return state;
    }
}

export default expenseReducer;
