import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { RequestStockMovementList, modalCloseReport } from '../../../../redux/modules/report';
import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DatePicker, { registerLocale } from "react-datepicker";
import "../datepicker.css";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { EmptyData } from 'dan-components';
import TbPaginationActions from '../../Ortak/TbPaginationActions';
import AlertDialog from '../../Ortak/AlertDialog';
import { getParameter } from '../../../../function/GeneralFunction';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalInformation from '../../Ortak/ModalInformation';
import styles from 'dan-components/Tables/tableStyle-jss';

class StockMovementList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { searchValue: "", startDate: new Date(new Date().setHours(0, 0, 0, 0)), endDate: new Date(new Date().setHours(23, 59, 59, 999)) }
    }

    async componentDidMount() {
        var TimeData = getParameter("BusinessWorkingHours");
        await this.setState({ startDate: TimeData.start, endDate: TimeData.end })
    }

    shouldComponentUpdate(nextProps, nextState) {
        //if (nextProps.stockMovementListUpdate == this.props.stockMovementListUpdate) {
        //return false;
        //}
        return true;
    }

    handleSearch = () => {
        const { filterStockMovementListData } = this.props;
        filterStockMovementListData.searchText = this.state.searchValue;
        this.props.RequestStockMovementList(filterStockMovementListData).then(() => { })
    };

    modalClose = () => {
        this.props.modalCloseReport().then(() => { })
    };

    render() {
        const { classes, loading } = this.props;

        return (
            <Paper className={classes.root} elevation={0} style={{ borderRadius: 0, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 10 }}>
                <ModalInformation event={this.modalClose} open={this.props.modalOpen} title={this.props.modalTitle} text={this.props.modalText} />
                <Toolbar style={{ marginBottom: 5, minHeight: 48, width: '100%', display: 'flex' }}>
                    <Grid container spacing={1}>
                        <Grid item md={9}></Grid>
                        <Grid item md={2}>
                            <FormControl fullWidth className={classNames(classes.formControl)}>
                                <Input
                                    id="search_filter"
                                    type="text"
                                    placeholder={"Arama"}
                                    value={this.state.searchValue}
                                    onChange={(event) => this.setState({ searchValue: event.target.value })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1}>
                            <Button disabled={loading} fullWidth onClick={this.handleSearch} style={{ borderRadius: 3, marginLeft: 5, marginTop:2 }} variant="contained" color="primary">
                                {"Filtrele"}
                                {(this.props.stockMovementListLoading ? <CircularProgress style={{ color: 'white', marginLeft: 10 }} disableShrink thickness={3} size={15} /> : null)}
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>

                <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 5 }}>
                    <TableHead style={{ backgroundColor: '#212121' }}>
                        <TableRow>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Adı"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Giriş"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Çıkış"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Kalan"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Birim Adı"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.filterStockMovementListData.List.map((n, i) => {
                                return (
                                    <TableRow hover key={i}>
                                        <TableCell align="left">{n.StockName}</TableCell>
                                        <TableCell align="left">{Number(n.Input.toFixed(3))}</TableCell>
                                        <TableCell align="left">{Number(n.Output.toFixed(3))}</TableCell>
                                        <TableCell align="left">{Number(n.Remaining.toFixed(3))}</TableCell>
                                        <TableCell align="left">{n.UnitName}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {
                    (this.props.filterStockMovementListData.List.length == 0 ? <EmptyData text={"Stok Durum Kaydı Bulunamadı"} /> : null)
                }
                {(this.props.stockMovementListLoading ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
            </Paper>
        )
    }
}


const valueSelector = (state, props) => state.get('reportReducer').stockMovementListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    stockMovementListUpdate: value(state, props),
    filterStockMovementListData: state.get('reportReducer').filterStockMovementListData,
    stockMovementListLoading: state.get('reportReducer').stockMovementListLoading,

    modalOpen: state.get('reportReducer').form_data.modalOpen,
    modalText: state.get('reportReducer').form_data.modalText,
    modalTitle: state.get('reportReducer').form_data.modalTitle
})

const mapDispatchToProps = {
    RequestStockMovementList,
    modalCloseReport
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(StockMovementList)));
