import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './ortak-jss';
import { withStyles } from '@material-ui/core/styles';

class AlertNotification extends React.Component {
  constructor() {
    super();
  }

  handleClose = () => {
    this.props.eventClose();
  };

  handleConfirmation = () => {
    this.props.eventConfirmation();
  };

  closeButton(stat) {
    if (!stat) {
      return (
        <Button variant="contained" style={{ backgroundColor: '#e74c3c', color: 'white' }} color="primary" onClick={this.handleClose}>
          {"İptal"}
        </Button>
      )
    } else {
      return null;
    }
  }

  confirmationButton(stat) {
    if (!stat) {
      return (
        <Button variant={"contained"} color="secondary"  onClick={this.handleConfirmation} autoFocus>
          {"Onay"}
        </Button>
      )
    } else {
      return null;
    }
  }

  render() {
    const { classes, title, text, open, contentStyle, contentTextStyle, closeVisible, confirmationVisible } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ classes: { root: classes.dialogPaperOrtakAlert } }}
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '10px 24px 5px', marginBottom: 10 }}>
            {title}
          </DialogTitle>
          <DialogContent id={"modaldiv"} style={contentStyle}>
            <DialogContentText id="alert-dialog-description">
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.closeButton(closeVisible)}
            {this.confirmationButton(confirmationVisible)}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AlertNotification);
