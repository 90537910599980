import axios from 'axios';
var base64 = require('base-64');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  setPrinterSocketNotificationVisible
} from './addition';

export const SYSTEM_START = 'system_start';
export const SYSTEM_STOP = 'system_stop';
export const SYSTEM_ERROR = 'system_error';
export const HUBSYSTEM_STOP = 'hubsystem_stop';

export const WAITERGETTABLE_VISIBLE = 'waitergettable_visible';

export const MUSICPLAY_START = 'musicplay_start';

import { store } from 'react-notifications-component';
const uuidv4 = require('uuid/v4');

let initialState = {
  data: [],
  loading: false,
  error: '',
  systemHubConnection: null,
  musicPlay: false
}

export async function setSystemHubConnection() {
  return async (dispatch, getState) => {
    var LoginDate = "";
    var UserId = cookie.load('UserId');
    var GroupName = cookie.load('BrandId');
console.log(UserId)
    const systemHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          } else {
            // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
            return null;
          }
        }
      })
      .withUrl(brand.urlSocket + "system?UserId=" + UserId, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    await connectSystem(systemHubConnection, UserId, GroupName, dispatch);

    dispatch({ type: HUBSYSTEM_STOP, payload: systemHubConnection });
  }
}

async function connectSystem(conn, UserId, GroupName, dispatch) {
  await conn.start().then(() => {
    conn.invoke('GetConnectionId').then((response) => {
      conn.on('GetAllMessageSystem', (message) => {
        var text = JSON.parse(message);
        if (text.ConnectionId.length != 0) {
          text.ConnectionId.map(n => {
            if (response == n && text.UserId == UserId && cookie.load('UserName') != "demo@javipos.com") {
              window.location.href = "/";
            }
          })
        }
      });
    });

    conn.on('SetMessageCriticalStockQuantity', (message) => {
      var jsonData = JSON.parse(message);
      jsonData.map((n, i) => {
        store.add({
          id: uuidv4(),
          title: "Kritik Stok Uyarısı",
          message: n.Message,
          type: "danger",
          container: "bottom-right",
          insert: "bottom",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "faster", "fadeOut"],
          slidingEnter: {
            duration: 300,
            timingFunction: "linear",
            delay: 0
          },
          slidingExit: {
            duration: 300,
            timingFunction: "linear",
            delay: 0
          },
          touchRevert: {
            duration: 600,
            timingFunction: "linear",
            delay: 0
          },
          touchSlidingExit: {
            swipe: {
              duration: 600,
              timingFunction: "linear",
              delay: 0
            },
            alpha: {
              duration: 300,
              timingFunction: "linear",
              delay: 0
            }
          },
          dismiss: {
            duration: 0,
            onScreen: !1,
            pauseOnHover: !0,
            waitForAnimation: !1,
            showIcon: !0,
            click: !0,
            touch: !0
          },
          onRemoval: (id, removedBy) => {

          }
        });
      });
    });
    
    conn.on('GetAllMessageSystemGetTable', (messageTable) => {
      var text = JSON.parse(messageTable);
      if (text.UserId == UserId) {
        dispatch(setMusicPlayStart(true))
        setTimeout(() => {
          dispatch(setMusicPlayStart(false))
        }, 3500);
        store.add({
          id: uuidv4(),
          title: (text.Type == false ? "Müşteri Garson Çağırıyor" : "Müşteri Hesap İstiyor"),
          message: text.Text,
          type: (text.Type == false ? "warning" : "info"),
          container: "top-right",
          insert: "top",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "faster", "fadeOut"],
          slidingEnter: {
            duration: 300,
            timingFunction: "linear",
            delay: 0
          },
          slidingExit: {
            duration: 300,
            timingFunction: "linear",
            delay: 0
          },
          touchRevert: {
            duration: 600,
            timingFunction: "linear",
            delay: 0
          },
          touchSlidingExit: {
            swipe: {
              duration: 600,
              timingFunction: "linear",
              delay: 0
            },
            alpha: {
              duration: 300,
              timingFunction: "linear",
              delay: 0
            }
          },
          dismiss: {
            duration: 0,
            onScreen: !1,
            pauseOnHover: !0,
            waitForAnimation: !1,
            showIcon: !0,
            click: !0,
            touch: !0
          },
          onRemoval: (id, removedBy) => {

          }
        });
      }
    });
    conn.invoke('BroadCastMessage', UserId).then(() => { }).catch(err => console.error(err));
  }).catch(e => {
    sleepSystem(5000);
    connectSystem(conn, UserId, GroupName, dispatch);
  })
}


export async function setMusicPlayStart(start) {
  return async (dispatch, getState) => {
    dispatch({
      type: MUSICPLAY_START,
      payload: start
    });
  };
}

async function sleepSystem(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

const systemReducer = (state = initialState, action) => {
  switch (action.type) {
    case SYSTEM_START:
      return { ...state, loading: true }
    case SYSTEM_ERROR:
      return { ...state, loading: false, error: action.payload }
    case SYSTEM_STOP:
      return { ...state, loading: false, data: action.payload }
    case HUBSYSTEM_STOP:
      return { ...state, loading: false, systemHubConnection: action.payload }
    case MUSICPLAY_START:
      return { ...state, musicPlay: action.payload }
    default:
      return state;
  }
}

export default systemReducer;
