import axios from 'axios';
var base64 = require('base-64');
const uuidv4 = require('uuid/v4');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';

export const USER_START = 'user_start';
export const USER_STOP = 'user_stop';
export const USER_ERROR = 'user_error';
export const USER_LOCKSCREEN = 'user_lockscreen';
export const USER_GLOBAL_FORM_DATA_SET = 'user_global_form_data_set';
export const USER_MODALCLOSE = 'user_modalclose';

export const USERLIST_LOADING = 'userlist_loading';
export const USERLIST_UPDATE = 'userlist_update';
export const USERLIST_VISIBLE = 'userlist_visible';

let initialState = {
  form_data: {
    userListLoad: false,
    userList: [],
    userListLoad: false,
    modalOpen: false,
    modalTitle: "",
    modalText: "",
    userAddModal: false,
    userAddModalTitle: "",
    data: {
      Id: "",
      Name: "",
      Username: "",
      Password: "",
      UserType: "",
      IsUpdate: false,
      TenantId: "",
      BrandId: "",
      IsStatus: true,
      IsCustomerCreate: true,
      Authorization: null,
      PlateId: "00000000-0000-0000-0000-000000000000",
      Phone: ""
    },
    userDeleteModal: false,
  },
  IsVisibleLockScreen: (cookie.load('UserType') == "Waiter" ? true : false),

  userListUpdate: false,
  userListVisible: false,
  filterDataUser: {
    sortColumnName: "Username",
    sortOrder: "asc",
    totalRecord: 0,
    currentPage: 1,
    rowsPerPage: 50,
    searchText: "",
    BrandId: "",
    totalPage: 0,
    List: []
  },
  userListLoading: false,
}

export async function setUser(callcenter = false) {
  return async (dispatch, getState) => {
    const { data } = getState().get('userReducer').form_data;
    if (!data.IsUpdate) {
      data["Id"] = uuidv4();
      data["TenantId"] = cookie.load('TenantId');
      data["BrandId"] = cookie.load('BrandId');

      if (callcenter) {
        data["BrandId"] = cookie.load('CenterBrandId');
        data["IsCustomerCreate"] = false;
      }
    }

    dispatch(formDataSetUser("userListLoad", false));
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/User/setAddUser',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(formDataSetUser("userList", json));
          dispatch(formDataSetUser("userListLoad", true));
          dispatch({ type: USER_ERROR, payload: "İşlem Tamamlandı" });
        } else {
          dispatch({ type: USER_ERROR, payload: response.data.responseData });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export async function getUserUpdate(Id) {
  return async (dispatch, getState) => {
    axios({
      method: 'GET',
      url: brand.urlSocket + 'api/User/GetUser?Id=' + Id + '',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(formDataSetUser("data", json));
          dispatch(formDataSetUser("userAddModalTitle", "Kullanıcı Düzenleme"));
          dispatch(formDataSetUser("userAddModal", true));
        } else {
          dispatch({ type: USER_ERROR, payload: response.data.responseData })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export async function getNewAuthorization() {
  return async (dispatch, getState) => {
    const { data } = getState().get('userReducer').form_data;
    axios({
      method: 'GET',
      url: brand.urlSocket + 'api/User/GetNewAuthorization?type=' + data.UserType + '&Id=' + data.Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          data.Authorization = json;
          dispatch(formDataSetUser("data", data));
        } else {
          dispatch({ type: USER_ERROR, payload: response.data.responseData })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export async function setUserDelete(Id) {
  return async (dispatch, getState) => {
    const { filterDataUser } = getState().get('userReducer');
    axios({
      method: 'GET',
      url: brand.urlSocket + 'api/User/setUserDelete?Id=' + Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      }
    })
      .then(function (response) {
        if (response.data.isStatus) {
          dispatch(getUserList(filterDataUser));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export async function getUser(callcenter = false) {
  return async (dispatch, getState) => {
    var TenantId = cookie.load('TenantId');
    var BrandId = cookie.load('BrandId');
    if (callcenter) {
      BrandId = cookie.load('CenterBrandId');
    }

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/User/getUserList?TenantId=' + TenantId + '&BrandId=' + BrandId + '',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        var json = JSON.parse(response.data.responseData);
        dispatch(formDataSetUser("userList", json));
        dispatch(formDataSetUser("userListLoad", true));
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export async function formDataSetUser(key, val) {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_GLOBAL_FORM_DATA_SET,
      payload: { key, val }
    });
  };
}

export async function modalClose() {
  return async (dispatch, getState) => {
    dispatch(formDataSetUser("modalOpen", false));
  }
}

export async function userClear() {
  return async (dispatch, getState) => {
    var data = {
      Id: "",
      Name: "",
      Username: "",
      Password: "",
      UserType: "",
      IsUpdate: false,
      TenantId: "",
      BrandId: "",
      IsStatus: true,
      IsCustomerCreate: true,
      PlateId: "00000000-0000-0000-0000-000000000000",
      Phone: ""
    };
    dispatch(formDataSetUser("data", data));
  }
}

export async function setLockScreenVisible(update) {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_LOCKSCREEN,
      payload: update
    });
  };
}

export async function setModalOpenUser(text) {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_ERROR,
      payload: text
    });
  };
}

export async function getUserList(filterDataUser) {
  return async (dispatch, getState) => {
    dispatch({ type: USERLIST_LOADING });
    filterDataUser.BrandId = cookie.load("BrandId");

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/User/getUserFilterList',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: filterDataUser,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({
            type: USERLIST_UPDATE,
            data: json
          });
        }
      })
      .catch(function (error) {
        alert(error);
      });
    dispatch({ type: USERLIST_LOADING });
  };
}

export async function setUserListVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      var filterDataUser = {
        sortColumnName: "Name",
        sortOrder: "asc",
        totalRecord: 0,
        currentPage: 1,
        searchText: "",
        BrandId: "",
        totalPage: 0,
        List: [],
        Category: []
      }
      dispatch(getUserList(filterDataUser));
    }
    dispatch({
      type: USERLIST_VISIBLE,
      open: visible
    });
  };
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERLIST_LOADING:
      return { ...state, userListLoading: !state.userListLoading }
    case USERLIST_VISIBLE:
      return { ...state, userListVisible: action.open }
    case USERLIST_UPDATE:
      return { ...state, filterDataUser: action.data, userListUpdate: !state.userListUpdate }
    case USER_START:
      return { ...state, loading: true }
    case USER_LOCKSCREEN:
      return { ...state, IsVisibleLockScreen: action.payload }
    case USER_ERROR:
      return {
        ...state, form_data: {
          ...state.form_data,
          modalOpen: true,
          modalText: action.payload,
          modalTitle: "Uyarı"
        }
      }
    case USER_STOP:
      return { ...state, loading: false, data: action.payload }
    case USER_GLOBAL_FORM_DATA_SET:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          [action.payload.key]: action.payload.val
        }
      }
    default:
      return state;
  }
}

export default userReducer;
