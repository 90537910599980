import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../Settings/settings-jss';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Switch from 'react-switch';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage, injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
const uuidv4 = require('uuid/v4');
import Select from '@material-ui/core/Select';
import {
  subscriberInvoiceModalVisibleChange,
  setSubscriberInvoiceData,
  alertModalChange,
  setSubscriberInvoiceHeader,
} from '../../../../redux/modules/admin';
import cookie from 'react-cookies';
import { createSelector } from 'reselect';
import moment from 'moment';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { PricingCard, ModuleCard } from 'dan-components';
import bgCover from 'dan-images/petal_bg.svg';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import MaskedInput from 'react-text-mask';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import paynet from 'dan-images/paynet.png';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function TextMaskCustomPhone(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        ' ',
        /[1-9]/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class SubscriberInvoceDataModal extends React.Component {
  handleChangeInvoice = (Name) => (event) => {
    const { subscriberInvoiceData } = this.props;
    subscriberInvoiceData[Name] = event.target.value;
    this.props.setSubscriberInvoiceData(subscriberInvoiceData).then(() => {});
  };

  closeModalButton() {
    this.props.subscriberInvoiceModalVisibleChange(false, '00000000-0000-0000-0000-000000000000');
    this.props.setSubscriberInvoiceData(null);
  }

  clickInvoiceSave = async () => {
    const { subscriberInvoiceData, subscriberInvoiceBrandId } = this.props;
    var count = Object.keys(subscriberInvoiceData).length;
    for (let index = 0; index < count; index++) {
      const element = Object.values(subscriberInvoiceData)[index];
      if (element == null) {
        await this.props
          .alertModalChange(true, 'Uyarı', 'Lütfen tüm alanları doldurunuz.')
          .then(() => {});
        return;
      }
      if (element.length == 0) {
        await this.props
          .alertModalChange(true, 'Uyarı', 'Lütfen tüm alanları doldurunuz.')
          .then(() => {});
        return;
      }
    }
    await this.props
      .setSubscriberInvoiceHeader(subscriberInvoiceData, subscriberInvoiceBrandId)
      .then(() => {});
  };

  render() {
    const { classes, subscriberInvoiceData } = this.props;
    return (
      <Dialog
        open={this.props.subscriberInvoiceModalVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {'Fatura Bilgileri'}
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          {this.props.modalsLoading == true ? (
            <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
              <CircularProgress
                style={{ color: '#fafafa' }}
                thickness={4}
                className={classes.progress}
              />
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <div style={{ textAlign: 'center', fontSize: 12, color: 'white' }}>
                {
                  'Abonelik başlatabilmeniz için fatura bilgilerini doldurmanız gerekmektedir. Faturalandırmalar otomatik yapıldığından bu bilgiler zorunludur.'
                }
              </div>{' '}
              {subscriberInvoiceData !== null ? (
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="name-simple">{'Ticari Ünvan'}</InputLabel>
                      <Input
                        fullWidth
                        defaultValue={subscriberInvoiceData.CompanyTitle}
                        onChange={this.handleChangeInvoice('CompanyTitle')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="name-simple">{'Vergi Dairesi'}</InputLabel>
                      <Input
                        fullWidth
                        defaultValue={subscriberInvoiceData.TaxOffice}
                        onChange={this.handleChangeInvoice('TaxOffice')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="name-simple">{'Vergi Numarası'}</InputLabel>
                      <Input
                        fullWidth
                        defaultValue={subscriberInvoiceData.TaxNo}
                        onChange={this.handleChangeInvoice('TaxNo')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="name-simple">{'Adres'}</InputLabel>
                      <Input
                        fullWidth
                        multiline
                        defaultValue={subscriberInvoiceData.Address}
                        onChange={this.handleChangeInvoice('Address')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="name-simple">{'E-posta'}</InputLabel>
                      <Input
                        fullWidth
                        defaultValue={subscriberInvoiceData.Eposta}
                        onChange={this.handleChangeInvoice('Eposta')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="name-simple">{'Telefon'}</InputLabel>
                      <Input
                        inputComponent={TextMaskCustomPhone}
                        fullWidth
                        defaultValue={subscriberInvoiceData.Phone}
                        onChange={this.handleChangeInvoice('Phone')}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
            </DialogContentText>
          )}
        </DialogContent>
        {this.props.modalsLoading == true ? null : (
          <DialogActions>
            <Button
              style={{ backgroundColor: '#e74c3c', color: 'white' }}
              onClick={() => this.closeModalButton()}
              color="primary">
              {'İptal'}
            </Button>
            <Button
              onClick={() => this.clickInvoiceSave()}
              color="secondary"
              variant="contained"
              autoFocus>
              {'Kaydet'}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

SubscriberInvoceDataModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('adminReducer').subscriberInvoiceModalIsupdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  subscriberInvoiceModalIsupdate: value(state, props),
  subscriberInvoiceModalVisible: state.get('adminReducer').subscriberInvoiceModalVisible,
  modalsLoading: state.get('adminReducer').modalsLoading,
  subscriberInvoiceData: state.get('adminReducer').subscriberInvoiceData,
  subscriberInvoiceBrandId: state.get('adminReducer').subscriberInvoiceBrandId,
});

const mapDispatchToProps = {
  subscriberInvoiceModalVisibleChange,
  setSubscriberInvoiceData,
  alertModalChange,
  setSubscriberInvoiceHeader,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(SubscriberInvoceDataModal)));
