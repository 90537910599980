import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PapperBlock } from 'dan-components';
import PropTypes from 'prop-types';
import brand from 'dan-api/dummy/brand';
import ReportList from '../ReportList';

export class Report extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentWillMount() {
    }

    render() {
        const title = brand.name + ' - Raporlama';
        const description = brand.desc;
        return (
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="twitter:title" content={title} />
                    <meta property="twitter:description" content={description} />
                </Helmet>
                <ReportList open={true} handleClose={null} title={"Raporlama"} />
            </div>
        );
    }
}

export default connect(null, null)(Report);
