import axios from 'axios';
import {
  HubConnectionBuilder, LogLevel, HttpTransportType, HubConnectionState
} from '@microsoft/signalr';
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import moment from 'moment';
import { push } from 'connected-react-router';

import { store } from 'react-notifications-component';
import {
  formDataSetCustomer
} from './customer';
import {
  sendMessage
} from './table';
import {
  getPaycellPosList
} from './settings';
import { getParameter, notificationShow, getLicenceModule } from '../../function/GeneralFunction';
const base64 = require('base-64');
const uuidv4 = require('uuid/v4');
import * as workerTimers from "worker-timers";

export const ADDITION_START = 'addition_start';
export const ADDITIONLIST_UPDATE = 'additionlist_update';
export const DETAILEDPAYMENT_VISIBLE = 'detailedpayment_visible';
export const DETAILEDPAYMENT_UPDATE = 'detailedpayment_update';
export const GET_ADDITION_DATA = 'get_addition_data';
export const DETAILPAYMENT_SNACKBAR = 'detailpayment_snackbar';

export const ADDITION_STOP = 'addition_stop';
export const HUB_STOP = 'hub_stop';
export const HUBCALLCENTER_STOP = 'hubcallcenter_stop';
export const HUBCALLERID_STOP = 'hubcallerid_stop';
export const HUBSCALES_STOP = 'hubscales_stop';
export const ADDITION_ERROR = 'addition_error';
export const ADDI_GLOBAL_FORM_DATA_SET = 'addi_global_form_data_set';
export const ADDITION_MODALCLOSE = 'addition_modalclose';
export const PRINTERSOCKETNOTIFICATION_VISIBLE = 'printersocketnatification_visible';
export const PRODUCTLISTDETAIL_UPDATE = 'productlistdetail_update';
export const PRODUCTDETAIL_OPEN = 'productdetail_open';
export const PRODUCTDETAIL_CLOSE = 'productdetail_close';
export const PRODUCTDETAIL_UPDATE = 'productdetail_update';
export const ADDITIONPAYMENT_LOADING = 'additionpayment_loading';

export const ADDITION_BACK = 'addition_back';
export const PACKAGEPRINT_MODALVISIBLE = 'packageprint_modavisible';
export const TREATPRODUCT_QUANTITY = 'treatproduct_quantity';

export const REPEATORDER_LIST = 'repeatorder_list';

export const PRINTER_UPDATE = 'printer_update';

export const SCALES_QUANTITY = 'scales_quantity';
export const SETADDITION_CLEAR = 'setaddition_clear';

export const SET_SEEPRICE = 'set_seeprice';

export const SETSALES_RETURN = 'setsales_return';
export const SET_ADDITIONWAITING_VISIBLE = 'set_additionwaiting_visible';
export const SET_ADDITIONWAITING = 'set_additionwaiting';
export const PACKAGELIST_CLEAR = 'packagelist_clear';
export const GETIRCANCEL_OPTIONS = 'getircancel_options';
export const SET_PACKAGERELOAD = 'set_packagereload';
export const SET_PACKAGERELOAD_UPDATE = 'set_packagereload_update';

export const SET_CALLERID_CUSTOMER = 'set_callerid_customer';
export const SET_CALLERID_CUSTOMER_REMOVE = 'set_callerid_customer_remove';

export const TRENDYOLCANCEL_OPTIONS = 'trendyolcancel_options';
export const MIGROSYEMEKCANCEL_OPTIONS = 'migrosyemek_options';
export const YEMEKSEPETIGLOBALCANCEL_OPTIONS = 'yemeksepetiglobalcancel_options';

export const PHONE_CHANGE_VISIBLE = 'phone_change_visible';
export const PHONE_CHANGE_VALUE = 'phone_change_value';

export const PACKAGE_BARCODE_UPDATE = 'package_barkode_update';
export const PACKAGE_BARCODE_VISIBLE = 'package_barkode_visible';
export const PACKAGE_BARCODE_DATA = 'package_barkode_data';

export const PACKAGE_GET_ADDITION_DETAIL = 'package_get_addition_detail';

export const PACKAGEFILTER_DATA_UPDATE = 'packagefilter_data_update';
export const PACKAGEFILTER_DATA_SET = 'packagefilter_data_set';
export const PACKAGEFILTER_COMPLATED_DATA_SET = 'packagefilter_complated_data_set';
export const PACKAGEFILTER_DATA_LOADING = 'packagefilter_data_loading';

export const PACKAGE_COURIER_VISIBLE = 'package_courier_visible';
export const PACKAGE_STATUS_VISIBLE = 'package_status_visible';

export const ADDITION_SPEED_PRODUCTADD = 'addition_speed_productadd';
export const MAXIJETT_DISTANCE_FEE = 'maxijett_distance_fee';
export const SET_OSM_GEOLOCATION = 'set_osm_geolocation';
export const MAXIJETT_NEW_LOCATION = 'maxijett_new_location';

export const MAXIJETT_COOKINGTIME = 'maxijett_cookingtime';
export const ADDITION_ISHOLD = 'addition_ishold';

export const ADDITION_HOLD_LIST_VISIBLE = 'addition_hold_list_visible';
export const ADDITION_HOLD_LIST_DATA = 'addition_hold_list_data';

export const HUB_CUSTOMERSCREEN_CONNECT = 'hub_customerscreen_connect';
export const CUSTOMER_SCREEN_DATA = 'customer_screen_data';
export const CUSTOMER_SCREEN_IMAGE = 'customer_screen_image';

export const ADDITION_BACK_VISIBLE = 'addition_back_visible';
export const ADDITION_BACK_DATA = 'addition_back_data';

export const ADDITION_PACKAGE_LASTORDER_VISIBLE = 'addition_package_lastorder_visible';

export const SET_PART_COURIER_ACCOUNT_VISIBLE = 'set_part_courier_account_visible';

export const PAYCELL_PAYMENT_TYPES_DATA = 'paycell_payment_types_data';
export const PAYCELL_PAYMENT_LOADING = 'paycell_payment_loading';
export const HUB_PAYCELL_STOP = 'hub_paycell_stop';

export const PACKAGE_RACK_DATA = 'package_rack_data';
export const PACKAGE_RACK_DATA_UPDATE = 'package_rack_data_update';
export const PACKAGE_RACK_UPDATE = 'package_rack_update';
export const PACKAGE_RACK_HUB_STOP = 'package_rack_hub_stop';
export const SET_PAYCELL_PROCESS = 'set_paycell_process';
export const SET_INGENICO_PROCESS = 'set_ingenico_process';

export const SET_PAYCELL_SEND_VISIBLE = 'set_paycell_send_visible';
export const SET_PAYCELL_SEND_UPDATE = 'set_paycell_send_update';

export const SET_RANKING_VISIBLE = 'set_ranking_visible';
export const SET_RANKINGNUMBER_DATA = 'set_rankingnumber_data';
export const SET_RANKINGNUMBER_CLEAR = 'set_rankingnumber_clear'

export const SET_TRACK_COUNT = 'set_track_count';

export const SET_FAST_PACKAGE_VISIBLE = 'set_fast_package_visible';
export const SET_FAST_PACKAGE_UPDATE = 'set_fast_package_update';
export const SET_FAST_PACKAGE_LOADING = 'set_fast_package_loading';

export const SET_FAST_IL = 'set_fast_il';
export const SET_FAST_ILCE = 'set_fast_ilce';
export const SET_FAST_MAHALLE = 'set_fast_mahalle';
export const SET_FAST_SOKAK = 'set_fast_sokak';

export const JAVIKURYEPOS_COURIER_LIST = 'javikuryepos_courier_list';
export const JAVIKURYEPOS_COURIER_VISIBLE = 'javikuryepos_courier_visible';

export const INGENICO_HUB_STOP = 'ingenico_hub_stop';
export const INGENICO_PAYMENT_LOADING = 'ingenico_payment_loading'
export const INGENICO_PAYMENT_SUCCESS = 'ingenico_payment_success'
export const INGENICO_PAYMENT_UPDATE = 'ingenico_payment_update'

export const INGENICO_INVOICE_VISIBLE = 'ingenico_invoice_visible';
export const INGENICO_INVOICE_UPDATE = 'ingenico_invoice_update';
export const INGENICO_INVOICE_DATA = 'ingenico_invoice_data';
export const INGENICO_INVOICE_REMOVE = 'ingenico_invoice_remove';

const TimeData = getParameter('BusinessWorkingHours');
const initialState = {
  form_data: {
    IsGlassLabelVisible: false,
    QuantityModalVisible: false,
    QuantityModalText: 1,
    PaymentModalOpen: false,
    PaymentTextModalOpen: false,
    AdditionTreatModalOpen: false,
    modalOpen: false,
    modalTitle: '',
    modalText: '',
    historyModalOpen: false,
    historyModalTitle: '',
    historyModalText: '',
    menuDetailModalOpen: false,
    menuDetailModalTitle: '',
    menuDetailModalText: '',
    alertOpen: false,
    alertTitle: '',
    alertText: '',
    alertType: 'KümüleDetailEdit',
    ProductDetailModalOpen: false,
    IsComeGetVisible: false,
    ProductDetailModalTitle: '',
    ProductDetailNumPad: false,
    ProductDetailModalText: '',
    ProductDetailId: null,
    IsKeyboardVisible: false,
    KeyboardText: '',
    ProductDetail: [],
    MenuDetailPropertyId: '',
    MenuDetailPropertyItemId: '',
    MenuDetailProperty: [],
    CourrierList: [],
    AdditionHistoryList: [],
    IsFilterVisible: false,
    AdditionEdit: false,
    AdditionListSelectedId: '',
    IsTableVisible: true,
    IsInputDialogVisible: false,
    IsInputDialogTitle: '',
    newPackage: false,
    UpdatePackageStatus: false,
    UpdatePackageCourrier: false,
    UpdatePackageStatusUserName: '',
    additionSaveEnable: false,
    customerAddressSave: false,
    addition_data: {
      Id: uuidv4(),
      CustomerId: '00000000-0000-0000-0000-000000000000',
      CustomerName: '',
      CustomerAddressId: '00000000-0000-0000-0000-000000000000',
      CustomerAddressText: '',
      TableId: '',
      TableName: '',
      AdditionId: '',
      ProductItems: [],
      ProductItemsJson: '',
      AdditionHistories: [],
      AdditionHistoriesJson: '',
      CreationTime: new Date(),
      UpdateTime: '',
      OrderType: 0,
      PackageStatus: 0,
      UserId: '',
      UserName: '',
      AdditionDescription: '',
      IsNew: true,
      Payments: [],
      AdditionPrinted: false,
      PaymentNote: '',
      IsComeGet: false,
      CustomerPhone: '',
      DeliveryTime: new Date()
    },
    AdditionHistoriesItem: {
      OperationType: '',
      CreationTime: '',
      OperationText: '',
      UserId: '',
      OperationDetailType: 0,
      ItemId: ''
    },
    numberButtonList: [
      { Name: '1', Id: 1, IsSelected: true },
      { Name: '2', Id: 2, IsSelected: false },
      { Name: '3', Id: 3, IsSelected: false },
      { Name: '4', Id: 4, IsSelected: false },
      { Name: '5', Id: 5, IsSelected: false },
      { Name: '6', Id: 6, IsSelected: false },
      { Name: '7', Id: 7, IsSelected: false },
      { Name: '8', Id: 8, IsSelected: false },
      { Name: '9', Id: 9, IsSelected: false },
      { Name: '10', Id: 10, IsSelected: false }
    ],
    printerHubConnection: null,
    callCenterHubConnection: null,
    scalesHubConnection: null,
    callerIdHubConnection: null,
    callerIdPhoneVisible: false,
    callerIdAddressData: [],

    callCenterPhone: '',
    callCenterPhoneVisible: false,
    callCenterAddressData: [],

    modalRemoveTextVisible: false,
    modalRemoveTextTitle: '',
    ProductRemoveExplanation: '',

    modalAdditionRemoveTextVisible: false,
    modalAdditionRemoveTextTitle: '',
    AdditionRemoveExplanation: '',

    additionHistoryModal: false,
    additionHistoryText: '',

    packageAllPaymentVisible: false,

    IlList: [],
    IlceList: [],
    MahalleList: [],
    SokakList: [],

    callCenterAddress: {
      CustomerId: '00000000-0000-0000-0000-000000000000',
      IsNewUser: true,
      Name: '',
      IlId: '0',
      IlText: '',
      IlceId: '0',
      IlceText: '',
      MahalleId: '0',
      MahalleText: '',
      SokakId: '0',
      SokakText: '',
      AdresDetail: '',
      AdresDescription: '',
      Phone: '',
      SecondPhone: '',
      IsSmsSend: true,
      IsEmailSend: true,
      TenantId: '',
      BrandId: '',
      BrandName: '',
      IsAddressUpdate: false,
      AddressId: '00000000-0000-0000-0000-000000000000',
      DiscountRate: 0
    },
    CustomerAddress: {
      IlId: '0',
      IlText: '',
      IlceId: '0',
      IlceText: '',
      MahalleId: '0',
      MahalleText: '',
      SokakId: '0',
      SokakText: '',
      AdresDetail: '',
      AdresDescription: '',
      Phone: '',
      SecondPhone: '',
      TenantId: '',
      BrandId: '',
      BrandName: '',
      IsAddressUpdate: false,
      AddressId: ''
    },
    AdresList: [],
    packageListModal: false,
    packageListModalTitle: '',
    CallCenterPackageList: [],
    CallCenterPackageLimit: 0,
    productItemModal: false,
    getPhoneModalVisible: false,
    getPhone: '',
    QueuesPhoneList: [],
    javiPosHelpVisible: false,
    deliveryCharge: 0,

    AdditionPayments: []
  },
  callerIdCustomer: [],
  IsAdditionListUpdate: false,
  IsDetailedPaymentVisible: false,
  IsDetailedPaymentUpdate: false,

  IsPrinterSocketNotificationUpdate: false,
  IsPrinterSocketNotificationVisible: false,
  IsPrinterSocketNotificationText: '',
  IsPrinterSocketNotificationType: 'warning',

  productListDetailUpdate: false,
  paymentLoading: false,

  detailPaymentSnackbarVisible: false,
  detailPaymentSnackbarText: '',

  repeatOrderOpen: false,
  repeatOrderList: null,
  additionBackVisible: false,
  additionBackVisibleType: '',
  packagePrintModalVisible: false,
  treatProductQuantity: 0,

  printerUpdate: false,
  scalesQuantity: 0,

  IsSeePrice: false,
  IsSalesReturn: false,

  additionWaitingUpdate: false,
  additionWaitingVisible: false,
  additionWaiting: null,
  getirCancelOptions: [],
  getirCancelOptionsVisible: false,

  trendyolCancelOptions: [],
  trendyolCancelOptionsVisible: false,

  migrosYemekCancelOptions: [],
  migrosYemekOptionsVisible: false,

  yemekSepetiGlobalCancelOptions: [],
  yemekSepetiGlobalCancelOptionsVisible: false,

  modalPhoneChangeVisible: false,
  phoneChangeControlValue: '',

  packageBarcodeUpdate: false,
  packageBarcodeVisible: false,
  barcodePackageList: {
    data: '',
    IsComplated: false,
    BrandId: '',
    RestName: '',
    BarcodeList: []
  },
  packageAdditionDetail: null,
  anchorElPackageDetail: null,
  IsPackageAdditionDetailUpdate: null,

  IsPackageFilterUpdate: false,
  IsPackageListLoad: true,
  packageFilter: {
    totalRecord: 0,
    List: [],
    PackageStatus: [],
    PackageButtonStatus: 'Pending'
  },
  packageFilterComplated: {
    totalRecord: 0,
    List: [],
    startDateTime: new Date(TimeData.start),
    endDateTime: new Date(TimeData.end),
    SearchText: '',
    Courrier: '00000000-0000-0000-0000-000000000000',
    PaymentId: '00000000-0000-0000-0000-000000000000'
  },
  IsPackageCourrierUpdate: false,
  IsPackageCourrierVisible: false,

  IsPackageStatusUpdate: false,
  IsPackageStatusVisible: false,

  IsAdditionSpeedProductAdd: false,
  distanceFee: null,
  osmGeolocation: [],
  maxiJettNewLocation: null,
  maxijettCookingTime: 10,
  additionIsHold: false,
  IsAdditionHoldListUpdate: false,
  IsAdditionHoldListVisible: false,
  AdditionHoldList: [],

  customerScreenUpdate: false,
  customerScreenHubConnection: null,
  customerScreenData: null,
  customerScreenImage: '',
  additionBackModalVisible: false,
  additionBackModalData: '',
  IsPackageLastOrderControl: false,
  IsCourierPartAccountVisible: false,

  PaycellPaymentTypes: [],
  paycellHubConnection: null,
  paycellPaymentLoading: false,
  ingenicoPaymentLoading: false,
  ingenicoTimeOut:false,

  packageRackData: [],
  packageRackDataCount: 0,
  packageRackUpdate: false,
  packageRackHubConnection: null,

  additionPaycellSendVisible: false,
  additionPaycellSendUpdate: false,

  rankingNumber: 0,
  rankingSystemVisible: false,
  rankingPaymentTypeSplit: false,

  trackCount: 0,

  IsFastPackageVisible: false,
  IsFastPackageUpdate: false,
  IsFastPackageLoading: false,

  fastIlId: "",
  fastIlceId: "",
  fastMahalleId: "",
  fastSokakId: "",

  javiKuryePosCourierList: [],
  javiKuryePosCourierListVisible: false,
  javiKuryePosCourierListUpdate: false,

  ingonicoHubConnection: null,
  ingenicoPaymentSuccess: false,
  ingenicoPaymentUpdate: false,

  incenicoInvoiceVisible: false,
  incenicoInvoiceUpdate: false,
  incenicoInvoiceData: {
    InvoiceType: 0,
    InvoiceTC: "",
    InvoiceVKN: "",
    InvoiceNo: "",
    InvoiceDate: new Date()
  }
};

export async function getNewFastPackage() {
  return async (dispatch, getState) => {
    var data = {
      BrandId: cookie.load("BrandId"),
      CenterBrandId: cookie.load("CenterBrandId"),
      TenantId: cookie.load("TenantId"),
    };
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/getNewFastPackage',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: data
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          dispatch(formDataSet('addition_data', jsonData));
        }
      })
      .catch((error) => {
      });
  };
}

export async function getTrackCount() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Settings/getTrackCount?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch({ type: SET_TRACK_COUNT, payload: Number(response.data.responseData) });
        }
      })
      .catch((error) => {
      });
  };
}

export async function setIngenicoHubConnection() {
  return async (dispatch, getState) => {

    const UserId = cookie.load('UserId');

    const ingenicoHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          }
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          return null;
        }
      })
      .withUrl(brand.urlSocket + 'ingenico?UserId=' + UserId, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    connectIngenico(ingenicoHubConnection, dispatch);

    dispatch({ type: INGENICO_HUB_STOP, payload: ingenicoHubConnection });
  };
}

export async function sendIngenico(data) {
  return async (dispatch, getState) => {
    const { ingenicoHubConnection, incenicoInvoiceData } = getState().get('additionReducer');
    var total = 0;
    data.E_SendDataProduct.map((n, i) => {
      total += n.Amount;
    })
    if (getParameter('PlugCuttingLimit') < total && (incenicoInvoiceData.InvoiceTC.length == 0 && incenicoInvoiceData.InvoiceVKN.length == 0)) {
      dispatch(setIngenicoInvoiceVisible(true));
      dispatch(setIngenicoPaymentLoading(false));
      dispatch(setModalAddition("Fiş kesme limiti aşılmıştır. Fatura bilgisi girmeniz zorunludur!"));
      data.IngenicoProcess = 3;
      return;
    } else {
      data.IngenicoProcess = (incenicoInvoiceData.InvoiceTC.length == 0 && incenicoInvoiceData.InvoiceVKN.length == 0 ? data.IngenicoProcess : 3);
    }
    data.InvoiceType = incenicoInvoiceData.InvoiceType;
    data.InvoiceTC = incenicoInvoiceData.InvoiceTC;
    data.InvoiceVKN = incenicoInvoiceData.InvoiceVKN;
    data.InvoiceNo = incenicoInvoiceData.InvoiceNo;
    data.InvoiceDate = incenicoInvoiceData.InvoiceDate;
    await dispatch(setIngenicoInvoiceData({
      InvoiceType: 0,
      InvoiceTC: "",
      InvoiceVKN: "",
      InvoiceNo: "",
      InvoiceDate: new Date()
    }))
    await ingenicoHubConnection.invoke('BroadCastMessage', 'IngenicoExeReceiveMessage', JSON.stringify(data), cookie.load('UserId')).then(() => { }).catch(err => console.error(err));
  };
}

async function connectIngenico(conn, dispatch) {
  await conn.start().then(() => {
    conn.on('IngenicoWebReceiveMessage', (message) => {
      var jsonData = JSON.parse(message);
      if (jsonData.IsSuccess && !jsonData.IsCancel) {
        dispatch({ type: SET_INGENICO_PROCESS, payload: jsonData, dispatch: dispatch });
        dispatch(setIngenicoPaymentLoading(false));
        if (jsonData.IsPaymentComplate) {
          dispatch(setDetailedPaymentVisible(false));
          const selfsales = getParameter('SelfSales');
          if (!selfsales) {
            dispatch(sendMessage()).then(() => {
              dispatch(push('/app?' + Math.random()));
            });
          }
        } else {
          dispatch(getAddition(jsonData.OrderId));
        }
      } else if (!jsonData.IsSuccess && !jsonData.IsCancel) {
        dispatch({ type: INGENICO_PAYMENT_SUCCESS, payload: true, timeout:(jsonData.RetCode == 61443 ? true : false), });
      } else if (jsonData.IsCancel) {
        dispatch({ type: SET_INGENICO_PROCESS, payload: jsonData, dispatch: dispatch });
      }
    });
  }).catch(e => {
    sleepIngenico(3000);
    connectIngenico(conn, dispatch);
  }
  );
}

async function sleepIngenico(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

// PackageRack

export async function setPackageRackHubConnection() {
  return async (dispatch, getState) => {

    const BrandId = cookie.load('BrandId');

    const packageRackHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          }
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          return null;
        }
      })
      .withUrl(brand.urlSocket + 'packagerack?BrandId=' + BrandId, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    connectPackageRack(packageRackHubConnection, dispatch);

    dispatch({ type: PACKAGE_RACK_HUB_STOP, payload: packageRackHubConnection });
  };
}

async function connectPackageRack(conn, dispatch) {
  await conn.start().then(() => {
    conn.on('PackageRackSendMessage', (message) => {
      var data = JSON.parse(message);
      dispatch({ type: PACKAGE_RACK_DATA_UPDATE, payload: data.rackList, count: data.rackCount });
    });
  }).catch(e => {
    sleepPackageRack(3000);
    connectPackageRack(conn, dispatch);
  }
  );
}

async function sleepPackageRack(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

export async function getPackageRackOrderControl() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Package/getPackageRackOrderControl?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          dispatch({ type: PACKAGE_RACK_DATA, payload: jsonData.rackList, count: jsonData.rackCount });
        }
      })
      .catch((error) => {
      });
  };
}

export async function getPackageRackList() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Package/getPackageRackList?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          dispatch({ type: PACKAGE_RACK_DATA, payload: jsonData.rackList, count: jsonData.rackCount });
        }
      })
      .catch((error) => {
      });
  };
}

export async function setRackClear(Id) {
  return async (dispatch, getState) => {
    const { packageRackData, packageRackDataCount } = getState().get('additionReducer');
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Package/setRackClear?Id=' + Id,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getPackageRackList());
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
      });
  };
}
//

//Paycell Start
export async function setPaycellHubConnection() {
  return async (dispatch, getState) => {
    const { printerHubConnection } = getState().get('additionReducer').form_data;

    const UserId = cookie.load('UserId');

    const paycellHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          }
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          return null;
        }
      })
      .withUrl(brand.urlSocket + 'paycell?UserId=' + UserId, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    connectPaycell(paycellHubConnection, dispatch, printerHubConnection);

    dispatch({ type: HUB_PAYCELL_STOP, payload: paycellHubConnection });
  };
}

async function connectPaycell(conn, dispatch, printerHubConnection) {
  await conn.start().then(() => {
    conn.on('SendMessagePaymentStatus', (message) => {
      var jsonData = JSON.parse(message);
      dispatch({ type: SET_PAYCELL_PROCESS, payload: jsonData, dispatch: dispatch });
      if (jsonData.status == "SUCCESS") {
        if (jsonData.is_closed) {
          dispatch(setDetailedPaymentVisible(false));
          if (getParameter('Paycell').IsInformationSlip) {
            workerTimers.setTimeout(() => {
              dispatch(setPaycellPrint(jsonData.order_code));
            }, 3000);
          }
          const selfsales = getParameter('SelfSales');
          if (!selfsales) {
            dispatch(push('/app?' + Math.random()));
          }
        } else {
          dispatch(getAddition(jsonData.order_code));
        }
      } else if (jsonData.status == "CANCEL") {
        dispatch(getAddition(jsonData.order_code));
      }
    });
  }).catch(e => {
    sleepPaycell(3000);
    connectPaycell(conn, dispatch, printerHubConnection);
  }
  );
}

async function sleepPaycell(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

export async function getPaycellPaymentTypes() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Entegration/getPaycellPaymentTypes?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          dispatch({ type: PAYCELL_PAYMENT_TYPES_DATA, payload: jsonData });
        }
      })
      .catch((error) => {
      });
  };
}

export async function setPaycellOrderCancel(OrderId, PaycellTransactionId = "") {
  return async (dispatch, getState) => {
    const { AdditionPayments } = getState().get('additionReducer').form_data;

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Entegration/setPaycellOrderCancel?OrderId=' + OrderId + '&PaycellTransactionId=' + PaycellTransactionId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(setPaycellPaymentLoading(false));
          AdditionPayments.find(x => x.Id == PaycellTransactionId).IsDelete = true;
          dispatch(formDataSet('AdditionPayments', AdditionPayments));
        } else {
          dispatch(setPaycellPaymentLoading(false));
          const json = JSON.parse(response.data.responseData);
          dispatch(setModalAddition(json.message));
        }

      })
      .catch((error) => {
      });
  };
}

export async function setPaycellPrint(OrderId) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Entegration/setPaycellPrint?OrderId=' + OrderId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {

      })
      .catch((error) => {
      });
  };
}

export async function setPaycellOrderCreate(OrderId, serial_no = "") {
  return async (dispatch, getState) => {
    const { addition_data } = getState().get('additionReducer').form_data;
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Entegration/setPaycellOrderCreate?OrderId=' + OrderId + '&serial_no=' + serial_no,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          addition_data.IsPaycellSend = true;
          dispatch(formDataSet('addition_data', addition_data));
          if (serial_no != "") {
            dispatch(setModalAddition("Sipariş cihaza yönlendirilmiştir."));
            dispatch(setAdditionPaycellSendVisible(false));
          }
        }
      })
      .catch((error) => {
      });
  };
}

export async function setPaycellOrderStart(OrderId, payment_type, payment_method, total_amount, productList, invoice_status) {
  return async (dispatch, getState) => {
    var data = {
      "OrderId": OrderId,
      "UserId": cookie.load('UserId'),
      "UserName": cookie.load('NameSurname'),
      "invoice_status": invoice_status,
      "is_partial": true,
      "total_amount": total_amount,
      "payment_type": payment_type,
      "payment_method": payment_method,
      "product": productList
    };
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Entegration/setPaycellOrderStart',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: data
    })
      .then((response) => {
        dispatch(setPaycellPaymentLoading(true));
      })
      .catch((error) => {
      });
  };
}

//Paycell End

export async function getCustomerScreenImageUrl(UserId) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerScreenImageUrl?UserId=' + UserId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        dispatch({ type: CUSTOMER_SCREEN_IMAGE, payload: response.data.responseData });
      })
      .catch((error) => {
        // dispatch(setModalAddition(error));
      });
  };
}

export async function setCustomerScreenHubConnection(UserId) {
  return async (dispatch, getState) => {
    const customerScreenHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          }
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          return null;
        }
      })
      .withUrl(brand.urlSocket + 'customerscreen?UserId=' + UserId, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    connectCustomerScreen(customerScreenHubConnection, dispatch);

    dispatch({ type: HUB_CUSTOMERSCREEN_CONNECT, payload: customerScreenHubConnection });
  };
}

async function connectCustomerScreen(conn, dispatch) {
  await conn.start().then(() => {
    conn.on('GetAllMessageCustomerScreen', (message) => {
      const json = JSON.parse(message);
      dispatch({ type: CUSTOMER_SCREEN_DATA, payload: json });
    });
  }).catch(e => {
    sleepCustomerScreen(3000);
    connectCustomerScreen(conn, dispatch);
  });
}

async function sleepCustomerScreen(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

export async function addAdditionPaymentsDiscount(PaymentId, PaymentName, Price, PaymentType, Product) {
  return async (dispatch, getState) => {
    const { AdditionPayments, callCenterAddress, addition_data } = getState().get('additionReducer').form_data;
    let discount = 0;
    const dis = addition_data.DiscountRate;

    if (dis > 0) {
      discount = ((Price * dis) / 100);
      Price -= discount;
    }
    if (AdditionPayments.find(x => x.CustomerId == addition_data.CustomerId && x.PaymentType == 2 && x.IsDelete == false) != undefined) {
      AdditionPayments.find(x => x.CustomerId == addition_data.CustomerId && x.PaymentType == 2 && x.IsDelete == false).Price = discount;
    } else if (dis > 0) {
      const data = {
        Id: uuidv4(),
        PaymentId: '00000000-0000-0000-0000-000000000000',
        PaymentName: '',
        Price: discount,
        CreationDatetime: new Date(),
        PaymentType: 2,
        IsDelete: false,
        PaymentProduct: [],
        CustomerId: addition_data.CustomerId,
        CustomerName: addition_data.CustomerName
      };
      AdditionPayments.push(data);
    }
    if (addition_data.IsNew) {
      if (dis > 0) {
        addition_data.Payments = AdditionPayments;
        dispatch(formDataSet('addition_data', addition_data));
      }
    }
    if (dis > 0) {
      dispatch(formDataSet('AdditionPayments', AdditionPayments));
    }
  };
}

export async function addAdditionPayments(PaymentId, PaymentName, Price, PaymentType, Product, Customer) {
  return async (dispatch, getState) => {
    const { AdditionPayments, callCenterAddress } = getState().get('additionReducer').form_data;
    let discount = 0;
    if (PaymentId == '58d99ca4-e5f6-49e8-80a4-a4936c181b8a') {
      var dis = (callCenterAddress.DiscountRate > Customer.DiscountRate ? callCenterAddress.DiscountRate : Customer.DiscountRate);
      if (dis > 0) {
        discount = ((Price * dis) / 100);
        Price -= discount;
      }
    }
    const guidId = uuidv4();
    var data = {
      Id: guidId,
      PaymentId,
      PaymentName,
      Price,
      CreationDatetime: new Date(),
      PaymentType: (Customer == null ? PaymentType : 3),
      IsDelete: false,
      PaymentProduct: Product,
      CustomerId: (Customer == null ? '00000000-0000-0000-0000-000000000000' : Customer.Id),
      CustomerName: (Customer == null ? '' : Customer.Name)
    };
    AdditionPayments.push(data);
    if (dis > 0) {
      if (AdditionPayments.find(x => x.CustomerId == Customer.Id && x.PaymentType == 2 && x.IsDelete == false) != undefined) {
        const disco = AdditionPayments.find(x => x.CustomerId == Customer.Id && x.PaymentType == 2 && x.IsDelete == false).Price;
        AdditionPayments.find(x => x.CustomerId == Customer.Id && x.PaymentType == 2 && x.IsDelete == false).Price = disco + discount;
      } else {
        var data = {
          Id: uuidv4(),
          PaymentId: '00000000-0000-0000-0000-000000000000',
          PaymentName: '',
          Price: discount,
          CreationDatetime: new Date(),
          PaymentType: 2,
          IsDelete: false,
          PaymentProduct: [],
          CustomerId: Customer.Id,
          CustomerName: Customer.Name,
          ItemId: guidId
        };
        AdditionPayments.push(data);
      }
    }
    dispatch(formDataSet('AdditionPayments', AdditionPayments));
  };
}

export async function setCalculateDeliveryCharge() {
  return async (dispatch, getState) => {
    const { addition_data } = getState().get('additionReducer').form_data;
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Address/setCalculateDeliveryCharge?CustomerAddressId=' + addition_data.CustomerAddressId + '&BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        addition_data.ProductItems.push(json.ProductList);
        dispatch(formDataSet('addition_data', addition_data));
      })
      .catch((error) => {
        // dispatch(setModalAddition(error));
      });
  };
}

export async function setCallerIdHubConnection() {
  return async (dispatch, getState) => {
    dispatch({ type: ADDITION_START });

    const UserName = cookie.load('UserId');
    const GroupName = cookie.load('BrandId');

    const callerIdHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          }
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          return null;
        }
      })
      .withUrl(brand.urlSocket + 'callerid?UserName=' + UserName + '&GroupName=' + GroupName + '&UserId=' + UserName + '', {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    connectCallerId(callerIdHubConnection, dispatch);

    dispatch({ type: HUBCALLERID_STOP, payload: callerIdHubConnection });
  };
}

export async function setScalesHubConnection() {
  return async (dispatch, getState) => {
    dispatch({ type: ADDITION_START });

    const UserId = cookie.load('UserId');
    const BrandId = cookie.load('BrandId');

    const scalesHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          }
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          return null;
        }
      })
      .withUrl(brand.urlSocket + 'scales?BrandId=' + BrandId + '&UserId=' + UserId, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    connectScales(scalesHubConnection, dispatch);

    dispatch({ type: HUBSCALES_STOP, payload: scalesHubConnection });
  };
}

export async function setCustomerAddress() {
  return async (dispatch, getState) => {
    const { callCenterAddress, callCenterPhone, addition_data } = getState().get('additionReducer').form_data;
    const adresdata = {
      CustomerId: callCenterAddress.CustomerId,
      IsNewUser: callCenterAddress.IsNewUser,
      IsAddressUpdate: callCenterAddress.IsAddressUpdate,
      Name: callCenterAddress.Name,
      IlId: callCenterAddress.IlId,
      IlceId: callCenterAddress.IlceId,
      MahalleId: callCenterAddress.MahalleId.length == 0 ? '0' : callCenterAddress.MahalleId,
      SokakId: callCenterAddress.SokakId.length == 0 ? '0' : callCenterAddress.SokakId,
      AdresDetail: callCenterAddress.AdresDetail,
      AdresDescription: callCenterAddress.AdresDescription,
      Phone: (cookie.load('UserType') == 'CallCenter' ? callCenterPhone : (callCenterAddress.Phone == '' ? callCenterPhone : callCenterAddress.Phone)),
      SecondPhone: callCenterAddress.SecondPhone,
      IsSmsSend: callCenterAddress.IsSmsSend,
      IsEmailSend: callCenterAddress.IsEmailSend,
      TenantId: cookie.load('TenantId'),
      BrandId: (cookie.load('UserType') == 'CallCenter' ? callCenterAddress.BrandId : cookie.load('BrandId')),
      AddressId: callCenterAddress.AddressId,
      DiscountRate: (callCenterAddress.DiscountRate.length == 0 ? 0 : callCenterAddress.DiscountRate)
    };

    if (adresdata.IsNewUser) {
      adresdata.CustomerId = uuidv4();
    }

    const aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Customer/setCustomerAddress',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: adresdata,
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          addition_data.DiscountRate = callCenterAddress.DiscountRate;
          dispatch(formDataSet('addition_data', addition_data));
          dispatch(formDataSet('AdresList', json));
          dispatch(formDataSet('customerAddressSave', true));
        } else {
          dispatch(setModalAddition(response.data.responseData));
          dispatch(formDataSet('customerAddressSave', false));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

async function connectScales(conn, dispatch) {
  await conn.start().then(() => {
    conn.on('GetAllMessageScales', (message) => {
      dispatch({ type: SCALES_QUANTITY, payload: message });
    });
  }).catch(e => {
    sleepScales(3000);
    connectScales(conn, dispatch);
  }
  );
}

async function connectCallerId(conn, dispatch) {
  await conn.start().then(() => {
    conn.on('GetAllMessageCallerId', (message) => {
      const json = JSON.parse(message);
      if (json.UserId == cookie.load('UserId')) {
        const phone = json.Phone;
        dispatch(getCallerIdPhoneControl(phone));
        dispatch(formDataSet('callCenterPhone', phone));
      }
    });
  }).catch(e => {
    sleepCallerId(3000);
    connectCallerId(conn, dispatch);
  }
  );
}

async function sleepCallerId(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}


async function sleepScales(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

export async function getCallCenterPhoneList() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CallCenter/getCallCenterPhoneList',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSet('QueuesPhoneList', json.queues[0].callers));
        } else {
          // dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        // dispatch(setModalAddition(error));
      });
  };
}

export async function setCallerIdData(data) {
  return async (dispatch, getState) => {
    await dispatch(formDataSet('callCenterPhone', data.Phone));
    await dispatch(formDataSet('callCenterAddressData', data));
    await dispatch(formDataSet('AdresList', data.CustomerAddress));
  };
}

export async function getCallerIdPhoneControl(phone) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerPhoneControlCallerId?phone=' + phone + '&BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          json.Phone = phone;

          dispatch(setCallerIdCustomer(json));

          dispatch(formDataSet('callerIdPhoneVisible', true));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function setTableCustomerAddress() {
  return async (dispatch, getState) => {
    const { CustomerAddress, customerSelected } = getState().get('additionReducer').form_data;
    const adresdata = {
      CustomerId: customerSelected.Id,
      IsAddressUpdate: CustomerAddress.IsAddressUpdate,
      IlId: CustomerAddress.IlId,
      IlceId: CustomerAddress.IlceId,
      MahalleId: CustomerAddress.MahalleId,
      SokakId: CustomerAddress.SokakId,
      AdresDetail: CustomerAddress.AdresDetail,
      AdresDescription: CustomerAddress.AdresDescription,
      Phone: CustomerAddress.Phone,
      SecondPhone: CustomerAddress.SecondPhone,
      TenantId: cookie.load('TenantId'),
      BrandId: cookie.load('BrandId'),
      AddressId: CustomerAddress.AddressId
    };

    if (!adresdata.IsAddressUpdate) {
      adresdata.AddressId = '00000000-0000-0000-0000-000000000000';
    }

    const aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Customer/setTableCustomerAddress',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: adresdata,
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSet('AdresList', json));
          dispatch(formDataSet('customerAddressNew', false));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function getCustomerNewAddress() {
  return async (dispatch, getState) => {
    const { callCenterAddressData } = getState().get('additionReducer').form_data;
    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerNewAddress?Id=' + callCenterAddressData.CustomerId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          json.IsAddressUpdate = false;
          json.IsNewUser = false;
          // json.Phone = callCenterPhone;
          dispatch(getAddressList('Il', ''));
          dispatch(formDataSet('callCenterAddress', json));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function callCenterPackageList() {
  return async (dispatch, getState) => {
    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Addition/callCenterPackageList?TenantId=' + cookie.load('TenantId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSet('CallCenterPackageList', json));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function getTableCustomerAddress(Id) {
  return async (dispatch, getState) => {
    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerAddress?Id=' + Id + '&BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSet('CustomerAddress', json));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function getCustomerAddress(Id, BrandId) {
  return async (dispatch, getState) => {
    const { AdresList } = getState().get('additionReducer').form_data;
    //let BrandId = cookie.load('BrandId');
    if (cookie.load('UserType') == 'CallCenter') {
      //BrandId = AdresList[0].BrandId;
    }

    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerAddress?Id=' + Id + '&BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSet('callCenterAddress', json));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function removeCustomerAddress(Id) {
  return async (dispatch, getState) => {
    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/removeCustomerAddress?Id=' + Id + '&TenantId' + cookie.load('TenantId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSet('AdresList', json));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function getCustomerAllAddress(Id) {
  return async (dispatch, getState) => {
    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerAddressList?Id=' + Id + '&BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSet('callCenterAddressData', json));
          dispatch(formDataSet('AdresList', json.CustomerAddress));
        } else {
          dispatch(setModalAddition(response.data.responseData));
          dispatch(formDataSet('callCenterAddressData', []));
          dispatch(formDataSet('AdresList', []));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function getBrand(mahalleId) {
  return async (dispatch, getState) => {
    const { callCenterAddress } = getState().get('additionReducer').form_data;
    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Address/getBrand?mahalleId=' + mahalleId + '&TenantId=' + cookie.load('TenantId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          callCenterAddress.BrandId = json.Id;
          callCenterAddress.BrandName = json.BrandName;
          dispatch(formDataSet('callCenterAddress', callCenterAddress));
        } else {
          callCenterAddress.BrandId = '';
          callCenterAddress.MahalleId = '';
          callCenterAddress.MahalleText = '';
          callCenterAddress.SokakId = '';
          callCenterAddress.SokakText = '';

          dispatch(formDataSet('callCenterAddress', callCenterAddress));
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function getAddressList(type, Id) {
  return async (dispatch, getState) => {
    const url_text = (type == 'Il' ? 'getIlList' : (type == 'Ilce' ? 'getIlceList' : (type == 'Mahalle' ? 'getMahalleList' : (type == 'Sokak' ? 'getSokakList' : ''))));
    const url_query = (type == 'Il' ? '' : (type == 'Ilce' ? '?ilId=' + Id : (type == 'Mahalle' ? '?ilceId=' + Id : (type == 'Sokak' ? '?mahalleId=' + Id : ''))));
    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Address/' + url_text + url_query,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          if (type == 'Il') {
            dispatch(formDataSet('IlList', json));
          } else if (type == 'Ilce') {
            dispatch(formDataSet('IlceList', json));
          } else if (type == 'Mahalle') {
            dispatch(formDataSet('MahalleList', json));
          } else if (type == 'Sokak') {
            dispatch(formDataSet('SokakList', json));
          }
        }
      })
      .catch((error) => {

      });
  };
}

export async function setCallCenterHubConnection() {
  return async (dispatch, getState) => {
    dispatch({ type: ADDITION_START });

    const UserName = uuidv4();
    const GroupName = cookie.load('CenterBrandId');

    const callCenterHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          }
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          return null;
        }
      })
      .withUrl(brand.urlSocket + 'callcenter?UserName=' + UserName + '&GroupName=' + GroupName + '&UserId=' + UserName + '', {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    connectCallCenter(callCenterHubConnection, dispatch);

    dispatch({ type: HUBCALLCENTER_STOP, payload: callCenterHubConnection });
  };
}

async function connectCallCenter(conn, dispatch) {
  await conn.start().then(() => {
    conn.on('GetAllMessage', (message) => {
      if (message.split(',')[0] == cookie.load('UserId')) {
        const phone = message.split(',')[1];
        dispatch(getPhoneControl(phone));
        dispatch(formDataSet('callCenterPhone', phone));
      }
    });
  }).catch(e => {
    sleepCallCenter(3000);
    connectCallCenter(conn);
  }
  );
}

async function sleepCallCenter(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

export async function getPhoneControl(phone, IsFast = false) {
  return async (dispatch, getState) => {
    const { addition_data } = getState().get('additionReducer').form_data;
    if (IsFast) {
      await dispatch({ type: SET_FAST_PACKAGE_LOADING, payload: true });
    }
    phone = phone.trim().replaceAll(" ", "");

    const response = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerPhoneControl?phone=' + phone + '&BrandId=' + cookie.load('CenterBrandId') + '&TenantId=' + cookie.load('TenantId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => response)
      .catch((error) => {
        dispatch(setModalAddition(error));
      });

    if (response) {
      (async () => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          if (!IsFast) {
            dispatch(formDataSet('callCenterAddressData', json));
            dispatch(formDataSet('AdresList', json.CustomerAddress));
            dispatch(formDataSet('callCenterPhoneVisible', true));
          } else {
            if (json.CustomerAddress != null) {
              addition_data.SokakId = json.CustomerAddress[0].Id.toString();
              addition_data.CustomerName = json.CustomerName;
              addition_data.CustomerId = json.CustomerId;
              addition_data.CustomerAddressId = json.CustomerAddress[0].AddressId;
              addition_data.PaycellTransactionId = json.CustomerAddress[0].AddressText;
              await dispatch(getAddressList('Il', ''))
              await dispatch(getAddressList('Ilce', json.CustomerAddress[0].IlId))
              await dispatch(getAddressList('Mahalle', json.CustomerAddress[0].IlceId))
              await dispatch(getAddressList('Sokak', json.CustomerAddress[0].MahalleId))
              await dispatch(setFastRegionSelect(json.CustomerAddress[0].IlId.toString(), 0));
              await dispatch(setFastRegionSelect(json.CustomerAddress[0].IlceId.toString(), 1));
              await dispatch(setFastRegionSelect(json.CustomerAddress[0].MahalleId.toString(), 2));
              await dispatch(setFastRegionSelect(json.CustomerAddress[0].Id.toString(), 3));
              await dispatch(formDataSet('addition_data', addition_data));
            }
          }
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })();
      if (IsFast) {
        await dispatch({ type: SET_FAST_PACKAGE_LOADING, payload: false });
      }
    }
  };
}

export async function setPrinterHubConnection() {
  return async (dispatch, getState) => {
    dispatch({ type: ADDITION_START });

    const UserName = cookie.load('UserId');
    const GroupName = cookie.load('CenterBrandId');

    const printerHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          }
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          return null;
        }
      })
      .withUrl(brand.urlSocket + 'printer?UserName=' + UserName + '&GroupName=' + GroupName + '&UserId=' + UserName + '', {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    printerHubConnection.onreconnecting((error) => {
      dispatch(setPrinterSocketNotificationVisible(true, 'Bağlantı sağlanamadı. Yeniden Bağlanılıyor...', 'warning'));
    });

    printerHubConnection.onreconnected((connectionId) => {
      dispatch(setPrinterSocketNotificationVisible(false, 'Bağlantı sağlanamadı. Yeniden Bağlanılıyor...', 'warning'));
    });

    printerHubConnection.onclose((error) => {
      dispatch(setPrinterSocketNotificationVisible(true, 'Bağlantı sağlanamadı. Yeniden bağlanmak için', 'error'));
    });

    connect(printerHubConnection, dispatch);

    dispatch({ type: HUB_STOP, payload: printerHubConnection });
  };
}


async function connect(conn, dispatch) {
  await conn.start().then(() => {
    conn.on('IsPrinterStatusMessages', (message) => {
      let duration = 2000;
      const text = JSON.parse(message);
      if (text.UserId == cookie.load('UserId')) {
        if (text.errorText != '') {
          type = 'danger';
          messageText = text.errorText;
          duration = 5000;
        } else {
          var messageText = (text.Type == 'Addition' ? 'Hesap' : (text.Type == 'Kitchen' ? 'Mutfak' : 'Paket')) + ' Fişi Yazdırma Başarılı';
          var type = 'success';

          if (text.Type == 'Kitchen') {
            if (!text.IsKitchenRepeat) {
              messageText = 'Mutfak Fişi daha önce yazdırılmıştır!';
              type = 'warning';
            }
            if (getParameter('ReturnTables')) {
              dispatch(getAddition(text.Id));
            }
          }
        }

        store.add({
          id: uuidv4(),
          title: 'Yazdırma Uyarısı',
          message: messageText,
          type,
          container: 'bottom-right',
          insert: 'bottom',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'faster', 'fadeOut'],
          slidingEnter: {
            duration: 300,
            timingFunction: 'linear',
            delay: 0
          },
          slidingExit: {
            duration: 300,
            timingFunction: 'linear',
            delay: 0
          },
          touchRevert: {
            duration: 600,
            timingFunction: 'linear',
            delay: 0
          },
          touchSlidingExit: {
            swipe: {
              duration: 600,
              timingFunction: 'linear',
              delay: 0
            },
            alpha: {
              duration: 300,
              timingFunction: 'linear',
              delay: 0
            }
          },
          dismiss: {
            duration,
            onScreen: !1,
            pauseOnHover: !0,
            waitForAnimation: !1,
            showIcon: !0,
            click: !0,
            touch: !0
          },
          onRemoval: (id, removedBy) => {

          }
        });
      }

      dispatch(packageReloadUpdate([{ Id: text.Id, TableName: 'PrintedUpdate' }]));
    });

    conn.on('GetAllMessage', (message) => {
      const LocalPrinterSystem = getParameter('LocalPrinterSystem');
      var jsonData = JSON.parse(message);
      if (LocalPrinterSystem.UserId == cookie.load('UserId') && LocalPrinterSystem.IsPrinterSystem == true) {
        dispatch(setLocalPrintingSystem(jsonData));
      }
    });
  }).catch(e => {
    sleepPrinter(3000);
    connect(conn, dispatch);
  }
  );
}

async function sleepPrinter(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

export async function sendCustomerScreenMessage(IsClear = false) {
  return async (dispatch, getState) => {
    if (getParameter('IsCustomerScreen')) {
      const { addition_data } = getState().get('additionReducer').form_data;
      const UserId = cookie.load('UserId');

      const jsonData = JSON.parse(JSON.stringify(addition_data));

      jsonData.ProductItems.filter(x => x.IsTreat == false && x.IsDelete == false).map((n, i) => {
        n.Property.map(x => {
          x.Item.map(y => {
            if (!y.Default) {
              x.Item.splice(x.Item.findIndex(item => item.ItemId === y.Id), 1);
            }
          });
        });

        n.Menu.map(t => {
          t.Item.map(k => {
            if (!k.Default) {
              t.Item.splice(t.Item.findIndex(item => item.ItemId === k.Id), 1);
            }
          });
        });
      });

      const sendData = {
        Payments: jsonData.Payments,
        ProductItems: jsonData.ProductItems
      };

      if (IsClear) {
        sendData.Payments = [];
        sendData.ProductItems = [];
      }

      await axios({
        method: 'POST',
        url: brand.urlSocket + 'api/Customer/sendCustomerScreenMessage?UserId=' + UserId,
        dataType: 'json',
        headers: {
          Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
        },
        data: sendData,
      })
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
}

export async function sendMessagePrinter(printerType, product = '') {
  return async (dispatch, getState) => {
    const { printerHubConnection, addition_data } = getState().get('additionReducer').form_data;
    const BrandId = cookie.load('BrandId');
    const GroupName = cookie.load('CenterBrandId');
    const LocalPrinterSystem = getParameter('LocalPrinterSystem');
    let data = {};
    if (printerType == 3) {
      var pro = {
        "Barcode": product.Barcode,
        "ProductName": product.Name,
        "UnitName": product.UnitName,
        "Price": product.Price,
      };
      data = {
        'BrandId': BrandId,
        'printerType': printerType,
        'ShelfLabel': JSON.stringify(pro)
      };
    } else {
      data = {
        'BrandId': BrandId,
        'printerType': printerType,
        'AdditionId': addition_data.Id,
        'UserId': cookie.load('UserId')
      };
    }

    if (printerHubConnection.state == HubConnectionState.Disconnected) {
      await connect(printerHubConnection, dispatch);
      dispatch({ type: HUB_STOP, payload: printerHubConnection });
    } else if (printerHubConnection.state == HubConnectionState.Reconnecting) {
      await connect(printerHubConnection, dispatch);
      dispatch({ type: HUB_STOP, payload: printerHubConnection });
    }

    await dispatch(setPrinterUpdate(true));
    if (LocalPrinterSystem.UserId == cookie.load('UserId') & LocalPrinterSystem.IsPrinterSystem == true) {
      await dispatch(setLocalPrintingSystem(data));
    } else {
      await printerHubConnection.invoke('BroadCastMessage', JSON.stringify(data), GroupName).then(() => { }).catch(err => console.error(err));
    }
  };
}

export async function sendMessagePackagePrinter(printerHubConnection, printerType, Id, BrandId) {
  return async (dispatch, getState) => {
    const LocalPrinterSystem = getParameter('LocalPrinterSystem');

    const data = {
      'BrandId': BrandId,
      'printerType': printerType,
      'AdditionId': Id,
      'UserId': cookie.load('UserId')
    };

    if (printerHubConnection.state == HubConnectionState.Disconnected) {
      await connect(printerHubConnection, dispatch);
      dispatch({ type: HUB_STOP, payload: printerHubConnection });
    } else if (printerHubConnection.state == HubConnectionState.Reconnecting) {
      await connect(printerHubConnection, dispatch);
      dispatch({ type: HUB_STOP, payload: printerHubConnection });
    }

    if (LocalPrinterSystem.UserId == cookie.load('UserId') & LocalPrinterSystem.IsPrinterSystem == true) {
      await dispatch(setLocalPrintingSystem(data));
    } else {
      await printerHubConnection.invoke('BroadCastMessage', JSON.stringify(data), cookie.load('CenterBrandId')).then(() => { }).catch(err => console.error(err));
    }
  };
}

export async function setLocalPrintingSystem(printerData) {
  return async (dispatch, getState) => {
    await axios({
      method: 'POST',
      url: 'https://testapi.javipos.com/api/Printer/setLocalPrintingSystem',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: printerData
    })
      .then((response) => {
        if (getParameter('IsKitchenScreen') && cookie.load('BrandId') == printerData.BrandId) {
          dispatch(setKitchenScreenData(printerData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setKitchenScreenData(printerData) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Kitchen/setKitchenScreenData?CenterBrandId=' + cookie.load('CenterBrandId') + '&Id=' + printerData.AdditionId + '&printerType=' + printerData.printerType,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
      })
      .catch((error) => {
      });
  };
}

export async function getAddition(additionId) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Addition/GetAddition?Id=' + additionId + '&IsWeb=true',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          json.Payments = (json.Payments == null ? [] : json.Payments);
          if (json.ProductItems.length != 0) {
            json.ProductItems.map((n, i) => {
              n.IsAddNew = false;
            });
          }
          dispatch(getAdditionData(json));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setRemoveAddition(additionId, text) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Addition/setRemoveAddition?Id=' + additionId + '&text=' + text + '&UserId' + cookie.load('UserId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {

      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getAdditionHistory(additionId) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Addition/getAdditionHistory?Id=' + additionId + '',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSet('AdditionHistoryList', json));
        } else {
          dispatch(formDataSet('AdditionHistoryList', []));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setPackageStatus(status) {
  return async (dispatch, getState) => {
    const { packageFilter } = getState().get('additionReducer');

    const dataList = [];
    packageFilter.List.filter(x => x.IsSelected == true).map((n, i) => {
      dataList.push({
        Id: n.Id,
        Status: status.Status,
        UserId: cookie.load('UserId'),
        RestName: cookie.load('RestaurantName'),
        GetirCancelText: status.GetirCancelText,
        GetirCancelId: status.GetirCancelId,
        IsStatus: false,
        ResponseData: ''
      });
    });

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Package/setPackageStatusList',
      dataType: 'json',
      headers: {
        'X-ClientId': cookie.load('UserId'),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: dataList,
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          notificationShow(json, 5000);
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
      });
  };
}

export async function setPackageCourrier(packageUserId, packageUserName) {
  return async (dispatch, getState) => {
    const { packageFilter } = getState().get('additionReducer');

    const dataList = [];
    packageFilter.List.filter(x => x.IsSelected == true).map((n, i) => {
      dataList.push({
        Id: n.Id,
        PackageUserId: packageUserId,
        PackageUserName: packageUserName,
        UserId: cookie.load('UserId'),
        IsStatus: false,
        ResponseData: ''
      });
    });

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Package/setPackageCourrierList',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: dataList,
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          notificationShow(json, 5000);
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getPackageBarcodeControl(data, packageFilter) {
  return async (dispatch, getState) => {
    data.BrandId = cookie.load('BrandId');
    data.UserId = cookie.load('UserId');

    const config = {
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/getPackageBarcodeControl',
      headers: {
        'X-ClientId': cookie.load('BrandId'),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
        'Content-Type': 'application/json'
      },
      maxRedirects: 0,
      data
    };

    axios(config)
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          dispatch(setPackageBarcodeData(jsonData));
          if (jsonData.IsComplated) {
            dispatch(filterPackageAddition(packageFilter));
          }
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log('barkod okutma', error.response.data);
      });
  };
}

export async function setAdditionSave(print = false, isFast = false) {
  return async (dispatch, getState) => {
    const { addition_data, printerHubConnection, newPackage } = getState().get('additionReducer').form_data;

    const addition = JSON.parse(JSON.stringify(addition_data));
    let date = new Date();
    if (!addition.IsNew) {
      date = new Date(addition.CreationTime);
    }

    addition.TenantId = cookie.load('TenantId');
    addition.ProductItemsJson = JSON.stringify(addition.ProductItems);
    addition.AdditionHistoriesJson = JSON.stringify(addition.AdditionHistories);
    addition.PaymentsJson = JSON.stringify(addition.Payments);

    addition.UserId = cookie.load('UserId');
    addition.IsHold = (cookie.load('IsIngenico') === 'true' ? true : false);
    addition.UserName = cookie.load('NameSurname');
    addition.ProductItems = null;
    addition.AdditionHistories = null;
    addition.Payments = null;
    addition.UpdateTime = date;
    addition.CreationTime = date;
    addition.DeliveryTime = (date < new Date(addition.DeliveryTime) ? new Date(addition.DeliveryTime) : date);

    if (addition.OrderType == 1) {
      addition.PackageStatus = 1;
    }

    if (cookie.load('UserType') == 'CallCenter') {
      addition.OrderType = 1;
      addition.PackageStatus = 1;
    }

    if (cookie.load('UserType') == 'CallCenter' && !addition.IsNew) {
      addition.BrandId = addition.BrandId;
    } else {
      addition.BrandId = cookie.load('BrandId');
    }

    const responseData = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/SetAddition',
      dataType: 'json',
      headers: {
        'X-ClientId': cookie.load('UserId'),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
        'Content-Type': 'application/json'
      },
      data: addition,
    })
      .then((response) => response)
      .catch((error) => {
        if(error.response.status == 429){
          console.log(error.response)
          dispatch(setModalAddition(error.response.data.details));
        }
        dispatch(setFastPackageVisible(false));
        dispatch(setFastPackageLoading(false));
        return null;
      });

    if (responseData != null) {
      (async () => {
        await sleep(300);
        if (responseData.data.isStatus) {
          const json = JSON.parse(responseData.data.responseData);
          if (cookie.load('UserType') == 'CallCenter') {
            dispatch(sendMessagePackagePrinter(printerHubConnection, 1, json.Id, json.BrandId));
          } else if (print) {
            dispatch(sendMessagePackagePrinter(printerHubConnection, 0, json.Id, json.BrandId));
          }

          if (getParameter('PackagePrint') && json.OrderType == 1) {
            dispatch(sendMessagePackagePrinter(printerHubConnection, 1, json.Id, json.BrandId));
          }

          const selfsales = getParameter('SelfSales');
          if (selfsales && newPackage) {
            return;
          }

          await dispatch(getAddition(json.Id));

          if (isFast) {
            dispatch(setFastPackageVisible(false));
          }
        } else {
          dispatch(setModalAddition(responseData.data.responseData));
        }
      })();
    }
  };
}

function renderTotalPrice(addition_data) {
  let totalprice = 0;

  addition_data.ProductItems.filter(x => x.IsTreat == false && x.IsDelete == false).map((n, i) => {
    let price = n.Price;
    n.Unit.map(n => {
      if (n.IsSelected) {
        price = n.Price;
      }
    });
    n.Property.map(n => {
      n.Item.map(n => {
        if (n.Default && n.Quantity == 0) {
          price += n.Price;
        } else if (n.Default && n.Quantity > 0) {
          price += (n.Price * n.Quantity);
        }
      });
    });

    n.Menu.map(n => {
      n.Item.map(n => {
        if (n.Default) {
          price += n.Price;
        }
      });

      if (n.MenuDetailSelected != null) {
        if (n.MenuDetailSelected.length != 0) {
          n.MenuDetailSelected.map(x => {
            x.Item.map(n => {
              if (n.Default && n.Quantity == 0) {
                price += n.Price;
              } else if (n.Default && n.Quantity > 0) {
                price += (n.Price * n.Quantity);
              }
            });
          });
        }
      }
    });

    totalprice += price * n.Quantity;
  });

  return totalprice;
}

function renderDetailPrice(item) {
  let price = item.Price;
  item.Unit.map(n => {
    if (n.IsSelected) {
      price = n.Price;
    }
  });

  item.Property.map(n => {
    n.Item.map(n => {
      if (n.Default && n.Quantity == 0) {
        price += n.Price;
      } else if (n.Default && n.Quantity > 0) {
        price += (n.Price * n.Quantity);
      }
    });
  });

  item.Menu.map(n => {
    n.Item.map(n => {
      if (n.Default) {
        price += n.Price;
      }
    });

    if (n.MenuDetailSelected != null) {
      if (n.MenuDetailSelected.length != 0) {
        n.MenuDetailSelected.map(x => {
          x.Item.map(n => {
            if (n.Default && n.Quantity == 0) {
              price += n.Price;
            } else if (n.Default && n.Quantity > 0) {
              price += (n.Price * n.Quantity);
            }
          });
        });
      }
    }
  });

  price *= item.Quantity;
  return price;
}

const sleep = m => new Promise(r => setTimeout(r, m));

export async function setAdditionSaveFastPayDetailed(IsPayment, IsPrint = false) {
  return async (dispatch, getState) => {
    const {
      addition_data, printerHubConnection, AdditionPayments, IsSalesReturn
    } = getState().get('additionReducer').form_data;

    const data = {
      AdditionPayments,
      IsPayment
    };

    const addition = JSON.parse(JSON.stringify(addition_data));
    let date = new Date();
    if (!addition.IsNew) {
      date = new Date(addition.CreationTime);
    }

    addition.AdditionPayments = data;
    addition.IsEntegrationSystem = getParameter('IsEntegrationSystem');
    addition.TenantId = cookie.load('TenantId');
    addition.ProductItemsJson = JSON.stringify(addition.ProductItems);
    addition.AdditionHistoriesJson = JSON.stringify(addition.AdditionHistories);
    addition.PaymentsJson = JSON.stringify(addition.Payments);
    addition.BrandId = cookie.load('BrandId');
    addition.CenterBrandId = cookie.load('CenterBrandId');
    addition.UserId = cookie.load('UserId');
    addition.UserName = cookie.load('NameSurname');
    addition.ProductItems = null;
    addition.AdditionHistories = null;
    addition.Payments = null;
    addition.UpdateTime = date;
    addition.CreationTime = date;
    addition.DeliveryTime = (date < new Date(addition.DeliveryTime) ? new Date(addition.DeliveryTime) : date);
    addition.IsSalesReturn = IsSalesReturn;

    if (addition.OrderType == 1) {
      addition.PackageStatus = '1';
    }

    if (cookie.load('UserType') == 'CallCenter') {
      addition.OrderType = 1;
      addition.PackageStatus = '1';
    }

    const responseData = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/setAdditionSaveFastPay',
      dataType: 'json',
      headers: {
        'X-ClientId': cookie.load('UserId'),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: addition,
    })
      .then((response) => response)
      .catch((error) => {
        return null;
      });

    if (responseData != null) {
      (async () => {
        await sleep(300);
        if (responseData.data.isStatus) {
          const json = JSON.parse(responseData.data.responseData);
          const selfsales = getParameter('SelfSales');
          if (selfsales) {
            dispatch(sendMessagePackagePrinter(printerHubConnection, 0, json.Id, json.BrandId));
          }
          if (!IsPayment) {
            await dispatch(getAddition(json.Id));
          }
          if (IsPayment == true && IsPrint == true) {
            dispatch(sendMessagePackagePrinter(printerHubConnection, 2, json.Id, json.BrandId));
          }
        } else {
          dispatch(setModalAddition(responseData.data.responseData));
        }
        dispatch(formDataSet('additionSaveEnable', false));
      })();
    } else {
      dispatch(formDataSet('additionSaveEnable', false));
    }
  };
}

export async function setAdditionSaveFastPay(IsPayment, PaymentId, PaymentName) {
  return async (dispatch, getState) => {
    dispatch(formDataSet('additionSaveEnable', true));
    const { addition_data, printerHubConnection, AdditionPayments } = getState().get('additionReducer').form_data;
    const { IsSalesReturn, additionIsHold } = getState().get('additionReducer');

    if (PaymentId != '') {
      let Price = 0;
      let total = 0;
      if (AdditionPayments.length > 0) {
        AdditionPayments.filter(x => x.IsDelete == false).map((n, i) => {
          total += n.Price;
        });
      }
      if (total == 0) {
        Price = renderTotalPrice(addition_data);
      } else {
        Price = renderTotalPrice(addition_data) - total;
      }
      const payment = {
        Id: uuidv4(),
        PaymentId,
        PaymentName,
        Price,
        CreationDatetime: new Date(),
        PaymentType: 0,
        IsDelete: false,
        PaymentProduct: []
      };
      AdditionPayments.push(payment);
    }

    const data = {
      AdditionPayments: (additionIsHold ? [] : AdditionPayments),
      IsPayment: (additionIsHold ? false : IsPayment)
    };

    const addition = JSON.parse(JSON.stringify(addition_data));
    let date = new Date();
    if (!addition.IsNew) {
      date = new Date(addition.CreationTime);
    }

    addition.AdditionPayments = data;
    addition.IsEntegrationSystem = getParameter('IsEntegrationSystem');
    addition.TenantId = cookie.load('TenantId');
    addition.ProductItemsJson = JSON.stringify(addition.ProductItems);
    addition.AdditionHistoriesJson = JSON.stringify(addition.AdditionHistories);
    addition.PaymentsJson = (additionIsHold ? '' : JSON.stringify(addition.Payments));
    addition.BrandId = cookie.load('BrandId');
    addition.CenterBrandId = cookie.load('CenterBrandId');
    addition.UserId = cookie.load('UserId');
    addition.UserName = cookie.load('UserName');
    addition.ProductItems = null;
    addition.AdditionHistories = null;
    addition.Payments = null;
    addition.UpdateTime = date;
    addition.CreationTime = date;
    addition.DeliveryTime = (date < new Date(addition.DeliveryTime) ? new Date(addition.DeliveryTime) : date);
    addition.IsSalesReturn = IsSalesReturn;
    addition.IsHold = additionIsHold;

    if (addition.OrderType == 1) {
      addition.PackageStatus = '1';
    }

    if (cookie.load('UserType') == 'CallCenter') {
      addition.OrderType = 1;
      addition.PackageStatus = '1';
    }

    const responseData = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/setAdditionSaveFastPay',
      dataType: 'json',
      headers: {
        'X-ClientId': cookie.load('UserId'),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: addition,
    })
      .then((response) => response)
      .catch((error) => {
        return null;
      });

    if (responseData != null) {
      (async () => {
        await sleep(300);
        if (responseData.data.isStatus) {
          const json = JSON.parse(responseData.data.responseData);
          const selfsales = getParameter('SelfSales');
          if (selfsales && !getParameter('IsAdditionClosedPrinted')) {
            dispatch(sendMessagePackagePrinter(printerHubConnection, 0, json.Id, json.BrandId));
          }
          if (!IsPayment) {
            dispatch(getAddition(json.Id));
          }
          if (!additionIsHold && getParameter('IsAdditionClosedPrinted')) {
            dispatch(sendMessagePackagePrinter(printerHubConnection, 2, json.Id, json.BrandId));
          }
          if (additionIsHold || (!selfsales && IsPayment && addition.OrderType == 2)) {
            dispatch(push('/app?' + Math.random()));
          }
        } else {
          dispatch(setModalAddition(responseData.data.responseData));
        }
        dispatch(formDataSet('additionSaveEnable', false));
      })();
    } else {
      dispatch(formDataSet('additionSaveEnable', false));
    }
  };
}


export async function getCourrierList() {
  return async (dispatch, getState) => {
    const TenantId = cookie.load('TenantId');
    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Addition/GetCourrier?TenantId=' + TenantId + '&BrandId=' + BrandId + '',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        dispatch(formDataSet('CourrierList', json));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function addHistoryItemNew(OperationType, CreationTime, OperationText, UserId, OperationDetailType = 0, ItemId = '') {
  return async (dispatch, getState) => {
    const { addition_data, AdditionHistoriesItem } = getState().get('additionReducer').form_data;
    AdditionHistoriesItem.OperationType = OperationType;
    AdditionHistoriesItem.CreationTime = CreationTime;
    AdditionHistoriesItem.OperationText = OperationText;
    AdditionHistoriesItem.UserId = UserId;
    AdditionHistoriesItem.OperationDetailType = OperationDetailType;
    AdditionHistoriesItem.ItemId = ItemId;

    addition_data.AdditionHistories.push(AdditionHistoriesItem);
    dispatch(clearAdditionHistoryItem());
  };
}

export async function packageReload(data, filterData) {
  return async (dispatch, getState) => {
    const { packageFilter } = getState().get('additionReducer');
    if (filterData == null) {
      filterData = packageFilter;
    }
    await dispatch(setPackageFilterLoading(false));
    dispatch({
      type: SET_PACKAGERELOAD,
      payload: data,
      filter: filterData
    });
    await dispatch(setPackageFilterLoading(true));
  };
}

export async function packageReloadUpdate(data, filterData) {
  return async (dispatch, getState) => {
    const { packageFilter } = getState().get('additionReducer');
    if (filterData == null) {
      filterData = packageFilter;
    }
    await dispatch(setPackageFilterLoading(false));
    dispatch({
      type: SET_PACKAGERELOAD_UPDATE,
      payload: data,
      filter: filterData
    });
    await dispatch(setPackageFilterLoading(true));
  };
}

export async function filterPackageAddition(packageFilter) {
  return async (dispatch, getState) => {
    dispatch(setPackageFilterLoading(false));
    const data = JSON.parse(JSON.stringify(packageFilter));

    data.TenantId = cookie.load('TenantId');
    data.BrandId = cookie.load('BrandId');
    data.List = [];

    const config = {
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/GetPackage',
      headers: {
        'X-ClientId': cookie.load('BrandId').toString(),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
        'Content-Type': 'application/json'
      },
      maxRedirects: 0,
      data
    };

    axios(config)
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(setPackageFilterSet(json));
          if (json.Is24HoursClosedPackage) {
            dispatch(setAdditionPackageLastOrderVisible(true));
          } else {
            dispatch(setAdditionPackageLastOrderVisible(false));
          }
        }
      })
      .catch((error) => {
        console.log('sipariş kontrol', error.response.data);
      });
  };
}

export async function setPackageAllCompleted(packageFilter) {
  return async (dispatch, getState) => {
    dispatch(setPackageFilterLoading(false));
    const data = JSON.parse(JSON.stringify(packageFilter));

    data.TenantId = cookie.load('TenantId');
    data.BrandId = cookie.load('BrandId');
    data.List = [];

    const config = {
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/setPackageAllCompleted',
      headers: {
        'X-ClientId': cookie.load('BrandId').toString(),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
        'Content-Type': 'application/json'
      },
      maxRedirects: 0,
      data
    };

    axios(config)
      .then((response) => {
        dispatch(filterPackageAddition(packageFilter));
        dispatch(setModalAddition(response.data.responseData));
      })
      .catch((error) => {
      });
  };
}

export async function setAdditionPaymentType(PaymentId, PaymentName, AdditionId) {
  return async (dispatch, getState) => {
    dispatch(setPackageFilterLoading(false));

    const data = {
      PaymentId,
      PaymentName,
      AdditionId,
      UserId: cookie.load('UserId'),
      UserName: cookie.load('NameSurname')
    };

    const config = {
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/setAdditionPaymentType',
      headers: {
        'X-ClientId': cookie.load('BrandId').toString(),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
        'Content-Type': 'application/json'
      },
      maxRedirects: 0,
      data
    };

    axios(config)
      .then((response) => {
        dispatch(filterPackageComplatedAddition());
        dispatch(setModalAddition(response.data.responseData));
        dispatch(setPackageFilterLoading(true));
      })
      .catch((error) => {
        console.log('sipariş kontrol', error);
      });
  };
}

export async function setAdditionCourier(CourierId, CourierName,) {
  return async (dispatch, getState) => {
    const { AdditionListSelectedId } = getState().get('additionReducer').form_data;

    dispatch(setPackageFilterLoading(false));

    const data = {
      CourierId,
      CourierName,
      AdditionId: AdditionListSelectedId,
      UserId: cookie.load('UserId'),
      UserName: cookie.load('NameSurname')
    };

    const config = {
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/setAdditionCourier',
      headers: {
        'X-ClientId': cookie.load('BrandId').toString(),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
        'Content-Type': 'application/json'
      },
      maxRedirects: 0,
      data
    };

    axios(config)
      .then((response) => {
        dispatch(formDataSet('AdditionListSelectedId', ''));
        dispatch(filterPackageComplatedAddition());
        dispatch(setModalAddition(response.data.responseData));
        dispatch(setPackageFilterLoading(true));
      })
      .catch((error) => {
        console.log('sipariş kontrol', error);
      });
  };
}

export async function filterPackageComplatedAddition() {
  return async (dispatch, getState) => {
    const { packageFilterComplated } = getState().get('additionReducer');

    dispatch(setPackageFilterLoading(false));
    const data = JSON.parse(JSON.stringify(packageFilterComplated));

    data.TenantId = cookie.load('TenantId');
    data.BrandId = cookie.load('BrandId');
    data.List = [];

    const config = {
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/GetPackageComplated',
      headers: {
        'X-ClientId': cookie.load('BrandId').toString(),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
        'Content-Type': 'application/json'
      },
      maxRedirects: 0,
      data
    };

    axios(config)
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          json.startDateTime = packageFilterComplated.startDateTime;
          json.endDateTime = packageFilterComplated.endDateTime;
          dispatch(setPackageComplatedFilterSet(json));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }

        dispatch(setPackageFilterLoading(true));
      })
      .catch((error) => {
        console.log('sipariş kontrol', error);
      });
  };
}

export async function formDataSet(key, val) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDI_GLOBAL_FORM_DATA_SET,
      payload: { key, val }
    });
  };
}

export async function clearPackage() {
  return async (dispatch, getState) => {
    const data = {
      totalRecord: 0,
      List: [],
      PackageStatus: [],
      PackageButtonStatus: 'Pending'
    };

    dispatch(setPackageFilterSet(data));
  };
}

export async function clearAdditionHistoryItem() {
  return async (dispatch, getState) => {
    const data = {
      OperationType: '',
      CreationTime: '',
      OperationText: '',
      UserId: '',
      OperationDetailType: 0,
      ItemId: ''
    };
    dispatch(formDataSet('AdditionHistoriesItem', data));
  };
}

export async function clearAdditionTable(TableId, TableName) {
  return async (dispatch, getState) => {
    const data = {
      Id: uuidv4(),
      CustomerId: '00000000-0000-0000-0000-000000000000',
      CustomerName: '',
      CustomerAddressId: '00000000-0000-0000-0000-000000000000',
      CustomerAddressText: '',
      TableId,
      TableName,
      AdditionId: '',
      ProductItems: [],
      ProductItemsJson: '',
      AdditionHistories: [],
      AdditionHistoriesJson: '',
      CreationTime: new Date(),
      UpdateTime: '',
      OrderType: 0,
      PackageStatus: 0,
      UserId: '',
      UserName: '',
      AdditionDescription: '',
      IsNew: true,
      Payments: [],
      AdditionPrinted: false,
      PaymentNote: '',
      IsComeGet: false,
      CustomerPhone: '',
      DeliveryTime: new Date()
    };
    dispatch(formDataSet('addition_data', data));
    dispatch(formDataSet('newPackage', false));
    dispatch(formDataSet('AdditionRemoveExplanation', ''));
    dispatch(formDataSet('ProductRemoveExplanation', ''));
    dispatch(formDataSet('AdditionPayments', []));
    dispatch(formDataSetCustomer('customerValue', ''));
    dispatch(formDataSetCustomer('customerSelected', null));
  };
}

export async function clearAdditionSelfSales() {
  return async (dispatch, getState) => {
    const data = {
      Id: uuidv4(),
      CustomerId: '00000000-0000-0000-0000-000000000000',
      CustomerName: '',
      CustomerAddressId: '00000000-0000-0000-0000-000000000000',
      CustomerAddressText: '',
      TableId: '00000000-0000-0000-0000-000000000000',
      TableName: 'Self Satış',
      AdditionId: '',
      ProductItems: [],
      ProductItemsJson: '',
      AdditionHistories: [],
      AdditionHistoriesJson: '',
      CreationTime: new Date(),
      UpdateTime: '',
      OrderType: 2,
      PackageStatus: 0,
      UserId: '',
      UserName: '',
      AdditionDescription: '',
      IsNew: true,
      Payments: [],
      AdditionPrinted: false,
      PaymentNote: '',
      IsComeGet: false,
      CustomerPhone: '',
      DeliveryTime: new Date()
    };
    dispatch(formDataSet('addition_data', data));
    dispatch(formDataSet('newPackage', false));
    dispatch(formDataSet('AdditionRemoveExplanation', ''));
    dispatch(formDataSet('ProductRemoveExplanation', ''));
    dispatch(formDataSet('AdditionPayments', []));
    dispatch(formDataSetCustomer('customerValue', ''));
    dispatch(formDataSetCustomer('customerSelected', null));

    dispatch(setAdditionListUpdate());
  };
}

export async function clearAddition() {
  return async (dispatch, getState) => {
    const data = {
      Id: uuidv4(),
      CustomerId: '00000000-0000-0000-0000-000000000000',
      CustomerName: '',
      CustomerAddressId: '00000000-0000-0000-0000-000000000000',
      CustomerAddressText: '',
      TableId: '',
      TableName: '',
      AdditionId: '',
      ProductItems: [],
      ProductItemsJson: '',
      AdditionHistories: [],
      AdditionHistoriesJson: '',
      CreationTime: new Date(),
      DeliveryTime: new Date(),
      UpdateTime: '',
      OrderType: 0,
      PackageStatus: 0,
      UserId: '',
      UserName: '',
      AdditionDescription: '',
      IsNew: true,
      Payments: [],
      AdditionPrinted: false,
      PaymentNote: '',
      IsComeGet: false,
      CustomerPhone: ''
    };

    dispatch(formDataSetCustomer('customerValue', ''));
    dispatch(formDataSetCustomer('customerSelected', null));
    dispatch({ type: SETADDITION_CLEAR, payload: data });
    dispatch(sendCustomerScreenMessage(true));
  };
}

export async function setModalAddition(text, caption = 'Uyarı') {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDITION_ERROR,
      payload: text,
      caption
    });
  };
}

export async function setProductDetailModalOpen(detail) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCTDETAIL_OPEN,
      payload: detail
    });
  };
}

export async function setProductDetailModalClose() {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCTDETAIL_CLOSE
    });
  };
}

export async function setProductDetailUpdate() {
  return async (dispatch, getState) => {
    const { ProductDetail } = getState().get('additionReducer').form_data;
    dispatch({
      type: PRODUCTDETAIL_UPDATE,
      payload: ProductDetail
    });
  };
}

export async function setAdditionListUpdate(update) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDITIONLIST_UPDATE,
      payload: update
    });
  };
}

export async function setDetailedPaymentVisible(open) {
  return async (dispatch, getState) => {
    dispatch({
      type: DETAILEDPAYMENT_VISIBLE,
      payload: open
    });
  };
}

export async function setDetailedPaymentUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: DETAILEDPAYMENT_UPDATE
    });
  };
}

export async function setPrinterSocketNotificationVisible(open, text, color) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRINTERSOCKETNOTIFICATION_VISIBLE,
      open,
      text,
      color
    });
  };
}

export async function setProductListDetailUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCTLISTDETAIL_UPDATE
    });
  };
}

export async function setAdditionPaymentLoading(visible = true) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDITIONPAYMENT_LOADING,
      payload: visible
    });
  };
}

export async function getAdditionData(data) {
  return async (dispatch, getState) => {
    await dispatch({
      type: GET_ADDITION_DATA,
      payload: data
    });
    dispatch(sendCustomerScreenMessage());
  };
}

export async function setDetailPaymentSnackbar(text) {
  return async (dispatch, getState) => {
    dispatch({
      type: DETAILPAYMENT_SNACKBAR,
      payload: text
    });
  };
}

export async function setPaycellPaymentLoading(visible = true) {
  return async (dispatch, getState) => {
    dispatch({
      type: PAYCELL_PAYMENT_LOADING,
      payload: visible
    });
  };
}

export async function setIngenicoPaymentUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: INGENICO_PAYMENT_UPDATE
    });
  };
}

export async function setIngenicoPaymentLoading(visible = true) {
  return async (dispatch, getState) => {
    dispatch({
      type: INGENICO_PAYMENT_LOADING,
      payload: visible
    });
  };
}

export async function setIngenicoPaymentSuccess() {
  return async (dispatch, getState) => {
    dispatch({ type: INGENICO_PAYMENT_SUCCESS, payload: false, timeout:false });
  };
}

export async function getTrendyolCancelOptions() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/trendyolYemekCancelOptions',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        dispatch({
          type: TRENDYOLCANCEL_OPTIONS,
          payload: json
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getYemekSepetiGlobalCancelOptions(IsVale) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/YemekSepetiGlobalCancelOptions?IsVale=' + IsVale,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        dispatch({
          type: YEMEKSEPETIGLOBALCANCEL_OPTIONS,
          payload: json
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function MigrosYemekCancelOptions() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/MigrosYemekCancelOptions',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        dispatch({
          type: MIGROSYEMEKCANCEL_OPTIONS,
          payload: json
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getGetirCancelOptions(orderId) {
  return async (dispatch, getState) => {
    const getiryemek = getParameter('GetirYemek');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getirCancelOptions?orderId=' + orderId+ '&BrandId=' + cookie.load("BrandId"),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        if (json.length > 0) {
          dispatch({
            type: GETIRCANCEL_OPTIONS,
            payload: json
          });
        } else {
          const newData = [{
            id: '',
            message: "Sadece JaviPos'dan İptal Et"
          }];

          dispatch({
            type: GETIRCANCEL_OPTIONS,
            payload: newData
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getRepeatOrderList(CustomerId) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Addition/getRepeatOrderList?CustomerId=' + CustomerId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(setRepeatOrderList(json, true));
        } else {
          dispatch(setModalAddition('Geçmiş paket bulunamadı!'));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getAdditionBack(additionId) {
  return async (dispatch, getState) => {
    const UserId = cookie.load('UserId');
    const UserName = cookie.load('UserName');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Addition/getAdditionBack?AdditionId=' + additionId + '&UserId=' + UserId + '&UserName=' + UserName,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        dispatch(setModalAddition(response.data.responseData));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getManagerControl(password, additionId) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Addition/getManagerControl?BrandId=' + BrandId + '&password=' + password,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getAdditionBack(additionId));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setChangePhoneControl(CustomerId) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');
    const { phoneChangeControlValue } = getState().get('additionReducer');
    const { AdresList, callCenterAddress } = getState().get('additionReducer').form_data;

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/setChangePhoneControl?BrandId=' + BrandId + '&CustomerId=' + CustomerId + '&phone=' + phoneChangeControlValue,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          callCenterAddress.Phone = phoneChangeControlValue;

          if (AdresList != null) {
            if (AdresList.length > 0) {
              AdresList.map((n, i) => {
                n.Phone = phoneChangeControlValue;
              });
            }
          }

          dispatch(formDataSet('AdresList', AdresList));
          dispatch(formDataSet('callCenterAddress', callCenterAddress));
          dispatch(setPhoneChangeVisible());
          dispatch(setModalAddition(response.data.responseData));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getPackageAdditionDetail(additionId, anchor) {
  return async (dispatch, getState) => {
    if (additionId != '') {
      await axios({
        method: 'GET',
        url: brand.urlSocket + 'api/Addition/GetAddition?Id=' + additionId + '&IsWeb=true',
        dataType: 'json',
        headers: {
          Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
        },
      })
        .then((response) => {
          if (response.data.isStatus) {
            const json = JSON.parse(response.data.responseData);
            json.Payments = (json.Payments == null ? [] : json.Payments);
            if (json.ProductItems.length != 0) {
              json.ProductItems.map((n, i) => {
                n.IsAddNew = false;
              });
            }

            dispatch({
              type: PACKAGE_GET_ADDITION_DETAIL,
              payload: json,
              anchor
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch({
        type: PACKAGE_GET_ADDITION_DETAIL,
        payload: null,
        anchor: null
      });
    }
  };
}

export async function setMaxiJettOrder(additionId) {
  return async (dispatch, getState) => {
    const { packageFilter, maxijettCookingTime } = getState().get('additionReducer');

    const dataList = [];
    packageFilter.List.filter(x => x.IsSelected == true).map((n, i) => {
      dataList.push({
        OrderId: n.Id,
        BrandId: cookie.load('BrandId'),
        IsStatus: false,
        ResponseData: '',
        maxijettCookingTime
      });
    });

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Package/setMaxiJettOrder',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: dataList
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          notificationShow(json, 5000);
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function getMaxiJettDistanceFee(orderId) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Package/getMaxiJettDistanceFee?OrderId=' + orderId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(setMaxiJettDistanceFeeData(json));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function getOSMGeoLocation(orderId) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getOSMGeoLocation?OrderId=' + orderId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          console.log(json);
          dispatch(setOSMGeoLocation(json));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function setOrderNewLocation(orderId) {
  return async (dispatch, getState) => {
    const { maxiJettNewLocation } = getState().get('additionReducer');
    const postData = {
      Id: orderId,
      Lat: maxiJettNewLocation.lat,
      Lng: maxiJettNewLocation.lng,
    };
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Package/setOrderNewLocation',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: postData
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getMaxiJettDistanceFee(orderId));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function setMaxiJettDistanceFeeData(json) {
  return async (dispatch, getState) => {
    dispatch({
      type: MAXIJETT_DISTANCE_FEE,
      payload: json
    });
  };
}

export async function setOSMGeoLocation(json) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_OSM_GEOLOCATION,
      payload: json
    });
  };
}

export async function setCirakAppOrder(additionId) {
  return async (dispatch, getState) => {
    const { packageFilter } = getState().get('additionReducer');

    const dataList = [];
    packageFilter.List.filter(x => x.IsSelected == true).map((n, i) => {
      dataList.push({
        OrderId: n.Id,
        BrandId: cookie.load('BrandId'),
        IsStatus: false,
        ResponseData: ''
      });
    });

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Package/setCirakAppOrder',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: dataList
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          notificationShow(json, 5000);
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function setPaketYoldaOrder(additionId) {
  return async (dispatch, getState) => {
    const { packageFilter } = getState().get('additionReducer');

    const dataList = [];
    packageFilter.List.filter(x => x.IsSelected == true).map((n, i) => {
      dataList.push({
        OrderId: n.Id,
        BrandId: cookie.load('BrandId'),
        IsStatus: false,
        ResponseData: ''
      });
    });

    const config = {
      method: 'POST',
      url: brand.urlSocket + 'api/PaketYolda/setPaketYoldaOrder',
      dataType: 'json',
      headers: {
        'X-ClientId': cookie.load('BrandId'),
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
        'Content-Type': 'application/json'
      },
      data: dataList,
      maxRedirects: 0
    };

    axios(config)
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          notificationShow(json, 5000);
          packageFilter.List.filter(x => x.IsSelected == true).map((n, i) => {
            n.IsSelected = false;
            n.PackageStatus = '6';
            n.IsPaketYolda = true;
          });
          dispatch(setPackageFilterSet(packageFilter));
        } else {
          dispatch(setModalAddition(response.data.responseData));
        }
      })
      .catch((error) => {
      });
  };
}

export async function getAdditionHoldList() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Addition/getAdditionHoldList?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: ADDITION_HOLD_LIST_DATA,
            payload: json
          });
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function getAdditionHoldBack(OrderId) {
  return async (dispatch, getState) => {
    const { IsAdditionListUpdate } = getState().get('additionReducer');
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Addition/getAdditionHoldBack?OrderId=' + OrderId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(clearAddition());
          json.Payments = (json.Payments == null ? [] : json.Payments);
          if (json.ProductItems.length != 0) {
            json.ProductItems.map((n, i) => {
              n.IsAddNew = false;
            });
          }
          dispatch(getAdditionData(json));
          dispatch(setAdditionListUpdate(!IsAdditionListUpdate));
          dispatch(push('/app/detailpage?selfsales'));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function setCourierPartAccount(UserId, PartAccountDatetime) {
  return async (dispatch, getState) => {
    const { IsAdditionListUpdate } = getState().get('additionReducer');
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Courier/setCourierPartAccount?UserId=' + UserId + '&PartAccountDatetime=' + PartAccountDatetime,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getCourrierList());
          dispatch(setCourierPartVisible(false));
        }
      })
      .catch((error) => {
        dispatch(setModalAddition(error));
      });
  };
}

export async function setRepeatOrderList(data, open) {
  return async (dispatch, getState) => {
    dispatch({
      type: REPEATORDER_LIST,
      payload: data,
      open
    });
  };
}

export async function additionBack(type) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDITION_BACK,
      payload: type
    });
  };
}

export async function packagePrintModal(open) {
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGEPRINT_MODALVISIBLE,
      payload: open
    });
  };
}

export async function setTreatProductQuantity(quantity) {
  return async (dispatch, getState) => {
    dispatch({
      type: TREATPRODUCT_QUANTITY,
      payload: quantity
    });
  };
}

export async function setPrinterUpdate(state) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRINTER_UPDATE,
      payload: state
    });
  };
}

export async function setScalesUpdate(quantity) {
  return async (dispatch, getState) => {
    dispatch({
      type: SCALES_QUANTITY,
      payload: quantity
    });
  };
}

export async function setSeePrice(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_SEEPRICE,
      payload: visible
    });
  };
}

export async function setCallerIdCustomer(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CALLERID_CUSTOMER,
      payload: data
    });
  };
}

export async function setCallerIdCustomerRemove(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CALLERID_CUSTOMER_REMOVE,
      payload: data
    });
  };
}

export async function setSalesReturn(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: SETSALES_RETURN,
      payload: visible
    });
  };
}

export async function setAdditionWaitingVisible(open) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ADDITIONWAITING_VISIBLE,
      payload: open
    });
  };
}

export async function setAdditionWaitingData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ADDITIONWAITING,
      payload: data
    });
  };
}


export async function packageListClear() {
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGELIST_CLEAR
    });
  };
}

export async function setGetirCancelVisible() {
  return async (dispatch, getState) => {
    dispatch({
      type: GETIRCANCEL_OPTIONS,
      payload: []
    });
  };
}

export async function setTrendyolCancelVisible() {
  return async (dispatch, getState) => {
    dispatch({
      type: TRENDYOLCANCEL_OPTIONS,
      payload: []
    });
  };
}

export async function setYemekSepetiGlobalCancelVisible() {
  return async (dispatch, getState) => {
    dispatch({
      type: YEMEKSEPETIGLOBALCANCEL_OPTIONS,
      payload: []
    });
  };
}

export async function setMigrosYemekVisible() {
  return async (dispatch, getState) => {
    dispatch({
      type: MIGROSYEMEKCANCEL_OPTIONS,
      payload: []
    });
  };
}

export async function setModalAdditionClose() {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDITION_MODALCLOSE
    });
  };
}

export async function setPhoneChangeVisible() {
  return async (dispatch, getState) => {
    dispatch({
      type: PHONE_CHANGE_VISIBLE
    });
  };
}

export async function setPhoneChangeValue(value) {
  return async (dispatch, getState) => {
    dispatch({
      type: PHONE_CHANGE_VALUE,
      payload: value
    });
  };
}

export async function setMaxiJettCookieTime(value) {
  return async (dispatch, getState) => {
    dispatch({
      type: MAXIJETT_COOKINGTIME,
      payload: value
    });
  };
}


export async function setPackageBarcodeVisible(open) {
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGE_BARCODE_VISIBLE,
      payload: open
    });
  };
}

export async function setPackageBarcodeData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGE_BARCODE_DATA,
      payload: data
    });
  };
}

export async function setPackageBarcodeDataClear() {
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGE_BARCODE_DATA,
      payload: {
        data: '',
        IsComplated: false,
        BrandId: '',
        RestName: '',
        BarcodeList: []
      }
    });
  };
}

export async function setPackageFilterSet(data) {
  return async (dispatch, getState) => {
    const jsonData = JSON.parse(JSON.stringify(data));
    dispatch({
      type: PACKAGEFILTER_DATA_SET,
      payload: jsonData
    });
  };
}

export async function setPackageComplatedFilterSet(data) {
  return async (dispatch, getState) => {
    const jsonData = JSON.parse(JSON.stringify(data));
    jsonData.startDateTime = data.startDateTime;
    jsonData.endDateTime = data.endDateTime;
    dispatch({
      type: PACKAGEFILTER_COMPLATED_DATA_SET,
      payload: jsonData
    });
  };
}

export async function setPackageFilterLoading(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGEFILTER_DATA_LOADING,
      payload: data
    });
  };
}

export async function setPackageCourierVisible(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGE_COURIER_VISIBLE,
      payload: data
    });
  };
}

export async function setPackageStatusVisible(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGE_STATUS_VISIBLE,
      payload: data
    });
  };
}

export async function setAdditionSpeedProductAdd() {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDITION_SPEED_PRODUCTADD
    });
  };
}

export async function setMaxiJettNewLocation(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: MAXIJETT_NEW_LOCATION,
      payload: data
    });
  };
}

export async function setAdditionIsHold(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDITION_ISHOLD,
      payload: data
    });
  };
}

export async function setAdditionHoldListVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getAdditionHoldList());
    }
    dispatch({
      type: ADDITION_HOLD_LIST_VISIBLE,
      payload: visible
    });
  };
}

export async function setPackageListUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGEFILTER_DATA_UPDATE
    });
  };
}

export async function setAdditionBackModalVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDITION_BACK_VISIBLE,
      payload: visible
    });
  };
}

export async function setAdditionBackModalData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDITION_BACK_DATA,
      payload: data
    });
  };
}

export async function setAdditionPackageLastOrderVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADDITION_PACKAGE_LASTORDER_VISIBLE,
      payload: visible
    });
  };
}

export async function setCourierPartVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PART_COURIER_ACCOUNT_VISIBLE,
      payload: visible
    });
  };
}

export async function setPackageRackUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGE_RACK_UPDATE
    });
  };
}

export async function setAdditionPaycellSendVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getPaycellPosList());
    }
    dispatch({
      type: SET_PAYCELL_SEND_VISIBLE,
      payload: visible
    });
  };
}

export async function setAdditionPaycellSendUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PAYCELL_SEND_UPDATE
    });
  };
}

export async function setRankingNumberData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_RANKINGNUMBER_DATA,
      payload: data
    });
  };
}

export async function setRankingNumberClear() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_RANKINGNUMBER_CLEAR
    });
  };
}

export async function setRankingNumberVisible(visible, type = false) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_RANKING_VISIBLE,
      payload: visible,
      split: type
    });
  };
}

export async function setFastPackageVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getNewFastPackage());

      var defaultcity = getParameter('DefaultCity');
      if (defaultcity.IlId > 0 && defaultcity.IlceId > 0) {
        await dispatch(getAddressList('Ilce', defaultcity.IlId));
        await dispatch(getAddressList('Mahalle', defaultcity.IlceId));

        await dispatch(setFastRegionSelect(defaultcity.IlId.toString(), 0));
        await dispatch(setFastRegionSelect(defaultcity.IlceId.toString(), 1));
      }
    }
    dispatch({
      type: SET_FAST_PACKAGE_VISIBLE,
      payload: visible
    });
  };
}

export async function setFastPackageUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_FAST_PACKAGE_UPDATE
    });
  };
}

export async function setFastRegionSelect(value, type) {
  return async (dispatch, getState) => {
    if (type == 0) {
      dispatch({
        type: SET_FAST_IL,
        payload: value
      });
    } else if (type == 1) {
      dispatch({
        type: SET_FAST_ILCE,
        payload: value
      });
    } else if (type == 2) {
      dispatch({
        type: SET_FAST_MAHALLE,
        payload: value
      });
    } else if (type == 3) {
      dispatch({
        type: SET_FAST_SOKAK,
        payload: value
      });
    }
  };
}

export async function setFastPackageLoading(loading) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_FAST_PACKAGE_LOADING,
      payload: loading
    });
  };
}

export async function setJaviPosKuryeCourierVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getJaviKuryePosCourierList());
    }
    dispatch({
      type: JAVIKURYEPOS_COURIER_VISIBLE,
      payload: visible
    });
  };
}

export async function getJaviKuryePosCourierList() {
  return async (dispatch, getState) => {

    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/PaketYolda/getCourierInfoList?BrandId=' + cookie.load("BrandId"),
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({
            type: JAVIKURYEPOS_COURIER_LIST,
            payload: json
          });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function setIngenicoInvoiceVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: INGENICO_INVOICE_VISIBLE,
      payload: visible
    });
  };
}

export async function setIngenicoInvoiceData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: INGENICO_INVOICE_DATA,
      payload: data
    });
  };
}

export async function setIngenicoRemove(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: INGENICO_INVOICE_REMOVE
    });
  };
}

const additionReducer = (state = initialState, action) => {
  switch (action.type) {
    case INGENICO_INVOICE_REMOVE:
      return { ...state, incenicoInvoiceData: {
        InvoiceType: 0,
        InvoiceTC: "",
        InvoiceVKN: "",
        InvoiceNo: "",
        InvoiceDate: new Date()
      }, incenicoInvoiceUpdate: !state.incenicoInvoiceUpdate }
    case INGENICO_INVOICE_DATA:
      return { ...state, incenicoInvoiceData: action.payload, incenicoInvoiceUpdate: !state.incenicoInvoiceUpdate }
    case INGENICO_INVOICE_VISIBLE:
      return { ...state, incenicoInvoiceVisible: action.payload, incenicoInvoiceUpdate: !state.incenicoInvoiceUpdate }
    case JAVIKURYEPOS_COURIER_VISIBLE:
      return { ...state, javiKuryePosCourierListVisible: action.payload, javiKuryePosCourierListUpdate: !state.javiKuryePosCourierListUpdate }
    case JAVIKURYEPOS_COURIER_LIST:
      return { ...state, javiKuryePosCourierList: action.payload, javiKuryePosCourierListUpdate: !state.javiKuryePosCourierListUpdate }
    case SET_FAST_PACKAGE_LOADING:
      return { ...state, IsFastPackageLoading: action.payload, IsFastPackageUpdate: !state.IsFastPackageUpdate };
    case SET_FAST_SOKAK:
      return { ...state, fastSokakId: action.payload, IsFastPackageUpdate: !state.IsFastPackageUpdate };
    case SET_FAST_MAHALLE:
      return { ...state, fastMahalleId: action.payload, IsFastPackageUpdate: !state.IsFastPackageUpdate, fastSokakId: "" };
    case SET_FAST_ILCE:
      return { ...state, fastIlceId: action.payload, IsFastPackageUpdate: !state.IsFastPackageUpdate, fastMahalleId: "", fastSokakId: "" };
    case SET_FAST_IL:
      return { ...state, fastIlId: action.payload, IsFastPackageUpdate: !state.IsFastPackageUpdate, fastIlceId: "", fastMahalleId: "", fastSokakId: "" };
    case SET_FAST_PACKAGE_UPDATE:
      return { ...state, IsFastPackageUpdate: !state.IsFastPackageUpdate };
    case SET_FAST_PACKAGE_VISIBLE:
      return { ...state, IsFastPackageVisible: action.payload, IsFastPackageUpdate: !state.IsFastPackageUpdate, fastMahalleId: "", fastSokakId: "" };
    case SET_TRACK_COUNT:
      return { ...state, trackCount: action.payload };
    case SET_RANKING_VISIBLE:
      return { ...state, rankingNumber: 0, rankingSystemVisible: action.payload, rankingPaymentTypeSplit: action.split };
    case SET_RANKINGNUMBER_CLEAR:
      return { ...state, rankingNumber: 0, rankingPaymentTypeSplit: false }
    case SET_RANKINGNUMBER_DATA:
      return { ...state, rankingNumber: Number(state.rankingNumber + action.payload) };
    case SET_PAYCELL_SEND_UPDATE:
      return { ...state, additionPaycellSendUpdate: !state.additionPaycellSendUpdate };
    case SET_PAYCELL_SEND_VISIBLE:
      return { ...state, additionPaycellSendVisible: action.payload, additionPaycellSendUpdate: !state.additionPaycellSendUpdate };
    case SET_INGENICO_PROCESS:
      {
        var dispatch = action.dispatch;
        var addition_data = state.form_data.addition_data;
        var IsAdditionListUpdate = state.IsAdditionListUpdate;

        const jsonData = action.payload;
        if (jsonData.IsSuccess) {
          if (jsonData.IsPaymentComplate) {
            const selfsales = getParameter('SelfSales');
            if (selfsales || addition_data.OrderType == 2) {
              const data = {
                Id: uuidv4(),
                CustomerId: '00000000-0000-0000-0000-000000000000',
                CustomerName: '',
                CustomerAddressId: '00000000-0000-0000-0000-000000000000',
                CustomerAddressText: '',
                TableId: '00000000-0000-0000-0000-000000000000',
                TableName: 'Self Satış',
                AdditionId: '',
                ProductItems: [],
                ProductItemsJson: '',
                AdditionHistories: [],
                AdditionHistoriesJson: '',
                CreationTime: new Date(),
                DeliveryTime: new Date(),
                UpdateTime: '',
                OrderType: 2,
                PackageStatus: 0,
                UserId: '',
                UserName: '',
                AdditionDescription: '',
                IsNew: true,
                Payments: [],
                AdditionPrinted: false,
                PaymentNote: '',
                IsComeGet: false,
                CustomerPhone: ''
              };
              dispatch(sendCustomerScreenMessage(true));
              return {
                ...state, form_data: {
                  ...state.form_data,
                  addition_data: data,
                  newPackage: false,
                  AdditionRemoveExplanation: '',
                  ProductRemoveExplanation: '',
                  additionSaveEnable: false,
                  AdditionPayments: [],
                  PaymentModalOpen: false
                },
                paycellPaymentLoading: false,
                IsSalesReturn: false,
                IsAdditionListUpdate: !IsAdditionListUpdate,
                additionIsHold: false
              }
            } else {
              dispatch(clearAddition());
              dispatch(formDataSet('additionSaveEnable', false));
              dispatch(sendMessage());
            }
          }
        }

        return {
          ...state, ingenicoPaymentLoading: false, ingenicoPaymentUpdate: !state.ingenicoPaymentUpdate, ingenicoPaymentSuccess: false, form_data: {
            ...state.form_data,
            PaymentModalOpen: false
          },
        }
      }
    case SET_PAYCELL_PROCESS:
      {
        var dispatch = action.dispatch;
        var addition_data = state.form_data.addition_data;
        var IsAdditionListUpdate = state.IsAdditionListUpdate;

        const jsonData = action.payload;
        if (jsonData.status == "SUCCESS") {
          if (jsonData.is_closed) {
            const selfsales = getParameter('SelfSales');
            if (selfsales || addition_data.OrderType == 2) {
              const data = {
                Id: uuidv4(),
                CustomerId: '00000000-0000-0000-0000-000000000000',
                CustomerName: '',
                CustomerAddressId: '00000000-0000-0000-0000-000000000000',
                CustomerAddressText: '',
                TableId: '00000000-0000-0000-0000-000000000000',
                TableName: 'Self Satış',
                AdditionId: '',
                ProductItems: [],
                ProductItemsJson: '',
                AdditionHistories: [],
                AdditionHistoriesJson: '',
                CreationTime: new Date(),
                DeliveryTime: new Date(),
                UpdateTime: '',
                OrderType: 2,
                PackageStatus: 0,
                UserId: '',
                UserName: '',
                AdditionDescription: '',
                IsNew: true,
                Payments: [],
                AdditionPrinted: false,
                PaymentNote: '',
                IsComeGet: false,
                CustomerPhone: ''
              };
              dispatch(sendCustomerScreenMessage(true));
              return {
                ...state, form_data: {
                  ...state.form_data,
                  addition_data: data,
                  newPackage: false,
                  AdditionRemoveExplanation: '',
                  ProductRemoveExplanation: '',
                  additionSaveEnable: false,
                  AdditionPayments: [],
                  PaymentModalOpen: false
                },
                paycellPaymentLoading: false,
                IsSalesReturn: false,
                IsAdditionListUpdate: !IsAdditionListUpdate,
                additionIsHold: false
              }
            } else {
              dispatch(clearAddition());
              dispatch(formDataSet('additionSaveEnable', false));
              dispatch(sendMessage());
            }
          }
        }

        return {
          ...state, paycellPaymentLoading: false, form_data: {
            ...state.form_data,
            PaymentModalOpen: false
          },
        }
      }
    case INGENICO_HUB_STOP:
      return { ...state, ingenicoHubConnection: action.payload };
    case PACKAGE_RACK_HUB_STOP:
      return { ...state, packageRackHubConnection: action.payload };
    case PACKAGE_RACK_DATA_UPDATE:
      {
        var count = action.count;
        var data = action.payload;
        var list = state.packageRackData;

        data.map((n, i) => {
          list.splice(list.findIndex(x => x.Id == n.Id), 1);
          list.push(n);
        })

        return { ...state, packageRackData: list, packageRackDataCount: count, packageRackUpdate: !state.packageRackUpdate };
      }
    case PACKAGE_RACK_DATA:
      return { ...state, packageRackData: action.payload, packageRackDataCount: action.count, packageRackUpdate: !state.packageRackUpdate };
    case PACKAGE_RACK_UPDATE:
      return { ...state, packageRackUpdate: !state.packageRackUpdate };
    case HUB_PAYCELL_STOP:
      return { ...state, paycellHubConnection: action.payload };
    case PAYCELL_PAYMENT_TYPES_DATA:
      return { ...state, PaycellPaymentTypes: action.payload };
    case SET_PART_COURIER_ACCOUNT_VISIBLE:
      return { ...state, IsCourierPartAccountVisible: action.payload };
    case ADDITION_PACKAGE_LASTORDER_VISIBLE:
      return { ...state, IsPackageLastOrderControl: action.payload, IsPackageFilterUpdate: !state.IsPackageFilterUpdate };
    case ADDITION_BACK_DATA:
      return { ...state, additionBackModalData: action.payload, IsPackageFilterUpdate: !state.IsPackageFilterUpdate };
    case ADDITION_BACK_VISIBLE:
      return { ...state, additionBackModalVisible: action.payload, IsPackageFilterUpdate: !state.IsPackageFilterUpdate };
    case CUSTOMER_SCREEN_IMAGE:
      return { ...state, customerScreenImage: action.payload, customerScreenUpdate: !state.customerScreenUpdate };
    case CUSTOMER_SCREEN_DATA:
      return {
        ...state, IsAdditionListUpdate: !state.IsAdditionListUpdate, customerScreenData: action.payload, customerScreenUpdate: !state.customerScreenUpdate
      };
    case HUB_CUSTOMERSCREEN_CONNECT:
      return { ...state, customerScreenHubConnection: action.data, customerScreenUpdate: !state.customerScreenUpdate };
    case ADDITION_HOLD_LIST_DATA:
      return { ...state, AdditionHoldList: action.payload, IsAdditionHoldListUpdate: !state.IsAdditionHoldListUpdate };
    case ADDITION_HOLD_LIST_VISIBLE:
      return { ...state, IsAdditionHoldListVisible: action.payload, IsAdditionHoldListUpdate: !state.IsAdditionHoldListUpdate };
    case ADDITION_ISHOLD:
      return { ...state, additionIsHold: action.payload };
    case MAXIJETT_COOKINGTIME:
      return { ...state, maxijettCookingTime: action.payload };
    case MAXIJETT_NEW_LOCATION:
      return { ...state, maxiJettNewLocation: action.payload };
    case SET_OSM_GEOLOCATION:
      return { ...state, osmGeolocation: action.payload };
    case MAXIJETT_DISTANCE_FEE:
      return { ...state, distanceFee: action.payload, maxijettCookingTime: 10 };
    case ADDITION_SPEED_PRODUCTADD:
      return { ...state, IsAdditionSpeedProductAdd: !state.IsAdditionSpeedProductAdd };
    case PACKAGE_COURIER_VISIBLE:
      return { ...state, IsPackageCourrierVisible: action.payload, IsPackageCourrierUpdate: !state.IsPackageCourrierUpdate };
    case PACKAGE_STATUS_VISIBLE:
      return { ...state, IsPackageStatusVisible: action.payload, IsPackageStatusUpdate: !state.IsPackageStatusUpdate };
    case PACKAGEFILTER_DATA_LOADING:
      return { ...state, IsPackageListLoad: action.payload, IsPackageFilterUpdate: !state.IsPackageFilterUpdate };
    case PACKAGEFILTER_DATA_SET:
      return {
        ...state, packageFilter: action.payload, IsPackageFilterUpdate: !state.IsPackageFilterUpdate, IsPackageListLoad: true
      };
    case PACKAGEFILTER_COMPLATED_DATA_SET:
      return {
        ...state, packageFilterComplated: action.payload, IsPackageFilterUpdate: !state.IsPackageFilterUpdate, IsPackageListLoad: true
      };
    case PACKAGEFILTER_DATA_UPDATE:
      return { ...state, IsPackageFilterUpdate: !state.IsPackageFilterUpdate };
    case PACKAGE_GET_ADDITION_DETAIL:
      return {
        ...state, packageAdditionDetail: action.payload, IsPackageAdditionDetailUpdate: !state.IsPackageAdditionDetailUpdate, anchorElPackageDetail: action.anchor
      };
    case PACKAGE_BARCODE_DATA:
      return { ...state, barcodePackageList: action.payload, packageBarcodeUpdate: !state.packageBarcodeUpdate };
    case PACKAGE_BARCODE_VISIBLE:
      return {
        ...state,
        packageBarcodeVisible: action.payload,
        packageBarcodeUpdate: !state.packageBarcodeUpdate,
        barcodePackageList: {
          data: '',
          IsComplated: false,
          BrandId: '',
          RestName: '',
          BarcodeList: []
        }
      };
    case PHONE_CHANGE_VISIBLE:
      return { ...state, modalPhoneChangeVisible: !state.modalPhoneChangeVisible };
    case PHONE_CHANGE_VALUE:
      return { ...state, phoneChangeControlValue: action.payload };
    case ADDITION_START:
      return { ...state, loading: true };
    case SET_PACKAGERELOAD:
      {
        var packageFilter = action.filter;
        var data = action.payload;
        if (packageFilter.PackageButtonStatus == 'Order') {
          data.map((n, i) => {
            packageFilter.List.unshift(n);
          });
        } else if (packageFilter.PackageButtonStatus == 'Pending') {
          data.map((n, i) => {
            if (packageFilter.List.filter(x => x.Id == n.Id).length == 0) {
              packageFilter.List.unshift(n);
            }
          });
        }
        return { ...state, packageFilter };
      }
    case SET_PACKAGERELOAD_UPDATE:
      {
        var packageFilter = action.filter;
        if (packageFilter.PackageButtonStatus == 'Order') {
          var data = action.payload;
          data.map((n, i) => {
            if (packageFilter.List.filter(x => x.Id == n.Id).length > 0) {
              if (n.TableName == 'PrintedUpdate') {
                packageFilter.List.find(x => x.Id == n.Id).PackagePrinted = true;
              } else if (n.TableName == 'StatusUpdate') {
                if (n.PackageStatus == 4) {
                  packageFilter.List.splice(packageFilter.List.findIndex(x => x.Id == n.Id), 1);
                } else {
                  packageFilter.List.find(x => x.Id == n.Id).PackageStatus = n.PackageStatus;
                  packageFilter.List.find(x => x.Id == n.Id).Courrier = n.CourierName;
                }
              }
            } else if (n.PackageStatus == 1) {
              packageFilter.List.unshift(n);
            }
          });
          return { ...state, packageFilter };
        }
      }
    case SET_ADDITIONWAITING:
      return { ...state, additionWaiting: action.payload };
    case SET_CALLERID_CUSTOMER:
      {
        const list = state.callerIdCustomer;
        var data = action.payload;
        list.push(data);
        return { ...state, callerIdCustomer: list };
      }
    case SET_CALLERID_CUSTOMER_REMOVE:
      {
        var data = JSON.parse(JSON.stringify(action.payload));
        return { ...state, callerIdCustomer: data };
      }
    case SETSALES_RETURN:
      return { ...state, IsSalesReturn: !state.IsSalesReturn };
    case PACKAGEPRINT_MODALVISIBLE:
      return { ...state, packagePrintModalVisible: action.payload };
    case SET_ADDITIONWAITING_VISIBLE:
      return { ...state, additionWaitingVisible: action.payload, additionWaitingUpdate: !state.additionWaitingUpdate };
    case SET_SEEPRICE:
      return { ...state, IsSeePrice: action.payload };
    case GETIRCANCEL_OPTIONS:
      return { ...state, getirCancelOptions: action.payload, getirCancelOptionsVisible: (action.payload.length > 0) };
    case TRENDYOLCANCEL_OPTIONS:
      return { ...state, trendyolCancelOptions: action.payload, trendyolCancelOptionsVisible: (action.payload.length > 0) };
    case YEMEKSEPETIGLOBALCANCEL_OPTIONS:
      return { ...state, yemekSepetiGlobalCancelOptions: action.payload, yemekSepetiGlobalCancelOptionsVisible: (action.payload.length > 0) };
    case MIGROSYEMEKCANCEL_OPTIONS:
      return { ...state, migrosYemekCancelOptions: action.payload, migrosYemekCancelOptionsVisible: (action.payload.length > 0) };
    case SCALES_QUANTITY:
      return { ...state, scalesQuantity: action.payload };
    case TREATPRODUCT_QUANTITY:
      return { ...state, treatProductQuantity: action.payload };
    case PRINTER_UPDATE:
      return { ...state, printerUpdate: action.payload };
    case ADDITION_BACK:
      return { ...state, additionBackVisible: !state.additionBackVisible, additionBackVisibleType: action.payload };
    case REPEATORDER_LIST:
      return { ...state, repeatOrderList: action.payload, repeatOrderOpen: action.open };
    case DETAILPAYMENT_SNACKBAR:
      return { ...state, detailPaymentSnackbarText: action.payload, detailPaymentSnackbarVisible: action.payload.length != 0 };
    case ADDITIONPAYMENT_LOADING:
      return { ...state, paymentLoading: action.payload };
    case PAYCELL_PAYMENT_LOADING:
      return { ...state, paycellPaymentLoading: action.payload };
    case INGENICO_PAYMENT_UPDATE:
      return { ...state, ingenicoPaymentUpdate: !state.ingenicoPaymentUpdate };
    case INGENICO_PAYMENT_LOADING:
      return { ...state, ingenicoPaymentLoading: action.payload, ingenicoPaymentUpdate: !state.ingenicoPaymentUpdate };
    case INGENICO_PAYMENT_SUCCESS:
      return { ...state, ingenicoPaymentSuccess: action.payload, ingenicoPaymentUpdate: !state.ingenicoPaymentUpdate, ingenicoTimeOut:action.timeout };
    case PRODUCTDETAIL_OPEN:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          ProductDetailModalOpen: true,
          ProductDetail: action.payload
        },
        productListDetailUpdate: !state.productListDetailUpdate
      };
    case PACKAGELIST_CLEAR:
      return {
        ...state,
        packageFilter: {
          totalRecord: 0,
          List: [],
          PackageStatus: [],
          PackageButtonStatus: 'Pending'
        },
        IsPackageFilterUpdate: !state.IsPackageFilterUpdate
      };
    case PRODUCTDETAIL_CLOSE:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          ProductDetailModalOpen: false,
          ProductDetail: [],
          AdditionEdit: false
        },
        productListDetailUpdate: !state.productListDetailUpdate
      };
    case GET_ADDITION_DATA:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          addition_data: action.payload,
          AdditionPayments: action.payload.Payments,
          AdditionRemoveExplanation: '',
          ProductRemoveExplanation: '',
          additionSaveEnable: true
        },
        IsAdditionListUpdate: !state.IsAdditionListUpdate
      };
    case SETADDITION_CLEAR:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          addition_data: action.payload,
          newPackage: false,
          AdditionRemoveExplanation: '',
          ProductRemoveExplanation: '',
          additionSaveEnable: true,
          AdditionPayments: [],
        },
        IsSalesReturn: false,
        IsAdditionListUpdate: !state.IsAdditionListUpdate,
        additionIsHold: false
      };
    case PRODUCTDETAIL_UPDATE:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          ProductDetail: action.payload
        },
        productListDetailUpdate: !state.productListDetailUpdate
      };
    case PRODUCTLISTDETAIL_UPDATE:
      return { ...state, productListDetailUpdate: !state.productListDetailUpdate };
    case ADDITIONLIST_UPDATE:
      return {
        ...state,
        IsAdditionListUpdate: action.payload,
        form_data: {
          ...state.form_data,
          additionSaveEnable: false,
          numberButtonList: [
            { Name: '1', Id: 1, IsSelected: true },
            { Name: '2', Id: 2, IsSelected: false },
            { Name: '3', Id: 3, IsSelected: false },
            { Name: '4', Id: 4, IsSelected: false },
            { Name: '5', Id: 5, IsSelected: false },
            { Name: '6', Id: 6, IsSelected: false },
            { Name: '7', Id: 7, IsSelected: false },
            { Name: '8', Id: 8, IsSelected: false },
            { Name: '9', Id: 9, IsSelected: false },
            { Name: '10', Id: 10, IsSelected: false }
          ],
        },
        scalesQuantity: 0
      };
    case DETAILEDPAYMENT_VISIBLE:
      return { ...state, IsDetailedPaymentVisible: action.payload, IsDetailedPaymentUpdate: action.payload };
    case DETAILEDPAYMENT_UPDATE:
      return { ...state, IsDetailedPaymentUpdate: !state.IsDetailedPaymentUpdate };
    case ADDITION_ERROR:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          modalOpen: true,
          modalText: action.payload,
          modalTitle: action.caption
        }
      };
    case ADDITION_STOP:
      return { ...state, loading: false, data: action.payload };
    case HUB_STOP:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          loading: false,
          printerHubConnection: action.payload
        }
      };
    case HUBCALLCENTER_STOP:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          loading: false,
          callCenterHubConnection: action.payload
        }
      };
    case HUBCALLERID_STOP:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          loading: false,
          callerIdHubConnection: action.payload
        }
      };
    case HUBSCALES_STOP:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          loading: false,
          scalesHubConnection: action.payload
        }
      };
    case ADDI_GLOBAL_FORM_DATA_SET:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          [action.payload.key]: action.payload.val
        }
      };
    case ADDITION_MODALCLOSE:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          modalOpen: false
        }
      };
    case PRINTERSOCKETNOTIFICATION_VISIBLE:
      return {
        ...state, IsPrinterSocketNotificationVisible: action.open, IsPrinterSocketNotificationText: action.text, IsPrinterSocketNotificationType: action.color, IsPrinterSocketNotificationUpdate: !state.IsPrinterSocketNotificationUpdate
      };
    default:
      return state;
  }
};

export default additionReducer;
