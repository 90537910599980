import axios from 'axios';
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  login
} from './login';
const base64 = require('base-64');

export const REGISTER_START = 'register_start';
export const REGISTER_STOP = 'register_stop';
export const REGISTER_ERROR = 'register_error';
export const REGISTER_MODALCLOSE = 'register_modalclose';

export const REGISTER_CODEMODAL = 'register_codemodal';
export const REGISTER_COMPLATEMODAL = 'register_complatemodal';

export const REGISTER_SET_PAYMENT = 'register_set_payment';

export const DEMO_MODAL_UPDATE = 'demo_modal_update';
export const DEMO_MODAL_VISIBLE = 'demo_modal_visible';
export const DEMO_PHONE_SET = 'demo_phone_set';
export const REGISTER_DATA_SET = 'register_data_set';

const initialState = {
  data: [],
  loading: false,
  error: '',
  modalOpen: false,
  modalTitle: '',
  modalText: '',
  codeModal: false,
  registerCompleteModal: false,
  businessType: -1,
  IsPackage: false,
  demoSmsModalVisible: false,
  demoSmsModalUpdate: false,
  demoSmsPhone: {
    phoneNumber: '',
    brandName: ''
  },
  registerData: null
};

export async function register(register_data) {
  return async (dispatch, getState) => {
    dispatch({ type: REGISTER_START });
    const { registerData } = getState().get('registerReducer');
    register_data.Language = cookie.load('Lang') || 'tr';
    register_data.PaymentTypes = registerData.PaymentTypes;
    register_data.ModuleList = registerData.ModuleList;
    register_data.BusinessWorkingHours = registerData.BusinessWorkingHours;

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/User/UserRegister',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: register_data,
    })
      .then((response) => {
        dispatch({ type: REGISTER_STOP, payload: response.data });
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          reactLocalStorage.remove('l_string');
          reactLocalStorage.remove('remember');
          reactLocalStorage.remove('p_string');
          reactLocalStorage.remove('j_string');
          cookie.save('7d924ee2-eb75-4c2a-afc7-64ebf6f9b43a', '', { path: '/' });
          cookie.save('UserId', json.UserId, { path: '/' });
          cookie.save('AdminLogin', "false", { path: '/' });
          cookie.save('UserName', json.UserName, { path: '/' });
          cookie.save('RestaurantName', json.RestaurantName, { path: '/' });
          cookie.save('BrandId', json.BrandId, { path: '/' });
          cookie.save('TenantId', json.TenantId, { path: '/' });
          cookie.save('CenterBrandId', json.CenterBrandId, { path: '/' });
          cookie.save('UserType', json.UserType, { path: '/' });
          cookie.save('Settings', json.SettingsData, { path: '/' });
          cookie.save('LicenceNumber', json.LicenceNumber, { path: '/' });
          cookie.save('NameSurname', json.NameSurname, { path: '/' });
          cookie.save('Authorization', json.Authorization, { path: '/' });

          reactLocalStorage.setObject('p_string', json.SettingsData);
          reactLocalStorage.setObject('l_string', json.SubscriptionInfo);

          window.location.href = '/mainpage';
        } else {
          dispatch({ type: REGISTER_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
        dispatch({ type: REGISTER_ERROR, payload: error });
      });
  };
}

export async function autoLogin() {
  return async (dispatch, getState) => {
    const data = {
      Email: 'demo@javipos.com',
      Password: '1111',
      IsPIN: false,
      IsRemember: false,
      IsDemo: true
    };
    dispatch(login(data, false));
  };
}


export async function setSmsVerificationCode(phone, type = 0, code = '', IsDemo = false) {
  return async (dispatch, getState) => {
    let phoneNumber = '';
    let brandName = '';
    if (IsDemo) {
      phoneNumber = phone.phoneNumber;
      brandName = phone.brandName;
    } else {
      phoneNumber = phone;
      brandName = '';
    }
    dispatch({ type: REGISTER_START });
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/User/setSmsVerificationCode?phone=' + phoneNumber + '&type=' + type + '&code=' + code + '&IsDemo=' + IsDemo + '&brandName=' + brandName,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        dispatch({ type: REGISTER_STOP, payload: null });
        const json = JSON.parse(response.data.responseData);
        if (type == 0) {
          if (response.data.isStatus) {
            dispatch({ type: (IsDemo ? DEMO_MODAL_VISIBLE : REGISTER_CODEMODAL), payload: true });
          }
        } else if (response.data.isStatus) {
          if (IsDemo) {
            const data = {
              Email: 'demo@javipos.com',
              Password: '1111',
              IsPIN: false,
              IsRemember: false,
              IsDemo: true
            };
            dispatch(login(data, false));
          } else {
            dispatch(getRegisterDetail());
          }
          dispatch({ type: (IsDemo ? DEMO_MODAL_VISIBLE : REGISTER_CODEMODAL), payload: false });
        } else {
          dispatch({ type: REGISTER_ERROR, payload: 'Doğrulama Başarısız' });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getRegisterDetail() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/User/registerDetail',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        dispatch({ type: REGISTER_COMPLATEMODAL, payload: true, data: json });
      })
      .catch((error) => {

      });
  };
}

export async function modalclose() {
  return async (dispatch, getState) => {
    dispatch({ type: REGISTER_MODALCLOSE });
  };
}

export async function setModalCode(visible) {
  return async (dispatch, getState) => {
    dispatch({ type: REGISTER_CODEMODAL, payload: visible });
  };
}

export async function setPayments(data, type) {
  return async (dispatch, getState) => {
    dispatch({ type: REGISTER_SET_PAYMENT, payload: data, type });
  };
}

export async function setRegisterComplateModal(visible) {
  return async (dispatch, getState) => {
    dispatch({ type: REGISTER_COMPLATEMODAL, payload: visible });
  };
}

export async function notificationModal(text) {
  return async (dispatch, getState) => {
    dispatch({ type: REGISTER_ERROR, payload: text });
  };
}

export async function setDemoSmsModalVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({ type: DEMO_MODAL_VISIBLE, payload: visible });
  };
}

export async function setDemoSmsChange(data) {
  return async (dispatch, getState) => {
    dispatch({ type: DEMO_PHONE_SET, payload: data });
  };
}

export async function setDemoSmsModalUpdate() {
  return async (dispatch, getState) => {
    dispatch({ type: DEMO_MODAL_UPDATE });
  };
}

export async function setRegisterData(data) {
  return async (dispatch, getState) => {
    dispatch({ type: REGISTER_DATA_SET, payload: data });
  };
}

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_DATA_SET:
      return { ...state, registerData: action.payload, demoSmsModalUpdate: !state.demoSmsModalUpdate };
    case DEMO_MODAL_UPDATE:
      return { ...state, demoSmsModalUpdate: !state.demoSmsModalUpdate };
    case DEMO_MODAL_VISIBLE:
      return { ...state, demoSmsModalVisible: action.payload, demoSmsModalUpdate: !state.demoSmsModalUpdate };
    case DEMO_PHONE_SET:
      return { ...state, demoSmsPhone: action.payload, demoSmsModalUpdate: !state.demoSmsModalUpdate };
    case REGISTER_START:
      return { ...state, loading: true };
    case REGISTER_CODEMODAL:
      return { ...state, codeModal: action.payload };
    case REGISTER_SET_PAYMENT:
      return { ...state, paymentType: action.payload, businessType: action.type };
    case REGISTER_COMPLATEMODAL:
      return { ...state, registerCompleteModal: action.payload, registerData: (action.payload ? action.data : null) };
    case REGISTER_ERROR:
      return {
        ...state, loading: false, modalOpen: true, modalTitle: 'Uyarı', modalText: action.payload
      };
    case REGISTER_STOP:
      return { ...state, loading: false, data: action.payload };
    case REGISTER_MODALCLOSE:
      return { ...state, modalOpen: false };
    default:
      return state;
  }
};

export default registerReducer;
