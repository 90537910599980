import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Ionicon from 'react-ionicons';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import UserMenu from './UserMenu';
import styles from './header-jss';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Link } from 'react-router-dom';
import { clearPackage, formDataSet, clearAddition, setAdditionHoldListVisible } from '../../redux/modules/addition';
import { setLockScreenVisible } from '../../redux/modules/user';
import { setSelectedTableUpdate, tableformDataSet, setTableUpdate } from '../../redux/modules/table';
import { formDataSetStock } from '../../redux/modules/stock';
import { setAdditionListModalVisible, redirectMainPage } from '../../redux/modules/report';
import { getNotification, setNotificationUpdate } from '../../redux/modules/settings';
import cookie from 'react-cookies';
import LockScreen from './LockScreen';
import Notification from './Notification';
import Badge from '@material-ui/core/Badge';
import { getLicenceModule, getLicenceModulePackage } from '../../function/GeneralFunction';
import Icon from '@material-ui/core/Icon';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Divider from '@material-ui/core/Divider';
import { reactLocalStorage } from 'reactjs-localstorage';

const elem = document.documentElement;

const MenuList = [
  { tooltip: 'Ana Sayfa', name: 'home', icon: 'home', color: '#fff' },
  { tooltip: 'Paketler', name: 'package', icon: 'delivery_dining', color: '#fff' },
  { tooltip: 'Gel Al Listesi', name: 'bolt', icon: 'bolt', color: '#fff' },
  { tooltip: 'Masa İşlemleri', name: 'touch_app', icon: 'touch_app', color: '#fff' },
  { tooltip: 'Ekranı Kapla', name: 'fullscreen', icon: 'fullscreen', color: '#fff' },
  { tooltip: 'Kilitle', name: 'lock', icon: 'lock', color: '#fff' },
  { tooltip: 'Adisyon Listesi', name: 'menu_book', icon: 'menu_book', color: '#fff' },
  { tooltip: 'Bildirimler', name: 'notifications', icon: 'notifications', color: '#fff' }
];

class Header extends React.Component {
  state = {
    open: false,
    fullScreen: false,
    turnDarker: false,
    showTitle: false,
    dialOpen: false
  };

  // Initial header style
  flagDarker = false;

  flagTitle = false;

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const newFlagDarker = (scroll > 30);
    const newFlagTitle = (scroll > 40);
    if (this.flagDarker !== newFlagDarker) {
      this.setState({ turnDarker: newFlagDarker });
      this.flagDarker = newFlagDarker;
    }
    if (this.flagTitle !== newFlagTitle) {
      this.setState({ showTitle: newFlagTitle });
      this.flagTitle = newFlagTitle;
    }
  }

  openFullScreen = () => {
    this.setState({ fullScreen: true });
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  closeFullScreen = () => {
    this.setState({ fullScreen: false });
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  turnMode = mode => {
    const { changeMode } = this.props;
    if (mode === 'light') {
      changeMode('dark');
    } else {
      changeMode('light');
    }
  };

  handlePackage = () => {
    this.props.history.push('/app/packagepage?type=order');
  };

  handleMainPage = () => {
    this.props.redirectMainPage(true).then(() => { })
  };

  handleScreenLock = () => {
    this.props.setLockScreenVisible(!this.props.IsVisibleLockScreen).then(() => { })
  };

  handleTableOperation = () => {
    this.props.tableformDataSet("IsMenuVisible", !this.props.IsMenuVisible).then(() => { })
    this.props.setTableUpdate().then(() => { });
  };

  handleComeGetIt = () => {
    this.props.setAdditionHoldListVisible(true).then(() => { })
  };

  handleAdditionListModal = () => {
    this.props.setAdditionListModalVisible(true).then(() => { })
  };

  handleNotificationList = async (open) => {
    if (open == true) {
      this.props.getNotification().then(() => { });
    } else {
      this.props.setNotificationUpdate(false).then(() => { })
    }
  };

  render() {
    const {
      classes,
      margin,
      position,
      gradient,
      mode,
      title,
      openGuide,
      history
    } = this.props;
    const {
      fullScreen,
      open,
      turnDarker,
      showTitle
    } = this.state;
    const setMargin = (sidebarPosition) => {
      if (sidebarPosition === 'right-sidebar') {
        return classes.right;
      }
      return classes.left;
    };
    return (
      <AppBar
        className={
          classNames(
            classes.appBar,
            classes.floatingBarNew,
            margin && classes.appBarShift,
            setMargin(position)
          )
        }
      >
        <LockScreen classes={classes} />
        <Notification classes={classes} />
        <Toolbar style={{ padding: 4, display: 'block', height: '100%' }}>
          {
            MenuList.map((n, i) => {
              var iconStyle = { height: 50, width: '100%', borderRadius: 1, color: n.color };
              var tooltipTitle = n.tooltip;
              var buttonHandle = null;

              if (n.name == 'home') {
                buttonHandle = this.handleMainPage;
                iconStyle = { backgroundColor: '#3498db', marginTop: 1, height: 50, width: '100%', borderRadius: 0, color: n.color, display: (cookie.load('UserType') == "Waiter" ? 'none' : 'inline-flex') };
              } else if (n.name == 'package') {
                buttonHandle = this.handlePackage;
                iconStyle = { marginTop: 1, height: 50, width: '100%', borderRadius: 0, color: n.color, backgroundColor: '#e74c3c', display: (cookie.load('UserType') == "Waiter" ? 'none' : (getLicenceModulePackage() ? '' : 'none')) };
              } else if (n.name == 'lock') {
                buttonHandle = this.handleScreenLock;
                iconStyle = { height: 50, marginTop: 1, width: '100%', borderRadius: 0, borderBottom: '1px solid grey' };
              } else if (n.name == 'fullscreen') {
                buttonHandle = (fullScreen ? this.closeFullScreen : this.openFullScreen);
                iconStyle = { height: 50, marginTop: 1, width: '100%', borderRadius: 0, borderBottom: '1px solid grey' };
              } else if (n.name == 'touch_app') {
                buttonHandle = this.handleTableOperation;
                iconStyle = { marginTop: 1, height: 50, width: '100%', borderRadius: 0, color: n.color, backgroundColor: '#f39c12' };
              } else if (n.name == 'bolt') {
                buttonHandle = this.handleComeGetIt;
                iconStyle = { marginTop: 1, height: 50, width: '100%', borderRadius: 0, color: n.color, backgroundColor: '#9b59b6' };
              } else if (n.name == 'menu_book') {
                buttonHandle = this.handleAdditionListModal;
                iconStyle = { height: 50, marginTop: 1, width: '100%', borderRadius: 0, borderBottom: '1px solid grey' };
              } else if (n.name == 'notifications') {
                iconStyle = { color: 'rgba(255, 177, 66,1.0)', height: 50, marginTop: 1, width: '100%', borderRadius: 0, borderBottom: '1px solid grey' };
              }

              return (
                <div key={i}>
                  <Tooltip title={tooltipTitle} placement="right">
                    {
                      (n.name == 'notifications' ?
                      <IconButton style={iconStyle} className={classes.button} onClick={() => this.handleNotificationList(true)}>
                        <Badge badgeContent={this.props.NotificationList.filter(x => x.IsVisible == true).length} color="error">
                          <Icon>{n.icon}</Icon>
                        </Badge>
                        </IconButton>
                        :
                        <IconButton style={iconStyle} className={classes.button} onClick={buttonHandle}>
                          <Icon>{n.icon}</Icon>
                        </IconButton>
                      )
                    }
                  </Tooltip>
                </div>
              )
            })
          }
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  margin: PropTypes.bool.isRequired,
  gradient: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  changeMode: PropTypes.func.isRequired,
  openGuide: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    additionReducer: state.get('additionReducer').form_data,
    IsNotificationUpdate: state.get('settingsReducer').IsNotificationUpdate,
    IsVisibleLockScreen: state.get('userReducer').IsVisibleLockScreen,
    NotificationList: state.get('settingsReducer').NotificationList,
    IsMenuVisible: state.get('tableReducer').form_data.IsMenuVisible,
  };
};

const mapDispatchToProps = {
  clearPackage,
  formDataSet,
  setLockScreenVisible,
  setSelectedTableUpdate,
  tableformDataSet,
  setTableUpdate,
  clearAddition,
  setAdditionHoldListVisible,
  setAdditionListModalVisible,
  redirectMainPage,
  formDataSetStock,
  getNotification,
  setNotificationUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
