import cookie from 'react-cookies';

let initialState = {

}

export async function setLanguage(lang){
  return async (dispatch,getState) => {
    cookie.save('Lang', lang, { path: '/' });
    window.location.href = window.location.href;
  }
}

const languageReducer = (state = initialState, action) => {
	switch (action.type) {
    default:
		return state;
  }
}

export default languageReducer;
