import React from 'react';
import { connect } from 'react-redux';
import {
    withStyles
} from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    createSelector
} from 'reselect';
import Button from '@material-ui/core/Button';
import {
    setProductAddAllVisible, setProductAddAllData, getStockGroup, setProductAllSave
} from '../../../../redux/modules/stock';
import Typography from '@material-ui/core/Typography';
import { CapitalizeText } from '../../../../function/GeneralFunction';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { CirclePicker } from 'react-color';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
const uuidv4 = require('uuid/v4');
import AlertDialog from '../../Ortak/AlertDialog';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';

const styles = theme => ({
    dropdownStyle: {
        border: "1px solid #ecf0f1",
        borderRadius: "5%",
    },
    dialogPaperAddAll: {
        margin: 10,
        maxHeight: 'calc(100% - 30px)'
    },
});

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            fixedDecimalScale={true}
            autocomplete="off"
            allowNegative={false}
            decimalSeparator=","
            thousandSeparator="."
            prefix="₺"
        />
    );
}

class ProductAddAll extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.getStockGroup(false).then(() => { });
    }

    shouldComponentUpdate(nextProps) {
        return true;
    }

    async handleProductAdd() {
        const {
            productAddAllData
        } = this.props;

        var product = {
            itemId: uuidv4(),
            categoryId: "00000000-0000-0000-0000-000000000000",
            productName: "",
            unitList: [{
                unitName: "Adet",
                normalPrice: 0,
                packagePrice: 0
            }]
        };

        productAddAllData.unshift(product);
        this.props.setProductAddAllData(productAddAllData).then(() => { })
    }

    handleChangeAddProduct = (name, i) => event => {
        const {
            productAddAllData
        } = this.props;

        if (name == "productName") {
            event.target.value = CapitalizeText(event.target.value);
        }

        productAddAllData[i][name] = event.target.value;

        this.props.setProductAddAllData(productAddAllData).then(() => { })
    };

    handleChangeAddUnit = (name, i, x) => event => {
        const {
            productAddAllData
        } = this.props;

        if (name == "unitName") {
            event.target.value = CapitalizeText(event.target.value);
        }else if (name == "normalPrice" || name == "packagePrice") {
            event.target.value = parseFloat(event.target.value);
        }

        productAddAllData[x].unitList[i][name] = event.target.value;

        this.props.setProductAddAllData(productAddAllData).then(() => { })
    };

    unitAdd = (i, x) => event => {
        const {
            productAddAllData
        } = this.props;

        productAddAllData[x].unitList.push({
            unitName: "",
            normalPrice: 0,
            packagePrice: 0
        });

        this.props.setProductAddAllData(productAddAllData).then(() => { })
    };

    unitAddDelete = (i, x) => event => {
        const {
            productAddAllData
        } = this.props;

        productAddAllData[x].unitList.splice(i, 1);

        this.props.setProductAddAllData(productAddAllData).then(() => { })
    };

    proDelete = i => event => {
        const {
            productAddAllData
        } = this.props;

        productAddAllData.splice(i, 1);

        this.props.setProductAddAllData(productAddAllData).then(() => { })
    };

    renderUnit(unitList, x) {
        {
            return unitList.map((n, i) => {
                return (
                    <Grid container spacing={1}>
                        <Grid item md={1}>
                            {
                                (unitList.length == 1 || unitList.length == i + 1 ?
                                    <Button fullWidth style={{ height: 47, marginTop: 16, borderRadius: 5, backgroundColor: 'rgba(39, 174, 96,1.0)' }} variant="contained" onClick={this.unitAdd(i, x)} color="primary">
                                        <Icon>{"add"}</Icon>
                                    </Button>
                                    : null)
                            }
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                size="small"
                                defaultValue={n.unitName}
                                id="unitName"
                                label="Birim Adı"
                                onChange={this.handleChangeAddUnit("unitName", i, x)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={n.normalPrice}
                                id="normalPrice"
                                label="Normal Fiyatı"
                                onChange={this.handleChangeAddUnit("normalPrice", i, x)}
                                fullWidth
                                onFocus={event => { event.target.select() }}
                                size="small"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                value={n.packagePrice}
                                id="packagePrice"
                                label="Paket Fiyatı"
                                onChange={this.handleChangeAddUnit("packagePrice", i, x)}
                                fullWidth
                                onFocus={event => { event.target.select() }}
                                size="small"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                            />
                        </Grid>
                        <Grid item md={1}>
                            <Button fullWidth style={{ height: 47, marginTop: 16, borderRadius: 5, backgroundColor: 'rgba(231, 76, 60,1.0)' }} variant="contained" onClick={this.unitAddDelete(i, x)} color="primary">
                                <Icon>{"delete"}</Icon>
                            </Button>
                        </Grid>
                    </Grid>
                )
            })
        }
    }

    renderProductList() {
        const {
            classes,
            productAddAllData,
            categoryList
        } = this.props;

        return productAddAllData.map((n, i) => {
            return (
                <Grid key={n.itemId} container spacing={1} style={{ marginBottom: 15, backgroundColor: '#313131', padding: 10, borderRadius: 5, border: '1px solid rgba(149, 165, 166, 0.2)' }}>
                    <Grid item md={3}>
                        <FormControl className={classes.formControl} style={{ margin: 0, marginTop: 0 }}>
                            <InputLabel htmlFor="age-helper">{"Kategori"}</InputLabel>
                            <Select
                                value={n.categoryId}
                                onChange={this.handleChangeAddProduct("categoryId", i)}
                                input={<Input name="categoryId" id="age-helper" />}
                            >
                                <MenuItem value="00000000-0000-0000-0000-000000000000">
                                    <em>{"Seçiniz"}</em>
                                </MenuItem>
                                {
                                    categoryList.map(n => ([
                                        <MenuItem key={n.Id} value={n.Id}>{n.Name}</MenuItem>
                                    ]))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={8}>
                        <TextField
                            margin="none"
                            defaultValue={n.productName}
                            id="productName"
                            label="Ürün Adı"
                            onChange={this.handleChangeAddProduct("productName", i)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={1} style={{ textAlign: 'end', paddingTop: 18 }}>
                        <Tooltip title="Sil">
                            <IconButton style={{ backgroundColor: '#3e3e3e' }} onClick={this.proDelete(i)}>
                                <Icon>{"delete_outline"}</Icon>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item md={12}>
                        <div style={{ border: '1px solid rgba(149, 165, 166, 0.2)', backgroundColor: '#383838', padding: 5, borderRadius: 5 }}>
                            {this.renderUnit(n.unitList, i)}
                        </div>
                    </Grid>
                </Grid>
            )
        })
    }

    render() {
        const {
            classes,
            productAddAllLoading,
            filterDataProduct,
            productAddAllData
        } = this.props;

        return (
            <Dialog
                open={this.props.productAddAllVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'md'}
                PaperProps={{ classes: { root: classes.dialogPaperAddAll } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    <Grid container>
                        <Grid item md={10} style={{ fontSize: 21 }}>
                            {"Toplu Ürün Ekleme İşlemleri"}
                            <Typography className={classes.instructions} style={{ fontSize: 13, fontWeight: 500, color: 'white' }}>
                                {"Hızlı ürün ekleme ekranı sayesinde onlarca ürünü hızlı ve kolay ekleyebilirsiniz!"}
                            </Typography>
                        </Grid>
                        <Grid item md={2} style={{ textAlign: 'right' }}>
                            <Button variant="contained" onClick={() => this.handleProductAdd()} color="primary">
                                {"ÜRÜN EKLE"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto', height: '100vh' }}>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ display: 'block' }}>
                            {this.renderProductList()}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={productAddAllLoading} variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setProductAddAllVisible(false).then(() => { })} color="primary">
                        {"İPTAL"}
                    </Button>
                    <Button disabled={productAddAllLoading} variant="contained" onClick={() => this.props.setProductAllSave().then(() => { })} color="secondary">
                        {"ÜRÜNLERİ KAYDET"}
                    </Button>
                </DialogActions>
            </Dialog >
        );
    }
}

const valueSelector = (state, props) => state.get('stockReducer').productAddAllUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    productAddAllUpdate: value(state, props),
    productAddAllVisible: state.get('stockReducer').productAddAllVisible,
    productAddAllData: state.get('stockReducer').productAddAllData,
    productAddAllLoading: state.get('stockReducer').productAddAllLoading,
    filterDataProduct: state.get('stockReducer').filterDataProduct,
    categoryList: state.get('stockReducer').form_data.categoryList,
})

const mapDispatchToProps = {
    setProductAddAllVisible,
    setProductAddAllData,
    getStockGroup,
    setProductAllSave
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withStyles(styles)(injectIntl(ProductAddAll)));
