import cookie from 'react-cookies';
import { HubConnectionBuilder, LogLevel, HttpTransportType, HubConnectionState } from "@microsoft/signalr";
import axios from 'axios';
var base64 = require('base-64');
import brand from 'dan-api/dummy/brand';
import {
  setModal
} from './settings';

export const TABLE_START = 'table_start';
export const TABLE_STOP = 'table_stop';
export const TABLE_ERROR = 'table_error';
export const TABLE_MODAL_STOP = 'table_modal_stop';
export const TABLE_ARRAY = 'table_array';
export const GET_TABLEGROUP = 'get_tablegroup';
export const TABLE_GLOBAL_FORM_DATA_SET = 'table_global_form_data_set';

export const TABLE_SELECTED_ID = 'table_selected_id';
export const TABLE_SELECTED_UPDATE = 'table_selected_update';
export const TABLE_UPDATE = 'table_update';

export const TABLE_SETTINGS_VISIBLE = 'table_settings_visible';
export const TABLE_SETTINGS_UPDATE = 'table_settings_update';

export const TABLEGROUP_DATA_UPDATE = 'tablegroup_data_update';
export const TABLEGROUP_VISIBLE = 'tablegroup_visible';

export const TABLE_DATA_UPDATE = 'table_data_update';
export const TABLE_VISIBLE = 'table_visible';

let initialState = {
  loading: false,
  error: '',
  tableList: [],
  SelectedTableId: "",
  IsSelectedTableUpdate: false,
  tableGroupList: [],
  groupname: 'zater',
  tableHubConnection: null,
  form_data: {
    modalOpen: false,
    modalText: "",
    modalTitle: "",
    selectedTableText: "",
    selectedTableId: "",
    selectedAdditionId: "",
    settingTableList: [],
    groupAddButtonVisible: false,
    groupAddName: "",
    groupAddOrder: 0,

    tableAddButtonVisible: false,

    tableGroupId: "",

    RemoveType: "",
    RemoveId: "",
    RemoveButtonVisible: false,
    RemoveModalVisible: false,

    EditValue: "",
    EditType: "",
    EditId: "",
    EditOrder: 0,
    EditButtonVisible: false,
    EditModalVisible: false,
    IsMenuVisible: false,
    IsMenuTitle: "",
    SnackbarVisible: false,
    SnackbarText: "",
    IsMenuOperationVisible: false,
    IsMenuOperationType: "",
    IsMenuOperationText: "",
    operationSelectedTableList: [],
    transferSelectedTable: null,
    transferContinues: false
  },
  IsTableSocketNotificationUpdate: false,
  IsTableSocketNotificationText: "",
  IsTableSocketNotificationType: "warning",
  IsTableUpdate: false,

  IsTableSettingsUpdate: false,
  IsTableSettingsVisible: false,

  tableGroupData: {
    BrandId: "",
    TenantId: "",
    Id: "00000000-0000-0000-0000-000000000000",
    Order: 1,
    GroupName: "",
    IsUpdate: false
  },
  groupAddModalVisible: false,

  tableData: {
    BrandId: "",
    TenantId: "",
    TableName: "",
    TableCount: 1,
    IsOrderTableAdd: false,
    Order: 0,
    IsUpdate: false,
    Id: "00000000-0000-0000-0000-000000000000",
    TableGroupId: "00000000-0000-0000-0000-000000000000",
  },
  tableAddModalVisible: false,
}

export async function groupTable() {
  return async (dispatch,getState) => {
    const { transferSelectedTable, operationSelectedTableList } = getState().get('tableReducer').form_data;
    var GuidList = [];
    operationSelectedTableList.map((n, i) => {
      GuidList.push(n.TableId);
    })

    let data = {
      UserName: cookie.load('UserName'),
      UserId: cookie.load('UserId'),
      TenantId: cookie.load('TenantId'),
      BrandId: cookie.load('BrandId'),
      StartTableList: GuidList
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/groupTable',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          dispatch(tableformDataSet("SnackbarVisible", false));
          dispatch(tableformDataSet("IsMenuOperationVisible", false));
          dispatch(tableformDataSet("SnackbarText", ""));
          dispatch(tableformDataSet("IsMenuOperationText", ""));
          dispatch(tableformDataSet("operationSelectedTableList", []));
          dispatch(tableformDataSet("transferContinues", false));
          dispatch(tableformDataSet("transferSelectedTable", null));
        } else {
          dispatch({ type: TABLE_ERROR, payload: response.data.responseData })
        }
      })
      .catch(function (error) {
        dispatch({ type: TABLE_ERROR, payload: error })
      });
  }
}

export async function transferTable() {
  return async (dispatch, getState) => {
    const { transferSelectedTable, operationSelectedTableList } = getState().get('tableReducer').form_data;
    var GuidList = [];
    operationSelectedTableList.map((n, i) => {
      GuidList.push(n.TableId);
    })

    let data = {
      UserName: cookie.load('UserName'),
      UserId: cookie.load('UserId'),
      TableId: transferSelectedTable,
      StartTableList: GuidList
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Addition/transferTable',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          dispatch(tableformDataSet("SnackbarVisible", false));
          dispatch(tableformDataSet("IsMenuOperationVisible", false));
          dispatch(tableformDataSet("SnackbarText", ""));
          dispatch(tableformDataSet("IsMenuOperationText", ""));
          dispatch(tableformDataSet("operationSelectedTableList", []));
          dispatch(tableformDataSet("transferContinues", false));
          dispatch(tableformDataSet("transferSelectedTable", null));
        } else {
          dispatch({ type: TABLE_ERROR, payload: response.data.responseData })
        }
      })
      .catch(function (error) {
        dispatch({ type: TABLE_ERROR, payload: error })
      });
  }
}

export async function setTableAddCrud(data) {
  return async (dispatch,getState) => {
    data.BrandId = cookie.load('BrandId');
    data.TenantId = cookie.load('TenantId');

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Table/setTableAddCrud',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          json.find(x => x.TableGroupId == data.TableGroupId).IsSelected = true;
          dispatch(tableformDataSet("settingTableList", json));
          dispatch(setTableDataUpdate({
            BrandId: "",
            TenantId: "",
            TableName: "",
            TableGroupId: "00000000-0000-0000-0000-000000000000",
            TableCount: 1,
            IsOrderTableAdd: false,
            Order: 0,
            IsUpdate: false,
            Id: "00000000-0000-0000-0000-000000000000"
          }));
          dispatch(setTableSettingsUpdate());
        }
      })
      .catch(function (error) {
        dispatch({ type: TABLE_ERROR, payload: error })
      });
  }
}

export async function setTableGroupCrud(data) {
  return async (dispatch,getState) => {
    data.BrandId = cookie.load('BrandId');
    data.TenantId = cookie.load('TenantId');
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Table/setTableGroupCrud',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
      data: data
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(tableformDataSet("settingTableList", json));
          dispatch(setTableGroupDataUpdate({
            BrandId: "",
            TenantId: "",
            Id: "00000000-0000-0000-0000-000000000000",
            Order: 1,
            GroupName: "",
            IsUpdate: false
          }));
          dispatch(setTableSettingsUpdate());
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch(function (error) {
        dispatch({ type: TABLE_ERROR, payload: error })
      });
  }
}

export async function setRemoveGroupOrTable(RemoveId, RemoveType, TableGroupId = "") {
  return async (dispatch,getState) => {
    var BrandId = cookie.load('BrandId');
    var TenantId = cookie.load('TenantId');
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Table/setRemoveGroupOrTable?BrandId=' + BrandId + '&TenantId=' + TenantId + '&Id=' + RemoveId + '&type=' + RemoveType,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          if (RemoveType == "Table") {
            json.find(x => x.TableGroupId == TableGroupId).IsSelected = true;
            dispatch(setTableVisible(false));
          } else {
            dispatch(setTableGroupVisible(false));
          }

          dispatch(tableformDataSet("settingTableList", json));
          dispatch(setTableSettingsUpdate());
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch(function (error) {
        dispatch({ type: TABLE_ERROR, payload: error })
      });
  }
}

export async function setEditGroupOrTable() {
  return async (dispatch, getState) => {
    const { EditId, EditType, tableGroupId, EditValue, EditOrder } = getState().get('tableReducer').form_data;
    var BrandId = cookie.load('BrandId');
    var TenantId = cookie.load('TenantId');
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Table/setEditGroupOrTable?BrandId=' + BrandId + '&TenantId=' + TenantId + '&Id=' + EditId + '&type=' + EditType + '&name=' + EditValue + '&Order=' + EditOrder,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          if (EditType == "Table") {
            json.find(x => x.TableGroupId == tableGroupId).IsSelected = true;
          }
          dispatch(tableformDataSet("settingTableList", json));
        }
      })
      .catch(function (error) {
        dispatch({ type: TABLE_ERROR, payload: error })
      });
  }
}

export async function getTableList() {
  return async (dispatch,getState) => {
    var BrandId = cookie.load('BrandId');
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Table/getTableList?BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          json[0].IsSelected = true;
          dispatch(tableformDataSet("settingTableList", json));
          dispatch(setTableSettingsUpdate());
        }
      })
      .catch(function (error) {
        dispatch({ type: TABLE_ERROR, payload: error })
      });
  }
}

export async function setTableDefault(TableGroupId) {
  return async (dispatch,getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Table/setTableDefault?TableGroupId=' + TableGroupId,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then(function (response) {
        dispatch(setModal(response.data.responseData));
        dispatch(getTableList());
      })
      .catch(function (error) {
        dispatch(setModal(error));
      });
  }
}

export async function setTableSizeLocation(data) {
  return async (dispatch,getState) => {
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Table/setTableSizeLocation',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: data,
    })
      .then(function (response) {
        dispatch(setModal(response.data.responseData))
      })
      .catch(function (error) {
        dispatch(setModal(error))
      });
  }
}

export async function setTableHubConnection() {
  return async (dispatch,getState) => {
    dispatch({ type: TABLE_START });
    var BrandId = cookie.load('BrandId');
    var UserId = cookie.load('UserId');
    const tableHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          } else {
            // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
            return null;
          }
        }
      })
      .withUrl(brand.urlSocket + "table?GroupName=" + BrandId + "&UserId=" + UserId, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();
  
    await connect(tableHubConnection, dispatch);

    dispatch({ type: TABLE_STOP, payload: tableHubConnection });
  }
}

async function connect(conn, dispatch) {
  await conn.start().then(() => {
    conn.on('GetAllMessageTable', (message) => {
      var json = JSON.parse(message);
      dispatch(getTable(json));
    });
  }).catch(e => {
    sleepCallerId(3000);
    connect(conn, dispatch);
  })
}

async function sleep(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

export async function getTable(message) {
  return async (dispatch,getState) => {
    dispatch({ type: TABLE_ARRAY, payload: message });
  }
}

export async function sendMessage() {
  return async (dispatch, getState) => {
    const { tableHubConnection, tableGroupList } = getState().get('tableReducer');
    if(tableGroupList.length > 0){
      var GroupName = cookie.load('BrandId');

      if (tableHubConnection.state == HubConnectionState.Disconnected) {
        dispatch({ type: TABLE_ARRAY, payload: [] });
        await connect(tableHubConnection, dispatch);
        dispatch({ type: TABLE_STOP, payload: tableHubConnection });
      } else if (tableHubConnection.state == HubConnectionState.Reconnecting) {
        dispatch({ type: TABLE_ARRAY, payload: [] });
        await connect(tableHubConnection, dispatch);
        dispatch({ type: TABLE_STOP, payload: tableHubConnection });
      }
  
      var TableGroupId = tableGroupList.find(x => x.IsSelected == true).Id;
      const aa = await tableHubConnection.invoke('BroadCastMessage', TableGroupId, GroupName).then(() => { }).catch(err => console.error(err));
    }
  }
}

export async function sendMessagePayment() {
  return async (dispatch,getState) => {
    const { tableHubConnection, tableGroupList } = getState().get('tableReducer');
    var GroupName = cookie.load('BrandId');

    if (tableHubConnection.state == HubConnectionState.Disconnected) {
      dispatch({ type: TABLE_ARRAY, payload: [] });
      await connect(tableHubConnection, dispatch);
      dispatch({ type: TABLE_STOP, payload: tableHubConnection });
    } else if (tableHubConnection.state == HubConnectionState.Reconnecting) {
      dispatch({ type: TABLE_ARRAY, payload: [] });
      await connect(tableHubConnection, dispatch);
      dispatch({ type: TABLE_STOP, payload: tableHubConnection });
    }

    var TableGroupId = tableGroupList.find(x => x.IsSelected == true).Id;
    const aa = await tableHubConnection.invoke('BroadCastMessage', TableGroupId, GroupName).then(() => { }).catch(err => console.error(err));
  }
}

export async function setTable(id) {
  return async (dispatch,getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Table/setTableStatus?Id=' + id + '',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then(function (response) {

      })
      .catch(function (error) {
        dispatch({ type: TABLE_ERROR, payload: error })
      });
  }
}

export async function getTableGroup() {
  return async (dispatch,getState) => {
    var BrandId = cookie.load('BrandId');
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Table/getTableGroup?BrandId=' + BrandId + '',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: GET_TABLEGROUP, payload: json.Group, table: json.Table });
        }
      })
      .catch(function (error) {
        dispatch({ type: TABLE_ERROR, payload: error })
      });
  }
}

export async function tableformDataSet(key, val) {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLE_GLOBAL_FORM_DATA_SET,
      payload: { key, val }
    });
  };
}

export async function setModalTable(text, title) {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLE_ERROR,
      payload: text,
      title: title
    });
  };
}

export async function setModalClose() {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLE_MODAL_STOP
    });
  };
}

export async function setTableUpdate() {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLE_UPDATE
    });
  };
}

export async function setSelectedTableId(id) {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLE_SELECTED_ID,
      payload: id
    });
  };
}

export async function setSelectedTableUpdate(visible) {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLE_SELECTED_UPDATE,
      payload: visible
    });
  };
}

export async function setTableSettingsVisible(open) {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLE_SETTINGS_VISIBLE,
      payload: open
    });
  };
}

export async function setTableSettingsUpdate() {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLE_SETTINGS_UPDATE
    });
  };
}

export async function setTableGroupDataUpdate(data) {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLEGROUP_DATA_UPDATE,
      payload: data
    });
  };
}

export async function setTableGroupVisible(visible) {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLEGROUP_VISIBLE,
      payload: visible
    });
  };
}

export async function setTableDataUpdate(data) {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLE_DATA_UPDATE,
      payload: data
    });
  };
}

export async function setTableVisible(visible) {
  return async (dispatch,getState) => {
    dispatch({
      type: TABLE_VISIBLE,
      payload: visible
    });
  };
}

const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case TABLE_VISIBLE:
      return {
        ...state, tableAddModalVisible: action.payload, tableData: {
          BrandId: "",
          TenantId: "",
          TableName: "",
          TableCount: 1,
          IsOrderTableAdd: false,
          Order: 0,
          IsUpdate: false,
          Id: "00000000-0000-0000-0000-000000000000",
          TableGroupId: "00000000-0000-0000-0000-000000000000",
        }, IsTableSettingsUpdate: !state.IsTableSettingsUpdate
      }
    case TABLE_DATA_UPDATE:
      return { ...state, tableData: action.payload, IsTableSettingsUpdate: !state.IsTableSettingsUpdate }
    case TABLEGROUP_VISIBLE:
      return {
        ...state, groupAddModalVisible: action.payload, tableGroupData: {
          BrandId: "",
          TenantId: "",
          Id: "00000000-0000-0000-0000-000000000000",
          Order: 1,
          GroupName: "",
          IsUpdate: false
        }, IsTableSettingsUpdate: !state.IsTableSettingsUpdate
      }
    case TABLEGROUP_DATA_UPDATE:
      return { ...state, tableGroupData: action.payload, IsTableSettingsUpdate: !state.IsTableSettingsUpdate }
    case TABLE_SETTINGS_UPDATE:
      return { ...state, IsTableSettingsUpdate: !state.IsTableSettingsUpdate }
    case TABLE_SETTINGS_VISIBLE:
      return { ...state, IsTableSettingsVisible: action.payload, IsTableSettingsUpdate: !state.IsTableSettingsUpdate }
    case TABLE_SELECTED_UPDATE:
      return { ...state, IsSelectedTableUpdate: action.payload, IsTableUpdate: !state.IsTableUpdate }
    case TABLE_SELECTED_ID:
      return { ...state, SelectedTableId: action.payload, IsTableUpdate: !state.IsTableUpdate }
    case TABLE_STOP:
      return { ...state, loading: false, tableHubConnection: action.payload }
    case TABLE_UPDATE:
      return { ...state, IsTableUpdate: !state.IsTableUpdate }
    case TABLE_ERROR:
      return {
        ...state, form_data: {
          ...state.form_data,
          modalOpen: true,
          modalText: action.payload,
          modalTitle: action.title || "Uyarı"
        }
      }
    case TABLE_MODAL_STOP:
      return {
        ...state, form_data: {
          ...state.form_data,
          modalOpen: false,
          modalText: "",
          modalTitle: "Uyarı"
        }
      }
    case TABLE_ARRAY:
      {
        if (action.payload == []) {
          return { ...state, loading: false, tableList: [], IsTableUpdate: !state.IsTableUpdate }
        }
        if (state.tableGroupList.find(x => x.IsSelected == true).Id == action.payload[0].TableGroupId) {
          return { ...state, loading: false, tableList: action.payload, IsTableUpdate: !state.IsTableUpdate }
        } else {
          return { ...state, loading: false, tableList: state.tableList, IsTableUpdate: !state.IsTableUpdate }
        }
      }
    case GET_TABLEGROUP:
      return { ...state, tableGroupList: action.payload, tableList: action.table, IsTableUpdate: !state.IsTableUpdate }
    case TABLE_GLOBAL_FORM_DATA_SET:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          [action.payload.key]: action.payload.val
        }
      }
    default:
      return state;
  }
}

export default tableReducer;
