import React from 'react';
import cookie from 'react-cookies';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import styles from '../../Settings/settings-jss';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    setProductRecipeListVisible, setProductRecipeUpdate, getStock, formDataSetStock,
    setProductRecipe
} from '../../../../redux/modules/stock';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

import DatePicker from "react-datepicker";
import "../datepicker.css";
import { getParameter } from '../../../../function/GeneralFunction';
import Autocomplete from 'react-autocomplete';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
const uuidv4 = require('uuid/v4');

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            autocomplete="off"
        />
    );
}

function renderInput(inputProps) {
    const { classes, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: ref,
                classes: {
                    input: classes.input,
                },
                ...other,
            }}
        />
    );
}

function renderSuggestionsContainer(options) {
    const { containerProps, children } = options;

    return (
        <Paper {...containerProps} square>
            {children}
        </Paper>
    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => (
                    part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </strong>
                    )
                ))}
            </div>
        </MenuItem>
    );
}

class ProductRecipe extends React.Component {
    constructor(props) {
        super(props);
        this.state = { search: "", value: '', suggestions: [], inputList: {} }
    }

    async componentDidMount() {
        await this.props.getStock(false).then(() => { });
    }

    handleFocus = (event) => event.target.select();

    async productRecipeItemDelete(i, UnitId) {
        const { productRecipeData } = this.props;
        productRecipeData.find(x => x.UnitId == UnitId).StockRecipeList.find(x=>x.Id == i).IsDelete = true;
        await this.props.setProductRecipeUpdate(productRecipeData).then(() => { });
    }

    productAutoComplate(props) {
        const suggestionsApi = [];

        this.props.stockReducer.productListSearch.map((pro, key) => {
            suggestionsApi.push({ label: pro.Name, value: pro.Id });
        });

        return suggestionsApi;
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0 ? [] : this.productAutoComplate().filter(suggestion => {
            //onst keep = count < 10 && suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;
            //console.log("suggestion.label.toLowerCase().indexOf(inputValue)",suggestion.label.toLowerCase().indexOf(inputValue))
            const keep = count < 15 && suggestion.label.toLowerCase().indexOf(inputValue) !== -1;
            if (keep) {
                count += 1;
            }

            return keep;
        });
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };


    getSuggestionValue = unit => (suggestion) => {
        const { productRecipeData } = this.props;

        var UnitId = unit.split('^')[0];
        var index = unit.split('^')[1];

        var pro = this.props.stockReducer.productListSearch.find(x => x.Id == suggestion.value);
        
        productRecipeData.find(x => x.UnitId == UnitId).StockRecipeList[index].RecipeStockUnitId = pro.Unit[0].Id;
        productRecipeData.find(x => x.UnitId == UnitId).StockRecipeList[index].RecipeStockId = suggestion.value;
        this.props.setProductRecipeUpdate(productRecipeData).then(() => { });

        return suggestion.label;
    }

    handleChangeMenuSelect = unit => (event, { newValue }) => {
        this.state.inputList[unit] = newValue;
        this.setState({ inputList: this.state.inputList });
    };

    renderMenuItemAuto(UnitId, i) {
        const { classes } = this.props;
        const { value, suggestions } = this.state;
        return (
            <Autosuggest
                theme={{
                    input: classes.inputText,
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderInputComponent={renderInput}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={this.getSuggestionValue(UnitId + "^" + i)}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    classes,
                    placeholder: 'Lütfen Ürün Seçiniz',
                    value: this.state.inputList[UnitId + "^" + i] || '',
                    onChange: this.handleChangeMenuSelect(UnitId + "^" + i)
                }}
            />
        )
    }

    renderName(Id) {
        var pro = this.props.stockReducer.productListSearch.find(x => x.Id == Id);
        return pro.Name;
    }

    renderUnitName(Id) {
        var pro = "";
        if(this.props.stockReducer.productListSearch.filter(x => x.Id == Id).length > 0){
            pro = this.props.stockReducer.productListSearch.find(x => x.Id == Id).Unit[0].UnitName;
        }
        return pro;
    }

    handleChange = (name, unit) => event => {
        const { productRecipeData } = this.props;

        var UnitId = unit.split('^')[0];
        var index = unit.split('^')[1];

        productRecipeData.find(x => x.UnitId == UnitId).StockRecipeList[index].Quantity = Number(event.target.value);
        this.props.setProductRecipeUpdate(productRecipeData).then(() => { });
    };

    renderRecipeDetail(recipe, UnitId) {
        const { classes } = this.props;

        return recipe.map((n, i) => {
            return (
                <TableRow key={n.Id}>
                    <TableCell style={{ width: '40%', padding: 3 }} align="left">
                        {
                            (n.Id != "00000000-0000-0000-0000-000000000000" ? this.renderName(n.RecipeStockId) : this.renderMenuItemAuto(UnitId, i))
                        }
                    </TableCell>
                    <TableCell style={{ width: '20%', padding: 3 }} align="left">
                        <TextField
                            value={n.Quantity}
                            defaultValue={0}
                            onChange={this.handleChange("Quantity", UnitId + "^" + i)}
                            id="formatted-numberformat-input"
                            onFocus={this.handleFocus}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                    </TableCell>
                    <TableCell style={{ width: '20%', padding: 3 }} align="center">
                        { this.renderUnitName(n.RecipeStockId) }
                    </TableCell>
                    <TableCell style={{ width: '20%', padding: 3 }} align="right">
                        <Tooltip title="Sil">
                            <IconButton style={{ backgroundColor: '#3e3e3e' }} onClick={() => this.productRecipeItemDelete(n.Id, UnitId)}>
                                <Icon>{"delete_outline"}</Icon>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            )
        })
    }

    async addRecipeItem(UnitId) {
        const { productRecipeData, selectedProductRecipeId } = this.props;

        var newRecipeItem = {
            "Id": "00000000-0000-0000-0000-000000000000",
            "RecipeStockId": "",
            "Quantity": 1,
            "UnitId": UnitId,
            "IsDelete" : false,
            "StockId": selectedProductRecipeId,
            "TenantId": cookie.load('TenantId'),
            "BrandId": cookie.load('BrandId')
        }

        productRecipeData.find(x => x.UnitId == UnitId).StockRecipeList.push(newRecipeItem);
        await this.props.setProductRecipeUpdate(productRecipeData).then(() => { });
    }

    renderRecipeAmount(StockRecipeList){
        var amount = 0;

        StockRecipeList.map((n,i) => {
            if(n.RecipeStockId != ""){
                var pro = this.props.stockReducer.productListSearch.find(x => x.Id == n.RecipeStockId);
                amount = amount + (pro.Unit.find(x=>x.Id == n.RecipeStockUnitId).Cost * n.Quantity);
            }
        })

        return amount.toFixed(3);
    }

    render() {
        const { classes, productRecipeListVisible, productRecipeData } = this.props;

        return (
            <Dialog
                open={productRecipeListVisible}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Ürün Reçete"}
                    <Typography className={classes.instructions} style={{ fontSize: 13, marginTop: 0 }}>
                        {"Ürün hammadde üretim reçeteleri"}
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ height: '100vh', zIndex: 1 }}>
                    {
                        productRecipeData.map((n, i) => {
                            return (
                                <div>
                                    <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Birim Adı"}</Typography>
                                    <div style={{ alignItems: 'center', display: 'flex', height: 47, position: 'relative', color: 'white', fontSize: 15, borderRadius: 5, backgroundColor: '#424242', padding: 5 }}>
                                        {n.UnitName} <span style={{ marginLeft:5 }}>{" - Tahmini Maliyet :"} {this.renderRecipeAmount(n.StockRecipeList)}</span>
                                        <Button style={{ borderRadius: 3, position: 'absolute', right: 5 }} variant="outlined" color="secondary" autoFocus onClick={() => this.addRecipeItem(n.UnitId)}>
                                            <Icon>{"playlist_add"}</Icon>
                                        </Button>
                                    </div>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">{"Ürün Adı"}</TableCell>
                                                <TableCell align="left">{"Miktarı"}</TableCell>
                                                <TableCell align="left">{"Birimi"}</TableCell>
                                                <TableCell align="right">{"İşlem"}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.renderRecipeDetail(n.StockRecipeList.filter(x=>x.IsDelete == false), n.UnitId)}
                                        </TableBody>
                                    </Table>
                                </div>
                            )
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }} color="primary" autoFocus onClick={() => this.props.setProductRecipeListVisible(false).then(() => { })}>
                        {"Çıkış"}
                    </Button>
                    <Button style={{ marginRight: 5 }} onClick={() => this.props.setProductRecipe(productRecipeData).then(() => { })} color="secondary" variant="contained" autoFocus>
                        {"Kaydet"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('stockReducer').productRecipeListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    productRecipeListUpdate: value(state, props),
    productRecipeListVisible: state.get('stockReducer').productRecipeListVisible,
    productRecipeData: state.get('stockReducer').productRecipeData,
    selectedProductRecipeId: state.get('stockReducer').selectedProductRecipeId,
    stockReducer: state.get('stockReducer').form_data,
})

const mapDispatchToProps = {
    setProductRecipeListVisible,
    setProductRecipeUpdate,
    getStock,
    formDataSetStock,
    setProductRecipe
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ProductRecipe)));