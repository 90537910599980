import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import AndroidCallerID from 'dan-images/javiposcallerid.png';
import PaketServis from 'dan-images/paketservis.png';
import KuryeTakip from 'dan-images/kuryetakip.png';
import CallCenterLogo from 'dan-images/callcenterlogo.png';
import SubeSistemi from 'dan-images/subesistemi.png';
import MaxiJett from 'dan-images/MaxiJettLogo.png';
import styles from './cardStyle-jss';

class ModuleCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { counter: 0 };
  }

  handleChange = event => {
    this.setState({ counter: event.target.value });
    this.props.courierTrack(event.target.value);
  };

  render() {
    const {
      classes,
      price,
      Id,
      module,
      courierCount
    } = this.props;

    const returnLogo = (Id) => {
      switch (Id) {
        case 'a388d94e-6e62-4c8e-bcc8-92f9d5b5a67c':
          return <span style={{ color: 'white', fontSize: 20, fontWeight: 700 }}>Android Caller ID</span>;
        case '54ced956-358d-4a38-a8e7-f79ca670eb69':
          return <span style={{ color: '#FE721B', fontSize: 20, fontWeight: 700 }}>QR Menü</span>;
        case '3a507e16-c1f5-4f2d-a3b2-71bceaa45b1e':
          return <span style={{ color: 'white', fontSize: 20, fontWeight: 700 }}>MaxiJett</span>;
        case 'a81eecbe-62a0-43b3-a467-f2bbf622dfa3':
          return <span style={{ color: '#d31212', fontSize: 20, fontWeight: 700 }}>PaketŞutla</span>;
        case 'c4297a5e-3e5b-4255-8022-14d4163e66c9':
          return <span style={{ color: '#27ae60', fontSize: 20, fontWeight: 700 }}>MigrosYemek</span>;
        case '6e98ccb9-08b3-47ce-840e-2745ad3bf706':
          return <span style={{ color: '#FEC006', fontSize: 20, fontWeight: 700 }}>GetirYemek</span>;
        case '9b5db60d-51a8-480d-8ea7-acc1ce3f88c0':
          return <span style={{ color: '#f27a1a', fontSize: 20, fontWeight: 700 }}>TrendyolYemek</span>;
        case '90f4970d-4698-4080-ba55-5ac2d174fcdb':
          return <span style={{ color: '#fa0050', fontSize: 20, fontWeight: 700 }}>YemekSepeti</span>;
        case 'bd2df397-1ec8-4298-bfd3-d15f516ce4cf':
          return <span style={{ color: '#f1b62a', fontSize: 20, fontWeight: 700 }}>GoFody</span>;
        default:
          return null;
      }
    };

    const returnDesc = (Id) => {
      switch (Id) {
        case 'a388d94e-6e62-4c8e-bcc8-92f9d5b5a67c':
          return 'Android Caller ID sayesinde extra bir cihaza ihtiyaç duymadan cep telefonunuza gelen aramaları JaviPos sistemlerine aktarabilirsiniz.';
        case '54ced956-358d-4a38-a8e7-f79ca670eb69':
          return 'QR Menü ile hem menülerinizi inceletebilir hemde QR Menü üzerinden sipariş alabilirsiniz.';
        case '3a507e16-c1f5-4f2d-a3b2-71bceaa45b1e':
          return 'MaxiJett ile hizmet verdiği bölgelerde anlaşmalı olarak paket taşıma hizmeti alabilirsiniz. Lütfen MaxiJett ile irtibata geçiniz!';
        case 'a81eecbe-62a0-43b3-a467-f2bbf622dfa3':
          return 'PaketŞutla ile hizmet verdiği bölgelerde anlaşmalı olarak paket taşıma hizmeti alabilirsiniz. Lütfen PaketŞutla ile irtibata geçiniz!';
        case '6e98ccb9-08b3-47ce-840e-2745ad3bf706':
          return 'GetirYemek Entegrasyonu';
        case 'c4297a5e-3e5b-4255-8022-14d4163e66c9':
          return 'MigrosYemek Entegrasyonu';
        case '9b5db60d-51a8-480d-8ea7-acc1ce3f88c0':
          return 'TrendyolYemekEntegrasyonu';
        case '90f4970d-4698-4080-ba55-5ac2d174fcdb':
          return 'YemekSepeti Entegrasyonu';
        case 'bd2df397-1ec8-4298-bfd3-d15f516ce4cf':
          return 'GoFody Entegrasyonu';
        default:
          return null;
      }
    };


    const returnPrice = () => {
      const { classes } = this.props;
      return (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5
        }}
        >
          {price + ' /AY'}
        </div>
      );
    };

    var isStatus = module.filter(t => t.Id == Id).length != 0;

    return (
      <Card
        className={classNames(classes.priceCard)}
        style={{
          width: '100%', boxShadow: 'none', backgroundColor: '#303030', position: 'relative', height: 210, borderRadius: 5, maxWidth: '100%', position: 'relative'
        }}
      >
        <div className={classes.priceHead} style={{ zIndex: 1, paddingTop: 0 }}>
          {(isStatus ? (
            <Icon style={{
              position: 'absolute', left: 10, top: 10, zIndex: 1, fontSize: 25, color: '#16a085'
            }}
            >
              {"check"}
            </Icon>
          ) : null)}
          <div style={{
            textTransform: 'capitalize', boxShadow: '0px 12px 12px -15px rgba(0, 0, 0, 0.5)', backgroundColor: '#212121', width: '100%', borderTopLeftRadius: 5, borderTopRightRadius: 5, padding: 10
          }}
          >
            {returnLogo(Id)}
          </div>
          <Typography style={{
            whiteSpace: 'pre-line', fontSize: 12, color: 'white', textTransform: 'capitalize', padding: 5, height: '75%', display: 'grid', alignItems: 'center'
          }}
          >
            {returnDesc(Id)}
          </Typography>
        </div>
        <div style={{
          position: 'absolute', bottom: 0, left: 10, right: 10, textAlign: 'center'
        }}
        >
          <Divider />
          {returnPrice()}
        </div>
      </Card>
    );
  }
}

ModuleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
};

export default withStyles(styles)(ModuleCard);
