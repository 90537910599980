import React from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import styles from '../../Settings/settings-jss';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { setRepeatOrderList, formDataSet, setAdditionListUpdate } from '../../../../redux/modules/addition';
import { formDataSetCustomer } from '../../../../redux/modules/customer';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class RepeatOrder extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentDidMount() {
        //this.props.getCustomerList().then(() => { })
    }

    renderProductDetail(property) {
        return property.map((n, x) => {
            return (
                <span key={x} style={{ display: 'block', fontSize: 11, fontWeight: 600, color: '#00BCD4', textTransform: 'capitalize' }}>{this.renderPropertyDetail(n.Item)}{(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? "(" : "") : "")}{this.renderPropertyDetailMenu(n.MenuDetailSelected)}{(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? ")" : "") : "")}</span>
            )
        })
    }

    renderPropertyDetail(item) {
        return item.map((n, x) => {
            if (n.Default) {
                return (
                    <span key={x} style={{ marginLeft: 5, fontSize: 11, fontWeight: 600, color: '#00BCD4', textTransform: 'capitalize' }}>{n.Quantity > 0 ? n.Quantity + "x" : ""}{n.Name}</span>
                )
            } else {
                return null;
            }
        })
    }

    renderPropertyDetailMenu(MenuDetail) {
        if (MenuDetail != null) {
            if (MenuDetail.length > 0) {
                return MenuDetail.map((n, x) => {
                    return n.Item.map((y, i) => {
                        if (y.Default) {
                            return (
                                <span key={i} style={{ fontSize: 11, fontWeight: 600, color: '#00BCD4', textTransform: 'capitalize' }}>{" "}{y.Quantity > 0 ? y.Quantity + "x" : ""}{y.Name}{" "}</span>
                            )
                        } else {
                            return null;
                        }
                    })
                })
            }
        }
    }

    renderProductDetailUnit(unit) {
        if (unit.length == 1) {
            return null;
        } else {
            return unit.map((n, x) => {
                if (n.IsSelected) {
                    return "(" + n.UnitName + ")"
                }
            })
        }
    }

    renderAdditionListPrice(item) {
        let price = item.Price;
        item.Unit.map(n => {
            if (n.IsSelected) {
                price = n.Price
            }
        })
        item.Property.map(n => {
            n.Item.map(n => {
                if (n.Default && n.Quantity == 0) {
                    price += n.Price
                  }else if (n.Default && n.Quantity > 0){
                    price += (n.Price * n.Quantity)
                  }
            })
        })

        item.Menu.map(n => {
            n.Item.map(n => {
                if (n.Default) {
                    price += n.Price
                }
            })

            if (n.MenuDetailSelected != null) {
                if (n.MenuDetailSelected.length != 0) {
                    n.MenuDetailSelected.map(x => {
                        x.Item.map(n => {
                            if (n.Default && n.Quantity == 0) {
                                price += n.Price
                              }else if (n.Default && n.Quantity > 0){
                                price += (n.Price * n.Quantity)
                              }
                        })
                    })
                }
            }
        })

        price = price * item.Quantity;
        
        return price.toFixed(2).replace(/[.]/, ",");
    }

    async clickRepeatOrder(order) {
        var date = new Date();
        var data = {
            Id: "",
            CustomerId: order.CustomerId,
            CustomerName: order.CustomerName,
            CustomerAddressId: order.CustomerAddressId,
            CustomerAddressText: order.CustomerAddressText,
            TableId: "00000000-0000-0000-0000-000000000000",
            TableName: "Paket Satış",
            AdditionId: "",
            ProductItems: order.ProductItems,
            ProductItemsJson: "",
            AdditionHistories: [],
            AdditionHistoriesJson: "",
            CreationTime: date,
            UpdateTime: "",
            OrderType: 1,
            PackageStatus: 0,
            UserId: "",
            UserName: "",
            AdditionDescription: "",
            IsNew: true,
            Payments: [],
            AdditionPrinted: false,
            PaymentNote: "",
            DeliveryTime: date
        };

        await this.props.formDataSet("addition_data", data).then(() => { })
        await this.props.setRepeatOrderList(null, false).then(() => { })
        await this.props.formDataSetCustomer("customerCallCenterNew", false).then(() => { })
        await this.props.formDataSetCustomer("customerFilterModalOpen", false).then(() => { })
        await this.props.formDataSetCustomer("customerCallerIdNew", false).then(() => { })
        this.props.setAdditionListUpdate(!this.props.IsAdditionListUpdate).then (()=>{})
    }

    renderProductDetailDraw(productList) {
        return productList.filter(x=> x.IsDelete == false && x.ParentIndex == 0).map((n, i) => {
            return (
                <Grid key={i} item md={12} style={{ marginTop: 5, left: 2, marginBottom: 5, right: 2, flexWrap: 'nowrap' }}>
                    <Grid container style={{ flexDirection: 'row' }}>
                        <Grid item md={1}>
                            <span style={{ marginLeft: 7, fontSize: 13, fontWeight: 500, color: 'white', marginRight: 5 }}>
                                {n.Quantity}
                            </span>
                        </Grid>
                        <Grid item md={9}>
                            <span style={{ marginLeft: 7, fontSize: 13, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>{n.Name} {this.renderProductDetailUnit(n.Unit)}</span>
                        </Grid>
                        <Grid item md={2} style={{ textAlign: 'right' }}>
                            <span style={{ marginRight: 7, fontSize: 13, fontWeight: 500, color: 'white' }}>{this.renderAdditionListPrice(n)}</span>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={1}>
                        </Grid>
                        <Grid item md={11} style={{ whiteSpace: 'pre-line' }}>
                            <span style={{ fontSize: 12, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize' }}>
                                {this.renderProductDetail(n.Property)}
                                {this.renderProductDetail(n.Menu)}
                                {
                                    (n.Description != null ? (n.Description.length != 0 ? <span style={{ marginLeft: 7, fontSize: 13, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize' }}>{"("}{n.Description}{")"}</span> : null) : null)
                                }
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            )
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.repeatOrderOpen}
                fullWidth={true}
                maxWidth={'md'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {this.props.repeatOrderList != null ? this.props.repeatOrderList[0].CustomerName : ""} {"Paket Adisyon Listesi"}
                </DialogTitle>
                <DialogContent style={{ height: '100vh' }}>
                    {
                        (this.props.repeatOrderList != null ? (
                            this.props.repeatOrderList.map((n, i) => {
                                var a = moment(moment(n.CreationTime).format('MM/DD/YYYY'));
                                var b = moment(moment(new Date(), 'MM/DD/YYYY'));
                                let day = b.diff(a, 'days');
                                return (
                                    <div key={i} style={{ height: 90, display: 'inline-block', width: '100%', border: '1px solid #636363', borderRadius: 5, position: 'relative', padding: 5 }}>
                                        <span style={{ fontWeight:700, textAlign:'center', width:26, position: 'absolute', left: 5, top: 5, color: '#424242', fontSize: 15, backgroundColor: 'white', padding: 2, borderRadius: 20 }}>{i+1}</span>
                                        <span style={{ position: 'absolute', left: 40, top: 5, color: 'white', fontSize: 14, backgroundColor: '#009688', padding: 3, borderRadius: 3 }}>{"Paket Tarihi : "} {moment(n.CreationTime).format('DD/MM/YYYY HH:mm:ss')} {" - " + day + " gün önce"}</span>
                                        <span style={{ position: 'absolute', left: 5, bottom: 5, color: 'white', fontSize: 12 }}>{"Teslimat Adresi : "} {n.CustomerAddressText}</span>
                                        <div style={{ display: 'flex', position: 'absolute', right: 5, top: 5 }}>
                                            <div>
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    placement={"left"}
                                                    disableFocusListener disableTouchListener
                                                    title={<div style={{ padding: 5, width: 250, backgroundColor: '#292929', borderRadius: 5 }}>{this.renderProductDetailDraw(n.ProductItems)}</div>}
                                                >
                                                    <Button
                                                        onClick={this.handleTooltipOpen}
                                                        className={classes.button}
                                                        variant="contained"
                                                        style={{ width: 75, backgroundColor: '#2980b9', color: 'white', marginRight: 5 }}
                                                    >
                                                        {"Detay"}
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <Button
                                                onClick={() => this.clickRepeatOrder(n)}
                                                className={classes.button}
                                                variant="contained"
                                                style={{ width: 159, backgroundColor: '#f39c12', color: 'white', marginRight: 5 }}
                                            >
                                                {"Siparişi Tekrarla"}
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })
                        ) : null)
                    }
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }} onClick={() => this.props.setRepeatOrderList(null, false).then(() => { })} color="primary" autoFocus>
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('additionReducer').repeatOrderOpen;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    repeatOrderOpen: value(state, props),
    repeatOrderList: state.get('additionReducer').repeatOrderList,
    IsAdditionListUpdate: state.get('additionReducer').IsAdditionListUpdate
})

const mapDispatchToProps = {
    setRepeatOrderList,
    formDataSet,
    formDataSetCustomer,
    setAdditionListUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(RepeatOrder)));