import React from 'react';
import { connect } from 'react-redux';
import {
  withStyles
} from '@material-ui/core/styles';
import {
  createSelector
} from 'reselect';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { formDataSetUser, setLockScreenVisible } from '../../redux/modules/user';
import cookie from 'react-cookies';
import jarvis from 'dan-images/jarvis.png';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import Outer from '../../containers/Templates/Outer';
import LockForm from '../Forms/LockForm';

class LockScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    valueForm: []
  }

  shouldComponentUpdate(nextProps) {
    console.log("nextProps", nextProps);
    return true;
  }

  render() {
    const {
      classes
    } = this.props;

    return (
      <div style={{ display: (this.props.openLockScreen ? '' : 'none'), position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(66, 66, 66, 0.8)', zIndex: 10000 }}>
        <Outer style={{ position: 'relative' }}>
          <LockForm />
        </Outer>
      </div>
    );
  }
}

const valueSelector = (state, props) => state.get('userReducer').IsVisibleLockScreen;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  openLockScreen: value(state, props),
})

const mapDispatchToProps = {
  formDataSetUser,
  setLockScreenVisible
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LockScreen);
