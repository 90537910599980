import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { RequestRecipeList, modalCloseReport } from '../../../../redux/modules/report';
import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DatePicker, { registerLocale } from "react-datepicker";
import "../datepicker.css";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { EmptyData } from 'dan-components';
import TbPaginationActions from '../../Ortak/TbPaginationActions';
import AlertDialog from '../../Ortak/AlertDialog';
import { getParameter } from '../../../../function/GeneralFunction';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalInformation from '../../Ortak/ModalInformation';
import styles from 'dan-components/Tables/tableStyle-jss';

class StockRecipeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { startDate: new Date(new Date().setHours(0, 0, 0, 0)), endDate: new Date(new Date().setHours(23, 59, 59, 999)) }
    }

    async componentDidMount() {
        var TimeData = getParameter("BusinessWorkingHours");
        await this.setState({ startDate: TimeData.start, endDate: TimeData.end })
    }

    shouldComponentUpdate(nextProps, nextState) {
        //if (nextProps.stockRecipeListUpdate == this.props.stockRecipeListUpdate) {
        //return false;
        //}
        return true;
    }

    handleSearch = () => {
        const { filterStockRecipeListData, ProductTabsValue } = this.props;
        filterStockRecipeListData.startDate = this.state.startDate;
        filterStockRecipeListData.endDate = this.state.endDate;
        this.props.RequestRecipeList(filterStockRecipeListData,(ProductTabsValue == 4 ? true : false)).then(() => { })
    };

    modalClose = () => {
        this.props.modalCloseReport().then(() => { })
    };

    render() {
        const { classes, loading } = this.props;

        return (
            <Paper className={classes.root} elevation={0} style={{ borderRadius: 0, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 10 }}>
                <ModalInformation event={this.modalClose} open={this.props.modalOpen} title={this.props.modalTitle} text={this.props.modalText} />
                <Toolbar style={{ marginBottom: 5, minHeight: 48, width: '100%', display: 'flex' }}>
                    <Grid container spacing={1}>
                        <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
                            <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Başlangıç Tarihi"}</Typography>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={date => this.setState({ startDate: date })}
                                timeInputLabel="Saat:"
                                dateFormat="dd.MM.yyyy HH:mm:ss"
                                showTimeInput
                                withPortal
                                locale="tr"
                                className={"inputDate"}
                            />
                        </Grid>
                        <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
                            <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Bitiş Tarihi"}</Typography>
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={date => this.setState({ endDate: date })}
                                timeInputLabel="Saat:"
                                dateFormat="dd.MM.yyyy HH:mm"
                                showTimeInput
                                withPortal
                                locale="tr"
                                className={"inputDate"}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Button disabled={loading} onClick={this.handleSearch} style={{ marginTop: 15, borderRadius: 3, marginLeft: 5 }} variant="contained" color="primary">
                                {"Filtrele"}
                                {(this.props.additionListLoading ? <CircularProgress style={{ color: 'white', marginLeft: 10 }} disableShrink thickness={3} size={15} /> : null)}
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>

                <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 5 }}>
                    <TableHead style={{ backgroundColor: '#212121' }}>
                        <TableRow>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Adı"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Birim Adı"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Toplam Miktar"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Ortalama Birim Maliyet"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Ortalama Maliyet"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.filterStockRecipeListData.List.map((n, i) => {
                                return (
                                    <TableRow hover key={i}>
                                        <TableCell align="left">{n.StockName}</TableCell>
                                        <TableCell align="left">{n.UnitName}</TableCell>
                                        <TableCell align="left">{Number(n.Quantity.toFixed(3))}</TableCell>
                                        <TableCell align="left">{Number(n.Cost.toFixed(3))}</TableCell>
                                        <TableCell align="left">{Number(n.UnitCost.toFixed(3))}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {
                    (this.props.filterStockRecipeListData.List.length == 0 ? <EmptyData text={"Reçete Hareket Kaydı Bulunamadı"} /> : null)
                }
                {(this.props.stockRecipeListLoading ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
            </Paper>
        )
    }
}


const valueSelector = (state, props) => state.get('reportReducer').stockRecipeListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    stockRecipeListUpdate: value(state, props),
    filterStockRecipeListData: state.get('reportReducer').filterStockRecipeListData,
    stockRecipeListLoading: state.get('reportReducer').stockRecipeListLoading,

    modalOpen: state.get('reportReducer').form_data.modalOpen,
    modalText: state.get('reportReducer').form_data.modalText,
    modalTitle: state.get('reportReducer').form_data.modalTitle
})

const mapDispatchToProps = {
    RequestRecipeList,
    modalCloseReport
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(StockRecipeList)));
