import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { PapperBlock } from 'dan-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalInformation from '../Ortak/ModalInformation';
import Outer from '../../Templates/Outer';
import Grid from '@material-ui/core/Grid';
import Decoration from '../../Templates/Decoration';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import jarvis from 'dan-images/jarvis.png';
import styles from './courierpage-jss';
import { formDataSet, setCourierControl } from '../../../redux/modules/courier';

class CourierPIN extends React.Component {
  constructor(props){
    super(props);
  }

  async componentDidMount(){

  }

  setNumberButton(number){
    const { PIN } = this.props.courierReducer;
    if (PIN.length < 4) {
      this.props.formDataSet("PIN" , PIN + number).then (()=>{})
    }
  }

  handleButtonCourierControl(){
    this.props.setCourierControl().then (()=>{})
  }

  handleChange = event => {
    const { PIN } = this.props.courierReducer;
    if (PIN.length < 4) {
      this.props.formDataSet("PIN" , event.target.value).then (()=>{})
    }
  };

  modalClose = () => {
    this.props.formDataSet("modalOpen" , false).then (()=>{})
  };

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleButtonCourierControl();
    }
  }

  render() {
    const { PIN } = this.props.courierReducer;
    const { classes } = this.props;
    return (
      <Outer style={{ position:'relative' }}>
      <Button variant="outlined" onClick={() => this.props.history.push("/")} style={{ zIndex:100, width:50, top:20, left:20,color:'white', height:60, position:'absolute'}}>
         <Icon>{"arrow_back"}</Icon>
      </Button>
      <ModalInformation event={this.modalClose} open={this.props.courierReducer.modalOpen} title={this.props.courierReducer.modalTitle} text={this.props.courierReducer.modalText} />
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh', maxWidth: '100%', zIndex: 99 }}
      >
     <Grid item md={6} align="center">
        <img style={{ height:90 }} src={jarvis} />
      </Grid>
      <Grid item md={6} align="center">
        <div style={{ height:500, width:450, backgroundColor:'#424242' ,borderRadius:10, padding:10, paddingTop:40 }}>
        <Grid container spacing={1}>
          <Grid item md={12} style={{ marginBottom:10 }}>
          <FormControl fullWidth>
            <Input autoFocus classes={{ input: classes.input_in }} type="password"  id="PIN" placeholder="PIN Giriniz" value={PIN} onKeyDown={this._handleKeyDown} onChange={(event) => this.handleChange(event)} />
          </FormControl>
          </Grid>
        </Grid>
          <Grid container spacing={1}>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.setNumberButton("1")} style={{ backgroundColor:'rgb(39, 99, 147)' ,width:'100%' ,height:'100%', height:80}}>
               <Typography style={{ fontSize:35, color:'white' }} variant="subtitle1">{"1"}</Typography>
            </ButtonBase>
            </Grid>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.setNumberButton("2")} style={{backgroundColor:'rgb(39, 99, 147)',width:'100%' ,height:'100%',color:'white', height:80}}>
               <Typography style={{ fontSize:35 }} variant="subtitle1">{"2"}</Typography>
            </ButtonBase>
            </Grid>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.setNumberButton("3")} style={{backgroundColor:'rgb(39, 99, 147)',width:'100%' ,height:'100%',color:'white', height:80}}>
               <Typography style={{ fontSize:35 }} variant="subtitle1">{"3"}</Typography>
            </ButtonBase>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.setNumberButton("4")} style={{backgroundColor:'rgb(39, 99, 147)',width:'100%' ,height:'100%',color:'white', height:80}}>
               <Typography style={{ fontSize:35 }} variant="subtitle1">{"4"}</Typography>
            </ButtonBase>
            </Grid>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.setNumberButton("5")} style={{backgroundColor:'rgb(39, 99, 147)',width:'100%' ,height:'100%',color:'white', height:80}}>
               <Typography style={{ fontSize:35 }} variant="subtitle1">{"5"}</Typography>
            </ButtonBase>
            </Grid>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.setNumberButton("6")} style={{backgroundColor:'rgb(39, 99, 147)',width:'100%' ,height:'100%',color:'white', height:80}}>
               <Typography style={{ fontSize:35 }} variant="subtitle1">{"6"}</Typography>
            </ButtonBase>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.setNumberButton("7")} style={{backgroundColor:'rgb(39, 99, 147)',width:'100%' ,height:'100%',color:'white', height:80}}>
               <Typography style={{ fontSize:35 }} variant="subtitle1">{"7"}</Typography>
            </ButtonBase>
            </Grid>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.setNumberButton("8")} style={{backgroundColor:'rgb(39, 99, 147)',width:'100%' ,height:'100%',color:'white', height:80}}>
               <Typography style={{ fontSize:35 }} variant="subtitle1">{"8"}</Typography>
            </ButtonBase>
            </Grid>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.setNumberButton("9")} style={{backgroundColor:'rgb(39, 99, 147)',width:'100%' ,height:'100%',color:'white', height:80}}>
               <Typography style={{ fontSize:35 }} variant="subtitle1">{"9"}</Typography>
            </ButtonBase>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.props.formDataSet("PIN" , "").then (()=>{})} style={{backgroundColor:'#c0392b',width:'100%' ,height:'100%', height:80}}>
               <Icon style={{ fontSize:45, color:'white' }}>{"close"}</Icon>
            </ButtonBase>
            </Grid>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.setNumberButton("0")} style={{backgroundColor:'rgb(39, 99, 147)',width:'100%' ,height:'100%',color:'white', height:80}}>
               <Typography style={{ fontSize:35 }} variant="subtitle1">{"0"}</Typography>
            </ButtonBase>
            </Grid>
            <Grid item md={4}>
            <ButtonBase onClick={() => this.handleButtonCourierControl()} style={{backgroundColor:'#27ae60',width:'100%' ,height:'100%', height:80}}>
               <Icon style={{ fontSize:45, color:'white' }}>{"check"}</Icon>
            </ButtonBase>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
      </Outer>
    );
  }
}

const mapStateToProps = state => {
	return {
    courierReducer : state.get('courierReducer').form_data,
	};
};

const mapDispatchToProps = {
  formDataSet,
  setCourierControl
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(CourierPIN)));
