import React from "react";
import L from "leaflet";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png"
import './stylesPopup.css';

const style = {
    width: "100%",
    height: '350px',
    borderRadius: 5,
    marginBottom: 10
};

class PaketYoldaCourierMap extends React.Component {
    componentDidMount() {
        // create map
        this.map = L.map("mapCustomer", {
            center: this.props.center,
            zoom: 15,
            layers: [
                L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution:
                        ''
                })
            ]
        });

        // add layer
        this.layer = L.layerGroup().addTo(this.map);
        this.updateMarkers(this.props.orderLocation);
    }

    compare(a, b) {
        var aSize = a.Username;
        var bSize = b.Username;
        var aLow = a.KM;
        var bLow = b.KM;

        if (aSize == bSize) {
            return (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0;
        }
        else {
            return (aSize < bSize) ? -1 : 1;
        }
    }

    state = { centerData: [] };
    componentDidUpdate({ orderLocation }) {
        if (this.props.orderLocation.length !== orderLocation.length) {
            this.updateMarkers(this.props.orderLocation);
        }
    }

    async updateMarkers(location) {
        if (location.length > 0) {
            var customerData = [];

            this.map.eachLayer(function (layerData) {
                if (layerData.options.name === "PackageList") {
                    layerData.remove();
                }
            });

            location.forEach((marker, i) => {
                var style = 'style="background-color: #218c74;"';
                var stylePin = 'style="border-top:10px solid #218c74;"';

                customerData.push(L.marker([marker.Lat, marker.Lng], {
                    zIndexOffset: (100 + i), name: "CustomerList", icon: new L.DivIcon({
                        className: 'my-div-icon',
                        html: '<div ' + style + ' class="my-div-span-order"><div ' + stylePin + ' class="arrow-down"></div>' + marker.AdditionOrder + '</div><div class="pulse"></div>',
                        iconSize: [40, 30],
                        iconAnchor: [20, 30]
                    })
                }, { title: "" }).addTo(this.layer));
            });

            var latlngs = Array();
            if (customerData.length > 0) {
                customerData.forEach(marker => {
                    latlngs.push(marker.getLatLng())
                });

                var polyline = L.polyline(latlngs, { color: 'transparent' }).addTo(this.layer);
                this.map.fitBounds(polyline.getBounds(), { maxZoom: 13 });
            }
        }
    }

    render() {
        return <div class="mapDiv" id="mapCustomer" style={style} />;
    }
}

export default PaketYoldaCourierMap;
