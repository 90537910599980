import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Info from '@material-ui/icons/Info';
import Warning from '@material-ui/icons/Warning';
import Check from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/RemoveCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Ionicon from 'react-ionicons';
import dummy from 'dan-api/dummy/dummyContents';
import messageStyles from 'dan-styles/Messages.scss';
import avatarApi from 'dan-api/images/avatars';
import link from 'dan-api/ui/link';
import styles from './header-jss';
import cookie from 'react-cookies';
import { getAuthorization } from '../Authorization';
import { withRouter } from 'react-router';
import { reactLocalStorage } from 'reactjs-localstorage';

class UserMenu extends React.Component {
  state = {
    anchorEl: null,
    openMenu: null
  };

  handleMenu = menu => (event) => {
    const { openMenu } = this.state;
    this.setState({
      openMenu: openMenu === menu ? null : menu,
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, openMenu: null });
  };

  handleCloseApp = () => {
    cookie.remove('UserName', { path: '/' });
    cookie.save('AdminLogin', "false", { path: '/' });
    cookie.remove('RestaurantName', { path: '/' });
    cookie.remove('UserId', { path: '/' });
    cookie.remove('BrandId', { path: '/' });
    cookie.remove('TenantId', { path: '/' });
    cookie.remove('CenterBrandId', { path: '/' });
    cookie.remove('UserType', { path: '/' });
    cookie.remove('Settings', { path: '/' });
    cookie.remove('LicenceNumber', { path: '/' });
    cookie.remove('NameSurname', { path: '/' });
    cookie.remove('Authorization', { path: '/' });
    reactLocalStorage.remove('l_string');
    
    if(cookie.load("IsRemember") === "false"){
      cookie.remove('Email', { path: '/' });
      cookie.remove('Password', { path: '/' });
    }
    this.props.history.push('/');
  };

  render() {
    const { classes, dark } = this.props;
    const { anchorEl, openMenu } = this.state;
    return (
      <div>
        <Button style={{ display: (cookie.load('UserType') == "Waiter" ? 'none' : 'inline-flex') }} onClick={this.handleMenu('user-setting')}>
          <Avatar
            alt={dummy.user.name}
            src={dummy.user.avatar}
          />
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openMenu === 'user-setting'}
          onClose={this.handleClose}
        >
          <MenuItem onClick={null}>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            {cookie.load('RestaurantName')}
          </MenuItem>
          <Divider />
          <MenuItem component={Link} to={'/app/settings'}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            Kontrol Paneli
          </MenuItem>
          <Divider />
          <MenuItem style={{ backgroundColor: '#F44336' }} onClick={this.handleCloseApp}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            Çıkış Yap
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  dark: PropTypes.bool,
};

UserMenu.defaultProps = {
  dark: false
};

export default withRouter(withStyles(styles)(UserMenu));
