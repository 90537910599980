import { alpha, darken } from '@material-ui/core/styles/colorManipulator';
const gradientBgLight = (theme) => `linear-gradient(-45deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 33%, ${theme.palette.secondary.main} 100%);`;
const gradientBgDark = (theme) => `linear-gradient(-45deg, ${darken(theme.palette.primary.main, 0.4)} 0%, ${darken(theme.palette.primary.main, 0.4)} 33%, ${darken(theme.palette.secondary.main, 0.4)} 100%);`;
const drawerWidth = 240;
const styles = theme => ({
  barcodeScalesStyle: {
    color: '#424242',
    borderRadius: 0,
    fontWeight:600,
    '& input': {
      fontSize:'18px !important',
    }
  },
  rootButton: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 5,
    borderRadius: 3,
    height: 46,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 12,
      marginTop: 8,
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& > *': {
      padding: '0 5px'
    }
  },
    dialogQuantityAddPaper: {
    maxHeight: 'calc(100% - 10px)',
    minHeight: '85%',
    maxWidth:'95%'
  },
  dialogPaperCallerId: {
    margin: 10,
    maxHeight: 'calc(100% - 30px)',
    maxWidth:800
  },
  dialogPaper: {
    margin: 10,
    maxHeight: 'calc(100% - 30px)'
  },
  dialogPaperIntegration: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  dialogPaperWaiting: {
    margin: 10,
    maxHeight: 'calc(100% - 30px)',
    maxWidth:600
  },
  dialogPaperAbonelik: {
    maxHeight: 'calc(100% - 30px)'
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '1.5em',
      zIndex: 999
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '1px solid slategrey'
    }
  },
  cover: {
    '& $name, & $subheading': {
      color: theme.palette.common.white
    },
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    height: '100%',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    textAlign: 'center',
    boxShadow: theme.shadows[7],
    backgroundPosition: 'bottom center',
    borderRadius: theme.rounded.medium
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    //height: 100,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    //marginTop: 5,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  inputText: {
    paddingBottom: 2,
    paddingTop: 2,
    fontSize:13
  },
  rootButtonTitle: {
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 500
  },
  root: {
    width: '100%',
  },
  stepper_button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
    backgroundImage: theme.palette.type === 'dark' ? gradientBgDark(theme) : gradientBgLight(theme),
    backgroundAttachment: 'fixed',
    textAlign: 'center'
  },
  paperStyled: {
    background: darken(theme.palette.primary.main, 0.2),
    padding: theme.spacing(3),
    color: theme.palette.common.white
  },
  profile: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    width: 80,
    height: 80,
  },
  profileText: {
    marginLeft: theme.spacing(2),
    fontSize: 12,
    '& h4': {
      marginBottom: theme.spacing(1),
      fontSize: 18,
    },
  },
  quickAccess: {
    display: 'flex',
    justifyContent: 'space-around',
    '& $icon': {
      fontSize: 36,
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      }
    },
    '& button': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      color: theme.palette.primary.light,
      '& > span:first-child': {
        flexDirection: 'column'
      }
    }
  },
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(1) * 0.2,
  }),
  flex: {
    flex: 1,
  },
  searchSettings: {
    marginBottom: theme.spacing(4),
    borderRadius: theme.rounded.medium,
    backgroundColor: theme.palette.type === 'dark' ? alpha(theme.palette.grey[200], 0.15) : alpha(theme.palette.grey[200], 0.95),
    '& svg': {
      fill: theme.palette.text.secondary
    }
  },
  wrapper: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRadius: 2,
    display: 'block',
  },
  search: {
    width: 'auto',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    font: 'inherit',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(4)}px`,
    border: 0,
    display: 'block',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: 'none',
    margin: 0, // Reset for Safari
    color: theme.palette.text.primary,
    width: '100%',
    '&:focus': {
      outline: 0,
    },
  },
  table: {
    marginTop: 0
  },
  button: {
    display: 'table',
    width: '100%',
    height: '100%',
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    '&:hover': {
      background: theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.secondary.light
    },
    '& > span:first-child': {
      display: 'table-row',
    },
    '& $icon': {
      margin: '0 auto',
      display: 'table-cell',
      fontSize: 64,
    },
    '& $text': {
      width: 210,
      fontSize:13,
      textAlign: 'left',
      paddingLeft: theme.spacing(1),
      verticalAlign: 'middle',
      display: 'table-cell'
    },
    '& $info': {
      display: 'block',
      textTransform: 'none',
      color: theme.palette.grey[500],
      whiteSpace: 'initial'
    }
  },
  text: {},
  info: {},
  icon: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
    marginTop: theme.spacing(1) * -1
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  detailWrap: {
    paddingTop: 0,
    backgroundColor: 'rgba(48,48,48,1)'
  },
  inputDate: {
    backgroundColor: 'white'
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar123: {
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: '#424242',
    marginLeft: 5
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    backgroundColor: 'rgb(90, 96, 101)'
  }
});

export default styles;
