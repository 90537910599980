import React from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form/immutable';
import { TextField } from 'redux-form-material-ui';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import BackspaceIcon from '@material-ui/icons/Backspace';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Help from '@material-ui/icons/Help';
import Avatar from '@material-ui/core/Avatar';
import dummy from 'dan-api/dummy/dummyContents';
import avatarApi from 'dan-api/images/avatars';
import styles from './user-jss';
import Grid from '@material-ui/core/Grid';
import jarvis from 'dan-images/jarvis.png';
import Input from '@material-ui/core/Input';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { login, setPINLoading, modalclose } from '../../redux/modules/login';
import { setLockScreenVisible } from '../../redux/modules/user';
import ModalInformation from '../../containers/Pages/Ortak/ModalInformation';
import LinearProgress from '@material-ui/core/LinearProgress';
import LockOpenIcon from '@material-ui/icons/LockOpen';

class LockForm extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    PIN: ""
  };

  async PINLogin() {
    var login_data = {
      "Email": "",
      "Password": this.state.PIN,
      "IsPIN": true,
      "BrandId": cookie.load('BrandId')
    };

    this.props.setPINLoading(true).then(() => { })
    await this.props.login(login_data).then(() => { })
    if (!this.props.modalOpen) {
      this.setState({ PIN: '' })
      this.props.setLockScreenVisible(false).then(() => { })
    }
    this.props.setPINLoading(false).then(() => { })
  }

  _handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await this.PINLogin();
    }
  }


  async setNumberButton(number) {
    if (this.state.PIN.length < 4) {
      await this.setState({ PIN: this.state.PIN + number });
    }
    if (this.state.PIN.length == 4) {
      await this.PINLogin();
      return;
    }
  }

  handleChange = async event => {
    this.setState({ PIN: event.target.value })
    if (this.state.PIN.length == 4) {
      await this.PINLogin();
      return;
    }
  };

  modalClose = () => {
    this.props.modalclose().then(() => { })
  };

  appClose = () => {
    window.location.href = "/";
  };

  render() {
    const {
      classes
    } = this.props;
    const { PIN } = this.state;
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '100vh', maxWidth: '100%', zIndex: 99 }}
      >
        <ModalInformation event={this.modalClose} open={this.props.modalOpen} title={this.props.modalTitle} text={this.props.modalText} />
        <Tooltip title="Çıkış">
          <Button variant="outlined" onClick={this.appClose} style={{ zIndex: 100, width: 75, top: 20, left: 20, color: 'white', height: 60, position: 'absolute' }}>
            <Icon style={{ transform: 'rotate(180deg)' }}>{"exit_to_app"}</Icon>
            <Typography style={{ fontSize: 12, color: 'white', marginLeft: 7, fontWeight: 600 }} variant="subtitle1">{"Çıkış"}</Typography>
          </Button>
        </Tooltip>
        <Grid item md={6} align="center">
          <img style={{ height: 90 }} src={jarvis} />
        </Grid>
        <Grid item md={6} align="center">
          <div style={{ height: 90, width: 450, backgroundColor: '#424242', borderRadius: 10, padding: 10, marginBottom: 10 }}>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <div style={{ color:'white' }}>{"Kullanıcı Kilit Ekranı"}</div>
                <FormControl fullWidth>
                  <Input
                    id="standard-adornment-password"
                    type={'password'}
                    classes={{ input: classes.inputPinCode }}
                    autoFocus
                    value={PIN}
                    inputProps={{ maxLength: 4 }}
                    onKeyDown={this._handleKeyDown}
                    onChange={(event) => this.handleChange(event)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.setState({ keyCode: '', PIN: '' })}
                        >
                          <BackspaceIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {this.props.PINLoading ? <LinearProgress /> : null}
              </Grid>
            </Grid>
          </div>
          <div style={{ height: 400, width: 450, backgroundColor: '#424242', borderRadius: 10, padding: 10, paddingTop: 15 }}>
            <div style={{ width: '100%', height: '100%', padding: 1.5 }}>
              <div style={{ height: '25%', display: 'flex', paddingBottom: 3 }}>
                <ButtonBase onClick={() => this.setNumberButton("1")} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white', marginRight: 3 }}>
                  <Typography style={{ fontSize: 20, color: 'white' }} variant="subtitle1">{"1"}</Typography>
                </ButtonBase>
                <ButtonBase onClick={() => this.setNumberButton("2")} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white', marginRight: 3 }}>
                  <Typography style={{ fontSize: 20, color: 'white' }} variant="subtitle1">{"2"}</Typography>
                </ButtonBase>
                <ButtonBase onClick={() => this.setNumberButton("3")} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white' }}>
                  <Typography style={{ fontSize: 20, color: 'white' }} variant="subtitle1">{"3"}</Typography>
                </ButtonBase>
              </div>
              <div style={{ height: '25%', display: 'flex', paddingBottom: 3 }}>
                <ButtonBase onClick={() => this.setNumberButton("4")} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white', marginRight: 3 }}>
                  <Typography style={{ fontSize: 20, color: 'white' }} variant="subtitle1">{"4"}</Typography>
                </ButtonBase>
                <ButtonBase onClick={() => this.setNumberButton("5")} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white', marginRight: 3 }}>
                  <Typography style={{ fontSize: 20, color: 'white' }} variant="subtitle1">{"5"}</Typography>
                </ButtonBase>
                <ButtonBase onClick={() => this.setNumberButton("6")} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white' }}>
                  <Typography style={{ fontSize: 20, color: 'white' }} variant="subtitle1">{"6"}</Typography>
                </ButtonBase>
              </div>
              <div style={{ height: '25%', display: 'flex', paddingBottom: 3 }}>
                <ButtonBase onClick={() => this.setNumberButton("7")} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white', marginRight: 3 }}>
                  <Typography style={{ fontSize: 20, color: 'white' }} variant="subtitle1">{"7"}</Typography>
                </ButtonBase>
                <ButtonBase onClick={() => this.setNumberButton("8")} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white', marginRight: 3 }}>
                  <Typography style={{ fontSize: 20, color: 'white' }} variant="subtitle1">{"8"}</Typography>
                </ButtonBase>
                <ButtonBase onClick={() => this.setNumberButton("9")} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white' }}>
                  <Typography style={{ fontSize: 20, color: 'white' }} variant="subtitle1">{"9"}</Typography>
                </ButtonBase>
              </div>
              <div style={{ height: '25%', display: 'flex' }}>
                <ButtonBase disabled={true} onClick={() => null} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white', marginRight: 3 }}>

                </ButtonBase>
                <ButtonBase onClick={() => this.setNumberButton("0")} style={{ backgroundColor: 'transparent', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white', marginRight: 3 }}>
                  <Typography style={{ fontSize: 20, color: 'white' }} variant="subtitle1">{"0"}</Typography>
                </ButtonBase>
                <ButtonBase disabled={this.props.PINLoading} onClick={() => this.PINLogin()} style={{ backgroundColor: '#009688', border: '1px solid #636363', width: '33.33%', height: '100%', color: 'white' }}>
                  <LockOpenIcon style={{ color:'white' }} />
                </ButtonBase>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

LockForm.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    modalOpen: state.get('loginReducer').modalOpen,
    modalText: state.get('loginReducer').modalText,
    modalTitle: state.get('loginReducer').modalTitle,
    PINLoading: state.get('loginReducer').PINLoading,
  };
};


const mapDispatchToProps = {
  login,
  setPINLoading,
  modalclose,
  setLockScreenVisible
};

LockForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LockForm)

const LockFormReduxed = reduxForm({
  form: 'immutableELockFrm',
  enableReinitialize: true,
})(LockForm);

export default withStyles(styles)(LockFormReduxed);
