import React, { Fragment } from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import Icon from '@material-ui/core/Icon';
const uuidv4 = require('uuid/v4');
import {
    setFranchiseRaportVisible, setFranchiseReportName, getBrandList, setFranchiseReportBrandSelected,
    getCenterSettings
} from '../../../redux/modules/centerbrand';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import StarBorder from '@material-ui/icons/StarBorder';
import Collapse from '@material-ui/core/Collapse';
import ModalInformation from '../Ortak/ModalInformation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import OrderHeat from './Report/OrderHeat';
import Reviews from './Report/Reviews';
import DailyMonthlyGraffic from './Report/DailyMonthlyGraffic';
import GeneralTotal from './Report/GeneralTotal';
import ProductSalesList from './Report/ProductSalesList';
import EntegrationList from './Report/EntegrationList';
import AdditionReportList from './Report/AdditionReportList';
import Logs from './Report/Logs';

const fontSizeTextItem = {
    fontSize: 14
};

const fontSizeTextItemSelected = {
    fontSize: 15,
    fontWeight: 600,
    color: '#16a085'
};

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4)
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class CenterReport extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.franchiseReportUpdate == this.props.franchiseReportUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        await this.props.getBrandList().then(() => { });
        await this.props.getCenterSettings().then(() => {});
    }

    handleChange = (event) => {
        this.props.setFranchiseReportBrandSelected(event.target.value).then(() => { });
    };

    render() {
        const {
            classes,
            franchiseReportName,
            franchiseReportVisible,
            centerBrandData,
            franchiseReportBrandSelected
        } = this.props;

        return (
            <Dialog
                fullScreen
                open={franchiseReportVisible}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%' }}>
                    <Toolbar>
                        <Button variant="contained" onClick={() => this.props.setFranchiseRaportVisible(false).then(() => { })}> <Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon></Button>
                        <Typography variant="h6" color="inherit" className={classes.flex}>{"JaviPos Franchise Raporlama ve Analiz İşlemleri"}</Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64 }}>
                    <Grid container style={{ height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
                        <Grid item md={3} style={{ height: '100%', padding: 4, overflowY: 'auto' }}>
                            <div style={{ height: '100%', backgroundColor: '#424242' }}>
                                <List
                                    component="nav"
                                    subheader={<ListSubheader style={{ position: 'inherit' }} component="div">{"Franchise Raporlama Seçenekleri"}</ListSubheader>}
                                >
                                    <ListItem onClick={() => this.props.setFranchiseReportName("GeneralTotal").then(() => { })} style={{ backgroundColor: '#313131' }} button>
                                        <ListItemIcon style={{ minWidth: 35 }}>
                                            <Icon>{"account_balance_wallet"}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ style: (franchiseReportName == "GeneralTotal" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Genel Toplam Raporları" />
                                        {(
                                            franchiseReportName == "GeneralTotal" ?
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                </ListItemIcon> : null
                                        )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem onClick={() => this.props.setFranchiseReportName("DayMounthReport").then(() => { })} style={{ backgroundColor: '#313131' }} button>
                                        <ListItemIcon style={{ minWidth: 35 }}>
                                            <Icon>{"query_stats"}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ style: (franchiseReportName == "DayMounthReport" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Günlük / Aylık Raporlar" />
                                        {(
                                            franchiseReportName == "DayMounthReport" ?
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                </ListItemIcon> : null
                                        )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem onClick={() => this.props.setFranchiseReportName("ProductSalesReport").then(() => { })} style={{ backgroundColor: '#313131' }} button>
                                        <ListItemIcon style={{ minWidth: 35 }}>
                                            <Icon>{"ssid_chart"}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ style: (franchiseReportName == "ProductSalesReport" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Ürün Satış Raporu" />
                                        {(
                                            franchiseReportName == "ProductSalesReport" ?
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                </ListItemIcon> : null
                                        )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem onClick={() => this.props.setFranchiseReportName("OrderHeatReport").then(() => { })} style={{ backgroundColor: '#313131' }} button>
                                        <ListItemIcon style={{ minWidth: 35 }}>
                                            <Icon>{"map"}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ style: (franchiseReportName == "OrderHeatReport" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Sipariş Isı Haritaları" />
                                        {(
                                            franchiseReportName == "OrderHeatReport" ?
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                </ListItemIcon> : null
                                        )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem onClick={() => this.props.setFranchiseReportName("AdditionListReport").then(() => { })} style={{ backgroundColor: '#313131' }} button>
                                        <ListItemIcon style={{ minWidth: 35 }}>
                                            <Icon>{"fact_check"}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ style: (franchiseReportName == "AdditionListReport" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Adisyon Listeleri" />
                                        {(
                                            franchiseReportName == "AdditionListReport" ?
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                </ListItemIcon> : null
                                        )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem onClick={() => this.props.setFranchiseReportName("EntegrationReport").then(() => { })} style={{ backgroundColor: '#313131' }} button>
                                        <ListItemIcon style={{ minWidth: 35 }}>
                                            <Icon>{"extension"}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ style: (franchiseReportName == "EntegrationReport" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Entegrasyon Bazında Raporlar" />
                                        {(
                                            franchiseReportName == "EntegrationReport" ?
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                </ListItemIcon> : null
                                        )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem onClick={() => this.props.setFranchiseReportName("LogsReport").then(() => { })} style={{ backgroundColor: '#313131' }} button>
                                        <ListItemIcon style={{ minWidth: 35 }}>
                                            <Icon>{"receipt_long"}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ style: (franchiseReportName == "LogsReport" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="İşlem Hareketleri (Log)" />
                                        {(
                                            franchiseReportName == "LogsReport" ?
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                </ListItemIcon> : null
                                        )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem onClick={() => this.props.setFranchiseReportName("ReviewsReport").then(() => { })} style={{ backgroundColor: '#313131' }} button>
                                        <ListItemIcon style={{ minWidth: 35 }}>
                                            <Icon>{"reviews"}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ style: (franchiseReportName == "ReviewsReport" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Yorum ve Puanlamalar" />
                                        {(
                                            franchiseReportName == "ReviewsReport" ?
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                </ListItemIcon> : null
                                        )}
                                    </ListItem>
                                </List>
                            </div>
                        </Grid>
                        <Grid item md={9} style={{ height: '100%', padding: 4 }}>
                            <OrderHeat classes={classes} />
                            <Reviews classes={classes} />
                            <DailyMonthlyGraffic classes={classes} />
                            <GeneralTotal classes={classes} />
                            <ProductSalesList classes={classes} />
                            <EntegrationList classes={classes} />
                            <AdditionReportList classes={classes} />
                            <Logs classes={classes} />
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').franchiseReportUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    franchiseReportUpdate: value(state, props),
    franchiseReportVisible: state.get('centerBrandReducer').franchiseReportVisible,
    franchiseReportName: state.get('centerBrandReducer').franchiseReportName,
    centerBrandData: state.get('centerBrandReducer').centerBrandData,
    franchiseReportBrandSelected: state.get('centerBrandReducer').franchiseReportBrandSelected,
})

const mapDispatchToProps = {
    setFranchiseRaportVisible,
    setFranchiseReportName,
    getBrandList,
    setFranchiseReportBrandSelected,
    getCenterSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(CenterReport)));
