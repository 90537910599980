import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import cookie from 'react-cookies';
import TimerIcon from '@material-ui/icons/Timer';
import Close from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

import GetirYemekLogo from 'dan-images/GetirYemekLogo.png';
import TrendyolYemekLogo from 'dan-images/trendyol.png';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Ionicon from 'react-ionicons';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import * as workerTimers from 'worker-timers';
import { reactLocalStorage } from 'reactjs-localstorage';
import Entegration from './Entegration';
import EntegrationProduct from './EntegrationProduct';
import TrendyolClaims from './TrendyolClaims';
import {
  getLicenceModule, getParameter, getLicence, getLicenceModulePackage, soundPlay, notificationShow, soundPlayCourier
} from '../../../function/GeneralFunction';
import styles from './SlidingMenu-jss';
import { getUser } from '../../../redux/modules/user';
import {
  setPendingPackageHubConnection
} from '../../../redux/modules/track';
import { setPrinterHubConnection, sendMessagePackagePrinter, packageReload } from '../../../redux/modules/addition';
import {
  formDataSetSetting, getSettings, getThirdPartyControl,
  setSettings, getGetirYemekRestaurantStatus, getGetirYemekCourierEnable,
  setRestaurantStatusTrendyolYemek, getGoFodyRestaurantStatus,
  setMigrosYemekStatus,
  setYemekSepetiGlobalRestStatus, setSoundControl, getEntegrationRestList,
  getTrendyolClaimsList, setOrderMenuTab, getRestStatus
} from '../../../redux/modules/settings';
import ModalInformation from '../../Pages/Ortak/ModalInformation';

const actions = [
  { icon: <Icon>file_copy</Icon>, name: 'Copy' },
  { icon: <Icon>save</Icon>, name: 'Save' },
  { icon: <Icon>print</Icon>, name: 'Print' }
];

class OrderControl extends React.Component {
  constructor() {
    super();
    this.state = { countOrder: 0, isOpen: false };
  }

  async componentDidMount() {
    const setting = await this.props.getSettings().then(() => {
      this.props.getUser(false).then(() => { });
    });

    if (this.props.additionReducer.printerHubConnection == null) {
      await this.props.setPrinterHubConnection().then(() => { });
    }

    const getiryemek = getParameter('GetirYemek');
    const trendyolyemek = getParameter('TrendyolYemek');
    const gofody = getParameter('GoFody');
    const migrosyemek = getParameter('MigrosYemek');

    await this.props.getEntegrationRestList(true, true, true, true, true).then(() => { });
    await this.props.getRestStatus().then(() => { });
    
    if ((migrosyemek.IsStatus || getiryemek.IsStatus || trendyolyemek.IsStatus || gofody.IsStatus)) {
      if (this.props.pendingPackageHubConnection == null) {
        await this.props.setPendingPackageHubConnection().then(() => { });
      }
      if (getParameter('WebApiUserId') == cookie.load('UserId')) {
        this.timerYD = workerTimers.setInterval(() => this.tickYD(), 1000);
      }
    }
  }

  async tickYD() {
    const timerCount = this.state.countOrder + 1;
    this.setState({
      countOrder: timerCount
    });
    if (timerCount > 24) {
      await this.clearYD();
      this.renderData();

      const trendyolyemek = getParameter('TrendyolYemek');
      if (trendyolyemek.IsStatus) {
        const date = await reactLocalStorage.getObject('TrendyolClaimsTime', '');
        if (date != '') {
          const interestStartDate = moment(date);
          const interestEndDate = moment();
          const minutes = moment(interestEndDate).diff(moment(interestStartDate), 'minutes', true);
          if (minutes > 59) {
            await this.props.getTrendyolClaimsList(true).then(() => {
              reactLocalStorage.setObject('TrendyolClaimsTime', new Date().toString());
            });
          }
        } else {
          reactLocalStorage.setObject('TrendyolClaimsTime', new Date().toString());
        }
      }
    }
  }

  clearYD() {
    this.setState({
      countOrder: 0
    });
  }

  componentWillUnmount() {
    workerTimers.clearInterval(this.timerYD);
  }

  modalClose = () => {
    this.props.formDataSetSetting('modalOpen', false).then(() => { });
  };

  handleTogglePanel = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  async clickTYRestStatus(restaurantid, status, i) {
    await this.props.setRestaurantStatusTrendyolYemek(restaurantid, status, i).then(() => { });
  }

  closeModalOrder() {
    this.props.formDataSetSetting('OrderControlModal', false).then(() => {
      soundPlay('stop');
      this.props.history.push('/app/packagepage?type=pedding');
    });
  }

  handleChangeUser = (name) => event => {
    if (name == 'WebApiUserId') {
      this.props.settingsReducer.Settings.WebApiUserId = event.target.value;
    } else if (name == 'UserId') {
      this.props.settingsReducer.Settings.LocalPrinterSystem.UserId = event.target.value;
    }

    this.props.formDataSetSetting('Settings', this.props.settingsReducer.Settings).then(() => { });
    this.props.setSettings().then(() => {
      window.location.reload(true);
    });
  };

  async renderData() {
    const { packageFilter } = this.props;
    this.props.getThirdPartyControl().then(() => {
      this.props.packageReload([], packageFilter).then(() => { });
    });
  }

  async renderRestData() {
    await this.props.getEntegrationRestList(true, true, true, true, true).then(() => { });
  }

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  renderMigrosYemekStore(data) {
    const {
      classes
    } = this.props;

    return data.mYemekRestaurants.map((n, i) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{
          width: '100%', fontWeight: '600', fontSize: 12, display: 'flex', alignItems: 'center'
        }}
        >
          <Icon style={{ color: (n.active ? '#43A047' : '#E53935'), marginRight: 5 }}>radio_button_checked</Icon>
          <span style={{
            overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, webkitBoxOrient: 'vertical'
          }}
          >
            {n.name}
          </span>
        </div>
        <ToggleButtonGroup size="small" style={{ borderColor: '#c8d6e5', display: 'flex', width: 140 }} value={(n.active ? 'left' : 'right')} exclusive>
          <ToggleButton onClick={() => this.props.setMigrosYemekStatus(data.ApiKey, n.storeId, n.warehouseId, true, n.name).then(() => { })} size="small" style={{ width: '100%', color: 'white', backgroundColor: (n.active ? '#10ac84' : '#222f3e') }} value="left">
            {'A'}
          </ToggleButton>
          <ToggleButton onClick={() => this.props.setMigrosYemekStatus(data.ApiKey, n.storeId, n.warehouseId, false, n.name).then(() => { })} style={{ width: '100%', color: 'white', backgroundColor: (!n.active ? '#ee5253' : '#222f3e') }} value="right">
            {'K'}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    ));
  }

  handleSoundControl = async () => {
    this.props.setSoundControl(false).then(() => { });
    const list = [
      {
        ResponseData: 'Ses testi yapılıyor. Lütfen uyarı sesini dikkate almayınız!',
        IsStatus: true
      }
    ];
    notificationShow(list, 5000);
    soundPlay('play');
    workerTimers.setTimeout(() => {
      soundPlay('stop');
    }, 2000);

    soundPlayCourier('play');
    workerTimers.setTimeout(() => {
      soundPlayCourier('stop');
    }, 2000);
  }

  renderSoundControl() {
    return (
      <div style={{
        display: (this.props.IsSoundControl ? '' : 'none'), zIndex: 9999, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.5)', bottom: 0, left: 0, right: 0, top: 0
      }}
      >
        <div style={{
          position: 'fixed', width: 400, backgroundColor: 'white', zIndex: 9999, padding: 20, borderRadius: 10, textAlign: 'center', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
        }}
        >
          <Icon style={{ fontSize: 50, color: '#424242' }}>notifications_active</Icon>
          <div style={{ display: 'grid' }}>
            <span style={{ color: '#424242', fontWeight: 500, marginTop: 5 }}>Uygulamanın ses çıkardığından emin olun.</span>
          </div>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <Button
              onClick={this.handleSoundControl}
              variant="contained"
              fullWidth
              style={{
                textTransform: 'capitalize', backgroundColor: '#27ae60', color: 'white', borderRadius: 3, width: '100%', margin: 3
              }}
            >
              {'Ses Testi Yap'}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  _handleEntegrationOpenClosed = (name, status) => event => {
    const {
      GetirYemekRestaurantList, MigrosYemekRestaurantList, TrendyolYemekRestaurantList, YemekSepetiRestaurantListGlobal
    } = this.props.settingsReducer;
    if (name == 'GetirYemek') {
      GetirYemekRestaurantList.map((n, i) => {
        this.props.getGetirYemekRestaurantStatus(status, i, n.name);
      });
    } else if (name == 'TrendyolYemek') {
      TrendyolYemekRestaurantList.map((n, i) => {
        this.clickTYRestStatus(n.id, (status == true ? 'OPEN' : 'CLOSED'), i);
      });
    } else if (name == 'MigrosYemek') {
      MigrosYemekRestaurantList.map((n, i) => {
        n.mYemekRestaurants.map((x, y) => {
          this.props.setMigrosYemekStatus(n.ApiKey, x.storeId, x.warehouseId, status, x.name).then(() => { });
        });
      });
    } else if (name == 'YemekSepeti') {
      YemekSepetiRestaurantListGlobal.map((n, i) => {
        this.props.setYemekSepetiGlobalRestStatus(n.chainCode, n.posVendorId, (status == true ? 'OPEN' : 'CLOSED'), n.platformRestaurantId, n.platformRestaurantName);
      });
    }
  };

  render() {
    const { classes, orderMenuTab } = this.props;
    const { show } = this.state;

    return (
      <div style={{ display: (!getLicenceModulePackage() || cookie.load('UserType') == 'Waiter' ? 'none' : (getLicence('date') < 0 ? 'none' : '')) }}>
        <TrendyolClaims classes={classes} />
        {this.renderSoundControl()}
        <aside
          className={
            classNames(
              classes.settingSidebar,
              classes.rightSidebar,
              show && classes.expanded
            )
          }
        >
          <div className={classes.toggleButton} style={{ top: 110 }}>
            <Fab
              size="small"
              color="primary"
              aria-label="toggle"
              className={classes.button}
              onClick={this.handleTogglePanel}
              classes={{
                root: classes.buttonDrawer,
              }}
            >
              <Tooltip title="Sipariş Kontrol Sayacı">
                <div className={classes.centerItem}>
                  <Chip
                    label={this.state.countOrder}
                    style={{
                      fontWeight: 700, fontSize: 23, color: 'white', backgroundColor: 'transparent', zIndex: 9999
                    }}
                    className={classes.chip}
                    color="default"
                  />
                  <CircularProgress style={{ color: '#424242' }} variant="determinate" className={classes.progressCircle} size={50} thickness={5} value={(this.state.countOrder == 25 ? 100 : this.state.countOrder * 4)} />
                </div>
              </Tooltip>
            </Fab>
          </div>
          <Slide direction="left" in={show} mountOnEnter unmountOnExit>
            <div className={classes.root} style={{ padding: 0, overflow: 'hidden', position: 'relative', borderLeft: '2px solid #84817a' }}>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <div style={{ display: 'flex', margin: 5, marginBottom: 0 }}>
                    <div
                      onClick={() => this.props.setOrderMenuTab(0).then(() => { })}
                      style={{
                        cursor: 'grab', borderBottom: (orderMenuTab == 0 ? '2px solid rgba(255, 255, 255, 0.7)' : ''), display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25%', height: 40, backgroundColor: 'rgba(66, 66, 66,1)'
                      }}
                    >
                      <span style={{ color: (orderMenuTab == 0 ? 'white' : 'rgba(255,255,255,.7)'), fontSize: 14 }}>Entegrasyon Kontrol</span>
                    </div>
                    <div
                      onClick={() => this.props.setOrderMenuTab(1).then(() => { })}
                      style={{
                        cursor: 'grab', borderBottom: (orderMenuTab == 1 ? '2px solid rgba(255, 255, 255, 0.7)' : ''), display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25%', height: 40, backgroundColor: 'rgba(66, 66, 66,1)'
                      }}
                    >
                      <span style={{ color: (orderMenuTab == 1 ? 'white' : 'rgba(255,255,255,.7)'), fontSize: 14 }}>Ürün Açma/Kapama</span>
                    </div>
                    <div
                      onClick={() => this.props.setOrderMenuTab(2).then(() => { })}
                      style={{
                        cursor: 'grab', borderBottom: (orderMenuTab == 2 ? '2px solid rgba(255, 255, 255, 0.7)' : ''), display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25%', height: 40, backgroundColor: 'rgba(66, 66, 66,1)'
                      }}
                    >
                      <span style={{ color: (orderMenuTab == 2 ? 'white' : 'rgba(255,255,255,.7)'), fontSize: 14 }}>Ürün Fiyat Değişikliği</span>
                    </div>
                    <div
                      onClick={() => this.props.setOrderMenuTab(3).then(() => { })}
                      style={{
                        cursor: 'grab', borderBottom: (orderMenuTab == 3 ? '2px solid rgba(255, 255, 255, 0.7)' : ''), display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25%', height: 40, backgroundColor: 'rgba(66, 66, 66,1)'
                      }}
                    >
                      <span style={{ color: (orderMenuTab == 3 ? 'white' : 'rgba(255,255,255,.7)'), fontSize: 14 }}>Ürün Eşleştirme</span>
                    </div>
                  </div>
                </Grid>
                <Grid item md={12} style={{ overflow: 'hidden', height: (orderMenuTab == 0 ? 'calc(100vh - 128.44px)' : 'calc(100vh - 52px)'), position: 'relative' }}>
                  {
                    (orderMenuTab == 0 ? <Entegration classes={classes} /> : ((orderMenuTab == 1 || orderMenuTab == 2 || orderMenuTab == 3) ? <EntegrationProduct classes={classes} /> : null))
                  }
                </Grid>
                <Grid item md={12} style={{ display: (orderMenuTab == 0 ? '' : 'none') }}>
                  <div style={{ margin: 5, padding: 5, backgroundColor: 'rgba(66, 66, 66,1)' }}>
                    <Grid container spacing={1}>
                      <Grid item md={6}>
                        <FormControl style={{ width: '100%' }} className={classes.formControl}>
                          <Typography
                            style={{
                              fontWeight: 400, color: 'rgba(255, 255, 255, 0.7)', marginBottom: 6, padding: 0, fontSize: 11, fontFamily: '"Open Sans", sans-serif', lineHeight: '1.2em'
                            }}
                            className={classes.flex}
                          >
                            {"Sipariş Kontrol Kullanıcısı"}
                          </Typography>
                          <Select
                            value={getParameter('WebApiUserId') || ''}
                            onChange={this.handleChangeUser('WebApiUserId')}
                            input={<Input name="WebApiUserId" id="age-helper" />}
                            style={{ width: '100%' }}
                          >
                            {
                              this.props.userReducer.userList.filter(x => x.UserTypeString == 'Manager' || x.UserTypeString == 'Cashier').map((n, i) => ([
                                <MenuItem key={i} value={n.Id}><em>{n.Name}</em></MenuItem>
                              ]))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl style={{ width: '100%' }} className={classes.formControl}>
                          <Typography
                            style={{
                              fontWeight: 400, color: 'rgba(255, 255, 255, 0.7)', marginBottom: 6, padding: 0, fontSize: 11, fontFamily: '"Open Sans", sans-serif', lineHeight: '1.2em'
                            }}
                            className={classes.flex}
                          >
                            {"Yazdırma Kullanıcısı"}
                          </Typography>
                          <Select
                            value={getParameter('LocalPrinterSystem').UserId || ''}
                            onChange={this.handleChangeUser('UserId')}
                            input={<Input name="WebApiUserId" id="age-helper" />}
                            style={{ width: '100%' }}
                          >
                            {
                              this.props.userReducer.userList.filter(x => x.UserTypeString == 'Manager' || x.UserTypeString == 'Cashier').map((n, i) => ([
                                <MenuItem key={i} value={n.Id}><em>{n.Name}</em></MenuItem>
                              ]))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <div style={{
                alignItems: 'center', textAlign: 'center', position: 'relative', height: '90%', display: (getParameter('WebApiUserId') == cookie.load('UserId') ? 'none' : 'flex')
              }}
              >
                <span style={{ color: 'white' }}>Entegrasyon sipariş kontrolü için uygun kullanıcıda değilsiniz!</span>
              </div>
            </div>
          </Slide>
        </aside>
        <ModalInformation event={this.modalClose} open={this.props.settingsReducer.modalOpen} title={this.props.settingsReducer.modalTitle} text={this.props.settingsReducer.modalText} />
        <div style={{
          display: (this.props.settingsReducer.OrderControlModal ? 'inline' : 'none'), position: 'absolute', zIndex: 9, width: 280, height: 160, backgroundColor: 'rgb(0, 150, 136)', bottom: 5, right: 5, borderRadius: 3, paddingTop: 15, textAlign: 'center', color: 'white'
        }}
        >
          <Button
            onClick={() => this.props.formDataSetSetting('OrderControlModal', false).then(() => { soundPlay('stop'); })}
            style={{
              position: 'absolute', top: 0, right: 0, height: 40, width: 40, minWidth: 40
            }}
          >
            <Icon style={{ color: 'white' }}>close</Icon>
          </Button>
          <div style={{
            fontSize: 15, color: 'white', marginTop: 5, marginLeft: 15, marginRight: 15, fontWeight: 600
          }}
          >
            {"Sipariş Geldi!"}
          </div>
          <div style={{
            fontSize: 13, color: 'white', marginTop: 5, marginLeft: 15, marginRight: 15, fontWeight: 600
          }}
          >
            {"Sistemde onay bekleyen siparişiniz bulunmaktadır"}
          </div>
          <Button
            onClick={() => this.closeModalOrder()}
            style={{
              marginTop: 10, backgroundColor: '#424242', color: 'white', borderRadius: 8, padding: 10, textTransform: 'capitalize', paddingLeft: 40, paddingRight: 40
            }}
            variant="contained"
          >
            {"Siparişe Git"}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  settingsReducer: state.get('settingsReducer').form_data,
  additionReducer: state.get('additionReducer').form_data,
  userReducer: state.get('userReducer').form_data,
  packageFilter: state.get('additionReducer').packageFilter,
  entegrationStatusLoading: state.get('settingsReducer').entegrationStatusLoading,
  IsSoundControl: state.get('settingsReducer').IsSoundControl,
  pendingPackageHubConnection: state.get('trackReducer').pendingPackageHubConnection,
  orderMenuTab: state.get('settingsReducer').orderMenuTab
});

const mapDispatchToProps = {
  formDataSetSetting,
  getSettings,
  setPrinterHubConnection,
  sendMessagePackagePrinter,
  getThirdPartyControl,
  getUser,
  setSettings,
  getGetirYemekRestaurantStatus,
  getGoFodyRestaurantStatus,
  getGetirYemekCourierEnable,
  setYemekSepetiGlobalRestStatus,
  setRestaurantStatusTrendyolYemek,
  setMigrosYemekStatus,
  setPendingPackageHubConnection,
  setSoundControl,
  packageReload,
  getEntegrationRestList,
  getTrendyolClaimsList,
  getRestStatus,
  setOrderMenuTab
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderControl)));
