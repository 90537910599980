import React from 'react';
import {
  createSelector
} from 'reselect';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../datepicker.css';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import tr from 'date-fns/locale/tr';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { EmptyData } from 'dan-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Popover from '@material-ui/core/Popover';
import ModalInformation from '../../Ortak/ModalInformation';
import { getParameter, trMoneyConvert } from '../../../../function/GeneralFunction';
import ProductItemDetailPage from '../../DetailPage/ProductItemDetailPage';
import AlertDialog from '../../Ortak/AlertDialog';
import TbPaginationActions from '../../Ortak/TbPaginationActions';
import {
  getAdditionHistory, getAddition, setPrinterHubConnection,
  sendMessagePackagePrinter, setModalAdditionClose, setModalAddition,
  getManagerControl, getPackageAdditionDetail
} from '../../../../redux/modules/addition';
import { RequestAdditionReport } from '../../../../redux/modules/report';
registerLocale('tr', tr);

class AdditionReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null, managerPasswordVisible: false, managerPasswordText: '', additionId: null, modalHistory: false, modalAdditionDetail: false, searchValue: '', startDate: new Date(new Date().setHours(0, 0, 0, 0)), endDate: new Date(new Date().setHours(23, 59, 59, 999))
    };
  }

  async componentDidMount() {
    const TimeData = getParameter('BusinessWorkingHours');
    await this.setState({ startDate: TimeData.start, endDate: TimeData.end });

    if (this.props.printerHubConnection == null) {
      await this.props.setPrinterHubConnection().then(() => { });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if (nextProps.additionListUpdate == this.props.additionListUpdate) {
    // return false;
    // }
    return true;
  }

  handleChangePage = (event, page) => {
    const { filterAdditionData } = this.props;
    filterAdditionData.searchText = this.state.searchValue;
    filterAdditionData.startDate = this.state.startDate;
    filterAdditionData.endDate = this.state.endDate;
    filterAdditionData.currentPage = page + 1;
    this.props.RequestAdditionReport(filterAdditionData).then(() => { });
  };

  handleChangeRowsPerPage = event => {
    const { filterAdditionData } = this.props;
    filterAdditionData.searchText = this.state.searchValue;
    filterAdditionData.startDate = this.state.startDate;
    filterAdditionData.endDate = this.state.endDate;
    filterAdditionData.rowsPerPage = event.target.value;
    this.props.RequestAdditionReport(filterAdditionData).then(() => { });
  };

  handleSearch = () => {
    const { filterAdditionData } = this.props;
    filterAdditionData.searchText = this.state.searchValue;
    filterAdditionData.startDate = this.state.startDate;
    filterAdditionData.endDate = this.state.endDate;
    this.props.RequestAdditionReport(filterAdditionData).then(() => { });
  };

  renderAdditionHistoryItem() {
    return this.props.AdditionHistoryList.map((n, i) => (
      <TableRow key={i}>
        <TableCell style={{ padding: 5 }}>{n.UserName}</TableCell>
        <TableCell style={{ padding: 5 }}>{moment(n.CreationTime).format('YYYY-MM-DD / HH:mm:ss')}</TableCell>
        <TableCell style={{ padding: 5, color: (n.OperationType == 'Package' ? '#2196F3' : (n.OperationType == 'Item' ? (n.OperationDetailType == 0 ? '#4CAF50' : (n.OperationDetailType == 1 ? '#FF9800' : '#F44336')) : (n.OperationDetailType == 0 ? '#4CAF50' : (n.OperationDetailType == 1 ? '#FF9800' : '#F44336')))) }} align="left">{n.OperationText}</TableCell>
      </TableRow>
    ));
  }

  async clickGetPackageHistory(Id) {
    const { classes } = this.props;
    await this.props.getAdditionHistory(Id).then(() => { });
    this.setState({ modalHistory: true });
  }

  renderHistory() {
    const { classes, AdditionHistoryList } = this.props;
    if (AdditionHistoryList.length == 0) {
      return null;
    }
    return (
      <div className={classes.rootTable}>
        <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 0 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 100 }}>Kullanıcı</TableCell>
              <TableCell style={{ width: 150 }}>Tarih</TableCell>
              <TableCell>İşlem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderAdditionHistoryItem()}
          </TableBody>
        </Table>
      </div>
    );
  }

  async clickGetAddition(Id) {
    await this.props.getAddition(Id).then(() => { });
    this.setState({ modalAdditionDetail: true });
  }

  renderManagerPassword() {
    return (
      <Dialog
        open={this.state.managerPasswordVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {'Adisyonu geri almak için yönetici şifresi girmelisiniz!'}
          <Typography style={{
            float: 'left', fontSize: 12, fontWeight: 600, color: '#f39c12', padding: 5, borderRadius: 5
          }}
          >
            {'Silinmiş veya Ödenmiş bir adisyon sadece yönetici şifresi ile geri alınabilir.'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={1}>
              <Grid item md={12}>
                <Input type="password" autoFocus fullWidth value={this.state.managerPasswordText} onChange={e => this.setState({ managerPasswordText: e.target.value })} />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} onClick={() => this.setState({ managerPasswordVisible: false })} color="primary">
            {'İptal'}
          </Button>
          <Button onClick={() => this.clickBackAddition()} color="secondary" variant="contained" autoFocus>
            {'Onay'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  async clickManagerPassword(Id) {
    await this.setState({ managerPasswordVisible: true, managerPasswordText: '', additionId: Id });
  }

  async clickBackAddition() {
    const { filterAdditionData } = this.props;
    filterAdditionData.searchText = this.state.searchValue;
    filterAdditionData.startDate = this.state.startDate;
    filterAdditionData.endDate = this.state.endDate;
    await this.props.getManagerControl(this.state.managerPasswordText, this.state.additionId).then(() => {
      this.setState({ managerPasswordVisible: false, managerPasswordText: '', additionId: null });
      this.props.RequestAdditionReport(filterAdditionData).then(() => { });
    });
  }

  clickPrintedPackage(Id, BrandId, type) {
    const { printerHubConnection } = this.props;
    this.props.sendMessagePackagePrinter(printerHubConnection, type, Id, BrandId).then(() => { });
  }

  modalClose = () => {
    this.props.setModalAdditionClose().then(() => { });
  };


  handleClickPopover = event => {
    this.props.getPackageAdditionDetail(event.currentTarget.id);
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.props.getPackageAdditionDetail('');
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { classes, loading, packageAdditionDetail } = this.props;
    const { anchorEl } = this.state;

    return (
      <Paper
        className={classes.root}
        elevation={0}
        style={{
          borderRadius: 0, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 10
        }}
      >
        {this.renderManagerPassword()}
        <ModalInformation event={this.modalClose} open={this.props.modalOpen} title={this.props.modalTitle} text={this.props.modalText} />
        <AlertDialog eventClose={() => this.setState({ modalHistory: false })} open={this.state.modalHistory} title="Adisyon Geçmişi" text={this.renderHistory()} />
        <ProductItemDetailPage Addition={this.props.addition_data} eventClose={() => this.setState({ modalAdditionDetail: false })} open={this.state.modalAdditionDetail} />
        <Toolbar style={{
          marginBottom: 5, minHeight: 48, width: '100%', display: 'flex'
        }}
        >
          <Grid container spacing={1}>
            <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
              <Typography
                style={{
                  marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                }}
                className={classes.flex}
              >
Başlangıç Tarihi
              </Typography>
              <DatePicker
                selected={this.state.startDate}
                onChange={date => this.setState({ startDate: date })}
                timeInputLabel="Saat:"
                dateFormat="dd.MM.yyyy HH:mm:ss"
                showTimeInput
                withPortal
                locale="tr"
                className="inputDate"
              />
            </Grid>
            <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
              <Typography
                style={{
                  marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                }}
                className={classes.flex}
              >
Bitiş Tarihi
              </Typography>
              <DatePicker
                selected={this.state.endDate}
                onChange={date => this.setState({ endDate: date })}
                timeInputLabel="Saat:"
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeInput
                withPortal
                locale="tr"
                className="inputDate"
              />
            </Grid>
            <Grid item md={3}>
              <Button disabled={loading} onClick={this.handleSearch} style={{ marginTop: 15, borderRadius: 3, marginLeft: 5 }} variant="contained" color="primary">
                {'Filtrele'}
                {(this.props.additionListLoading ? <CircularProgress style={{ color: 'white', marginLeft: 10 }} disableShrink thickness={3} size={15} /> : null)}
              </Button>
            </Grid>
          </Grid>
          <FormControl style={{ width: 225 }} className={classNames(classes.textField)}>
            <Input
              id="search_filter"
              type="text"
              placeholder="Arama"
              value={this.state.searchValue}
              onChange={(event) => this.setState({ searchValue: event.target.value })}
            />
          </FormControl>
          <Button disabled={loading} onClick={this.handleSearch} style={{ borderRadius: 3, marginLeft: 5 }} variant="contained" color="primary">
            {'Ara'}
            {(this.props.additionListLoading ? <CircularProgress style={{ color: 'white', marginLeft: 10 }} disableShrink thickness={3} size={15} /> : null)}
          </Button>
        </Toolbar>

        <Table style={{ marginTop: 5 }}>
          <TableHead style={{ backgroundColor: '#212121' }}>
            <TableRow>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Tip</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Adisyon No</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Masa Adı</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">M. Adı Soyadı</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Kullanıcı</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Kurye</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.props.filterAdditionData.List.map((n, i) => {
                let colorTable = '#2980b9';
                if (n.TableName == 'YemekSepeti' || n.TableName == 'YemekSepetiGlobal') {
                  colorTable = '#fa0050';
                } else if (n.TableName == 'GetirYemek') {
                  colorTable = '#8e44ad';
                } else if (n.TableName == 'TrendyolYemek') {
                  colorTable = '#d35400';
                } else if (n.TableName == 'GoFody') {
                  colorTable = '#f1b62a';
                } else if (n.TableName == 'MigrosYemek') {
                  colorTable = '#27ae60';
                }

                let deleteText = (n.OrderType == 1 ? 'Paket' : 'Adisyon');
                if (n.IsDelete) {
                  if (n.CancellationReason == 0) {
                    deleteText = deleteText + ' ' + 'Silindi';
                  } else if (n.CancellationReason == 1) {
                    deleteText = deleteText + ' ' + 'Transfer';
                  } else if (n.CancellationReason == 2) {
                    deleteText = deleteText + ' ' + 'Gruplama';
                  }
                }

                return (
                  <TableRow hover key={i}>
                    <TableCell align="left" style={{ width: 75, padding: 5 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                          marginRight: 5, backgroundColor: colorTable, borderRadius: 50, height: 15, width: 15
                        }}
                        />
                        <span style={{ color: (n.IsDelete ? '#e74c3c' : 'white') }}>
                          {deleteText}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left" style={{ padding: 5, width: 100 }}>{n.AdditionCode}</TableCell>
                    <TableCell align="left" style={{ padding: 5, width: 100 }}>{n.TableName}</TableCell>
                    <TableCell align="left" style={{ padding: 5 }}>{n.CustomerNameSurname}</TableCell>
                    <TableCell align="left" style={{ padding: 5 }}>{n.UserName}</TableCell>
                    <TableCell align="left" style={{ padding: 5 }}>{n.Courrier}</TableCell>
                    <TableCell align="right" style={{ padding: 5 }}>
                      {
                        n.OrderType != 2
                          ? (
                            <Tooltip title="Mutfak Yazdır">
                              <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5, borderRadius: 5 }} disabled={(n.PackagePrinted ? 'disabled' : '')} onClick={() => this.clickPrintedPackage(n.Id, n.BrandId, 1)}>
                                <Icon style={{ color: (n.PackagePrinted ? '#27ae60' : '#c0392b') }}>print</Icon>
                                <span style={{ fontSize: 12, marginLeft: 5 }}>Mutfak</span>
                              </IconButton>
                            </Tooltip>
                          ) : null
                      }
                      {
                        n.OrderType != 1
                          ? (
                            <Tooltip title="Paket Yazdır">
                              <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5, borderRadius: 5 }} disabled={(n.KitchenPrinted ? 'disabled' : '')} onClick={() => this.clickPrintedPackage(n.Id, n.BrandId, 0)}>
                                <Icon style={{ color: (n.KitchenPrinted ? '#27ae60' : '#c0392b') }}>print</Icon>
                                <span style={{ fontSize: 12, marginLeft: 5 }}>Paket</span>
                              </IconButton>
                            </Tooltip>
                          ) : null
                      }
                      <Tooltip title="Adisyon Geçmişi">
                        <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5 }} onClick={() => this.clickGetPackageHistory(n.Id)}>
                          <Icon>info</Icon>
                        </IconButton>
                      </Tooltip>
                      {
                        n.IsDelete || n.IsPayment
                          ? (
                            <Tooltip title="Adisyonu Geri Al">
                              <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5 }} onClick={() => this.clickManagerPassword(n.Id)}>
                                <Icon>compare_arrows</Icon>
                              </IconButton>
                            </Tooltip>
                          ) : null
                      }
                      <Tooltip title="Sipariş Detayı Git">
                        <IconButton name={n.Id} id={n.Id} onClick={this.handleClickPopover} style={{ marginRight: 5 }}>
                          <Icon>description</Icon>
                        </IconButton>
                      </Tooltip>
                      <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        PaperProps={{ style: { borderRadius: 0 } }}
                        onClose={this.handleClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <div style={{
                          padding: 5, width: 250, borderRadius: 0, backgroundColor: '#424242'
                        }}
                        >
                          {this.renderAdditionDraw(packageAdditionDetail)}
                        </div>
                      </Popover>
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
        {
          (this.props.filterAdditionData.List.length == 0 ? <EmptyData text="Adiyon Kaydı Bulunamadı" /> : null)
        }
        {(this.props.additionListLoading ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
        <div style={{ width: '100%', display: 'table' }}>
          <TablePagination
            labelRowsPerPage="Sayfa başına satır sayısı"
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={this.props.filterAdditionData.rowsPerPage}
            count={this.props.filterAdditionData.totalRecord}
            page={this.props.filterAdditionData.currentPage - 1}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TbPaginationActions}
          />
        </div>
      </Paper>
    );
  }

  renderProductDetail(property) {
    return property.map((n, x) => (
      <span
        key={x}
        style={{
          display: 'block', fontSize: 9, fontWeight: 600, color: '#00BCD4', textTransform: 'capitalize'
        }}
      >
        {this.renderPropertyDetail(n.Item)}
        {(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? '(' : '') : '')}
        {this.renderPropertyDetailMenu(n.MenuDetailSelected)}
        {(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? ')' : '') : '')}
      </span>
    ));
  }

  renderPropertyDetail(item) {
    return item.map((n, x) => {
      if (n.Default) {
        return (
          <span
            key={x}
            style={{
              marginLeft: 5, fontSize: 9, fontWeight: 600, color: '#00BCD4', textTransform: 'capitalize'
            }}
          >
            {n.Quantity > 0 ? n.Quantity + 'x' : ''}
            {n.Name}
          </span>
        );
      }
      return null;
    });
  }

  renderPropertyDetailMenu(MenuDetail) {
    if (MenuDetail != null) {
      if (MenuDetail.length > 0) {
        return MenuDetail.map((n, x) => n.Item.map((y, i) => {
          if (y.Default) {
            return (
              <span
                key={i}
                style={{
                  fontSize: 9, fontWeight: 600, color: '#00BCD4', textTransform: 'capitalize'
                }}
              >
                {' '}
                {y.Quantity > 0 ? y.Quantity + 'x' : ''}
                {y.Name}
                {' '}
              </span>
            );
          }
          return null;
        }));
      }
    }
  }

  renderProductDetailUnit(unit) {
    if (unit.length == 1) {
      return null;
    }
    return unit.map((n, x) => {
      if (n.IsSelected) {
        return '(' + n.UnitName + ')';
      }
    });
  }

  renderAdditionListPrice(item) {
    let price = item.Price;
    item.Unit.map(n => {
      if (n.IsSelected) {
        price = n.Price;
      }
    });
    item.Property.map(n => {
      n.Item.map(n => {
        if (n.Default && n.Quantity == 0) {
          price += n.Price;
        } else if (n.Default && n.Quantity > 0) {
          price += (n.Price * n.Quantity);
        }
      });
    });

    item.Menu.map(n => {
      n.Item.map(n => {
        if (n.Default) {
          price += n.Price;
        }
      });

      if (n.MenuDetailSelected != null) {
        if (n.MenuDetailSelected.length != 0) {
          n.MenuDetailSelected.map(x => {
            x.Item.map(n => {
              if (n.Default && n.Quantity == 0) {
                price += n.Price;
              } else if (n.Default && n.Quantity > 0) {
                price += (n.Price * n.Quantity);
              }
            });
          });
        }
      }
    });

    price *= item.Quantity;

    return price.toFixed(2).replace(/[.]/, ',');
  }

  renderAdditionDraw(n) {
    if (n == null) {
      return null;
    }
    return (
      <div style={{ display: 'block' }}>
        <div style={{
          display: 'flex', justifyContent: 'center', backgroundColor: '#16a085', color: 'white'
        }}
        >
Adisyon Detayları
        </div>
        <Grid container style={{ lineHeight: 1 }}>
          {this.renderAdditionDetailDraw(n)}
        </Grid>
        <Grid container style={{ padding: 8, lineHeight: 1 }}>
          <Grid item md={12} style={{ textAlign: 'left' }}>
            <span style={{
              fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize'
            }}
            >
              {'Adres:'}
              {' '}
              {n.CustomerAddressText}
            </span>
          </Grid>
          <Grid item md={12} style={{ textAlign: 'left' }}>
            <span style={{
              fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize'
            }}
            >
              {'Ödeme Notu:'}
              {' '}
              {n.PaymentNote}
            </span>
          </Grid>
        </Grid>
        <div style={{
          display: 'flex', justifyContent: 'center', backgroundColor: '#2980b9', color: 'white'
        }}
        >
Ürün Detayları
        </div>
        <Grid container style={{ lineHeight: 1 }}>
          {this.renderProductDetailDraw(n.ProductItems)}
        </Grid>
        <div style={{
          display: 'flex', justifyContent: 'center', backgroundColor: '#f39c12', color: 'white'
        }}
        >
Ödeme Detayları
        </div>
        <Grid container style={{ lineHeight: 1 }}>
          {n.Payments != null ? this.renderPaymentsDraw(n.Payments) : null}
        </Grid>
      </div>
    );
  }

  renderAdditionDetailDraw(addi) {
    return (
      <Grid container spacing={1} style={{ flexDirection: 'row' }}>
        <Grid item md={5} style={{ textAlign: 'left' }}>
          <span style={{
            marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white'
          }}
          >
            {'Adisyon No'}
          </span>
        </Grid>
        <Grid item md={7} style={{ textAlign: 'right' }}>
          <span style={{
            marginRight: 7, fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize'
          }}
          >
            {addi.AdditionId}
          </span>
        </Grid>
        <Grid item md={5} style={{ textAlign: 'left' }}>
          <span style={{
            marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white'
          }}
          >
            {'Müşteri Adı'}
          </span>
        </Grid>
        <Grid item md={7} style={{ textAlign: 'right' }}>
          <span style={{
            marginRight: 7, fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize'
          }}
          >
            {addi.CustomerNameSurname}
          </span>
        </Grid>
      </Grid>
    );
  }

  renderPaymentsDraw(payments) {
    return payments.map((n, i) => (
      <Grid
        key={i}
        item
        md={12}
        style={{
          marginTop: 5, left: 2, marginBottom: 5, right: 2, flexWrap: 'nowrap'
        }}
      >
        <Grid container style={{ flexDirection: 'row' }}>
          <Grid item md={10} style={{ textAlign: 'left' }}>
            <span style={{
              marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white'
            }}
            >
              {(n.PaymentType != 2 ? (n.PaymentType == 1 ? 'Ürün Parçalı' : n.PaymentName) : 'İndirim')}
              {(n.CustomerName != '' ? (n.CustomerName != null ? ' - ' + n.CustomerName : '') : '')}
            </span>
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <span style={{
              marginRight: 7, fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize'
            }}
            >
              {trMoneyConvert(n.Price,2)}
            </span>
          </Grid>
        </Grid>
      </Grid>
    ));
  }

  renderProductDetailDraw(productList) {
    return productList.filter(x => x.IsDelete == false && x.ParentIndex == 0).map((n, i) => (
      <Grid
        key={i}
        item
        md={12}
        style={{
          marginTop: 2, left: 2, marginBottom: 5, right: 2, flexWrap: 'nowrap'
        }}
      >
        <Grid container style={{ flexDirection: 'row' }}>
          <Grid item md={1}>
            <span style={{
              marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white', marginRight: 5
            }}
            >
              {n.Quantity}
            </span>
          </Grid>
          <Grid item md={9} style={{ textAlign: 'left' }}>
            <span style={{
              marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize'
            }}
            >
              {n.Name}
              {' '}
              {this.renderProductDetailUnit(n.Unit)}
            </span>
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <span style={{
              marginRight: 7, fontSize: 10, fontWeight: 500, color: 'white'
            }}
            >
              {this.renderAdditionListPrice(n)}
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={1} />
          <Grid item md={11} style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
            <span style={{
              fontSize: 9, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize'
            }}
            >
              {this.renderProductDetail(n.Property)}
              {this.renderProductDetail(n.Menu)}
              {
                (n.Description != null ? (n.Description.length != 0 ? (
                  <span style={{
                    marginLeft: 7, fontSize: 10, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize'
                  }}
                  >
                    {'('}
                    {n.Description}
                    {')'}
                  </span>
                ) : null) : null)
              }
            </span>
          </Grid>
        </Grid>
      </Grid>
    ));
  }
}

const valueSelector = (state, props) => state.get('reportReducer').additionListUpdate;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  additionListUpdate: value(state, props),
  filterAdditionData: state.get('reportReducer').filterAdditionData,
  additionListLoading: state.get('reportReducer').additionListLoading,
  AdditionHistoryList: state.get('additionReducer').form_data.AdditionHistoryList,
  addition_data: state.get('additionReducer').form_data.addition_data,
  printerHubConnection: state.get('additionReducer').form_data.printerHubConnection,

  modalOpen: state.get('additionReducer').form_data.modalOpen,
  modalText: state.get('additionReducer').form_data.modalText,
  modalTitle: state.get('additionReducer').form_data.modalTitle,
  packageAdditionDetail: state.get('additionReducer').packageAdditionDetail,
});

const mapDispatchToProps = {
  RequestAdditionReport,
  getAdditionHistory,
  getAddition,
  setPrinterHubConnection,
  sendMessagePackagePrinter,
  setModalAdditionClose,
  setModalAddition,
  getManagerControl,
  getPackageAdditionDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdditionReport));
