import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { PapperBlock } from 'dan-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalInformation from '../Ortak/ModalInformation';
import CourierDialog from '../Ortak/CourierDialog';
import Outer from '../../Templates/Outer';
import Grid from '@material-ui/core/Grid';
import Decoration from '../../Templates/Decoration';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import jarvis from 'dan-images/jarvis.png';
import styles from './courierpage-jss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formDataSet, getCourierPackages, setPackageCourier, packageClose, setCourier } from '../../../redux/modules/courier';
import { getAddition, clearAddition } from '../../../redux/modules/addition';
import { getSettings } from '../../../redux/modules/settings';
import cookie from 'react-cookies';
import {
  ShadowDivider
} from '../../../components/Divider';

class CourierPackageList extends React.Component {
  constructor(props){
    super(props);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async componentDidMount(){
    const response1 = await this.props.getCourierPackages().then (()=>{
      this.interval = setInterval(() => {
        this.props.formDataSet("Timer" , this.props.courierReducer.Timer - 1).then (()=>{})
        if (this.props.courierReducer.Timer == 1) {
          window.location.href = "/courier";
        }
      }, 1000);
    });
  }

  async clickPackageSelected(Id){
    await this.props.setCourier(Id).then (()=>{
      if (this.props.courierReducer.CourierPackageList.find(x=>x.Id == Id).IsSelected) {
        this.props.getAddition(Id).then (()=>{})
      }else {
        this.props.clearAddition().then (()=>{})
      }
    })
  }

  renderPackageButton(){
    const { classes } = this.props;
    const { CourierPackageList, CourierPackageListLoad } = this.props.courierReducer;

    if (!CourierPackageListLoad) {
      return  <div style={{ textAlign:'center', width:'100%', marginTop:50 }}><CircularProgress style={{ backgroundColor:'transparent' }} className={classes.progress} size={50} /></div>;
    }else {
    return CourierPackageList.map((n,i) => {
      return(
        <Grid key={i} item md={3} style={{ padding:3 }}>
        <ButtonBase onClick={() => this.clickPackageSelected(n.Id)} style={{backgroundColor:(n.StandbyTime <= 20 ? "rgba(0,119,181 ,1)" : (n.StandbyTime <= 30 && n.StandbyTime >= 20 ? "rgb(232, 111, 98)" : (n.StandbyTime <= 40 && n.StandbyTime >= 30 ? "rgb(232, 21, 31)" : "rgba(175,6,6 ,1)"))),width:'100%' ,height:'100%',color:'white', height:150, position:'relative'}}>
          <Typography style={{ fontSize:15, position:'absolute', top:5, right:10 }} variant="subtitle1">{n.StandbyTime}</Typography>
          <Typography style={{ fontSize:15, position:'absolute', bottom:5, left:10 }} variant="subtitle1">{n.CustomerNameSurname}</Typography>
          <Typography style={{ fontSize:25, fontWeight:'bold' }} variant="subtitle1">{n.AdditionCode}</Typography>
          {(n.IsSelected ? <Icon style={{ fontSize:40, position:'absolute', top:5, left:10, fontWeight:'bold' }}>{"check_box"}</Icon> : null )}
        </ButtonBase>
        </Grid>
      )
    })
    }
  }

  renderAdditionListPrice(item){
    let price = item.Price;
    item.Unit.map(n => {
      if (n.IsSelected) {
        price = n.Price
      }
    })
    item.Property.map(n => {
      n.Item.map(n => {
        if (n.Default && n.Quantity == 0) {
          price += n.Price
        }else if (n.Default && n.Quantity > 0){
          price += (n.Price * n.Quantity)
        }
      })
    })

    item.Menu.map(n => {
      n.Item.map(n => {
        if (n.Default) {
          price += n.Price
        }
      })

      if (n.MenuDetailSelected != null) {
        if (n.MenuDetailSelected.length != 0) {
          n.MenuDetailSelected.map(x => {
            x.Item.map(n => {
              if (n.Default && n.Quantity == 0) {
                price += n.Price
              }else if (n.Default && n.Quantity > 0){
                price += (n.Price * n.Quantity)
              }
            })
          })
        }
      }
    })

    price = price * item.Quantity;
    return price.toFixed(2).replace(/[.]/, ",");
  }

  compare(a, b) {
  const genreA = a.Order;
  const genreB = b.Order;

  let comparison = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison;
  }

  renderProductDetailUnit(unit){
    if (unit.length == 1) {
      return null;
    }else {
      return unit.map((n,x)=> {
        if (n.IsSelected) {
          return "("+n.UnitName+")"
        }
      })
    }
  }

  renderPropertyDetailMenu(MenuDetail){
    if (MenuDetail != null) {
      if (MenuDetail.length > 0) {
        return MenuDetail.map((n,x)=> {
          return n.Item.map((y,i) => {
            if (y.Default) {
              return(
                <span key={i} style={{marginLeft:7,fontSize:13,fontWeight:600, color:'#2980b9',textTransform: 'capitalize'}}>{y.Quantity > 0 ? y.Quantity + "x" : ""}{y.Name}{" "}</span>
              )
            }else {
              return null;
            }
          })
        })
      }
    }
  }

  renderPropertyDetail(item){
    return item.map((n,x)=> {
      if (n.Default) {
        return(
          <span key={x} style={{marginLeft:7,fontSize:13,fontWeight:600, color:'#2980b9',textTransform: 'capitalize'}}>{n.Quantity > 0 ? n.Quantity + "x" : ""}{" "}{n.Name}</span>
        )
      }else {
        return null;
      }
    })
  }

  renderProductDetail(property){
    return property.map((n,x)=> {
      return(
      <span key={x} style={{fontSize:13,fontWeight:600, color:'#2980b9',textTransform: 'capitalize'}}>{this.renderPropertyDetail(n.Item)}{(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? "(" : "" ): "")}{this.renderPropertyDetailMenu(n.MenuDetailSelected)}{(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? ")" : "" ): "")}</span>
      )
    })
  }

  renderAdditionList(){
    let order = this.props.additionReducer.addition_data.ProductItems.filter(x=>x.IsDelete == false).sort(this.compare);
    return order.map((item,i)=> {
      return(
        <div key={i} style={{borderRadius:0, textAlign:'inherit',paddingTop:2,margin:5, paddingBottom:2, minHeight:56,flex:1, position:'relative' }}>
        <Grid item md={12} style={{ bottom:0, left:0, top:0, right:0 }}>
          <Grid container style={{ flexDirection:'row' }}>
            <Grid item md={1}>
              <span style={{marginLeft:7,fontSize:15,fontWeight:500, color:'white'}}>{item.Quantity}</span>
            </Grid>
            <Grid item md={9}>
              <span style={{marginLeft:7,fontSize:15,fontWeight:500, color:'white',textTransform: 'capitalize'}}>{item.Name} {this.renderProductDetailUnit(item.Unit)}</span>
            </Grid>
            <Grid item md={2} style={{ textAlign:'right' }}>
              <span style={{marginRight:7,fontSize:16,fontWeight:500, color:'white'}}>{this.renderAdditionListPrice(item)}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={1}>
            </Grid>
            <Grid item md={11} style={{whiteSpace: 'pre-line'}}>
            <span style={{fontSize:13,fontWeight:600, color:'#2980b9',textTransform: 'capitalize' }}>
              { this.renderProductDetail(item.Property) }
              { this.renderProductDetail(item.Menu) }
              {
               (item.Description != null ? (item.Description.length != 0 ? <span style={{marginLeft:7,fontSize:13,fontWeight:600, color:'#2980b9',textTransform: 'capitalize'}}>{"("}{item.Description}{")"}</span> : null) : null)
              }
            </span>
            </Grid>
          </Grid>
        </Grid>
        </div>
      );
    })
  }

  async clickSetPackage(){
    if (this.props.courierReducer.CourierPackageList.filter(x=>x.IsSelected == true).length == 0) {
      this.props.formDataSet("modalOpen" , true).then (()=>{})
      this.props.formDataSet("modalText" , "Paket seçmeniz gerekmektedir!").then (()=>{})
      this.props.formDataSet("modalTitle" , "Uyarı").then (()=>{})
    }else {
      await this.props.setPackageCourier().then (()=>{})
    }
  }

  clickClose(){
    this.props.packageClose().then (()=>{})
  }

  modalAlertClose = () => {
    this.props.formDataSet("modalOpen" , false).then (()=>{})
  };

  render() {
    const { classes } = this.props;

    return (
      <Outer style={{ padding:5 }}>
        <ModalInformation event={this.modalAlertClose} open={this.props.courierReducer.modalOpen} title={this.props.courierReducer.modalTitle} text={this.props.courierReducer.modalText} />
        <Grid container style={{ height:'100vh', zIndex: 99 }}>
          <Grid item md={9} style={{ padding:10, paddingRight:0 }}>
            <div style={{ width:'100%', height:window.innerHeight-20, backgroundColor:'#424242', padding:2, overflowY:'auto' }}>
              <Grid container style={{ maxHeight:'100%', overflowY:'auto' }}>
                { this.renderPackageButton() }
              </Grid>
            </div>
          </Grid>
          <Grid item md={3} style={{ padding:10 }}>
            <div style={{flex:1,flexDirection:'column', overflowY:'auto', width:'100%', height:'100%', backgroundColor:'#424242', position:'relative' }}>
            <Typography style={{ fontSize:13, textAlign:'center', margin:5, border:'1px solid #1abc9c' }} variant="subtitle1">{cookie.load('PackageUserName')}</Typography>
            <div style={{ margin:10, display:(this.props.additionReducer.addition_data.CustomerName.length == 0 ? 'none' : 'inline-block') }}>
              <Typography style={{ fontSize:12, color:'#f39c12' }} variant="subtitle1">{"Müşteri Adı : "}{this.props.additionReducer.addition_data.CustomerName}</Typography>
              <Typography style={{ fontSize:12, color:'#f39c12' }} variant="subtitle1">{"Adres : "}{this.props.additionReducer.addition_data.CustomerAddressText}</Typography>
              <Typography style={{ fontSize:12, color:'#f39c12' }} variant="subtitle1">{"Açıklama : "}{this.props.additionReducer.addition_data.AdditionDescription}</Typography>
            </div>
            <ShadowDivider style={{ marginTop:0, marginBottom:0 }}/>
            { this.renderAdditionList() }
            <ButtonBase onClick={() => this.clickClose()} style={{backgroundColor:'rgba(192,57,43 ,1)',width:'30%' ,height:'100%',color:'white', height:60, position:'absolute', bottom:0, right:0}}>
               <Typography style={{ fontSize:25 }} variant="subtitle1">{"Çıkış"}</Typography>
            </ButtonBase>
            <ButtonBase onClick={() => this.clickSetPackage()} style={{backgroundColor:'#1abc9c',width:'70%' ,height:'100%',color:'white', height:60, position:'absolute', bottom:0}}>
               <span style={{ color:'white', fontSize:25 }}>{this.props.courierReducer.Timer}</span>
               <Typography style={{ fontSize:25, marginLeft:15 }} variant="subtitle1">{"Paketleri Al"}</Typography>
            </ButtonBase>
            </div>
          </Grid>
        </Grid>
      </Outer>
    );
  }
}

const mapStateToProps = state => {
	return {
    courierReducer : state.get('courierReducer').form_data,
    additionReducer : state.get('additionReducer').form_data,
    settingsReducer : state.get('settingsReducer').form_data,
	};
};

const mapDispatchToProps = {
  formDataSet,
  getCourierPackages,
  getAddition,
  clearAddition,
  setPackageCourier,
  packageClose,
  setCourier,
  getSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(CourierPackageList)));
