import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import "../datepicker.css";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  PieChart,
  Pie,
  Sector
} from 'recharts';
import { createTheme } from '@material-ui/core/styles';
import ThemePallete from 'dan-api/palette/themePalette';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DataTable from 'react-data-table-component';
import StockMovementList from './StockMovementList';
import StockRecipeList from './StockRecipeList';


const ExpanableComponentUnit = ({ data }) => {
  return data.ProductUnit.map((n, i) => {
    return (
      <div key={i} style={{ width: '35%', display: 'flex' }}>
        <div style={{ color: '#1abc9c', fontSize: 14, width: '80%' }}>{n.UnitName}</div>
        <div style={{ color: '#1abc9c', fontSize: 14, width: '20%', textAlign: 'right' }}>{n.Total.toFixed(2)}</div>
      </div>
    )
  });
};

const ExpanableComponentProperty = ({ data }) => {
  return data.PropertyItems.map((n, i) => {
    return (
      <div key={i} style={{ width: '35%', display: 'flex' }}>
        <div style={{ color: '#00BCD4', fontSize: 14, width: '15%', textAlign: 'left' }}>{n.TotalCount}</div>
        <div style={{ color: '#00BCD4', fontSize: 14, width: '70%' }}>{n.Name}</div>
        <div style={{ color: '#00BCD4', fontSize: 14, width: '15%', textAlign: 'right' }}>{n.TotalPrice.toFixed(2)}</div>
      </div>
    )
  });
};

const ExpanableComponentMenuItem = ({ data }) => {
  return data.map((n, i) => {
    return (
      <div key={i} style={{ width: '35%', display: 'flex' }}>
        <div style={{ color: '#FFC107', fontSize: 14, width: '15%', textAlign: 'left' }}>{n.TotalCount}</div>
        <div style={{ color: '#FFC107', fontSize: 14, width: '70%' }}>{n.Name}</div>
        <div style={{ color: '#FFC107', fontSize: 14, width: '15%', textAlign: 'right' }}>{n.TotalPrice.toFixed(2)}</div>
      </div>
    )
  });
};

const ExpanableComponentMenu = ({ data }) => {
  return data.MenuItems.map((n, i) => {
    return (
      <div key={i}>
        <div style={{ width: '35%', display: 'flex' }}>
          <div style={{ color: '#00BCD4', fontSize: 14, width: '15%', textAlign: 'left' }}>{n.TotalCount}</div>
          <div style={{ color: '#00BCD4', fontSize: 14, width: '70%' }}>{n.Name}</div>
          <div style={{ color: '#00BCD4', fontSize: 14, width: '15%', textAlign: 'right' }}>{n.TotalPrice.toFixed(2)}</div>
        </div>
        <div style={{ display: (n.MenuItems.length > 0 ? 'block' : 'none'), backgroundColor: 'rgb(93, 91, 91)', borderRadius: 5, padding: 10, margin: 10 }}>
          <ExpanableComponentMenuItem data={n.MenuItems} />
        </div>
      </div>
    )
  });
};

const ExpanableComponent = ({ data }) => {
  return (
    <div style={{ display: ((data.MenuItems.length > 0 || data.PropertyItems.length > 0) ? 'block' : 'none'), backgroundColor: '#4b4b4b', borderRadius: 5, padding: 10, margin: 10 }}>
      <div style={{ display: 'flex', width: '35%', borderBottom: '1px solid #636363' }}>
        <div style={{ color: 'white', fontSize: 13, width: '80%' }}>{"Birim Adı"}</div>
        <div style={{ color: 'white', fontSize: 13, width: '20%', textAlign: 'right' }}>{"Toplam"}</div>
      </div>
      <ExpanableComponentUnit data={data} />
      <div style={{ display: 'flex', width: '35%', borderBottom: '1px solid #636363' }}>
        <div style={{ color: 'white', fontSize: 13, width: '15%', textAlign: 'left' }}>{"Miktar"}</div>
        <div style={{ color: 'white', fontSize: 13, width: '70%' }}>{"Ürün Adı"}</div>
        <div style={{ color: 'white', fontSize: 13, width: '15%', textAlign: 'right' }}>{"Tutar"}</div>
      </div>
      <ExpanableComponentProperty data={data} />
      <ExpanableComponentMenu data={data} />
    </div>
  )
};

const theme = createTheme(ThemePallete.greenTheme);
const color = ({
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
});

const options = {
  filterType: 'checkbox',
  rowsPerPage: 50,
  selectableRows: 'none'
};

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + ((outerRadius + 10) * cos);
  const sy = cy + ((outerRadius + 10) * sin);
  const mx = cx + ((outerRadius + 30) * cos);
  const my = cy + ((outerRadius + 30) * sin);
  const ex = mx + ((cos >= 0 ? 1 : -1) * 22);
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <text style={{ color: 'white' }} x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + ((cos >= 0 ? 1 : -1) * 12)} y={ey} textAnchor={textAnchor} fill="white">{`Tutar ${value.toFixed(2)}`}</text>
      <text x={ex + ((cos >= 0 ? 1 : -1) * 12)} y={ey} dy={18} textAnchor={textAnchor} fill="white">
        {`(Oran ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

renderActiveShape.defaultProps = {
  cx: 0,
  cy: 0,
  midAngle: 0,
  innerRadius: 0,
  outerRadius: 0,
  startAngle: 0,
  endAngle: 0,
  fill: '#eee',
  payload: '',
  percent: 0,
  value: 0,
};

class ProductReport extends React.Component {
  constructor() {
    super();
  }

  state = {
    activeIndex: 0
  };

  renderDataProductBestSallerCount() {
    const { data } = this.props;
    if (data.length == 0) {
      return []
    }

    var dataArray = [];
    data.ProductBestSellersCount.map((n, i) => {
      dataArray.push({ Name: n.Name, Count: n.Count, amt: n.Count });
    })
    return dataArray;
  }

  renderSalesReportData() {
    const { data } = this.props;
    if (data.length == 0) {
      return []
    }

    var list = [];
    data.ProductData.map((n, i) => {
      list.push({ ProName: n.ProName, TableUserName: n.TableUserName, TotalCount:Number(n.TotalCount.toFixed(2)) , TotalPrice:Number(n.TotalPrice.toFixed(2)) , PropertyItems: n.PropertyItems, MenuItems: n.MenuItems, ProductUnit: n.ProductUnit });
    });
    return list;
  }

  renderDataProductBestSallerTotal() {
    const { data } = this.props;
    if (data.length == 0) {
      return []
    }

    var dataArray = [];
    data.ProductBestSellersTotal.map((n, i) => {
      dataArray.push({ Name: n.Name, Total: n.Total, amt: n.Total });
    })
    return dataArray;
  }

  renderDataProductGroupBestSallers() {
    const { data } = this.props;
    if (data.length == 0) {
      return []
    }

    var dataArray = [];
    data.ProductGroupSales.map((n, i) => {
      dataArray.push({ name: n.GroupName, value: n.Total });
    })
    return dataArray;
  }

  renderColorfulLegendText(value, entry) {
    const { color } = entry;
    return <span style={{ color: 'white', fontSize: 12 }}>{value}</span>;
  }

  
  renderProductType() {
    const { classes, data, ProductTabsValue, handleProductDownload, loading } = this.props;
    const { activeIndex } = this.state;

    const columnsNew = [
      {
        name: 'Ürün Adı',
        selector: 'ProName',
        sortable: true
      },
      {
        name: 'Satış Kanalı',
        selector: 'TableUserName',
        sortable: true
      },
      {
        name: 'Stok Miktarı',
        selector: 'TotalCount',
        sortable: true,
        right: true,
      },
      {
        name: 'Stok Tutarı',
        selector: 'TotalPrice',
        sortable: true,
        right: true,
      }
    ];

    if (ProductTabsValue == 0) {
      return (
        <div>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 30, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'center' }} className={classes.flex}>{"Adet Bazında (20 Ürün)"}</Typography>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              height={400}
              data={this.renderDataProductBestSallerCount()}
              margin={{
                right: 10,
                bottom: 100
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={"#c0392b"} stopOpacity={1} />
                  <stop offset="95%" stopColor={"#c0392b"} stopOpacity={1} />
                </linearGradient>
              </defs>
              <XAxis interval={0} angle={-45} textAnchor="end" dataKey="Name" tickLine={false} label={{ size: 12 }} />
              <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <CartesianAxis vertical={false} />
              <Tooltip />
              <Legend verticalAlign={"top"} formatter={this.renderColorfulLegendText} />
              <Bar name="Adet" dataKey="Count" fillOpacity="1" fill="url(#colorUv)" />
            </BarChart>
          </ResponsiveContainer>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 30, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'center' }} className={classes.flex}>{"Tutar Bazında (20 Ürün)"}</Typography>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              height={400}
              data={this.renderDataProductBestSallerTotal()}
              margin={{
                right: 10,
                bottom: 100
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={"#c0392b"} stopOpacity={1} />
                  <stop offset="95%" stopColor={"#c0392b"} stopOpacity={1} />
                </linearGradient>
              </defs>
              <XAxis interval={0} angle={-45} textAnchor="end" dataKey="Name" tickLine={false} label={{ size: 12 }} />
              <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <CartesianAxis vertical={false} />
              <Tooltip />
              <Legend verticalAlign={"top"} formatter={this.renderColorfulLegendText} />
              <Bar name="Tutar" dataKey="Total" fillOpacity="1" fill="url(#colorUv)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )
    } else if (ProductTabsValue == 1) {
      return (
        <div style={{ width: '100%', textAlign: '-webkit-center' }}>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 30, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'center' }} className={classes.flex}>{"Ürün Grubu Bazında Satış Raporu"}</Typography>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          <PieChart
            width={500}
            height={400}
            margin={{
              top: 5,
              bottom: 5
            }}
          >
            <Pie
              dataKey="value"
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={this.renderDataProductGroupBestSallers()}
              cx={250}
              cy={200}
              innerRadius={60}
              outerRadius={100}
              fill={"#f39c12"}
              fillOpacity="0.8"
              onMouseEnter={(event) => this.onPieEnter(event)}
            />
          </PieChart>
        </div>
      )
    } else if (ProductTabsValue == 2) {
      return (
        <div>
          <DataTable
            title="Ürün Satış Listesi"
            columns={columnsNew}
            data={this.renderSalesReportData()}
            expandableRows
            theme="dark"
            subHeader={true}
            noHeader={true}
            subHeaderComponent={
              (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton style={{ backgroundColor: '#3e3e3e' }} onClick={handleProductDownload}>
                    <Icon style={{ margin: '5px', color: 'white', fontSize: 35 }}>{"cloud_download"}</Icon>
                  </IconButton>
                </div>
              )
            }
            subHeaderAlign={'right'}
            highlightOnHover={true}
            expandableRowDisabled={row => row.disabled}
            expandableRowsComponent={<ExpanableComponent />}
          />
        </div>
      )
    } else if (ProductTabsValue == 3) {
      return(
        <StockMovementList loading={loading} classes={classes} />
      )
    } else if (ProductTabsValue == 4) {
      return(
        <StockRecipeList loading={loading} ProductTabsValue={ProductTabsValue} classes={classes} />
      )
    } else if (ProductTabsValue == 5) {
      return(
        <StockRecipeList loading={loading} ProductTabsValue={ProductTabsValue} classes={classes} />
      )
    }
  }

  onPieEnter(evt) {
    const index = this.renderDataProductGroupBestSallers().findIndex(p => p.name === evt.name);
    this.setState({
      activeIndex: index,
    });
  }

  render() {
    const { StartDate, EndDate, handleChangeFilter, handleFilterProductReport, classes, ProductTabsValue, loading } = this.props;

    return (
      <Paper className={classes.root} elevation={0} style={{ borderRadius: 0, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 10 }}>
        {
          (ProductTabsValue == 0 || ProductTabsValue == 1 || ProductTabsValue == 2 ?
            <Grid container spacing={1}>
              <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
                <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Başlangıç Tarihi"}</Typography>
                <DatePicker
                  selected={StartDate}
                  onChange={handleChangeFilter("StartDate")}
                  timeInputLabel="Saat:"
                  dateFormat="dd.MM.yyyy HH:mm"
                  showTimeInput
                  withPortald
                  className={"inputDate"}
                />
              </Grid>
              <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
                <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Bitiş Tarihi"}</Typography>
                <DatePicker
                  selected={EndDate}
                  onChange={handleChangeFilter("EndDate")}
                  timeInputLabel="Saat:"
                  dateFormat="dd.MM.yyyy HH:mm"
                  showTimeInput
                  withPortal
                  className={"inputDate"}
                />
              </Grid>
              <Grid item md={3}>
                <Button disabled={loading} onClick={handleFilterProductReport} style={{ borderRadius: 5, marginTop: 15, height: 37, padding: '6px 24px' }} size="large" variant="contained" color="secondary" type="submit">
                  {"Filtrele"}
                </Button>
              </Grid>
              <Grid item md={12}>
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
              </Grid>
            </Grid> : null)
        }
        {this.renderProductType()}
      </Paper>
    )
  }
}

export default ProductReport;
