import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Switch from '@material-ui/core/Switch';
import Icon from '@material-ui/core/Icon';

const AuthorizationListItem = (props) => {
      const { data, onChange } = props;
      return (
        <ListItem>
          <ListItemText primary={data.Title} />
          <ListItemSecondaryAction>
            <Switch
              onChange={() => onChange(data.Name)}
              checked={data.IsStatus}
            />
          </ListItemSecondaryAction>
        </ListItem>
      );
};

export default AuthorizationListItem;
