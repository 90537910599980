import React, { Fragment } from 'react';
import {
  createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import TablePagination from '@material-ui/core/TablePagination';
import { EmptyData } from 'dan-components';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import cookie from 'react-cookies';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import { getLicenceModule, getLicenceModulePackage, getIsLite, getParameter } from '../../../../function/GeneralFunction';
import ModalInformation from '../../Ortak/ModalInformation';
import PrinterGroup from './PrinterGroup';
import Category from './Category';
import ProductAddAll from './ProductAddAll';
import ProductAllDiscount from './ProductAllDiscount';
import ProductPropertyAddList from './ProductPropertyAddList';
import ProductRecipe from './ProductRecipe';
import ProductQuantityList from './ProductQuantityList';
import ProductAdd from './ProductAdd';
import AlertNotification from '../../Ortak/AlertNotification';
import {
  setPrinterHubConnection,
  sendMessagePrinter
} from '../../../../redux/modules/addition';
import {
  getProductList, setProductListVisible, formDataSetStock, setProductDelete, setModalStock,
  getProductUpdate, setCategoryListVisible, stockClear, setProductListUpdate, setProductIsQrMenu,
  productListUpdate, setProductQuantityListVisible, setProductQuantityAddVisible, setPrinterGroupListVisible,
  getProductCopy, setProductRecipeListVisible, setProductMovementListVisible, setProductEntegrationVisible,
  setProductPropertyListVisible, setProductPriceUpdate, setProductAllDiscountVisible, setProductAddAllVisible,
  setStockTakingVisible, setProductIsApp, setProductPrinter
} from '../../../../redux/modules/stock';
import TbPaginationActions from '../../Ortak/TbPaginationActions';
import StockTaking from './StockTaking';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale={true}
      autocomplete="off"
      allowNegative={false}
      decimalSeparator=","
      thousandSeparator="."
      prefix="₺"
    />
  );
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '', productId: null, open: false, printDialog: false, pro: null, anchorEl: null
    };
  }

  async componentDidMount() {
    if (this.props.additionReducer.printerHubConnection == null) {
      await this.props.setPrinterHubConnection().then(() => { });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  handleChangePage = (event, page) => {
    const { filterDataProduct } = this.props;
    let status = true;
    filterDataProduct.List.map((n, i) => {
      if (n.IsSelected) {
        status = false;
      }
    });
    if (!status) {
      this.props.setModalStock('Fiyatı değiştirilip kayıt edilmemiş ürün var!').then(() => { });
      return;
    }

    filterDataProduct.currentPage = page + 1;
    this.props.getProductList(filterDataProduct).then(() => { });
  };

  handleChangeCategory = (event) => {
    const { filterDataProduct } = this.props;
    let status = true;
    filterDataProduct.List.map((n, i) => {
      if (n.IsSelected) {
        status = false;
      }
    });
    if (!status) {
      this.props.setModalStock('Fiyatı değiştirilip kayıt edilmemiş ürün var!').then(() => { });
      return;
    }
    filterDataProduct.CategoryId = event.target.value;
    this.props.getProductList(filterDataProduct).then(() => { });
  };

  handleChangeRowsPerPage = event => {
    const { filterDataProduct } = this.props;
    let status = true;
    filterDataProduct.List.map((n, i) => {
      if (n.IsSelected) {
        status = false;
      }
    });
    if (!status) {
      this.props.setModalStock('Fiyatı değiştirilip kayıt edilmemiş ürün var!').then(() => { });
      return;
    }
    filterDataProduct.rowsPerPage = event.target.value;
    this.props.getProductList(filterDataProduct).then(() => { });
  };

  handleSearch = () => {
    const { filterDataProduct } = this.props;
    let status = true;
    filterDataProduct.List.map((n, i) => {
      if (n.IsSelected) {
        status = false;
      }
    });
    if (!status) {
      this.props.setModalStock('Fiyatı değiştirilip kayıt edilmemiş ürün var!').then(() => { });
      return;
    }
    filterDataProduct.searchText = this.state.searchValue;
    this.props.getProductList(filterDataProduct).then(() => { });
  };

  handleSort(sortColumnName) {
    const { filterDataProduct } = this.props;
    if (filterDataProduct.sortColumnName == sortColumnName) {
      if (filterDataProduct.sortOrder == 'asc') {
        filterDataProduct.sortOrder = 'desc';
      } else if (filterDataProduct.sortOrder == 'desc') {
        filterDataProduct.sortOrder = 'asc';
      }
    } else {
      filterDataProduct.sortOrder = 'asc';
    }
    filterDataProduct.sortColumnName = sortColumnName;
    this.props.getProductList(filterDataProduct).then(() => { });
  }

  handleCloseModal = () => {
    this.props.formDataSetStock('productDeleteModal', false).then(() => { });
  };

  handleConfirmation = () => {
    this.props.formDataSetStock('productDeleteModal', false).then(() => { });
    const Id = this.state.productId;
    this.props.setProductDelete(Id).then(() => { });
  };

  proDelete(Id) {
    this.props.formDataSetStock('productDeleteModal', true).then(() => { });
    this.setState({ productId: Id });
  }

  proUpdate(Id) {
    this.props.getProductUpdate(Id).then(() => { });
  }

  proCopy(Id) {
    this.props.getProductCopy(Id).then(() => { });
  }

  productIsListUpdate(Id, IsList, type) {
    const { filterDataProduct } = this.props;
    if (type == 'Product') {
      this.props.filterDataProduct.List.find(x => x.Id == Id).IsList = !IsList;
    }

    this.props.setProductListUpdate(filterDataProduct, Id, !IsList, type).then(() => { });
  }

  productIsQrMenuUpdate(Id, IsList) {
    const { filterDataProduct } = this.props;
    this.props.filterDataProduct.List.find(x => x.Id == Id).IsQrMenu = !IsList;
    this.props.setProductIsQrMenu(filterDataProduct, Id, !IsList).then(() => { });
  }

  productIsAppUpdate(Id, IsApp) {
    const { filterDataProduct } = this.props;
    this.props.filterDataProduct.List.find(x => x.Id == Id).IsApp = !IsApp;
    this.props.setProductIsApp(filterDataProduct, Id, !IsApp).then(() => { });
  }

  handleChange(Id, Price, IsPackage) {
    if (IsPackage) {
      this.props.filterDataProduct.List.find(x => x.Id == Id).PackagePrice = Price;
    } else {
      this.props.filterDataProduct.List.find(x => x.Id == Id).Price = Price;
    }
  }

  handleClose = () => {
    this.props.formDataSetStock('productAddModalTitle', '').then(() => { });
    this.props.formDataSetStock('productAddModal', false).then(() => { });
  };

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  handleTooltipOpen = () => {
    this.setState({ open: true });
  };

  handleClickOpen = () => {
    this.props.stockClear().then(() => {
      this.props.formDataSetStock('productAddModalTitle', 'Yeni Ürün Ekleme').then(() => { });
      this.props.formDataSetStock('activeStep', 0).then(() => { });
      this.props.formDataSetStock('productAddModal', true).then(() => { });
    });
  };

  handleFocus = (event) => event.target.select();

  clickPrinter() {
    this.setState({ printDialog: false, pro: null });
    this.props.sendMessagePrinter(3, this.state.pro).then(() => { });
  }

  renderPrint() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.printDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {'Uyarı'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'white' }} id="alert-dialog-description">
            {'Fiyat Etiketi yazdırılsın mı?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: 'capitalize', backgroundColor: '#e74c3c', color: 'white' }} onClick={() => this.setState({ printDialog: false, pro: null })} variant="outlined" color="primary" autoFocus>
            {'Hayır'}
          </Button>
          <Button style={{ textTransform: 'capitalize' }} color="secondary" variant="contained" onClick={() => this.clickPrinter()}>
            {'Evet'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  modalClose = () => {
    this.props.formDataSetStock('modalOpen', false).then(() => { });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleChangePrinter = Id => event => {
    const { filterDataProduct } = this.props;
    this.props.setProductPrinter(filterDataProduct, Id, event.target.value).then(() => { })
  };

  render() {
    const { classes, printerGroupList } = this.props;
    const { open, anchorEl } = this.state;

    var franchise = getParameter("Franchise");
    
    return (
      <Dialog
        fullScreen
        open={this.props.productListVisible}
        TransitionComponent={Transition}
      >
        {this.renderPrint()}
        <Category classes={classes} />
        <StockTaking classes={classes} />
        <PrinterGroup classes={classes} />
        <ProductQuantityList classes={classes} />

        <ProductRecipe classes={classes} />
        <ProductPropertyAddList classes={classes} />
        <ProductAllDiscount classes={classes} />
        <ProductAddAll classes={classes} />
        <ModalInformation event={this.modalClose} open={this.props.modalOpen} title={this.props.modalTitle} text={this.props.modalText} />
        <ProductAdd open={this.props.productAddModal} handleClose={this.handleClose} title={this.props.productAddModalTitle || ''} />
        {<AlertNotification eventConfirmation={this.handleConfirmation} eventClose={this.handleCloseModal} open={this.props.productDeleteModal} title="Uyarı" text="Ürünü silmek istediğinize emin misiniz?" />}
        <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%' }}>
          <Toolbar>
            <Button color="inherit" onClick={() => this.props.setProductListVisible(false).then(() => { })}>Geri</Button>
            <Typography variant="h6" color="inherit" className={classes.flex}>Ürün İşlemleri</Typography>
            <Button className={classes.centerBrandButton} style={{ marginRight: 5, display: (franchise.IsFranchise ? 'none' : '') }} color="default" variant="contained" onClick={() => this.props.setProductAddAllVisible(true).then(() => { })}>
              <Icon style={{ fontSize: 20, marginRight: 5 }}>{"widgets"}</Icon>
              {"ÇOKLU ÜRÜN EKLE"}
            </Button>
            <Button disabled={this.props.productListLoading} className={classes.centerBrandButton} style={{ marginRight: 5 }} color="default" variant="contained" onClick={() => this.props.setProductPriceUpdate(this.props.filterDataProduct).then(() => { })}>
              <Icon style={{ fontSize: 20, marginRight: 5 }}>{"price_check"}</Icon>
              {"FİYATLARI GÜNCELLE"}
            </Button>
            <Button style={{ marginRight: 5, display: (window.location.pathname == "/centerbrand" ? 'none' : '') }} color="default" variant="contained" onClick={() => this.props.setStockTakingVisible(true).then(() => { })}>
              <Icon style={{ fontSize: 20, marginRight: 5 }}>{"inventory"}</Icon>
              {"Stok Sayım"}
            </Button>
            <Button
              color="inherit"
              variant="outlined"
              aria-owns={anchorEl ? 'simple-menu' : null}
              aria-haspopup="true"
              onClick={this.handleClick}
              className={classes.centerBrandButton}
            >
              {'İŞLEMLER'}
              <Icon style={{ marginLeft: 10 }}>{"menu"}</Icon>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleCloseMenu}
            >
              <MenuItem style={{ display: (franchise.IsFranchise ? 'none' : '') }} onClick={() => this.handleClickOpen()}>
                <Icon style={{ fontSize: 20, marginRight: 10 }}>{"library_add"}</Icon>
                {"Yeni Ürün"}
              </MenuItem>
              <Divider />
              <MenuItem style={{ display: (franchise.IsFranchise ? 'none' : '') }} onClick={() => this.props.setCategoryListVisible(true).then(() => { })}>
                <Icon style={{ fontSize: 20, marginRight: 10 }}>{"category"}</Icon>
                {"Kategori İşlemleri"}
              </MenuItem>
              <Divider />
              <MenuItem style={{ display: (window.location.pathname == "/centerbrand" ? 'none' : '') }} onClick={() => this.props.setPrinterGroupListVisible(true).then(() => { })}>
                <Icon style={{ fontSize: 20, marginRight: 10 }}>{"print"}</Icon>
                {"Yazdırma Grupları"}
              </MenuItem>
              <Divider />
              <MenuItem style={{ display: (franchise.IsFranchise ? 'none' : '') }} onClick={() => this.props.setProductPropertyListVisible(true, '').then(() => { })}>
                <Icon style={{ fontSize: 20, marginRight: 10 }}>{"featured_play_list"}</Icon>
                {"Ürün Özellik / Menü Grupları"}
              </MenuItem>
              <Divider />
              <MenuItem style={{ display: (window.location.pathname == "/centerbrand" ? 'none' : '') }} onClick={() => this.props.setProductAllDiscountVisible(true).then(() => { })}>
                <Icon style={{ fontSize: 20, marginRight: 10 }}>{"sell"}</Icon>
                {"Toplu İndirim Uygula"}
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64, padding: 20 }}>
          <Toolbar style={{
            marginBottom: 30, minHeight: 48, width: '100%', display: 'flex', backgroundColor: '#303030', borderRadius: 5
          }}
          >
            <Grid style={{ alignItems: 'center' }} container spacing={1}>
              <Grid item md={2}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="age-helper">Kategori</InputLabel>
                  <Select
                    value={this.props.filterDataProduct.CategoryId}
                    onChange={this.handleChangeCategory}
                    input={<Input name="Category" id="age-helper" />}
                  >
                    <MenuItem value="00000000-0000-0000-0000-000000000000">
                      <em>Seçiniz</em>
                    </MenuItem>
                    {
                      this.props.filterDataProduct.Category.map(n => ([
                        <MenuItem key={n.CategoryId} value={n.CategoryId}>{n.CategoryName}</MenuItem>
                      ]))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={7} />
              <Grid item md={2}>
                <FormControl style={{ width: '100%', marginLeft: 8 }} className={classNames(classes.textField)}>
                  <Input
                    id="search_filter"
                    type="text"
                    placeholder="Arama"
                    value={this.state.searchValue}
                    onChange={(event) => this.setState({ searchValue: event.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item md={1}>
                <Button fullWidth onClick={this.handleSearch} style={{ borderRadius: 3, marginLeft: 5 }} variant="contained" color="primary">
                  {'Ara'}
                  {(this.props.productListLoading ? <CircularProgress style={{ color: 'white', marginLeft: 10 }} disableShrink thickness={3} size={15} /> : null)}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
          <Table>
            <TableHead style={{ backgroundColor: '#212121' }}>
              <TableRow>
                <TableCell
                  style={{
                    color: 'white', fontSize: 13, width: 30, padding: '4px 8px 4px 24px'
                  }}
                  align="left"
                />
                <TableCell
                  style={{
                    color: 'white', fontSize: 13, width: 30, padding: '4px 8px 4px 24px'
                  }}
                  align="left"
                >
                  {"Satışta"}
                </TableCell>
                {
                  (getLicenceModule('54ced956-358d-4a38-a8e7-f79ca670eb69') || window.location.pathname == "/centerbrand" ? <TableCell
                    style={{
                      color: 'white', fontSize: 13, width: 30, padding: '4px 8px 4px 24px'
                    }}
                    align="left"
                  >
                    {"QrMenü"}
                  </TableCell> : null)
                }
                {
                  (cookie.load('IsApp') === "true" ? <TableCell
                    style={{
                      color: 'white', fontSize: 13, width: 30, padding: '4px 8px 4px 24px'
                    }}
                    align="left"
                  >
                    {"App"}
                  </TableCell> : null)
                }
                <TableCell style={{ color: 'white', padding: '4px 8px 4px 24px' }} align="left">
                  <Button onClick={() => this.handleSort('Name')} style={{ textTransform: 'capitalize', fontSize: 13 }}>
                    {(this.props.filterDataProduct.sortColumnName == 'Name' ? (this.props.filterDataProduct.sortOrder == 'asc' ? <Icon style={{ marginRight: 5, fontWeight: 500 }}>arrow_upward</Icon> : <Icon style={{ marginRight: 5, fontWeight: 500 }}>arrow_downward</Icon>) : null)}
                    {'Adı'}
                  </Button>
                </TableCell>
                <TableCell style={{ color: 'white', fontSize: 13, padding: '4px 8px 4px 24px' }} align="left">
                  <Button onClick={() => this.handleSort('Category')} style={{ textTransform: 'capitalize', fontSize: 13 }}>
                    {(this.props.filterDataProduct.sortColumnName == 'Category' ? (this.props.filterDataProduct.sortOrder == 'asc' ? <Icon style={{ marginRight: 5, fontWeight: 500 }}>arrow_upward</Icon> : <Icon style={{ marginRight: 5, fontWeight: 500 }}>arrow_downward</Icon>) : null)}
                    {'Kategori Adı'}
                  </Button>
                </TableCell>
                <TableCell style={{ color: 'white', fontSize: 13, padding: '4px 8px 4px 24px', display: (franchise.IsFranchise ? (franchise.IsPriceChange ? '' : 'none') : '') }} align="left">Fiyatı</TableCell>
                <TableCell
                  style={{
                    color: 'white', fontSize: 13, padding: '4px 8px 4px 24px', display: ((getLicenceModulePackage() || window.location.pathname == "/centerbrand") ? (franchise.IsFranchise ? (franchise.IsPriceChange ? '' : 'none') : '') : 'none')
                  }}
                  align="left"
                >{"P. Fiyatı"}
                </TableCell>
                <TableCell
                  style={{
                    color: 'white', fontSize: 13, padding: '4px 8px 4px 24px', display: (franchise.IsFranchise ? '' : 'none')
                  }}
                  align="left"
                >{"Ürün Bazında Yazdırma"}
                </TableCell>
                <TableCell style={{ color: 'white', fontSize: 13, padding: '4px 8px 4px 24px' }} align="right">{"İşlem"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.props.filterDataProduct.List.map((n, i) => (
                  <TableRow hover key={i}>
                    <TableCell align="left" style={{ padding: '4px 10px 4px 15px', width: 50 }}>{<Icon style={{ fontWeight: 700, fontSize: 25, color: (n.Color) }}>lens</Icon>}</TableCell>
                    <TableCell align="left" style={{ padding: '4px 10px 4px 15px' }}>
                      {
                        <IconButton style={{ color: (n.IsList ? '#4CAF50' : '') }} onClick={() => this.productIsListUpdate(n.Id, n.IsList, 'Product')}>
                          <Icon>{(n.IsList ? 'check_box' : 'check_box_outline_blank')}</Icon>
                        </IconButton>
                      }
                    </TableCell>
                    {
                      (getLicenceModule('54ced956-358d-4a38-a8e7-f79ca670eb69') || window.location.pathname == "/centerbrand" ?
                        <TableCell align="left" style={{ padding: '4px 10px 4px 15px' }}>
                          {
                            <IconButton style={{ color: (n.IsQrMenu ? '#4CAF50' : '') }} onClick={() => this.productIsQrMenuUpdate(n.Id, n.IsQrMenu)}>
                              <Icon>{(n.IsQrMenu ? 'check_box' : 'check_box_outline_blank')}</Icon>
                            </IconButton>
                          }
                        </TableCell>
                        : null)
                    }
                    {
                      (cookie.load('IsApp') === "true" ?
                        <TableCell align="left" style={{ padding: '4px 10px 4px 15px' }}>
                          {
                            <IconButton style={{ color: (n.IsApp ? '#4CAF50' : '') }} onClick={() => this.productIsAppUpdate(n.Id, n.IsApp)}>
                              <Icon>{(n.IsApp ? 'check_box' : 'check_box_outline_blank')}</Icon>
                            </IconButton>
                          }
                        </TableCell> : null)
                    }
                    <TableCell align="left" style={{ padding: '4px 10px 4px 15px' }}>{n.Name}</TableCell>
                    <TableCell align="left" style={{ padding: '4px 10px 4px 15px' }}>{n.Category}</TableCell>
                    <TableCell align="left" style={{ padding: '4px 10px 4px 15px', width: '12%', display: (franchise.IsFranchise ? (franchise.IsPriceChange ? '' : 'none') : '') }}>
                      {this.renderUnit(n.Unit, n.Id)}
                    </TableCell>
                    <TableCell align="left" style={{ padding: '4px 10px 4px 15px', width: '12%', display: ((getLicenceModulePackage() || window.location.pathname == "/centerbrand") ? (franchise.IsFranchise ? (franchise.IsPriceChange ? '' : 'none') : '') : 'none') }}>
                      {this.renderUnitPackage(n.Unit, n.Id)}
                    </TableCell>
                    <TableCell align="left" style={{ padding: '4px 10px 4px 15px', width: '12%', display: (franchise.IsFranchise ? '' : 'none') }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper">{"Yazdırma Grubu"}</InputLabel>
                        <Select
                          value={n.PrinterGroup}
                          onChange={this.handleChangePrinter(n.Id)}
                          input={<Input name="PrinterGroup" id="age-helper" />}
                        >
                          <MenuItem value="00000000-0000-0000-0000-000000000000">
                            <em>{"Seçiniz"}</em>
                          </MenuItem>
                          {
                            printerGroupList.map(n => ([
                              <MenuItem key={n.Id} value={n.Id}>{n.GroupName}</MenuItem>
                            ]))
                          }
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right" style={{ padding: '4px 10px 4px 15px' }}>
                      <Tooltip title="Düzenle">
                        <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5, display: (franchise.IsFranchise ? 'none' : '') }} onClick={() => this.proUpdate(n.Id)}>
                          <Icon>{"edit"}</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Giriş Çıkış Listesi">
                        <IconButton style={{ display: (getIsLite() || window.location.pathname == "/centerbrand" ? 'none' : ''), backgroundColor: '#3e3e3e', marginRight: 5 }} onClick={() => this.props.setProductQuantityListVisible(true, n.Id).then(() => { })}>
                          <Icon>{"library_books"}</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Reçete İşlemleri">
                        <IconButton style={{ display: (getIsLite() || franchise.IsFranchise ? 'none' : ''), backgroundColor: '#3e3e3e', marginRight: 5 }} onClick={() => this.props.setProductRecipeListVisible(true, n.Id).then(() => { })}>
                          <Icon>{"account_tree"}</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ürünü Kopyala">
                        <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5, display: (franchise.IsFranchise ? 'none' : '') }} onClick={() => this.proCopy(n.Id)}>
                          <Icon>{"content_copy"}</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Sil">
                        <IconButton style={{ backgroundColor: '#3e3e3e', display: (franchise.IsFranchise ? 'none' : '') }} onClick={() => this.proDelete(n.Id)}>
                          <Icon>{"delete_outline"}</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
          {
            (this.props.filterDataProduct.List.length == 0 ? <EmptyData text="Ürün Bulunamadı" /> : null)
          }
          {(this.props.productListLoading ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
          <div style={{ width: '100%', display: 'table' }}>
            <TablePagination
              labelRowsPerPage="Sayfa başına satır sayısı"
              rowsPerPageOptions={[25, 50, 100]}
              rowsPerPage={this.props.filterDataProduct.rowsPerPage}
              count={this.props.filterDataProduct.totalRecord}
              page={this.props.filterDataProduct.currentPage - 1}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TbPaginationActions}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  renderUnit(Unit, Id) {
    const { classes } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <TextField
          value={Unit[0].Price}
          style={{ borderRadius: 8, border: (Unit.filter(x => x.IsSelected == true).length > 0 ? '1px solid #e74c3c' : '') }}
          onChange={this.handleChangeUnitListPrice(Id, Unit[0].Id)}
          fullWidth
          onFocus={this.handleFocus}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
        {(Unit.length > 1
          ? this.renderUnitPrice(Unit, Id)
          : null)}
      </div>
    );
  }

  renderUnitPackage(Unit, Id) {
    const { classes } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <TextField
          value={Unit[0].PackagePrice}
          id="formatted-numberformat-input"
          style={{ borderRadius: 8, border: (Unit.filter(x => x.IsSelected == true).length > 0 ? '1px solid #e74c3c' : '') }}
          onChange={this.handleChangeUnitListPackagePrice(Id, Unit[0].Id)}
          fullWidth
          onFocus={this.handleFocus}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
        {(Unit.length > 1
          ? this.renderUnitPackagePrice(Unit, Id)
          : null)}
      </div>
    );
  }

  handleChangeUnitListPrice = (Id, UnitId) => event => {
    const { filterDataProduct } = this.props;
    filterDataProduct.List.find(x => x.Id == Id).Unit.find(x => x.Id == UnitId).Price = parseFloat(event.target.value);
    filterDataProduct.List.find(x => x.Id == Id).Unit.find(x => x.Id == UnitId).IsSelected = true;
    filterDataProduct.List.find(x => x.Id == Id).IsSelected = true;
    this.props.productListUpdate().then(() => { });
  };

  handleChangeUnitListPackagePrice = (Id, UnitId) => event => {
    const { filterDataProduct } = this.props;
    filterDataProduct.List.find(x => x.Id == Id).Unit.find(x => x.Id == UnitId).PackagePrice = parseFloat(event.target.value);
    filterDataProduct.List.find(x => x.Id == Id).Unit.find(x => x.Id == UnitId).IsSelected = true;
    filterDataProduct.List.find(x => x.Id == Id).IsSelected = true;
    this.props.productListUpdate().then(() => { });
  };

  renderUnitPrice = (unit, Id) => (
    <PopupState variant="popover" popupId="demoMenu">
      {popupState => (
        <Fragment>
          <IconButton
            {...bindTrigger(popupState)}
            style={{
              borderRadius: 5, backgroundColor: '#009688', padding: '0px 10px 0px 10px', marginLeft: 10
            }}
          >
            <Icon>format_list_bulleted</Icon>
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <div style={{
              width: 350, margin: 10, border: '2px solid rgb(33, 33, 33)', padding: 10, borderRadius: 5
            }}
            >
              <div style={{ color: 'white' }}>Satış Fiyatı</div>
              <Table style={{ marginTop: 5 }}>
                <TableHead style={{ backgroundColor: '#212121' }}>
                  <TableRow>
                    <TableCell align="left">Birim Adı</TableCell>
                    <TableCell align="left">Fiyat</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    unit.map((n, i) => (
                      <TableRow hover key={i}>
                        <TableCell align="left" style={{ padding: '0px 15px 0px 24px', width: '10%' }}>{n.UnitName}</TableCell>
                        <TableCell align="left" style={{ padding: '0px 15px 0px 24px', width: '35%' }}>
                          <TextField
                            value={n.Price}
                            style={{ borderRadius: 8, border: (n.IsSelected == true ? '1px solid #e74c3c' : '') }}
                            onChange={this.handleChangeUnitListPrice(Id, n.Id)}
                            fullWidth
                            onFocus={this.handleFocus}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </div>
          </Popover>
        </Fragment>
      )}
    </PopupState>
  )

  renderUnitPackagePrice = (unit, Id) => (
    <PopupState variant="popover" popupId="demoMenu">
      {popupState => (
        <Fragment>
          <IconButton
            {...bindTrigger(popupState)}
            style={{
              borderRadius: 5, backgroundColor: '#009688', padding: '0px 10px 0px 10px', marginLeft: 10
            }}
          >
            <Icon>format_list_bulleted</Icon>
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <div style={{
              width: 350, margin: 10, border: '2px solid rgb(33, 33, 33)', padding: 10, borderRadius: 5
            }}
            >
              <div style={{ color: 'white' }}>Paket Satış Fiyatı</div>
              <Table style={{ marginTop: 5 }}>
                <TableHead style={{ backgroundColor: '#212121' }}>
                  <TableRow>
                    <TableCell align="left">Birim Adı</TableCell>
                    <TableCell align="left">Fiyat</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    unit.map((n, i) => (
                      <TableRow hover key={i}>
                        <TableCell align="left" style={{ padding: '0px 15px 0px 24px', width: '10%' }}>{n.UnitName}</TableCell>
                        <TableCell align="left" style={{ padding: '0px 15px 0px 24px', width: '35%' }}>
                          <TextField
                            value={n.PackagePrice}
                            style={{ borderRadius: 8, border: (n.IsSelected == true ? '1px solid #e74c3c' : '') }}
                            onChange={this.handleChangeUnitListPackagePrice(Id, n.Id)}
                            fullWidth
                            onFocus={this.handleFocus}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </div>
          </Popover>
        </Fragment>
      )}
    </PopupState>
  )
}

const valueSelector = (state, props) => state.get('stockReducer').productListUpdate;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  productListUpdate: value(state, props),
  filterDataProduct: state.get('stockReducer').filterDataProduct,
  productListLoading: state.get('stockReducer').productListLoading,
  productListVisible: state.get('stockReducer').productListVisible,
  productDeleteModal: state.get('stockReducer').form_data.productDeleteModal,
  productAddModal: state.get('stockReducer').form_data.productAddModal,
  productAddModalTitle: state.get('stockReducer').form_data.productAddModalTitle,
  additionReducer: state.get('additionReducer').form_data,

  modalOpen: state.get('stockReducer').form_data.modalOpen,
  modalTitle: state.get('stockReducer').form_data.modalTitle,
  modalText: state.get('stockReducer').form_data.modalText,

  entegrationLoadDataLoading: state.get('stockReducer').entegrationLoadDataLoading,
  printerGroupList: state.get('stockReducer').form_data.printerGroupList,
});

const mapDispatchToProps = {
  getProductList,
  setProductListVisible,
  formDataSetStock,
  setProductDelete,
  getProductUpdate,
  setCategoryListVisible,
  setPrinterGroupListVisible,
  setProductPriceUpdate,
  setModalStock,
  stockClear,
  setProductListUpdate,
  setProductIsQrMenu,
  productListUpdate,
  setPrinterHubConnection,
  sendMessagePrinter,
  setProductQuantityListVisible,
  setProductQuantityAddVisible,
  setProductMovementListVisible,
  getProductCopy,
  setProductRecipeListVisible,
  setProductEntegrationVisible,
  setProductPropertyListVisible,
  setProductAllDiscountVisible,
  setProductAddAllVisible,
  setStockTakingVisible,
  setProductIsApp,
  setProductPrinter
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductList));
