import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import "../datepicker.css";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from 'recharts';
import TooltipDetail from '@material-ui/core/Tooltip';


class CourierReport extends React.Component {
  constructor() {
    super();
  }

  renderCourierReportData() {
    const { data } = this.props;
    if (data.length == 0) {
      return []
    }
    var dataArray = [];
    data.Couriers.map((n, i) => {
      dataArray.push({ CourrierName: n.CourrierName, OrderCount: n.OrderCount, Total: n.Total.toFixed(2), amt: n.Total.toFixed(2) });
    })
    return dataArray;
  }

  renderColorfulLegendText(value, entry) {
    const { color } = entry;
    return <span style={{ color: 'white', fontSize: 12 }}>{value}</span>;
  }

  renderCourierReport() {
    const { handleChangeFilter, classes, data, GeneralTotalType, IsCourierOpenAddition } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Grid container spacing={1}>
            <Grid item md={2} style={{ maxWidth: '20%', flexBasis: '20%' }}>
              <CounterTrading
                color={"#f39c12"}
                unitBefore=""
                title="Bekleyen Paket Adet"
                icon={"show_chart"}
                position="down"
                start={0}
                end={data.length != 0 ? data.NumberPendingPackages : 0}
              >
              </CounterTrading>
            </Grid>
            <Grid item md={2} style={{ maxWidth: '20%', flexBasis: '20%' }}>
              <CounterTrading
                color={"#8e44ad"}
                unitBefore=""
                unitAfter="dk"
                title="Bekleyen Paket Zamanı"
                icon={"timelapse"}
                position="down"
                start={0}
                end={data.length != 0 ? data.PendingPacketTime : 0}
              >
              </CounterTrading>
            </Grid>
            <Grid item md={2} style={{ maxWidth: '20%', flexBasis: '20%' }}>
              <CounterTrading
                color={"#c0392b"}
                unitBefore=""
                title="Ort. Çıkış Süresi"
                unitAfter="dk"
                icon={"sports_motorsports"}
                position="down"
                start={0}
                end={data.length != 0 ? data.PacketOutputAverage : 0}
              >
              </CounterTrading>
            </Grid>
            <Grid item md={2} style={{ maxWidth: '20%', flexBasis: '20%' }}>
              <CounterTrading
                color={"#2980b9"}
                unitBefore=""
                title="Açık Paket Toplamı"
                icon={"money"}
                position="down"
                start={0}
                end={data.length != 0 ? data.OpenCourierTotal : 0}
              >
              </CounterTrading>
            </Grid>
            <Grid item md={2} style={{ maxWidth: '20%', flexBasis: '20%' }}>
              <CounterTrading
                color={"#27ae60"}
                unitBefore=""
                title="Kapalı Paket Toplamı"
                icon={"money"}
                position="down"
                start={0}
                end={data.length != 0 ? data.CloseCourierTotal : 0}
              >
              </CounterTrading>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <div className={classes.chartFluid} style={{ padding: 10, borderRadius: 5, marginTop: 30 }}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                height={400}
                data={this.renderCourierReportData()}
                margin={{
                  right: 10,
                  bottom: 100
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={"#c0392b"} stopOpacity={1} />
                    <stop offset="95%" stopColor={"#c0392b"} stopOpacity={1} />
                  </linearGradient>
                  <linearGradient id="colorYv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={"#16a085"} stopOpacity={1} />
                    <stop offset="95%" stopColor={"#16a085"} stopOpacity={1} />
                  </linearGradient>
                </defs>
                <XAxis interval={0} angle={-45} textAnchor="end" dataKey="CourrierName" tickLine={false} />
                <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
                <CartesianGrid vertical={false} strokeDasharray="3 3" />
                <CartesianAxis vertical={false} />
                <Tooltip />
                <Legend verticalAlign={"top"} formatter={this.renderColorfulLegendText} />
                <Bar name="Toplam" dataKey="Total" fillOpacity="1" fill="url(#colorUv)" />
                <Bar name="Adisyon Sayısı" dataKey="OrderCount" fillOpacity="1" fill="url(#colorYv)" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid container spacing={1}>
          {
            data.length != 0 ? data.Couriers.map((n, i) => {
              return (
                <Grid item md={2} style={{ maxWidth: '20%', flexBasis: '20%', position: 'relative' }}>
                  <CounterTrading
                    color={n.Color}
                    unitBefore=""
                    title={n.CourrierName}
                    icon={"show_chart"}
                    position="down"
                    start={0}
                    end={n.Total}
                  >
                  </CounterTrading>
                  {
                    <div style={{ display: 'flex', position: 'absolute', left: 5, bottom: 5, zIndex: 2 }}>
                      <TooltipDetail
                        PopperProps={{
                          disablePortal: true,
                        }}
                        placement={"top"}
                        disableFocusListener disableTouchListener
                        title={
                          <div style={{ padding: 5, width: 290, backgroundColor: '#292929', borderRadius: 5 }}>
                            <Grid container style={{ flexDirection: 'row', zIndex: 6 }}>
                              <Grid item md={6}>{"Ödeme Adı"}</Grid>
                              {!IsCourierOpenAddition ? <Grid item md={3} align={"right"}>{"İndirim"}</Grid> : null}
                              <Grid item md={(!IsCourierOpenAddition ? 3 : 6)} align={"right"}>{"Toplam"}</Grid>
                            </Grid>
                            {this.renderDetailDraw(n.PaymentTotal)}
                          </div>
                        }
                      >
                        <Button
                          onClick={this.handleTooltipOpen}
                          className={classes.button}
                          variant="contained"
                          style={{ borderRadius: 10, width: 75, backgroundColor: '#4b4b4b', color: 'white', marginRight: 5 }}
                        >
                          {"Detay"}
                        </Button>
                      </TooltipDetail>
                    </div>
                  }
                </Grid>
              )
            }) : null
          }
        </Grid>
      </Grid>
    )
  }

  renderDetailDraw(detail) {
    return detail.map((n, i) => {
      return (
        <Grid container style={{ flexDirection: 'row', zIndex: 6 }}>
          <Grid item md={6} style={{ textAlign: 'left', paddingBottom: 5 }}>
            <span style={{ marginLeft: 7, fontSize: 12, fontWeight: 500, color: 'white', marginRight: 5 }}>
              {n.PaymentName}
            </span>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'right' }}>
            <span style={{ marginLeft: 7, fontSize: 12, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>
              {n.Discount}
            </span>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'right' }}>
            <span style={{ marginLeft: 7, fontSize: 12, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>
              {n.Total}
            </span>
          </Grid>
        </Grid>
      )
    })
  }

  render() {
    const { StartDate, EndDate, handleChangeFilter, handleFilterCourierReport, classes, data, IsCourierOpenAddition, loading } = this.props;
    return (
      <Paper className={classes.root} elevation={0} style={{ borderRadius: 0, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 10 }}>
        <Grid container spacing={1}>
          <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
            <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Başlangıç Tarihi"}</Typography>
            <DatePicker
              selected={StartDate}
              onChange={handleChangeFilter("StartDate")}
              timeInputLabel="Saat:"
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeInput
              className={"inputDate"}
            />
          </Grid>
          <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
            <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Bitiş Tarihi"}</Typography>
            <DatePicker
              selected={EndDate}
              onChange={handleChangeFilter("EndDate")}
              timeInputLabel="Saat:"
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeInput
              className={"inputDate"}
            />
          </Grid>
          <Grid item md={2}>
            <FormControlLabel
              style={{ marginLeft: 10 }}
              control={(
                <Switch
                  checked={IsCourierOpenAddition}
                  onChange={handleChangeFilter("IsCourierOpenAddition")}
                />
              )}
              label={"Kapalı"}
            />
          </Grid>
          <Grid item md={3}>
            <Button disabled={loading} onClick={handleFilterCourierReport} style={{ borderRadius: 5, marginTop: 15, height: 37, padding: '6px 24px' }} size="large" variant="contained" color="secondary" type="submit">
              {"Filtrele"}
            </Button>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {this.renderCourierReport()}
      </Paper>
    )
  }
}

export default CourierReport;
