import axios from 'axios';
var base64 = require('base-64');
const uuidv4 = require('uuid/v4');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { reactLocalStorage } from 'reactjs-localstorage';

export const PLATE_ERROR = 'plate_error';
export const PLATE_GLOBAL_FORM_DATA_SET = 'plate_global_form_data_set';
export const PLATELIST_VISIBLE = 'platelist_visible';
export const PLATELIST_UPDATE = 'platelist_update';
export const PLATELIST_LOADING = 'platelist_loading';

export const SET_PLATETEXT = 'set_platetext';
export const PLATEADD_VISIBLE = 'plateadd_visible';
export const PLATEADDLIST_UPDATE = 'plateaddlist_update';

export const PLATEOPERATIONSADD_VISIBLE = 'plateoperationsadd_visible';
export const PLATEOPERATIONSDATA_UPDATE = 'plateoperationsdata_update';

let initialState = {
    form_data: {
        modalOpen: false,
        modalTitle: "",
        modalText: ""
    },
    plateListUpdate: false,
    plateListVisible: false,
    filterDataPlate: {
        sortColumnName: "CreationTime",
        sortOrder: "desc",
        totalRecord: 0,
        currentPage: 1,
        rowsPerPage: 50,
        BrandId: "",
        totalPage: 0,
        List: [],
        PlateOperationType: [-1],
        PlateId: "00000000-0000-0000-0000-000000000000"
    },
    plateListLoading: false,
    plateText: "",
    plateAddUpdate: false,
    plateAddVisible: false,
    plateList: [],
    plateOperationsAddUpdate: false,
    plateOperationsAddVisible: false,
    plateOperationsData: {
        Id: "00000000-0000-0000-0000-000000000000",
        PlateId: "00000000-0000-0000-0000-000000000000",
        Plate: "",
        UserId: "00000000-0000-0000-0000-000000000000",
        Username: "",
        Price: 0,
        Kilometer: 0,
        Description: "",
        PlateOperationType: "0",
        TenantId: "",
        BrandId: ""
    }
}

export async function setPlateUpdateData(data) {
    return async (dispatch,getState) => {
        dispatch({
            type: PLATEOPERATIONSDATA_UPDATE,
            payload: data
        });
    };
}

export async function setPlateOparationsDataClear() {
    return async (dispatch,getState) => {
        var data = {
            Id: "00000000-0000-0000-0000-000000000000",
            PlateId: "00000000-0000-0000-0000-000000000000",
            Plate: "",
            UserId: "00000000-0000-0000-0000-000000000000",
            Username: "",
            Price: 0,
            Kilometer: 0,
            Description: "",
            PlateOperationType: "0",
            TenantId: "",
            BrandId: ""
        }
        dispatch({
            type: PLATEOPERATIONSDATA_UPDATE,
            payload: data
        });
    };
}

export async function setPlateAddVisible(visible) {
    return async (dispatch,getState) => {
        if (visible) {
            await dispatch(getPlateAddList(false));
        }
        dispatch({
            type: PLATEADD_VISIBLE,
            payload: visible
        });
    };
}

export async function setPlateOperationsAddVisible(visible) {
    return async (dispatch,getState) => {
        if (visible) {
            await dispatch(getPlateAddList(false));
        }
        dispatch({
            type: PLATEOPERATIONSADD_VISIBLE,
            payload: visible
        });
    };
}

export async function setDelete(Id, filterDataPlate) {
    return async (dispatch,getState) => {

        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Courier/setDelete?Id=' + Id,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPlateList(filterDataPlate));
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setPlateOperationsUpdate(Id, filterDataPlate) {
    return async (dispatch,getState) => {
        var data = filterDataPlate.List.find(x => x.Id == Id);

        var plateOperation = {
            Id: data.Id,
            PlateId: data.PlateId,
            Plate: data.Plate,
            UserId: data.UserId,
            Username: data.Username,
            Price: data.Price,
            Kilometer: data.Kilometer,
            Description: data.Description,
            PlateOperationType: String(data.PlateOperationType),
            TenantId: data.TenantId,
            BrandId: data.BrandId
        }
        await dispatch({
            type: PLATEOPERATIONSDATA_UPDATE,
            payload: plateOperation
        });
        dispatch(setPlateOperationsAddVisible(true));
    };
}

export async function setPlateOperation(data, platelist, filterDataPlate) {
    return async (dispatch,getState) => {
        if (data.PlateId == "00000000-0000-0000-0000-000000000000") {
            dispatch(setPlateModal("Plaka bilgisi zorunludur!"));
            return;
        }
        if (data.Kilometer <= 0) {
            dispatch(setPlateModal("Kilometre bilgisi 0 veya daha küçük olamaz!"));
            return;
        } else if (data.Price <= 0) {
            dispatch(setPlateModal("Tutar bilgisi 0 veya daha küçük olamaz!"));
            return;
        }
        dispatch({ type: PLATELIST_LOADING });
        if (data.PlateOperationType == "0") {
            data.Description = "";
        }
        data.BrandId = cookie.load("BrandId");
        data.TenantId = cookie.load("TenantId");
        data.UserId = cookie.load("UserId");
        data.Username = cookie.load("NameSurname");
        data.Plate = platelist.find(x => x.Id == data.PlateId).Plate;

        await axios({
            method: 'POST',
            url: brand.urlSocket + 'api/Courier/setPlateOperation',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: data,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPlateList(filterDataPlate));
                    dispatch(setPlateOparationsDataClear());
                    dispatch(setPlateModal(response.data.responseData));
                } else {
                    dispatch(setPlateOparationsDataClear());
                    dispatch(setPlateModal(response.data.responseData));
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: PLATELIST_LOADING });
    };
}

export async function getPlateList(filterDataPlate) {
    return async (dispatch,getState) => {
        dispatch({ type: PLATELIST_LOADING });
        filterDataPlate.BrandId = cookie.load("BrandId");

        await axios({
            method: 'POST',
            url: brand.urlSocket + 'api/Courier/getPlateOperationsList',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: filterDataPlate,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: PLATELIST_UPDATE,
                        data: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: PLATELIST_LOADING });
    };
}

export async function setPlate(plateText) {
    return async (dispatch,getState) => {
        if(plateText.length == 0){
            dispatch(setPlateModal("Plaka boş geçilemez!"));
            return;
        }
        var data = {
            "Plate": plateText,
            "BrandId": cookie.load("BrandId"),
            "TenantId": cookie.load("TenantId"),
        };

        await axios({
            method: 'POST',
            url: brand.urlSocket + 'api/Courier/setPlate',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: data,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPlateAddList(false));
                    dispatch(setPlateText(""));
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function getPlateAddList(IsSet) {
    return async (dispatch,getState) => {
        var BrandId = cookie.load("BrandId");

        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Courier/getPlateList?BrandId=' + BrandId + '&IsSet=' + IsSet,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: PLATEADDLIST_UPDATE,
                        data: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setUserPlate(UserId,PlateId, Plate) {
    return async (dispatch,getState) => {
        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Courier/setUserPlate?UserId=' + UserId + '&PlateId='+ PlateId + '&Plate=' + Plate,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                console.log(response.data.responseData)
                if (response.data.isStatus) {
                    dispatch(getPlateAddList(false));
                }else{
                    dispatch(setPlateModal(response.data.responseData));
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setPlateDelete(Id) {
    return async (dispatch,getState) => {
        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Courier/setPlateDelete?Id=' + Id,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPlateAddList(false));
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setPlateListVisible(visible) {
    return async (dispatch,getState) => {
        if (visible) {
            dispatch(getPlateAddList(false));
        }
        dispatch({
            type: PLATELIST_VISIBLE,
            open: visible
        });
    };
}


export async function setPlateAdd() {
    return async (dispatch,getState) => {
        dispatch({
            type: PLATEADD_UPDATE
        });
    };
}

export async function setPlateClear() {
    return async (dispatch,getState) => {
        dispatch({
            type: PLATEADD_CLEAR
        });
    };
}

export async function formDataSetPlate(key, val) {
    return async (dispatch,getState) => {
        dispatch({
            type: PLATE_GLOBAL_FORM_DATA_SET,
            payload: { key, val }
        });
    };
}

export async function setPlateModal(text) {
    return async (dispatch,getState) => {
        dispatch({
            type: PLATE_ERROR,
            payload: text
        });
    };
}


export async function setPlateText(text) {
    return async (dispatch,getState) => {
        dispatch({
            type: SET_PLATETEXT,
            payload: text
        });
    };
}

const plateReducer = (state = initialState, action) => {
    switch (action.type) {
        case PLATELIST_LOADING:
            return { ...state, plateListLoading: !state.plateListLoading }
        case PLATEADD_VISIBLE:
            return { ...state, plateAddVisible: action.payload, plateAddUpdate: !state.plateAddUpdate }
        case PLATEOPERATIONSDATA_UPDATE:
            return { ...state, plateOperationsData: action.payload, plateOperationsAddUpdate: !state.plateOperationsAddUpdate }
        case PLATEOPERATIONSADD_VISIBLE:
            return { ...state, plateOperationsAddVisible: action.payload, plateOperationsAddUpdate: !state.plateOperationsAddUpdate }
        case PLATEADDLIST_UPDATE:
            return { ...state, plateList: action.data, plateAddUpdate: !state.plateAddUpdate }
        case SET_PLATETEXT:
            return { ...state, plateText: action.payload, plateAddUpdate: !state.plateAddUpdate }
        case PLATELIST_VISIBLE:
            return { ...state, plateListVisible: action.open }
        case PLATELIST_UPDATE:
            return { ...state, filterDataPlate: action.data, plateListUpdate: !state.plateListUpdate }
        case PLATE_ERROR:
            return {
                ...state, form_data: {
                    ...state.form_data,
                    modalOpen: true,
                    modalText: action.payload,
                    modalTitle: "Uyarı"
                }
            }
        case PLATE_GLOBAL_FORM_DATA_SET:
            return {
                ...state,
                form_data: {
                    ...state.form_data,
                    [action.payload.key]: action.payload.val
                }
            }
        default:
            return state;
    }
}

export default plateReducer;
