import React from 'react';
import {
  createSelector
} from 'reselect';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import BarcodeReader from 'react-barcode-reader';
import NumPad from 'react-numpad';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import DatePicker, { registerLocale } from 'react-datepicker';
import './datepicker.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import {
  getPackageAdditionDetail
} from '../../../redux/modules/addition';
import {
  setPaketYoldaReportVisible, setPaketYoldaReportFilterData, getPaketYoldaBrandList, getPaketYoldaCourierList,
  getOrderReport, setPaketYoldaData, setOrderCompleted
} from '../../../redux/modules/paketyolda';
import styles from './track-style-jss';

class PaketYoldaReport extends React.Component {
  constructor(props) {
    super(props);
  }

  compareAdditionReport(a, b) {
    const genreA = a.AdditionCode;
    const genreB = b.AdditionCode;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  comparePaketYoldaReport(a, b) {
    const genreA = a.AdditionId;
    const genreB = b.AdditionId;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  async componentDidMount() {
    await this.props.getPaketYoldaBrandList(cookie.load('PaketYoldaId')).then(() => { });
    await this.props.getPaketYoldaCourierList(cookie.load('PaketYoldaId')).then(() => { });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.paketYoldaReportUpdate == this.props.paketYoldaReportUpdate) {
      return false;
    }
    return true;
  }

  handleChangeFilterReport = name => event => {
    const { paketYoldaFilter } = this.props;
    if (name == 'IsList') {
      this.props.setPaketYoldaData([]).then(() => { });
    }
    paketYoldaFilter[name] = ((name == 'StartDatetime' || name == 'EndDatetime') ? event : (name == 'IsList' || name == 'IsCompare' ? event.target.checked : event.target.value));
    this.props.setPaketYoldaReportFilterData(paketYoldaFilter).then(() => { });
    if (paketYoldaFilter.ReportType == 'delivered' && name == 'CourierId') {
      this.props.getOrderReport().then(() => { });
    }
  };

  renderPackageColor(n) {
    let backColor = '#2980b9';
    if (n.TableName == 'YemekSepeti' || n.TableName == 'YemekSepetiGlobal') {
      backColor = '#fa0050';
    } else if (n.TableName == 'GetirYemek') {
      backColor = '#8e44ad';
    } else if (n.TableName == 'TrendyolYemek') {
      backColor = '#d35400';
    } else if (n.TableName == 'GoFody') {
      backColor = '#f1b62a';
    } else if (n.TableName == 'MigrosYemek') {
      backColor = '#27ae60';
    }
    return backColor;
  }

  handleClickPopover = event => {
    this.props.getPackageAdditionDetail(event.currentTarget.id, event.currentTarget);
  };

  renderPayment() {
    const { paketYoldaReportDataPayment } = this.props;

    {
      return paketYoldaReportDataPayment.map((n, i) => (
        <Grid container style={{ flexDirection: 'row', zIndex: 6 }}>
          <Grid item md={6} style={{ textAlign: 'left', paddingBottom: 5 }}>
            <span style={{
              marginLeft: 7, fontSize: 13, fontWeight: 500, color: 'white', marginRight: 5
            }}
            >
              {n.PaymentName}
            </span>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'center' }}>
            <span style={{
              marginLeft: 7, fontSize: 13, fontWeight: 500, color: 'white', textTransform: 'capitalize'
            }}
            >
              {n.TotalCount.toFixed(0)}
            </span>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'right' }}>
            <span style={{
              marginRight: 7, fontSize: 13, fontWeight: 500, color: 'white', textTransform: 'capitalize'
            }}
            >
              {n.TotalPrice.toFixed(2)}
            </span>
          </Grid>
        </Grid>
      ));
    }
  }

  render() {
    const {
      paketYoldaAdditionReportData, paketYoldaCourierListData, paketYoldaBrandList, classes, paketYoldaFilter, paketYoldaReportLoading, paketYoldaReportVisible, paketYoldaReportData, paketYoldaReportDataPayment
    } = this.props;

    return (
      <Dialog
        open={paketYoldaReportVisible}
        aria-labelledby="simple-dialog-title"
        maxWidth="lg"
        fullWidth
        PaperProps={{ classes: { root: classes.dialogPaperPaketYoldaReport } }}
      >
        <DialogTitle style={{ marginBottom: 5 }} id="simple-dialog-title">
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Icon style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, backgroundColor: '#3498db', borderRadius: 5, padding: 3
            }}
            >
              {"two_wheeler"}
            </Icon>
            <span style={{ marginLeft: 5, fontSize: 15 }}>{(paketYoldaFilter.ReportType == 'report' ? 'Restoran / Kurye Hesap İşlemleri' : (paketYoldaFilter.ReportType == 'delivered' ? 'Teslim Edilen Paketler Listesi' : 'Tamamlanan Paketler Listesi'))}</span>
          </div>
        </DialogTitle>
        <DialogContent style={{ height: '100vh', padding: '0 20px 0px' }}>
          <Grid
            container
            spacing={1}
            style={{
              borderBottom: '1px solid #424242', padding: 5, borderRadius: 0, marginBottom: 5
            }}
          >
            <Grid style={{ display: (paketYoldaFilter.ReportType != 'delivered' ? '' : 'none') }} item md={2}>
              {(paketYoldaFilter.ReportType != 'delivered' ? (
                <div>
                  <Typography
                    style={{
                      marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                    }}
                    className={classes.flex}
                  >
                    {"Başlangıç Tarihi"}
                  </Typography>
                  <DatePicker
                    selected={paketYoldaFilter.StartDatetime}
                    onChange={this.handleChangeFilterReport('StartDatetime')}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeIntervals={15}
                    timeFormat="HH:mm"
                    locale="tr"
                    className="inputDate"
                  />
                </div>
              ) : null)}
            </Grid>
            <Grid style={{ display: (paketYoldaFilter.ReportType != 'delivered' ? '' : 'none') }} item md={2}>
              {(paketYoldaFilter.ReportType != 'delivered' ? (
                <div>
                  <Typography
                    style={{
                      marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                    }}
                    className={classes.flex}
                  >
                    {"Bitiş Tarihi"}
                  </Typography>
                  <DatePicker
                    selected={paketYoldaFilter.EndDatetime}
                    onChange={this.handleChangeFilterReport('EndDatetime')}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeIntervals={15}
                    timeFormat="HH:mm"
                    locale="tr"
                    className="inputDate"
                  />
                </div>
              ) : null)}
            </Grid>
            <Grid item md={(paketYoldaFilter.ReportType != 'delivered' ? 2 : 4)}>
              <FormControl fullWidth className={classes.formControl} style={{ margin: 0 }}>
                <Typography
                  style={{
                    marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                  }}
                  className={classes.flex}
                >
                  Restoran Seçimi
                </Typography>
                <Select
                  fullWidth
                  style={{ width: '100%', borderRadius: 5, height: 38 }}
                  value={paketYoldaFilter.BrandId}
                  onChange={this.handleChangeFilterReport('BrandId')}
                  inputProps={{
                    name: 'age',
                    id: 'age-simple',
                  }}
                >
                  <MenuItem value="00000000-0000-0000-0000-000000000000">Boş</MenuItem>
                  {
                    paketYoldaBrandList.map((n, i) => <MenuItem value={n.Id}>{n.Name}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={(paketYoldaFilter.ReportType != 'delivered' ? 2 : 4)}>
              <FormControl fullWidth className={classes.formControl} style={{ margin: 0 }}>
                <Typography
                  style={{
                    marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
                  }}
                  className={classes.flex}
                >
                  Kurye Seçimi
                </Typography>
                <Select
                  fullWidth
                  style={{ width: '100%', borderRadius: 5, height: 38 }}
                  value={paketYoldaFilter.CourierId}
                  onChange={this.handleChangeFilterReport('CourierId')}
                  inputProps={{
                    name: 'age',
                    id: 'age-simple',
                  }}
                >
                  <MenuItem value="00000000-0000-0000-0000-000000000000">Boş</MenuItem>
                  {
                    paketYoldaCourierListData.map((n, i) => <MenuItem value={n.Id}>{n.NameSurname}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} style={{ marginTop: 17, display: 'flex', alignItems: 'center' }} />
            <Grid item md={2}>
              <Button
                fullWidth
                disabled={paketYoldaReportLoading}
                onClick={() => this.props.getOrderReport().then(() => { })}
                style={{
                  borderRadius: 5, height: 37, padding: '6px 24px', marginTop: 17
                }}
                size="large"
                variant="contained"
                color="secondary"
                type="submit"
              >
                {'FİLTRELE'}
              </Button>
            </Grid>
          </Grid>
          {(paketYoldaFilter.ReportType == 'report' ? (
            <div style={{ display: 'block', margin: 5 }}>
              <div style={{ display: 'block', width: '100%' }}>
                <div style={{ marginTop: 2, padding: 5, display: 'flex' }}>
                  <span style={{
                    textAlign: 'left', width: '100%', alignItems: 'center', display: 'flex'
                  }}
                  >
                    {(paketYoldaFilter.CourierId != '00000000-0000-0000-0000-000000000000' ? 'Kurye' : 'İşletme')}
                    {' '}
                    {'Adı'}
                  </span>
                  <span style={{ textAlign: 'center', width: '100%' }}>Toplam Adet</span>
                  <span style={{ textAlign: 'right', width: '100%' }}>Toplam Tutar</span>
                </div>
              </div>
              {
                paketYoldaReportData.map((n, i) => (
                  <div style={{
                    padding: 4, display: 'block', width: '100%', border: '1px solid rgba(66,66,66,.5)', borderRadius: 5
                  }}
                  >
                    <div style={{ display: 'block', width: '100%' }}>
                      <div style={{ marginTop: 2, padding: 5, display: 'flex' }}>
                        <span style={{
                          textAlign: 'left', width: '100%', alignItems: 'center', display: 'flex'
                        }}
                        >
                          <Icon style={{ marginRight: 10 }}>restaurant</Icon>
                          {n.Name}
                        </span>
                        <span style={{ textAlign: 'center', width: '100%' }}>{n.TotalCount}</span>
                        <span style={{ textAlign: 'right', width: '100%' }}>{n.TotalPrice}</span>
                      </div>
                    </div>
                    {
                      n.PaymentData.map((t, y) => (
                        <div style={{ display: 'block', width: '100%' }}>
                          <div style={{
                            marginTop: 2, borderRadius: 5, padding: 5, backgroundColor: 'rgba(66,66,66,.6)', display: 'flex'
                          }}
                          >
                            <span style={{ textAlign: 'left', width: '100%' }}>{t.PaymentName}</span>
                            <span style={{ textAlign: 'center', width: '100%' }}>{t.TotalCount}</span>
                            <span style={{ textAlign: 'right', width: '100%' }}>{t.TotalPrice}</span>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          ) : null)}
          {(paketYoldaFilter.ReportType == 'completed' ? (
            <Table style={{ marginTop: 5, marginRight: 5 }}>
              <TableHead style={{ backgroundColor: '#212121' }}>
                <TableRow>
                  <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Tarih</TableCell>
                  <TableCell style={{ color: 'white', fontSize: 13 }} align="left">No</TableCell>
                  <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Rest. Adı</TableCell>
                  <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Müş. Adı</TableCell>
                  <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Tutar</TableCell>
                  <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Kurye</TableCell>
                  <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Durumu</TableCell>
                  <TableCell style={{ color: 'white', fontSize: 13 }} align="right">İşlem</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  paketYoldaReportData.sort(this.comparePaketYoldaReport).map((n, i) => (
                    <TableRow hover key={i}>
                      <TableCell align="left" style={{ padding: 5 }}>{moment(n.CreationTime).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
                      <TableCell align="left" style={{ padding: 5 }}>{n.AdditionId}</TableCell>
                      <TableCell align="left" style={{ padding: 5 }}>{n.RestName}</TableCell>
                      <TableCell align="left" style={{ padding: 5 }}>{n.CustomerName}</TableCell>
                      <TableCell align="left" style={{ padding: 5 }}>{n.TotalPrice.toFixed(2)}</TableCell>
                      <TableCell align="left" style={{ padding: 5 }}>{n.CourierName}</TableCell>
                      <TableCell align="left" style={{ padding: 5 }}>{(n.OrderStatus == 0 ? 'Beklemede' : (n.OrderStatus == 1 ? 'Kurye Atandı' : (n.OrderStatus == 2 ? 'Teslim Edildi' : (n.OrderStatus == 3 ? 'İptal Edildi' : (n.OrderStatus == 4 ? 'Kurye Kabul Edildi' : (n.OrderStatus == 5 ? 'Tamamlandı' : ''))))))}</TableCell>
                      <TableCell align="right" style={{ padding: 5 }}>
                        <Tooltip title="Adisyon Detayı Görüntüle">
                          <IconButton
                            style={{ padding: 4, backgroundColor: '#3e3e3e', marginRight: 5 }}
                            aria-label="Adisyon Detayı Görüntüle"
                            id={n.OrderId}
                            onClick={this.handleClickPopover}
                          >
                            <Icon>receipt_long</Icon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          ) : null)}
          {(paketYoldaFilter.ReportType == 'delivered' ? (
            <div style={{
              position: 'relative', width: '100%', marginBottom: 5, display: 'flex'
            }}
            >
              <div style={{ position: 'relative', width: '100%', marginBottom: 5 }}>
                {
                  paketYoldaReportData.map((n, i) => (
                    <div style={{
                      position: 'relative', width: '100%', backgroundColor: '#424242', height: 90, marginBottom: 5
                    }}
                    >
                      <div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: 0, bottom: 0, backgroundColor: this.renderPackageColor(n.EntegrationType), width: 150
                      }}
                      >
                        <span style={{
                          textAlign: 'center', display: 'grid', lineHeight: 1, width: '100%', fontSize: 50, color: 'white', fontWeight: 600
                        }}
                        >
                          <span style={{ color: 'white', fontSize: 12 }}>{(n.EntegrationType == 0 ? 'GetirYemek' : (n.EntegrationType == 1 ? 'YemekSepeti' : (n.EntegrationType == 2 ? 'TrendyolYemek' : (n.EntegrationType == 3 ? 'GoFody' : (n.EntegrationType == 4 ? 'MigrosYemek' : 'JaviPos')))))}</span>
                          <span style={{
                            width: '100%', fontSize: 16, color: 'white', fontWeight: 500
                          }}
                          >
                            {'#'}
                            {n.AdditionId}
                          </span>
                        </span>
                      </div>
                      <div style={{
                        display: 'flex', alignItems: 'center', color: 'white', fontSize: 15, position: 'absolute', left: 160, top: 5
                      }}
                      >
                        <Tooltip title="PAKET SERVİS">
                          <Icon style={{ marginRight: 6, color: 'white', fontSize: 20 }}>moped</Icon>
                        </Tooltip>
                        {n.CustomerName}
                      </div>
                      <div style={{
                        display: 'flex', alignItems: 'center', color: 'white', fontSize: 15, position: 'absolute', right: 60, top: 5
                      }}
                      >
                        {n.CourierName}
                      </div>
                      <div style={{
                        overflow: 'hidden', WebkitBoxOrient: 'vertical', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 4, right: 50, alignItems: 'center', color: 'white', fontSize: 10, position: 'absolute', left: 160, top: 30
                      }}
                      >
                        <Icon style={{ marginRight: 12, color: 'white', fontSize: 15 }}>location_on</Icon>
                        {n.AdresssText}
                        {' '}
                        {n.AdresssDesc}
                        {' '}
                        {n.PaymentName}
                      </div>
                      <div style={{
                        display: 'flex', fontSize: 18, position: 'absolute', right: 0, bottom: 0, left: 150, height: 25
                      }}
                      >
                        <div style={{
                          fontWeight: 600, fontSize: 15, width: '50%', justifyContent: 'center', alignItems: 'center', display: 'flex'
                        }}
                        >
                          {(
                            n.PackageStatus == '0' ? (
                              <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#7f8c8d', color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: 12, width: '100%'
                              }}
                              >
                                Beklemede
                              </div>
                            )
                              : n.PackageStatus == '1' ? (
                                <div style={{
                                  display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#27ae60', color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: 12, width: '100%'
                                }}
                                >
                                  Onaylandı
                                </div>
                              )
                                : n.PackageStatus == '2' ? (
                                  <div style={{
                                    display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#8e44ad', color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: 12, width: '100%'
                                  }}
                                  >
                                    Hazırlandı
                                  </div>
                                )
                                  : n.PackageStatus == '3' ? (
                                    <div style={{
                                      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: (IsEntegrationDeliveryStatus == false ? '#FFB300' : '#8e44ad'), color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: 12, width: '100%'
                                    }}
                                    >
                                      Yola Çıktı
                                    </div>
                                  )
                                    : n.PackageStatus == '4' ? (
                                      <div style={{
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#3498db', color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: 12, width: '100%'
                                      }}
                                      >
                                        Teslim Edildi
                                      </div>
                                    )
                                      : n.PackageStatus == '5' ? (
                                        <div style={{
                                          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#c0392b', color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: 12, width: '100%'
                                        }}
                                        >
                                          İptal Edildi
                                        </div>
                                      ) : '')}
                        </div>
                        <div style={{
                          zIndex: 1, fontWeight: 600, fontSize: 12, width: '50%', justifyContent: 'center', backgroundColor: 'white', color: '#313131', alignItems: 'center', display: 'flex'
                        }}
                        >
                          {n.TotalPrice.toFixed(2)}
                        </div>
                      </div>
                      <div style={{
                        backgroundColor: 'rgb(66, 66, 66)', flexDirection: 'column', display: 'flex', fontSize: 18, position: 'absolute', right: 0, bottom: 0, top: 0, width: 50, padding: 1
                      }}
                      >
                        <Button
                          id={n.OrderId}
                          onClick={this.handleClickPopover}
                          variant="outlined"
                          style={{
                            minWidth: '100%', height: '33.33%', backgroundColor: 'rgb(41, 41, 41)', borderRadius: 1, borderWidth: 0, marginBottom: 1
                          }}
                        >
                          <Icon style={{ fontSize: 20 }}>description</Icon>
                        </Button>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div style={{
                marginLeft: 5, position: 'relative', width: '40%', backgroundColor: '#424242', height: '100%', marginBottom: 5
              }}
              >
                <Grid container style={{ flexDirection: 'row', zIndex: 6 }}>
                  <Grid item md={6} style={{ textAlign: 'left', paddingBottom: 5 }}>
                    <span style={{
                      marginLeft: 7, fontSize: 13, fontWeight: 500, color: 'white', marginRight: 5
                    }}
                    >
                      {''}
                    </span>
                  </Grid>
                  <Grid item md={3} style={{ textAlign: 'center' }}>
                    <span style={{
                      marginLeft: 7, fontSize: 13, fontWeight: 500, color: 'white', textTransform: 'capitalize'
                    }}
                    >
                      {'Adet'}
                    </span>
                  </Grid>
                  <Grid item md={3} style={{ textAlign: 'right' }}>
                    <span style={{
                      marginRight: 7, fontSize: 13, fontWeight: 500, color: 'white', textTransform: 'capitalize'
                    }}
                    >
                      {'Tutar'}
                    </span>
                  </Grid>
                </Grid>
                {this.renderPayment()}
              </div>
            </div>
          ) : null)}
        </DialogContent>
        <DialogActions>
          {
            (paketYoldaFilter.ReportType == 'delivered' ? (
              <Tooltip title="Paket kapatma işlemi için kurye seçimi yapmanız gerekmektedir.">
                <Button disabled={(!!(paketYoldaFilter.CourierId == '00000000-0000-0000-0000-000000000000' || paketYoldaReportData.length == 0))} style={{ backgroundColor: '#2980b9', color: 'white', marginRight: 5 }} onClick={() => this.props.setOrderCompleted(paketYoldaFilter.CourierId).then(() => { })} color="primary" autoFocus>
                  {'KURYENİN TESLİM EDİLEN PAKETLERİNİ KAPAT'}
                </Button>
              </Tooltip>
            ) : null)
          }
          <Button style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }} onClick={() => this.props.setPaketYoldaReportVisible(false, paketYoldaFilter.ReportType).then(() => { })} color="primary" autoFocus>
            {'ÇIKIŞ'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaReportUpdate;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  paketYoldaReportUpdate: value(state, props),
  paketYoldaReportVisible: state.get('paketYoldaReducer').paketYoldaReportVisible,
  paketYoldaReportLoading: state.get('paketYoldaReducer').paketYoldaReportLoading,
  paketYoldaCourierList: state.get('paketYoldaReducer').paketYoldaCourierList,
  paketYoldaFilter: state.get('paketYoldaReducer').paketYoldaFilter,
  paketYoldaBrandList: state.get('paketYoldaReducer').paketYoldaBrandList,
  paketYoldaCourierListData: state.get('paketYoldaReducer').paketYoldaCourierListData,

  paketYoldaReportData: state.get('paketYoldaReducer').paketYoldaReportData,
  paketYoldaReportDataPayment: state.get('paketYoldaReducer').paketYoldaReportDataPayment,
  paketYoldaAdditionReportData: state.get('paketYoldaReducer').paketYoldaAdditionReportData
});

const mapDispatchToProps = {
  setPaketYoldaReportVisible,
  setPaketYoldaReportFilterData,
  getPaketYoldaBrandList,
  getPaketYoldaCourierList,
  getOrderReport,
  setPaketYoldaData,
  getPackageAdditionDetail,
  setOrderCompleted
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(PaketYoldaReport)));
