import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DatePicker, { registerLocale } from "react-datepicker";
import "../datepicker.css";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  BarChart,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  PieChart,
  Pie,
  Sector,
  LabelList,
  Line
} from 'recharts';
import CircularProgress from '@material-ui/core/CircularProgress';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);

class GeneralTotal extends React.Component {
  constructor() {
    super();
  }

  renderColorfulLegendText(value, entry) {
    const { color } = entry;

    return <span style={{ color: 'white', fontSize: 12 }}>{value}</span>;
  }

  renderDataHour() {
    const { dataHour } = this.props;
    if (dataHour.length == 0) {
      return []
    }

    var dataArray = [];
    dataHour.map((n, i) => {
      dataArray.push({ Hour: n.Hour, NormalTotal: n.NormalTotal, PackageTotal: n.PackageTotal, amt: (n.NormalTotal + n.PackageTotal).toFixed(2) });
    })
    return dataArray;
  }

  renderDataMountly() {
    const { dataMountly } = this.props;
    if (dataMountly.length == 0) {
      return []
    }

    var dataArray = [];
    dataMountly.map((n, i) => {
      dataArray.push({ Day: n.Day, NormalTotal: n.NormalTotal, PackageTotal: n.PackageTotal, amt: (n.NormalTotal + n.PackageTotal).toFixed(2) });
    })
    return dataArray;
  }

  renderHourData() {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          height={400}
          data={this.renderDataHour()}
          margin={{
            right: 10,
            bottom: 100
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={"#FFC107"} stopOpacity={1} />
              <stop offset="95%" stopColor={"#FFC107"} stopOpacity={1} />
            </linearGradient>
            <linearGradient id="colorUp" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={"#009688"} stopOpacity={1} />
              <stop offset="95%" stopColor={"#009688"} stopOpacity={1} />
            </linearGradient>
          </defs>
          <XAxis interval={0} angle={-45} textAnchor="end" dataKey="Hour" tickLine={false} label={{ size: 12 }} />
          <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <CartesianAxis vertical={false} />
          <Tooltip />
          <Legend verticalAlign={"top"} formatter={this.renderColorfulLegendText} />
          <Bar stackId="a" name="İç Satış" dataKey="NormalTotal" fillOpacity="1" fill="url(#colorUv)" />
          <Bar stackId="a" name="Paket" dataKey="PackageTotal" fillOpacity="1" fill="url(#colorUp)" />
          <Line type="monotone" name="S Toplam" dataKey="amt" strokeWidth={3} stroke="#E91E63" />
        </ComposedChart>
      </ResponsiveContainer>
    )
  }

  renderMountlyData() {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          height={400}
          data={this.renderDataMountly()}
          margin={{
            right: 10,
            bottom: 100
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={"#FFC107"} stopOpacity={1} />
              <stop offset="95%" stopColor={"#FFC107"} stopOpacity={1} />
            </linearGradient>
            <linearGradient id="colorUp" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={"#009688"} stopOpacity={1} />
              <stop offset="95%" stopColor={"#009688"} stopOpacity={1} />
            </linearGradient>
          </defs>
          <XAxis interval={0} angle={-45} textAnchor="end" dataKey="Day" tickLine={false} label={{ size: 12 }} />
          <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <CartesianAxis vertical={false} />
          <Tooltip />
          <Legend verticalAlign={"top"} formatter={this.renderColorfulLegendText} />
          <Bar stackId="a" name="İç Satış" dataKey="NormalTotal" fillOpacity="1" fill="url(#colorUv)" />
          <Bar stackId="a" name="Paket" dataKey="PackageTotal" fillOpacity="1" fill="url(#colorUp)" />
          <Line type="monotone" name="G Toplam" dataKey="amt" strokeWidth={3} stroke="#E91E63" />
        </ComposedChart >
      </ResponsiveContainer >
    )
  }

  render() {
    const { Date, Mountly, handleChangeFilterHour, handleChangeFilterMountly, handleFilterReportHour, handleFilterReportMountly, classes, loading } = this.props;
    return (
      <Paper className={classes.root} elevation={0} style={{ borderRadius: 0, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 10 }}>
        <div style={{ marginBottom: 10, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: 'white' }}>{"Saat Bazında Satış Raporu"}</div>
        <Grid container spacing={1}>
          <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
            <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Gün Seçimi"}</Typography>
            <DatePicker
              selected={Date}
              onChange={handleChangeFilterHour("Date")}
              dateFormat="dd.MM.yyyy"
              withPortal
              locale="tr"
              className={"inputDate"}
            />
          </Grid>
          <Grid item md={3}>
            <Button disabled={loading} onClick={handleFilterReportHour} style={{ borderRadius: 5, marginTop: 15, height: 37, padding: '6px 24px' }} size="large" variant="contained" color="secondary" type="submit">
              {"FİLTRELE"}
            </Button>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {(loading ? <div style={{ width: '100%', textAlign: 'center', marginBottom: 15 }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
        {this.renderHourData()}
        <div style={{ marginBottom: 10, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: 'white' }}>{"Aylık Gün Bazında Satış Raporu"}</div>
        <Grid container spacing={1}>
          <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
            <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Gün Seçimi"}</Typography>
            <DatePicker
              selected={Mountly}
              showMonthYearPicker
              onChange={handleChangeFilterMountly("Mountly")}
              dateFormat="MM.yyyy"
              withPortal
              locale="tr"
              className={"inputDate"}
            />
          </Grid>
          <Grid item md={3}>
            <Button disabled={loading} onClick={handleFilterReportMountly} style={{ borderRadius: 5, marginTop: 15, height: 37, padding: '6px 24px' }} size="large" variant="contained" color="secondary" type="submit">
              {"FİLTRELE"}
            </Button>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {(loading ? <div style={{ width: '100%', textAlign: 'center', marginBottom: 15 }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
        {this.renderMountlyData()}
      </Paper>
    )
  }
}

export default GeneralTotal;
