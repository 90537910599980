import cookie from 'react-cookies';
import {
  HubConnectionBuilder, LogLevel, HttpTransportType, HubConnectionState
} from '@microsoft/signalr';
import axios from 'axios';
import brand from 'dan-api/dummy/brand';
import moment from 'moment';
import * as workerTimers from 'worker-timers';
import {
  packageReload, packageReloadUpdate
} from './addition';
import {
  formDataSetSetting
} from './settings';
import {
  getParameter, notificationShow, getLicenceModule, soundPlay, soundPlayCourier
} from '../../function/GeneralFunction';
const base64 = require('base-64');
const uuidv4 = require('uuid/v4');

export const PENDINGPACKAGE_HUB_CONNECT = 'pendingpackage_hub_connect';

const initialState = {
  pendingPackageHubConnection: null
};

export async function setPendingPackageHubConnection() {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');
    const pendingPackageHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 300000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 3000;
          }
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          return null;
        }
      })
      .withUrl(brand.urlSocket + 'pendingpackage?BrandId=' + BrandId, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    await connectPendingPackage(pendingPackageHubConnection, dispatch);

    dispatch({ type: PENDINGPACKAGE_HUB_CONNECT, payload: pendingPackageHubConnection });
  };
}

async function connectPendingPackage(conn, dispatch) {
  await conn.start().then(() => {
    conn.on('GetAllMessagePackageUpdate', (message) => {
      const json = JSON.parse(message);
      const data = json[0];

      if (data.TableName == 'PrintedUpdate' || data.TableName == 'StatusUpdate') {
        dispatch(packageReloadUpdate(json));
        if(data.TableName == 'StatusUpdate' && data.PackageStatus == 7){
          soundPlayCourier('play');
          workerTimers.setTimeout(() => {
            soundPlayCourier('stop');
          }, 4000);
        }
      } else if (data.PackageUserName == 'PaketSutlaCourierAppointed') {
        dispatch(packageReload(json, null));
        const list = [
          {
            ResponseData: moment(new Date()).format('HH:mm:ss') + ' saatinde ' + data.AdditionCode + ' nolu sipariş için kurye ataması yapıldı.',
            IsStatus: true
          }
        ];
        notificationShow(list, 180000);
      } else {
        let OrderControlModalVisible = false;

        const getiryemek = getParameter('GetirYemek');
        const trendyolyemek = getParameter('TrendyolYemek');
        const gofody = getParameter('GoFody');
        const migrosyemek = getParameter('MigrosYemek');

        if (!getiryemek.WebApiOrderConfirmation && json.filter(x => x.ThirdPartyType == 'GetirYemek').length > 0) {
          OrderControlModalVisible = true;
        }

        if (!trendyolyemek.WebApiOrderConfirmation && json.filter(x => x.ThirdPartyType == 'TrendyolYemek').length > 0) {
          OrderControlModalVisible = true;
        }

        if (!migrosyemek.WebApiOrderConfirmation && json.filter(x => x.ThirdPartyType == 'MigrosYemek').length > 0) {
          OrderControlModalVisible = true;
        }

        if (!gofody.WebApiOrderConfirmation && json.filter(x => x.ThirdPartyType == 'GoFody').length > 0) {
          OrderControlModalVisible = true;
        }

        dispatch(packageReload(json, null));
        if (getParameter('WebApiUserId') == cookie.load('UserId')) {
          if (OrderControlModalVisible) {
            soundPlay('play');
            dispatch(formDataSetSetting('OrderControlModal', true));
          } else if (json.filter(x => x.TableName == 'Paket Satış').length == 0) {
            soundPlay('play');
            workerTimers.setTimeout(() => {
              soundPlay('stop');
            }, 7000);
          }
        }
      }
    });
  }).catch(e => {
    sleepPendingPackage(3000);
    connectPendingPackage(conn, dispatch);
  });
}

async function sleepPendingPackage(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

const trackReducer = (state = initialState, action) => {
  switch (action.type) {
    case PENDINGPACKAGE_HUB_CONNECT:
      return {
        ...state, pendingPackageHubConnection: action.payload
      };
    default:
      return state;
  }
};

export default trackReducer;
