import React from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import styles from '../../Settings/settings-jss';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    setCustomerAddAccountVisible, setCustomerAdd, setCustomerAddClear, setCustomerAccountAdd,
    formDataSetCustomer, getCustomer, getCustomerAccountDetail, getStaffParameters
} from '../../../../redux/modules/customer';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { getParameter, trMoneyConvert } from '../../../../function/GeneralFunction';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DatePicker, { registerLocale } from 'react-datepicker';
import "../datepicker.css";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from 'react-autocomplete';
import tr from 'date-fns/locale/tr';
registerLocale('tr', tr);
import moment from 'moment';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            fixedDecimalScale={true}
            autocomplete="off"
            allowNegative={false}
            decimalSeparator=","
            thousandSeparator="."
            prefix="₺"
        />
    );
}

class CustomerAccountAdd extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    handleChange = name => event => {
        const { customerAccountAdd } = this.props;
        customerAccountAdd[name] = (name == "TransactionDate" ? moment.utc(event, 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm") : (name == "Amount" ? parseFloat(event.target.value) : event.target.value));
        this.props.setCustomerAdd().then(() => { })
    };

    customerAccountAdd() {
        const { filterData } = this.props;
        this.props.setCustomerAccountAdd((filterData.BrandId == "expense" ? "expense" : "")).then(() => { })
    }

    render() {
        const { classes, customerAccountAdd, staffParametersData, filterData, customerList, customerValue } = this.props;

        return (
            <Dialog
                open={this.props.customerAccountAddVisible}
                fullWidth={true}
                maxWidth={'xs'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Hesap İşlemleri"}
                </DialogTitle>
                <DialogContent style={{ zIndex: 1, height: '50vh' }}>
                    <Grid container spacing={1}>
                        {
                            (staffParametersData.PersonnelNote.length > 0 ?
                                <div style={{ width: '100%' }}>
                                    <span style={{ fontSize: 12, color: 'rgba(255, 255, 255, 0.7)' }}>{"Personel Notu"}</span>
                                    <div style={{ backgroundColor: '#e74c3c', padding: 5, width: '100%', borderRadius: 5, fontSize: 13, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{staffParametersData.PersonnelNote}</div>
                                </div>
                                : null)
                        }
                        <Grid item md={12} style={{ textAlign: 'center', backgroundColor: '#7f8c8d', marginTop: 5, borderRadius: 5, marginBottom: 10 }}>
                            <Typography className={classes.instructions} style={{ fontSize: 15, marginTop: 0, marginBottom: 0 }}>
                                {"Bakiye"}
                            </Typography>
                            <Typography className={classes.instructions} style={{ fontSize: 25, marginTop: 0, fontWeight: 700, marginBottom: 0 }}>
                                {trMoneyConvert(this.props.total, 2)}
                            </Typography>
                            <Divider />
                        </Grid>
                        {
                            (filterData.BrandId == "expense" ?
                                <Grid item md={12}>
                                    <span style={{ fontSize: 12, color: 'rgba(255, 255, 255, 0.7)' }}>{(filterData.CustomerType == 4 ? "Personel" : "Tedarikçi")} {"Seçimi"}</span>
                                    <Autocomplete
                                        value={customerValue}
                                        inputProps={{ id: 'states-autocomplete' }}
                                        wrapperStyle={{ position: 'relative', marginRight: 5 }}
                                        items={(filterData.CustomerType == 4 ? customerList.filter(x => x.CustomerType == 4) : customerList.filter(x => x.CustomerType == 1))}
                                        getItemValue={item => item.Name}
                                        onChange={(event, value) => this.props.formDataSetCustomer('customerValue', value).then(() => {
                                            this.props.getCustomer(filterData.CustomerType).then(() => { });
                                        })}
                                        onSelect={(value, item) => {
                                            this.props.formDataSetCustomer('customerValue', value).then(() => { });
                                            this.props.formDataSetCustomer('customerSelected', item).then(() => { });
                                            this.props.getCustomerAccountDetail(item.Id).then(() => { });
                                            this.props.getStaffParameters(item.Id, false).then(() => { });
                                        }}
                                        renderMenu={children => (
                                            <div className="menu">
                                                {children}
                                            </div>
                                        )}
                                        renderItem={(item, isHighlighted) => (
                                            <div
                                                className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                                key={item.Id}
                                            >
                                                {item.Name}
                                                {' '}
                                                {'-'}
                                                {' '}
                                                {item.Phone}
                                            </div>
                                        )}
                                    />
                                </Grid>
                                : null)
                        }
                        {
                            (filterData.CustomerType == 4 ?
                                <Grid item md={12}>
                                    <RadioGroup
                                        aria-label="radio"
                                        name="radio"
                                        className={classes.group}
                                        style={{ display: 'block', width: '100%' }}
                                        value={Number(customerAccountAdd.AccountAddType)}
                                        onChange={this.handleChange("AccountAddType")}
                                    >
                                        <FormControlLabel style={{ marginRight: 20 }} value={2} control={<Radio />} label={"Avans"} />
                                        <FormControlLabel style={{ marginRight: 20 }} value={0} control={<Radio />} label={"Ödeme"} />
                                        <FormControlLabel style={{ marginRight: 0 }} value={1} control={<Radio />} label={"Hakediş"} />
                                    </RadioGroup>
                                </Grid>
                                : null)
                        }
                        <Grid item md={12}>
                            <FormControl className={classes.formControl} style={{ margin: 0 }}>
                                <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"İşlem Tarihi"}</Typography>
                                <DatePicker
                                    selected={new Date(customerAccountAdd.TransactionDate)}
                                    onChange={this.handleChange('TransactionDate')}
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    showTimeSelect
                                    timeIntervals={15}
                                    timeFormat="HH:mm"
                                    locale="tr"
                                    className="inputDate"
                                />
                            </FormControl>
                        </Grid>
                        {
                            (filterData.CustomerType == 4 && customerAccountAdd.AccountAddType == 1 ? null :
                                <Grid item md={12}>
                                    <FormControl className={classes.formControl} style={{ margin: 0 }}>
                                        <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Tipi"}</Typography>
                                        <Select
                                            fullWidth
                                            value={customerAccountAdd.PaymentId}
                                            onChange={this.handleChange("PaymentId")}
                                            inputProps={{
                                                name: 'age',
                                                id: 'age-simple',
                                            }}
                                        >
                                            {
                                                getParameter("PaymentTypes").filter(x => x.IsStatus == true && x.Id != '58d99ca4-e5f6-49e8-80a4-a4936c181b8a').map((n, i) => {
                                                    return <MenuItem key={i} value={n.Id}>{n.Name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>)
                        }

                        <Grid item md={12}>
                            <FormControl className={classes.formControl} style={{ margin: 0 }}>
                                <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Tutarı"}</Typography>
                                <TextField
                                    value={customerAccountAdd.Amount}
                                    onChange={this.handleChange("Amount")}
                                    id="formatted-numberformat-input"
                                    onFocus={event => { event.target.select() }}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }} color="primary" autoFocus onClick={() => this.props.setCustomerAddAccountVisible(false).then(() => { })}>
                        {"Çıkış"}
                    </Button>
                    <Button style={{ marginRight: 5 }} onClick={() => this.props.setCustomerAddClear().then(() => { })} color="default" variant="contained" autoFocus>
                        {"TEMİZLE"}
                    </Button>
                    <Button disabled={(customerAccountAdd.Amount == 0 ? true : false)} style={{ marginRight: 5 }} onClick={() => this.customerAccountAdd()} color="secondary" variant="contained" autoFocus>
                        {"Kaydet"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('customerReducer').customerAccountAddUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    customerAccountAddUpdate: value(state, props),
    customerAccountAddVisible: state.get('customerReducer').customerAccountAddVisible,
    customerAccountAdd: state.get('customerReducer').customerAccountAdd,
    customerAccountDetail: state.get('customerReducer').customerAccountDetail,
    staffParametersData: state.get('customerReducer').staffParametersData,
    filterData: state.get('customerReducer').filterData,
    customerList: state.get('customerReducer').form_data.customerList,
    customerValue: state.get('customerReducer').form_data.customerValue,
})

const mapDispatchToProps = {
    setCustomerAddAccountVisible,
    setCustomerAdd,
    setCustomerAddClear,
    setCustomerAccountAdd,
    formDataSetCustomer,
    getCustomer,
    getCustomerAccountDetail,
    getStaffParameters
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(CustomerAccountAdd)));