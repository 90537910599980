import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import {
    setCenterBrandVisible, setCenterBrandAddVisible, setCenterBrandAddData, getBrand,
    setCenterRegionVisible, setCenterAuthorityVisible, setFranchiseRegionVisible
} from '../../../redux/modules/centerbrand';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import Paper from '@material-ui/core/Paper';
import CenterBrandAdd from './CenterBrandAdd';
import CenterRegionList from './CenterRegionList';
import CenterAuthority from './CenterAuthority';
import Region from './Region';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class CenterBrand extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.centerBrandUpdate == this.props.centerBrandUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() { }

    render() {
        const {
            classes,
            centerBrandVisible,
            centerBrandData
        } = this.props;

        return (
            <Dialog
                open={centerBrandVisible}
                fullScreen
                TransitionComponent={Transition}
            >
                <CenterAuthority classes={classes} />
                <CenterRegionList classes={classes} />
                <CenterBrandAdd classes={classes} />
                <Region classes={classes} />
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%', backgroundImage: 'linear-gradient(-45deg, rgb(96, 125, 139) 0%, rgb(96, 125, 139) 33%, rgb(117, 117, 117) 100%)' }}>
                    <Toolbar>
                        <Button variant="contained" onClick={() => this.props.setCenterBrandVisible(false).then(() => { })}>
                            <Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon>
                        </Button>
                        <Typography style={{ textAlign: 'center', width: '100%', fontWeight: 600 }} variant="h7" color="inherit" className={classes.flex}>{"Franchise Şubeler"}</Typography>
                        <Button style={{ paddingLeft: 30, paddingRight: 30 }} variant="contained" color="secondary" onClick={() => this.props.setCenterBrandAddVisible(true).then(() => { })}>
                            <Icon style={{ marginRight: 5 }}>{"store"}</Icon>
                            {"Şube Ekle"}
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64 }}>
                        <Table className={classNames(classes.table, classes.stripped)}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{"Adı"}</TableCell>
                                    <TableCell align="left">{"Email"}</TableCell>
                                    <TableCell align="left">{"Telefon"}</TableCell>
                                    <TableCell align="left">{"Tarihi"}</TableCell>
                                    <TableCell align="left">{"Ciro Payı"}</TableCell>
                                    <TableCell align="right">{""}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    centerBrandData.map(n => ([
                                        <TableRow hover key={n.Id}>
                                            <TableCell align="left">{n.Name}</TableCell>
                                            <TableCell align="left">{n.Email}</TableCell>
                                            <TableCell align="left">{n.MobilePhone}</TableCell>
                                            <TableCell align="left">{moment(n.CreationDatetime).format('DD.MM.YYYY')}</TableCell>
                                            <TableCell align="left">{"%"}{n.TurnoverShare}</TableCell>
                                            <TableCell align="right">
                                                <Tooltip title={"JaviApp Bölge Limitleri"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.props.setFranchiseRegionVisible(true, n.Id).then(() => { })}>
                                                        <Icon>{"south_america"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Düzenle"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.props.getBrand(n.Id).then(() => { })}>
                                                        <Icon>{"edit"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Call Center Bölge Limitleri"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.props.setCenterRegionVisible(n.Id, true).then(() => { })}>
                                                        <Icon>{"add_location_alt"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Yetkilendirme"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.props.setCenterAuthorityVisible(true, n.Id, n.TenantId).then(() => { })}>
                                                        <Icon>{"lock"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ]))
                                }
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').centerBrandUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    centerBrandUpdate: value(state, props),
    centerBrandVisible: state.get('centerBrandReducer').centerBrandVisible,
    centerBrandData: state.get('centerBrandReducer').centerBrandData
});

const mapDispatchToProps = {
    setCenterBrandVisible,
    setCenterBrandAddVisible,
    setCenterBrandAddData,
    getBrand,
    setCenterRegionVisible,
    setCenterAuthorityVisible,
    setFranchiseRegionVisible 
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(CenterBrand)));
