import React from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { setSystemHubConnection } from '../../../redux/modules/system';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
const notification = new Audio("https://web.javipos.com/notification.wav")

class System extends React.Component {
  constructor() {
    super();
  }

  async componentDidMount() {
    if (this.props.systemReducer.systemHubConnection == null) {
      await this.props.setSystemHubConnection().then(() => { });
    }
  }

  render() {
    if(this.props.systemReducer.musicPlay){
      notification.play();
    }

    return <ReactNotification />;
  }
}

const mapStateToProps = state => {
  return {
    systemReducer: state.get('systemReducer')
  };
};

const mapDispatchToProps = {
  setSystemHubConnection
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(System);
