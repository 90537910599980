import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles, createTheme } from '@material-ui/core/styles';
import styles from '../Settings/settings-jss';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import Icon from '@material-ui/core/Icon';
const uuidv4 = require('uuid/v4');
import AlertDialog from '../Ortak/AlertDialog';
import {
  formDataSetReport, modalCloseReport,
  RequestCourierReport, RequestUserReport, filterProductReportDownload,
  filterStockRecipeListDataClear
} from '../../../redux/modules/report';
import { formDataSet, sendMessagePackagePrinter, setPrinterHubConnection, getAdditionHistory, getAddition, clearAddition } from '../../../redux/modules/addition';
import ModalInformation from '../Ortak/ModalInformation';
import ProductItemDetailPage from '../DetailPage/ProductItemDetailPage';
import ThemePallete from 'dan-api/palette/themePalette';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import "./datepicker.css";
import ReportMenu from '../Menu/ReportMenu';
import GeneralTotal from './ReportPage/GeneralTotal';
import EndOfDayPage from './ReportPage/EndOfDayPage';
import CourierReport from './ReportPage/CourierReport';
import UserReport from './ReportPage/UserReport';
import ProductReport from './ReportPage/ProductReport';
import AdditionReport from './ReportPage/AdditionReport';
import CurrentAccountRep from './ReportPage/CurrentAccountRep';
import PaketYoldaReport from './ReportPage/PaketYoldaReport';
import MapReport from './ReportPage/MapReport';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const theme = createTheme(ThemePallete.magentaTheme);
const color = ({
  primary: theme.palette.primary.main,
  primaryDark: theme.palette.primary.dark,
  secondary: theme.palette.secondary.main,
  secondaryDark: theme.palette.secondary.dark,
});

class ReportList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      GeneralTabsValue: 0,
      ProductTabsValue: 0
    };
  }

  handleCloseModalProduct = () => {
    this.props.clearAddition().then(() => {
      this.props.formDataSet("productItemModal", false).then(() => { })
    })
  };

  state = {
    startDate: new Date()
  };

  handleChange = date => {
    this.setState({
      startDate: date
    });
  };

  modalClose = () => {
    this.props.modalCloseReport().then(() => { })
  };

  handleFilterHour() {
  }

  handleFilterMountly() {
  }

  handleFilterEndOfDayReport() {
  }

  handleFilterCourierReport() {
    this.props.RequestCourierReport().then(() => { });
  }

  handleFilterUserReport() {
    this.props.RequestUserReport().then(() => { });
  }

  handleFilterProductReport() {
  }

  handleProductDownload() {
    this.props.filterProductReportDownload().then(() => { });
  }

  handleChangeFilter = name => event => {
    this.props.reportReducer.filterData[name] = (name == "IsCourierOpenAddition" ? event.target.checked : event);
    this.props.formDataSetReport("filterData", this.props.reportReducer.filterData).then(() => { });
  };

  handleChangeFilterHour = name => event => {
    this.props.reportReducer.filterDataHour[name] = event;
    this.props.formDataSetReport("filterDataHour", this.props.reportReducer.filterDataHour).then(() => { });
  };

  handleChangeFilterMountly = name => event => {
    this.props.reportReducer.filterDataMountly[name] = event;
    this.props.formDataSetReport("filterDataMountly", this.props.reportReducer.filterDataMountly).then(() => { });
  };

  handleClickReportData(name) {
    this.props.reportReducer.reportListData.map((n, i) => {
      n.IsSelected = false;
    })
    this.props.reportReducer.reportListData.find(x => x.Name == name).IsSelected = true;
    this.props.formDataSetReport("reportListData", this.props.reportReducer.reportListData).then(() => { });
  }

  renderColorfulLegendText(value, entry) {
    const { color } = entry;
    return <span style={{ color: 'white', fontSize: 12 }}>{value}</span>;
  }

  renderReport() {
    const { classes } = this.props;
    const { GeneralTabsValue, ProductTabsValue } = this.state;
    return this.props.reportReducer.reportListData.filter(x => x.IsSelected == true).map((n, i) => {
      if (n.Name == "Kurye Performans") {
        return (
          <CourierReport loading={this.props.reportLoading} IsCourierOpenAddition={this.props.reportReducer.filterData.IsCourierOpenAddition} key={i} data={this.props.reportReducer.r_CourierReportData} classes StartDate={this.props.reportReducer.filterData.StartDate} EndDate={this.props.reportReducer.filterData.EndDate} handleChangeFilter={(value) => this.handleChangeFilter(value)} handleFilterCourierReport={() => this.handleFilterCourierReport()} />
        )
      } else if (n.Name == "Ürün Satış") {
        return (
          <div key={i}>
            <Tabs
              value={ProductTabsValue || 0}
              onChange={this.handleChangeProductTabs}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="EN ÇOK SATANLAR" />
              <Tab label="ÜRÜN GRUBU BAZINDA SATIŞ" />
              <Tab label="ÜRÜN SATIŞ RAPORU" />
              <Tab label="STOK DURUM RAPORU" />
              <Tab label="REÇETE SATIŞ/MALİYET RAPORU" />
              <Tab label="STOK SATIŞ/MALİYET RAPORU" />
            </Tabs>
            <ProductReport loading={this.props.reportLoading} ProductTabsValue={this.state.ProductTabsValue} data={this.props.reportReducer.r_ProductReportData} classes StartDate={this.props.reportReducer.filterData.StartDate} EndDate={this.props.reportReducer.filterData.EndDate} handleChangeFilter={(value) => this.handleChangeFilter(value)} handleFilterProductReport={() => this.handleFilterProductReport()} handleProductDownload={() => this.handleProductDownload()} />
          </div>
        )
      } else if (n.Name == "Kullanıcı Performans") {
        return (
          <UserReport loading={this.props.reportLoading} key={i} data={this.props.reportReducer.r_UserReportData} classes StartDate={this.props.reportReducer.filterData.StartDate} EndDate={this.props.reportReducer.filterData.EndDate} handleChangeFilter={(value) => this.handleChangeFilter(value)} handleFilterUserReport={() => this.handleFilterUserReport()} />
        )
      } else if (n.Name == "Gün Sonu") {
        return (
          <div key={i}>
            <Tabs
              value={GeneralTabsValue || 0}
              onChange={this.handleChangeGeneralTabs}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="DETAYLI RAPOR" />
              <Tab label="GENEL İSTATİSTİK" />
            </Tabs>
            {((GeneralTabsValue || 0) == 1 ? <GeneralTotal loading={this.props.reportLoading} dataMountly={this.props.reportReducer.r_MountlyData} dataHour={this.props.reportReducer.r_HourData} classes Date={this.props.reportReducer.filterDataHour.Date} Mountly={this.props.reportReducer.filterDataMountly.Mountly} handleChangeFilterMountly={(value) => this.handleChangeFilterMountly(value)} handleFilterReportMountly={() => this.handleFilterMountly()} handleChangeFilterHour={(value) => this.handleChangeFilterHour(value)} handleFilterReportHour={() => this.handleFilterHour()} /> : null)}
            {((GeneralTabsValue || 0) == 0 ? <EndOfDayPage loading={this.props.reportLoading} data={this.props.reportReducer.EndOfDayReport} classes StartDate={this.props.reportReducer.filterData.StartDate} EndDate={this.props.reportReducer.filterData.EndDate} handleChangeFilter={(value) => this.handleChangeFilter(value)} handleFilterReport={() => this.handleFilterEndOfDayReport()} /> : null)}
          </div>
        )
      } else if (n.Name == "Adisyon Raporu") {
        return (
          <AdditionReport loading={this.props.reportLoading} classes={classes} />
        )
      } else if (n.Name == "Harita") {
        return (
          <PaketYoldaReport loading={this.props.reportLoading} classes={classes} />
        )
      } else if (n.Name == "Açık Hesap") {
        return (
          <CurrentAccountRep loading={this.props.reportLoading} classes={classes} />
        )
      }
    })
  }

  handleChangeGeneralTabs = (event, value) => {
    this.setState({ GeneralTabsValue: value });
  };

  handleChangeProductTabs = async (event, value) => {
    if (value == 4 || value == 5) {
      await this.props.filterStockRecipeListDataClear().then(() => { })
    }
    this.setState({ ProductTabsValue: value });
  };

  handleCloseModal = () => {
    this.props.formDataSet("historyModalOpen", false).then(() => { })
  };

  render() {
    const {
      classes,
      children,
      pageLoaded,
      history,
      gradient,
      layout,
      open,
      handleClose,
      title
    } = this.props;
    const { fixed } = true;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <ReportMenu
          pageLoaded={true}
          gradient={false}
          handleClickReportData={(name) => this.handleClickReportData(name)}
          handleClose={handleClose}
          data={this.props.reportReducer.reportListData.find(x => x.IsSelected == true)}
        >
          <ProductItemDetailPage Addition={this.props.additionReducer.addition_data} eventClose={this.handleCloseModalProduct} open={this.props.additionReducer.productItemModal} />
          {this.props.additionReducer.historyModalOpen ? <AlertDialog eventConfirmation={this.handleCloseModal} eventClose={this.handleCloseModal} open={this.props.additionReducer.historyModalOpen} title={this.props.additionReducer.historyModalTitle} text={this.props.additionReducer.historyModalText} /> : null}
          <ModalInformation event={this.modalClose} open={this.props.reportReducer.modalOpen} title={this.props.reportReducer.modalTitle} text={this.props.reportReducer.modalText} />
          <div style={{ marginTop: 5, flex: 1, flexDirection: 'column', width: '100%', backgroundColor: '#292929', position: 'relative' }}>
            {this.renderReport()}
          </div>
        </ReportMenu>
      </Dialog>
    );
  }
}

ReportList.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    reportReducer: state.get('reportReducer').form_data,
    additionReducer: state.get('additionReducer').form_data,
    reportLoading: state.get('reportReducer').reportLoading,
  };
};

const mapDispatchToProps = {
  formDataSetReport,
  formDataSet,
  modalCloseReport,
  sendMessagePackagePrinter,
  setPrinterHubConnection,
  getAdditionHistory,
  getAddition,
  clearAddition,

  RequestCourierReport,
  RequestUserReport,
  filterProductReportDownload,

  filterStockRecipeListDataClear
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ReportList)));
