import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/orange';
import teal from '@material-ui/core/colors/teal';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lightBlue from '@material-ui/core/colors/lightBlue';
import indigo from '@material-ui/core/colors/indigo';
import grey from '@material-ui/core/colors/grey';
import amber from '@material-ui/core/colors/amber';
const colorfull = [
  pink[400],
  purple[500],
  lightBlue[500],
  teal[500],
  orange[600],
  lightGreen[600],
  indigo.A200,
  grey[800],
  amber.A700,
  grey[700]
];
export default colorfull;
