import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../../CenterBrandPage/centerbrandpage-jss';
import {
    setPaketYoldaUserAddData, setPaketYoldaCenterAddVisible, setPaketYoldaNewUser, setPaketYoldaUserAddVisible
} from '../../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import { CapitalizeText, ComplateLowerText } from '../../../../function/GeneralFunction';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class UserAdd extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaUserAddUpdate == this.props.paketYoldaUserAddUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangePaketYoldaCenter = (Name) => (event) => {
        const pointer = event.target.selectionStart;
        const element = event.target;

        const { paketYoldaUserAddData } = this.props;
        paketYoldaUserAddData[Name] = (Name == "IsActive" ? event.target.checked : (Name == "AuthorizedPerson" ? CapitalizeText(event.target.value) : (Name == "Email" ? ComplateLowerText(event.target.value) : event.target.value)));
        this.props.setPaketYoldaUserAddData(paketYoldaUserAddData).then(() => { })

        window.requestAnimationFrame(() => {
            element.selectionStart = pointer;
            element.selectionEnd = pointer;
        });
    };

    handleSubmit(event) {
        event.preventDefault();
        this.props.setPaketYoldaNewUser().then(() => { })
    }

    render() {
        const { classes, paketYoldaUserAddVisible, paketYoldaUserAddData, paketYoldaUserAddLoading } = this.props;

        return (
            <Dialog
                open={paketYoldaUserAddVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'sm'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <form autocomplete="off" style={{ display: 'contents' }} onSubmit={this.handleSubmit}>
                    <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                        {"JaviKurye Merkez Ekleme/Düzenleme"}
                    </DialogTitle>
                    <DialogContent style={{ overflow: 'hidden auto' }}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={paketYoldaUserAddData.IsActive}
                                            onChange={this.handleChangePaketYoldaCenter("IsActive")}
                                        />
                                    )}
                                    label={"Aktif"}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Kullanıcı Adı'}</InputLabel>
                                    <Input
                                        type="email"
                                        autoComplete="new-email"
                                        fullWidth
                                        required
                                        placeholder={'Kullanıcı Adı Giriniz'}
                                        value={paketYoldaUserAddData.Username}
                                        onChange={this.handleChangePaketYoldaCenter('Username')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Şifre'}</InputLabel>
                                    <Input
                                        type="password"
                                        autoComplete="new-password"
                                        fullWidth
                                        required
                                        placeholder={'Şifre Giriniz'}
                                        value={paketYoldaUserAddData.Passsword}
                                        onChange={this.handleChangePaketYoldaCenter('Passsword')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'PIN'}</InputLabel>
                                    <Input
                                        type="number"
                                        fullWidth
                                        required
                                        placeholder={'(1-9999 arasında) PIN Giriniz'}
                                        value={paketYoldaUserAddData.PIN}
                                        onChange={this.handleChangePaketYoldaCenter('PIN')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={paketYoldaUserAddLoading} type={"submit"} variant="contained" color="secondary">
                            {"Kaydet"}
                        </Button>
                        <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPaketYoldaUserAddVisible(false).then(() => { })} color="primary">
                            {"Çıkış"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

UserAdd.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaUserAddUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaUserAddUpdate: value(state, props),
    paketYoldaUserAddVisible: state.get('paketYoldaReducer').paketYoldaUserAddVisible,
    paketYoldaUserAddLoading: state.get('paketYoldaReducer').paketYoldaUserAddLoading,
    paketYoldaUserAddData: state.get('paketYoldaReducer').paketYoldaUserAddData
});

const mapDispatchToProps = {
    setPaketYoldaCenterAddVisible,
    setPaketYoldaUserAddData,
    setPaketYoldaNewUser,
    setPaketYoldaUserAddVisible
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(UserAdd)));
