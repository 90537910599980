import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import {
    setCenterSettingsVisible, setCenterSettingsData, setCenterSettings
} from '../../../redux/modules/centerbrand';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class CenterSettings extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.centerSettingUpdate == this.props.centerSettingUpdate) {
            return false;
        }
        return true;
    }

    handleChangeCenterGeneral = (Name) => (event) => {
        const { centerSettingData } = this.props;
        centerSettingData[Name] = (Name == "ReportStartDate" || Name == "ReportEndDate" ? moment.utc(event, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm") : (Name == "IsFranchise" ? event.target.checked : event.target.value));
        this.props.setCenterSettingsData(centerSettingData).then(() => { })
    };

    async componentDidMount() { }

    render() {
        const {
            classes,
            centerSettingVisible,
            centerSettingData
        } = this.props;

        return (
            <Dialog
                open={centerSettingVisible}
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%', backgroundImage: 'linear-gradient(-45deg, rgb(96, 125, 139) 0%, rgb(96, 125, 139) 33%, rgb(117, 117, 117) 100%)' }}>
                    <Toolbar>
                        <Button variant="contained" onClick={() => this.props.setCenterSettingsVisible(false).then(() => { })}>
                            <Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon>
                        </Button>
                        <Typography style={{ textAlign: 'center', width: '100%', fontWeight: 600 }} variant="h7" color="inherit" className={classes.flex}>{"Ayarlar"}</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64 }}>
                        <div style={{ height: '100%', padding: 5, overflowY: 'auto' }}>
                            <div style={{ backgroundColor: 'rgb(49, 49, 49)', padding: 5, marginTop: 10 }}>
                                <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {"Franchise Sistemi Kullanımı"}
                                </div>
                                <div style={{ fontSize: 15, display: 'grid', textAlign: 'center', alignItems: 'center', backgroundColor: '#d1d8e0', color: '#424242', fontWeight: 600 }}>
                                    <div style={{ width: '100%', fontSize: 14 }}>
                                        {"Franchise sistemini aktif ettiğinizde şubelerden veri akışı başlatılmış olur!"}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                                    <div style={{ fontSize: 13, textAlign: 'left', width: '75%' }}>{"Franchise Sistemini Aktif Et"}</div>
                                    <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                        <FormControlLabel
                                            control={(
                                                <Switch
                                                    checked={centerSettingData.IsFranchise}
                                                    onChange={this.handleChangeCenterGeneral("IsFranchise")}
                                                />
                                            )}
                                            label={"Kullan"}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {"Franchise Çalışma Saat Aralığı"}
                                </div>
                                <div style={{ fontSize: 15, display: 'grid', textAlign: 'center', alignItems: 'center', backgroundColor: '#d1d8e0', color: '#424242', fontWeight: 600 }}>
                                    <div style={{ width: '100%', fontSize: 14 }}>{"Ayarlanan saat yeni oluşturduğunuz şubelerde çalışma saati olarak aktarılacaktır!"}</div>
                                </div>
                                <div style={{ margin: 5 }}>
                                    <Grid container spacing={1} style={{ backgroundColor: '#424242' }}>
                                        <Grid item md={6} >
                                            <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"İşletmelerinizin Açılış Saati"}</Typography>
                                            <DatePicker
                                                selected={new Date(centerSettingData.ReportStartDate)}
                                                onChange={this.handleChangeCenterGeneral("ReportStartDate")}
                                                dateFormat="HH:mm"
                                                withPortal
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                locale="tr"
                                                className={"inputDate"}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"İşletmelerinizin Kapanış Saati"}</Typography>
                                            <DatePicker
                                                selected={new Date(centerSettingData.ReportEndDate)}
                                                onChange={this.handleChangeCenterGeneral("ReportEndDate")}
                                                dateFormat="HH:mm"
                                                withPortal
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                locale="tr"
                                                className={"inputDate"}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.setCenterSettings().then(() => { })} variant="contained" color="secondary">
                        {"Kaydet"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').centerSettingUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    centerSettingUpdate: value(state, props),
    centerSettingVisible: state.get('centerBrandReducer').centerSettingVisible,
    centerSettingData: state.get('centerBrandReducer').centerSettingData
});

const mapDispatchToProps = {
    setCenterSettingsVisible,
    setCenterSettingsData,
    setCenterSettings
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(CenterSettings)));
