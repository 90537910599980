import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../Settings/settings-jss';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {
  setLicenceModuleData, setLicenceModule, setNewBrandTrackData, setTrackCount
} from '../../../../redux/modules/admin';
import { createSelector } from 'reselect';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import MaskedInput from 'react-text-mask';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class LicenceModuleUpdate extends React.Component {

  handleChangeModule = (Id, Name) => event => {
    const { licenceModuleBrandId, licenceModuleData } = this.props;
    if (Name == "Minus" || Name == "Plus") {
      if (licenceModuleData.find(x => x.Id == Id).CourierCount == 0 && Name == "Minus") {
        return;
      }
      if (licenceModuleData.find(x => x.Id == Id).CourierCount == 20 && Name == "Plus") {
        return;
      }
      licenceModuleData.find(x => x.Id == Id)["CourierCount"] = (Name == "Minus" ? licenceModuleData.find(x => x.Id == Id).CourierCount - 1 : licenceModuleData.find(x => x.Id == Id).CourierCount + 1);
      licenceModuleData.find(x => x.Id == Id)["Price"] = licenceModuleData.find(x => x.Id == Id)["CourierCount"] * 59;
    } else {
      licenceModuleData.find(x => x.Id == Id)[Name] = (Name == "IsStatus" ? event.target.checked : event.target.value);
    }
    this.props.setLicenceModuleData(licenceModuleData, licenceModuleBrandId);
  }

  render() {
    const { licenceModuleBrandId, classes, licenceModuleVisible, licenceModuleData, brandTrackCount, newBrandTrackCount } = this.props;

    if (licenceModuleData == null) {
      return null;
    }

    return (
      <Dialog
        open={licenceModuleVisible}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'md'}>
        <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {'Modül Ekleme ve Çıkarma İşlemleri'}
        </DialogTitle>
        <DialogContent style={{ width: 800, height: 700 }}>
          <Table
            className={classNames(classes.table, classes.bordered)}
            style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">{'Bakiye'}</TableCell>
                <TableCell align="left">{'Yeni Bakiye'}</TableCell>
                <TableCell align="center">{'İşlem'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  {brandTrackCount}
                </TableCell>
                <TableCell align="left">
                  <FormControl className={classes.formControl} style={{ margin: 0 }}>
                    <Input
                      placeholder='Yüklenecek Bakiye'
                      value={newBrandTrackCount}
                      onFocus={event => { event.target.select() }}
                      fullWidth
                      onChange={(event) => this.props.setNewBrandTrackData(event.target.value).then(() => {})} />
                  </FormControl>
                </TableCell>
                <TableCell align="center">
                  <Button fullWidth variant='contained' color="secondary" onClick={() => (confirm('Şubeye krediyi yüklemek istediğinize emin misiniz?') ? this.props.setTrackCount(licenceModuleBrandId, newBrandTrackCount).then(() => { }) : null)} style={{ zIndex: 1, borderRadius: 5 }}>
                    {"Yükle"}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={classNames(classes.table, classes.bordered)}
            style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">{'Durumu'}</TableCell>
                <TableCell align="left">{'Adı'}</TableCell>
                <TableCell align="center">{'Fiyatı'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                licenceModuleData.map((n, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell align="left">
                        <Switch
                          checked={n.IsStatus}
                          onChange={this.handleChangeModule(n.Id, "IsStatus")}
                        />
                      </TableCell>
                      <TableCell align="left">{n.Name}</TableCell>
                      <TableCell align="center">
                        {n.Price.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: '#27ae60', color: 'white' }}
            onClick={() => this.props.setLicenceModule(licenceModuleBrandId, licenceModuleData.filter(x => x.IsStatus == true))}
            color="primary">
            {'Kaydet'}
          </Button>
          <Button
            style={{ backgroundColor: '#e74c3c', color: 'white' }}
            onClick={() => this.props.setLicenceModuleData(null, null)}
            color="primary">
            {'Kapat'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const valueSelector = (state, props) => state.get('adminReducer').licenceModuleUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  licenceModuleUpdate: value(state, props),
  licenceModuleVisible: state.get('adminReducer').licenceModuleVisible,
  licenceModuleData: state.get('adminReducer').licenceModuleData,
  licenceModuleBrandId: state.get('adminReducer').licenceModuleBrandId,

  brandTrackCount: state.get('adminReducer').brandTrackCount,
  newBrandTrackCount: state.get('adminReducer').newBrandTrackCount,
});

const mapDispatchToProps = {
  setLicenceModuleData,
  setLicenceModule,
  setNewBrandTrackData,
  setTrackCount
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(LicenceModuleUpdate)));
