import axios from 'axios';
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';
import { getParameter, MakeRandomKey } from '../../function/GeneralFunction';
import {
  formDataSetSetting, setModal
} from './settings';
import {
  setCustomerAddDataVisible, formDataSetCustomer
} from './customer';
const base64 = require('base-64');
const uuidv4 = require('uuid/v4');
const utf8 = require('utf8');

export const STOCK_START = 'stock_start';
export const STOCK_STOP = 'stock_stop';
export const STOCK_ERROR = 'stock_error';
export const STOCK_GLOBAL_FORM_DATA_SET = 'stock_global_form_data_set';
export const STOCK_MODALCLOSE = 'stock_modalclose';
export const STOCKCATEGORYLIST_UPDATE = 'stockcategorylist_update';

export const PRODUCTLIST_LOADING = 'productlist_loading';
export const PRODUCTLIST_UPDATE = 'productlist_update';
export const PRODUCTLISTPRICE_UPDATE = 'productlistprice_update';
export const PRODUCTLIST_VISIBLE = 'productlist_visible';

export const CATEGORYLIST_UPDATE = 'categorylist_update';
export const CATEGORYLIST_VISIBLE = 'categorylist_visible';
export const CATEGORY_NEW = 'category_new';


export const PRINTERGROUPLIST_UPDATE = 'printergrouplist_update';
export const PRINTERGROUPLIST_VISIBLE = 'printergrouplist_visible';
export const PRINTERGROUP_NEW = 'printergroup_new';

export const STOCK_CLEAR = 'stock_clear';

export const SET_SEEPRICEDATA = 'set_seepricedata';

export const PRODUCTQUANTITYLIST_UPDATE = 'productquantitylist_update';
export const PRODUCTQUANTITYLIST_VISIBLE = 'productquantitylist_visible';
export const PRODUCTQUANTITYLIST_LOADING = 'productquantitylist_loading';

export const PRODUCTQUANTITYADD_VISIBLE = 'productquantityadd_visible';
export const PRODUCTQUANTITYADD_UPDATE = 'productquantityadd_update';
export const PRODUCTQUANTITYADD_CLEAR = 'productquantityadd_clear';
export const PRODUCT_AUTOCOMPLETE_SET = 'product_autocomplete_set';
export const PRODUCTQUANTITY_GET = 'productquantity_get';

export const SET_GETİRYEMEK_PRODUCT = 'set_getiryemek_product';

export const SET_PRODUCTADDLIST = 'set_productaddlist';

export const PRODUCTRECIPELIST_UPDATE = 'productrecipelist_update';
export const PRODUCTRECIPELIST_VISIBLE = 'productrecipelist_visible';
export const PRODUCTRECIPELIST_LOADING = 'productrecipelist_loading';

export const SET_PRODUCTQUANTITYADDDESC = 'set_productquantityadddesc';

export const PRODUCTMOVEMENTLIST_UPDATE = 'productmovementlist_update';
export const PRODUCTMOVEMENTFILTER_UPDATE = 'productmovementfilter_update';
export const PRODUCTMOVEMENTLIST_VISIBLE = 'productmovementlist_visible';
export const PRODUCTMOVEMENTLIST_LOADING = 'productmovementlist_loading';

export const PRODUCTMOVEMENT_UPDATE = 'productmovement_update';

export const PRODUCT_ENTEGRATION_UPDATE = 'product_entegration_update';
export const PRODUCT_ENTEGRATION_VISIBLE = 'product_entegration_visible';

export const PRODUCT_ENTEGRATION_LOADING = 'product_entegration_loading';

export const PRODUCT_PROPERTY_LIST_UPDATE = 'product_property_list_update';
export const PRODUCT_PROPERTY_LIST_VISIBLE = 'product_property_list_visible';
export const PRODUCT_PROPERTY_LIST_DATA = 'product_property_list_data';
export const PRODUCT_PROPERTY_LIST_LOADING = 'product_property_list_loading';

export const PRODUCT_ALL_DISCOUNT_VISIBLE = 'product_all_discount_visible';
export const PRODUCT_ALL_DISCOUNT_LOADING = 'product_all_discount_loading';
export const PRODUCT_ALL_DISCOUNT_DATA = 'product_all_discount_data';

export const PRODUCTADD_SAVE_LOADING = 'productadd_save_loading';

export const PRODUCT_ADD_ALL_VISIBLE = 'product_add_all_visible';
export const PRODUCT_ADD_ALL_LOADING = 'product_add_all_loading';
export const PRODUCT_ADD_ALL_DATA = 'product_add_all_data';

export const HB_GETDOCUMENT_LIST_VISIBLE = 'hb_getdocument_list_visible';
export const HB_GETDOCUMENT_LIST_UPDATE = 'hb_getdocument_list_update';
export const HB_GETDOCUMENT_LIST_DATA = 'hb_getdocument_list_data';
export const HB_XML_FILE_GET_DATA = 'hb_xml_file_get_data';

export const SET_STOCK_TAKING_VISIBLE = 'set_stock_taking_visible';
export const SET_STOCK_TAKING_LOADING = 'set_stock_taking_loading';
export const SET_STOCK_TAKING_DATA = 'set_stock_taking_data';

export const HB_INVOICE_DATA = 'hb_invoice_data';

var TimeData = getParameter("BusinessWorkingHours");
const initialState = {
  form_data: {
    modalOpen: false,
    modalTitle: '',
    modalText: '',
    activeStep: 0,
    altLabel: false,
    skipped: new Set(),
    age: '',
    checkedA: true,
    MenuId: '',
    ItemId: '',
    MenuItemStockName: '',
    MenuItemStockId: '',
    MenuItemModalOpen: false,
    MenuItemModalText: '',
    MenuItemSearchText: '',
    UnitDeleteId: '',
    unit: {
      Id: 'a546413a-0abb-48cc-9fff-915b6da9598a',
      UnitName: '',
      Price: 0,
      PackagePrice: 0,
      Cost: 0,
      Order: 1,
      IsDelete: false,
      IsVisible: true,
      UnitMultiplier: 1
    },
    property: {
      Id: '',
      Name: '',
      Order: 1,
      Type: 'Radio',
      Item: [],
      IsDelete: false,
      MenuType: '0'
    },
    propertyitem: {
      Id: '',
      Name: '',
      Price: 0,
      Default: false,
      IsDelete: false,
      StockId: '00000000-0000-0000-0000-000000000000'
    },
    menu: {
      Id: '',
      Name: '',
      Order: 1,
      Item: [],
      IsDelete: false,
      MenuType: '1',
      IsCompulsory: true
    },
    menuitem: {
      Id: '',
      StockId: '00000000-0000-0000-0000-000000000000',
      Name: '',
      Price: 0,
      Default: false,
      IsDelete: false
    },
    data: {
      Id: '',
      Category: '',
      ProductName: '',
      Barcode: '',
      Kdv: 8,
      Order: 1,
      IsFavorite: false,
      IsList: true,
      IsScales: false,
      Color: '#2196f3',
      IsUpdate: false,
      TenantId: '',
      BrandId: '',
      Unit: [],
      Property: [],
      Menu: []
    },
    category: {
      Id: '',
      Name: '',
      Order: '',
      Color: '',
      TenantId: '',
      BrandId: ''
    },
    categoryadd: {
      Id: '',
      Name: '',
      Order: 1,
      Color: '#2196f3',
      TenantId: '',
      BrandId: '',
      IsUpdate: false
    },
    printergroupadd: {
      Id: '',
      GroupName: '',
      PrinterName: '',
      KitPrinterName: '',
      KitchenPrinterName: '',
      PackagePrinterName: '',
      TenantId: '',
      BrandId: '',
    },
    categoryList: [],

    printerGroupList: [],
    printerList: [],

    productListLoad: false,
    productList: [],
    productListSearch: [],
    productOldList: [],
    productOldCallCenterList: [],
    openProperty: false,
    openCategory: false,
    categoryAddButtonVisible: false,
    printerGroupAddButtonVisible: false,
    productDeleteModal: false,
    categoryDeleteModal: false,
    printerGroupDeleteModal: false,
    openMenu: false,
    productAddModal: false,
    productAddModalTitle: ''
  },

  productListUpdate: false,
  productListVisible: false,
  filterDataProduct: {
    sortColumnName: 'Name',
    sortOrder: 'asc',
    totalRecord: 0,
    currentPage: 1,
    rowsPerPage: 50,
    searchText: '',
    BrandId: '',
    totalPage: 0,
    List: [],
    Category: [],
    CategoryId: '00000000-0000-0000-0000-000000000000'
  },
  productListLoading: false,

  categoryListUpdate: false,
  categoryListVisible: false,

  productMovementListUpdate: false,
  productMovementListVisible: false,
  productMovementListLoading: false,
  filterDataProductMovementFilter: {
    BrandId: '',
    startDate: TimeData.start,
    endDate: TimeData.end,
    page: 1
  },
  filterDataProductMovementList: {
    Results: [],
    PageSize: 0,
    RowCount: 0,
    CurrentPage: 0
  },
  printerGroupListUpdate: false,
  printerGroupListVisible: false,

  seePriceData: null,

  productQuantityListUpdate: false,
  productQuantityListVisible: false,
  productQuantityListLoading: false,
  filterDataProductQuantity: {
    sortColumnName: 'CreationDatetime',
    sortOrder: 'desc',
    totalRecord: 0,
    currentPage: 1,
    rowsPerPage: 50,
    searchText: '',
    BrandId: '',
    StockId: '00000000-0000-0000-0000-000000000000',
    totalPage: 0,
    List: [],
    RemainingAmount: []
  },

  productQuantityAddVisible: false,
  productQuantityUpdate: false,
  productQuantityAddUpdate: false,
  productQuantityAdd: {
    Id: uuidv4(),
    StockQantityType: 0,
    IsRecipe: false,
    CreationDatetime: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
    Quantity: 1,
    Price: 0,
    Amount: 0,
    IsNew: false,
    DiscountRate: 0,
    NetAmount: 0,
    VatStatus: false,
    VatRate: 0,
    VatAmount: 0,
    DiscountAmount: 0,
    BrandId: cookie.load('BrandId'),
    TenantId: cookie.load('TenantId'),
    UserId: cookie.load('UserId'),
    Username: cookie.load('NameSurname'),
    StockId: '00000000-0000-0000-0000-000000000000',
    SecondQuantity: 1,
    SecondUnitId: '00000000-0000-0000-0000-000000000000',
    UnitId: '00000000-0000-0000-0000-000000000000',
    StockQuantityHeaderId: '00000000-0000-0000-0000-000000000000',
    IsDelete: false,
    AdditionId: '00000000-0000-0000-0000-000000000000'
  },
  productQuantityAddDesc: {
    Id: uuidv4(),
    Explanation: '',
    CreationDatetime: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
    ReceiptNumber: MakeRandomKey(8),
    GrossTotal: 0,
    DiscountTotal: 0,
    NetTotal: 0,
    VatTotal: 0,
    Total: 0,
    UserId: cookie.load('UserId'),
    Username: cookie.load('NameSurname'),
    BrandId: cookie.load('BrandId'),
    TenantId: cookie.load('TenantId'),
    CustomerId: '',
    CustomerName: '',
    IsDelete: false,
    StockQuantityList: [],
    ClosedStatus: false,
    ClosedPaymentId: '00000000-0000-0000-0000-000000000000',
    ClosedPaymentName: '',
    ClosedDate: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm")
  },
  productAutoCompleteList: [],
  getirProductList: [],
  productAddList: [],
  productRecipeListUpdate: false,
  productRecipeListVisible: false,
  productRecipeListLoading: false,
  productRecipeData: [],
  selectedProductRecipeId: '',

  productPropertyListUpdate: false,
  productPropertyListVisible: false,
  productPropertyListLoading: false,
  productPropertyListData: [],

  productAllDiscountUpdate: false,
  productAllDiscountVisible: false,
  productAllDiscountLoading: false,
  productAllDiscountData: {
    PriceType: 'Normal',
    Discount: 1
  },
  productAddSaveLoading: false,
  productAddSaveText: '',

  productAddAllUpdate: false,
  productAddAllVisible: false,
  productAddAllLoading: false,
  productAddAllData: [],
  hbGetDocumentList: [],
  hbGetDocumentListVisible: false,
  hbGetDocumentListUpdate: false,
  hbGetXmlFileReadData: null,

  stockTakingVisible: false,
  stockTakingUpdate: false,
  stockTakingLoading: false,
  stockTakingData: [],

  invoiceVisible: false,
  invoiceData: ''
};

export async function getStockMovementList(filterData) {
  return async (dispatch, getState) => {
    const { filterDataProductMovementFilter } = getState().get('stockReducer');

    dispatch({ type: PRODUCTMOVEMENTLIST_LOADING });
    filterDataProductMovementFilter.BrandId = cookie.load('BrandId');

    const aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/getStockMovementList',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: filterDataProductMovementFilter,
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: PRODUCTMOVEMENTLIST_UPDATE,
            data: json
          });
        } else {
          dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
        alert(error);
      });
    dispatch({ type: PRODUCTMOVEMENTLIST_LOADING });
  };
}

export async function ProductQuantityGet(Id) {
  return async (dispatch, getState) => {
    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/ProductQuantityGet?Id=' + Id,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        if (response.data.isStatus) {
          json.CreationDatetime = new Date(json.CreationDatetime);
          dispatch({ type: PRODUCTQUANTITY_GET, payload: json });
        }
      })
      .catch((error) => {

      });
  };
}

export async function ProductQuantityDelete(Id, StockId) {
  return async (dispatch, getState) => {
    const { filterDataProductQuantity } = getState().get('stockReducer');

    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/ProductQuantityDelete?Id=' + Id,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          filterDataProductQuantity.StockId = StockId;
          dispatch(getProductQuantityList(filterDataProductQuantity));
        }
      })
      .catch((error) => {

      });
  };
}

export async function ProductQuantityHeaderDelete(Id) {
  return async (dispatch, getState) => {

    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/ProductQuantityHeaderDelete?Id=' + Id,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getStockMovementList());
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {

      });
  };
}

export async function getStockMovementDetail(Id) {
  return async (dispatch, getState) => {

    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/getStockMovementDetail?Id=' + Id,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch(setProductMovementUpdate(jsonData));
        }
      })
      .catch((error) => {

      });
  };
}

export async function postProductQuantityAdd() {
  return async (dispatch, getState) => {
    const { productQuantityAddDesc, hbGetDocumentList } = getState().get('stockReducer');

    var text = "";
    if (productQuantityAddDesc.CustomerId == '') {
      text = "Tedarikçi seçimi yapmanız gerekmektedir.";
    } else if (productQuantityAddDesc.StockQuantityList.length == 0) {
      text = "Ürün satırı eklemeniz gerekmektedir.";
    } else if (productQuantityAddDesc.ReceiptNumber.length == 0) {
      text = "Evrak numarası girilmedilir.";
    } else if (productQuantityAddDesc.StockQuantityList.length > 0) {
      productQuantityAddDesc.StockQuantityList.map((n, i) => {
        if (n.Price == 0 || n.Quantity < 1 || n.UnitId == '00000000-0000-0000-0000-000000000000' || n.StockId == '00000000-0000-0000-0000-000000000000') {
          text = "Ürün satır bilgilerinde girilmemiş veya geçersiz veri bulunmaktadır.";
        }
      })
    } else if (productQuantityAddDesc.ClosedStatus && productQuantityAddDesc.ClosedPaymentId == '00000000-0000-0000-0000-000000000000') {
      text = "Kapalı fatura için ödeme yöntemi seçmelisiniz!";
    }

    if (productQuantityAddDesc.ClosedStatus) {
      productQuantityAddDesc.ClosedPaymentName = getParameter("PaymentTypes").find(x => x.Id == productQuantityAddDesc.ClosedPaymentId).Name;
    }

    if (text.length > 0) {
      dispatch(setModal(text));
      return;
    }

    productQuantityAddDesc.StockQuantityList.map((n, i) => {
      n.BrandId = cookie.load('BrandId');
      n.TenantId = cookie.load('TenantId');
      n.Username = cookie.load('NameSurname');
      n.UserId = cookie.load('UserId');
    });

    const aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/ProductQuantityAdd',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: productQuantityAddDesc,
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getStockMovementList());
          dispatch(setModal(response.data.responseData));
          hbGetDocumentList.find(x => x.UUID == productQuantityAddDesc.Id).IsProcessed = true
          dispatch({ type: HB_GETDOCUMENT_LIST_DATA, payload: hbGetDocumentList });
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {

      });
  };
}

export async function getStockAutoComplete(value) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/getStockAutoComplete?value=' + value + '&BrandId=' + cookie.load('CenterBrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({ type: PRODUCT_AUTOCOMPLETE_SET, payload: json });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setProductCategory() {
  return async (dispatch, getState) => {
    const { categoryadd } = getState().get('stockReducer').form_data;
    dispatch(formDataSetStock('categoryAddButtonVisible', true));
    if (!categoryadd.IsUpdate) {
      categoryadd.Id = uuidv4();
    }

    categoryadd.TenantId = cookie.load('TenantId');
    categoryadd.BrandId = cookie.load('CenterBrandId');

    axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/SetProductCategory',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: categoryadd,
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        dispatch(getStockGroup(true));
        dispatch(formDataSetStock('categoryAddButtonVisible', false));
        dispatch(setCategoryNew());
      })
      .catch((error) => {
        dispatch(formDataSetStock('categoryAddButtonVisible', false));
      });
  };
}

export async function setPrinterGroup() {
  return async (dispatch, getState) => {
    const { printergroupadd } = getState().get('stockReducer').form_data;
    dispatch(formDataSetStock('printerGroupAddButtonVisible', true));
    if (!printergroupadd.IsUpdate) {
      printergroupadd.Id = uuidv4();
    }

    printergroupadd.TenantId = cookie.load('TenantId');
    printergroupadd.BrandId = cookie.load('BrandId');

    axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/setPrinterGroup',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: printergroupadd,
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        dispatch(formDataSetStock('printerGroupList', json.PrinterGroup));
        dispatch(formDataSetStock('printerList', json.PrinterList));
        dispatch(formDataSetStock('printerGroupAddButtonVisible', false));
        dispatch(setCategoryNew());
      })
      .catch((error) => {
        dispatch(formDataSetStock('printerGroupAddButtonVisible', false));
      });
  };
}

export async function getProductUpdate(Id) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');

    axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/GetProduct?Id=' + Id + '&BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSetStock('data', json));
          dispatch(formDataSetStock('productAddModalTitle', 'Ürün Düzenleme'));
          dispatch(formDataSetStock('productAddModal', true));
          dispatch(formDataSetStock('activeStep', 0));
        } else {
          dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getProductCopy(Id) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');

    axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/GetProduct?Id=' + Id + '&BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          json.Id = '';
          json.ProductName = '';
          json.IsUpdate = false;
          json.Unit.map((n, i) => {
            n.Id = uuidv4();
          });
          /* json.Property.map((n, i) => {
            n.Id = uuidv4();
            n.Item.map((x, y) => {
              x.Id = uuidv4();
            })
          })
          json.Menu.map((n, i) => {
            n.Id = uuidv4();
            n.Item.map((x, y) => {
              x.Id = uuidv4();
            })
          }) */
          dispatch(formDataSetStock('data', json));
          dispatch(formDataSetStock('productAddModalTitle', 'Kopya Üründe Çalışmaktasınız'));
          dispatch(formDataSetStock('productAddModal', true));
          dispatch(formDataSetStock('activeStep', 0));
        } else {
          dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setProductDelete(Id) {
  return async (dispatch, getState) => {
    const { filterDataProduct } = getState().get('stockReducer');
    const data = {
      Id,
      TenantId: cookie.load('TenantId'),
      BrandId: cookie.load('BrandId')
    };

    axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/SetProductDelete',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data,
    })
      .then((response) => {
        if (response.data.isStatus) {
          filterDataProduct.List.splice(filterDataProduct.List.findIndex(item => item.Id === Id), 1);
          dispatch(getProductList(filterDataProduct));
        } else {
          dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
        dispatch({ type: STOCK_ERROR, payload: error });
      });
  };
}

export async function setProductAllSave() {
  return async (dispatch, getState) => {
    const { productAddAllData, filterDataProduct } = getState().get('stockReducer');

    dispatch({ type: PRODUCT_ADD_ALL_LOADING, payload: true });

    let warningText = '';
    if (productAddAllData.length == 0) {
      warningText = 'Ürün listesi üründe boş geçilemez!';
    }

    productAddAllData.map((n, i) => {
      if (n.categoryId == '00000000-0000-0000-0000-000000000000' || n.productName.length == 0) {
        warningText = 'Ürün Adı veya Kategori bilgisi boş geçilemez!';
      }

      if (n.unitList.length == 0) {
        warningText = 'Üründeki birim listesi boş geçilemez!';
      }

      n.unitList.map((x, y) => {
        if (x.unitName.length == 0 || x.normalPrice == 0 || x.packagePrice == 0) {
          warningText = 'Birim Adı veya Fiyatları bilgisi boş geçilemez!';
        }
      });
    });

    if (warningText.length > 0) {
      dispatch({ type: PRODUCT_ADD_ALL_LOADING, payload: false });
      dispatch({ type: STOCK_ERROR, payload: warningText });
      return;
    }

    const data = {
      ProductAll: productAddAllData,
      TenantId: cookie.load('TenantId'),
      BrandId: cookie.load('BrandId'),
      CenterBrandId: cookie.load('CenterBrandId')
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/setProductAllSave',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data,
    })
      .then((response) => {
        dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
        if (response.data.isStatus) {
          dispatch(setProductAddAllData([]));
          dispatch(getProductList(filterDataProduct));
        }
      })
      .catch((error) => {
        dispatch({ type: STOCK_ERROR, payload: error });
      });

    dispatch({ type: PRODUCT_ADD_ALL_LOADING, payload: false });
  };
}

export async function setPrinterGroupDelete(Id) {
  return async (dispatch, getState) => {
    const data = {
      "Id": Id,
      "TenantId": cookie.load('TenantId'),
      "BrandId": cookie.load('BrandId')
    };

    axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/setPrinterGroupDelete',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data,
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSetStock('printerGroupList', json.PrinterGroup));
          dispatch(formDataSetStock('printerList', json.PrinterList));
          dispatch(setPrinterGroupUpdate());
        } else {
          dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
        dispatch({ type: STOCK_ERROR, payload: error });
      });
  };
}

export async function setCategoryDelete(Id) {
  return async (dispatch, getState) => {
    const data = {
      Id,
      TenantId: cookie.load('TenantId'),
      BrandId: cookie.load('CenterBrandId')
    };

    axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/setCategoryDelete',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data,
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getStockGroup(true));
        } else {
          dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
        dispatch({ type: STOCK_ERROR, payload: error });
      });
  };
}

export async function setProduct() {
  return async (dispatch, getState) => {
    dispatch({ type: PRODUCTADD_SAVE_LOADING, payload: true, text: 'Ürün kayıt ediliyor..' });
    const { data } = getState().get('stockReducer').form_data;

    if (!data.IsUpdate) {
      data.Id = uuidv4();
      data.TenantId = cookie.load('TenantId');
      data.CenterBrandId = cookie.load('CenterBrandId');
      data.BrandId = cookie.load('BrandId');
    } else {
      data.CenterBrandId = cookie.load('CenterBrandId');
      data.BrandId = cookie.load('BrandId');
    }
    data.IsFranchise = window.location.pathname == "/centerbrand" ? true : false;

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/SetProduct',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data,
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSetStock('data', json));
          dispatch(getStock(true));
        }
      })
      .catch((error) => {
        dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
      });

    dispatch({ type: PRODUCTADD_SAVE_LOADING, payload: false, text: '' });
  };
}

export async function getStockGroup(status) {
  return async (dispatch, getState) => {
    const { categoryList } = getState().get('stockReducer').form_data;
    let stat = false;
    if (!status && categoryList.length == 0) {
      stat = true;
    } else if (status) {
      stat = true;
    }

    if (stat) {
      const TenantId = cookie.load('TenantId');
      const BrandId = cookie.load('CenterBrandId');

      await axios({
        method: 'GET',
        url: brand.urlSocket + 'api/Product/getStockGroup?TenantId=' + TenantId + '&BrandId=' + BrandId + '',
        dataType: 'json',
        headers: {
          Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
        },
      })
        .then((response) => {
          if (response.data.isStatus) {
            const json = JSON.parse(response.data.responseData);
            dispatch(formDataSetStock('categoryList', json));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
}

export async function getUnitRecipeControl(UnitId, data) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/getUnitRecipeControl?Id=' + UnitId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          data.Unit.filter(x => x.Id == UnitId)[0].IsDelete = true;
          dispatch(formDataSetStock('data', data));
        } else {
          dispatch({ type: STOCK_ERROR, payload: "Birime bağlı reçete bulunmaktadır. Lütfen ilk önce reçeteyi siliniz!" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getPrinterGroup() {
  return async (dispatch, getState) => {
    const TenantId = cookie.load('TenantId');
    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/getPrinterGroup?TenantId=' + TenantId + '&BrandId=' + BrandId + '',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSetStock('printerGroupList', json.PrinterGroup));
          dispatch(formDataSetStock('printerList', json.PrinterList));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getStockFirst(barcode = "") {
  return async (dispatch, getState) => {
    const { data, unit, productList } = getState().get('stockReducer').form_data;
    const { addition_data, numberButtonList } = getState().get('additionReducer').form_data;
    const { IsSeePrice } = getState().get('stockReducer');
    const TenantId = cookie.load('TenantId');
    const CenterBrandId = cookie.load('CenterBrandId');
    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/getStock?TenantId=' + TenantId + '&CenterBrandId=' + CenterBrandId + '&BrandId=' + BrandId + '&Barcode=' + barcode,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        let json = JSON.parse(response.data.responseData);

        if (response.data.isStatus) {
          json = json.filter(x => x.IsList == true);
          json = JSON.parse(response.data.responseData)[0];

          if (json.Unit.length > 1) {
            const addunit = json.Unit.find(y => y.IsSelected == true);
            if (addunit == null) {
              json.Unit[0].IsSelected = true;
            }
          }

          if (IsSeePrice) {
            dispatch({
              type: SET_SEEPRICEDATA,
              payload: "Ürün Adı : '" + json.Name + "' Fiyatı : " + json.Price.toFixed(2).replace(/[.]/, ',')
            });
            return;
          }

          json.ItemId = uuidv4();
          json.IsAddNew = true;
          json.Order = addition_data.ProductItems.length + 1;
          const count = numberButtonList.find(x => x.IsSelected == true).Id;

          json.Quantity = json.Quantity == 1 ? count : json.Quantity.toFixed(3);
          if (barcode.length > 2) {
            var indexBar = barcode.substring(0, 2);
            var barcodeType = getParameter('BarcodeType');
            if ((indexBar == "27" && !barcodeType.Is27GramStatus) || (indexBar == "28" && !barcodeType.Is28GramStatus) || (indexBar == "29" && !barcodeType.Is29GramStatus) || (indexBar == "30" && !barcodeType.Is30GramStatus) || (indexBar == "31" && !barcodeType.Is31GramStatus)) {
              json.Quantity = Number(json.Quantity).toFixed(0);
            }
          }

          addition_data.ProductItems.push(json);

          const AdditionHistoriesItem = {
            OperationType: 'Item',
            CreationTime: new Date(),
            OperationText: json.Name + ' Ürünü Eklendi.',
            UserId: cookie.load('UserId'),
            OperationDetailType: 0,
            ItemId: json.ItemId
          };

          addition_data.AdditionHistories.push(AdditionHistoriesItem);
        } else {
          if (IsSeePrice) {
            const dat = productList.find(x => x.Barcode == barcode);
            dispatch({
              type: SET_SEEPRICEDATA,
              payload: "Ürün Adı : '" + dat.Name + "' Fiyatı : " + dat.Price.toFixed(2).replace(/[.]/, ',')
            });
            return;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getStock(status, barcode = '') {
  return async (dispatch, getState) => {
    const TenantId = cookie.load('TenantId');
    const CenterBrandId = cookie.load('CenterBrandId');
    const BrandId = cookie.load('BrandId');

    let prolist = [];
    if (status) {

    } else {
      const { productList } = getState().get('stockReducer').form_data;
      prolist = productList;
    }

    let stat = false;
    if (!status && prolist.length == 0) {
      stat = true;
    } else if (status) {
      stat = true;
    }

    if (stat) {
      await axios({
        method: 'GET',
        url: brand.urlSocket + 'api/Product/getStock?TenantId=' + TenantId + '&CenterBrandId=' + CenterBrandId + '&BrandId=' + BrandId + '&Barcode=' + barcode,
        dataType: 'json',
        headers: {
          Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
        },
      })
        .then((response) => {
          const json = JSON.parse(response.data.responseData);

          const oldData = JSON.parse(JSON.stringify(json));
          if (cookie.load('UserType') == 'CallCenter') {
            json.map((n, i) => {
              n.Unit.map((x, s) => {
                x.Price = x.PackagePrice;
              });
              n.Price = n.Unit[0].PackagePrice;
            });
          }

          dispatch(formDataSetStock('productList', json.filter(x => x.IsList == true)));
          dispatch(formDataSetStock('productListSearch', json));
          dispatch(formDataSetStock('productOldCallCenterList', oldData.filter(x => x.IsList == true)));
          dispatch(formDataSetStock('productOldList', JSON.parse(JSON.stringify(json.filter(x => x.IsList == true)))));
          dispatch(formDataSetStock('productListLoad', true));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
}

export async function formDataSetStock(key, val) {
  return async (dispatch, getState) => {
    dispatch({
      type: STOCK_GLOBAL_FORM_DATA_SET,
      payload: { key, val }
    });
  };
}

export async function modalClose() {
  return async (dispatch, getState) => {
    dispatch({ type: STOCK_MODALCLOSE });
  };
}

export async function stockClear() {
  return async (dispatch, getState) => {
    const data = {
      Id: '',
      Category: '',
      ProductName: '',
      Barcode: '',
      Kdv: 8,
      Order: 1,
      IsFavorite: false,
      IsList: true,
      Color: '#2196f3',
      IsUpdate: false,
      TenantId: '',
      BrandId: '',
      Unit: [],
      Property: [],
      Menu: []
    };

    const unit = {
      Id: 'a546413a-0abb-48cc-9fff-915b6da9598a',
      UnitName: '',
      Price: 0,
      PackagePrice: 0,
      Cost: 0,
      Order: 1,
      IsDelete: false,
      IsVisible: true,
      UnitMultiplier: 1
    };

    const property = {
      Id: '',
      Name: '',
      Order: 1,
      Type: 'Radio',
      Item: [],
      IsDelete: false,
      MenuType: '0'
    };

    const propertyitem = {
      Id: '',
      Name: '',
      Price: 0,
      Default: false,
      IsDelete: false,
      StockId: '00000000-0000-0000-0000-000000000000'
    };

    const menu = {
      Id: '',
      Name: '',
      Order: 1,
      Item: [],
      IsDelete: false,
      MenuType: '1',
      IsCompulsory: true
    };

    const menuitem = {
      Id: '',
      Name: '',
      Price: 0,
      Default: false,
      IsDelete: false,
      StockId: '00000000-0000-0000-0000-000000000000'
    };

    await dispatch({
      type: STOCK_CLEAR,
      data,
      unit,
      property,
      propertyitem,
      menuitem,
      menu
    });
  };
}

export async function setCategoryListUpdate() {
  return async (dispatch, getState) => {
    dispatch({ type: STOCKCATEGORYLIST_UPDATE });
  };
}

export async function getProductList(filterDataProduct) {
  return async (dispatch, getState) => {
    dispatch({ type: PRODUCTLIST_LOADING });
    filterDataProduct.CenterBrandId = cookie.load('CenterBrandId');
    filterDataProduct.BrandId = cookie.load('BrandId');

    const aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/getProductList',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: filterDataProduct,
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: PRODUCTLIST_UPDATE,
            data: json
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
}

export async function getProductQuantityList(filterDataProductQuantity) {
  return async (dispatch, getState) => {
    dispatch({ type: PRODUCTQUANTITYLIST_LOADING });
    filterDataProductQuantity.BrandId = cookie.load('BrandId');

    const aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/getProductQuantityList',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: filterDataProductQuantity,
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: PRODUCTQUANTITYLIST_UPDATE,
            data: json
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
    dispatch({ type: PRODUCTQUANTITYLIST_LOADING });
  };
}

export async function setProductListVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      const filterDataProduct = {
        sortColumnName: 'Name',
        sortOrder: 'asc',
        totalRecord: 0,
        currentPage: 1,
        searchText: '',
        BrandId: '',
        totalPage: 0,
        List: [],
        Category: []
      };
      dispatch(getProductList(filterDataProduct));
    }
    dispatch({
      type: PRODUCTLIST_VISIBLE,
      open: visible
    });
  };
}

export async function setProductMovementListVisible(visible) {
  return async (dispatch, getState) => {
    await dispatch({
      type: PRODUCTQUANTITYADD_CLEAR
    })

    dispatch({
      type: PRODUCTMOVEMENTLIST_VISIBLE,
      open: visible
    });
  };
}

export async function setCategoryListVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getStockGroup(true));
    }
    dispatch({
      type: CATEGORYLIST_VISIBLE,
      open: visible
    });
  };
}

export async function setPrinterGroupListVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRINTERGROUPLIST_VISIBLE,
      open: visible
    });

    dispatch(setPrinterGroupNew());
  };
}

export async function setProductAddList(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PRODUCTADDLIST,
      payload: data
    });
  };
}

export async function setCategoryNew() {
  return async (dispatch, getState) => {
    const categoryadd = {
      Id: '',
      Name: '',
      Order: 1,
      Color: '#2196f3',
      TenantId: '',
      BrandId: '',
      IsUpdate: false
    };

    dispatch({
      type: CATEGORY_NEW,
      data: categoryadd
    });
  };
}

export async function setPrinterGroupNew() {
  return async (dispatch, getState) => {
    const printergroupadd = {
      Id: '',
      GroupName: '',
      PrinterName: '',
      KitPrinterName: '',
      KitchenPrinterName: '',
      PackagePrinterName: '',
      TenantId: '',
      BrandId: '',
    };

    dispatch({
      type: PRINTERGROUP_NEW,
      data: printergroupadd
    });
  };
}

export async function setCategoryUpdate() {
  return async (dispatch, getState) => {
    dispatch({ type: CATEGORYLIST_UPDATE });
  };
}

export async function setPrinterGroupUpdate() {
  return async (dispatch, getState) => {
    dispatch({ type: PRINTERGROUPLIST_UPDATE });
  };
}

export async function setProductQuantityListUpdate() {
  return async (dispatch, getState) => {
    dispatch({ type: PRODUCTQUANTITYLIST_UPDATE });
  };
}

export async function setProductQuantityListVisible(visible, Id = '00000000-0000-0000-0000-000000000000') {
  return async (dispatch, getState) => {
    if (visible) {
      const filterDataProductQuantity = {
        sortColumnName: 'CreationDatetime',
        sortOrder: 'desc',
        totalRecord: 0,
        currentPage: 1,
        rowsPerPage: 50,
        searchText: '',
        BrandId: '',
        StockId: Id,
        totalPage: 0,
        List: [],
        RemainingAmount: []
      };
      dispatch(getProductQuantityList(filterDataProductQuantity));
    }
    dispatch({
      type: PRODUCTQUANTITYLIST_VISIBLE,
      open: visible
    });
  };
}

export async function setProductRecipeUpdate(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCTRECIPELIST_UPDATE,
      data
    });
  };
}

export async function setProductRecipeListVisible(visible, Id = '00000000-0000-0000-0000-000000000000') {
  return async (dispatch, getState) => {
    if (visible) {
      dispatch(getProductRecipeList(Id));
    }
    dispatch({
      type: PRODUCTRECIPELIST_VISIBLE,
      open: visible,
      id: Id
    });
  };
}

export async function getProductRecipeList(Id) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');

    dispatch({ type: PRODUCTRECIPELIST_LOADING });

    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/getProductRecipe?Id=' + Id + '&BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: PRODUCTRECIPELIST_UPDATE,
            data: json
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
    dispatch({ type: PRODUCTRECIPELIST_LOADING });
  };
}

export async function setProductRecipe(productRecipeData) {
  return async (dispatch, getState) => {
    productRecipeData.map((n, i) => {
      n.TenantId = cookie.load('TenantId');
      n.IsFranchise = window.location.pathname == "/centerbrand" ? true : false;
      n.StockRecipeList.map((x, y) => {
        if (x.Id == '00000000-0000-0000-0000-000000000000') {
          x.Id = uuidv4();
          x.BrandId = cookie.load('BrandId');
          x.TenantId = cookie.load('TenantId');
        }
      });
    });

    axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/setProductRecipe',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: productRecipeData,
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(setProductRecipeListVisible(false));
          dispatch({ type: STOCK_ERROR, payload: 'Kayıt Edildi.' });
        } else {
          dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
      });
  };
}

export async function setProductPriceUpdate(data) {
  return async (dispatch, getState) => {

    dispatch({ type: PRODUCTLIST_LOADING });

    if (data.List.filter(x => x.IsSelected == true).length == 0) {
      dispatch({ type: STOCK_ERROR, payload: "Bir veya birden fazla ürüne fiyat girmelisiniz!" });
      return;
    }

    const priceData = {
      List: data.List,
      BrandId: cookie.load('BrandId'),
      CenterBrandId: cookie.load('CenterBrandId'),
      UserType: cookie.load('UserType')
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/setProductPrice',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: priceData
    })
      .then((response) => {
        if (response.data.isStatus) {
          data.List.map((n, i) => {
            n.IsSelected = false;
            n.Unit.map((x, y) => {
              x.IsSelected = false;
            });
          });
          dispatch({
            type: PRODUCTLIST_UPDATE,
            data
          });
          dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function productListUpdate() {
  return async (dispatch, getState) => {
    dispatch({ type: PRODUCTLISTPRICE_UPDATE });
  };
}

export async function setSaveImageProduct(dataFile, Id, type, width, height) {
  return async (dispatch, getState) => {
    dispatch({ type: PRODUCTADD_SAVE_LOADING, payload: true, text: 'Resim ölçeklendirilip kayıt ediliyor..' });
    const { data, categoryadd, property } = getState().get('stockReducer').form_data;
    const apiBaseUrl = brand.urlSocket + 'api/ThirdParty/ImageSave';
    const f = new FormData();
    f.append('files', dataFile);
    f.append('BrandId', cookie.load('BrandId'));
    f.append('CenterBrandId', cookie.load('CenterBrandId'));
    f.append('ProductId', (type == 0 || type == 2 || type == 3 || type == 4 ? Id : '00000000-0000-0000-0000-000000000000'));
    f.append('type', type);
    f.append('width', width);
    f.append('height', height);

    await axios.post(apiBaseUrl, f, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      }
    }).then((response) => {
      if (response.data.isStatus) {
        if (type == 0 || type == 1) {
          data.ImageUrl = response.data.responseData;
          var json = JSON.parse(JSON.stringify(data));
          dispatch(formDataSetStock('data', json));
        } else if (type == 2) {
          var json = response.data.responseData;
          reactLocalStorage.remove('p_string');
          reactLocalStorage.setObject('p_string', json);
          const base = base64.decode(json);
          dispatch(formDataSetSetting('Settings', JSON.parse(utf8.decode(base))));
        } else if (type == 3) {
          categoryadd.ImageUrl = response.data.responseData;
          var json = JSON.parse(JSON.stringify(categoryadd));
          dispatch(formDataSetStock('categoryadd', json));
          dispatch(setCategoryUpdate());
        }else if(type == 4){
          property.Item.find(x=>x.Id == Id).ImageUrl = response.data.responseData;
          var json = JSON.parse(JSON.stringify(property));
          dispatch(formDataSetStock('property', json));
        }
      }
    })
      .catch((error) => {

      });
    dispatch({ type: PRODUCTADD_SAVE_LOADING, payload: false, text: '' });
  };
}

export async function setProductListUpdate(json, Id, IsList, type) {
  return async (dispatch, getState) => {
    dispatch({ type: PRODUCTLIST_LOADING });

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/setProductIsList?Id=' + Id + '&IsList=' + IsList + '&BrandId=' + cookie.load('BrandId') + '&type=' + type + '&userType=' + cookie.load("UserType") + '&CenterBrandId=' + cookie.load("CenterBrandId"),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (type == 'Product') {
          json.List.find(x => x.Id == Id).IsList = IsList;
        }
        dispatch({
          type: PRODUCTLIST_UPDATE,
          data: json
        });
        dispatch(getStock(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setProductIsQrMenu(json, Id, IsList) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/setProductIsQrMenu?Id=' + Id + '&IsQrMenu=' + IsList + '&BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        json.List.find(x => x.Id == Id).IsQrMenu = IsList;
        dispatch({
          type: PRODUCTLIST_UPDATE,
          data: json
        });
        dispatch(getStock(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setProductPrinter(json, Id, PrinterId) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/setProductPrinter?Id=' + Id + '&BrandId=' + BrandId + '&PrinterId=' + PrinterId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          json.List.find(x => x.Id == Id).PrinterGroup = PrinterId;
          dispatch({
            type: PRODUCTLIST_UPDATE,
            data: json
          });
        } else {
          dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
        }
        //dispatch(getStock(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setProductIsApp(json, Id, IsApp) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/setProductIsApp?Id=' + Id + '&IsApp=' + IsApp + '&BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        json.List.find(x => x.Id == Id).IsApp = IsApp;
        dispatch({
          type: PRODUCTLIST_UPDATE,
          data: json
        });
        dispatch(getStock(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setModalStock(text) {
  return async (dispatch, getState) => {
    dispatch({
      type: STOCK_ERROR,
      payload: text
    });
  };
}

export async function setProductQuantityAddVisible() {
  return async (dispatch, getState) => {
    await dispatch(formDataSetCustomer('customerValue', ""));
    dispatch({
      type: PRODUCTQUANTITYADD_VISIBLE
    });
  };
}

export async function setProductQuantityAdd() {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCTQUANTITYADD_UPDATE
    });
  };
}


export async function setProductQuantityClear() {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCTQUANTITYADD_CLEAR
    });
  };
}

export async function setProductQuantityAddDesc(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PRODUCTQUANTITYADDDESC,
      payload: data
    });
  };
}

export async function setProductMovementUpdate(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCTMOVEMENT_UPDATE,
      payload: data
    });
  };
}

export async function setStockProperty(data) {
  return async (dispatch, getState) => {
    let warning = false;
    let warning_text = '';
    if (data.Name.length == 0) {
      warning = true;
      warning_text = 'Özellik grubunun adı boş geçilemez!';
    } else if (data.Item.filter(x => x.IsDelete == false).length == 0) {
      warning = true;
      warning_text = 'Ürün özellik grubuna özellik eklemelisiniz.';
    } else if (data.Item.filter(x => x.IsDelete == false).length > 0) {
      data.Item.filter(x => x.IsDelete == false).map((item, key) => {
        if (item.Name.length == 0) {
          warning = true;
          warning_text = data.Name + ' ürün özellik ismi boş geçilemez.';
        }
      });
    }

    if (warning) {
      dispatch({ type: STOCK_ERROR, payload: warning_text });
      return;
    }

    const Id = uuidv4();
    if (data.Id == '') {
      data.Id = Id;
      data.Item.map((n, i) => {
        n.PropertyId = Id;
      });
      data.BrandId = cookie.load('CenterBrandId');
      data.TenantId = cookie.load('TenantId');
    }

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/setStockProperty',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getStockPropertyGroupList(''));
        }
        dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getStockPropertyDetail(Property, type, data, addProduct) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/getStockPropertyDetail?StockPropertyId=' + Property.Id + '&BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const property = JSON.parse(JSON.stringify(Property));
          const prooertyItem = JSON.parse(response.data.responseData);
          property.Item = prooertyItem;
          if (addProduct) {
            if (type == 'property') {
              dispatch(formDataSetStock('property', property));
              dispatch(formDataSetStock('openProperty', true));
            } else {
              dispatch(formDataSetStock('menu', property));
              dispatch(formDataSetStock('openMenu', true));
            }
          } else {
            property.IsDelete = false;
            if (type == 'property') {
              data.Property.push(property);
            } else {
              data.Menu.push(property);
            }
            dispatch(formDataSetStock('data', data));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getStockPropertyGroupList(type) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('CenterBrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/getStockPropertyGroupList?BrandId=' + BrandId + '&type=' + type,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch({ type: PRODUCT_PROPERTY_LIST_DATA, payload: JSON.parse(response.data.responseData) });
        } else {
          dispatch({ type: PRODUCT_PROPERTY_LIST_DATA, payload: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function removeStockProperty(PropertyId, data) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/removeStockProperty?PropertyId=' + PropertyId + '&TenantId=' + cookie.load('TenantId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          data.splice(data.findIndex(item => item.Id === PropertyId), 1);
          dispatch({ type: PRODUCT_PROPERTY_LIST_DATA, payload: data });
        }
        dispatch({ type: STOCK_ERROR, payload: response.data.responseData });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setProductAllDiscount(filterDataProduct) {
  return async (dispatch, getState) => {
    const { productAllDiscountData } = getState().get('stockReducer');
    dispatch({ type: PRODUCT_ALL_DISCOUNT_LOADING });
    filterDataProduct.CenterBrandId = cookie.load('CenterBrandId');
    filterDataProduct.BrandId = cookie.load('BrandId');

    filterDataProduct.PriceType = productAllDiscountData.PriceType;
    filterDataProduct.Discount = Number(productAllDiscountData.Discount);

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/setProductAllDiscount',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: filterDataProduct
    })
      .then((response) => {
        dispatch(setProductAllDiscountVisible(false));
        dispatch(getProductList(filterDataProduct));
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch({ type: PRODUCT_ALL_DISCOUNT_LOADING });
  };
}

export async function getDocumentList(date) {
  return async (dispatch, getState) => {
    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Entegration/getDocumentList?BrandId=' + cookie.load('BrandId') + '&date=' + date,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({ type: HB_GETDOCUMENT_LIST_DATA, payload: json });
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {

      });
  };
}

export async function getDocumentFile(Uuid) {
  return async (dispatch, getState) => {
    window.open(brand.urlSocket + 'api/Entegration/getDocumentFile?BrandId=' + cookie.load('BrandId') + '&Uuid=' + Uuid, '_blank');
  };
}

export async function getDocumentFileHtml(Uuid) {
  return async (dispatch, getState) => {

    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Entegration/getDocumentFileHtml?BrandId=' + cookie.load('BrandId') + '&Uuid=' + Uuid + '&type=' + "HTML",
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(setInvoiceData(response.data.responseData));
          console.log(response.data.responseData)
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {

      });
  };
}

export async function getDocumentFileRead(Uuid) {
  return async (dispatch, getState) => {
    await dispatch(setProductQuantityClear());
    const { productQuantityAddDesc } = getState().get('stockReducer');

    const aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Entegration/getDocumentFileRead?BrandId=' + cookie.load('BrandId') + '&Uuid=' + Uuid,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {

          const json = JSON.parse(response.data.responseData);

          productQuantityAddDesc.Id = json.Id;
          productQuantityAddDesc.CustomerName = json.CustomerName;
          json.Items.map((n, i) => {
            productQuantityAddDesc.StockQuantityList.push(
              {
                Id: uuidv4(),
                StockQantityType: 0,
                IsRecipe: false,
                CreationDatetime: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
                Quantity: n.quantityValue,
                Price: n.priceValue,
                Amount: 0,
                IsNew: false,
                DiscountRate: n.discountValue,
                NetAmount: 0,
                VatStatus: n.taxStatus,
                VatRate: n.taxValue,
                VatAmount: 0,
                DiscountAmount: n.discountTotal,
                BrandId: cookie.load('BrandId'),
                TenantId: cookie.load('TenantId'),
                UserId: cookie.load('UserId'),
                Username: cookie.load('NameSurname'),
                StockName: n.nameValue,
                StockId: '00000000-0000-0000-0000-000000000000',
                UnitId: '00000000-0000-0000-0000-000000000000',
                StockQuantityHeaderId: '00000000-0000-0000-0000-000000000000',
                IsDelete: false,
                AdditionId: '00000000-0000-0000-0000-000000000000'
              }
            );
          })
          dispatch({
            type: HB_XML_FILE_GET_DATA,
            payload: json
          });

          dispatch(setProductMovementUpdate(productQuantityAddDesc));
          dispatch(setProductQuantityAddUpdate("", "", 0, true));
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {

      });
  };
}

export async function setProductQuantityAddUpdate(value, name, Id, isAll = false) {
  return async (dispatch, getState) => {
    const { productQuantityAddDesc } = getState().get('stockReducer');

    if (isAll) {
      productQuantityAddDesc.StockQuantityList.map((n, i) => {
        var data = n;
        data[name] = value;

        var discount = 0;
        var net = 0;
        var tax = 0;
        var total = 0;
        if (data.VatStatus) {
          var kdvHaricBirimFiyat = data.Price / (1 + (data.VatRate / 100.0)) * data.Quantity;
          var iskontoluBirimFiyat = kdvHaricBirimFiyat - (kdvHaricBirimFiyat * (data.DiscountRate / 100.0));
          net = iskontoluBirimFiyat * (1 + (data.VatRate / 100.0));
          tax = net - iskontoluBirimFiyat;
          discount = kdvHaricBirimFiyat - iskontoluBirimFiyat;
          total = kdvHaricBirimFiyat;

          if (data.DiscountRate == 0 && data.DiscountAmount > 0) {
            data.DiscountRate = (data.DiscountAmount / (net - tax)) * 100;

            var kdvHaricBirimFiyat = data.Price / (1 + (data.VatRate / 100.0)) * data.Quantity;
            var iskontoluBirimFiyat = kdvHaricBirimFiyat - (kdvHaricBirimFiyat * (data.DiscountRate / 100.0));
            net = iskontoluBirimFiyat * (1 + (data.VatRate / 100.0));
            tax = net - iskontoluBirimFiyat;
            discount = kdvHaricBirimFiyat - iskontoluBirimFiyat;
            total = kdvHaricBirimFiyat;
          }
        } else {
          var kdvHaricBirimFiyat = data.Price * data.Quantity;
          var iskontoluBirimFiyat = kdvHaricBirimFiyat - (kdvHaricBirimFiyat * (data.DiscountRate / 100.0));
          net = iskontoluBirimFiyat * (1 + (data.VatRate / 100.0));
          tax = net - iskontoluBirimFiyat;
          discount = kdvHaricBirimFiyat - iskontoluBirimFiyat;
          total = kdvHaricBirimFiyat;

          if (data.DiscountRate == 0 && data.DiscountAmount > 0) {
            data.DiscountRate = (data.DiscountAmount / (net - tax)) * 100;

            var kdvHaricBirimFiyat = data.Price * data.Quantity;
            var iskontoluBirimFiyat = kdvHaricBirimFiyat - (kdvHaricBirimFiyat * (data.DiscountRate / 100.0));
            net = iskontoluBirimFiyat * (1 + (data.VatRate / 100.0));
            tax = net - iskontoluBirimFiyat;
            discount = kdvHaricBirimFiyat - iskontoluBirimFiyat;
            total = kdvHaricBirimFiyat;
          }
        }

        data.Amount = parseFloat(total);
        data.NetAmount = parseFloat(net);
        data.VatAmount = parseFloat(tax);
        data.DiscountAmount = parseFloat(discount);
      })
    } else {
      var data = productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id);
      data[name] = value;

      var discount = 0;
      var net = 0;
      var tax = 0;
      var total = 0;
      if (data.VatStatus) {
        var kdvHaricBirimFiyat = data.Price / (1 + (data.VatRate / 100.0)) * data.Quantity;
        var iskontoluBirimFiyat = kdvHaricBirimFiyat - (kdvHaricBirimFiyat * (data.DiscountRate / 100.0));
        net = iskontoluBirimFiyat * (1 + (data.VatRate / 100.0));
        tax = net - iskontoluBirimFiyat;
        discount = kdvHaricBirimFiyat - iskontoluBirimFiyat;
        total = kdvHaricBirimFiyat;
      } else {
        var kdvHaricBirimFiyat = data.Price * data.Quantity;
        var iskontoluBirimFiyat = kdvHaricBirimFiyat - (kdvHaricBirimFiyat * (data.DiscountRate / 100.0));
        net = iskontoluBirimFiyat * (1 + (data.VatRate / 100.0));
        tax = net - iskontoluBirimFiyat;
        discount = kdvHaricBirimFiyat - iskontoluBirimFiyat;
        total = kdvHaricBirimFiyat;
      }

      data.Amount = parseFloat(total);
      data.NetAmount = parseFloat(net);
      data.VatAmount = parseFloat(tax);
      data.DiscountAmount = parseFloat(discount);
    }

    productQuantityAddDesc.VatTotal = 0;
    productQuantityAddDesc.Total = 0;
    productQuantityAddDesc.NetTotal = 0;
    productQuantityAddDesc.DiscountTotal = 0;
    productQuantityAddDesc.StockQuantityList.filter(x => x.IsDelete == false).map((n, i) => {
      productQuantityAddDesc.VatTotal += parseFloat(n.VatAmount);
      productQuantityAddDesc.NetTotal += parseFloat(n.Amount);
      productQuantityAddDesc.Total += parseFloat(n.NetAmount);
      productQuantityAddDesc.DiscountTotal += parseFloat(n.DiscountAmount);
    })

    productQuantityAddDesc.StockQuantityList.map((n, i) => {
      delete n[""];
    });

    dispatch(setProductQuantityAddDesc(productQuantityAddDesc))
  };
}


export async function hbCreateCustomer() {
  return async (dispatch, getState) => {
    const { hbGetXmlFileReadData } = getState().get('stockReducer');
    dispatch(setCustomerAddDataVisible(hbGetXmlFileReadData));
  };
}

export async function getDocumentListVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: HB_GETDOCUMENT_LIST_VISIBLE,
      payload: visible
    });
  };
}

export async function setProductEntegrationVisible(visible, type) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(setProductEntegrationData(type));
    }
    dispatch({
      type: PRODUCT_ENTEGRATION_VISIBLE,
      open: visible
    });
  };
}

export async function setProductPropertyListVisible(visible, type) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getStockPropertyGroupList(type));
    } else {
      await dispatch({ type: PRODUCT_PROPERTY_LIST_DATA, payload: [] });
    }
    dispatch({
      type: PRODUCT_PROPERTY_LIST_VISIBLE,
      open: visible
    });
  };
}

export async function setProductAllDiscountVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCT_ALL_DISCOUNT_VISIBLE,
      payload: visible
    });
  };
}

export async function setProductAllDiscountData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCT_ALL_DISCOUNT_DATA,
      payload: data
    });
  };
}

export async function setProductAddAllVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCT_ADD_ALL_VISIBLE,
      payload: visible
    });
  };
}

export async function setProductAddAllData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCT_ADD_ALL_DATA,
      payload: data
    });
  };
}

export async function setProductMovementFilterData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCTMOVEMENTFILTER_UPDATE,
      payload: data
    });
  };
}

export async function getStockTakingList() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Product/getStockTakingList?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          json.map((n, i) => {
            n.Difference = n.TakingQuantity - n.RemaningQuantity;
            n.IsSelected = false;
          })
          dispatch({ type: SET_STOCK_TAKING_DATA, payload: json });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setStockTakingList() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_STOCK_TAKING_LOADING, payload: true });

    const { stockTakingData } = getState().get('stockReducer');
    var data = {
      StockTakingList: stockTakingData,
      BrandId: cookie.load("BrandId"),
      TenantId: cookie.load("TenantId"),
      UserId: cookie.load("UserId"),
      UserName: cookie.load("NameSurname"),
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Product/setStockTakingList',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: data
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getStockTakingList());
        }
        dispatch(setModal(response.data.responseData));
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch({ type: SET_STOCK_TAKING_LOADING, payload: false });
  };
}

export async function setStockTakingVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getStockTakingList());
    }
    dispatch({
      type: SET_STOCK_TAKING_VISIBLE,
      payload: visible
    });
  };
}

export async function setStockTakingData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_STOCK_TAKING_DATA,
      payload: data
    });
  };
}

export async function setInvoiceData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: HB_INVOICE_DATA,
      payload: data
    });
  };
}


export async function getDocumentListUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: HB_GETDOCUMENT_LIST_UPDATE
    });
  };
}

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case HB_INVOICE_DATA:
      return { ...state, invoiceVisible: (action.payload.length == 0 ? false : true), invoiceData: action.payload, hbGetDocumentListUpdate: !state.hbGetDocumentListUpdate };
    case SET_STOCK_TAKING_LOADING:
      return { ...state, stockTakingLoading: action.payload, stockTakingUpdate: !state.stockTakingUpdate };
    case SET_STOCK_TAKING_DATA:
      return { ...state, stockTakingData: action.payload, stockTakingUpdate: !state.stockTakingUpdate };
    case SET_STOCK_TAKING_VISIBLE:
      return { ...state, stockTakingVisible: action.payload, stockTakingUpdate: !state.stockTakingUpdate };
    case HB_XML_FILE_GET_DATA:
      return { ...state, hbGetXmlFileReadData: action.payload };
    case HB_GETDOCUMENT_LIST_UPDATE:
      return { ...state, hbGetDocumentListUpdate: !state.hbGetDocumentListUpdate };
    case HB_GETDOCUMENT_LIST_VISIBLE:
      return { ...state, hbGetDocumentListVisible: action.payload, hbGetDocumentListUpdate: !state.hbGetDocumentListUpdate };
    case HB_GETDOCUMENT_LIST_DATA:
      return { ...state, hbGetDocumentList: action.payload, hbGetDocumentListUpdate: !state.hbGetDocumentListUpdate };
    case PRODUCTMOVEMENTFILTER_UPDATE:
      return {
        ...state, filterDataProductMovementFilter: action.payload, productMovementListUpdate: !state.productMovementListUpdate
      };
    case PRODUCT_ADD_ALL_VISIBLE:
      return {
        ...state, productAddAllVisible: action.payload, productAddAllUpdate: !state.productAddAllUpdate, productAddAllData: []
      };
    case PRODUCT_ADD_ALL_LOADING:
      return { ...state, productAddAllLoading: !state.productAddAllLoading, productAddAllUpdate: !state.productAddAllUpdate };
    case PRODUCT_ADD_ALL_DATA:
      return { ...state, productAddAllData: action.payload, productAddAllUpdate: !state.productAddAllUpdate };
    case PRODUCTADD_SAVE_LOADING:
      return { ...state, productAddSaveLoading: action.payload, productAddSaveText: action.text };
    case PRODUCT_ALL_DISCOUNT_LOADING:
      return { ...state, productAllDiscountLoading: !state.productAllDiscountLoading, productAllDiscountUpdate: !state.productAllDiscountUpdate };
    case PRODUCT_ALL_DISCOUNT_DATA:
      return { ...state, productAllDiscountData: action.payload, productAllDiscountUpdate: !state.productAllDiscountUpdate };
    case PRODUCT_ALL_DISCOUNT_VISIBLE:
      return {
        ...state, productAllDiscountVisible: action.payload, productAllDiscountUpdate: !state.productAllDiscountUpdate, productAllDiscountData: { PriceType: 'Normal', Discount: 1 }
      };
    case PRODUCT_PROPERTY_LIST_DATA:
      return { ...state, productPropertyListData: action.payload, productPropertyListUpdate: !state.productPropertyListUpdate };
    case PRODUCT_PROPERTY_LIST_VISIBLE:
      return { ...state, productPropertyListVisible: action.open, productPropertyListUpdate: !state.productPropertyListUpdate };
    case STOCK_START:
      return { ...state, loading: true };
    case PRODUCT_ENTEGRATION_LOADING:
      return { ...state, productEntegrationLoading: action.payload, productEntegrationUpdate: !state.productEntegrationUpdate };
    case PRODUCT_ENTEGRATION_VISIBLE:
      return { ...state, productEntegrationVisible: action.open, productEntegrationUpdate: !state.productEntegrationUpdate };
    case PRODUCTMOVEMENT_UPDATE:
      return {
        ...state,
        productQuantityAddVisible: true,
        productQuantityAddDesc: action.payload
      };
    case SET_PRODUCTQUANTITYADDDESC:
      return { ...state, productQuantityAddDesc: action.payload, productQuantityAddUpdate: !state.productQuantityAddUpdate };
    case SET_GETİRYEMEK_PRODUCT:
      return { ...state, getirProductList: action.payload };
    case SET_PRODUCTADDLIST:
      return {
        ...state,
        productAddList: action.payload,
        productQuantityAdd: {
          Id: uuidv4(),
          StockQantityType: 0,
          IsRecipe: false,
          CreationDatetime: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
          Quantity: 1,
          Price: 0,
          Amount: 0,
          DiscountRate: 0,
          NetAmount: 0,
          VatStatus: false,
          VatRate: 0,
          VatAmount: 0,
          IsNew: false,
          DiscountAmount: 0,
          BrandId: cookie.load('BrandId'),
          TenantId: cookie.load('TenantId'),
          UserId: cookie.load('UserId'),
          Username: cookie.load('NameSurname'),
          StockId: '00000000-0000-0000-0000-000000000000',
          UnitId: '00000000-0000-0000-0000-000000000000',
          SecondQuantity: 1,
          SecondUnitId: '00000000-0000-0000-0000-000000000000',
          StockQuantityHeaderId: '00000000-0000-0000-0000-000000000000',
          IsDelete: false,
          AdditionId: '00000000-0000-0000-0000-000000000000'
        },
        productQuantityAddUpdate: !state.productQuantityAddUpdate,
        form_data: {
          ...state.form_data,
          MenuItemSearchText: '',
          MenuItemStockName: '',
          MenuItemStockId: '',
        }
      };
    case PRODUCTQUANTITY_GET:
      return {
        ...state, productQuantityAddVisible: !state.productQuantityAddVisible, productQuantityAdd: action.payload, productQuantityUpdate: true
      };
    case PRODUCT_AUTOCOMPLETE_SET:
      return { ...state, productAutoCompleteList: action.payload };
    case PRODUCTQUANTITYADD_CLEAR:
      return {
        ...state,
        productQuantityAdd: {
          Id: uuidv4(),
          StockQantityType: 0,
          IsRecipe: false,
          CreationDatetime: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
          Quantity: 1,
          Price: 0,
          Amount: 0,
          DiscountRate: 0,
          NetAmount: 0,
          VatStatus: false,
          IsNew: false,
          VatRate: 0,
          VatAmount: 0,
          DiscountAmount: 0,
          BrandId: cookie.load('BrandId'),
          TenantId: cookie.load('TenantId'),
          UserId: cookie.load('UserId'),
          Username: cookie.load('NameSurname'),
          StockId: '00000000-0000-0000-0000-000000000000',
          UnitId: '00000000-0000-0000-0000-000000000000',
          SecondQuantity: 1,
          SecondUnitId: '00000000-0000-0000-0000-000000000000',
          StockQuantityHeaderId: '00000000-0000-0000-0000-000000000000',
          IsDelete: false,
          AdditionId: '00000000-0000-0000-0000-000000000000'
        },
        productQuantityAddUpdate: !state.productQuantityAddUpdate,
        productQuantityAddDesc: {
          Id: uuidv4(),
          Explanation: '',
          CreationDatetime: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
          ReceiptNumber: MakeRandomKey(8),
          GrossTotal: 0,
          DiscountTotal: 0,
          NetTotal: 0,
          VatTotal: 0,
          Total: 0,
          UserId: cookie.load('UserId'),
          Username: cookie.load('NameSurname'),
          BrandId: cookie.load('BrandId'),
          TenantId: cookie.load('TenantId'),
          CustomerId: '',
          CustomerName: '',
          IsDelete: false,
          StockQuantityList: [],
          ClosedStatus: false,
          ClosedPaymentId: '00000000-0000-0000-0000-000000000000',
          ClosedPaymentName: '',
          ClosedDate: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm")
        }
      };
    case PRODUCTQUANTITYADD_UPDATE:
      return { ...state, productQuantityAdd: state.productQuantityAdd, productQuantityAddUpdate: !state.productQuantityAddUpdate };
    case PRODUCTQUANTITYADD_VISIBLE:
      return {
        ...state,
        productAddList: [],
        productQuantityUpdate: false,
        productQuantityAddVisible: !state.productQuantityAddVisible,
        productQuantityAdd: (!state.productQuantityAddVisible == true
          ? {
            Id: uuidv4(),
            StockQantityType: 0,
            IsRecipe: false,
            CreationDatetime: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
            Quantity: 1,
            Price: 0,
            Amount: 0,
            DiscountRate: 0,
            NetAmount: 0,
            VatStatus: false,
            VatRate: 0,
            IsNew: false,
            VatAmount: 0,
            DiscountAmount: 0,
            BrandId: cookie.load('BrandId'),
            TenantId: cookie.load('TenantId'),
            UserId: cookie.load('UserId'),
            Username: cookie.load('NameSurname'),
            StockId: '00000000-0000-0000-0000-000000000000',
            UnitId: '00000000-0000-0000-0000-000000000000',
            SecondQuantity: 1,
            SecondUnitId: '00000000-0000-0000-0000-000000000000',
            StockQuantityHeaderId: '00000000-0000-0000-0000-000000000000',
            IsDelete: false,
            AdditionId: '00000000-0000-0000-0000-000000000000'
          } : state.productQuantityAdd),
        form_data: {
          ...state.form_data,
          MenuItemSearchText: '',
          MenuItemStockName: '',
          MenuItemStockId: '',
        },
        productQuantityAddDesc: (!state.productQuantityAddVisible == true
          ? {
            Id: uuidv4(),
            Explanation: '',
            CreationDatetime: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
            ReceiptNumber: MakeRandomKey(8),
            GrossTotal: 0,
            DiscountTotal: 0,
            NetTotal: 0,
            VatTotal: 0,
            Total: 0,
            UserId: cookie.load('UserId'),
            Username: cookie.load('NameSurname'),
            BrandId: cookie.load('BrandId'),
            TenantId: cookie.load('TenantId'),
            CustomerId: '',
            CustomerName: '',
            IsDelete: false,
            StockQuantityList: [],
            ClosedStatus: false,
            ClosedPaymentId: '00000000-0000-0000-0000-000000000000',
            ClosedPaymentName: '',
            ClosedDate: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm")
          } : state.productQuantityAddDesc)
      };
    case STOCK_ERROR:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          modalOpen: true,
          modalText: action.payload,
          modalTitle: 'Uyarı'
        }
      };
    case STOCK_STOP:
      return { ...state, loading: false, data: action.payload };
    case SET_SEEPRICEDATA:
      return { ...state, seePriceData: action.payload };
    case STOCK_CLEAR:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          data: action.data,
          unit: action.unit,
          property: action.property,
          propertyitem: action.propertyitem,
          menuitem: action.menuitem,
          menu: action.menu
        }
      };
    case PRODUCTLIST_UPDATE:
      return {
        ...state, filterDataProduct: action.data, productListUpdate: !state.productListUpdate, productListLoading: false
      };
    case PRODUCTLISTPRICE_UPDATE:
      return { ...state, productListUpdate: !state.productListUpdate };
    case CATEGORYLIST_UPDATE:
      return { ...state, categoryListUpdate: !state.categoryListUpdate };
    case PRINTERGROUPLIST_UPDATE:
      return { ...state, printerGroupListUpdate: !state.printerGroupListUpdate };
    case PRODUCTQUANTITYLIST_UPDATE:
      return { ...state, filterDataProductQuantity: action.data, productQuantityListUpdate: !state.productQuantityListUpdate };
    case PRODUCTRECIPELIST_UPDATE:
      return { ...state, productRecipeData: action.data, productRecipeListUpdate: !state.productRecipeListUpdate };
    case PRODUCTLIST_LOADING:
      return { ...state, productListLoading: !state.productListLoading };
    case PRODUCTQUANTITYLIST_LOADING:
      return { ...state, productQuantityListLoading: !state.productQuantityListLoading };
    case PRODUCTRECIPELIST_LOADING:
      return { ...state, productRecipeListLoading: !state.productRecipeListLoading };
    case PRODUCTLIST_VISIBLE:
      return { ...state, productListVisible: action.open };
    case CATEGORYLIST_VISIBLE:
      return {
        ...state, categoryListVisible: action.open,
        form_data: {
          ...state.form_data,
          categoryadd: { "Id": "", "Name": "", "Order": 1, "Color": "#2196f3", "IsUpdate": false }
        }
      };
    case PRODUCTMOVEMENTLIST_VISIBLE:
      return {
        ...state, productMovementListVisible: action.open
      };
    case PRODUCTMOVEMENTLIST_UPDATE:
      return { ...state, filterDataProductMovementList: action.data, productMovementListUpdate: !state.productMovementListUpdate };
    case PRODUCTMOVEMENTLIST_LOADING:
      return { ...state, productMovementListLoading: !state.productMovementListLoading, productMovementListUpdate: !state.productMovementListUpdate };
    case PRINTERGROUPLIST_VISIBLE:
      return { ...state, printerGroupListVisible: action.open };
    case PRODUCTQUANTITYLIST_VISIBLE:
      return { ...state, productQuantityListVisible: action.open };
    case PRODUCTRECIPELIST_VISIBLE:
      return { ...state, productRecipeListVisible: action.open, selectedProductRecipeId: action.id };
    case CATEGORY_NEW:
      return {
        ...state,
        categoryListUpdate: !state.categoryListUpdate,
        form_data: {
          ...state.form_data,
          categoryadd: action.data
        }
      };
    case PRINTERGROUP_NEW:
      return {
        ...state,
        printerGroupListUpdate: !state.printerGroupListUpdate,
        form_data: {
          ...state.form_data,
          printergroupadd: action.data
        }
      };
    case STOCKCATEGORYLIST_UPDATE:
      return { ...state, categoryListUpdate: !state.categoryListUpdate };
    case STOCK_GLOBAL_FORM_DATA_SET:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          [action.payload.key]: action.payload.val
        }
      };
    case STOCK_MODALCLOSE:
      return { ...state, modalOpen: false };
    default:
      return state;
  }
};

export default stockReducer;
