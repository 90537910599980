import React from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import styles from '../../Settings/settings-jss';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { setProductQuantityListVisible, ProductQuantityDelete, ProductQuantityGet } from '../../../../redux/modules/stock';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import TablePagination from '@material-ui/core/TablePagination';
import TbPaginationActions from '../../Ortak/TbPaginationActions';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import { EmptyData } from 'dan-components';
import DatePicker, { registerLocale } from "react-datepicker";
import "../datepicker.css";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import ForwardIcon from '@material-ui/icons/Forward';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class ProductQuantityList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { searchValue: "", startDate: new Date(new Date().setHours(0, 0, 0, 0)), endDate: new Date(new Date().setHours(23, 59, 59, 999)) }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentDidMount() {

    }

    handleChangePage = (event, page) => {
        const { filterDataProductQuantity } = this.props;
        filterDataProductQuantity.searchText = this.state.searchValue;
        filterDataProductQuantity.startDate = this.state.startDate;
        filterDataProductQuantity.endDate = this.state.endDate;
        filterDataProductQuantity.currentPage = page + 1;
        this.props.getProductQuantityList(filterDataProductQuantity).then(() => { })
    };

    handleChangeRowsPerPage = event => {
        const { filterDataProductQuantity } = this.props;
        filterDataProductQuantity.searchText = this.state.searchValue;
        filterDataProductQuantity.startDate = this.state.startDate;
        filterDataProductQuantity.endDate = this.state.endDate;
        filterDataProductQuantity.rowsPerPage = event.target.value;
        this.props.getProductQuantityList(filterDataProductQuantity).then(() => { })
    };

    handleSearch = () => {
        const { filterDataProductQuantity } = this.props;
        filterDataProductQuantity.startDate = this.state.startDate;
        filterDataProductQuantity.endDate = this.state.endDate;
        filterDataProductQuantity.searchText = this.state.searchValue;
        this.props.getProductQuantityList(filterDataProductQuantity).then(() => { })
    };

    handleSort(sortColumnName) {
        const { filterDataProductQuantity } = this.props;
        if (filterDataProductQuantity.sortColumnName == sortColumnName) {
            if (filterDataProductQuantity.sortOrder == "asc") {
                filterDataProductQuantity.sortOrder = "desc";
            } else if (filterDataProductQuantity.sortOrder == "desc") {
                filterDataProductQuantity.sortOrder = "asc";
            }
        } else {
            filterDataProductQuantity.sortOrder = "asc";
        }
        filterDataProductQuantity.searchText = this.state.searchValue;
        filterDataProductQuantity.startDate = this.state.startDate;
        filterDataProductQuantity.endDate = this.state.endDate;
        filterDataProductQuantity.sortColumnName = sortColumnName;
        this.props.getProductQuantityList(filterDataProductQuantity).then(() => { })
    };

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.productQuantityListVisible}
                fullWidth={true}
                maxWidth={'md'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Ürün Giriş Çıkış Listesi"}
                </DialogTitle>
                <DialogContent style={{ height: '100vh' }}>
                    <div className={classes.detailWrap} style={{ backgroundColor: 'rgb(48, 48, 48)' }}>
                        <Toolbar style={{ padding: 8, marginBottom: 30, minHeight: 48, width: '100%', display: 'flex', backgroundColor: 'rgb(90, 96, 101)', borderRadius: 5 }}>
                            <FormControl style={{ width: 225 }} className={classNames(classes.textField)}>
                                <Input
                                    id="search_filter"
                                    type="text"
                                    placeholder={"Arama"}
                                    value={this.state.searchValue}
                                    onChange={(event) => this.setState({ searchValue: event.target.value })}
                                />
                            </FormControl>
                            <Button onClick={this.handleSearch} style={{ borderRadius: 3, marginLeft: 5 }} variant="contained" color="primary">
                                {"Ara"}
                                {(this.props.productQuantityListLoading ? <CircularProgress style={{ color: 'white', marginLeft: 10 }} disableShrink thickness={3} size={15} /> : null)}
                            </Button>
                        </Toolbar>
                        <Table>
                            <TableHead style={{ backgroundColor: '#212121' }}>
                                <TableRow>
                                    <TableCell style={{ color: 'white', fontSize: 13, width: 30 }} align="left">{"Birim Adı"}</TableCell>
                                    <TableCell style={{ color: 'white', fontSize: 13, width: 30 }} align="right">{"Kalan Miktar"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.props.filterDataProductQuantity.RemainingAmount.map((n, i) => {
                                        return (
                                            <TableRow hover key={i}>
                                                <TableCell align="left">{n.UnitName}</TableCell>
                                                <TableCell align="right">{n.RemainingQuantity}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>

                        <Table>
                            <TableHead style={{ backgroundColor: '#212121' }}>
                                <TableRow>
                                    <TableCell style={{ color: 'white', fontSize: 13, width: 30 }} align="left">{"Kullanıcı"}</TableCell>
                                    <TableCell style={{ color: 'white', fontSize: 13, width: 30 }} align="left">{"Miktar"}</TableCell>
                                    <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Tipi"}</TableCell>
                                    <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Tarihi"}</TableCell>
                                    <TableCell style={{ color: 'white', fontSize: 13 }} align="right">{"İşlem"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.props.filterDataProductQuantity.List.map((n, i) => {
                                        return (
                                            <TableRow hover key={i}>
                                                <TableCell align="left">{n.Username}</TableCell>
                                                <TableCell align="left">{n.Quantity}</TableCell>
                                                <TableCell align="left">{(n.StockQantityType == 0 ? <ForwardIcon style={{ color: '#4CAF50', transform: 'rotate(270deg)' }} /> : (n.StockQantityType == 1 ? <ForwardIcon style={{ color: '#F44336', transform: 'rotate(90deg)' }} /> : <FiberManualRecordIcon style={{ color: '#FFC107' }} />))}</TableCell>
                                                <TableCell align="left">{moment(n.CreationDatetime).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Giriş Çıkış Sil">
                                                        <IconButton style={{ backgroundColor: '#3e3e3e' }} onClick={() => (confirm('İşlem haraketini silmek istediğinize emin misiniz?') ? this.props.ProductQuantityDelete(n.Id, n.StockId).then(() => { }) : null)}>
                                                            <Icon>{"delete_outline"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                        {
                            (this.props.filterDataProductQuantity.List.length == 0 ? <EmptyData text={"Giriş Çıkış Kaydı Bulunamadı"} /> : null)
                        }
                        {(this.props.productQuantityListLoading ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
                        <div style={{ width: '100%', display: 'table' }}>
                            <TablePagination
                                labelRowsPerPage={"Sayfa başına satır sayısı"}
                                rowsPerPageOptions={[25, 50, 100]}
                                rowsPerPage={this.props.filterDataProductQuantity.rowsPerPage}
                                count={this.props.filterDataProductQuantity.totalRecord}
                                page={this.props.filterDataProductQuantity.currentPage - 1}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TbPaginationActions}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }} onClick={() => this.props.setProductQuantityListVisible(false).then(() => { })} color="primary" autoFocus>
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('stockReducer').productQuantityListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    productQuantityListUpdate: value(state, props),
    productQuantityListVisible: state.get('stockReducer').productQuantityListVisible,
    productQuantityListLoading: state.get('stockReducer').productQuantityListLoading,
    filterDataProductQuantity: state.get('stockReducer').filterDataProductQuantity,
})

const mapDispatchToProps = {
    setProductQuantityListVisible,
    ProductQuantityDelete,
    ProductQuantityGet
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ProductQuantityList)));