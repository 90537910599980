import axios from 'axios';
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';
import { getParameter, isEmpty, NotDurNotification } from '../../function/GeneralFunction';
import {
  getAddressList
} from './addition';
import {
  setAnchorEntegration,
  setModalPaketYolda
} from './paketyolda';
import {
  getUser
} from './user';
const base64 = require('base-64');
const uuidv4 = require('uuid/v4');

export const SETTINGS_START = 'settings_start';
export const SETTINGS_STOP = 'settings_stop';
export const SETTINGS_ERROR = 'settings_error';
export const SET_GLOBAL_FORM_DATA_SET = 'set_global_form_data_set';
export const SETTINGS_MODALCLOSE = 'settings_modalclose';

export const SETTINGS_UPDATE = 'settings_update';

export const PRINT_VISIBLE = 'print_visible';
export const PRINT_UPDATE = 'print_update';

export const TELEGRAM_CHAT = 'telegram_chat';
export const PAKETYOLDA_VISIBLE = 'paketyolda_visible';
export const PAKETYOLDA_UPDATE = 'paketyolda_update';

export const DOWNLOADQRCODE_LOADING = 'downloadqrcode_loading';
export const ENTEGRATION_STATUS_LOADING = 'entegration_status_loading';
export const SOUND_CONTROL = 'sound_control';

export const TRENDYOL_CLAIMS_DATA = 'trendyol_claims_data';
export const TRENDYOL_CLAIMS_LOADING = 'trendyol_claims_loading';
export const TRENDYOL_CLAIMS_VISIBLE = 'trendyol_claims_visible';
export const TRENDYOL_CLAIMS_UPDATE = 'trendyol_claims_update';

export const SET_ORDER_MENU_TAB = 'set_order_menu_tab';
export const SET_ENT_PRO_SELECT_PLATFORM = 'set_ent_pro_select_platform';
export const SET_ENT_PRO_SELECT_PLATFORM_REST = 'set_ent_pro_select_platform_rest';

export const PRODUCT_ENTEGRATION_DATA = 'product_entegration_data';
export const ENTEGRATION_LOAD_DATA_LOADING = 'entegration_load_data_loading';

export const PAYCELL_VISIBLE = 'paycell_visible';
export const PAYCELL_UPDATE = 'paycell_update';
export const PAYCELL_POS_LIST_DATA = 'paycell_pos_list_data';

export const NOTIFICATION_UPDATE = 'notification_update';
export const NOTIFICATION_LISTUPDATE = 'notification_listupdate';



export const INGENICO_VISIBLE = 'ingenico_visible';
export const INGENICO_UPDATE = 'ingenico_update';

export const GETIR_WEBHOOK_CONTROL = 'getir_webhook_control';
export const JAVIAPP_STATE_STATUS = 'javiapp_state_status';

const initialState = {
  form_data: {
    modalOpen: false,
    modalText: '',
    modalTitle: '',
    OperationSettingsModalOpen: false,
    OperationSettingsModalTitle: '',
    IntegrationModalOpen: false,
    IntegrationModalTitle: '',
    ReportListModalOpen: false,
    ReportListModalTitle: '',
    TableModalOpen: false,
    TableModalTitle: '',
    LicenceModalOpen: false,
    LicenceModalTitle: '',
    PaymentTypesList: [],
    Settings: [],

    SelfSales: false,
    CallerId: false,

    // Market Ayarları
    IsScales: false,
    IsCustomerScreen: false,
    IsBarcodeSystem: false,

    // Parametreler
    ReturnTables: false,
    PackagePrint: false,

    MigrosYemekRestaurantList: [],
    GetirYemekRestaurantList: null,
    GoFodyRestaurantList: null,

    YemekSepeti: [],
    OrderControlModal: false,
    YemekSepetiOrderId: [],
    YemekSepetiRestaurantListGlobal: [],
    TrendyolYemekRestaurantList: null,

    CancelledText: '',
    CancelledTextModal: false,

    Status: '',
    OrderId: '',
    MessageId: '',

    Lat: '37.7387013',
    Lng: '29.092491',

    YemekSepetiRestaurantVendorList: [],
  },

  IsSettingsUpdate: false,

  IsPrintVisible: false,
  IsPrintUpdate: false,
  printData: [],
  printerList: [],
  sectionPrinterList: [],
  entegrasyonPrinterList: [],
  userPrinterList: [],
  telegramChat: [],
  paketYoldaUpdate: false,
  paketYoldaVisible: false,
  settingsLoading: false,

  downloadQrCodeLoading: false,
  entegrationStatusLoading: false,
  IsSoundControl: true,

  trendyolClaimsData: [],
  trendyolClaimsLoading: false,
  trendyolClaimsVisible: false,
  trendyolClaimsUpdate: false,

  orderMenuTab: 0,
  IsEntegrationProductUpdate: false,
  entProSelectPlatform: '',
  entProSelectPlatformRest: '',
  entProSelectPlatformStoreId: '',


  entegrationLoadDataLoading: false,
  productEntegrationLoading: false,
  productEntegrationData: [],

  IsPaycellUpdate: false,
  IsPaycellVisible: false,
  PaycellPosListData: [],

  IsNotificationUpdate: false,
  IsNotificationVisible: false,
  NotificationList: [],


  IsIngenicoUpdate: false,
  IsIngenicoVisible: false,
  getirWebHookControl: null,
  IsAppOpen: false,
};



//Paycell Start
export async function getPaycellPosList(BrandId = cookie.load('BrandId'), IsPaketYolda = false) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: (IsPaketYolda ? brand.urlSocketPaketYolda : brand.urlSocket) + 'api/Entegration/getPaycellPosList?BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          dispatch({ type: PAYCELL_POS_LIST_DATA, payload: jsonData });
        }
      })
      .catch((error) => {
      });
  };
}

export async function setPaycellUserSerialNo(users) {
  return async (dispatch, getState) => {
    var userlist = [];
    users.map((n, i) => {
      if (n.PaycellSerialNo.length > 0) {
        userlist.push({ "UserId": n.Id, "serial_no": n.PaycellSerialNo });
      }
    })
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/User/setPaycellUserSerialNo',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: userlist
    })
      .then((response) => {
      })
      .catch((error) => {
      });
  };
}
//Paycell End


export async function setIngenicoUser(users) {
  return async (dispatch, getState) => {
    var userlist = [];
    users.map((n, i) => {
      userlist.push({ "UserId": n.Id, "IsIngenico": n.IsIngenico });
    })
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/User/setIngenicoUser',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: userlist
    })
      .then((response) => {
      })
      .catch((error) => {
      });
  };
}

const sleep = m => new Promise(r => setTimeout(r, m));

export async function setRestaurantStatusTrendyolYemek(restaurantid, status, i) {
  return async (dispatch, getState) => {
    const { TrendyolYemekRestaurantList } = getState().get('settingsReducer').form_data;

    dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: true });
    const BrandId = cookie.load('BrandId');
    const trendyolyemek = getParameter('TrendyolYemek');
    const propList = trendyolyemek.propList[i];

    const data = {
      restaurantid: restaurantid,
      status: status,
      trendyolyemek: propList,
      BrandId: BrandId,
      UserId: cookie.load('UserId'),
      name: TrendyolYemekRestaurantList.find(x => x.id == restaurantid).name
    };

    const responseData = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/ThirdParty/setRestaurantStatusTrendyolYemek',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data
    })
      .then((response) => response.data.isStatus)
      .catch((error) => false);

    if (responseData) {
      (async () => {
        await sleep(5000);
        await dispatch(getEntegrationRestList(false, true, false, false, false));
        dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: false });
      })();
    } else {
      dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: false });
    }
  };
}

export async function setCreateGetirProduct(GetirYemekRestaurantList) {
  return async (dispatch, getState) => {
    dispatch({ type: SETTINGS_START });
    const getiryemek = getParameter('GetirYemek');

    const list = [];
    GetirYemekRestaurantList.map((n, i) => {
      if (n.IsSelected == true) {
        list.push(n.secretkey);
      }
    });

    const data = {
      secretKey: list,
      BrandId: cookie.load('BrandId'),
      CenterBrandId: cookie.load('CenterBrandId'),
      TenantId: cookie.load('TenantId'),
    };

    const aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/ThirdParty/setCreateGetirProduct',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data,
    })
      .then((response) => {
        dispatch({ type: SETTINGS_ERROR, payload: response.data.responseData });
      })
      .catch((error) => {
        dispatch({ type: SETTINGS_ERROR, payload: 'Hata oluştu. Lütfen JaviPos teknik ekibi ile irtibata geçiniz!' });
      });
    dispatch({ type: SETTINGS_START });
  };
}

function getOrderControlGetirYemek(GetirYemekRestaurantList) {

  const getiryemek = getParameter('GetirYemek');
  var secretKeyList = [];
  getiryemek.getirRestaurantSecretKey.map((x, i) => {
    if (GetirYemekRestaurantList.filter(y => y.EntegrationType == 1 && y.secretkey == x).length == 0) {
      secretKeyList.push(x);
    }
  });
  const dataGetirYemek = {
    GetirSecretKey: secretKeyList,
    TenantId: cookie.load('TenantId'),
    BrandId: cookie.load('BrandId'),
    CenterBrandId: cookie.load('CenterBrandId'),
    RestName: cookie.load('RestaurantName'),
    IsGetirYemek: getiryemek.IsStatus,
    GetirYemekConfirmation: getiryemek.WebApiOrderConfirmation,
  };

  const config = {
    method: 'POST',
    url: brand.urlSocket + 'api/ThirdParty/getirYemekOrderControl',
    headers: {
      'X-ClientId': cookie.load('BrandId'),
      Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      'Content-Type': 'application/json'
    },
    maxRedirects: 0,
    data: dataGetirYemek
  };
  return axios(config);
}

function getOrderControlTrendyolYemek() {
  const trendyolyemek = getParameter('TrendyolYemek');
  const dataTrendyolYemek = {
    TenantId: cookie.load('TenantId'),
    BrandId: cookie.load('BrandId'),
    CenterBrandId: cookie.load('CenterBrandId'),
    RestName: cookie.load('RestaurantName'),
    IsTrendyolYemek: trendyolyemek.IsStatus,
    TrendyolYemekConfirmation: trendyolyemek.WebApiOrderConfirmation,
    supplierId: trendyolyemek.supplierId,
    apiKey: trendyolyemek.apiKey,
    apiSecretKey: trendyolyemek.apiSecretKey,
    TrendyolYemekList: trendyolyemek.propList,
  };

  const config = {
    method: 'POST',
    url: brand.urlSocket + 'api/ThirdParty/trendyolYemekOrderControl',
    headers: {
      'X-ClientId': cookie.load('BrandId'),
      Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      'Content-Type': 'application/json'
    },
    maxRedirects: 0,
    data: dataTrendyolYemek
  };
  return axios(config);
}

function getOrderControlGoFody() {
  const gofody = getParameter('GoFody');
  const dataGoFody = {
    TenantId: cookie.load('TenantId'),
    BrandId: cookie.load('BrandId'),
    CenterBrandId: cookie.load('CenterBrandId'),
    RestName: cookie.load('RestaurantName'),
    IsGoFody: gofody.IsStatus,
    GoFodySecretKey: gofody.goFodyRestaurantSecretKey,
    GoFodyConfirmation: gofody.WebApiOrderConfirmation
  };

  const config = {
    method: 'POST',
    url: brand.urlSocket + 'api/ThirdParty/goFodyOrderControl',
    headers: {
      'X-ClientId': cookie.load('BrandId'),
      Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      'Content-Type': 'application/json'
    },
    maxRedirects: 0,
    data: dataGoFody
  };
  return axios(config);
}

function getOrderControlMigrosYemek(MigrosYemekRestaurantList) {
  const migrosyemek = getParameter('MigrosYemek');

  const migrosList = [];
  MigrosYemekRestaurantList.map((n, i) => {
    const listAdd = { ApiKey: n.ApiKey, storeIds: [] };
    n.mYemekRestaurants.map((x, y) => {
      listAdd.storeIds.push(x.storeId);
    });
    migrosList.push(listAdd);
  });

  const dataMigrosYemek = {
    TenantId: cookie.load('TenantId'),
    BrandId: cookie.load('BrandId'),
    CenterBrandId: cookie.load('CenterBrandId'),
    RestName: cookie.load('RestaurantName'),
    IsMigrosYemek: migrosyemek.IsStatus,
    MigrosYemekConfirmation: migrosyemek.WebApiOrderConfirmation,
    MigrosYemekList: migrosList
  };

  const config = {
    method: 'POST',
    url: brand.urlSocket + 'api/ThirdParty/migrosYemekOrderControl',
    headers: {
      'X-ClientId': cookie.load('BrandId'),
      Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      'Content-Type': 'application/json'
    },
    maxRedirects: 0,
    data: dataMigrosYemek
  };
  return axios(config);
}

export async function getThirdPartyControl() {
  return async (dispatch, getState) => {
    const { packageFilter } = getState().get('additionReducer');
    const { MigrosYemekRestaurantList, GetirYemekRestaurantList } = getState().get('settingsReducer').form_data;

    const getiryemek = getParameter('GetirYemek');
    const trendyolyemek = getParameter('TrendyolYemek');
    const gofody = getParameter('GoFody');
    const migrosyemek = getParameter('MigrosYemek');
    const javikurye = getParameter('JaviKurye');

    const requestList = [];
    if (getiryemek.IsStatus) {
      requestList.push(getOrderControlGetirYemek(GetirYemekRestaurantList));
    }
    if (trendyolyemek.IsStatus) {
      requestList.push(getOrderControlTrendyolYemek());
    }
    if (gofody.IsStatus) {
      requestList.push(getOrderControlGoFody());
    }
    if (migrosyemek.IsStatus) {
      requestList.push(getOrderControlMigrosYemek(MigrosYemekRestaurantList));
    }
    if (javikurye.IsAutomaticRouting) {
      requestList.push(getJaviKuryeControl());
    }

    await Promise.all(requestList).then((results) => { });

    if (getParameter("PackagingTime") > 0 && getParameter("IsKitchenScreen")) {
      var IsNotifi = false;
      packageFilter.List.filter(x => x.PackageStatus == 1 || x.PackageStatus == 2 || (x.IsEntegrationDelivery == true && x.PackageStatus == 3) || x.PackageStatus == 6 || x.PackageStatus == 7).map((n, i) => {
        const minutes = moment.duration(moment(new Date()).diff(moment(n.CreationTime))).asMinutes().toFixed(0);
        if (minutes > n.MaxPreparationTime) {
          IsNotifi = true;
        }
      })
      if (IsNotifi) {
        NotDurNotification("Yola Çıkmayı Bekleyen Paket", "Yola çıkmayı bekleyen süresi dolan paketleriniz bulunmaktadır!", "top-right", 30000);
      }
    }
  };
}

export async function getJaviKuryeControl() {
  return async (dispatch, getState) => {

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Courier/automaticAssignmentControl?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {

      })
      .catch((error) => {
      });
  };
}

export async function getTrendyolClaimsList(isVisible) {
  return async (dispatch, getState) => {
    dispatch({ type: TRENDYOL_CLAIMS_LOADING, payload: true });

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getTrendyolClaimsList?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          dispatch({ type: TRENDYOL_CLAIMS_DATA, payload: jsonData });
          if (isVisible && jsonData.length > 0) {
            dispatch({ type: TRENDYOL_CLAIMS_VISIBLE, payload: true });
          }
        }
      })
      .catch((error) => {
      });

    dispatch({ type: TRENDYOL_CLAIMS_LOADING, payload: false });
  };
}

export async function getTrendyolClaimsStatus(id, IsAccept, reasonId, note) {
  return async (dispatch, getState) => {
    const { trendyolClaimsData } = getState().get('settingsReducer');

    const item = trendyolClaimsData.find(x => x.id == id);
    const ids = [];
    item.claimItems.map((n, i) => {
      ids.push(n.id);
    });

    const data = {
      claimId: item.id,
      IsAccept,
      claimItemIds: ids,
      reasonId,
      note,
      supplierID: item.supplierID,
      apiKey: item.apiKey,
      secretKey: item.secretKey
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/ThirdParty/getTrendyolClaimsStatus',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data,
    })
      .then((response) => {
        if (response.data.isStatus) {
          trendyolClaimsData.splice(trendyolClaimsData.findIndex(item => item.id === id), 1);
          dispatch({ type: TRENDYOL_CLAIMS_DATA, payload: trendyolClaimsData });
        }
      })
      .catch((error) => {
      });
  };
}

export async function setLocation() {
  return async (dispatch, getState) => {
    const { Lat, Lng } = getState().get('settingsReducer').form_data;
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Settings/setLocation?BrandId=' + cookie.load('BrandId') + '&Latitude=' + Lat + '&Longitude=' + Lng,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {

      })
      .catch((error) => {
      });
  };
}

export async function getLocation() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Settings/getLocation?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        const json = JSON.parse(response.data.responseData);
        dispatch(formDataSetSetting('Lat', json.Lat));
        dispatch(formDataSetSetting('Lng', json.Lng));
      })
      .catch((error) => {
      });
  };
}

export async function getGoFodyRestaurantStatus(status, i, name, id) {
  return async (dispatch, getState) => {
    dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: true });
    const gofody = getParameter('GoFody');
    const secretKey = gofody.goFodyRestaurantSecretKey[0];

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/goFodyRestaurantStatus?secretkey=' + secretKey + '&status=' + status + '&BrandId=' + cookie.load('BrandId') + '&name=' + name + '&rest_id=' + id + '&UserId=' + cookie.load('UserId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getEntegrationRestList(false, false, false, true, false));
        }
      })
      .catch((error) => {
      });

    dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: false });
  };
}

export async function getGetirWebHookControl(restSecretKey) {
  return async (dispatch, getState) => {

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getGetirWebHookControl?restSecretKey=' + restSecretKey + '&BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          dispatch({ type: GETIR_WEBHOOK_CONTROL, payload: jsonData });
        }
      })
      .catch((error) => {
      });
  };
}

export async function removeGetirRest(secretKey) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/removeGetirRest?secretKey=' + secretKey + '&BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {

      })
      .catch((error) => {
      });
  };
}

export async function getGetirYemekRestaurantStatus(status, i, name) {
  return async (dispatch, getState) => {
    const { GetirYemekRestaurantList } = getState().get('settingsReducer').form_data;

    dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: true });
    const getiryemek = getParameter('GetirYemek');
    const secretKey = getiryemek.getirRestaurantSecretKey[i];

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getirRestaurantStatus?secretkey=' + secretKey + '&status=' + status + '&BrandId=' + cookie.load('BrandId') + '&name=' + name + '&UserId=' + cookie.load('UserId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          GetirYemekRestaurantList.find(x => x.secretkey == secretKey).status = (status ? 100 : 300);
          dispatch(formDataSetSetting('GetirYemekRestaurantList', GetirYemekRestaurantList));
        } else {
          dispatch({ type: SETTINGS_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
      });
    dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: false });
  };
}

export async function getGetirRestPosStatus(status, i) {
  return async (dispatch, getState) => {
    const { GetirYemekRestaurantList } = getState().get('settingsReducer').form_data;

    const getiryemek = getParameter('GetirYemek');
    const secretKey = getiryemek.getirRestaurantSecretKey[i];

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getGetirRestPosStatus?restSecretKey=' + secretKey + '&status=' + status + '&BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch({ type: SETTINGS_ERROR, payload: "İşlem tamamlandı lütfen GetirYemek panelinden kontrol ediniz." });
        } else {
          dispatch({ type: SETTINGS_ERROR, payload: "İşlem yapılamadı. Lütfen JaviPos teknik ekibi ile irtibata geçiniz!" });
        }
      })
      .catch((error) => {
      });
  };
}

export async function getGetirYemekCourierEnable(i, isEnable) {
  return async (dispatch, getState) => {
    const getiryemek = getParameter('GetirYemek');
    const secretKey = getiryemek.getirRestaurantSecretKey[i];

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getirCourierEnable?secretkey=' + secretKey + '&isEnable=' + isEnable + '&BrandId=' + cookie.load("BrandId"),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getEntegrationRestList(false, false, true, false, false));
        }
      })
      .catch((error) => {
      });
  };
}

export async function setYemekSepetiGlobalUpdate(data) {
  return async (dispatch, getState) => {
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/ThirdParty/setYemekSepetiGlobalUpdate',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data,
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getEntegrationRestList(true, false, false, false, false));
          dispatch(formDataSetSetting('YemekSepetiRestaurantVendorList', []));
        }
        dispatch({ type: SETTINGS_ERROR, payload: response.data.responseData });
      })
      .catch((error) => {
      });
  };
}

export async function setGetirWebHookDelete(Id) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/setYemekSepetiGlobalDelete?Id=' + Id,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getEntegrationRestList(false, false, true, false, false));
          dispatch(formDataSetSetting('YemekSepetiRestaurantVendorList', []));
        }
        dispatch({ type: SETTINGS_ERROR, payload: response.data.responseData });
      })
      .catch((error) => {
      });
  };
}

export async function setYemekSepetiGlobalDelete(Id) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/setYemekSepetiGlobalDelete?Id=' + Id,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getEntegrationRestList(true, false, false, false, false));
          dispatch(formDataSetSetting('YemekSepetiRestaurantVendorList', []));
        }
        dispatch({ type: SETTINGS_ERROR, payload: response.data.responseData });
      })
      .catch((error) => {
      });
  };
}

export async function getYemekSepetiGlobalRestSave(YemekSepetiRestaurantVendorList) {
  return async (dispatch, getState) => {
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/ThirdParty/getYemekSepetiGlobalRestSave',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: YemekSepetiRestaurantVendorList,
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getEntegrationRestList(true, false, false, false, false));
          dispatch(formDataSetSetting('YemekSepetiRestaurantVendorList', []));
          dispatch({ type: GETIR_WEBHOOK_CONTROL, payload: null });
        }
      })
      .catch((error) => {
      });
  };
}

export async function getYemekSepetiRestaurantVendorList(chainCode, posVendorId) {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');
    const TenantId = cookie.load('TenantId');
    const CenterBrandId = cookie.load('CenterBrandId');

    const list = [];
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getYemekSepetiGlobalVendorList?chainCode=' + chainCode + '&posVendorId=' + posVendorId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          json.map((n, i) => {
            const data = {
              Id: uuidv4(),
              platformRestaurantId: n.platformRestaurantId,
              chainCode,
              posVendorId,
              platformRestaurantName: '',
              IsStatus: true,
              YemekSepetiGlobalConfirmation: true,
              BrandId,
              TenantId,
              CenterBrandId,
              availabilityState: n.availabilityState
            };
            list.push(data);
          });
          dispatch(formDataSetSetting('YemekSepetiRestaurantVendorList', list));
        } else {
          dispatch({ type: SETTINGS_ERROR, payload: response.data.responseData });
        }
      })
      .catch((error) => {
      });
  };
}

export async function setYemekSepetiGlobalRestStatus(chainCode, posVendorId, status, platformRestaurantId, name) {
  return async (dispatch, getState) => {
    dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: true });

    const responseData = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/setYemekSepetiGlobalRestStatus?chainCode=' + chainCode + '&posVendorId=' + posVendorId + '&status=' + status + '&platformRestaurantId=' + platformRestaurantId + '&BrandId=' + cookie.load('BrandId') + '&UserId=' + cookie.load('UserId') + '&name=' + name,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => response.data.isStatus)
      .catch((error) => {
      });

    if (responseData) {
      (async () => {
        await sleep(5000);
        await dispatch(getEntegrationRestList(true, false, false, false, false));
        dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: false });
      })();
    } else {
      dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: false });
    }
  };
}

export async function getEntegrationRestList(IsYemekSepeti, IsTrendyolYemek, IsGetirYemek, IsGoFody, IsMigrosYemek, anchor = '', IsPaketYoldaEntegration = false) {
  return async (dispatch, getState) => {
    const { anchorElEntegrationDetail } = getState().get('paketYoldaReducer');
    const filterData = {
      BrandId: cookie.load('BrandId'),
      IsYemekSepeti,
      IsTrendyolYemek,
      IsGetirYemek,
      IsGoFody,
      IsMigrosYemek,
      IsPaketYoldaEntegration
    };
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/ThirdParty/getEntegrationRestList',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: filterData
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          if (IsGetirYemek) {
            dispatch(formDataSetSetting('GetirYemekRestaurantList', jsonData.GetirYemek));
          }
          if (IsYemekSepeti) {
            dispatch(formDataSetSetting('YemekSepetiRestaurantListGlobal', jsonData.YemekSepeti));
          }
          if (IsGoFody) {
            dispatch(formDataSetSetting('GoFodyRestaurantList', jsonData.GoFody));
          }
          if (IsTrendyolYemek) {
            dispatch(formDataSetSetting('TrendyolYemekRestaurantList', jsonData.TrendyolYemek));
          }
          if (IsMigrosYemek) {
            dispatch(formDataSetSetting('MigrosYemekRestaurantList', jsonData.MigrosYemek));
          }

          dispatch(setAnchorEntegration((anchor == '' ? anchorElEntegrationDetail : anchor)));
        } else {
          dispatch(setModalPaketYolda(response.data.responseData));
        }
      })
      .catch((error) => {
      });
  };
}


export async function setMigrosYemekStatus(ApiKey, storeId, warehouseId, IsActive, name) {
  return async (dispatch, getState) => {
    dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: true });

    const data = {
      "ApiKey": ApiKey,
      "storeId": storeId,
      "warehouseId": warehouseId,
      "IsActive": IsActive,
      "BrandId": cookie.load("BrandId"),
      "UserId": cookie.load("UserId"),
      "name": name
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/ThirdParty/setMigrosYemekStatus',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getEntegrationRestList(false, false, false, false, true));
        }
      })
      .catch((error) => {
      });

    dispatch({ type: ENTEGRATION_STATUS_LOADING, payload: false });
  };
}

export async function setSettings() {
  return async (dispatch, getState) => {
    const { Settings } = getState().get('settingsReducer').form_data;
    const BrandId = cookie.load('BrandId');
    Settings.BrandId = BrandId;

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Settings/setSettings',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: Settings,
    })
      .then((response) => {
        if (response.data.isStatus) {
          reactLocalStorage.remove('p_string');
          reactLocalStorage.setObject('p_string', response.data.responseData);
        }
        dispatch({ type: SETTINGS_ERROR, payload: 'Kayıt Edildi' });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getNotification(open = true) {
  return async (dispatch) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Settings/getNotification',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var coList = reactLocalStorage.getObject('NotificationList');
          var notList = JSON.parse(response.data.responseData);
          if (isEmpty(coList)) {
            reactLocalStorage.setObject('NotificationList', []);
            coList = reactLocalStorage.getObject('NotificationList');
          }
          coList.map((n, i) => {
            var is = notList.find(x => x.Id == n);
            if (is == null) {
              coList.splice(n.Id, 1);
            }
          })
          reactLocalStorage.setObject('NotificationList', coList);

          notList.map((n, i) => {
            var find = coList.find(x => x == n.Id);
            if (find != null) {
              n["IsVisible"] = false;
            } else {
              n["IsVisible"] = true;
            }
          })

          dispatch(setNotificationListUpdate(notList, open));
        } else {
          dispatch(setNotificationListUpdate([], open));
        }
      })
      .catch(function (error) {
      });
  }
}

export async function setNotificationListUpdate(list, open) {
  return async (dispatch) => {
    dispatch({
      type: NOTIFICATION_LISTUPDATE,
      payload: list,
      open: open
    });
  };
}

export async function setNotificationUpdate(update) {
  return async (dispatch) => {
    dispatch({
      type: NOTIFICATION_UPDATE,
      payload: update
    });
  };
}

export async function getSettings(type = 'OperationSettings') {
  return async (dispatch, getState) => {
    const BrandId = cookie.load('BrandId');
    const TenantId = cookie.load('TenantId');
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Settings/getSettings?TenantId=' + TenantId + '&BrandId=' + BrandId + '',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch(formDataSetSetting('Settings', json));
          if (type == 'OperationSettings') {
            dispatch(getAddressList('Il', ''));
            if (json.DefaultCity.IlId > 0) {
              dispatch(getAddressList('Ilce', json.DefaultCity.IlId));
            }
          }
        }
      })
      .catch((error) => {
      });
  };
}

export async function formDataSetSetting(key, val) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_GLOBAL_FORM_DATA_SET,
      payload: { key, val }
    });
  };
}

export async function setModal(text) {
  return async (dispatch, getState) => {
    dispatch({
      type: SETTINGS_ERROR,
      payload: text
    });
  };
}

export async function setPaketYoldaUpdate() {
  return async (dispatch, getState) => {
    dispatch({ type: PAKETYOLDA_UPDATE });
  };
}


export async function setPaketYoldaVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: PAKETYOLDA_VISIBLE,
      payload: visible
    });
  };
}

export async function setSettingsUpdate(update) {
  return async (dispatch, getState) => {
    dispatch({
      type: SETTINGS_UPDATE,
      payload: update
    });
  };
}

export async function setPrintUpdate(printData, printerList, sectionPrinterList, entegrasyonPrinterList, userPrinterList) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRINT_UPDATE,
      data: printData,
      list: printerList,
      section: sectionPrinterList,
      entegrasyon: entegrasyonPrinterList,
      user: userPrinterList
    });
  };
}

export async function getTelegramChatId(token) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getTelegramChatId?token=' + token,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: TELEGRAM_CHAT,
            payload: json
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
}

export async function clearCache() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Settings/clearCache?BrandId=' + cookie.load('BrandId') + '&CenterBrandId=' + cookie.load('CenterBrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        window.location.reload(true);
      })
      .catch((error) => {
        alert(error);
      });
  };
}

export async function getPrintList() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Printer/getPrint?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: PRINT_UPDATE,
            data: json.PrinterList,
            list: json.PrinterNames,
            section: json.SectionPrinterList,
            entegrasyon: json.EntegrasyonPrinterList,
            user: json.UserPrinterList,
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
}

export async function setPrint() {
  return async (dispatch, getState) => {
    const { printData, sectionPrinterList, entegrasyonPrinterList, userPrinterList } = getState().get('settingsReducer');

    const data = {
      BrandId: cookie.load('BrandId'),
      printer: printData,
      sectionPrinter: sectionPrinterList,
      entegrasyonPrinter: entegrasyonPrinterList,
      userPrinter: userPrinterList
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Settings/setPrint',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data,
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(setModal('Kayıt Edildi'));
        } else {
          dispatch(setModal('Hata Oluştu'));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setPrintVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getPrintList());
      await dispatch(getUser());
    }
    dispatch({
      type: PRINT_VISIBLE,
      open: visible
    });
  };
}

export async function setSettingsLoading() {
  return async (dispatch, getState) => {
    dispatch({
      type: SETTINGS_START
    });
  };
}

export async function setSoundControl(status) {
  return async (dispatch, getState) => {
    dispatch({
      type: SOUND_CONTROL,
      payload: status
    });
  };
}

export async function setTrendyolClaimsVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getTrendyolClaimsList(false));
    }
    dispatch({
      type: TRENDYOL_CLAIMS_VISIBLE,
      payload: visible
    });
  };
}

export async function setOrderMenuTab(menu) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ORDER_MENU_TAB,
      payload: menu
    });
  };
}

export async function setProductEntegrationDataState(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCT_ENTEGRATION_DATA,
      payload: data
    });
  };
}

export async function setEntProSelectPlatform(process, type) {
  return async (dispatch, getState) => {
    const { orderMenuTab } = getState().get('settingsReducer');
    if (orderMenuTab == 3) {
      if (type == 'YemekSepeti') {
        dispatch({ type: PRODUCT_ENTEGRATION_DATA, payload: [] });
      } else {
        dispatch(setProductEntegrationData((type == 'GetirYemek' ? 0 : (type == 'TrendyolYemek' ? 2 : (type == 'MigrosYemek' ? 4 : -1)))));
      }
    } else if (process == 'active') {
      if (type != 'TrendyolYemek' && type != 'MigrosYemek' && type != 'GetirYemek') {
        dispatch({ type: SETTINGS_ERROR, payload: 'Şeçmiş olduğunuz entegrasyon firması servis üzerinden fiyat güncellemeyi desteklememektedir!' });
        return;
      }
    } else if (process == 'price') {
      if (type != 'TrendyolYemek' && type != 'MigrosYemek') {
        dispatch({ type: SETTINGS_ERROR, payload: 'Şeçmiş olduğunuz entegrasyon firması servis üzerinden fiyat güncellemeyi desteklememektedir!' });
        return;
      }
    }


    dispatch({
      type: SET_ENT_PRO_SELECT_PLATFORM,
      payload: type
    });
  };
}

export async function setEntProSelectPlatformRest(id, storeId) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ENT_PRO_SELECT_PLATFORM_REST,
      payload: id,
      storeId
    });
  };
}

export async function setEntegrationLoadLoading(loading) {
  return async (dispatch, getState) => {
    dispatch({
      type: ENTEGRATION_LOAD_DATA_LOADING,
      payload: loading
    });
  };
}

export async function setProductEntegrationLoading(loading) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCT_ENTEGRATION_LOADING,
      payload: loading
    });
  };
}

export async function setEntegrationProductPrice() {
  return async (dispatch, getState) => {
    const {
      productEntegrationData, entProSelectPlatformRest, entProSelectPlatform, entProSelectPlatformStoreId
    } = getState().get('settingsReducer');
    const { TrendyolYemekRestaurantList } = getState().get('settingsReducer').form_data;
    const trendyolyemek = getParameter('TrendyolYemek');

    if (productEntegrationData.filter(x => x.isSelected == true && x.newPrice > 0).length == 0) {
      dispatch({ type: SETTINGS_ERROR, payload: 'En az bir adet ürün fiyat değişikliği yapmanız gerekmektedir!' });
      return;
    }

    const proData = [];
    productEntegrationData.filter(x => x.isSelected == true && x.newPrice > 0).map((n, i) => {
      proData.push(n);
    });

    let storeGroupId = '';
    let menuId = '';
    const trendyolPrice = [];
    const migrosPrice = [];
    if (entProSelectPlatform == 'MigrosYemek') {
      storeGroupId = proData[0].storeGroupId;
      menuId = proData[0].menuId;
      proData.map((n, i) => {
        migrosPrice.push({
          menuItemId: n.menuItemId,
          productId: n.platProductId,
          defaultPrimaryPrice: n.newPrice,
          defaultPrimaryDiscountedPrice: n.newPrice
        });
      });
    } else if (entProSelectPlatform == 'TrendyolYemek') {
      proData.map((n, i) => {
        trendyolPrice.push({
          restaurantid: (entProSelectPlatform == 'TrendyolYemek' ? TrendyolYemekRestaurantList.find(x => x.supplierId == entProSelectPlatformRest).id : ''),
          productId: n.platProductId,
          sellingPrice: n.newPrice
        });
      });
    }

    const data = {
      ApiKey: (entProSelectPlatform == 'TrendyolYemek' ? trendyolyemek.propList.find(x => x.supplierId == entProSelectPlatformRest).apiKey : (entProSelectPlatform == 'MigrosYemek' ? entProSelectPlatformRest : '')),
      supplierID: (entProSelectPlatform == 'TrendyolYemek' ? entProSelectPlatformRest : ''),
      secretKey: (entProSelectPlatform == 'TrendyolYemek' ? trendyolyemek.propList.find(x => x.supplierId == entProSelectPlatformRest).apiSecretKey : ''),
      storeGroupId,
      storeId: entProSelectPlatformStoreId,
      menuId,
      type: (entProSelectPlatform == 'TrendyolYemek' ? 2 : 4),
      trendyolPrice,
      migrosPrice
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/ThirdParty/setEntegrationProductPrice',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data
    })
      .then((response) => {
        let platformid = entProSelectPlatformRest;
        if (entProSelectPlatform == 'MigrosYemek') {
          platformid = base64.encode(entProSelectPlatformRest);
        }
        dispatch(setProductEntegrationData((entProSelectPlatform == 'GetirYemek' ? 0 : (entProSelectPlatform == 'TrendyolYemek' ? 2 : 4)), platformid));
        dispatch({ type: SETTINGS_ERROR, payload: response.data.responseData });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setEntegrationProductStatus(productId, status) {
  return async (dispatch, getState) => {
    const { entProSelectPlatformRest, entProSelectPlatform, entProSelectPlatformStoreId } = getState().get('settingsReducer');
    const { TrendyolYemekRestaurantList } = getState().get('settingsReducer').form_data;
    const trendyolyemek = getParameter('TrendyolYemek');

    const data = {
      productId: productId,
      status: status,
      ApiKey: (entProSelectPlatform == 'TrendyolYemek' ? trendyolyemek.propList.find(x => x.supplierId == entProSelectPlatformRest).apiKey : (entProSelectPlatform == 'MigrosYemek' ? entProSelectPlatformRest : '')),
      storeId: entProSelectPlatformStoreId,
      type: (entProSelectPlatform == 'GetirYemek' ? 0 : (entProSelectPlatform == 'TrendyolYemek' ? 2 : 4)),
      supplierID: (entProSelectPlatform == 'TrendyolYemek' ? entProSelectPlatformRest : ''),
      secretKey: (entProSelectPlatform == 'GetirYemek' ? entProSelectPlatformRest : (entProSelectPlatform == 'TrendyolYemek' ? trendyolyemek.propList.find(x => x.supplierId == entProSelectPlatformRest).apiSecretKey : '')),
      restaurantid: (entProSelectPlatform == 'TrendyolYemek' ? TrendyolYemekRestaurantList.find(x => x.supplierId == entProSelectPlatformRest).id : ''),
      BrandId: cookie.load("BrandId")
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/ThirdParty/setEntegrationProductStatus',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data
    })
      .then((response) => {
        let platformid = entProSelectPlatformRest;
        if (entProSelectPlatform == 'MigrosYemek') {
          platformid = base64.encode(entProSelectPlatformRest);
        }
        dispatch(setProductEntegrationData((entProSelectPlatform == 'GetirYemek' ? 0 : (entProSelectPlatform == 'TrendyolYemek' ? 2 : 4)), platformid));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setEntegrationProductSave() {
  return async (dispatch, getState) => {
    const { productEntegrationData } = getState().get('settingsReducer');

    dispatch(setProductEntegrationLoading(true));
    const BrandId = cookie.load('BrandId');
    const TenantId = cookie.load('TenantId');

    const data = [];

    productEntegrationData.map((n, i) => {
      if (n.EntegrationId != '00000000-0000-0000-0000-000000000000') {
        data.push({
          Id: n.Id, BrandId, TenantId, EntegrationProductId: n.EntegrationId, ProductType: n.ProductType
        });
      }
    });

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/ThirdParty/setEntegrationProductSave',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data
    })
      .then((response) => {
        dispatch({ type: SETTINGS_ERROR, payload: response.data.responseData });
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch(setProductEntegrationLoading(false));
  };
}

export async function setProductEntegrationData(type, platformid = '') {
  return async (dispatch, getState) => {
    dispatch(setEntegrationLoadLoading(true));

    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getEntegrationProductList?BrandId=' + BrandId + '&type=' + type + '&platformid=' + platformid,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const productData = JSON.parse(response.data.responseData);
          productData.map((n, i) => {
            n.isSelected = false;
            n.newPrice = 0;
          });
          dispatch({ type: PRODUCT_ENTEGRATION_DATA, payload: productData });
        } else {
          dispatch({ type: PRODUCT_ENTEGRATION_DATA, payload: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setEntegrationData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRODUCT_ENTEGRATION_DATA,
      payload: data
    });
  };
}

export async function setPaycellVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: PAYCELL_VISIBLE,
      payload: visible
    });
  };
}

export async function setPaycellUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: PAYCELL_UPDATE
    });
  };
}

export async function setIngenicoVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: INGENICO_VISIBLE,
      payload: visible
    });
  };
}

export async function setIngenicoUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: INGENICO_UPDATE
    });
  };
}


export async function getRestStatus() {
  return async (dispatch, getState) => {

    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/JaviApp/getRestStatus?BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          dispatch({ type: JAVIAPP_STATE_STATUS, payload: jsonData.IsAppOpen });
        } else {
          dispatch({ type: JAVIAPP_STATE_STATUS, payload: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setRestStatus(status) {
  return async (dispatch, getState) => {

    const BrandId = cookie.load('BrandId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/JaviApp/setRestStatus?BrandId=' + BrandId + '&status=' + status,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch({ type: JAVIAPP_STATE_STATUS, payload: status });
        } else {
          dispatch({ type: JAVIAPP_STATE_STATUS, payload: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case JAVIAPP_STATE_STATUS:
      return { ...state, IsAppOpen: action.payload }
    case GETIR_WEBHOOK_CONTROL:
      return { ...state, getirWebHookControl: action.payload }
    case NOTIFICATION_LISTUPDATE:
      return { ...state, NotificationList: action.payload, IsNotificationVisible: action.open, IsNotificationUpdate: !state.IsNotificationUpdate }
    case NOTIFICATION_UPDATE:
      return { ...state, IsNotificationVisible: action.payload, IsNotificationUpdate: !state.IsNotificationUpdate }
    case PAYCELL_POS_LIST_DATA:
      return { ...state, PaycellPosListData: action.payload, IsPaycellUpdate: !state.IsPaycellUpdate };
    case PAYCELL_UPDATE:
      return { ...state, IsPaycellUpdate: !state.IsPaycellUpdate };
    case INGENICO_UPDATE:
      return { ...state, IsIngenicoUpdate: !state.IsIngenicoUpdate };
    case INGENICO_VISIBLE:
      return { ...state, IsIngenicoVisible: action.payload, IsIngenicoUpdate: !state.IsIngenicoUpdate };
    case PAYCELL_VISIBLE:
      return { ...state, IsPaycellVisible: action.payload, IsPaycellUpdate: !state.IsPaycellUpdate };
    case ENTEGRATION_LOAD_DATA_LOADING:
      return { ...state, entegrationLoadDataLoading: action.payload, IsEntegrationProductUpdate: !state.IsEntegrationProductUpdate };
    case PRODUCT_ENTEGRATION_DATA:
      return {
        ...state, entegrationLoadDataLoading: false, productEntegrationData: action.payload, IsEntegrationProductUpdate: !state.IsEntegrationProductUpdate
      };
    case SET_ENT_PRO_SELECT_PLATFORM_REST:
      return {
        ...state, entProSelectPlatformStoreId: action.storeId, entProSelectPlatformRest: action.payload, IsEntegrationProductUpdate: !state.IsEntegrationProductUpdate
      };
    case SET_ENT_PRO_SELECT_PLATFORM:
      return {
        ...state, entProSelectPlatformStoreId: '', productEntegrationData: [], entProSelectPlatformRest: '', entProSelectPlatform: action.payload, IsEntegrationProductUpdate: !state.IsEntegrationProductUpdate
      };
    case SET_ORDER_MENU_TAB:
      return {
        ...state, entProSelectPlatformStoreId: '', productEntegrationData: [], entProSelectPlatformRest: '', orderMenuTab: action.payload, IsEntegrationProductUpdate: !state.IsEntegrationProductUpdate
      };
    case TRENDYOL_CLAIMS_LOADING:
      return { ...state, trendyolClaimsLoading: action.payload, trendyolClaimsUpdate: !state.trendyolClaimsUpdate };
    case TRENDYOL_CLAIMS_DATA:
      return { ...state, trendyolClaimsData: action.payload, trendyolClaimsUpdate: !state.trendyolClaimsUpdate };
    case TRENDYOL_CLAIMS_VISIBLE:
      return { ...state, trendyolClaimsVisible: action.payload, trendyolClaimsUpdate: !state.trendyolClaimsUpdate };
    case SOUND_CONTROL:
      return { ...state, IsSoundControl: action.payload };
    case ENTEGRATION_STATUS_LOADING:
      return { ...state, entegrationStatusLoading: action.payload };
    case DOWNLOADQRCODE_LOADING:
      return { ...state, downloadQrCodeLoading: action.payload };
    case PRINT_VISIBLE:
      return { ...state, IsPrintVisible: action.open };
    case PAKETYOLDA_VISIBLE:
      return { ...state, paketYoldaVisible: action.payload, paketYoldaUpdate: !state.paketYoldaUpdate };
    case PAKETYOLDA_UPDATE:
      return { ...state, paketYoldaUpdate: !state.paketYoldaUpdate };
    case TELEGRAM_CHAT:
      return { ...state, telegramChat: action.payload };
    case PRINT_UPDATE:
      return {
        ...state, printData: action.data, printerList: action.list, sectionPrinterList: action.section, entegrasyonPrinterList: action.entegrasyon, userPrinterList: action.user, IsPrintUpdate: !state.IsPrintUpdate
      };
    case SETTINGS_START:
      return { ...state, settingsLoading: !state.settingsLoading };
    case SETTINGS_ERROR:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          modalOpen: true,
          modalText: action.payload,
          modalTitle: 'Uyarı'
        },
        entProSelectPlatformRest: ''
      };
    case SETTINGS_UPDATE:
      return { ...state, IsSettingsUpdate: !state.IsSettingsUpdate };
    case SETTINGS_STOP:
      return { ...state, loading: false, data: action.payload };
    case SET_GLOBAL_FORM_DATA_SET:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          [action.payload.key]: action.payload.val
        }
      };
    case SETTINGS_MODALCLOSE:
      return { ...state, modalOpen: false };
    default:
      return state;
  }
};

export default settingsReducer;
