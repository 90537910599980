import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../../CenterBrandPage/centerbrandpage-jss';
import {
    setDealerAddVisible, setDealerAddData, getDealerAddUpdate
} from '../../../../redux/modules/admin';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import { CapitalizeText, ComplateLowerText } from '../../../../function/GeneralFunction';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class DealerAdd extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.dealerAddUpdate == this.props.dealerAddUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeDealerChange = (Name) => (event) => {
        const pointer = event.target.selectionStart;
        const element = event.target;

        const { dealerAdd } = this.props;
        dealerAdd[Name] = (Name == "IsStatus" ? event.target.checked : (Name == "CompanyTitle" || Name == "NameSurname" || Name == "TaxAdministration" ? CapitalizeText(event.target.value) : (Name == "Email" ? ComplateLowerText(event.target.value) : event.target.value)));
        this.props.setDealerAddData(dealerAdd).then(() => { })

        window.requestAnimationFrame(() => {
            element.selectionStart = pointer;
            element.selectionEnd = pointer;
        });
    };

    handleSubmit(event) {
        event.preventDefault();
        this.props.getDealerAddUpdate().then(() => { })
    }

    render() {
        const { classes, dealerAddVisible, dealerAdd } = this.props;

        return (
            <Dialog
                open={dealerAddVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'sm'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <form autocomplete="off" style={{ display: 'contents' }} onSubmit={this.handleSubmit}>
                    <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                        {"JaviKurye Merkez Ekleme/Düzenleme"}
                    </DialogTitle>
                    <DialogContent style={{ overflow: 'hidden auto' }}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={dealerAdd.IsStatus}
                                            onChange={this.handleChangeDealerChange("IsStatus")}
                                        />
                                    )}
                                    label={"Aktif"}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Firma Adı'}</InputLabel>
                                    <Input
                                        type="text"
                                        autoComplete="new-password"
                                        autocomplete="off"
                                        fullWidth
                                        required
                                        placeholder={'Firma Adı'}
                                        value={dealerAdd.CompanyTitle}
                                        onChange={this.handleChangeDealerChange('CompanyTitle')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Yetkili Adı Soyadı'}</InputLabel>
                                    <Input
                                        type="text"
                                        autoComplete="new-password"
                                        autocomplete="off"
                                        fullWidth
                                        required
                                        placeholder={'Yetkili Adı Soyadı'}
                                        value={dealerAdd.NameSurname}
                                        onChange={this.handleChangeDealerChange('NameSurname')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Email'}</InputLabel>
                                    <Input
                                        type="email"
                                        autoComplete="new-password"
                                        autocomplete="off"
                                        fullWidth
                                        required
                                        placeholder={'Email Giriniz'}
                                        value={dealerAdd.Email}
                                        onChange={this.handleChangeDealerChange('Email')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <InputMask
                                    mask="0 999 999 99 99"
                                    onFocus={event => { event.target.select() }}
                                    value={dealerAdd.Phone}
                                    onChange={this.handleChangeDealerChange("Phone")}
                                >
                                    {() => <TextField
                                        label={'Telefon Numarası'}
                                        name={"Phone"}
                                        fullWidth
                                        required
                                        style={{ marginTop: 0 }}
                                        margin="normal"
                                        type="text"
                                    />}
                                </InputMask>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Şifre'}</InputLabel>
                                    <Input
                                        type="password"
                                        autoComplete="new-password"
                                        autocomplete="off"
                                        fullWidth
                                        required
                                        placeholder={'Şifre Giriniz'}
                                        value={dealerAdd.Password}
                                        onChange={this.handleChangeDealerChange('Password')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Vergi Dairesi'}</InputLabel>
                                    <Input
                                        type="text"
                                        autoComplete="new-password"
                                        autocomplete="off"
                                        fullWidth
                                        required
                                        placeholder={'Vergi Dairesi'}
                                        value={dealerAdd.TaxAdministration}
                                        onChange={this.handleChangeDealerChange('TaxAdministration')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Vergi Numarası'}</InputLabel>
                                    <Input
                                        type="text"
                                        autoComplete="new-password"
                                        autocomplete="off"
                                        fullWidth
                                        required
                                        placeholder={'Vergi Numarası'}
                                        value={dealerAdd.TaxNumber}
                                        onChange={this.handleChangeDealerChange('TaxNumber')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Adres'}</InputLabel>
                                    <Input
                                        type="text"
                                        autoComplete="new-password"
                                        autocomplete="off"
                                        multiline
                                        fullWidth
                                        required
                                        placeholder={'Adres'}
                                        value={dealerAdd.Address}
                                        onChange={this.handleChangeDealerChange('Address')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type={"submit"} variant="contained" color="secondary">
                            {"Kaydet"}
                        </Button>
                        <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setDealerAddVisible(false).then(() => { })} color="primary">
                            {"Çıkış"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

DealerAdd.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('adminReducer').dealerAddUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    dealerAddUpdate: value(state, props),
    dealerAddVisible: state.get('adminReducer').dealerAddVisible,
    dealerAdd: state.get('adminReducer').dealerAdd
});

const mapDispatchToProps = {
    setDealerAddVisible,
    setDealerAddData,
    getDealerAddUpdate
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(DealerAdd)));
