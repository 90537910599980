import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import classNames from 'classnames';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Ionicon from 'react-ionicons';
import styles from './papperStyle-jss';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import jarvis from 'dan-images/jarvis.png';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import cookie from 'react-cookies';
import { push } from 'connected-react-router';

class TableBlock extends React.Component {
  renderTableGroup(grouplist) {
    const { classes, event } = this.props;
    if (grouplist.length != 0) {
      return (
        grouplist.map((message, index) => (
          <Grid item xs={6} md={2} key={index} style={{ padding: 1, flexBasis: 0 }}>
            <ButtonBase onClick={() => event(message.Id)} className={classes.buttonRoot} style={{ borderRadius: 2, height: 50, backgroundColor: (message.IsSelected ? "rgb(0, 150, 136)" : "#313131"), border: '1px solid rgba(99,99,99,0.7)' }}>
              <Typography className={classes.buttonTitle} variant="subtitle1">{message.Name}</Typography>
            </ButtonBase>
          </Grid>
        ))
      );
    } else {
      return <div style={{ width: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{ color: 'rgb(22, 160, 133)', marginLeft: 20 }} thickness={4} className={classes.progress} /></div>
    }
  }

  render() {
    const {
      classes,
      title,
      desc,
      children,
      whiteBg,
      noMargin,
      colorMode,
      overflowX,
      comeGetIt,
      grouplist
    } = this.props;
    return (
      <div>
        <div className={classes.descBlock} style={{ position: 'fixed', top: 0, right: 0, left: 64, zIndex: 999, backgroundColor: '#424242' }}>
          <div className={classes.titleText} style={{ padding: '4px 0px 4px 0px', position: 'relative' }}>
            <Grid container>
              {this.renderTableGroup(grouplist)}
              <Grid item xs={6} md={2} style={{ padding: 1, flexBasis: 0 }}>
                <ButtonBase onClick={() => comeGetIt()} className={classes.buttonRoot} style={{ borderRadius: 2, height: 50, backgroundColor: "#9b59b6", border: '1px solid rgba(99,99,99,0.7)' }}>
                  <Icon style={{ color: 'white' }}>{"bolt"}</Icon>
                  <Typography className={classes.buttonTitle} variant="subtitle1">{"GEL AL SATIŞ"}</Typography>
                </ButtonBase>
              </Grid>
            </Grid>
            <img style={{ height: 30, position: 'absolute', right: 10, top: 15 }} src={jarvis} />
          </div>
        </div>
        <Paper style={{ padding: 0, backdropFilter: 'saturate(180%) blur(20px)', backgroundColor: 'rgba(66, 66, 66, 0.80)', overflow: 'hidden' }} className={classNames(classes.root, noMargin && classes.noMargin, colorMode && classes.colorMode)} elevation={0}>
          <section style={{ height: 'calc(100vh - 75px)', backgroundColor: 'transparent', padding: 0 }} className={classNames(classes.content, whiteBg && classes.whiteBg, overflowX && classes.overflowX)}>
            {children}
          </section>
        </Paper>
      </div>
    );
  }
}

TableBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  whiteBg: PropTypes.bool,
  colorMode: PropTypes.bool,
  noMargin: PropTypes.bool,
  overflowX: PropTypes.bool,
};

TableBlock.defaultProps = {
  whiteBg: false,
  noMargin: false,
  colorMode: false,
  overflowX: false,
  icon: 'ios-bookmark-outline'
};

export default withStyles(styles)(TableBlock);
