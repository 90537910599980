import axios from 'axios';
var base64 = require('base-64');
const uuidv4 = require('uuid/v4');
import cookie from 'react-cookies';
import { push } from 'connected-react-router';
import brand from 'dan-api/dummy/brand';

export const COURIER_START = 'addition_start';
export const COURIER_STOP  = 'addition_stop';
export const COURIER_ERROR = 'addition_error';
export const COUR_GLOBAL_FORM_DATA_SET = 'cour_global_form_data_set';
export const COURIER_MODALCLOSE = 'addition_modalclose';

let initialState = {
  form_data : {
    modalOpen:false,
    modalTitle: "",
    modalText: "",
    PIN:"",
    CourierPackageList:[],
    CourierPackageListLoad:false,
    Timer:60,

    courierOutsideModalOpen : false,
    courierOutsideModalText : ""
  }
}

export async function setCourier(Id){
  return async (dispatch,getState) => {
    const { CourierPackageList } = getState().get('courierReducer').form_data;
    var courier = CourierPackageList.find(x=>x.Id == Id);
    courier.IsSelected = !courier.IsSelected;
    courier.Courrier = (!courier.IsSelected ? null : cookie.load('PackageUserId'));
    courier.CourrierName = (!courier.IsSelected ? "" : cookie.load('PackageUserName'));
    dispatch(formDataSet("CourierPackageList" , CourierPackageList));
}}

export async function setPackageCourier(){
  return async (dispatch,getState) => {
    const { CourierPackageList } = getState().get('courierReducer').form_data;

    await axios({
            method: 'POST',
            url: brand.urlSocket + 'api/Courier/setPackageCourier',
            dataType : 'json',
            headers: {
                'Authorization':  'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: CourierPackageList,
            })
            .then(function (response) {
              if (response.data.isStatus) {
                var json = JSON.parse(response.data.responseData);
                dispatch(formDataSet("Timer" , 60));
                cookie.save('PackageUserName', "" , { path: '/' });
                cookie.save('PackageUserId', "" , { path: '/' });
                dispatch(push('/courier'));
              }else {
                dispatch(formDataSet("modalText" , "Paket alma işleminde hata oluştu!"));
                dispatch(formDataSet("modalTitle" , "Uyarı"));
                dispatch(formDataSet("modalOpen" , true));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
}}

export async function packageClose(){
  return async (dispatch,getState) => {
    dispatch(formDataSet("PIN" , ""));
    cookie.save('PackageUserName', "" , { path: '/' });
    cookie.save('PackageUserId', "" , { path: '/' });
    dispatch(formDataSet("Timer" , 60));
    dispatch(push('/courier'));
}}

export async function setCourierControl(){
  return async (dispatch,getState) => {
    const { PIN } = getState().get('courierReducer').form_data;

    var TenantId = cookie.load('TenantId');
    var BrandId = cookie.load('BrandId');
    await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Courier/setCourierControl?TenantId='+TenantId+'&BrandId='+BrandId+'&PIN='+PIN+'',
            dataType : 'json',
            headers: {
                'Authorization':  'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            })
            .then(function (response) {
              if (response.data.isStatus) {
                var json = JSON.parse(response.data.responseData);
                cookie.save('PackageUserName', json.UserName , { path: '/' });
                cookie.save('PackageUserId', json.UserId , { path: '/' });
                dispatch(formDataSet("PIN" , ""));
                dispatch(formDataSet("Timer" , 60));
                dispatch(push('/courier/packages'));
              }else {
                dispatch(formDataSet("modalText" , "Kurye Girişi Yapılamadı!"));
                dispatch(formDataSet("modalTitle" , "Uyarı"));
                dispatch(formDataSet("modalOpen" , true));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
}}

export async function getCourierPackages(){
  return async (dispatch,getState) => {
    dispatch(formDataSet("CourierPackageListLoad" , false));
    var TenantId = cookie.load('TenantId');
    var BrandId = cookie.load('BrandId');

    await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Courier/getCourierPackages?TenantId='+TenantId+'&BrandId='+BrandId+'',
            dataType : 'json',
            headers: {
                'Authorization':  'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            })
            .then(function (response) {
              if (response.data.isStatus) {
                var json = JSON.parse(response.data.responseData);
                dispatch(formDataSet("CourierPackageList" , json));
              }
              dispatch(formDataSet("CourierPackageListLoad" , true));
            })
            .catch(function (error) {
              console.log(error);
            });
}}

export async function formDataSet (key, val) {
  return async (dispatch,getState) => {
    dispatch({
      type: COUR_GLOBAL_FORM_DATA_SET,
      payload: {key, val}
    });
  };
}

const courierReducer = (state = initialState, action) => {
	switch (action.type) {
    case COURIER_START:
      return {...state, loading : true}
    case COURIER_ERROR:
      return {...state, form_data: {
        ...state.form_data,
        modalOpen : true,
        modalText: action.payload,
        modalTitle: "Uyarı"
      }
    }
    case COURIER_STOP:
      return {...state, loading : false, data : action.payload}
    case COUR_GLOBAL_FORM_DATA_SET:
      return {
          ...state,
          form_data: {
            ...state.form_data,
            [action.payload.key] : action.payload.val
          }
      }
    case COURIER_MODALCLOSE:
    return {...state, modalOpen : false }
    default:
		return state;
  }
}

export default courierReducer;
