import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import {
    setCenterRegionVisible, deleteLimit, setCenterRegionData, setLimit, setModalCenterBrand, setCenterRegionUpdate, formDataSetCenterBrand,
    setLimitAdd
} from '../../../redux/modules/centerbrand';
import { getAddressList } from '../../../redux/modules/addition';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import { CapitalizeText, ComplateLowerText } from '../../../function/GeneralFunction';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import NumberFormat from 'react-number-format';
import SelectSearch from 'react-select-search';
import Divider from '@material-ui/core/Divider';
import '../DetailPage/styles.css';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            prefix="₺"
        />
    );
}

class CenterRegionList extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.centerRegionUpdate == this.props.centerRegionUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        await this.props.getAddressList("Il", "").then(() => { })
    }

    handleGetIlSelected = async (e) => {
        const { centerBrandReducer } = this.props;

        await this.props.getAddressList("Ilce", e.value).then(() => { })
        centerBrandReducer.IlId = e.value;
        centerBrandReducer.IlText = e.name;

        centerBrandReducer.IlceId = "";
        centerBrandReducer.IlceText = "";

        centerBrandReducer.MahalleId = "";
        centerBrandReducer.MahalleText = "";

        centerBrandReducer.SokakId = "";
        centerBrandReducer.SokakText = "";

        this.props.setCenterRegionUpdate().then(() => { })
    };

    handleGetIlceSelected = async (e) => {
        const { centerBrandReducer } = this.props;

        await this.props.getAddressList("Mahalle", e.value).then(() => { })
        centerBrandReducer.IlceId = e.value;
        centerBrandReducer.IlceText = e.name;

        centerBrandReducer.MahalleId = "";
        centerBrandReducer.MahalleText = "";

        centerBrandReducer.SokakId = "";
        centerBrandReducer.SokakText = "";

        this.props.setCenterRegionUpdate().then(() => { })
    };

    handleGetMahalleSelected = async (e) => {
        const { centerBrandReducer } = this.props;

        await this.props.getAddressList("Sokak", e.value).then(() => { })
        centerBrandReducer.MahalleId = e.value;
        centerBrandReducer.MahalleText = e.name;

        centerBrandReducer.SokakId = "";
        centerBrandReducer.SokakText = "";

        this.props.setCenterRegionUpdate().then(() => { })
    };

    setLimit() {
        const { centerBrandReducer } = this.props;
        if (centerBrandReducer.MahalleId.length == 0 || centerBrandReducer.MahalleId.length == 0) {
            this.props.setModalCenterBrand("Zorunlu alanlar girilmelidir!").then(() => { })
            return;
        }
        this.props.setLimitAdd().then(() => { })
    }

    handleChangeLimit = async (event) => {
        this.props.formDataSetCenterBrand("Limit", event.target.value).then(() => { })
        await this.props.setCenterRegionUpdate().then(() => { })
      };

    renderBrandLimitList() {
        const { classes, centerBrandReducer, additionReducer } = this.props;

        return (
            <div>
                <Divider style={{ margin: 5 }} />
                <Grid container spacing={1}>
                    <Grid item md={4}>
                        <SelectSearch onChange={this.handleGetIlSelected} value={centerBrandReducer.IlId} options={additionReducer.IlList} search={true} name="il" placeholder="İl Seçimi" />
                    </Grid>
                    <Grid item md={4}>
                        <SelectSearch onChange={this.handleGetIlceSelected} value={centerBrandReducer.IlceId} options={additionReducer.IlceList} search={true} name="ilce" placeholder="İlçe Seçimi" />
                    </Grid>
                    <Grid item md={4}>
                        <SelectSearch onChange={this.handleGetMahalleSelected} value={centerBrandReducer.MahalleId} options={additionReducer.MahalleList} search={true} name="mahalle" placeholder="Mahalle Seçimi" />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            style={{ marginTop: 0 }}
                            onChange={this.handleChangeLimit}
                            id="Limit"
                            value={centerBrandReducer.Limit}
                            label="Bölge Limiti (zorunlu)"
                            variant="outlined"
                            onFocus={event => { event.target.select() }}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Button fullWidth onClick={() => this.setLimit()} style={{ borderRadius: 5, height: 47 }} size="large" variant="contained" color="secondary" type="submit">
                            {"Bölge Ekle"}
                        </Button>
                    </Grid>
                </Grid>
                <Divider style={{ margin: 5 }} />
            </div>
        )
    }

    handleChangeCenterRegionLimit = (i) => (event) => {
        const { centerRegionData } = this.props;
        centerRegionData[i].PackageLimit = event.target.value;
        this.props.setCenterRegionData(centerRegionData).then(() => { })
    };

    render() {
        const { classes, centerRegionVisible, centerRegionData } = this.props;

        return (
            <Dialog
                open={centerRegionVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'lg'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Call Center Bölge Limitleri"}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <div className={classes.detailWrap} style={{ backgroundColor: '#292929' }}>
                        {this.renderBrandLimitList()}
                        <Table className={classNames(classes.table, classes.stripped)}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '40%' }} align="left">{"Bölge Adı"}</TableCell>
                                    <TableCell style={{ width: '30%' }} align="left">{"Limiti"}</TableCell>
                                    <TableCell style={{ width: '30%' }} align="right">{""}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    centerRegionData.map((n, i) => ([
                                        <TableRow hover key={n.Id}>
                                            <TableCell align="left">{n.MahalleAdi}</TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    value={n.PackageLimit}
                                                    style={{ borderRadius: 8 }}
                                                    onChange={this.handleChangeCenterRegionLimit(i)}
                                                    fullWidth
                                                    onFocus={event => { event.target.select() }}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title={"Düzenle"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.props.setLimit(n).then(() => { })}>
                                                        <Icon>{"save"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Sil"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => (confirm('Bölgeyi silmek istediğinize emin misiniz?') ? this.props.deleteLimit(n.Id, n.BrandId).then(() => { }) : null)}>
                                                        <Icon>{"delete"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ]))
                                }
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setCenterRegionVisible("", false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').centerRegionUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    centerRegionUpdate: value(state, props),
    centerRegionVisible: state.get('centerBrandReducer').centerRegionVisible,
    centerRegionData: state.get('centerBrandReducer').centerRegionData,
    centerBrandReducer: state.get('centerBrandReducer').form_data,
    additionReducer: state.get('additionReducer').form_data
});

const mapDispatchToProps = {
    setCenterRegionVisible,
    deleteLimit,
    setCenterRegionData,
    setLimit,
    setLimitAdd,
    getAddressList,
    setModalCenterBrand,
    setCenterRegionUpdate,
    formDataSetCenterBrand
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(CenterRegionList)));
