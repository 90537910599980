import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {
    setFranchiseOrderAddVisible, setFranchiseOrderAddData, setFranchiseOrderListAddData, setFranchiseOrder
} from '../../../../redux/modules/centerbrand';
import {
    getStock
} from '../../../../redux/modules/stock';
import {
    setModal
} from '../../../../redux/modules/settings';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import { CapitalizeText, ComplateLowerText } from '../../../../function/GeneralFunction';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            decimalScale={2}
            allowNegative={false}
            fixedDecimalScale={true}
            decimalSeparator="."
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    },
                });
            }}
            thousandSeparator
            autocomplete="off"
        />
    );
}

function renderInput(inputProps) {
    const { classes, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: ref,
                classes: {
                    input: classes.input,
                },
                ...other,
            }}
        />
    );
}

function renderSuggestionsContainer(options) {
    const { containerProps, children } = options;

    return (
        <Paper {...containerProps} square>
            {children}
        </Paper>
    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => (
                    part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </strong>
                    )
                ))}
            </div>
        </MenuItem>
    );
}

class FranchiseOrderAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = { search: "", value: '', suggestions: [], inputList: {} }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.franchiseOrderAddUpdate == this.props.franchiseOrderAddUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        await this.props.getStock(false).then(() => { });
    }

    getProductName(StockId) {
        var proName = "";
        if (this.props.productListSearch.filter(x => x.Id == StockId).length > 0) {
            proName = this.props.productListSearch.find(x => x.Id == StockId).Name;
        }
        return proName;
    }

    getProductNameUnit(StockId, UnitId) {
        var UnitName = "";
        if (this.props.productListSearch.filter(x => x.Id == StockId).length > 0) {
            if (this.props.productListSearch.find(x => x.Id == StockId).Unit.filter(x => x.Id == UnitId).length > 0) {
                UnitName = this.props.productListSearch.find(x => x.Id == StockId).Unit.find(x => x.Id == UnitId).UnitName;
            }
        }
        return UnitName;
    }

    productAutoComplate(props) {
        const { productListSearch } = this.props;
        const suggestionsApi = [];

        productListSearch.map((pro, key) => {
            suggestionsApi.push({ label: pro.Name, value: pro.Id });
        });

        return suggestionsApi;
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0 ? [] : this.productAutoComplate().filter(suggestion => {
            //onst keep = count < 10 && suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;
            //console.log("suggestion.label.toLowerCase().indexOf(inputValue)",suggestion.label.toLowerCase().indexOf(inputValue))
            const keep = count < 15 && suggestion.label.toLowerCase().indexOf(inputValue) !== -1;
            if (keep) {
                count += 1;
            }

            return keep;
        });
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    getSuggestionValue = (suggestion) => {
        const { franchiseOrderAddData } = this.props;
        franchiseOrderAddData.StockId = suggestion.value;
        this.props.setFranchiseOrderAddData(franchiseOrderAddData).then(() => { });

        return suggestion.label;
    }

    renderMenuItemAuto() {
        const { classes, franchiseOrderAddData } = this.props;
        const { suggestions, inputList } = this.state;
        return (
            <Autosuggest
                theme={{
                    input: classes.inputText,
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,

                }}
                renderInputComponent={renderInput}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    classes,
                    placeholder: 'Lütfen Ürün Seçiniz',
                    value: franchiseOrderAddData.StockName || '',
                    onChange: this.handleChangeMenuSelect
                }}
            />
        )
    }

    handleChangeMenuSelect = (event, { newValue }) => {
        const { franchiseOrderAddData } = this.props;
        franchiseOrderAddData["StockName"] = newValue;
        this.props.setFranchiseOrderAddData(franchiseOrderAddData).then(() => { });
    };

    handleFranchiseOrderChange = name => event => {
        const { franchiseOrderAddData } = this.props;
        franchiseOrderAddData[name] = (name == "Quantity" ? parseFloat(event.target.value) : event.target.value);
        this.props.setFranchiseOrderAddData(franchiseOrderAddData).then(() => { })
    };

    renderUnits(StockId) {
        var Units = [];
        if (StockId != '00000000-0000-0000-0000-000000000000') {
            Units = this.props.productListSearch.find(x => x.Id == StockId).Unit;
        }
        return Units;
    }

    clickOrderAdd() {
        const { franchiseOrderAddData, franchiseOrderAddDataList } = this.props;
        if (franchiseOrderAddData.StockId == '00000000-0000-0000-0000-000000000000') {
            this.props.setModal("Ürün seçmeniz gerekmektedir!").then(() => { });
            return;
        }
        if (franchiseOrderAddData.UnitId == '00000000-0000-0000-0000-000000000000') {
            this.props.setModal("Ürün birimi seçmeniz gerekmektedir!").then(() => { });
            return;
        }
        if (franchiseOrderAddData.Quantity <= 0) {
            this.props.setModal("Ürün miktarı 0 dan büyük olmalıdır!").then(() => { });
            return;
        }
        var stock = JSON.parse(JSON.stringify(franchiseOrderAddData));
        franchiseOrderAddDataList.push(stock);
        this.props.setFranchiseOrderListAddData(franchiseOrderAddDataList).then(() => { })
    }

    clickOrderAddDelete(i) {
        const { franchiseOrderAddDataList } = this.props;
        franchiseOrderAddDataList.splice(i, 1);
        this.props.setFranchiseOrderListAddData(franchiseOrderAddDataList).then(() => { })
    }

    renderOrderVisible() {
        const { franchiseOrderAddDataList } = this.props;
        var visible = false;
        if (franchiseOrderAddDataList.length > 0) {
            visible = franchiseOrderAddDataList[0].FranchiseOrderType == 0 ? false : true;
        }
        return visible;
    }

    render() {
        const { classes, franchiseOrderAddVisible, franchiseOrderAddData, franchiseOrderAddDataList } = this.props;

        return (
            <Dialog
                open={franchiseOrderAddVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'md'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <form autocomplete="off" style={{ display: 'contents' }} onSubmit={this.handleSubmit}>
                    <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                        {"Sipariş Oluşturma ve Düzenleme"}
                    </DialogTitle>
                    <DialogContent style={{ overflow: 'hidden auto' }}>
                        <Grid style={{ display: (this.renderOrderVisible() ? 'none' : '') }} container spacing={1}>
                            <Grid item md={3}>
                                <span style={{ fontSize: 12, color: '#d1d8e0' }}>{"Ürün Seçimi"}</span>
                            </Grid>
                            <Grid item md={3}>
                                <span style={{ fontSize: 12, color: '#d1d8e0' }}>{"Birim Seçimi"}</span>
                            </Grid>
                            <Grid item md={3}>
                                <span style={{ fontSize: 12, color: '#d1d8e0' }}>{"Sipariş Miktarı"}</span>
                            </Grid>
                            <Grid item md={3}>
                            </Grid>
                            <Grid item md={3}>
                                {this.renderMenuItemAuto()}
                            </Grid>
                            <Grid item md={3}>
                                <Select
                                    value={franchiseOrderAddData.UnitId}
                                    fullWidth
                                    onChange={this.handleFranchiseOrderChange("UnitId")}
                                    input={<Input name="Aylar" id="age-helper" />}>
                                    {
                                        this.renderUnits(franchiseOrderAddData.StockId).map((n, i) => {
                                            return <MenuItem value={n.Id}>{n.UnitName}</MenuItem>
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    value={franchiseOrderAddData.Quantity}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleFranchiseOrderChange("Quantity")}
                                    fullWidth
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <Button style={{ borderRadius: 5, height: 37 }} fullWidth variant='contained' color="primary" onClick={() => this.clickOrderAdd()}>{"Ekle"}</Button>
                            </Grid>
                        </Grid>
                        <Table className={classNames(classes.table, classes.stripped)}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '30%' }} align="left">{"Stok Adı"}</TableCell>
                                    <TableCell style={{ width: '30%' }} align="left">{"Birim Adı"}</TableCell>
                                    <TableCell style={{ width: '30%' }} align="left">{"Miktar"}</TableCell>
                                    <TableCell style={{ width: '10%' }} align="right">{"İşlem"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    franchiseOrderAddDataList.map((n, i) => ([
                                        <TableRow hover key={n.Id}>
                                            <TableCell align="left">{(n.StockId != "00000000-0000-0000-0000-000000000000" ? this.getProductName(n.StockId) : "")}</TableCell>
                                            <TableCell align="left">{(n.UnitId != "00000000-0000-0000-0000-000000000000" ? this.getProductNameUnit(n.StockId, n.UnitId) : "")}</TableCell>
                                            <TableCell align="left">{n.Quantity.toFixed(3)}</TableCell>
                                            <TableCell align="right">
                                                <Tooltip title={"Sil"}>
                                                    <IconButton
                                                        disabled={this.renderOrderVisible()}
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.clickOrderAddDelete(i)}>
                                                        <Icon>{"delete"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ]))
                                }
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={this.renderOrderVisible()} onClick={() => this.props.setFranchiseOrder().then((n, i) => { })} variant="contained" color="secondary">
                            {"Kaydet"}
                        </Button>
                        <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setFranchiseOrderAddVisible(false).then(() => { })} color="primary">
                            {"Çıkış"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').franchiseOrderAddUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    franchiseOrderAddUpdate: value(state, props),
    franchiseOrderAddVisible: state.get('centerBrandReducer').franchiseOrderAddVisible,
    franchiseOrderAddData: state.get('centerBrandReducer').franchiseOrderAddData,
    franchiseOrderAddDataList: state.get('centerBrandReducer').franchiseOrderAddDataList,
    productListSearch: state.get('stockReducer').form_data.productListSearch
});

const mapDispatchToProps = {
    setFranchiseOrderAddVisible,
    getStock,
    setFranchiseOrderAddData,
    setFranchiseOrderListAddData,
    setModal,
    setFranchiseOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FranchiseOrderAdd));
