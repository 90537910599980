import axios from 'axios';
var base64 = require('base-64');
const uuidv4 = require('uuid/v4');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { getParameter } from '../../function/GeneralFunction';
import { push } from 'connected-react-router';
import { SET_PAKETYOLDA_COURIERLIST_DATA } from './paketyolda';
import moment from 'moment';

export const REPORT_START = 'report_start';
export const REPORT_STOP = 'report_stop';
export const REPORT_ERROR = 'report_error';
export const REPORT_GLOBAL_FORM_DATA_SET = 'report_global_form_data_set';
export const REPORT_MODALCLOSE = 'report_modalclose';

export const REPORTLOADING_START = 'reportloading_start';
export const REPORTLOADING_STOP = 'reportloading_stop';

export const ADDITIONLIST_UPDATE_REPORT = 'additionlist_update_report';
export const ADDITIONLIST_LOADING = 'additionlist_loading';

export const CURRENTACCOUNT_UPDATE_REPORT = 'currentaccount_update_report';
export const CURRENTACCOUNT_LOADING = 'currentaccount_loading';

export const PAKETYOLDA_UPDATE_REPORT = 'paketyolda_update_report';
export const PAKETYOLDA_LOADING = 'paketyolda_loading';
export const PAKETYOLDA_DATA = 'paketyolda_data';
export const PAKETYOLDA_FILTER = 'paketyolda_filter';

export const STOCKMOVEMENTLIST_UPDATE_REPORT = 'stockmovementlist_update_report';
export const STOCKMOVEMENTLIST_LOADING = 'stockmovementlist_loading';

export const STOCKRECIPELIST_UPDATE_REPORT = 'stockrecipelist_update_report';
export const STOCKRECIPELIST_LOADING = 'stockrecipelist_loading';
export const STOCKRECIPELIST_CLEAR = 'stockrecipelist_clear';

export const REPORTPAGE_VISIBLE = 'reportpage_visible';
export const SET_REPORTPAGE_NAME = 'set_reportpage_name';

export const SET_ENDOFDAY_FILTER_DATA = 'set_endofday_filter_data';
export const SET_ENDOFDAY_DATA = 'set_endofday_data';
export const SET_ENDOFDAY_LOADING = 'set_endofday_loading';
export const SET_ENDOFDAY_UPDATE = 'set_endofday_update';

export const SET_HOURPERFORMANCE_FILTER_DATA = 'set_hourperformance_filter_data';
export const SET_HOURPERFORMANCE_DATA = 'set_hourperformance_data';
export const SET_HOURPERFORMANCE_LOADING = 'set_hourperformance_loading';
export const SET_HOURPERFORMANCE_UPDATE = 'set_hourperformance_update';

export const SET_MOUNTLYPERFORMANCE_FILTER_DATA = 'set_moutlyperformance_filter_data';
export const SET_MOUNTLYPERFORMANCE_DATA = 'set_moutlyperformance_data';
export const SET_MOUNTLYPERFORMANCE_LOADING = 'set_moutlyperformance_loading';
export const SET_MOUNTLYPERFORMANCE_UPDATE = 'set_moutlyperformance_update';

export const SET_COURIERREPORT_FILTER_DATA = 'set_courierreport_filter_data';
export const SET_COURIERREPORT_DATA = 'set_courierreport_data';
export const SET_COURIERREPORT_LOADING = 'set_courierreport_loading';
export const SET_COURIERREPORT_UPDATE = 'set_courierreport_update';

export const SET_USERREPORT_FILTER_DATA = 'set_userreport_filter_data';
export const SET_USERREPORT_DATA = 'set_userreport_data';
export const SET_USERREPORT_LOADING = 'set_userreport_loading';
export const SET_USERREPORT_UPDATE = 'set_userreport_update';

export const SET_PAKETYOLDAREPORT_FILTER_DATA = 'set_paketyoldareport_filter_data';
export const SET_PAKETYOLDAREPORT_DATA = 'set_paketyoldareport_data';
export const SET_PAKETYOLDAREPORT_LOADING = 'set_paketyoldareport_loading';
export const SET_PAKETYOLDAREPORT_UPDATE = 'set_paketyoldareport_update';

export const SET_ADDITIONREPORT_FILTER_DATA = 'set_additionreport_filter_data';
export const SET_ADDITIONREPORT_DATA = 'set_additionreport_data';
export const SET_ADDITIONREPORT_LOADING = 'set_additionreport_loading';
export const SET_ADDITIONREPORT_UPDATE = 'set_additionreport_update';

export const SET_CURRENTACCOUNTREPORT_DATA = 'set_currentaccountreport_data';
export const SET_CURRENTACCOUNTREPORT_LOADING = 'set_currentaccountreport_loading';
export const SET_CURRENTACCOUNTREPORT_UPDATE = 'set_currentaccountreport_update';

export const SET_PRODUCTSALESREPORT_FILTER_DATA = 'set_productsalesreport_filter_data';
export const SET_PRODUCTSALESREPORT_DATA = 'set_productsalesreport_data';
export const SET_PRODUCTSALESREPORT_LOADING = 'set_productsalesreport_loading';
export const SET_PRODUCTSALESREPORT_UPDATE = 'set_productsalesreport_update';

export const SET_CURRENTACCOUNTREPORT_FILTER_DATA = 'set_currentaccountreport_filter_data';

export const SET_PRODUCTMOVEMENTREPORT_FILTER_DATA = 'set_productmovementreport_filter_data';
export const SET_PRODUCTMOVEMENTREPORT_DATA = 'set_productmovementreport_data';
export const SET_PRODUCTMOVEMENTREPORT_LOADING = 'set_productmovementreport_loading';
export const SET_PRODUCTMOVEMENTREPORT_UPDATE = 'set_productmovementreport_update';

export const SET_PRODUCTCOSTREPORT_FILTER_DATA = 'set_productcostreport_filter_data';
export const SET_PRODUCTCOSTREPORT_DATA = 'set_productcostreport_data';
export const SET_PRODUCTCOSTREPORT_LOADING = 'set_productcostreport_loading';
export const SET_PRODUCTCOSTREPORT_UPDATE = 'set_productcostreport_update';

export const SET_PAKETYOLDABRANDREPORT_DATA = 'set_paketyoldabrandreport_data';
export const SET_PAKETYOLDABRANDREPORT_DATA_ORDER = 'set_paketyoldabrandreport_data_order';
export const SET_PAKETYOLDABRANDREPORT_LOADING = 'set_paketyoldabrandreport_loading';
export const SET_PAKETYOLDABRANDREPORT_UPDATE = 'set_paketyoldabrandreport_update';

export const SET_ENTEGRATIONREPORT_DATA = 'set_entegrationreport_data';
export const SET_ENTEGRATIONREPORT_LOADING = 'set_entegrationreport_loading';
export const SET_ENTEGRATIONREPORT_UPDATE = 'set_entegrationreport_update';

export const SET_ADDITIONLISTMODAL_VISIBLE = 'set_additionlistmodal_visible';

export const SETPAKETYOLDA_COURIERLIST_DATA = 'set_paketyolda_courierlist_data';

export const SET_STAFFREPORT_DATA = 'set_staffreport_data';
export const SET_STAFFREPORT_LOADING = 'set_staffreport_loading';
export const SET_STAFFREPORT_UPDATE = 'set_staffreport_update';

export const SET_STAFFREPORT_MOUNTH_SELECT = 'set_staffreport_mounth_select';

export const SET_KITCHENREPORT_FILTER_DATA = 'set_kitchenreport_filter_data';
export const SET_KITCHENREPORT_DATA = 'set_kitchenreport_data';
export const SET_KITCHENREPORT_LOADING = 'set_kitchenreport_loading';
export const SET_KITCHENREPORT_UPDATE = 'set_kitchenreport_update';

var TimeData = getParameter("BusinessWorkingHours");

let initialState = {
  form_data: {
    modalOpen: false,
    modalTitle: "",
    modalText: "",
    filterData: {
      StartDate: new Date(TimeData.start),
      EndDate: new Date(TimeData.end),
      TenantId: "",
      BrandId: "",
      IsCourierOpenAddition: false
    },
    filterDataHour: {
      Date: new Date(new Date().setHours(0, 0, 0, 0)),
      TenantId: "",
      BrandId: ""
    },
    filterDataMountly: {
      Mountly: new Date(),
      TenantId: "",
      BrandId: ""
    },
    ReportData: [],
    r_GeneralTotalData: [],
    r_CourierReportData: [],
    r_UserReportData: [],
    r_ProductReportData: [],
    AdditionListData: [],
    r_HourData: [],
    r_MountlyData: [],
    reportListData:
      [
        { Name: "Gün Sonu", IsSelected: true, Icon: "bar_chart" },
        { Name: "Ürün Satış", IsSelected: false, Icon: "show_chart" },
        { Name: "Kurye Performans", IsSelected: false, Icon: "motorcycle" },
        { Name: "Kullanıcı Performans", IsSelected: false, Icon: "supervised_user_circle" },
        { Name: "Adisyon Raporu", IsSelected: false, Icon: "vertical_split" },
        { Name: "Harita", IsSelected: false, Icon: "history" },
        { Name: "Açık Hesap", IsSelected: false, Icon: "vertical_split" },
      ]
  },
  reportLoading: false,
  filterAdditionData: {
    sortColumnName: "CreationTime",
    sortOrder: "desc",
    totalRecord: 0,
    currentPage: 1,
    rowsPerPage: 50,
    searchText: "",
    BrandId: "",
    totalPage: 0,
    List: []
  },
  additionListLoading: false,
  additionListUpdate: false,
  filterCurrentAccount: {
    sortColumnName: "NameSurname",
    sortOrder: "asc",
    totalRecord: 0,
    currentPage: 1,
    rowsPerPage: 50,
    searchText: "",
    BrandId: "",
    totalPage: 0,
    List: [],
    BalanceStatus: -1
  },
  currentAccountLoading: false,
  currentAccountUpdate: false,

  paketYoldaLoading: false,
  paketYoldaUpdate: false,
  paketYoldaData: [],
  paketYoldaFilter: {
    startDate: "",
    endDate: "",
    BrandId: "",
    TableType: "All",
    PackageUserId: "00000000-0000-0000-0000-000000000000"
  },
  filterStockMovementListData: {
    BrandId: "",
    searchText: "",
    List: []
  },
  stockMovementListLoading: false,
  stockMovementListUpdate: false,
  filterStockRecipeListData: {
    BrandId: "",
    List: []
  },
  stockRecipeListLoading: false,
  stockRecipeListUpdate: false,


  IsReportPageVisible: false,
  IsReportPageUpdate: false,

  EndOfDayFilterData: {
    StartDate: new Date(TimeData.start),
    EndDate: new Date(TimeData.end),
    TenantId: "",
    BrandId: ""
  },
  EndOfDayReport: null,
  EndOfDayLoading: false,
  ReportName: "",

  HourPerformanceFilterData: {
    Date: new Date(new Date().setHours(0, 0, 0, 0)),
    TenantId: "",
    BrandId: ""
  },
  HourPerformanceReport: null,
  HourPerformanceLoading: false,
  IsReportHourPerformanceUpdate: false,

  MountlyPerformanceFilterData: {
    Mountly: new Date(),
    TenantId: "",
    BrandId: ""
  },
  MountlyPerformanceReport: null,
  MountlyPerformanceLoading: false,
  IsReportMountlyPerformanceUpdate: false,

  CourierReportFilterData: {
    StartDate: new Date(TimeData.start),
    EndDate: new Date(TimeData.end),
    TenantId: "",
    BrandId: "",
    IsCourierOpenAddition: false
  },
  CourierReport: null,
  CourierReportLoading: false,
  IsCourierReportUpdate: false,

  UserReportFilterData: {
    StartDate: new Date(TimeData.start),
    EndDate: new Date(TimeData.end),
    TenantId: "",
    BrandId: ""
  },
  UserReport: null,
  UserReportLoading: false,
  IsUserReportUpdate: false,

  PaketYoldaReportFilterData: {
    startDate: new Date(TimeData.start),
    endDate: new Date(TimeData.end),
    BrandId: "",
    TableType: "All",
    PackageUserId: "00000000-0000-0000-0000-000000000000"
  },
  PaketYoldaReport: [],
  PaketYoldaReportLoading: false,
  IsPaketYoldaReportUpdate: false,

  AdditionReportFilterData: {
    startDate: new Date(TimeData.start),
    endDate: new Date(TimeData.end),
    sortColumnName: "CreationTime",
    sortOrder: "desc",
    totalRecord: 0,
    currentPage: 1,
    rowsPerPage: 50,
    searchText: "",
    BrandId: "",
    totalPage: 0,
    OrderType: 2
  },
  AddditionReport: [],
  AddditionReportLoading: false,
  IsAddditionReportUpdate: false,
  CurrentAccountReport: {
    Results: [],
    PageSize: 0,
    RowCount: 0,
    CurrentPage: 0
  },
  CurrentAccountReportLoading: false,
  IsCurrentAccountReportUpdate: false,

  ProductSalesReportFilterData: {
    StartDate: new Date(TimeData.start),
    EndDate: new Date(TimeData.end),
    sortColumnName: "NameSurname",
    sortOrder: "desc",
    totalRecord: 0,
    currentPage: 1,
    rowsPerPage: 50,
    searchText: "",
    BrandId: "",
    totalPage: 0,
    IsCategoryGroup: false,
    IsEntegrationListAddition: false,
    TableType: "All"
  },
  ProductSalesReport: [],
  ProductSalesReportLoading: false,
  IsProductSalesReportUpdate: false,

  ProductMovementReportFilterData: {
    BrandId: "",
    searchText: ""
  },
  ProductMovementReport: [],
  ProductMovementReportLoading: false,
  IsProductMovementReportUpdate: false,

  ProductCostReportFilterData: {
    BrandId: "",
    StartDate: new Date(TimeData.start),
    EndDate: new Date(TimeData.end),
    IsMainStock: false
  },
  ProductCostRecipeReport: [],
  ProductCostReport: [],
  ProductCostReportLoading: false,
  IsProductCostReportUpdate: false,

  PaketYoldaBrandReport: null,
  PaketYoldaBrandReportPayment: [],
  PaketYoldaBrandReportLoading: false,
  IsPaketYoldaBrandReportUpdate: false,

  EntegrationReport: [],
  EntegrationReportLoading: false,
  IsEntegrationReportUpdate: false,

  IsAdditionListModalUpdate: false,
  IsAdditionListModalVisible: false,

  PaketYoldaCourierList: [],

  StaffReport: {
    Results: [],
    PageSize: 0,
    RowCount: 0,
    CurrentPage: 0
  },
  StaffReportLoading: false,
  IsStaffReportUpdate: false,
  StaffSelectMounth: new Date().getMonth(),

  KitchenReportFilter: {
    BrandId: cookie.load("BrandId"),
    TenantId: cookie.load("TenantId"),
    CenterBrandId: cookie.load("CenterBrandId"),
    StartDatetime: moment.utc(new Date(TimeData.start), 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm"),
    EndDatetime: moment.utc(new Date(TimeData.end), 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm"),
    KitchenUserId: "00000000-0000-0000-0000-000000000000",
    ProductId: "00000000-0000-0000-0000-000000000000"
  },
  IsKitchenReportUpdate: false,
  IsKitchenReportLoading: false,
  KitchenReportData: {
    KitchenProductReport: [],
    KitchenHourData: [],
    KitchenStaffData: []
  },
  CurrentAccountReportFilter: {
    BrandId: cookie.load("BrandId"),
    page: 1,
    searchText: "",
    CustomerType: 0,
    BalanceStatus: 1
  },
}

export async function RequestStockMovementList(filterData) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_PRODUCTMOVEMENTREPORT_LOADING, payload: true });
    const { ProductMovementReportFilterData } = getState().get('reportReducer');
    ProductMovementReportFilterData["TenantId"] = cookie.load('TenantId');
    ProductMovementReportFilterData["BrandId"] = cookie.load('BrandId');

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/getStockMovementList',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: ProductMovementReportFilterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_PRODUCTMOVEMENTREPORT_DATA, payload: json.List });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_PRODUCTMOVEMENTREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function RequestPaketYoldaBrandReportListOrder(startDate, endDate, courierId, page) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_PAKETYOLDABRANDREPORT_LOADING, payload: true });
    var reportFilter = {
      StartDatetime: startDate,
      EndDatetime: endDate,
      JaviPosBrandId: cookie.load("BrandId"),
      CourierId: courierId,
      BrandId: cookie.load("PaketYoldaId"),
      page: page
    };

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocketPaketYolda + 'api/Report/getOrderGeneralReportList',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: reportFilter,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_PAKETYOLDABRANDREPORT_DATA_ORDER, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_PAKETYOLDABRANDREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function RequestPaketYoldaBrandReportList(startDate, endDate, courierId, page) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_PAKETYOLDABRANDREPORT_LOADING, payload: true });
    var reportFilter = {
      StartDatetime: startDate,
      EndDatetime: endDate,
      JaviPosBrandId: cookie.load("BrandId"),
      CourierId: courierId,
      BrandId: cookie.load("PaketYoldaId"),
      page: page
    };

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocketPaketYolda + 'api/Report/getOrderGeneralReport',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: reportFilter,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_PAKETYOLDABRANDREPORT_DATA, payment: json.PaymentData });
          dispatch(RequestPaketYoldaBrandReportListOrder(startDate, endDate, courierId, page));
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_PAKETYOLDABRANDREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function RequestEntegrationReportList(startDate, endDate) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ENTEGRATIONREPORT_LOADING, payload: true });
    var reportFilter = {
      StartDate: startDate,
      EndDate: endDate,
      BrandId: cookie.load("BrandId"),
      TenantId: cookie.load("TenantId")
    };

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/filterEntegrationReport',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: reportFilter,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_ENTEGRATIONREPORT_DATA, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
        }
      })
      .catch(function (error) {
        alert(error);
      });
    dispatch({ type: SET_ENTEGRATIONREPORT_LOADING, payload: false });
  };
}

export async function RequestRecipeList(ProductTabsValue) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_PRODUCTCOSTREPORT_LOADING, payload: true });
    const { ProductCostReportFilterData } = getState().get('reportReducer');
    ProductCostReportFilterData["TenantId"] = cookie.load('TenantId');
    ProductCostReportFilterData["BrandId"] = cookie.load('BrandId');
    ProductCostReportFilterData.IsMainStock = ProductTabsValue;

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/getRecipeList',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: ProductCostReportFilterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_PRODUCTCOSTREPORT_DATA, payload: json.List, tabs: ProductTabsValue });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_PRODUCTCOSTREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        dispatch({ type: SET_PRODUCTCOSTREPORT_LOADING, payload: false });
      });
  };
}

export async function RequestAdditionReport(startDate, endDate) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ADDITIONREPORT_LOADING, payload: true });
    const { AdditionReportFilterData } = getState().get('reportReducer');
    AdditionReportFilterData.BrandId = cookie.load("BrandId");
    AdditionReportFilterData.TenantId = cookie.load("TenantId");
    AdditionReportFilterData.List = [];

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/getAdditionList',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: AdditionReportFilterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          json.startDate = startDate;
          json.endDate = endDate;
          dispatch({ type: SET_ADDITIONREPORT_DATA, payload: json.List, filter: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_ADDITIONREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function RequestStaffReport(page = 1) {
  return async (dispatch, getState) => {
    const { StaffSelectMounth } = getState().get('reportReducer');

    dispatch({ type: SET_STAFFREPORT_LOADING, payload: true });

    var filter = {
      BrandId: cookie.load('BrandId'),
      page: page,
      ChosenMonth: StaffSelectMounth
    };

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Customer/filterStaffReport',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: filter,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setReportStaffData(json));
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_STAFFREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function RequestCurrentAccountReport(page = 1) {
  return async (dispatch, getState) => {
    const { CurrentAccountReportFilter } = getState().get('reportReducer');

    dispatch({ type: SET_CURRENTACCOUNTREPORT_LOADING, payload: true });

    CurrentAccountReportFilter.page = page;

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/getCurrentAccount',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: CurrentAccountReportFilter,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_CURRENTACCOUNTREPORT_DATA, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_CURRENTACCOUNTREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function RequestPaketYoldaReport() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_HOURPERFORMANCE_LOADING, payload: true });
    const { PaketYoldaReportFilterData } = getState().get('reportReducer');
    PaketYoldaReportFilterData.BrandId = cookie.load("BrandId");
    PaketYoldaReportFilterData.TenantId = cookie.load("TenantId");

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/getPaketYoldaReport',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: PaketYoldaReportFilterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_PAKETYOLDAREPORT_DATA, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_PAKETYOLDAREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function RequestFilterHourReport() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_HOURPERFORMANCE_LOADING, payload: true });
    const { HourPerformanceFilterData } = getState().get('reportReducer');
    HourPerformanceFilterData["TenantId"] = cookie.load('TenantId');
    HourPerformanceFilterData["BrandId"] = cookie.load('BrandId');

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/filterHourReport',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: HourPerformanceFilterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_HOURPERFORMANCE_DATA, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_HOURPERFORMANCE_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function redirectMainPage(redirect) {
  return async (dispatch, getState) => {
    if (cookie.load('UserType') == "Manager") {
      dispatch(setReportPageEndOfDayData({
        StartDate: new Date(TimeData.start),
        EndDate: new Date(TimeData.end),
        TenantId: "",
        BrandId: ""
      }))
      dispatch(setReportPageHourPerformanceData({
        Date: new Date(new Date().setHours(0, 0, 0, 0)),
        TenantId: "",
        BrandId: ""
      }))
      dispatch(RequestFilterHourReport())
      dispatch(RequestEndOfDayReport())
    }
    if (redirect) {
      dispatch(push('/mainpage'))
    }
  };
}

export async function RequestFilterMountlyReport() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_MOUNTLYPERFORMANCE_LOADING, payload: true });
    const { MountlyPerformanceFilterData } = getState().get('reportReducer');
    MountlyPerformanceFilterData["TenantId"] = cookie.load('TenantId');
    MountlyPerformanceFilterData["BrandId"] = cookie.load('BrandId');

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/filterMountlyReport',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: MountlyPerformanceFilterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_MOUNTLYPERFORMANCE_DATA, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_MOUNTLYPERFORMANCE_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });

    dispatch({ type: REPORTLOADING_STOP });
  };
}

export async function RequestEndOfDayReport() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ENDOFDAY_LOADING, payload: true });
    const { EndOfDayFilterData } = getState().get('reportReducer');
    EndOfDayFilterData["TenantId"] = cookie.load('TenantId');
    EndOfDayFilterData["BrandId"] = cookie.load('BrandId');

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/EndOfDay',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: EndOfDayFilterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_ENDOFDAY_DATA, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_ENDOFDAY_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function RequestCourierReport() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ENDOFDAY_LOADING, payload: true });
    const { CourierReportFilterData } = getState().get('reportReducer');
    CourierReportFilterData["TenantId"] = cookie.load('TenantId');
    CourierReportFilterData["BrandId"] = cookie.load('BrandId');

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/filterCourierReport',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: CourierReportFilterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_COURIERREPORT_DATA, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_COURIERREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });

    dispatch({ type: REPORTLOADING_STOP });
  };
}

export async function RequestUserReport() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_USERREPORT_LOADING, payload: true });
    const { UserReportFilterData } = getState().get('reportReducer');
    UserReportFilterData["TenantId"] = cookie.load('TenantId');
    UserReportFilterData["BrandId"] = cookie.load('BrandId');

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/filterUserReport',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: UserReportFilterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_USERREPORT_DATA, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_USERREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });

    dispatch({ type: REPORTLOADING_STOP });
  };
}

export async function RequestKitchenReport() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_KITCHENREPORT_LOADING, payload: true });
    const { KitchenReportFilter } = getState().get('reportReducer');

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Kitchen/reportKitchen',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: KitchenReportFilter,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_KITCHENREPORT_DATA, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_KITCHENREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function RequestProductReport() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_PRODUCTSALESREPORT_LOADING, payload: true });
    const { ProductSalesReportFilterData } = getState().get('reportReducer');
    ProductSalesReportFilterData["TenantId"] = cookie.load('TenantId');
    ProductSalesReportFilterData["BrandId"] = cookie.load('BrandId');
    ProductSalesReportFilterData["CenterBrandId"] = cookie.load('CenterBrandId');

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Report/filterProductReportNew',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: ProductSalesReportFilterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_PRODUCTSALESREPORT_DATA, payload: json });
        } else {
          dispatch(setModalReport(response.data.responseData));
          dispatch({ type: SET_PRODUCTSALESREPORT_LOADING, payload: false });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function getAdditionListExcel() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ADDITIONREPORT_LOADING, payload: true });
    const { AdditionReportFilterData } = getState().get('reportReducer');
    AdditionReportFilterData.BrandId = cookie.load("BrandId");
    AdditionReportFilterData.TenantId = cookie.load("TenantId");
    AdditionReportFilterData.List = [];

    axios({
      url: brand.urlSocket + 'api/Report/getAdditionListExcel',
      method: 'POST',
      responseType: 'blob',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: AdditionReportFilterData,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'AdditionDetailSales.xlsx');
      document.body.appendChild(link);
      link.click();
      dispatch({ type: SET_ADDITIONREPORT_LOADING, payload: false });
    }).catch(function (error) {
      dispatch({ type: SET_ADDITIONREPORT_LOADING, payload: false });
    });
  };
}

export async function filterProductReportDownload() {
  return async (dispatch, getState) => {
    const { ProductSalesReportFilterData, ProductSalesReport } = getState().get('reportReducer');

    const list = [];
    ProductSalesReport.ProductData.map((n, i) => {
      list.push({
        ProId: n.ProId, ProName: n.ProName, TableUserName: n.TableUserName, TotalCount: Number(n.TotalCount), TotalPrice: Number(n.TotalPrice).toFixed(2), PropertyItems: n.PropertyItems, MenuItems: n.MenuItems, ProductUnit: n.ProductUnit, TotalCountEntegration: n.TotalCountEntegration, TotalCountEntegrationSum: n.TotalCountEntegrationSum
      });
    });

    axios({
      url: brand.urlReport + 'api/Report/DownloadExcelDocument',
      method: 'POST',
      responseType: 'blob',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: list,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'UrunBazindaSatisRaporu.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  };
}

export async function modalClose() {
  return async (dispatch, getState) => {
    dispatch({ type: STOCK_MODALCLOSE });
  }
}

export async function setPaketYoldaFilter(filterdata) {
  return async (dispatch, getState) => {
    dispatch({ type: PAKETYOLDA_FILTER, payload: filterdata });
  }
}

export async function formDataSetReport(key, val) {
  return async (dispatch, getState) => {
    dispatch({
      type: REPORT_GLOBAL_FORM_DATA_SET,
      payload: { key, val }
    });
  };
}

export async function setModalReport(text) {
  return async (dispatch, getState) => {
    dispatch({
      type: REPORT_ERROR,
      payload: text
    });
  };
}

export async function modalCloseReport() {
  return async (dispatch, getState) => {
    dispatch({
      type: REPORT_MODALCLOSE
    });
  };
}

export async function filterStockRecipeListDataClear() {
  return async (dispatch, getState) => {
    dispatch({
      type: STOCKRECIPELIST_CLEAR
    });
  };
}

export async function setReportPageVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: REPORTPAGE_VISIBLE,
      payload: visible
    });
  };
}

export async function getPaketYoldaCourierList() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocketPaketYolda + 'api/Courier/getPaketYoldaCourierList?BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({
            type: SET_PAKETYOLDA_COURIERLIST_DATA,
            payload: json
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export async function setReportPageName(data) {
  return async (dispatch, getState) => {
    if (data == "PaketYoldaReport") {
      await dispatch(getPaketYoldaCourierList());
    }
    dispatch({
      type: SET_REPORTPAGE_NAME,
      payload: data
    });
  };
}

export async function setReportPageEndOfDayData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ENDOFDAY_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPageEndOfUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ENDOFDAY_UPDATE
    });
  };
}

export async function setReportPageHourPerformanceData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_HOURPERFORMANCE_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPageHourPerformanceUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_HOURPERFORMANCE_UPDATE
    });
  };
}

export async function setReportPageMountlyPerformanceData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_MOUNTLYPERFORMANCE_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPageMountlyPerformanceUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_MOUNTLYPERFORMANCE_UPDATE
    });
  };
}

export async function setReportPageCourierReportData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_COURIERREPORT_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPageCourierReportUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_COURIERREPORT_UPDATE
    });
  };
}

export async function setReportPageUserReportData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_USERREPORT_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPageUserReportUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_USERREPORT_UPDATE
    });
  };
}

export async function setReportPagePaketYoldaReportData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PAKETYOLDAREPORT_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPagePaketYoldaReportUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PAKETYOLDAREPORT_UPDATE
    });
  };
}

export async function setReportPageAdditionReportData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ADDITIONREPORT_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPageKitchenReportData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_KITCHENREPORT_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPageAdditionReportUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ADDITIONREPORT_UPDATE
    });
  };
}

export async function setReportPageKitchenReportUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_KITCHENREPORT_UPDATE
    });
  };
}

export async function setReportPageCurrentAccountReportUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CURRENTACCOUNTREPORT_UPDATE
    });
  };
}

export async function setReportPageProductSalesReportData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PRODUCTSALESREPORT_FILTER_DATA,
      payload: data
    });
  };
}


export async function setReportPageCurrentAccountData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CURRENTACCOUNTREPORT_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPageProductSalesReportUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PRODUCTSALESREPORT_UPDATE
    });
  };
}

export async function setReportPageProductMovementReportData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PRODUCTMOVEMENTREPORT_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPageProductMovementReportUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PRODUCTMOVEMENTREPORT_UPDATE
    });
  };
}

export async function setReportPageProductCostReportData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PRODUCTCOSTREPORT_FILTER_DATA,
      payload: data
    });
  };
}

export async function setReportPageProductCostReportUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PRODUCTCOSTREPORT_UPDATE
    });
  };
}

export async function setReportPagePaketYoldaUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PAKETYOLDABRANDREPORT_UPDATE
    });
  };
}

export async function setReportEntegrationUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ENTEGRATIONREPORT_UPDATE
    });
  };
}

export async function setAdditionListModalVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ADDITIONLISTMODAL_VISIBLE,
      payload: visible
    });
    dispatch({
      type: SET_REPORTPAGE_NAME,
      payload: "AdditionReport"
    });
  };
}

export async function setReportStaffData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_STAFFREPORT_DATA,
      payload: data
    });
  };
}

export async function setReportStaffMounth(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_STAFFREPORT_MOUNTH_SELECT,
      payload: data
    });
  };
}

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFFREPORT_MOUNTH_SELECT:
      return { ...state, StaffSelectMounth: action.payload, IsStaffReportUpdate: !state.IsStaffReportUpdate }
    case SET_STAFFREPORT_LOADING:
      return { ...state, StaffReportLoading: action.payload, IsStaffReportUpdate: !state.IsStaffReportUpdate }
    case SET_STAFFREPORT_DATA:
      return { ...state, StaffReport: action.payload, IsStaffReportUpdate: !state.IsStaffReportUpdate, StaffReportLoading: false }
    case SET_ADDITIONLISTMODAL_VISIBLE:
      return { ...state, IsAdditionListModalUpdate: action.payload, IsAdditionListModalVisible: !state.IsAdditionListModalVisible }
    case SET_ENTEGRATIONREPORT_LOADING:
      return { ...state, EntegrationReportLoading: action.payload, IsEntegrationReportUpdate: !state.IsEntegrationReportUpdate }
    case SET_ENTEGRATIONREPORT_DATA:
      return { ...state, EntegrationReport: action.payload, IsEntegrationReportUpdate: !state.IsEntegrationReportUpdate, PaketYoldaBrandReportLoading: false }
    case SET_ENTEGRATIONREPORT_UPDATE:
      return { ...state, IsEntegrationReportUpdate: !state.IsEntegrationReportUpdate }
    case SET_PAKETYOLDA_COURIERLIST_DATA:
      return { ...state, PaketYoldaCourierList: action.payload, IsPaketYoldaBrandReportUpdate: !state.IsPaketYoldaBrandReportUpdate, PaketYoldaBrandReportLoading: false }
    case SET_PAKETYOLDABRANDREPORT_DATA_ORDER:
      return { ...state, PaketYoldaBrandReport: action.payload, IsPaketYoldaBrandReportUpdate: !state.IsPaketYoldaBrandReportUpdate, PaketYoldaBrandReportLoading: false }
    case SET_PAKETYOLDABRANDREPORT_DATA:
      return { ...state, PaketYoldaBrandReportPayment: action.payment, IsPaketYoldaBrandReportUpdate: !state.IsPaketYoldaBrandReportUpdate, PaketYoldaBrandReportLoading: false }
    case SET_PAKETYOLDABRANDREPORT_UPDATE:
      return { ...state, IsPaketYoldaBrandReportUpdate: !state.IsPaketYoldaBrandReportUpdate }
    case SET_PRODUCTCOSTREPORT_UPDATE:
      return { ...state, IsProductCostReportUpdate: !state.IsProductCostReportUpdate }
    case SET_PRODUCTCOSTREPORT_LOADING:
      return { ...state, ProductCostReportLoading: action.payload, IsProductCostReportUpdate: !state.IsProductCostReportUpdate }
    case SET_PRODUCTCOSTREPORT_DATA:
      return { ...state, ProductCostRecipeReport: (!action.tabs ? action.payload : state.ProductCostRecipeReport), ProductCostReport: (action.tabs ? action.payload : state.ProductCostReport), IsProductCostReportUpdate: !state.IsProductCostReportUpdate, ProductCostReportLoading: false }
    case SET_PRODUCTCOSTREPORT_FILTER_DATA:
      return { ...state, ProductCostReportFilterData: action.payload, IsProductCostReportUpdate: !state.IsProductCostReportUpdate }

    case SET_PRODUCTMOVEMENTREPORT_UPDATE:
      return { ...state, IsProductMovementReportUpdate: !state.IsProductMovementReportUpdate }
    case SET_PRODUCTMOVEMENTREPORT_LOADING:
      return { ...state, ProductMovementReportLoading: action.payload, IsProductMovementReportUpdate: !state.IsProductMovementReportUpdate }
    case SET_PRODUCTMOVEMENTREPORT_DATA:
      return { ...state, ProductMovementReport: action.payload, IsProductMovementReportUpdate: !state.IsProductMovementReportUpdate, ProductMovementReportLoading: false }
    case SET_PRODUCTMOVEMENTREPORT_FILTER_DATA:
      return { ...state, ProductMovementReportFilterData: action.payload, IsProductMovementReportUpdate: !state.IsProductMovementReportUpdate }

    case SET_PRODUCTSALESREPORT_UPDATE:
      return { ...state, IsProductSalesReportUpdate: !state.IsProductSalesReportUpdate }
    case SET_PRODUCTSALESREPORT_LOADING:
      return { ...state, ProductSalesReportLoading: action.payload, IsProductSalesReportUpdate: !state.IsProductSalesReportUpdate }
    case SET_PRODUCTSALESREPORT_DATA:
      return { ...state, ProductSalesReport: action.payload, IsProductSalesReportUpdate: !state.IsProductSalesReportUpdate, ProductSalesReportLoading: false }
    case SET_PRODUCTSALESREPORT_FILTER_DATA:
      return { ...state, ProductSalesReportFilterData: action.payload, IsProductSalesReportUpdate: !state.IsProductSalesReportUpdate }

      case SET_CURRENTACCOUNTREPORT_FILTER_DATA:
      return { ...state, CurrentAccountReportFilter: action.payload, IsCurrentAccountReportUpdate: !state.IsCurrentAccountReportUpdate }

    case SET_CURRENTACCOUNTREPORT_UPDATE:
      return { ...state, IsCurrentAccountReportUpdate: !state.IsCurrentAccountReportUpdate }
    case SET_CURRENTACCOUNTREPORT_LOADING:
      return { ...state, CurrentAccountReportLoading: action.payload, IsCurrentAccountReportUpdate: !state.IsCurrentAccountReportUpdate }
    case SET_CURRENTACCOUNTREPORT_DATA:
      return { ...state, CurrentAccountReport: action.payload, IsCurrentAccountReportUpdate: !state.IsCurrentAccountReportUpdate, CurrentAccountReportLoading: false }
    case SET_ADDITIONREPORT_UPDATE:
      return { ...state, IsAdditionReportUpdate: !state.IsAdditionReportUpdate }
    case SET_ADDITIONREPORT_LOADING:
      return { ...state, AdditionReportLoading: action.payload, IsAdditionReportUpdate: !state.IsAdditionReportUpdate }
    case SET_ADDITIONREPORT_DATA:
      return { ...state, AdditionReportFilterData: action.filter, AdditionReport: action.payload, IsAdditionReportUpdate: !state.IsAdditionReportUpdate, AdditionReportLoading: false }
    case SET_ADDITIONREPORT_FILTER_DATA:
      return { ...state, AdditionReportFilterData: action.payload, IsAdditionReportUpdate: !state.IsAdditionReportUpdate }

    case SET_KITCHENREPORT_FILTER_DATA:
      return { ...state, KitchenReportFilter: action.payload, IsKitchenReportUpdate: !state.IsKitchenReportUpdate }
    case SET_KITCHENREPORT_LOADING:
      return { ...state, ProductSalesReportLoading: action.payload, IsKitchenReportUpdate: !state.IsKitchenReportUpdate }
    case SET_KITCHENREPORT_DATA:
      return { ...state, KitchenReportData: action.payload, IsKitchenReportUpdate: !state.IsKitchenReportUpdate, IsKitchenReportLoading: false }

    case SET_PAKETYOLDAREPORT_UPDATE:
      return { ...state, IsPaketYoldaReportUpdate: !state.IsPaketYoldaReportUpdate }
    case SET_PAKETYOLDAREPORT_LOADING:
      return { ...state, PaketYoldaReportLoading: action.payload, IsPaketYoldaReportUpdate: !state.IsPaketYoldaReportUpdate }
    case SET_PAKETYOLDAREPORT_DATA:
      return { ...state, PaketYoldaReport: action.payload, IsPaketYoldaReportUpdate: !state.IsPaketYoldaReportUpdate, PaketYoldaReportLoading: false }
    case SET_PAKETYOLDAREPORT_FILTER_DATA:
      return { ...state, PaketYoldaReportFilterData: action.payload, IsPaketYoldaReportUpdate: !state.IsPaketYoldaReportUpdate }

    case SET_USERREPORT_UPDATE:
      return { ...state, IsUserReportUpdate: !state.IsUserReportUpdate }
    case SET_USERREPORT_LOADING:
      return { ...state, UserReportLoading: action.payload, IsUserReportUpdate: !state.IsUserReportUpdate }
    case SET_USERREPORT_DATA:
      return { ...state, UserReport: action.payload, IsUserReportUpdate: !state.IsUserReportUpdate, UserReportLoading: false }
    case SET_USERREPORT_FILTER_DATA:
      return { ...state, UserReportFilterData: action.payload, IsUserReportUpdate: !state.IsUserReportUpdate }

    case SET_COURIERREPORT_UPDATE:
      return { ...state, IsCourierReportUpdate: !state.IsCourierReportUpdate }
    case SET_COURIERREPORT_LOADING:
      return { ...state, CourierReportLoading: action.payload, IsCourierReportUpdate: !state.IsCourierReportUpdate }
    case SET_COURIERREPORT_DATA:
      return { ...state, CourierReport: action.payload, IsCourierReportUpdate: !state.IsCourierReportUpdate, CourierReportLoading: false }
    case SET_COURIERREPORT_FILTER_DATA:
      return { ...state, CourierReportFilterData: action.payload, IsCourierReportUpdate: !state.IsCourierReportUpdate }

    case SET_MOUNTLYPERFORMANCE_UPDATE:
      return { ...state, IsReportMountlyPerformanceUpdate: !state.IsReportMountlyPerformanceUpdate }
    case SET_MOUNTLYPERFORMANCE_LOADING:
      return { ...state, MoutlyPerformanceLoading: action.payload, IsReportMountlyPerformanceUpdate: !state.IsReportMountlyPerformanceUpdate }
    case SET_MOUNTLYPERFORMANCE_DATA:
      return { ...state, MountlyPerformanceReport: action.payload, IsReportMountlyPerformanceUpdate: !state.IsReportMountlyPerformanceUpdate, MoutlyPerformanceLoading: false }
    case SET_MOUNTLYPERFORMANCE_FILTER_DATA:
      return { ...state, MoutlyPerformanceFilterData: action.payload, IsReportMountlyPerformanceUpdate: !state.IsReportMountlyPerformanceUpdate }

    case SET_HOURPERFORMANCE_UPDATE:
      return { ...state, IsReportHourPerformanceUpdate: !state.IsReportHourPerformanceUpdate }
    case SET_HOURPERFORMANCE_LOADING:
      return { ...state, HourPerformanceLoading: action.payload, IsReportHourPerformanceUpdate: !state.IsReportHourPerformanceUpdate }
    case SET_HOURPERFORMANCE_DATA:
      return { ...state, HourPerformanceReport: action.payload, IsReportHourPerformanceUpdate: !state.IsReportHourPerformanceUpdate, HourPerformanceLoading: false }
    case SET_HOURPERFORMANCE_FILTER_DATA:
      return { ...state, HourPerformanceFilterData: action.payload, IsReportHourPerformanceUpdate: !state.IsReportHourPerformanceUpdate }

    case SET_ENDOFDAY_UPDATE:
      return { ...state, IsReportEndOfDayUpdate: !state.IsReportEndOfDayUpdate }
    case SET_ENDOFDAY_LOADING:
      return { ...state, EndOfDayLoading: action.payload, IsReportEndOfDayUpdate: !state.IsReportEndOfDayUpdate }
    case SET_ENDOFDAY_DATA:
      return { ...state, EndOfDayReport: action.payload, IsReportEndOfDayUpdate: !state.IsReportEndOfDayUpdate, EndOfDayLoading: false }
    case SET_ENDOFDAY_FILTER_DATA:
      return { ...state, EndOfDayFilterData: action.payload, IsReportEndOfDayUpdate: !state.IsReportEndOfDayUpdate }
    case SET_REPORTPAGE_NAME:
      return {
        ...state, ReportName: action.payload, IsReportEndOfDayUpdate: !state.IsReportEndOfDayUpdate, EndOfDayLoading: false,
        IsReportHourPerformanceUpdate: !state.IsReportHourPerformanceUpdate, HourPerformanceLoading: false,
        IsReportMountlyPerformanceUpdate: !state.IsReportMountlyPerformanceUpdate, MountlyPerformanceLoading: false,
        IsReportPageUpdate: !state.IsReportPageUpdate,
        IsCourierReportUpdate: !state.IsCourierReportUpdate, CourierReportLoading: false,
        IsUserReportUpdate: !state.IsUserReportUpdate, UserReportLoading: false,
        IsPaketYoldaReportUpdate: !state.IsPaketYoldaReportUpdate, PaketYoldaReportLoading: false,
        IsAdditionReportUpdate: !state.IsAdditionReportUpdate, AdditionReportLoading: false,
        IsCurrentAccountReportUpdate: !state.IsCurrentAccountReportUpdate, CurrentAccountReportLoading: false,
        IsProductSalesReportUpdate: !state.IsProductSalesReportUpdate, ProductSalesReportLoading: false,
        IsProductMovementReportUpdate: !state.IsProductMovementReportUpdate, ProductMovementReportLoading: false,
        IsProductCostReportUpdate: !state.IsProductCostReportUpdate, ProductCostReportLoading: false,
        IsPaketYoldaBrandReportUpdate: !state.IsPaketYoldaBrandReportUpdate, PaketYoldaBrandReportLoading: false,
        IsEntegrationReportUpdate: !state.IsEntegrationReportUpdate, EntegrationReportLoading: false,
        IsStaffReportUpdate: !state.IsStaffReportUpdate, StaffReportLoading: false,
        IsKitchenReportUpdate: !state.IsKitchenReportUpdate, IsKitchenReportLoading: false,
        CurrentAccountReportFilter: {
          BrandId: cookie.load("BrandId"),
          page: 1,
          searchText: "",
          CustomerType: (action.payload == "SupplierReport" ? 1 : 0),
          BalanceStatus: 1
        },
        CurrentAccountReport: {
          Results: [],
          PageSize: 0,
          RowCount: 0,
          CurrentPage: 0
        }
      }
    case REPORTPAGE_VISIBLE:
      return { ...state, IsReportPageVisible: action.payload, IsReportPageUpdate: !state.IsReportPageUpdate }
    case REPORT_START:
      return { ...state, loading: true }
    case ADDITIONLIST_UPDATE_REPORT:
      return { ...state, filterAdditionData: action.data, additionListUpdate: !state.additionListUpdate }
    case ADDITIONLIST_LOADING:
      return { ...state, additionListLoading: !state.additionListLoading }
    case STOCKMOVEMENTLIST_UPDATE_REPORT:
      return { ...state, filterStockMovementListData: action.data, stockMovementListUpdate: !state.stockMovementListUpdate }
    case STOCKMOVEMENTLIST_LOADING:
      return { ...state, stockMovementListLoading: !state.stockMovementListLoading }
    case STOCKRECIPELIST_UPDATE_REPORT:
      return { ...state, filterStockRecipeListData: action.data, stockRecipeListUpdate: !state.stockRecipeListUpdate }
    case STOCKRECIPELIST_CLEAR:
      return {
        ...state, filterStockRecipeListData: {
          BrandId: "",
          List: []
        }, stockRecipeListUpdate: !state.stockRecipeListUpdate
      }
    case STOCKRECIPELIST_LOADING:
      return { ...state, stockRecipeListLoading: !state.stockRecipeListLoading }
    case PAKETYOLDA_DATA:
      return { ...state, paketYoldaData: action.data, paketYoldaUpdate: !state.paketYoldaUpdate }
    case PAKETYOLDA_FILTER:
      return { ...state, paketYoldaFilter: action.payload, paketYoldaUpdate: !state.paketYoldaUpdate }
    case CURRENTACCOUNT_UPDATE_REPORT:
      return { ...state, filterCurrentAccount: action.data, currentAccountUpdate: !state.currentAccountUpdate }
    case CURRENTACCOUNT_LOADING:
      return { ...state, currentAccountLoading: !state.currentAccountLoading }
    case PAKETYOLDA_LOADING:
      return { ...state, paketYoldaLoading: !state.paketYoldaLoading }
    case REPORTLOADING_START:
      return { ...state, reportLoading: true }
    case REPORTLOADING_STOP:
      return { ...state, reportLoading: false }
    case REPORT_ERROR:
      return {
        ...state, form_data: {
          ...state.form_data,
          modalOpen: true,
          modalText: action.payload,
          modalTitle: "Uyarı"
        }, IsReportPageUpdate: !state.IsReportPageUpdate, IsAdditionReportUpdate: !state.IsReportPageUpdate,
      }
    case REPORT_STOP:
      return { ...state, loading: false, data: action.payload }
    case REPORT_GLOBAL_FORM_DATA_SET:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          [action.payload.key]: action.payload.val
        }
      }
    case REPORT_MODALCLOSE:
      return {
        ...state, form_data: {
          ...state.form_data,
          modalOpen: false,
          modalText: "",
          modalTitle: "Uyarı"
        }, IsReportPageUpdate: !state.IsReportPageUpdate
      }
    default:
      return state;
  }
}

export default reportReducer;
