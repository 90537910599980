import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Decoration from '../../Templates/Decoration';
import styles from './appStyles-jss';
import AppBar from '@material-ui/core/AppBar';
import { NavLink } from 'react-router-dom';
import logo from 'dan-images/logo.png';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { getLicenceModulePackage } from '../../../function/GeneralFunction';

class ReportMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  handleCloseApp = () => {
    cookie.remove('UserName', { path: '/' });
    cookie.remove('RestaurantName', { path: '/' });
    cookie.save('AdminLogin', "false", { path: '/' });
    cookie.remove('UserId', { path: '/' });
    cookie.remove('BrandId', { path: '/' });
    cookie.remove('TenantId', { path: '/' });
    cookie.remove('CenterBrandId', { path: '/' });
    cookie.remove('UserType', { path: '/' });
    cookie.remove('Settings', { path: '/' });
    cookie.remove('LicenceNumber', { path: '/' });
    cookie.remove('NameSurname', { path: '/' });
    cookie.remove('Authorization', { path: '/' });
    reactLocalStorage.remove('l_string');
    window.location.href = '/';
  };

  render() {
    const {
      classes,
      children,
      pageLoaded,
      mode,
      gradient,
      data,
      bgPosition,
      handleClose,
      handleClickReportData
    } = this.props;
    let name = (data != null ? (data.length != 0 ? data.Name : "") : "");
    const { fixed } = true;
    return (
      <Fragment>
        <AppBar
          className={
            classNames(
              classes.appBar,
              classes.attachedbar,
              fixed ? classes.fixed : ''
            )
          }
        >
          <div className={classes.appMenu}>
            <div className={classes.brand}>
              <img src={logo} />
              {"JaviPos Gelişmiş Raporlama Sistemi"}
            </div>
            <Toolbar>
              <Tooltip title="Geri" placement="bottom">
                <Button style={{ display: (cookie.load('UserType') == "Reporting" ? 'none' : 'inline-flex') }} color="inherit" onClick={handleClose}>
                  {"Geri"}
                </Button>
              </Tooltip>
              <Tooltip title="Çıkış" placement="bottom">
                <Button style={{ display: (cookie.load('UserType') == "Reporting" ? 'inline-flex' : 'none'), backgroundColor: '#F44336' }} variant="contained" color="inherit" onClick={this.handleCloseApp}>
                  {"Çıkış"}
                </Button>
              </Tooltip>
            </Toolbar>
          </div>
        </AppBar>
        <Fragment>
          <nav style={{ zIndex: 99, position: (fixed ? 'fixed' : 'relative'), width: (fixed ? '100%' : 'auto'), top: (fixed ? '0' : 'auto'), backgroundColor: '#292929', padding: (fixed ? 15 : 10), display: 'flex', justifyContent: 'center', marginLeft: -8, marginRight: -8 }}>
            <div>
              <Button onClick={() => handleClickReportData("Gün Sonu")} variant={"outlined"} style={{ marginLeft: 10, backgroundColor: (name != "" && name == "Gün Sonu" ? '#bdc3c7' : 'transparent') }}>
                <Icon>{"equalizer"}</Icon>
                <Typography style={{ fontSize: 15, marginLeft: 10, marginRight: 10, textTransform: 'capitalize' }} className={classes.buttonMenuTitle} variant="subtitle1">{"Genel"}</Typography>
              </Button>
              <Button onClick={() => handleClickReportData("Kurye Performans")} variant={"outlined"} style={{ marginLeft: 10, backgroundColor: (name != "" && name == "Kurye Performans" ? '#bdc3c7' : 'transparent'), display:(getLicenceModulePackage() ? '' : 'none') }}>
                <Icon>{"sports_motorsports"}</Icon>
                <Typography style={{ fontSize: 15, marginLeft: 10, marginRight: 10, textTransform: 'capitalize' }} className={classes.buttonMenuTitle} variant="subtitle1">{"Kurye"}</Typography>
              </Button>
              <Button onClick={() => handleClickReportData("Kullanıcı Performans")} variant={"outlined"} style={{ marginLeft: 10, backgroundColor: (name != "" && name == "Kullanıcı Performans" ? '#bdc3c7' : 'transparent') }}>
                <Icon>{"supervised_user_circle"}</Icon>
                <Typography style={{ fontSize: 15, marginLeft: 10, marginRight: 10, textTransform: 'capitalize' }} className={classes.buttonMenuTitle} variant="subtitle1">{"Kullanıcı"}</Typography>
              </Button>
              <Button onClick={() => handleClickReportData("Ürün Satış")} variant={"outlined"} style={{ marginLeft: 10, backgroundColor: (name != "" && name == "Ürün Satış" ? '#bdc3c7' : 'transparent') }}>
                <Icon>{"restaurant_menu"}</Icon>
                <Typography style={{ fontSize: 15, marginLeft: 10, marginRight: 10, textTransform: 'capitalize' }} className={classes.buttonMenuTitle} variant="subtitle1">{"Ürün"}</Typography>
              </Button>
              <Button onClick={() => handleClickReportData("Adisyon Raporu")} variant={"outlined"} style={{ marginLeft: 10, backgroundColor: (name != "" && name == "Adisyon Raporu" ? '#bdc3c7' : 'transparent') }}>
                <Icon>{"timeline"}</Icon>
                <Typography style={{ fontSize: 15, marginLeft: 10, marginRight: 10, textTransform: 'capitalize' }} className={classes.buttonMenuTitle} variant="subtitle1">{"Adisyon"}</Typography>
              </Button>
              <Button onClick={() => handleClickReportData("Açık Hesap")} variant={"outlined"} style={{ marginLeft: 10, backgroundColor: (name != "" && name == "Açık Hesap" ? '#bdc3c7' : 'transparent') }}>
                <Icon>{"card_travel"}</Icon>
                <Typography style={{ fontSize: 15, marginLeft: 10, marginRight: 10, textTransform: 'capitalize' }} className={classes.buttonMenuTitle} variant="subtitle1">{"Açık Hesap"}</Typography>
              </Button>
              <Button onClick={() => handleClickReportData("Harita")} variant={"outlined"} style={{ marginLeft: 10, backgroundColor: (name != "" && name == "Harita" ? '#bdc3c7' : 'transparent') }}>
                <Icon>{"pin_drop"}</Icon>
                <Typography style={{ fontSize: 15, marginLeft: 10, marginRight: 10, textTransform: 'capitalize' }} className={classes.buttonMenuTitle} variant="subtitle1">{"Müşteri Takip"}</Typography>
              </Button>
            </div>
          </nav>
        </Fragment>
        <main
          className={
            classNames(
              classes.content,
              classes.highMargin
            )
          }
          style={{ paddingTop: 0, zIndex: 1 }}
          id="mainContent"
        >
          <Decoration
            mode={'dark'}
            gradient={true}
            decoration={true}
            bgPosition={'half'}
            horizontalMenu
          />
          <section className={classNames(classes.mainWrap, classes.topbarLayout)}>
            {children}
          </section>
        </main>
      </Fragment>
    );
  }
}

ReportMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  gradient: PropTypes.bool.isRequired,
};

export default (withStyles(styles)(ReportMenu));
