import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../paketyolda-jss';
import {
    setMileageChargeVisible, setMileageCharge, setMileageChargeData
} from '../../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import { CapitalizeText, ComplateLowerText } from '../../../../function/GeneralFunction';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RegionMap from './RegionMap';
import cookie from 'react-cookies';
import NumberFormat from 'react-number-format';
import Divider from '@material-ui/core/Divider';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            prefix="₺"
        />
    );
}

class MileageCharge extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.mileageChargeUpdate == this.props.mileageChargeUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
    }

    handleChangeMileage = (Id) => event => {
        const { mileageChargeData } = this.props;
        mileageChargeData.find(x => x.Id == Id).Price = event.target.value;
        this.props.setMileageChargeData(mileageChargeData).then(() => { });
    };

    render() {
        const { mileageChargeType, classes, mileageChargeVisible, mileageChargeData, mileageChargeLoading } = this.props;

        if (mileageChargeData.length == 0) {
            return null;
        }
        return (
            <Dialog
                open={mileageChargeVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {(mileageChargeType == 0 ? "Kurye" : "İşletme")}{" Kilometre Ücretlendirmesi"}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto', width: '100%' }}>
                    <div style={{ display: 'block', width: '100%', height: '100%' }}>
                        <Grid container spacing={1}>
                            <Grid item md={12} style={{ textAlign:'center', backgroundColor:'#424242', marginBottom:10, borderRadius:10 }}>{"Başlangıç Ücretleri"}</Grid>
                            <Grid item md={6}>{"1. Paket"}</Grid>
                            <Grid item md={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    className={classes.formControl}
                                    value={mileageChargeData.find(x => x.Order == 0).Price}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangeMileage(mileageChargeData.find(x => x.Order == 0).Id)}
                                    id="formatted-numberformat-input"
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </Grid>
                            <Grid style={{ display:(mileageChargeType == 0 ? "" : "none") }} item md={6}>{"2. Paket"}</Grid>
                            <Grid item md={6} style={{ display:(mileageChargeType == 0 ? "flex" : "none"), alignItems: 'center' }}>
                                <TextField
                                    className={classes.formControl}
                                    value={mileageChargeData.find(x => x.Order == 101).Price}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangeMileage(mileageChargeData.find(x => x.Order == 101).Id)}
                                    id="formatted-numberformat-input"
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} style={{ display:(mileageChargeType == 0 ? "" : "none") }}>{"3. Paket"}</Grid>
                            <Grid item md={6} style={{ display: (mileageChargeType == 0 ? "flex" : "none"), alignItems: 'center' }}>
                                <TextField
                                    className={classes.formControl}
                                    value={mileageChargeData.find(x => x.Order == 102).Price}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangeMileage(mileageChargeData.find(x => x.Order == 102).Id)}
                                    id="formatted-numberformat-input"
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} style={{ display:(mileageChargeType == 0 ? "" : "none") }}>{"4. Paket"}</Grid>
                            <Grid item md={6} style={{ display:(mileageChargeType == 0 ? "flex" : "none"), alignItems: 'center' }}>
                                <TextField
                                    className={classes.formControl}
                                    value={mileageChargeData.find(x => x.Order == 103).Price}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangeMileage(mileageChargeData.find(x => x.Order == 103).Id)}
                                    id="formatted-numberformat-input"
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} style={{ display:(mileageChargeType == 0 ? "" : "none") }}>{"5. Paket"}</Grid>
                            <Grid item md={6} style={{ display:(mileageChargeType == 0 ? "flex" : "none"), alignItems: 'center' }}>
                                <TextField
                                    className={classes.formControl}
                                    value={mileageChargeData.find(x => x.Order == 104).Price}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangeMileage(mileageChargeData.find(x => x.Order == 104).Id)}
                                    id="formatted-numberformat-input"
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                        <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 0 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '50%' }}>Km Farkı</TableCell>
                                    <TableCell style={{ width: '50%' }}>Fark Ücreti</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    mileageChargeData.filter(x => x.Order != 0 && x.Order != 101 && x.Order != 102 && x.Order != 103 && x.Order != 104).sort(function (a, b) { return a.Order - b.Order }).map((n, i) => {
                                        return <TableRow key={i}>
                                            <TableCell align="left">
                                                {(n.Order == 10 ? n.Order - 1 + " Km+" : n.Order - 1 + "-" + n.Order + " Km")}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    className={classes.formControl}
                                                    value={n.Price}
                                                    onFocus={event => { event.target.select() }}
                                                    onChange={this.handleChangeMileage(n.Id)}
                                                    id="formatted-numberformat-input"
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ backgroundColor: '#27ae60', color: 'white' }}
                        onClick={() => this.props.setMileageCharge().then(() => { })}
                        color="primary">
                        {'Kaydet'}
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setMileageChargeVisible(false, "", 0).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

MileageCharge.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').mileageChargeUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    mileageChargeUpdate: value(state, props),
    mileageChargeVisible: state.get('paketYoldaReducer').mileageChargeVisible,
    mileageChargeLoading: state.get('paketYoldaReducer').mileageChargeLoading,
    mileageChargeData: state.get('paketYoldaReducer').mileageChargeData,
    mileageChargeType: state.get('paketYoldaReducer').mileageChargeType,
});

const mapDispatchToProps = {
    setMileageChargeVisible,
    setMileageCharge,
    setMileageChargeData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(MileageCharge)));
