import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class CourierDialog extends React.Component {
  constructor(){
    super();
  }

  handleClose = () => {
    this.props.eventClose();
  };

  handleConfirmation = () => {
    this.props.eventConfirmation();
  };

render() {
  const { classes, title, text, open, contentStyle, contentTextStyle } = this.props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{padding: '24px 24px 5px'}}>
          {title}
        </DialogTitle>
        <DialogContent style={contentStyle}>
          <DialogContentText style={contentTextStyle} id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" style={{ backgroundColor:'rgba(192,57,43 ,1)' }} onClick={this.handleClose} color="secondary">
            Giriş Yap Çık
          </Button>
          <Button variant="contained" style={{ backgroundColor:'rgba(39,174,96 ,1)' }} onClick={this.handleConfirmation} color="primary" autoFocus>
            Giriş Yap Devam Et
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
}

export default CourierDialog;
