import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
});

class ModalInformation extends React.Component {
  constructor(){
    super();
  }

  handleClose = () => {
    this.props.event();
  };

  render() {
    const { classes, title, text, open } = this.props;
    return (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography style={{ color:'white' }} variant="h6" id="modal-title">
              {title}
            </Typography>
            <Typography style={{ color:'white' }} variant="subtitle1" id="simple-modal-description">
              {text}
            </Typography>
          </div>
        </Modal>
    );
  }
}

ModalInformation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ModalInformation);
