import React from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { PapperBlock } from 'dan-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import jarvis from 'dan-images/jarvis.png';
import moment from 'moment';
import ScrollContainer from 'react-indiana-drag-scroll';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import Ionicon from 'react-ionicons';
import {
    getPackageRackList, setPackageRackHubConnection, setPackageRackUpdate, setRackClear,
    formDataSet, getPackageRackOrderControl
} from '../../../redux/modules/addition';
import styles from './rackpage-jss';
import Decoration from '../../Templates/Decoration';
import Outer from '../../Templates/Outer';
import ModalInformation from '../Ortak/ModalInformation';
import {
    getParameter
} from '../../../function/GeneralFunction';
import IconButton from '@material-ui/core/IconButton';

class PackageRackPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { counterOrder: 30, fullScreen: false };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.packageRackUpdate == this.props.packageRackUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        if (this.props.packageRackHubConnection == null) {
            await this.props.setPackageRackHubConnection().then(() => { });
        }
        await this.props.getPackageRackList().then(() => { });
        this.timerOrder = setInterval(() => this.tickOrder(), 1000);
    }

    tickOrder() {
        this.setState({
            counterOrder: this.state.counterOrder - 1
        });
        if (this.state.counterOrder < 0) {
            this.clearOrder();
            this.props.getPackageRackOrderControl().then(() => { });
        }
        this.props.setPackageRackUpdate().then(() => { });
    }

    clearOrder() {
        this.setState({
            counterOrder: 30
        });
        this.props.setPackageRackUpdate().then(() => { });
    }

    componentWillUnmount() {
        clearInterval(this.timerOrder);
    }

    modalClose = () => {
        this.props.formDataSet('modalOpen', false).then(() => { });
    };

    render() {
        const { classes, packageRackData, packageRackDataCount, modalOpen, modalTitle, modalText } = this.props;
        const { counterOrder } = this.state;
        return (
            <Outer>
                <ModalInformation event={this.modalClose} open={modalOpen} title={modalTitle} text={modalText} />
                <div style={{
                    position: 'fixed', top: 2, bottom: 2, left: 2, right: 2, zIndex: 10000
                }}
                >
                    <div style={{ columnCount: 4, display: 'flex', flexWrap: 'wrap', height: 'calc(100vh - 34px)', boxSizing: 'border-box' }}>
                        {
                            packageRackData.sort((a, b) => a.RackOrder - b.RackOrder).map((n, i) => {
                                return (
                                    <div style={{ backgroundColor: (n.IsEmpty == true ? '#424242' : (n.EntegrationName == 'YemekSepetiGlobal' ? '#ea004b' : (n.EntegrationName == 'GetirYemek' ? '#5d3ebc' : (n.EntegrationName == 'TrendyolYemek' ? '#f27a1a' : (n.EntegrationName == 'MigrosYemek' ? 'rgba(39, 174, 96,1.0)' : (n.EntegrationName == 'Paket' ? 'rgba(41, 128, 185,1.0)' : 'rgba(41, 128, 185,1.0)')))))), fontSize: (n.IsEmpty == true ? 50 : 15) }} className={classNames(classes.box)}>
                                        {n.IsEmpty ? <span>{n.RackOrder}</span> :
                                            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                                <span style={{ color: 'white', position: 'absolute', right: 0, top: 0, display: 'flex', alignItems: 'center' }}>
                                                    <Icon style={{ fontSize: 20 }}>{"sports_motorsports"}</Icon>
                                                    <span style={{ fontSize: 13, marginLeft: 5, fontWeight: 600 }}>{(n.CourierName.length == 0 ? "Atama Bekliyor" : "")}</span>
                                                </span>
                                                <div style={{ width: '100%', height: '100%', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div style={{ display: 'grid', textAlign: 'center', lineHeight: 1.5 }}>
                                                        <span style={{ fontSize: 25, marginLeft: 5, fontWeight: 600 }}>{n.CustomerName}</span>
                                                        <span style={{ fontSize: 25, marginLeft: 5, fontWeight: 600 }}>{n.IsDeliveryType ? n.orderNo : n.AdditionId + " - " + n.AdditionOrder}</span>
                                                    </div>
                                                </div>
                                                <span style={{ color: 'white', position: 'absolute', left: 0, bottom: 20, display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ fontSize: 13, marginLeft: 5, fontWeight: 600 }}>{moment(n.CreationTime).format('DD.MM.YYYY HH:mm')}</span>
                                                </span>
                                                <span style={{ color: 'white', position: 'absolute', left: 0, bottom: 0, display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ fontSize: 13, marginLeft: 5, fontWeight: 600 }}>{n.EntegrationName}{(n.IsDeliveryType ? " Kendi" : "")}</span>
                                                </span>
                                                <span style={{ color: 'white', position: 'absolute', left: 0, top: 0 }}>
                                                    <span style={{ lineHeight: 1, fontSize: 50, marginLeft: 5, fontWeight: 600 }}>{n.RackOrder}</span>
                                                </span>
                                                <IconButton style={{ width: 35, height: 35, position: 'absolute', right: 0, bottom: 0, borderRadius: 5, backgroundColor: '#c0392b' }} className={classes.button} onClick={() => this.props.setRackClear(n.Id).then(() => { })}>
                                                    <Icon style={{ color: 'white', width: 30 }}>{"delete"}</Icon>
                                                </IconButton>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div style={{ width: '100%', height: 30, backgroundColor: '#424242', display: 'flex', alignItems: 'center' }}>
                        <IconButton style={{ borderRadius: 0, backgroundColor: '#c0392b', height: 30 }} className={classes.button} onClick={() => this.props.history.push('/')}>
                            <Icon style={{ color: 'white', width: 50 }}>{"logout"}</Icon>
                        </IconButton>
                        <IconButton style={{ borderRadius: 0, backgroundColor: '#2980b9', height: 30 }} className={classes.button} onClick={() => this.props.getPackageRackList().then(() => { })}>
                            <Icon style={{ color: 'white', width: 50 }}>{"refresh"}</Icon>
                        </IconButton>
                        <span style={{ marginRight: 15, textAlign: 'right', width: '80%', color: 'white', fontSize: 14 }}>{"Bekleyen Sipariş Sayısı : "}{packageRackDataCount}{" adet"}</span>
                        <span style={{ marginRight: 15, textAlign: 'right', width: '20%', color: 'white', fontSize: 14 }}>{"Sipariş Durum Kontrol Sayacı : "}{counterOrder}{" sn"}</span>
                    </div>
                </div>
            </Outer>
        );
    }
}


const valueSelector = (state, props) => state.get('additionReducer').packageRackUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    packageRackUpdate: value(state, props),
    packageRackData: state.get('additionReducer').packageRackData,
    packageRackDataCount: state.get('additionReducer').packageRackDataCount,
    packageRackHubConnection: state.get('additionReducer').packageRackHubConnection,

    modalOpen: state.get('additionReducer').form_data.modalOpen,
    modalText: state.get('additionReducer').form_data.modalText,
    modalTitle: state.get('additionReducer').form_data.modalTitle,
});

const mapDispatchToProps = {
    getPackageRackList,
    setPackageRackHubConnection,
    setPackageRackUpdate,
    setRackClear,
    formDataSet,
    getPackageRackOrderControl
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(PackageRackPage)));
