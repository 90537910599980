import React from 'react';
import { connect } from 'react-redux';
import {
    withStyles
} from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    createSelector
} from 'reselect';
import Button from '@material-ui/core/Button';
import {
    setPrinterGroupListVisible, formDataSetStock, setPrinterGroup, setCategoryNew, setPrinterGroupUpdate,
    setModalStock, setPrinterGroupDelete
} from '../../../../redux/modules/stock';
import Typography from '@material-ui/core/Typography';
import { CapitalizeText } from '../../../../function/GeneralFunction';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { CirclePicker } from 'react-color';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
const uuidv4 = require('uuid/v4');
import AlertDialog from '../../Ortak/AlertDialog';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import classNames from 'classnames';

const styles = theme => ({
    dropdownStyle: {
        border: "1px solid #ecf0f1",
        borderRadius: "5%",
    },
    dialogPaperPrinter: {
        margin: 10,
        maxHeight: 'calc(100% - 30px)',
        maxWidth: '70%',
    },
});

class PrinterGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = { printerGroupId: null }
    }

    shouldComponentUpdate(nextProps) {
        return true;
    }

    handleChangePrinterGroup = (name, type) => event => {
        this.props.printergroupadd[name] = (name == "GroupName" ? CapitalizeText(event.target.value) : event.target.value);
        this.props.formDataSetStock("printergroupadd", this.props.printergroupadd).then(() => { })
        this.props.setPrinterGroupUpdate().then(() => { })
    };

    async printerGroupUpdate(Id) {
        let group = this.props.printerGroupList.find(x => x.Id == Id);
        let printergroupadd = {
            Id: group.Id,
            GroupName: group.GroupName,
            PrinterName: group.PrinterName,
            KitPrinterName: group.KitPrinterName,
            KitchenPrinterName: group.KitchenPrinterName,
            PackagePrinterName: group.PackagePrinterName,
            TenantId: group.TenantId,
            BrandId: group.BrandId,
            IsUpdate: true
        };
        this.props.formDataSetStock("printergroupadd", printergroupadd).then(() => { })
    }

    async PrinterGroupAdd() {
        if (this.props.printergroupadd.GroupName.length == 0) {
            this.props.setModalStock("Grup adı boş geçilemez!").then(() => { })
            return;
        }
        {/*if (this.props.printergroupadd.PrinterName.length == 0) {
            this.props.setModalStock("Yazıcı adı boş geçilemez!").then(() => { })
            return;
        }*/}
        await this.props.setPrinterGroup().then(() => { });
        this.props.setPrinterGroupUpdate().then(() => { })
    }

    renderKitchenPrinterName(Id) {
        const {
            userList
        } = this.props;
        var name = "Gönderme";
        if (userList.filter(x => x.UserType == 2 && x.Id == Id).length > 0) {
            name = userList.find(x => x.Id == Id).Name;
        }
        return name;
    }

    render() {
        const {
            classes,
            printergroupadd,
            printerGroupAddButtonVisible,
            printerGroupList,
            printerList,
            userList
        } = this.props;

        return (
            <Dialog
                open={this.props.printerGroupListVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'md'}
                PaperProps={{ classes: { root: classes.dialogPaperPrinter } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Yazdırma Grubu İşlemleri"} {(printergroupadd.IsUpdate ? "(Düzenleme)" : "(Yeni Yazdırma Grubu Ekleme)")}
                    <Typography className={classes.instructions} style={{ fontSize: 13, fontWeight: 500, color: 'white' }}>
                        {"Yazdırma Grubu Ekleme ve Düzenleme İşlemleri"}
                    </Typography>
                </DialogTitle>
                <Grid container spacing={1} style={{ padding: '0 24px 24px', width: '100%' }}>
                    <Grid item md={4}>
                        <TextField
                            autoFocus
                            margin="none"
                            value={printergroupadd.GroupName}
                            id="name"
                            label="Grup Adı"
                            onChange={this.handleChangePrinterGroup("GroupName")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2}>
                        <FormControl className={classes.formControl} style={{ marginTop: 0 }}>
                            <InputLabel htmlFor="age-helper">{"Mutfak Yazıcı Seçimi"}</InputLabel>
                            <Select
                                value={printergroupadd.PrinterName}
                                onChange={this.handleChangePrinterGroup("PrinterName")}
                                input={<Input name="PrinterName" id="age-helper" />}
                                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            >
                                <MenuItem value={" "}><em>{"Gönderme"}</em></MenuItem>
                                <Divider />
                                <ListSubheader style={{ color: '#f1c40f' }}>{"Yazıcı Listesi"}</ListSubheader>
                                <Divider />
                                {
                                    printerList.map((n, i) => {
                                        return <MenuItem key={i} value={n}><em>{n}</em></MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2}>
                        <FormControl className={classes.formControl} style={{ marginTop: 0 }}>
                            <InputLabel htmlFor="age-helper">{"Paket Yazıcı Seçimi"}</InputLabel>
                            <Select
                                value={printergroupadd.KitPrinterName}
                                onChange={this.handleChangePrinterGroup("KitPrinterName")}
                                input={<Input name="PrinterName" id="age-helper" />}
                                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            >
                                <MenuItem value={" "}><em>{"Gönderme"}</em></MenuItem>
                                <Divider />
                                <ListSubheader style={{ color: '#f1c40f' }}>{"Yazıcı Listesi"}</ListSubheader>
                                <Divider />
                                {
                                    printerList.map((n, i) => {
                                        return <MenuItem key={i} value={n}><em>{n}</em></MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2}>
                        <FormControl className={classes.formControl} style={{ marginTop: 0 }}>
                            <InputLabel htmlFor="age-helper">{"İç Satış Mutfak Ekranı Seçimi"}</InputLabel>
                            <Select
                                value={printergroupadd.KitchenPrinterName}
                                onChange={this.handleChangePrinterGroup("KitchenPrinterName")}
                                input={<Input name="PrinterName" id="age-helper" />}
                                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            >
                                <MenuItem value={" "}><em>{"Boş"}</em></MenuItem>
                                <Divider />
                                <ListSubheader style={{ color: '#f1c40f' }}>{"Mutfak Ekranı Kullanıcı Listesi"}</ListSubheader>
                                <Divider />
                                {
                                    userList.filter(x => x.UserType == 2).map((n, i) => {
                                        return <MenuItem key={i} value={n.Id}><em>{n.Name}</em></MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2}>
                        <FormControl className={classes.formControl} style={{ marginTop: 0 }}>
                            <InputLabel htmlFor="age-helper">{"Paket Satış Mutfak Ekranı Seçimi"}</InputLabel>
                            <Select
                                value={printergroupadd.PackagePrinterName}
                                onChange={this.handleChangePrinterGroup("PackagePrinterName")}
                                input={<Input name="PrinterName" id="age-helper" />}
                                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            >
                                <MenuItem value={" "}><em>{"Boş"}</em></MenuItem>
                                <Divider />
                                <ListSubheader style={{ color: '#f1c40f' }}>{"Mutfak Ekranı Kullanıcı Listesi"}</ListSubheader>
                                <Divider />
                                {
                                    userList.filter(x => x.UserType == 2).map((n, i) => {
                                        return <MenuItem key={i} value={n.Id}><em>{n.Name}</em></MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={1}>
                            <Table style={{ marginTop: 5 }}>
                                <TableHead style={{ backgroundColor: '#212121' }}>
                                    <TableRow>
                                        <TableCell align="left">{"Adı"}</TableCell>
                                        <TableCell align="left">{"İç Satış Mutfak Yazıcısı"}</TableCell>
                                        <TableCell align="left">{"Paket Satış Mutfak Yazıcısı"}</TableCell>
                                        <TableCell align="left">{"İç Satış Mutfak Ekranı"}</TableCell>
                                        <TableCell align="left">{"Paket Satış Mutfak Ekranı"}</TableCell>
                                        <TableCell align="right">{"İşlem"}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        printerGroupList.map((n, i) => {
                                            return (
                                                <TableRow hover key={n.Id}>
                                                    <TableCell style={{ borderLeft: '1px solid #636363', borderRight: '1px solid #636363' }} align="left">{n.GroupName}</TableCell>
                                                    <TableCell style={{ borderRight: '1px solid #636363' }} align="left">{(n.PrinterName.length == 0 ? "Gönderme" : n.PrinterName)}</TableCell>
                                                    <TableCell style={{ borderRight: '1px solid #636363' }} align="left">{(n.KitPrinterName.length == 0 ? "Gönderme" : n.KitPrinterName)}</TableCell>
                                                    <TableCell style={{ borderRight: '1px solid #636363' }} align="left">{this.renderKitchenPrinterName(n.KitchenPrinterName)}</TableCell>
                                                    <TableCell style={{ borderRight: '1px solid #636363' }} align="left">{this.renderKitchenPrinterName(n.PackagePrinterName)}</TableCell>
                                                    <TableCell style={{ borderRight: '1px solid #636363' }} align="right">
                                                        <Tooltip title="Yazdırma Grubu Düzenle">
                                                            <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5, padding: 10 }} onClick={() => this.printerGroupUpdate(n.Id)}>
                                                                <Icon>{"edit"}</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Yazdırma Grubu Sil">
                                                            <IconButton style={{ backgroundColor: '#3e3e3e', padding: 10 }} onClick={() => (confirm('Ürün bazında yazdırma grubunu silmek istediğinize emin misiniz?') ? this.props.setPrinterGroupDelete(n.Id).then(() => { }) : null)}>
                                                                <Icon>{"delete_outline"}</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={printerGroupAddButtonVisible ? true : false} variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPrinterGroupListVisible(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                    <Button style={{ backgroundColor: '#2196F3' }} variant="contained" onClick={() => this.props.formDataSetStock("printergroupadd", { Id: "", GroupName: "", PrinterName: "", KitPrinterName: "", KitchenPrinterName: "", TenantId: "", BrandId: "" }).then(() => {
                        this.props.setPrinterGroupUpdate().then(() => { })
                    })} color="secondary">
                        {"Yeni Yazdırma Grubu"}
                    </Button>
                    <Button disabled={printerGroupAddButtonVisible ? true : false} variant="contained" onClick={() => this.PrinterGroupAdd()} color="secondary">
                        {"Kaydet"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('stockReducer').printerGroupListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    printerGroupListUpdate: value(state, props),
    printerGroupListVisible: state.get('stockReducer').printerGroupListVisible,
    printergroupadd: state.get('stockReducer').form_data.printergroupadd,
    printerGroupAddButtonVisible: state.get('stockReducer').form_data.printerGroupAddButtonVisible,
    printerGroupList: state.get('stockReducer').form_data.printerGroupList,
    printerList: state.get('stockReducer').form_data.printerList,
    userList: state.get('userReducer').form_data.userList,
})

const mapDispatchToProps = {
    setPrinterGroupListVisible,
    formDataSetStock,
    setPrinterGroup,
    setCategoryNew,
    setPrinterGroupUpdate,
    setModalStock,
    setPrinterGroupDelete
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withStyles(styles)(injectIntl(PrinterGroup)));
