import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import {
    setCenterProductListVisible
} from '../../../redux/modules/centerbrand';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            prefix="₺"
        />
    );
}

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class CenterProductList extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.centerProductListUpdate == this.props.centerProductListUpdate) {
            return false;
        }
        return true;
    }
    async componentDidMount() { }

    render() {
        const {
            classes,
            centerProductListVisible,
            centerProductListData
        } = this.props;

        return (
            <Dialog
                open={centerProductListVisible}
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%', backgroundImage: 'linear-gradient(-45deg, rgb(96, 125, 139) 0%, rgb(96, 125, 139) 33%, rgb(117, 117, 117) 100%)' }}>
                    <Toolbar>
                        <Button variant="contained" onClick={() => this.props.setCenterProductListVisible(false).then(() => { })}>
                            <Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon>
                        </Button>
                        <Typography style={{ textAlign: 'center', width: '100%', fontWeight: 600 }} color="inherit">{"Franchise Ürün Listesi"}</Typography>
                        <Button style={{ textTransform:'capitalize' }} color="primary" variant="contained" onClick={() => (confirm('Fiyat değişiklikleri tüm şubelere uygulanacaktır! Onaylıyor musunuz?') ? null : null)}>
                            <Icon style={{ fontSize: 25, marginRight: 5 }}>{"update"}</Icon>
                            {"Fiyatları Güncelle"}
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64 }}>
                        <Table className={classNames(classes.table, classes.stripped)}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '30%' }} align="left">{"Ürün Adı"}</TableCell>
                                    <TableCell align="left">{"Kategori"}</TableCell>
                                    <TableCell style={{ width: '15%' }} align="left">{"İç Fiyatı"}</TableCell>
                                    <TableCell style={{ width: '15%' }} align="left">{"Paket Fiyatı"}</TableCell>
                                    <TableCell align="right">{""}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    centerProductListData.map((n, i) => ([
                                        <TableRow hover key={n.Id}>
                                            <TableCell align="left">{n.Name}</TableCell>
                                            <TableCell align="left">{n.Category}</TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    value={n.Price}
                                                    style={{ borderRadius: 8 }}
                                                    onChange={null}
                                                    fullWidth
                                                    onFocus={event => { event.target.select() }}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    value={n.PackagePrice}
                                                    style={{ borderRadius: 8 }}
                                                    onChange={null}
                                                    fullWidth
                                                    onFocus={event => { event.target.select() }}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title={"Sil"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => (confirm('Bölgeyi silmek istediğinize emin misiniz?') ? null : null)}>
                                                        <Icon>{"delete"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ]))
                                }
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').centerProductListUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    centerProductListUpdate: value(state, props),
    centerProductListVisible: state.get('centerBrandReducer').centerProductListVisible,
    centerProductListData: state.get('centerBrandReducer').centerProductListData
});

const mapDispatchToProps = {
    setCenterProductListVisible
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(CenterProductList)));
