import axios from 'axios';
var base64 = require('base-64');
const uuidv4 = require('uuid/v4');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { reactLocalStorage } from 'reactjs-localstorage';
import { HubConnectionBuilder, LogLevel, HttpTransportType, HubConnectionState } from "@microsoft/signalr";

export const KITCHEN_ERROR = 'kitchen_error';
export const KITCHEN_LOADING = 'kitchen_loading';
export const KITCHEN_GLOBAL_FORM_DATA_SET = 'kitchen_global_form_data_set';
export const KITCHEN_UPDATE = 'kitchen_update';
export const KITCHEN_HUB_STOP = 'kitchen_hub_stop';
export const KITCHEN_ORDER_DATA = 'kitchen_order_data';
export const KITCHEN_ORDER_DATA_UPDATE = 'kitchen_order_data_update';
export const ALL_KITCHEN_ORDER_DATA = 'all_kitchen_order_data';
export const SET_KITCHEN_PIN = 'set_kitchen_pin';
export const SET_KITCHEN_STATUS_TYPE = 'set_kitchen_status_type';
export const KITCHEN_COOKING_TIME_DATA = 'kitchen_cooking_time_data';
let initialState = {
    form_data: {
        modalOpen: false,
        modalTitle: "",
        modalText: ""
    },
    kitchenUpdate: false,
    kitchenLoading: false,
    kitchenHubConnection: null,
    kitchenOrderData: [],
    kitchenUserPIN: "",
    kitchenStatusType: 1,
    kitchenCookingTimeData: [],
}

export async function setKitchenHubConnection() {
    return async (dispatch, getState) => {

        var UserId = cookie.load('UserId');

        const kitchenHubConnection = new HubConnectionBuilder()
            .configureLogging(LogLevel.None)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 300000) {
                        // If we've been reconnecting for less than 60 seconds so far,
                        // wait between 0 and 10 seconds before the next reconnect attempt.
                        return Math.random() * 3000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            })
            .withUrl(brand.urlSocket + "kitchen?UserId=" + UserId, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .build();

        connectKitchen(kitchenHubConnection, dispatch);

        dispatch({ type: KITCHEN_HUB_STOP, payload: kitchenHubConnection });
    }
}

async function connectKitchen(conn, dispatch) {
    await conn.start().then(() => {
        conn.on('GetAllMessageKitchen', (message) => {
            dispatch(getAllKitchenTable(1, true));
        });
    }).catch(e => {
        sleepKitchen(3000);
        connectKitchen(conn, dispatch);
    }
    )
}

export async function getCookingTimeList() {
    return async (dispatch, getState) => {
        await dispatch(setKitchenLoading(true));

        var BrandId = cookie.load("BrandId");
        var PrinterUserId = cookie.load("UserId");

        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Kitchen/getCookingTimeList?BrandId=' + BrandId + '&PrinterUserId=' + PrinterUserId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var jsonData = JSON.parse(response.data.responseData);
                    dispatch({
                        type: KITCHEN_COOKING_TIME_DATA,
                        payload: jsonData
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        await dispatch(setKitchenLoading(false));
    }
}


export async function getKitchenStaffControl() {
    return async (dispatch, getState) => {
        const { kitchenUserPIN } = getState().get('kitchenReducer');
        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Kitchen/getKitchenStaffControl?Password=' + kitchenUserPIN + '&BrandId=' + cookie.load("BrandId"),
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var jsonData = JSON.parse(response.data.responseData);
                    cookie.save('KitchenUserId', jsonData.Id, { path: '/' });
                    cookie.save('KitchenUserName', jsonData.Name, { path: '/' });
                    dispatch(setKitchenPin(""));
                } else {
                    dispatch(setKitchenModal(response.data.responseData));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export async function getAllKitchenTable(type, socket = false) {
    return async (dispatch, getState) => {
        await dispatch(setKitchenLoading(true));
        if (!socket) {
            await dispatch({ type: SET_KITCHEN_STATUS_TYPE, payload: type });
        }

        var BrandId = cookie.load("BrandId");
        var PrinterUserId = cookie.load("UserId");

        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Kitchen/getAllKitchenTable?BrandId=' + BrandId + '&type=' + type + '&PrinterUserId=' + PrinterUserId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var jsonData = JSON.parse(response.data.responseData);
                    jsonData.map((n, i) => {
                        n.ProductItems = JSON.parse(n.ProductItems);
                    })
                    if (socket) {
                        dispatch(setKitchenOrderData(jsonData));
                    } else {
                        dispatch(setAllKitchenOrderData(jsonData));
                    }
                    dispatch(getCookingTimeList());
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        await dispatch(setKitchenLoading(false));
    }
}

export async function setStatusKitchenTable(Id, type, itemId = "00000000-0000-0000-0000-000000000000", MenuId, MenuItemId, IsSelected) {
    return async (dispatch, getState) => {
        await dispatch(setKitchenLoading(true));
        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Kitchen/setStatusKitchenTable?Id=' + Id + '&type=' + type + '&KitchenUserId=' + cookie.load('KitchenUserId') + '&ItemId=' + itemId + '&KitchenUserName=' + cookie.load('KitchenUserName') + '&MenuId=' + MenuId + '&MenuItemId=' + MenuItemId + '&IsSelected=' + IsSelected,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getAllKitchenTable(1));
                } else {
                    dispatch(setKitchenModal(response.data.responseData));
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        await dispatch(setKitchenLoading(false));
    }
}

export async function setCookingTime(Id, ItemId, MenuId, MenuItemId, IsSelected) {
    return async (dispatch, getState) => {
        var PrinterUserId = cookie.load("UserId");

        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Kitchen/setCookingTime?PrinterUserId=' + PrinterUserId + '&Id=' + Id + '&ItemId=' + ItemId + '&MenuId=' + MenuId + '&MenuItemId=' + MenuItemId + '&IsSelected=' + IsSelected,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                dispatch(getAllKitchenTable(1));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export async function orderControlTrendyol() {
    return async (dispatch, getState) => {
        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Kitchen/orderControlTrendyol?BrandId=' + cookie.load('BrandId'),
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                dispatch(setKitchenUpdate());
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

async function sleepKitchen(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
}

export async function formDataSetKitchen(key, val) {
    return async (dispatch, getState) => {
        dispatch({
            type: KITCHEN_GLOBAL_FORM_DATA_SET,
            payload: { key, val }
        });
    };
}

export async function setKitchenOrderDataUpdate(Id, itemId) {
    return async (dispatch, getState) => {
        dispatch({
            type: KITCHEN_ORDER_DATA_UPDATE,
            payload: Id,
            itemId: itemId
        });
    };
}

export async function setKitchenOrderData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: KITCHEN_ORDER_DATA,
            payload: data
        });
    };
}

export async function setKitchenUpdate() {
    return async (dispatch, getState) => {
        dispatch({
            type: KITCHEN_UPDATE
        });
    };
}

export async function setAllKitchenOrderData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: ALL_KITCHEN_ORDER_DATA,
            payload: data
        });
    };
}

export async function setKitchenModal(text) {
    return async (dispatch, getState) => {
        dispatch({
            type: KITCHEN_ERROR,
            payload: text
        });
    };
}

export async function setKitchenPin(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_KITCHEN_PIN,
            payload: data
        });
    };
}

export async function setKitchenLoading(visible) {
    return async (dispatch, getState) => {
        dispatch({
            type: KITCHEN_LOADING,
            payload: visible
        });
    };
}

const kitchenReducer = (state = initialState, action) => {
    switch (action.type) {
        case KITCHEN_COOKING_TIME_DATA:
            return { ...state, kitchenCookingTimeData: action.payload, kitchenUpdate: !state.kitchenUpdate }
        case SET_KITCHEN_STATUS_TYPE:
            return { ...state, kitchenStatusType: action.payload, kitchenUpdate: !state.kitchenUpdate }
        case SET_KITCHEN_PIN:
            return { ...state, kitchenUserPIN: (action.payload.length == 0 ? "" : action.payload), kitchenUpdate: !state.kitchenUpdate }
        case KITCHEN_LOADING:
            return { ...state, kitchenLoading: !state.kitchenLoading, kitchenUpdate: !state.kitchenUpdate }
        case KITCHEN_ORDER_DATA_UPDATE:
            {
                var list = state.kitchenOrderData;
                var data = action.payload;
                var itemId = action.itemId;
                if (itemId != "00000000-0000-0000-0000-000000000000") {
                    list.find(x => x.Id == data).ProductItems.find(x => x.ItemId == itemId).PreparationComplated = true;
                    if (list.find(x => x.Id == data).ProductItems.filter(x => x.PreparationComplated == false).length == 0) {
                        list.splice(list.findIndex(x => x.Id == data), 1);
                    }
                } else {
                    list.splice(list.findIndex(x => x.Id == data), 1);
                }

                return { ...state, kitchenOrderData: list, kitchenUpdate: !state.kitchenUpdate }
            }
        case KITCHEN_ORDER_DATA:
            {
                if (state.kitchenStatusType == 1) {
                    return { ...state, kitchenOrderData: action.payload, kitchenUpdate: !state.kitchenUpdate }
                } else {
                    return { ...state, kitchenOrderData: state.kitchenOrderData, kitchenUpdate: !state.kitchenUpdate }
                }
            }
        case ALL_KITCHEN_ORDER_DATA:
            return { ...state, kitchenOrderData: action.payload, kitchenUpdate: !state.kitchenUpdate }
        case KITCHEN_UPDATE:
            return { ...state, kitchenUpdate: !state.kitchenUpdate }
        case KITCHEN_ERROR:
            return {
                ...state, form_data: {
                    ...state.form_data,
                    modalOpen: (action.payload.length == 0 ? false : true),
                    modalText: action.payload,
                    modalTitle: "Uyarı"
                }, kitchenUpdate: !state.kitchenUpdate
            }
        case KITCHEN_GLOBAL_FORM_DATA_SET:
            return {
                ...state,
                form_data: {
                    ...state.form_data,
                    [action.payload.key]: action.payload.val
                }
            }
        case KITCHEN_HUB_STOP:
            return { ...state, kitchenHubConnection: action.payload }
        default:
            return state;
    }
}

export default kitchenReducer;
