const styles = theme => ({
  input_in: {
    fontSize: 40,
    border: '1px solid white',
    borderRadius: 5,
    color: 'white'
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '1.5em',
      zIndex: 999
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '1px solid slategrey'
    }
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#424242',
    color: 'white',
    width: 'calc((100% / 5) - 6px)',
    height: 'auto',
    margin: '3px 3px 3px 3px',
    padding: '5px',
    boxSizing: 'border-box',
    border:'1px solid rgba(236, 240, 241,.2)'
  }
});

export default styles;
