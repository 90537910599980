import React from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import styles from '../../Settings/settings-jss';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    setProductQuantityAddVisible, setProductQuantityAdd, setProductQuantityClear,
    getStockAutoComplete, postProductQuantityAdd, setModalStock, setProductAddList,
    formDataSetStock, getStock, setProductQuantityAddDesc, hbCreateCustomer, setProductQuantityAddUpdate
} from '../../../../redux/modules/stock';
import {
    getCustomer, formDataSetCustomer, setCustomerAddVisible
} from '../../../../redux/modules/customer';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

import DatePicker from "react-datepicker";
import "../datepicker.css";
import { getParameter, trMoneyConvert } from '../../../../function/GeneralFunction';
import Autocomplete from 'react-autocomplete';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
const uuidv4 = require('uuid/v4');
import Divider from '@material-ui/core/Divider';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import classNames from 'classnames';
import CustomerAdd from '../Customer/CustomerAdd';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


function NumberFormatCustomInt(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            decimalScale={0}
            allowNegative={false}
            fixedDecimalScale={true}
            decimalSeparator="."
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    },
                });
            }}
            thousandSeparator
            autocomplete="off"
        />
    );
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            decimalScale={4}
            allowNegative={false}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator="."
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    },
                });
            }}
            autocomplete="off"
        />
    );
}

function renderInput(inputProps) {
    const { classes, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: ref,
                classes: {
                    input: classes.input,
                },
                ...other,
            }}
        />
    );
}

function renderSuggestionsContainer(options) {
    const { containerProps, children } = options;

    return (
        <Paper {...containerProps} square>
            {children}
        </Paper>
    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => (
                    part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </strong>
                    )
                ))}
            </div>
        </MenuItem>
    );
}

class ProductQuantityAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = { search: "", value: '', suggestions: [], inputList: {} }
    }

    async componentDidMount() {
        await this.props.getStock(false).then(() => { });
    }

    handleFocus = (event) => event.target.select();

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    handleDescChange = name => event => {
        this.props.productQuantityAddDesc[name] = (name == "CreationDatetime" || name == "ClosedDate" ? moment.utc(event, 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm") : (name == "ClosedStatus" ? event.target.checked : event.target.value));
        this.props.setProductQuantityAddDesc(this.props.productQuantityAddDesc).then(() => { })
    };

    async clickProductQuantitySave() {
        this.props.postProductQuantityAdd().then(() => { })
    }

    async clickProductQuantityAdd() {
        const { productQuantityAdd, productQuantityAddDesc } = this.props;
        var proAdd = JSON.parse(JSON.stringify(productQuantityAdd));
        proAdd.Id = uuidv4();
        proAdd.IsNew = true;
        productQuantityAddDesc.StockQuantityList.push(proAdd);
        this.props.setProductQuantityAddDesc(productQuantityAddDesc).then(() => { })
    }

    productAddListDelete(Id) {
        const { productQuantityAddDesc } = this.props;
        if (productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id).IsNew) {
            productQuantityAddDesc.StockQuantityList.splice(productQuantityAddDesc.StockQuantityList.findIndex(x => x.Id == Id), 1);
        } else {
            productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id).IsDelete = true;
        }
        this.props.setProductQuantityAddDesc(productQuantityAddDesc).then(() => { })
    }

    getProductName(StockId) {
        var proName = "";
        if (this.props.productListSearch.filter(x => x.Id == StockId).length > 0) {
            proName = this.props.productListSearch.find(x => x.Id == StockId).Name;
        }
        return proName;
    }

    compare(a, b) {
        const genreA = a.Order;
        const genreB = b.Order;

        let comparison = 0;
        if (genreA > genreB) {
            comparison = 1;
        } else if (genreA < genreB) {
            comparison = -1;
        }
        return comparison;
    }

    renderUnitName(StockId, UnitId) {
        var UnitName = "";
        if (StockId != '00000000-0000-0000-0000-000000000000' && UnitId != '00000000-0000-0000-0000-000000000000') {
            UnitName = this.props.productListSearch.find(x => x.Id == StockId).Unit.find(x => x.Id == UnitId).UnitName;
        }
        return UnitName;
    }

    renderUnitsFirst(StockId) {
        var Units = [];
        if (StockId != '00000000-0000-0000-0000-000000000000') {
            Units = this.props.productListSearch.find(x => x.Id == StockId).Unit.sort(this.compare)[0];
        }
        return Units;
    }

    renderUnits(StockId) {
        var Units = [];
        if (StockId != '00000000-0000-0000-0000-000000000000') {
            Units = this.props.productListSearch.find(x => x.Id == StockId).Unit;
        }
        return Units;
    }

    handleChangeUpdate = (name, Id) => event => {
        const { productQuantityAddDesc } = this.props;

        var stockData = productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id);
        var product = this.props.productListSearch.find(x => x.Id == stockData.StockId);

        var data = event.target.value;
        if (name == "Price" || name == "SecondQuantity" || name == "DiscountRate") {
            data = parseFloat(data);
            if (name == "SecondQuantity") {
                if (stockData.UnitId == stockData.SecondUnitId) {
                    productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id).Quantity = data;
                } else {
                    var unit = product.Unit.find(x => x.Id == stockData.SecondUnitId);
                    productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id).Quantity = data * unit.UnitMultiplier;
                }
            }
        } else if (name == "VatRate") {
            data = Number(data);
        }
        if (name == "SecondUnitId") {
            productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id).SecondUnitId = data;
            if (stockData.UnitId == data) {
                productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id).Quantity = productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id).SecondQuantity;
            } else {
                var unit = product.Unit.find(x => x.Id == data);
                productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id).Quantity = productQuantityAddDesc.StockQuantityList.find(x => x.Id == Id).SecondQuantity * unit.UnitMultiplier;
            }
        }

        this.props.setProductQuantityAddUpdate(data, name, Id).then(() => { })
    };

    productAutoComplate(props) {
        const { productListSearch } = this.props;
        const suggestionsApi = [];

        productListSearch.map((pro, key) => {
            suggestionsApi.push({ label: pro.Name, value: pro.Id });
        });

        return suggestionsApi;
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0 ? [] : this.productAutoComplate().filter(suggestion => {
            //onst keep = count < 10 && suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;
            //console.log("suggestion.label.toLowerCase().indexOf(inputValue)",suggestion.label.toLowerCase().indexOf(inputValue))
            const keep = count < 15 && suggestion.label.toLowerCase().indexOf(inputValue) !== -1;
            if (keep) {
                count += 1;
            }

            return keep;
        });
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    getSuggestionValue = (i) => (suggestion) => {
        const { productQuantityAddDesc, productListSearch } = this.props;
        var pro = productListSearch.find(x => x.Id == suggestion.value);

        var proData = productQuantityAddDesc.StockQuantityList[i];
        if (proData.StockName != undefined && proData.StockId == "00000000-0000-0000-0000-000000000000") {
            if (proData.StockName.length > 0) {
                productQuantityAddDesc.StockQuantityList[i].StockId = suggestion.value;
                productQuantityAddDesc.StockQuantityList[i].UnitId = pro.Unit[0].Id;
                this.props.setProductQuantityAddDesc(productQuantityAddDesc).then(() => { })
                this.props.setProductQuantityAddUpdate("", "", 0, true).then(() => { });
                return suggestion.label;
            }
        }

        var unitFirst = pro.Unit.sort(this.compare)[0];
        productQuantityAddDesc.StockQuantityList[i].StockId = suggestion.value;
        productQuantityAddDesc.StockQuantityList[i].VatRate = pro.Kdv;
        productQuantityAddDesc.StockQuantityList[i].UnitId = unitFirst.Id;
        productQuantityAddDesc.StockQuantityList[i].Price = pro.Unit[0].Cost;
        productQuantityAddDesc.StockQuantityList[i].SecondUnitId = unitFirst.Id

        this.props.setProductQuantityAddDesc(productQuantityAddDesc).then(() => { })
        this.props.setProductQuantityAddUpdate("", "", 0, true).then(() => { });
        return suggestion.label;
    }

    renderMenuItemAuto(Id, i) {
        const { classes } = this.props;
        const { suggestions, inputList } = this.state;
        return (
            <Autosuggest
                theme={{
                    input: classes.inputText,
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderInputComponent={renderInput}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={this.getSuggestionValue(i)}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    classes,
                    placeholder: 'Lütfen Ürün Seçiniz',
                    value: this.state.inputList[Id + "^" + i] || '',
                    onChange: this.handleChangeMenuSelect(Id + "^" + i)
                }}
            />
        )
    }

    handleChangeMenuSelect = Id => (event, { newValue }) => {
        this.state.inputList[Id] = newValue;
        this.setState({ inputList: this.state.inputList });
    };

    async customerAddVisible() {
        const { productQuantityAddDesc } = this.props;
        if (productQuantityAddDesc.CustomerName.length > 0 && productQuantityAddDesc.CustomerId == "") {
            await this.props.hbCreateCustomer().then(() => { });
        } else {
            this.props.setCustomerAddVisible(true, "1").then(() => { })
        }
    }

    render() {
        const { classes, productQuantityAddDesc, customerList, customerValue } = this.props;

        return (
            <Dialog
                open={this.props.productQuantityAddVisible}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ classes: { root: classes.dialogQuantityAddPaper } }}
            >
                <CustomerAdd classes={classes} />
                <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 5 }}>
                    {"Alış Fişi İşlemleri"}
                </DialogTitle>
                <DialogContent style={{ height: '100vh', zIndex: 1 }}>
                    <SnackbarContent style={{ backgroundColor: '#ffa000', borderRadius: 5, maxWidth: '100%', width: '100%', marginBottom: 5 }} className={classNames(classes.snackbar, messageStyles.bgSuccess)} message="Not: Alım yaptığınız fiyatlar otomatik olarak reçete ve ürün maliyetlerine aktarılacaktır! Alış fiyatları boş bırakılamaz!" />
                    <Grid container spacing={1}>
                        <Grid item md={3}>
                            <FormControl className={classes.formControl} style={{ margin: 0 }}>
                                <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Tedarikçi Seçimi"}</Typography>
                                <Autocomplete
                                    value={customerValue}
                                    inputProps={{ id: 'states-autocomplete', placeholder: 'Ad Soyad veya Telefon' }}
                                    wrapperStyle={{ position: 'relative', marginRight: 5 }}
                                    items={customerList}
                                    getItemValue={item => item.Name}
                                    onChange={(event, value) => this.props.formDataSetCustomer('customerValue', value).then(() => {
                                        this.props.getCustomer().then(() => { });
                                    })}
                                    onSelect={(value, item) => {
                                        this.props.formDataSetCustomer('customerValue', "").then(() => { });
                                        productQuantityAddDesc.CustomerId = item.Id;
                                        productQuantityAddDesc.CustomerName = item.Name;
                                        this.props.setProductQuantityAddDesc(productQuantityAddDesc).then(() => { })
                                    }}
                                    renderMenu={children => (
                                        <div className="menu">
                                            {children}
                                        </div>
                                    )}
                                    renderItem={(item, isHighlighted) => (
                                        <div
                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                            key={item.Id}
                                        >
                                            {item.Name}
                                            {' '}
                                            {'-'}
                                            {' '}
                                            {item.Phone}
                                        </div>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1}>
                            <Button fullWidth style={{ marginTop: 17, borderRadius: 5, textTransform: 'capitalize' }} onClick={() => this.customerAddVisible()} color="primary" variant="contained" autoFocus>
                                <Icon style={{ marginRight: 5 }}>{"person_add"}</Icon>
                                {"Yeni Ekle"}
                            </Button>
                        </Grid>
                        <Grid item md={9}>
                        </Grid>
                        <Grid item md={6} style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}>
                            <FormControl className={classes.formControl} style={{ margin: 0 }}>
                                <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Seçilen Tedarikçi"}</Typography>
                                <span style={{ display: 'flex', alignItems: 'center', minHeight: 38, backgroundColor: (productQuantityAddDesc.CustomerName.length == 0 ? '' : '#16a085'), borderRadius: 8, border: '1px solid rgba(255,255,255,0.32)', padding: 5 }}>{productQuantityAddDesc.CustomerName}</span>
                            </FormControl>
                        </Grid>
                        <Grid item md={3}>
                            <FormControl className={classes.formControl} style={{ margin: 0 }}>
                                <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Evrak No"}</Typography>
                                <TextField
                                    value={productQuantityAddDesc.ReceiptNumber}
                                    defaultValue={""}
                                    onChange={this.handleDescChange("ReceiptNumber")}
                                    id="formatted-numberformat-input"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3}>
                            <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Tarihi"}</Typography>
                            <DatePicker
                                selected={new Date(productQuantityAddDesc.CreationDatetime)}
                                onChange={this.handleDescChange("CreationDatetime")}
                                timeInputLabel="Saat:"
                                dateFormat="dd.MM.yyyy HH:mm:ss"
                                showTimeInput
                                withPortal
                                locale="tr"
                                className={"inputDate"}
                            />
                        </Grid>
                        <Grid item md={10}>
                            <FormControl className={classes.formControl} style={{ margin: 0 }}>
                                <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Açıklama"}</Typography>
                                <TextField
                                    value={productQuantityAddDesc.Explanation}
                                    defaultValue={""}
                                    onChange={this.handleDescChange("Explanation")}
                                    id="formatted-numberformat-input"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <Button fullWidth style={{ marginTop: 17, borderRadius: 5, textTransform: 'capitalize' }} onClick={() => this.clickProductQuantityAdd()} color="primary" variant="contained" autoFocus>
                                <Icon style={{ marginRight: 5 }}>{"arrow_downward"}</Icon>
                                {"Ürün Satırı Ekle"}
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginBottom: 5, marginTop: 5 }} />
                    <Grid container spacing={1}>

                    </Grid>
                    <Divider style={{ marginBottom: 5, marginTop: 5 }} />
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <Paper className={classes.root} style={{ margin: 0, padding: 3, borderRadius: 5, backgroundColor: '#424242' }} elevation={4}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '25%' }} align="left">{"Ürün Adı"}</TableCell>
                                            <TableCell align="center">{"Temel Birim"}</TableCell>
                                            <TableCell align="center">{"Temel Miktar"}</TableCell>
                                            <TableCell align="center">{"İkinci Birim"}</TableCell>
                                            <TableCell align="center">{"İkinci Miktar"}</TableCell>
                                            <TableCell align="center">{"Fiyat"}</TableCell>
                                            <TableCell align="center">{"Kdv Durum"}</TableCell>
                                            <TableCell align="center">{"Kdv %"}</TableCell>
                                            <TableCell align="center">{"Kdv Tutar"}</TableCell>
                                            <TableCell align="center">{"Tutar"}</TableCell>
                                            <TableCell align="center">{"İndirim %"}</TableCell>
                                            <TableCell align="center">{"İnd. Tutar"}</TableCell>
                                            <TableCell align="center">{"Net"}</TableCell>
                                            <TableCell align="right">{""}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(productQuantityAddDesc.StockQuantityList.filter(x => x.IsDelete == false).map((n, i) => {
                                            return (
                                                <TableRow hover key={n.Id}>
                                                    <TableCell align="left">
                                                        <span style={{ marginLeft: (n.StockName != undefined ? 10 : 0) }}>{(n.StockName != undefined && n.StockId == "00000000-0000-0000-0000-000000000000" ? (n.StockName.length > 0 ? n.StockName : "") : "")}</span>
                                                        {(n.StockId != "00000000-0000-0000-0000-000000000000" ? this.getProductName(n.StockId) : "")}
                                                        {this.renderMenuItemAuto(n.Id, i)}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        {this.renderUnitsFirst(n.StockId).UnitName}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        {n.Quantity}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        <Select
                                                            value={n.SecondUnitId}
                                                            fullWidth
                                                            onChange={this.handleChangeUpdate("SecondUnitId", n.Id)}
                                                            input={<Input name="Aylar" id="age-helper" />}>
                                                            {
                                                                this.renderUnits(n.StockId).sort(this.compare).map((n, i) => {
                                                                    return <MenuItem value={n.Id}>{n.UnitName}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        <TextField
                                                            value={n.SecondQuantity}
                                                            fullWidth
                                                            onChange={this.handleChangeUpdate("SecondQuantity", n.Id)}
                                                            id="formatted-numberformat-input"
                                                            onFocus={this.handleFocus}
                                                            InputProps={{
                                                                inputComponent: NumberFormatCustom,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        <TextField
                                                            value={n.Price}
                                                            fullWidth
                                                            onChange={this.handleChangeUpdate("Price", n.Id)}
                                                            id="formatted-numberformat-input"
                                                            onFocus={this.handleFocus}
                                                            InputProps={{
                                                                inputComponent: NumberFormatCustom,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 100 }}>
                                                        <Select
                                                            value={n.VatStatus}
                                                            fullWidth
                                                            onChange={this.handleChangeUpdate("VatStatus", n.Id)}
                                                            input={<Input name="Aylar" id="age-helper" />}>

                                                            <MenuItem value={false}>{'Hariç'}</MenuItem>
                                                            <MenuItem value={true}>{'Dahil'}</MenuItem>
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        <TextField
                                                            value={n.VatRate}
                                                            fullWidth
                                                            onChange={this.handleChangeUpdate("VatRate", n.Id)}
                                                            id="formatted-numberformat-input"
                                                            onFocus={this.handleFocus}
                                                            InputProps={{
                                                                inputComponent: NumberFormatCustomInt,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        {trMoneyConvert(n.VatAmount, 4)}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        {trMoneyConvert(n.Amount, 4)}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        <TextField
                                                            value={n.DiscountRate}
                                                            fullWidth
                                                            onChange={this.handleChangeUpdate("DiscountRate", n.Id)}
                                                            id="formatted-numberformat-input"
                                                            onFocus={this.handleFocus}
                                                            InputProps={{
                                                                inputComponent: NumberFormatCustomInt,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        {trMoneyConvert(n.DiscountAmount, 4)}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 80 }}>
                                                        {trMoneyConvert(n.NetAmount, 4)}
                                                    </TableCell>
                                                    <TableCell align="right" style={{ width: 80 }}>
                                                        <Tooltip title="Sil">
                                                            <IconButton style={{ backgroundColor: '#e74c3c', padding: 5 }} onClick={() => this.productAddListDelete(n.Id)}>
                                                                <Icon>{"delete_outline"}</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#313131' }}>
                    <Grid container spacing={1}>
                        <Grid item md={9} style={{ display: 'flex', alignItems: 'center' }}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <FormControlLabel
                                        style={{ marginLeft: 0 }}
                                        control={(
                                            <Switch
                                                checked={productQuantityAddDesc.ClosedStatus}
                                                onChange={this.handleDescChange("ClosedStatus")}
                                                value="checkedA"
                                            />
                                        )}
                                        label="Kapalı Fatura"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"İşlem Tarihi"}</Typography>
                                    <DatePicker
                                        selected={new Date(productQuantityAddDesc.ClosedDate)}
                                        onChange={this.handleDescChange("ClosedDate")}
                                        timeInputLabel="Saat:"
                                        dateFormat="dd.MM.yyyy HH:mm:ss"
                                        showTimeInput
                                        withPortal
                                        locale="tr"
                                        className={"inputDate"}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <FormControl className={classes.formControl} style={{ margin: 0 }}>
                                        <Typography style={{ marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Ödeme Tipi"}</Typography>
                                        <Select
                                            fullWidth
                                            style={{ height: 38 }}
                                            value={productQuantityAddDesc.ClosedPaymentId}
                                            onChange={this.handleDescChange("ClosedPaymentId")}
                                            inputProps={{
                                                name: 'age',
                                                id: 'age-simple',
                                            }}
                                        >
                                            {
                                                getParameter("PaymentTypes").filter(x => x.IsStatus == true && x.Id != '58d99ca4-e5f6-49e8-80a4-a4936c181b8a').map((n, i) => {
                                                    return <MenuItem key={i} value={n.Id}>{n.Name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4}></Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3}>
                            <Grid container spacing={1}>
                                <Grid container spacing={1}>
                                    <Grid item md={6} style={{ borderBottom: '1px solid white', textAlign: 'right', fontSize: 13 }}>{"Ara Toplam :"}</Grid>
                                    <Grid item md={6} style={{ borderBottom: '1px solid white', textAlign: 'right' }}>{trMoneyConvert(productQuantityAddDesc.NetTotal, 4)}</Grid>
                                    <Grid item md={6} style={{ borderBottom: '1px solid white', textAlign: 'right', fontSize: 13 }}>{"Toplam KDV :"}</Grid>
                                    <Grid item md={6} style={{ borderBottom: '1px solid white', textAlign: 'right' }}>{trMoneyConvert(productQuantityAddDesc.VatTotal, 4)}</Grid>
                                    <Grid item md={6} style={{ borderBottom: '1px solid white', textAlign: 'right', fontSize: 13 }}>{"Toplam İskonto :"}</Grid>
                                    <Grid item md={6} style={{ borderBottom: '1px solid white', textAlign: 'right' }}>{trMoneyConvert(productQuantityAddDesc.DiscountTotal, 4)}</Grid>
                                    <Grid item md={6} style={{ textAlign: 'right', fontSize: 16, fontWeight: 600 }}>{"Toplam :"}</Grid>
                                    <Grid item md={6} style={{ textAlign: 'right', fontSize: 16, fontWeight: 600 }}>{trMoneyConvert(productQuantityAddDesc.Total, 4)}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </DialogActions>
                <DialogActions>
                    <Button style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }} color="primary" autoFocus onClick={() => this.props.setProductQuantityAddVisible().then(() => { })}>
                        {"ÇIKIŞ"}
                    </Button>
                    <Button style={{ marginRight: 5 }} onClick={() => this.props.setProductQuantityClear().then(() => { })} color="default" variant="contained" autoFocus>
                        {"TEMİZLE"}
                    </Button>
                    <Button style={{ marginRight: 5 }} onClick={() => this.clickProductQuantitySave()} color="secondary" variant="contained" autoFocus>
                        {"İŞLEMLERİ KAYDET"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('stockReducer').productQuantityAddUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    productQuantityAddUpdate: value(state, props),
    stockReducer: state.get('stockReducer').form_data,
    productQuantityAddVisible: state.get('stockReducer').productQuantityAddVisible,
    productQuantityAdd: state.get('stockReducer').productQuantityAdd,
    productAutoCompleteList: state.get('stockReducer').productAutoCompleteList,
    productQuantityUpdate: state.get('stockReducer').productQuantityUpdate,
    productAddList: state.get('stockReducer').productAddList,
    productQuantityAddDesc: state.get('stockReducer').productQuantityAddDesc,
    productMovementListVisible: state.get('stockReducer').productMovementListVisible,
    customerList: state.get('customerReducer').form_data.customerList,
    customerValue: state.get('customerReducer').form_data.customerValue,
    MenuItemSearchText: state.get('stockReducer').form_data.MenuItemSearchText,
    productListSearch: state.get('stockReducer').form_data.productListSearch,

    hbGetXmlFileReadData: state.get('stockReducer').form_data.hbGetXmlFileReadData
})

const mapDispatchToProps = {
    setProductQuantityAddVisible,
    setProductQuantityAdd,
    setProductQuantityClear,
    getStockAutoComplete,
    postProductQuantityAdd,
    setModalStock,
    setProductAddList,
    formDataSetStock,
    getStock,
    setProductQuantityAddDesc,
    getCustomer,
    formDataSetCustomer,
    setCustomerAddVisible,
    hbCreateCustomer,
    setProductQuantityAddUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ProductQuantityAdd)));