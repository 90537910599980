import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import styles from './cardStyle-jss';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';

class IntegrationCardText extends React.Component {
    clickButton = () => {
        this.props.clickButton();
    }

    render() {
        const {
            classes,
            backcolor,
            title,
            color,
            logo,
            visible,
            franchise
        } = this.props;
        
        return (
            <Card className={classNames(classes.priceCard)} style={{ width: '100%', boxShadow: 'none', backgroundColor: '#303030', position: 'relative', height: 225, borderRadius: 5, maxWidth: '100%', position: 'relative' }}>
                <div className={classes.priceHead} style={{ zIndex: 1, paddingTop: 0, textAlign: 'center' }}>
                    <div style={{ minHeight: 60, boxShadow: '0px 12px 12px -15px rgba(0, 0, 0, 0.5)', backgroundColor: backcolor, width: '100%', borderTopLeftRadius: 5, borderTopRightRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{logo}</div>
                    <Typography style={{ justifyContent: 'center', whiteSpace: 'pre-line', fontSize: 12, color: 'white', textTransform: 'capitalize', padding: 5, height: '85%', display: 'flex', alignItems: 'center' }}>{title}</Typography>
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: 10, right: 10, textAlign: 'center' }}>
                    <Divider />
                    <span style={{ fontSize: 12, color: 'white' }}>
                        {
                            (visible ? (franchise.IsFranchise && !franchise.IsEntVisible ? "Franchise tarafından giriş engellenmiştir!" : "") : "Modül Satın Alınması Gereklidir")
                        }
                    </span>
                    <Button color="default" onClick={() => (franchise.IsFranchise && !franchise.IsEntVisible ? null : this.clickButton())} style={{ zIndex: 1, borderRadius: 5, display: (visible ? (franchise.IsFranchise && !franchise.IsEntVisible ? 'none' : '') : 'none') }}>
                        {"Detaylar"}
                    </Button>
                </div>
            </Card>
        );
    }
}

IntegrationCardText.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    feature: PropTypes.array.isRequired,
    backcolor: PropTypes.string.isRequired
};

IntegrationCardText.defaultProps = {
    franchise: {
        IsFranchise:false,
        IsEntVisible:false
    },
  };

export default withStyles(styles)(IntegrationCardText);
