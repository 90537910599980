/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { reducer as form } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import history from 'utils/history';

import languageProviderReducer from 'containers/LanguageProvider/reducer';
import uiReducer from './modules/ui';
import initval from './modules/initForm';
import testReducer from './modules/test';
import languageReducer from './modules/language';
import registerReducer from './modules/register';
import loginReducer from './modules/login';
import tableReducer from './modules/table';
import stockReducer from './modules/stock';
import userReducer from './modules/user';
import additionReducer from './modules/addition';
import courierReducer from './modules/courier';
import customerReducer from './modules/customer';
import settingsReducer from './modules/settings';
import centerBrandReducer from './modules/centerbrand';
import reportReducer from './modules/report';
import systemReducer from './modules/system';
import licenceReducer from './modules/licence';
import logReducer from './modules/log';
import expenseReducer from './modules/expense';
import adminReducer from './modules/admin';
import trackReducer from './modules/track';
import plateReducer from './modules/plate';
import kitchenReducer from './modules/kitchen';
import paketYoldaReducer from './modules/paketyolda';

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    form,
    ui: uiReducer,
    initval,
    language: languageProviderReducer,
    testReducer,
    languageReducer,
    registerReducer,
    loginReducer,
    tableReducer,
    stockReducer,
    userReducer,
    additionReducer,
    courierReducer,
    customerReducer,
    settingsReducer,
    centerBrandReducer,
    reportReducer,
    systemReducer,
    licenceReducer,
    logReducer,
    expenseReducer,
    adminReducer,
    trackReducer,
    plateReducer,
    kitchenReducer,
    paketYoldaReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}
