import React from 'react';
import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';
import './stylesPopup.css';

const style = {
  width: '100%',
  height: '90%'
};

class MapClusterReport extends React.Component {
  componentDidMount() {
    // create map
    this.map = L.map('map', {
      center: this.props.center,
      zoom: 12,
      layers: [
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution:
                        '&copy; <a href="https://www.javipos.com">JaviPos Bulut Adisyon</a> Sistemleri'
        })
      ]
    });

    // add layer
    this.layer = L.markerClusterGroup().addTo(this.map);
    this.updateMarkers(this.props.markersData);
  }

  componentDidUpdate({ markersData }) {
    if (this.props.markersData !== markersData) {
      this.updateMarkers(this.props.markersData);
    }
  }

  updateMap(center) {
    this.map.setView(center, 14);
  }

  updateMarkers(markersData) {
    this.layer.clearLayers();

    markersData.forEach(marker => {
      const color = (marker.TableType == 'YemekSepetiGlobal' ? '#fa0050' : (marker.TableType == 'GetirYemek' ? '#9b59b6' : (marker.TableType == 'GoFody' ? '#f1b62a' : (marker.TableType == 'TrendyolYemek' ? '#f27a1a' : (marker.TableType == 'MigrosYemek' ? '#27ae60' : '#2980b9')))));
      const text = (marker.TableType == 'YemekSepetiGlobal' ? 'YS' : (marker.TableType == 'GetirYemek' ? 'GY' : (marker.TableType == 'GoFody' ? 'GF' : (marker.TableType == 'TrendyolYemek' ? 'TY' : (marker.TableType == 'MigrosYemek' ? 'MY' : 'TEL')))));
      L.marker([marker.Lat, marker.Lng], {
        icon: new L.DivIcon({
          className: 'my-div-icon',
          html: '<div style="background-color:' + color + ' !important" class="my-div-span"><div style="border-top:10px solid ' + color + ' !important" class="arrow-down"></div>' + text + '</div><div class="pulse"></div>',
          shadowUrl: markerShadowPng,
          shadowSize: [48, 48],
          shadowAnchor: [14, 48],
          iconSize: [40, 48],
          iconAnchor: [20, 48]
        })
      }, { title: marker.TableType }).addTo(this.layer);
    });
  }

  render() {
    return <div id="map" style={style} />;
  }
}

export default MapClusterReport;
