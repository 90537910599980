import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { EmptyData } from 'dan-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePagination from '@material-ui/core/TablePagination';
import {
    setPaketYoldaRegionAddVisible, deleteRegion, setPaketYoldaRegionAddData
} from '../../../../redux/modules/paketyolda';
import { getPaketYolda } from '../../../../function/GeneralFunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import RegionAdd from './RegionAdd';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
});

class RegionSettingsList extends React.Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaRegionListUpdate == this.props.paketYoldaRegionListUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    render() {
        const { paketYoldaRegionListData, paketYoldaRegionListLoading, paketYoldaSettingsMenu, classes } = this.props;

        if (paketYoldaSettingsMenu != "RegionSettings") {
            return null;
        }

        if (paketYoldaRegionListLoading) {
            return <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
                <CircularProgress
                    style={{ color: '#fafafa' }}
                    thickness={4}
                    className={classes.progress}
                />
            </div>
        }

        return (
            <Paper className={classes.root} elevation={0} style={{ backgroundColor: 'transparent', borderRadius: 0, height: '100%' }}>
                <RegionAdd classes={classes} />
                <div style={{ height: 'calc(100% - 43px)', padding: 5, overflowY: 'auto' }}>
                    <div style={{ backgroundColor: 'rgb(49, 49, 49)', padding: 5 }}>
                        <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {"Akıllı Atama için Bölge Tanımları"}
                        </div>
                        <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{'Bölge Adı'}</TableCell>
                                    <TableCell>{'Kuryeler'}</TableCell>
                                    <TableCell>{'İşletmeler'}</TableCell>
                                    <TableCell align={"right"}>{'İşlemler'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paketYoldaRegionListData.map((n, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{n.RegionName}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{n.RegionCourier.map(x => x.Name).join(", ")}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{n.RegionBusiness.map(x => x.Name).join(", ")}</TableCell>
                                            <TableCell align={"right"}>
                                                <Tooltip title={"Düzenle"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.props.setPaketYoldaRegionAddData(n, true).then(() => { })}>
                                                        <Icon>{"edit"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Sil"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#e74c3c', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => (confirm('Bölgeyi silmek istediğinize emin misiniz?') ? this.props.deleteRegion(n.Id).then(() => { }) : null)}>
                                                        <Icon>{"delete"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
                <div style={{ backgroundColor: 'rgb(66, 66, 66)', width: '100%', padding: 5, textAlign: 'right' }}>
                    <Button onClick={() => this.props.setPaketYoldaRegionAddVisible(true).then(() => { })} style={{ backgroundColor: '#2980b9' }} variant="contained" color="secondary">
                        {"Bölge Tanımı Ekle"}
                    </Button>
                </div>
            </Paper>
        )
    }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaRegionListUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    paketYoldaRegionListUpdate: value(state, props),
    paketYoldaSettingsMenu: state.get('paketYoldaReducer').paketYoldaSettingsMenu,
    paketYoldaRegionListLoading: state.get('paketYoldaReducer').paketYoldaRegionListLoading,
    paketYoldaRegionListData: state.get('paketYoldaReducer').paketYoldaRegionListData
})

const mapDispatchToProps = {
    setPaketYoldaRegionAddVisible,
    setPaketYoldaRegionAddData,
    deleteRegion
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(RegionSettingsList)));
