import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../Settings/settings-jss';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {
  selectedSubscriberChange,
  sendPaymentLinkNameSurnameChange,
  sendPaymentLinkPhoneChange,
  sendPaymentLinkPriceChange,
  getSendMessageLink
} from '../../../../redux/modules/admin';
import { createSelector } from 'reselect';
import 'react-credit-cards/es/styles-compiled.css';
import MaskedInput from 'react-text-mask';
import FormControl from '@material-ui/core/FormControl';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function TextMaskCustomPhone(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        ' ',
        /[1-9]/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class SendPaymentLinkModal extends React.Component {
  handleChangeInvoice = (Name) => (event) => {
    if (Name == 'Phone') {
      this.props.sendPaymentLinkPhoneChange(event.target.value);
    } else if (Name == 'NameSurname') {
      this.props.sendPaymentLinkNameSurnameChange(event.target.value);
    } else {
      this.props.sendPaymentLinkPriceChange(event.target.value);
    }
  };

  closeModalButton() {
    this.props.selectedSubscriberChange([], 'sendMessage', false);
    this.props.sendPaymentLinkNameSurnameChange('');
    this.props.sendPaymentLinkPriceChange('');
    this.props.sendPaymentLinkPhoneChange('');
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.sendPaymentLinkModalVisible}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.dialogPaperAbonelik } }}>
        <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {'Ödeme Link Gönder'}
        </DialogTitle>
        <DialogContent style={{ width: 400 }}>
          {this.props.modalsLoading == true ? (
            <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
              <CircularProgress
                style={{ color: '#fafafa' }}
                thickness={4}
                className={classes.progress}
              />
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <div style={{ textAlign: 'left', fontSize: 14, color: 'white' }}>
                {'Adı soyadı: ' + '\n' + this.props.selectedSubscriberData.name_surname}
              </div>
              <div style={{ textAlign: 'left', fontSize: 14, color: 'white' }}>
                {'Telefon numarası: ' + '\n' + '0' + this.props.selectedSubscriberData.end_user_gsm}
              </div>
              <div style={{ textAlign: 'left', fontSize: 14, color: 'white', marginBottom: 10 }}>
                {'Abonelik tutarı: ' + '\n' + this.props.selectedSubscriberData.amount}
              </div>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{'Ad Soyad'}</InputLabel>
                    <Input
                      fullWidth
                      defaultValue={this.props.sendPaymentLinkPhone}
                      onChange={this.handleChangeInvoice('NameSurname')}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{'Telefon'}</InputLabel>
                    <Input
                      inputComponent={TextMaskCustomPhone}
                      fullWidth
                      defaultValue={this.props.sendPaymentLinkPhone}
                      onChange={this.handleChangeInvoice('Phone')}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{'Tutar'}</InputLabel>
                    <Input
                      fullWidth
                      placeholder={"00,00"}
                      defaultValue={this.props.sendPaymentLinkPrice}
                      onChange={this.handleChangeInvoice('Price')}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContentText>
          )}
        </DialogContent>
        {this.props.modalsLoading == false ? (
          <DialogActions>
            <Button
              style={{ backgroundColor: '#e74c3c', color: 'white' }}
              onClick={() => this.closeModalButton()}
              color="primary">
              {'İptal'}
            </Button>
            <Button onClick={() => this.props.getSendMessageLink()} color="secondary" variant="contained" autoFocus>
              {'Gönder'}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    );
  }
}

SendPaymentLinkModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('adminReducer').sendPaymentLinkModalIsupdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  sendPaymentLinkModalIsupdate: value(state, props),
  sendPaymentLinkModalVisible: state.get('adminReducer').sendPaymentLinkModalVisible,
  selectedSubscriberData: state.get('adminReducer').selectedSubscriberData,
  sendPaymentLinkNameSurname: state.get('adminReducer').sendPaymentLinkNameSurname,
  sendPaymentLinkPhone: state.get('adminReducer').sendPaymentLinkPhone,
  sendPaymentLinkPrice: state.get('adminReducer').sendPaymentLinkPrice,
  modalsLoading: state.get('adminReducer').modalsLoading,
});

const mapDispatchToProps = {
  selectedSubscriberChange,
  sendPaymentLinkNameSurnameChange,
  sendPaymentLinkPhoneChange,
  sendPaymentLinkPriceChange,
  getSendMessageLink
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(SendPaymentLinkModal)));
