import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {
  ShadowDivider
} from '../../../components/Divider';

class ProductItemDetailPage extends React.Component {
  constructor() {
    super();
  }

  renderAdditionListPrice(item) {
    let price = item.Price;
    item.Unit.map(n => {
      if (n.IsSelected) {
        price = n.Price
      }
    })
    item.Property.map(n => {
      n.Item.map(n => {
        if (n.Default && n.Quantity == 0) {
          price += n.Price
        }else if (n.Default && n.Quantity > 0){
          price += (n.Price * n.Quantity)
        }
      })
    })

    item.Menu.map(n => {
      n.Item.map(n => {
        if (n.Default) {
          price += n.Price
        }
      })

      if (n.MenuDetailSelected != null) {
        if (n.MenuDetailSelected.length != 0) {
          n.MenuDetailSelected.map(x => {
            x.Item.map(n => {
              if (n.Default && n.Quantity == 0) {
                price += n.Price
              }else if (n.Default && n.Quantity > 0){
                price += (n.Price * n.Quantity)
              }
            })
          })
        }
      }
    })

    price = price * item.Quantity;
    return price.toFixed(2).replace(/[.]/, ",");
  }

  compare(a, b) {
    const genreA = a.Order;
    const genreB = b.Order;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  renderProductDetailUnit(unit) {
    if (unit.length == 1) {
      return null;
    } else {
      return unit.map((n, x) => {
        if (n.IsSelected) {
          return "(" + n.UnitName + ")"
        }
      })
    }
  }

  renderPropertyDetailMenu(MenuDetail) {
    if (MenuDetail != null) {
      if (MenuDetail.length > 0) {
        return MenuDetail.map((n, x) => {
          return n.Item.map((y, i) => {
            if (y.Default) {
              return (
                <span key={i} style={{ marginLeft: 7, fontSize: 13, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize' }}>{y.Quantity > 0 ? y.Quantity + "x" : ""}{y.Name}{" "}</span>
              )
            } else {
              return null;
            }
          })
        })
      }
    }
  }

  renderPropertyDetail(item) {
    return item.map((n, x) => {
      if (n.Default) {
        return (
          <span key={x} style={{ marginLeft: 7, fontSize: 13, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize' }}>{n.Quantity > 0 ? n.Quantity + "x" : ""}{" "}{n.Name}</span>
        )
      } else {
        return null;
      }
    })
  }

  renderProductDetail(property) {
    return property.map((n, x) => {
      return (
        <span key={x} style={{ fontSize: 13, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize' }}>{this.renderPropertyDetail(n.Item)}{(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? "(" : "") : "")}{this.renderPropertyDetailMenu(n.MenuDetailSelected)}{(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? ")" : "") : "")}</span>
      )
    })
  }

  renderAdditionList(ProductItems) {
    let order = ProductItems.filter(x=> x.IsDelete == false).sort(this.compare);
    return order.map((item, i) => {
      return (
        <div key={i} style={{ borderRadius: 0, textAlign: 'inherit', paddingTop: 2, margin: 5, paddingBottom: 2, minHeight: 56, flex: 1, position: 'relative' }}>
          <Grid item md={12} style={{ bottom: 0, left: 0, top: 0, right: 0 }}>
            <Grid container style={{ flexDirection: 'row' }}>
              <Grid item md={1}>
                <span style={{ marginLeft: 7, fontSize: 15, fontWeight: 500, color: 'white' }}>{item.Quantity}</span>
              </Grid>
              <Grid item md={9}>
                <span style={{ marginLeft: 7, fontSize: 15, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>{item.Name} {this.renderProductDetailUnit(item.Unit)}</span>
              </Grid>
              <Grid item md={2} style={{ textAlign: 'right' }}>
                <span style={{ marginRight: 7, fontSize: 16, fontWeight: 500, color: 'white' }}>{this.renderAdditionListPrice(item)}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={1}>
              </Grid>
              <Grid item md={11} style={{ whiteSpace: 'pre-line' }}>
                <span style={{ fontSize: 13, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize' }}>
                  {this.renderProductDetail(item.Property)}
                  {this.renderProductDetail(item.Menu)}
                  {
                    (item.Description != null ? (item.Description.length != 0 ? <span style={{ marginLeft: 7, fontSize: 13, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize' }}>{"("}{item.Description}{")"}</span> : null) : null)
                  }
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }} />
        </div>
      );
    })
  }

  handleClose = () => {
    this.props.eventClose();
  };

  render() {
    const { classes, open, contentStyle, contentTextStyle, Addition } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullWidth
          maxWidth={'sm'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
            {"Adisyon Detayı"}
          </DialogTitle>
          <DialogContent style={contentStyle}>
            <DialogContentText style={contentTextStyle} id="alert-dialog-description">
              <div style={{ margin: 10, display: (Addition.CustomerName.length == 0 ? 'none' : 'inline-block') }}>
                <Typography style={{ fontSize: 12, color: '#f39c12' }} variant="subtitle1">{"Müşteri Adı : "}{Addition.CustomerName}</Typography>
                <Typography style={{ fontSize: 12, color: '#f39c12' }} variant="subtitle1">{"Adres : "}{Addition.CustomerAddressText}</Typography>
                <Typography style={{ fontSize: 12, color: '#f39c12' }} variant="subtitle1">{"Açıklama : "}{Addition.AdditionDescription}</Typography>
              </div>
              <ShadowDivider style={{ marginTop: 0, marginBottom: 0 }} />
              {this.renderAdditionList(Addition.ProductItems)}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default ProductItemDetailPage;
