import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../../SettingPage/datepicker.css';
import tr from 'date-fns/locale/tr';
import 'leaflet/dist/leaflet.css';
import cookie from 'react-cookies';
import Select from '@material-ui/core/Select';
import MapClusterReport from '../../SettingPage/ReportPage/Maps/MapClusterReport';
import {
    getDailyMonthlyGrafficReport, setFranchiseReportGrafficFilter, setFranchiseReportBrandSelected, setFranchiseMainReportData
} from '../../../../redux/modules/centerbrand';
import { getParameter } from '../../../../function/GeneralFunction';
registerLocale('tr', tr);
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
    BarChart,
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    CartesianAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
    PieChart, Pie, Cell,
    Sector,
    LabelList,
    Line
} from 'recharts';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
});

function CustomTooltip({ payload, label, active }) {
    if (active && payload != null) {
        return (
            <div style={{ backgroundColor: '#313131', borderRadius: 5, padding: 10 }}>
                <div style={{ display: 'flex', width: 200 }}>
                    <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'left' }}>{`Saat`}</div>
                    <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'right' }}>{`${label}:00`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'left' }}>{`İç Satış`}</div>
                    <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'right' }}>{`${payload[0].payload.NormalTotal.toFixed(2)}`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'left' }}>{`Paket Satışı`}</div>
                    <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'right' }}>{`${payload[0].payload.PackageTotal.toFixed(2)}`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'left' }}>{`İç Satış Adet`}</div>
                    <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'right' }}>{`${payload[0].payload.NormalCount.toFixed(2)}`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'left' }}>{`Paket Satışı Adet`}</div>
                    <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'right' }}>{`${payload[0].payload.PackageCount.toFixed(2)}`}</div>
                </div>
            </div>
        );
    }

    return null;
}

class DailyMonthlyGraffic extends React.Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.franchiseReportGrafficUpdate == this.props.franchiseReportGrafficUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    handleChangeFilterDaily = name => event => {
        const { franchiseReportGrafficFilter } = this.props;
        franchiseReportGrafficFilter[name] = (name == "Datetime" ? moment.utc(event, 'MM-DD-YYYY').local().format("YYYY-MM-DD") : (name == "GrafficType" ? event.target.value : event));
        this.props.setFranchiseReportGrafficFilter(franchiseReportGrafficFilter).then(() => { });
        if(name == "GrafficType"){
            this.props.setFranchiseMainReportData([]).then(() => { });
        }
    };

    handleChange = async (event) => {
        this.props.setFranchiseReportBrandSelected(event.target.value).then(() => { });
    };

    renderDataHour() {
        const { franchiseMainReportData } = this.props;

        var dataArray = [];
        franchiseMainReportData.map((n, i) => {
            dataArray.push({ Hour: n.Hour, NormalTotal: n.NormalTotal, PackageTotal: n.PackageTotal, NormalCount: n.NormalCount, PackageCount: n.PackageCount });
        })
        return dataArray;
    }

    renderDataMoutly() {
        const { franchiseMainReportData } = this.props;

        const dataArray = [];
        franchiseMainReportData.map((n, i) => {
            dataArray.push({
                Day: n.Day, NormalTotal: n.NormalTotal, PackageTotal: n.PackageTotal, NormalCount: n.NormalCount, PackageCount: n.PackageCount
            });
        });
        return dataArray;
    }

    render() {
        const {
            franchiseReportName, franchiseMainReportData, franchiseReportGrafficFilter, isCenterReportLoading, classes,
            franchiseReportBrandSelected, centerBrandData
        } = this.props;

        if (franchiseReportName != 'DayMounthReport') {
            return null;
        }

        return (
            <Paper className={classes.root} elevation={0} style={{ height: '97%', borderRadius: 0 }}>
                <div style={{
                    backgroundColor: '#424242', padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}
                >
                    {"Gün veya Aylık İstatistiksel Grafik"}
                </div>
                <Grid container spacing={1} style={{ padding: 10 }}>
                    <Grid item md={2} style={{ display: 'block' }}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Rapor Tipi"}
                        </Typography>
                        <RadioGroup
                            aria-label="radio"
                            name="radio"
                            className={classes.group}
                            style={{ display: 'block', width: '100%' }}
                            value={Number(franchiseReportGrafficFilter.GrafficType)}
                            onChange={this.handleChangeFilterDaily("GrafficType")}
                        >
                            <FormControlLabel value={1} control={<Radio />} label={"Günlük"} />
                            <FormControlLabel value={2} control={<Radio />} label={"Aylık"} />
                        </RadioGroup>
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Tarih"}
                        </Typography>
                        {
                            (franchiseReportGrafficFilter.GrafficType == 2 ?
                                <DatePicker
                                    selected={new Date(franchiseReportGrafficFilter.Datetime)}
                                    showMonthYearPicker
                                    onChange={this.handleChangeFilterDaily('Datetime')}
                                    dateFormat="MM.yyyy"
                                    locale="tr"
                                    className="inputDate"
                                /> :
                                <DatePicker
                                    selected={new Date(franchiseReportGrafficFilter.Datetime)}
                                    onChange={this.handleChangeFilterDaily('Datetime')}
                                    dateFormat="dd.MM.yyyy"
                                    locale="tr"
                                    className="inputDate"
                                />
                            )
                        }

                    </Grid>
                    <Grid item md={5}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Şube Seçimi"}
                        </Typography>
                        <Select
                            fullWidth
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            style={{ height: 38 }}
                            value={franchiseReportBrandSelected}
                            onChange={this.handleChange}
                        >
                            <MenuItem value={"00000000-0000-0000-0000-000000000000"}>
                                <ListItemText primary={"Tüm Şubeler"} />
                            </MenuItem>
                            {centerBrandData.map((n, i) => (
                                <MenuItem lat={n.Latitude} lng={n.Longitude} key={i} value={n.Id}>
                                    <ListItemText primary={n.Name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item md={2}>
                        <Button fullWidth disabled={isCenterReportLoading} onClick={() => this.props.getDailyMonthlyGrafficReport().then(() => { })} style={{ marginTop: 18, borderRadius: 5, height: 37, padding: '6px 24px' }} size="large" variant="contained" color="secondary" type="submit">
                            {'FİLTRELE'}
                        </Button>
                    </Grid>
                </Grid>
                {(isCenterReportLoading ? <div style={{ width: '100%', textAlign: 'center', marginBottom: 15 }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
                <div style={{
                    display: 'flex', height: '97%', width: '100%'
                }}
                >
                    <ResponsiveContainer>
                        <ComposedChart
                            data={(franchiseReportGrafficFilter.GrafficType == 2 ? this.renderDataMoutly() : this.renderDataHour())}
                            margin={{
                                top: 15,
                                right: 20, left: -10,
                                bottom: 100
                            }}
                        >
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={"#f7b731"} stopOpacity={1} />
                                    <stop offset="95%" stopColor={"#f7b731"} stopOpacity={1} />
                                </linearGradient>
                                <linearGradient id="colorUp" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={"#0fb9b1"} stopOpacity={1} />
                                    <stop offset="95%" stopColor={"#0fb9b1"} stopOpacity={1} />
                                </linearGradient>
                            </defs>
                            <XAxis interval={0} angle={-45} textAnchor="end" dataKey={(franchiseReportGrafficFilter.GrafficType == 2 ? "Day" : "Hour")} tickLine={false} label={{ size: 12 }} />
                            <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
                            <CartesianGrid vertical={false} strokeDasharray="3 3" />
                            <CartesianAxis vertical={false} />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend verticalAlign={"bottom"} formatter={this.renderColorfulLegendText} />
                            <Line strokeWidth={2} type="monotone" stackId="a" name="İç Satış" dataKey="NormalTotal" stroke="url(#colorUv)" />
                            <Line strokeWidth={2} type="monotone" stackId="a" name="Paket Satışı" dataKey="PackageTotal" stroke="url(#colorUp)" />
                            <Bar stackId="b" name="İç Satış Adet" dataKey="NormalCount" fill="url(#colorUv)" />
                            <Bar stackId="b" name="Paket Satışı Adet" dataKey="PackageCount" fill="url(#colorUp)" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </Paper>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').franchiseReportGrafficUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    franchiseReportGrafficUpdate: value(state, props),
    franchiseReportName: state.get('centerBrandReducer').franchiseReportName,
    isCenterReportLoading: state.get('centerBrandReducer').isCenterReportLoading,
    franchiseReportGrafficFilter: state.get('centerBrandReducer').franchiseReportGrafficFilter,
    franchiseMainReportData: state.get('centerBrandReducer').franchiseMainReportData,
    centerBrandData: state.get('centerBrandReducer').centerBrandData,
    franchiseReportBrandSelected: state.get('centerBrandReducer').franchiseReportBrandSelected
});

const mapDispatchToProps = {
    getDailyMonthlyGrafficReport,
    setFranchiseReportGrafficFilter,
    setFranchiseReportBrandSelected,
    setFranchiseMainReportData
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(DailyMonthlyGraffic)));
