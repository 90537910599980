import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from './SlidingMenu-jss';
import {
    setTrendyolClaimsVisible, getTrendyolClaimsStatus
} from '../../../redux/modules/settings';
import {
    getPackageAdditionDetail
} from '../../../redux/modules/addition';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import PackageAdditionDetail from '../../Pages/PackagePage/PackageAdditionDetail';

class TrendyolClaims extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.trendyolClaimsUpdate == this.props.trendyolClaimsUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() { }

    returnClaimsItem(items) {
        return items.map((n, i) => {
            return (
                <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ width: '40%', textAlign: 'left' }}>{n.name}</div>
                    <div style={{ width: '40%', textAlign: 'left' }}>{n.customerClaimItemReason.name}</div>
                    <div style={{ width: '20%', textAlign: 'right' }}>{n.price.toFixed(2)}</div>
                </div>
            )
        })

    }

     handleClickPopover = event => {
        this.props.getPackageAdditionDetail(event.currentTarget.id, event.currentTarget);
    };

    render() {
        const { classes, trendyolClaimsLoading, trendyolClaimsData, trendyolClaimsVisible } = this.props;

        return (
            <Dialog
                open={trendyolClaimsVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'lg'}
                PaperProps={{ classes: { root: classes.dialogPaperTrendyolClaims } }}
            >
                <PackageAdditionDetail classes={classes} />
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Trendyol İade İşlemleri"}
                </DialogTitle>
                <SnackbarContent style={{ textAlign: 'center', backgroundColor: '#ffa000', borderRadius: 5, maxWidth: '100%', width: '100%', marginBottom: 5 }} className={classNames(classes.snackbar, messageStyles.bgSuccess)} message="Önemli Açıklama: TrendyolYemek iade kontrolleri 30dk bir otomatik olarak yapılmaktadır. İade işlemlerine maksimum 4 saat içerisinde cevap vermeniz gerekmektedir. Aksi halde TrendyolYemek iade işlemini otomatik yapmaktadır!" />
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '10%' }}>{'Sipariş Numarası'}</TableCell>
                                <TableCell>{'Ürünler'}</TableCell>
                                <TableCell align="center" style={{ width: '10%' }}>{'Toplam Fiyat'}</TableCell>
                                <TableCell style={{ width: '15%' }}>{'Tarih'}</TableCell>
                                <TableCell style={{ width: '25%' }} align={"right"}>{'İşlemler'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trendyolClaimsData.map((n, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left" style={{ padding: 5 }}>{n.orderNumber}</TableCell>
                                        <TableCell align="left" style={{ padding: 5 }}>
                                            <div style={{ padding: 5, borderRadius: 5, border: '1px solid rgba(236, 240, 241,.3)', margin: 5 }}>
                                                <div style={{ width: '100%', display: 'flex', marginBottom: 5 }}>
                                                    <div style={{ width: '40%', textDecoration: 'underline', textAlign: 'left' }}>{"Ürün Adı"}</div>
                                                    <div style={{ width: '40%', textDecoration: 'underline', textAlign: 'left' }}>{"İptal Açıklaması"}</div>
                                                    <div style={{ width: '20%', textDecoration: 'underline', textAlign: 'right' }}>{"Fiyatı"}</div>
                                                </div>
                                                {this.returnClaimsItem(n.claimItems)}
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" style={{ padding: 5 }}>{n.totalPrice.toFixed(2)}</TableCell>
                                        <TableCell align="left" style={{ padding: 5 }}>{moment(new Date(n.createdDate).toString()).format('DD.MM.YYYY HH:mm')}</TableCell>
                                        <TableCell style={{ padding: 5 }}>
                                            <div style={{ display: 'flex' }}>
                                                <Tooltip title="Sipariş Detayı Git">
                                                    <IconButton name={n.javiOrderId} id={n.javiOrderId} onClick={this.handleClickPopover} style={{ marginRight: 5, backgroundColor: '#3e3e3e' }}>
                                                        <Icon>{"description"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Kabul Et">
                                                    <Button size="sm" onClick={() => (confirm('Siparişin iptalini kabul etmek istediğinize emin misiniz?') ? this.props.getTrendyolClaimsStatus(n.id, true, 0, "").then(() => { }) : null)} style={{ backgroundColor: 'rgba(22, 160, 133,1.0)', color: 'white', borderRadius: 8, padding: 5, textTransform: 'capitalize' }} variant="contained">{"Kabul Et"}</Button>
                                                </Tooltip>
                                                <Tooltip title="Reddet">
                                                    <Select
                                                        value={0}
                                                        onChange={(data) => (confirm('Siparişin iptalini reddetmek istediğinize emin misiniz?') ? this.props.getTrendyolClaimsStatus(n.id, false, data.target.value, data.currentTarget.getAttribute("data-name")).then(() => { }) : null)}
                                                        input={<Input id="age-helper" />}
                                                        style={{ marginLeft: 10, width: '100%', backgroundColor: 'rgba(192, 57, 43,1.0)', height: 48, marginBottom: 2 }}
                                                    >
                                                        <MenuItem data-name={"Reddetme Seçeneği Seçiniz"} value={0}><em>{"Reddetme Seçeneği Seçiniz"}</em></MenuItem>
                                                        <MenuItem data-name={"Gönderdiğim ürün yanlış değil"} value={5000}><em>{"Gönderdiğim ürün yanlış değil"}</em></MenuItem>
                                                        <MenuItem data-name={"Gönderdiğim ürün kusurlu değil"} value={5001}><em>{"Gönderdiğim ürün kusurlu değil"}</em></MenuItem>
                                                        <MenuItem data-name={"Müşteriyle görüşüldü-sorun çözüldü / Müşteriye ürünü yeniden göndereceğim"} value={5002}><em>{"Müşteriyle görüşüldü-sorun çözüldü / Müşteriye ürünü yeniden göndereceğim"}</em></MenuItem>
                                                        <MenuItem data-name={"Gönderdiğim ürünlerde eksik yok"} value={5003}><em>{"Gönderdiğim ürünlerde eksik yok"}</em></MenuItem>
                                                        <MenuItem data-name={"Satıcı kaynaklı gecikme yok"} value={5004}><em>{"Satıcı kaynaklı gecikme yok"}</em></MenuItem>
                                                        <MenuItem data-name={"Ürünler müşteriye teslim edildi"} value={5005}><em>{"Ürünler müşteriye teslim edildi"}</em></MenuItem>
                                                        <MenuItem data-name={"İletilen görseller veya bilgiler yanlış / yetersiz"} value={5006}><em>{"İletilen görseller veya bilgiler yanlış / yetersiz"}</em></MenuItem>
                                                        <MenuItem data-name={"Fiş gönderilmişti, yeni fiş kesemiyorum."} value={5007}><em>{"Fiş gönderilmişti, yeni fiş kesemiyorum."}</em></MenuItem>
                                                        <MenuItem data-name={"Fiş müşteriye yeniden gönderildi, iade yapılmayacak"} value={5016}><em>{"Fiş müşteriye yeniden gönderildi, iade yapılmayacak"}</em></MenuItem>
                                                        <MenuItem data-name={"Ürün notu eklenmemişti / eklenen not uygulanabilir değildi"} value={5017}><em>{"Ürün notu eklenmemişti / eklenen not uygulanabilir değildi"}</em></MenuItem>
                                                    </Select>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setTrendyolClaimsVisible(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

TrendyolClaims.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('settingsReducer').trendyolClaimsUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    trendyolClaimsUpdate: value(state, props),
    trendyolClaimsLoading: state.get('settingsReducer').trendyolClaimsLoading,
    trendyolClaimsData: state.get('settingsReducer').trendyolClaimsData,
    trendyolClaimsVisible: state.get('settingsReducer').trendyolClaimsVisible
});

const mapDispatchToProps = {
    setTrendyolClaimsVisible,
    getTrendyolClaimsStatus,
    getPackageAdditionDetail
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(TrendyolClaims)));
