import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import classNames from 'classnames';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Ionicon from 'react-ionicons';
import styles from './papperStyle-jss';

class DetailBlock extends React.Component {
  render() {
    const {
      classes,
      children,
      whiteBg,
      noMargin,
      colorMode,
      overflowX,
      icon,
      color
    } = this.props;
    return (
      <div style={{ height:'100%' }}>
        <Paper style={{ backgroundColor:color, elevation:1, height:'100%', borderRadius:0, padding:0}} className={classNames(classes.root, noMargin && classes.noMargin, colorMode && classes.colorMode)} elevation={0}>
          <section className={classNames(classes.content, whiteBg && classes.whiteBg, overflowX && classes.overflowX)}>
            {children}
          </section>
        </Paper>
      </div>
    );
  }
}

DetailBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  whiteBg: PropTypes.bool,
  colorMode: PropTypes.bool,
  noMargin: PropTypes.bool,
  overflowX: PropTypes.bool
};

DetailBlock.defaultProps = {
  whiteBg: false,
  noMargin: false,
  colorMode: false,
  overflowX: false,
  icon: 'ios-bookmark-outline'
};

export default withStyles(styles)(DetailBlock);
