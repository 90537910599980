import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import cookie from 'react-cookies';

import TimerIcon from '@material-ui/icons/Timer';
import Close from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

import GetirYemekLogo from 'dan-images/GetirYemekLogo.png';
import TrendyolYemekLogo from 'dan-images/trendyol.png';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Ionicon from 'react-ionicons';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import * as workerTimers from 'worker-timers';
import Switch from '@material-ui/core/Switch';
import { purple } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  getLicenceModule, getParameter, getLicence, getLicenceModulePackage, soundPlay, notificationShow
} from '../../../function/GeneralFunction';
import styles from './SlidingMenu-jss';
import {
  getGetirYemekRestaurantStatus,
  getGoFodyRestaurantStatus,
  getGetirYemekCourierEnable,
  setYemekSepetiGlobalRestStatus,
  setRestaurantStatusTrendyolYemek,
  setMigrosYemekStatus,
  setTrendyolClaimsVisible
} from '../../../redux/modules/settings';
import ModalInformation from '../../Pages/Ortak/ModalInformation';
import { getUser } from '../../../redux/modules/user';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#c0392b',
    '&$checked': {
      color: '#16a085',
    },
    '&$checked + $track': {
      backgroundColor: '#16a085',
    },
  },
  checked: {},
  track: {
    backgroundColor: '#c0392b',
  },
})(Switch);

const actions = [
  { icon: <Icon>file_copy</Icon>, name: 'Copy' },
  { icon: <Icon>save</Icon>, name: 'Save' },
  { icon: <Icon>print</Icon>, name: 'Print' }
];

class Entegration extends React.Component {
  constructor() {
    super();
  }

  async componentDidMount() {
  }

  async clickTYRestStatus(restaurantid, status, i) {
    await this.props.setRestaurantStatusTrendyolYemek(restaurantid, status, i).then(() => { });
  }

  renderMigrosCount(data) {
    let count = 0;

    if (data != null) {
      data.map((n, i) => {
        n.mYemekRestaurants.map((v, y) => {
          count += v.mYemekRestaurants;
        });
      });
    }

    return count;
  }

  renderMigrosYemekStore(data) {
    const {
      classes
    } = this.props;

    return data.mYemekRestaurants.map((n, i) => (
      <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
        <PurpleSwitch
          onChange={() => this.props.setMigrosYemekStatus(data.ApiKey, n.storeId, n.warehouseId, (!n.active), n.name).then(() => { })}
          checked={n.active}
        />
        <div style={{
          width: '100%', fontWeight: '600', fontSize: 12, display: 'flex', alignItems: 'center'
        }}
        >
          <span style={{
            color: '#313131', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, webkitBoxOrient: 'vertical'
          }}
          >
            {n.name}
          </span>
        </div>
      </div>
    ));
  }

  _handleEntegrationOpenClosed = (name, status) => event => {
    const {
      GetirYemekRestaurantList, MigrosYemekRestaurantList, TrendyolYemekRestaurantList, YemekSepetiRestaurantListGlobal
    } = this.props.settingsReducer;
    if (name == 'GetirYemek') {
      GetirYemekRestaurantList.map((n, i) => {
        this.props.getGetirYemekRestaurantStatus(status, i, n.name);
      });
    } else if (name == 'TrendyolYemek') {
      TrendyolYemekRestaurantList.map((n, i) => {
        this.clickTYRestStatus(n.id, (status == true ? 'OPEN' : 'CLOSED'), i);
      });
    } else if (name == 'MigrosYemek') {
      MigrosYemekRestaurantList.map((n, i) => {
        n.mYemekRestaurants.map((x, y) => {
          this.props.setMigrosYemekStatus(n.ApiKey, x.storeId, x.warehouseId, status, x.name).then(() => { });
        });
      });
    } else if (name == 'YemekSepeti') {
      YemekSepetiRestaurantListGlobal.map((n, i) => {
        this.props.setYemekSepetiGlobalRestStatus(n.chainCode, n.posVendorId, (status == true ? 'OPEN' : 'CLOSED'), n.platformRestaurantId, n.platformRestaurantName);
      });
    }
  };

  render() {
    const { type, classes, entegrationStatusLoading } = this.props;

    return (
      <div style={{
        display: 'block', overflow: 'auto', height: '100%', padding: '0px 3px 0px 3px',
      }}
      >
        <div style={{
          display: (entegrationStatusLoading ? 'flex' : 'none'), justifyContent: 'center', alignItems: 'center', zIndex: 999, backgroundColor: 'rgba(0, 0, 0, 0.3)', position: 'absolute', left: 0, right: 0, bottom: 0, top: 0
        }}
        >
          <div style={{ display: 'grid' }}>
            <CircularProgress size={50} style={{ color: 'rgb(243, 156, 18)', backgroundColor: 'transparent', margin: 'auto' }} thickness={4} className={classes.progress} />
            <span style={{ color: 'white', fontSize: 20 }}>Lütfen Bekleyiniz!</span>
          </div>
        </div>
        <div style={{
          position: 'relative', display: (type == 'paketyolda' ? '' : (getLicenceModule('6e98ccb9-08b3-47ce-840e-2745ad3bf706') ? (getParameter('WebApiUserId') == cookie.load('UserId') ? 'block' : 'none') : 'none')), marginTop: 3, backgroundColor: '#f5f6fa'
        }}
        >
          <div style={{ display:'grid', backgroundColor: '#e67e22', padding: 5, color: 'white', fontSize: 12, flexDirection:'column' }}>
            <span style={{ fontWeight: 700, marginBottom: 5 }}>{"Önemli Bilgilendirme"}</span>
            <span style={{ marginBottom: 8 }}><span style={{ fontWeight: 700 }}>{"Genel : "}</span>{"Ani elektirik kesintisinde veya bilgisayar kapanması gibi durumlarda uzun süre sisteme giriş yapamayacaksanız. Online sipariş yerlerinin kendi panellerinden sayfalarınızı lütfen kapalı konuma alınız!"}</span>
            <span style={{ marginBottom: 8 }}><span style={{ fontWeight: 700 }}>{"GetirYemek : "}</span>{"GetirYemek servislerinden 30sn bir 2 kere restoran listesi çekilebilmektedir. Listede restoranlarızı görmemeniz durumunda en az 30sn bekleyip tekrar sayfanızı yenilemeniz gerekmektedir."}</span>
          </div>
          <div style={{
            padding: 5, backgroundColor: 'white', color: 'rgb(76, 51, 152)', height: 40, width: '100%', verticalAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 16, fontWeight: 700, borderBottom: '2px solid rgb(76, 51, 152)'
          }}
          >
            <span>{"GetirYemek"}</span>
            <div style={{ display: (this.props.settingsReducer.GetirYemekRestaurantList != null ? (this.props.settingsReducer.GetirYemekRestaurantList.length > 1 ? '' : 'none') : 'none') }}>
              <Tooltip title="GetirYemek restoranlarının tümünü aç">
                <IconButton size="small" style={{ backgroundColor: '#1abc9c', padding: 5, marginRight: 5 }} onClick={this._handleEntegrationOpenClosed('GetirYemek', true)}>
                  <Icon style={{ fontSize: 22 }}>{"lock_open"}</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="GetirYemek restoranlarının tümünü kapat">
                <IconButton size="small" style={{ backgroundColor: '#e74c3c', padding: 5 }} onClick={this._handleEntegrationOpenClosed('GetirYemek', false)}>
                  <Icon style={{ fontSize: 22 }}>{"lock"}</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div style={{ padding: 4, display: 'block', alignItems: 'center' }}>
            {
              (this.props.settingsReducer.GetirYemekRestaurantList != null
                ? this.props.settingsReducer.GetirYemekRestaurantList.map((n, i) => (
                  <div style={{ height: 26, display: 'flex', alignItems: 'center' }}>
                    <PurpleSwitch
                      onChange={() => this.props.getGetirYemekRestaurantStatus((n.status != 100), i, n.name)}
                      checked={(n.status == 100)}
                    />
                    <div style={{
                      width: '100%', fontWeight: '600', fontSize: 12, display: 'flex', alignItems: 'center'
                    }}
                    >
                      <span style={{
                        paddingLeft: 5, color: '#313131', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, webkitBoxOrient: 'vertical'
                      }}
                      >
                        {n.name}
                      </span>
                    </div>
                    <div
                      onClick={() => this.props.getGetirYemekCourierEnable(i, !n.isCourierAvailable)}
                      style={{
                        cursor: 'grab', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: (n.isCourierAvailable ? '#10ac84' : '#b33939'), width: 240, height: 25
                      }}
                    >
                      <span style={{
                        fontWeight: 600, color: 'white', fontSize: 11, display: 'flex', justifyContent: 'center', alignItems: 'center'
                      }}
                      >
                        <Icon style={{ fontSize: 17, marginRight: 5 }}>{"sports_motorsports"}</Icon>
                        {(n.isCourierAvailable ? 'Restoran Kuryesi Açık' : 'Restoran Kuryesi Kapalı')}
                      </span>
                    </div>
                  </div>
                ))
                : null)
            }
          </div>
        </div>
        <div style={{
          position: 'relative', display: (type == 'paketyolda' ? '' : (getLicenceModule('c4297a5e-3e5b-4255-8022-14d4163e66c9') ? (getParameter('WebApiUserId') == cookie.load('UserId') ? 'block' : 'none') : 'none')), marginTop: 3, backgroundColor: '#f5f6fa'
        }}
        >
          <div style={{
            padding: 5, backgroundColor: 'white', color: '#27ae60', height: 40, width: '100%', verticalAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 16, fontWeight: 700, borderBottom: '2px solid #27ae60'
          }}
          >
            <span>MigrosYemek</span>
            <div style={{ display: (this.renderMigrosCount(this.props.settingsReducer.MigrosYemekRestaurantList) > 1 ? '' : 'none') }}>
              <Tooltip title="MigrosYemek restoranlarının tümünü aç">
                <IconButton size="small" style={{ backgroundColor: '#1abc9c', padding: 5, marginRight: 5 }} onClick={this._handleEntegrationOpenClosed('MigrosYemek', true)}>
                  <Icon style={{ fontSize: 22 }}>lock_open</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="MigrosYemek restoranlarının tümünü kapat">
                <IconButton size="small" style={{ backgroundColor: '#e74c3c', padding: 5 }} onClick={this._handleEntegrationOpenClosed('MigrosYemek', false)}>
                  <Icon style={{ fontSize: 22 }}>lock</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div style={{ padding: 4, display: 'block', alignItems: 'center' }}>
            {
              (this.props.settingsReducer.MigrosYemekRestaurantList != null
                ? (
                  this.props.settingsReducer.MigrosYemekRestaurantList.map((n, i) => this.renderMigrosYemekStore(n))
                )
                : null)
            }
          </div>
        </div>
        <div style={{
          position: 'relative', display: (type == 'paketyolda' ? '' : (getLicenceModule('9b5db60d-51a8-480d-8ea7-acc1ce3f88c0') ? (getParameter('WebApiUserId') == cookie.load('UserId') ? 'block' : 'none') : 'none')), marginTop: 3, backgroundColor: '#f5f6fa'
        }}
        >
          <div style={{
            padding: 5, backgroundColor: 'white', color: '#f27a1a', height: 40, width: '100%', verticalAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 16, fontWeight: 700, borderBottom: '2px solid #f27a1a'
          }}
          >
            <Tooltip title="İadeleri kontrol et">
              <Button
                size="sm"
                onClick={() => this.props.setTrendyolClaimsVisible(true).then(() => { })}
                style={{
                  backgroundColor: '#c0392b', color: 'white', borderRadius: 8, padding: 5, textTransform: 'capitalize'
                }}
                variant="contained"
              >
                İadeler
              </Button>
            </Tooltip>
            <span>TrendyolYemek</span>
            <div style={{ display: (this.props.settingsReducer.TrendyolYemekRestaurantList != null ? (this.props.settingsReducer.TrendyolYemekRestaurantList.length > 1 ? '' : 'none') : 'none') }}>
              <Tooltip title="TrendyolYemek restoranlarının tümünü aç">
                <IconButton size="small" style={{ backgroundColor: '#1abc9c', padding: 5, marginRight: 5 }} onClick={this._handleEntegrationOpenClosed('TrendyolYemek', true)}>
                  <Icon style={{ fontSize: 22 }}>lock_open</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="TrendyolYemek restoranlarının tümünü kapat">
                <IconButton size="small" style={{ backgroundColor: '#e74c3c', padding: 5 }} onClick={this._handleEntegrationOpenClosed('TrendyolYemek', false)}>
                  <Icon style={{ fontSize: 22 }}>lock</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div style={{ padding: 4, display: 'block', alignItems: 'center' }}>
            {
              (this.props.settingsReducer.TrendyolYemekRestaurantList != null
                ? (
                  this.props.settingsReducer.TrendyolYemekRestaurantList.map((n, i) => (
                    <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                      <PurpleSwitch
                        onChange={() => this.clickTYRestStatus(n.id, (n.workingStatus == 'CLOSED' ? 'OPEN' : 'CLOSED'), i)}
                        checked={(n.workingStatus != 'CLOSED')}
                      />
                      <div style={{
                        width: '100%', fontWeight: '600', fontSize: 12, display: 'flex', alignItems: 'center'
                      }}
                      >
                        <span style={{
                          color: '#313131', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, webkitBoxOrient: 'vertical'
                        }}
                        >
                          {n.name}
                        </span>
                      </div>
                    </div>
                  ))
                )
                : null)
            }
          </div>
        </div>
        <div style={{
          position: 'relative', display: (type == 'paketyolda' ? '' : (getLicenceModule('90f4970d-4698-4080-ba55-5ac2d174fcdb') ? (getParameter('WebApiUserId') == cookie.load('UserId') ? 'block' : 'none') : 'none')), marginTop: 3, backgroundColor: '#f5f6fa'
        }}
        >
          <div style={{
            padding: 5, backgroundColor: 'white', color: '#fa0050', height: 40, width: '100%', verticalAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 16, fontWeight: 700, borderBottom: '2px solid #fa0050'
          }}
          >
            <span>YemekSepeti (Delivery Hero)</span>
            <div style={{ display: (this.props.settingsReducer.YemekSepetiRestaurantListGlobal.length > 1 ? '' : 'none') }}>
              <Tooltip title="YemekSepeti restoranlarının tümünü aç">
                <IconButton size="small" style={{ backgroundColor: '#1abc9c', padding: 5, marginRight: 5 }} onClick={this._handleEntegrationOpenClosed('YemekSepeti', true)}>
                  <Icon style={{ fontSize: 22 }}>lock_open</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="YemekSepeti restoranlarının tümünü kapat">
                <IconButton size="small" style={{ backgroundColor: '#e74c3c', padding: 5 }} onClick={this._handleEntegrationOpenClosed('YemekSepeti', false)}>
                  <Icon style={{ fontSize: 22 }}>lock</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div style={{ padding: 4, display: 'block', alignItems: 'center' }}>
            {
              (
                this.props.settingsReducer.YemekSepetiRestaurantListGlobal.map((n, i) => (
                  <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                    {
                      (
                        n.availabilityStates == null
                          ? (
                            <Tooltip title={(n.changeable ? '' : 'Restoran durum değişikliğine şu an için izin verilmiyor. Lütfen YemekSepeti ile irtibata geçiniz!')}>
                              <div style={{
                                padding: 5, backgroundColor: '#e74c3c', borderRadius: 5, fontSize: 12, fontWeight: 700, textAlign: 'center', color: 'white'
                              }}
                              >
                                İşlem Yapılamaz
                              </div>
                            </Tooltip>
                          )
                          : (
                            <Tooltip title={(n.changeable ? '' : 'Restoran durum değişikliğine şu an için izin verilmiyor. Lütfen YemekSepeti ile irtibata geçiniz!')}>
                              <PurpleSwitch
                                onChange={() => this.props.setYemekSepetiGlobalRestStatus(n.chainCode, n.posVendorId, (n.availabilityState == 'OPEN' ? 'CLOSED' : 'OPEN'), n.platformRestaurantId, n.platformRestaurantName)}
                                checked={(n.availabilityState == 'OPEN')}
                              />
                            </Tooltip>
                          ))
                    }
                    <div style={{
                      width: '100%', fontWeight: '600', fontSize: 12, display: 'flex', alignItems: 'center'
                    }}
                    >
                      <span style={{
                        color: '#313131', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, webkitBoxOrient: 'vertical'
                      }}
                      >
                        {n.platformRestaurantName}
                      </span>
                    </div>
                  </div>
                ))
              )
            }
          </div>
        </div>
        <div style={{
          position: 'relative', display: (type == 'paketyolda' ? '' : (getLicenceModule('bd2df397-1ec8-4298-bfd3-d15f516ce4cf') ? (getParameter('WebApiUserId') == cookie.load('UserId') ? 'block' : 'none') : 'none')), marginTop: 3, backgroundColor: '#f5f6fa'
        }}
        >
          <div style={{
            backgroundColor: 'white', color: '#f1b62a', height: 30, width: '100%', verticalAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16, fontWeight: 700, borderBottom: '2px solid #f1b62a'
          }}
          >
            GoFody
          </div>
          <div style={{ padding: 4, display: 'block', alignItems: 'center' }}>
            {
              (this.props.settingsReducer.GoFodyRestaurantList != null
                ? (
                  this.props.settingsReducer.GoFodyRestaurantList.map((n, i) => (
                    <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                      <PurpleSwitch
                        onChange={() => this.props.getGoFodyRestaurantStatus((n.state_working != 'active'), i, n.name, n.id)}
                        checked={(n.state_working == 'active')}
                      />
                      <div style={{
                        width: '100%', fontWeight: '600', fontSize: 12, display: 'flex', alignItems: 'center'
                      }}
                      >
                        <span style={{
                          color: '#313131', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, webkitBoxOrient: 'vertical'
                        }}
                        >
                          {n.name}
                        </span>
                      </div>
                    </div>
                  ))
                )
                : null)
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  settingsReducer: state.get('settingsReducer').form_data,
  additionReducer: state.get('additionReducer').form_data,
  userReducer: state.get('userReducer').form_data,
  packageFilter: state.get('additionReducer').packageFilter,
  entegrationStatusLoading: state.get('settingsReducer').entegrationStatusLoading
});

const mapDispatchToProps = {
  getGetirYemekRestaurantStatus,
  getGoFodyRestaurantStatus,
  getGetirYemekCourierEnable,
  setYemekSepetiGlobalRestStatus,
  setRestaurantStatusTrendyolYemek,
  setMigrosYemekStatus,
  setTrendyolClaimsVisible
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Entegration)));
