import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import Comment from '@material-ui/icons/Comment';
import styles from './cardStyle-jss';

class GeneralCard extends React.Component {
  render() {
    const {
      classes,
      children
    } = this.props;
    return (
      <Card className={classes.card}>
        <CardContent style={{padding:10}}>
          {children}
        </CardContent>
      </Card>
    );
  }
}

GeneralCard.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(GeneralCard);
