import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../../SettingPage/datepicker.css';
import tr from 'date-fns/locale/tr';
import 'leaflet/dist/leaflet.css';
import cookie from 'react-cookies';
import Select from '@material-ui/core/Select';
import MapClusterReport from '../../SettingPage/ReportPage/Maps/MapClusterReport';
import {
    getOrderHeat, setFranchiseReportOrderHeatFilter, setFranchiseReportBrandSelected, getEntegrationCacheList
} from '../../../../redux/modules/centerbrand';
import { getParameter } from '../../../../function/GeneralFunction';
registerLocale('tr', tr);
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
});

class OrderHeat extends React.Component {
    constructor() {
        super();
        this.state = { Lat: 0, Lng: 0 };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isOrderHeatUpdate == this.props.isOrderHeatUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    handleChangeFilterOrderHeat = name => event => {
        const { filterOrderHeat } = this.props;
        filterOrderHeat[name] = (name == "StartDatetime" || name == "EndDatetime" ? moment.utc(event, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm") : event.target.value);
        this.props.setFranchiseReportOrderHeatFilter(filterOrderHeat).then(() => { });
    };

    handleChange = async (event) => {
        var brand = event.currentTarget;
        this.setState({ Lat: brand.getAttribute('lat'), Lng: brand.getAttribute('lng') });
        await this.props.setFranchiseReportBrandSelected(event.target.value).then(() => { });
        this.props.getEntegrationCacheList(event.target.value).then(() => { });
    };

    render() {
        const {
            franchiseReportName, franchiseReportOrderHeatData, filterOrderHeat, isCenterReportLoading, classes,
            franchiseReportBrandSelected, centerBrandData, franchiseBrandEntegrationList
        } = this.props;
        const {
            Lat, Lng
        } = this.state;

        if (franchiseReportName != 'OrderHeatReport') {
            return null;
        }
        
        var entList = [];
        if (franchiseBrandEntegrationList != null) {
            franchiseBrandEntegrationList.GetirYemek.map((n, i) => {
                entList.push({ "Id": n.id, "Name": "GetirYemek - " + n.name });
            })
            franchiseBrandEntegrationList.MigrosYemek.map((n, i) => {
                n.mYemekRestaurants.map((s, d) => {
                    entList.push({ "Id": s.storeId, "Name": "MigrosYemek - " + s.name });
                })
            })
            franchiseBrandEntegrationList.TrendyolYemek.map((n, i) => {
                entList.push({ "Id": n.id, "Name": "TrendyolYemek - " + n.name });
            })
            franchiseBrandEntegrationList.YemekSepeti.map((n, i) => {
                entList.push({ "Id": n.Id, "Name": "YemekSepeti - " + n.platformRestaurantName });
            })
        }

        return (
            <Paper className={classes.root} elevation={0} style={{ height: '97%', borderRadius: 0 }}>
                <div style={{
                    backgroundColor: '#424242', padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}
                >
                    {"Sipariş Isı Haritası"}
                </div>
                <Grid container spacing={1} style={{ padding: 10 }}>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Başlangıç Tarihi"}
                        </Typography>
                        <DatePicker
                            selected={new Date(filterOrderHeat.StartDatetime)}
                            onChange={this.handleChangeFilterOrderHeat('StartDatetime')}
                            dateFormat="dd.MM.yyyy HH:mm"
                            showTimeSelect
                            timeIntervals={15}
                            timeFormat="HH:mm"
                            locale="tr"
                            className="inputDate"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Bitiş Tarihi"}
                        </Typography>
                        <DatePicker
                            selected={new Date(filterOrderHeat.EndDatetime)}
                            onChange={this.handleChangeFilterOrderHeat('EndDatetime')}
                            dateFormat="dd.MM.yyyy HH:mm"
                            showTimeSelect
                            timeIntervals={15}
                            timeFormat="HH:mm"
                            locale="tr"
                            className="inputDate"
                        />
                    </Grid>
                    <Grid item md={2}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Şube Seçimi"}
                        </Typography>
                        <Select
                            fullWidth
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            style={{ height: 38 }}
                            value={franchiseReportBrandSelected}
                            onChange={this.handleChange}
                        >
                            {centerBrandData.map((n, i) => (
                                <MenuItem lat={n.Latitude} lng={n.Longitude} key={i} value={n.Id}>
                                    <ListItemText primary={n.Name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item md={2}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Entegrasyon Seçimi"}
                        </Typography>
                        <Select
                            fullWidth
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            style={{ height: 38 }}
                            value={filterOrderHeat.EntegrationId}
                            onChange={this.handleChangeFilterOrderHeat("EntegrationId")}
                        >
                            {entList.map((n, i) => (
                                <MenuItem key={i} value={n.Id}>
                                    <ListItemText primary={n.Name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item md={2}>
                        <Button fullWidth disabled={isCenterReportLoading} onClick={() => this.props.getOrderHeat().then(() => { })} style={{ marginTop: 18, borderRadius: 5, height: 37, padding: '6px 24px' }} size="large" variant="contained" color="secondary" type="submit">
                            {'FİLTRELE'}
                        </Button>
                    </Grid>
                </Grid>
                {(isCenterReportLoading ? <div style={{ width: '100%', textAlign: 'center', marginBottom: 15 }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
                <div style={{
                    display: 'flex', padding: 10, height: '100%', width: '100%'
                }}
                >
                    <MapClusterReport center={[parseFloat(Lat), parseFloat(Lng)]} markersData={franchiseReportOrderHeatData} />
                </div>
            </Paper>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').isOrderHeatUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    isOrderHeatUpdate: value(state, props),
    franchiseReportName: state.get('centerBrandReducer').franchiseReportName,
    isCenterReportLoading: state.get('centerBrandReducer').isCenterReportLoading,
    filterOrderHeat: state.get('centerBrandReducer').filterOrderHeat,
    franchiseReportOrderHeatData: state.get('centerBrandReducer').franchiseReportOrderHeatData,
    centerBrandData: state.get('centerBrandReducer').centerBrandData,
    franchiseReportBrandSelected: state.get('centerBrandReducer').franchiseReportBrandSelected,

    franchiseBrandEntegrationList: state.get('centerBrandReducer').franchiseBrandEntegrationList,
});

const mapDispatchToProps = {
    getOrderHeat,
    setFranchiseReportOrderHeatFilter,
    setFranchiseReportBrandSelected,
    getEntegrationCacheList
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(OrderHeat)));
