import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 10,
    height: 190,
    marginBottom: 6,
    width: '100% !important',
    borderRadius:5,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      height: 126,
      marginBottom: -1,
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& > *': {
      padding: '0 5px'
    }
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 24,
    fontWeight: 500
  }
});

class CounterWidget extends PureComponent {
  render() {
    const {
      classes,
      color,
      title
    } = this.props;
    return (
      <ButtonBase className={classes.root} style={{ backgroundColor: color }}>
          <Typography className={classes.title} variant="subtitle1">{title}</Typography>
      </ButtonBase>
    );
  }
}

CounterWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(CounterWidget);
