import axios from 'axios';
var base64 = require('base-64');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { reactLocalStorage } from 'reactjs-localstorage';
var utf8 = require('utf8');
import { push } from 'connected-react-router';

export const LOGIN_START = 'login_start';
export const LOGIN_STOP = 'login_stop';
export const LOGIN_ERROR = 'login_error';
export const LOGIN_MODALCLOSE = 'login_modalclose';
export const LOGIN_FORM_DATA_SET = 'login_form_data_set';

export const PIN_LOADING = 'pin_loading';

let initialState = {
  data: [],
  loading: false,
  error: '',
  modalOpen: false,
  modalTitle: '',
  modalText: '',
  PINLoading: false,
}

export async function setForgotPassword(eposta) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/User/setForgotPassword?eposta=' + eposta,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        dispatch({ type: LOGIN_ERROR, payload: response.data.responseData })
      })
      .catch(function (error) {

      });
  };
}

export async function login(login_data, IsAdminLogin = false) {
  return async (dispatch, getState) => {
    dispatch({ type: LOGIN_START });
    login_data["Language"] = cookie.load('Lang') || 'tr';
    
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/User/UserLogin',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
      data: login_data,
    })
      .then(function (response) {
        dispatch({ type: LOGIN_STOP, payload: response.data });
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          reactLocalStorage.remove('l_string');
          if (!login_data.IsPIN) {
            reactLocalStorage.remove('remember');
          }
          reactLocalStorage.remove('p_string');
          reactLocalStorage.remove('j_string');
          reactLocalStorage.remove('c_string');
          cookie.save('UserName', json.UserName, { path: '/' });
          cookie.save('RestaurantName', json.RestaurantName, { path: '/' });
          cookie.save('UserId', json.UserId, { path: '/' });
          cookie.save('BrandId', json.BrandId, { path: '/' });
          cookie.save('TenantId', json.TenantId, { path: '/' });
          cookie.save('CenterBrandId', json.CenterBrandId, { path: '/' });
          cookie.save('PaketYoldaId', json.PaketYoldaId, { path: '/' });
          cookie.save('UserType', json.UserType, { path: '/' });
          cookie.save('LicenceNumber', json.LicenceNumber, { path: '/' });
          cookie.save('NameSurname', json.NameSurname, { path: '/' });
          cookie.save('Authorization', json.Authorization, { path: '/' });
          cookie.save('IsRemember', login_data.IsRemember, { path: '/' });
          cookie.save('Email', login_data.Email, { path: '/' });
          cookie.save('Password', login_data.Password, { path: '/' });
          cookie.save('IsIngenico', json.IsIngenico, { path: '/' });
          cookie.save('IsApp', json.IsApp, { path: '/' });

          cookie.save('KitchenUserId', "", { path: '/' });
          cookie.save('KitchenUserName', "", { path: '/' });

          cookie.save('Lat', json.Lat, { path: '/' });
          cookie.save('Lng', json.Lng, { path: '/' });
          reactLocalStorage.setObject('c_string', json.CampaignList);
          reactLocalStorage.setObject('p_string', json.SettingsData);
          reactLocalStorage.setObject('l_string', json.SubscriptionInfo);
          reactLocalStorage.setObject('j_string', json.IsLite);

          var selfsales = JSON.parse(base64.decode(json.SettingsData)).SelfSales;
          if (!IsAdminLogin) {
            if (selfsales == true && (json.UserType == "Manager" || json.UserType == "Cashier")) {
              if (login_data.IsPIN) {
                dispatch(push('/app/detailpage?selfsales'));
              } else {
                window.location.href = (json.UserType == "Cashier" ? '/app' : '/mainpage');
              }
            } else {
              if (login_data.IsPIN) {
                dispatch(push(json.Url));
              } else {
                if(json.Url == "/app"){
                  window.location.href = (json.UserType == "Cashier" ? json.Url : '/mainpage');
                }else{
                  window.location.href = json.Url;
                }
              }
            }
          } else {
            window.open(json.Url, '_blank');
          }
        } else {
          dispatch({ type: LOGIN_ERROR, payload: response.data.responseData })
        }
      })
      .catch(function (error) {
        dispatch({ type: LOGIN_ERROR, payload: error })
      });

  }
}

export async function modalclose() {
  return async (dispatch, getState) => {
    dispatch({ type: LOGIN_MODALCLOSE });
  }
}

export async function setPINLoading(open) {
  return async (dispatch, getState) => {
    dispatch({ type: PIN_LOADING, payload: open });
  }
}

export async function formDataSet(key, val) {
  return async (dispatch, getState) => {
    dispatch({
      type: LOGIN_FORM_DATA_SET,
      payload: { key, val }
    });
  };
}


const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START:
      return { ...state, loading: true }
    case PIN_LOADING:
      return { ...state, PINLoading: action.payload }
    case LOGIN_ERROR:
      return { ...state, loading: false, modalOpen: true, modalTitle: 'Uyarı', modalText: action.payload }
    case LOGIN_STOP:
      return { ...state, loading: false, data: action.payload }
    case LOGIN_MODALCLOSE:
      return { ...state, modalOpen: false }
    case LOGIN_FORM_DATA_SET:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          [action.payload.key]: action.payload.val
        }
      }
    default:
      return state;
  }
}

export default loginReducer;
