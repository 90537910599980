import React from 'react';
import {
    createSelector
} from 'reselect';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { RequestCurrentAccountReport } from '../../../../redux/modules/report';
import {
    getCustomerAccountDetail
} from '../../../../redux/modules/customer';
import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DatePicker, { registerLocale } from "react-datepicker";
import "../datepicker.css";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { EmptyData } from 'dan-components';
import TbPaginationActions from '../../Ortak/TbPaginationActions';
import AlertDialog from '../../Ortak/AlertDialog';
import { getParameter } from '../../../../function/GeneralFunction';
import CustomerAccount from '../Customer/CustomerAccount';
import CustomerAdd from '../Customer/CustomerAdd';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class CurrentAccountRep extends React.Component {
    constructor(props) {
        super(props);
        this.state = { searchValue: "", startDate: new Date(new Date().setHours(0, 0, 0, 0)), endDate: new Date(new Date().setHours(23, 59, 59, 999)) }
    }

    async componentDidMount() {
        var TimeData = getParameter("BusinessWorkingHours");
        await this.setState({ startDate: TimeData.start, endDate: TimeData.end })
    }

    shouldComponentUpdate(nextProps, nextState) {
        //if (nextProps.currentAccountUpdate == this.props.currentAccountUpdate) {
        //return false;
        //}
        return true;
    }

    handleChangePage = (event, page) => {
        const { filterCurrentAccount } = this.props;
        filterCurrentAccount.searchText = this.state.searchValue;
        filterCurrentAccount.currentPage = page + 1;
        this.props.RequestCurrentAccountReport(filterCurrentAccount).then(() => { })
    };

    handleChangeRowsPerPage = event => {
        const { filterCurrentAccount } = this.props;
        filterCurrentAccount.searchText = this.state.searchValue;
        filterCurrentAccount.rowsPerPage = event.target.value;
        this.props.RequestCurrentAccountReport(filterCurrentAccount).then(() => { })
    };

    handleSearch = () => {
        const { filterCurrentAccount } = this.props;
        filterCurrentAccount.searchText = this.state.searchValue;
        this.props.RequestCurrentAccountReport(filterCurrentAccount).then(() => { })
    };

    handleSort(sortColumnName) {
        const { filterCurrentAccount } = this.props;
        if (filterCurrentAccount.sortColumnName == sortColumnName) {
            if (filterCurrentAccount.sortOrder == "asc") {
                filterCurrentAccount.sortOrder = "desc";
            } else if (filterCurrentAccount.sortOrder == "desc") {
                filterCurrentAccount.sortOrder = "asc";
            }
        } else {
            filterCurrentAccount.sortOrder = "asc";
        }
        filterCurrentAccount.searchText = this.state.searchValue;
        filterCurrentAccount.sortColumnName = sortColumnName;
        this.props.RequestCurrentAccountReport(filterCurrentAccount).then(() => { })
    };

    async clickGetAddition(Id) {
        await this.props.getAddition(Id).then(() => { })
        this.setState({ modalAdditionDetail: true })
    }

    clickPrintedPackage(Id, BrandId, type) {
        const { printerHubConnection } = this.props;
        this.props.sendMessagePackagePrinter(printerHubConnection, type, Id, BrandId).then(() => {

        })
    }

    handleChangeBalanceStatus = (event) => {
        const { filterCurrentAccount } = this.props;
        filterCurrentAccount.BalanceStatus = event.target.value;
        this.props.RequestCurrentAccountReport(filterCurrentAccount).then(() => { })
    };

    render() {
        const { classes, loading } = this.props;

        return (
            <Paper className={classes.root} elevation={0} style={{ borderRadius: 0, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 10 }}>
                <CustomerAccount classes={classes} />
                <CustomerAdd classes={classes} />
                <Toolbar style={{ marginBottom: 5, minHeight: 48, width: '100%', display: 'flex' }}>
                    <Grid style={{ alignItems: 'center' }} container spacing={1}>
                        <Grid item md={2}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="age-helper">{"Bakiye Durumu"}</InputLabel>
                                <Select
                                    value={this.props.filterCurrentAccount.BalanceStatus}
                                    onChange={this.handleChangeBalanceStatus}
                                    input={<Input name="Category" id="age-helper" />}
                                >
                                    <MenuItem value="-1">
                                        <em>{"Seçiniz"}</em>
                                    </MenuItem>
                                    <MenuItem value={1}>{"Borcu Olanlar"}</MenuItem>
                                    <MenuItem value={2}>{"Borçlu Olduklarımız"}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}></Grid>
                        <Grid item md={2}>
                            <FormControl style={{ width: '100%', marginLeft: 8 }} className={classNames(classes.textField)}>
                                <Input
                                    id="search_filter"
                                    type="text"
                                    placeholder={"Arama"}
                                    value={this.state.searchValue}
                                    onChange={(event) => this.setState({ searchValue: event.target.value })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1}>
                            <Button disabled={loading} fullWidth onClick={this.handleSearch} style={{ borderRadius: 3, marginLeft: 5 }} variant="contained" color="primary">
                                {"Ara"}
                                {(this.props.currentAccountLoading ? <CircularProgress style={{ color: 'white', marginLeft: 10 }} disableShrink thickness={3} size={15} /> : null)}
                            </Button>
                        </Grid>
                        <Grid item md={1}>
                            <Button disabled={loading} fullWidth onClick={this.handleSearch} style={{ borderRadius: 3 }} variant="contained" color="primary">
                                {"LİSTELE"}
                                {(this.props.currentAccountLoading ? <CircularProgress style={{ color: 'white', marginLeft: 10 }} disableShrink thickness={3} size={15} /> : null)}
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>

                <Table style={{ marginTop: 5 }}>
                    <TableHead style={{ backgroundColor: '#212121' }}>
                        <TableRow>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Adı Soyadı"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Toplam Tutar"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Ödenen Tutar"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Kalan Bakiye"}</TableCell>
                            <TableCell style={{ color: 'white', fontSize: 13 }} align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.filterCurrentAccount.List.map((n, i) => {
                                return (
                                    <TableRow hover key={i}>
                                        <TableCell align="left">{n.NameSurname}</TableCell>
                                        <TableCell align="left">{n.TotalAmount.toFixed(2)}</TableCell>
                                        <TableCell align="left">{n.AmountPaid.toFixed(2)}</TableCell>
                                        <TableCell align="left">{n.AvailableBalance.toFixed(2)}</TableCell>
                                        <TableCell align="left">
                                            <Tooltip title="Müşteri Borç/Alacak">
                                                <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5 }} onClick={() => this.props.getCustomerAccountDetail(n.Id).then(() => { })}>
                                                    <Icon>{"account_balance_wallet"}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {
                    (this.props.filterCurrentAccount.List.length == 0 ? <EmptyData text={"Açık Hesap Kaydı Bulunamadı"} /> : null)
                }
                {(this.props.currentAccountLoading ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
                <div style={{ width: '100%', display: 'table' }}>
                    <TablePagination
                        labelRowsPerPage={"Sayfa başına satır sayısı"}
                        rowsPerPageOptions={[25, 50, 100]}
                        rowsPerPage={this.props.filterCurrentAccount.rowsPerPage}
                        count={this.props.filterCurrentAccount.totalRecord}
                        page={this.props.filterCurrentAccount.currentPage - 1}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TbPaginationActions}
                    />
                </div>
            </Paper>
        )
    }
}

const valueSelector = (state, props) => state.get('reportReducer').currentAccountUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    currentAccountUpdate: value(state, props),
    filterCurrentAccount: state.get('reportReducer').filterCurrentAccount,
    currentAccountLoading: state.get('reportReducer').currentAccountLoading,

    customerAccountVisible: state.get('customerReducer').customerAccountVisible,
    customerAccountUpdate: state.get('customerReducer').customerAccountUpdate,
})

const mapDispatchToProps = {
    RequestCurrentAccountReport,
    getCustomerAccountDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CurrentAccountRep));
