import axios from 'axios';
var base64 = require('base-64');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { getParameter } from '../../function/GeneralFunction';
import moment from 'moment';

export const LOGLIST_VISIBLE = 'loglist_visible';
export const LOGLIST_UPDATE = 'loglist_update';
export const LOGLIST_LOADING = 'loglist_loading';

export const LOG_GENERAL_LIST_FILTER = 'log_general_list_filter';
export const LOG_GENERAL_LIST_DATA = 'log_general_list_data';

var TimeData = getParameter("BusinessWorkingHours");

let initialState = {
    logListUpdate: false,
    logListVisible: false,
    filterDataLog: {
        sortColumnName: "CreationTime",
        sortOrder: "desc",
        totalRecord: 0,
        currentPage: 1,
        rowsPerPage: 50,
        searchText: "",
        BrandId: "",
        totalPage: 0,
        List: [],
        startDate: TimeData.start,
        endDate: TimeData.end,
        logType: 0
    },
    filterGenelDataLog: {
        Page: 1,
        StartDatetime: (TimeData == null ? new Date() : moment.utc(TimeData.start, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm")),
        EndDatetime: (TimeData == null ? new Date() : moment.utc(TimeData.end, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm")),
        BrandId: cookie.load("BrandId"),
        LogType: 1,
        ProcessType: 0
    },
    genelDataLogData: {
        Results: [],
        PageSize: 0,
        RowCount: 0,
        CurrentPage: 0
    },
    logListLoading: false
}

export async function setGeneralLogFilterData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: LOG_GENERAL_LIST_FILTER,
            payload: data
        });
    };
}

export async function getLogs(page) {
    return async (dispatch, getState) => {
        const { filterGenelDataLog, genelDataLogData } = getState().get('logReducer');
        dispatch({ type: LOGLIST_LOADING });
        filterGenelDataLog.Page = page;

        let aa = await axios({
            method: 'POST',
            url: brand.urlSocket + 'api/Settings/getLogs',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: filterGenelDataLog,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: LOG_GENERAL_LIST_DATA,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: LOGLIST_LOADING });
    };
}

export async function getLogList(filterDataLog) {
    return async (dispatch, getState) => {
        dispatch({ type: LOGLIST_LOADING });
        filterDataLog.BrandId = cookie.load("BrandId");

        let aa = await axios({
            method: 'POST',
            url: brand.urlSocket + 'api/Settings/getLogList',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: filterDataLog,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: LOGLIST_UPDATE,
                        data: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: LOGLIST_LOADING });
    };
}

export async function setLogListVisible(visible) {
    return async (dispatch, getState) => {
        dispatch({
            type: LOGLIST_VISIBLE,
            open: visible
        });
    };
}

const logReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOG_GENERAL_LIST_DATA:
            return { ...state, genelDataLogData: action.payload, logListUpdate: !state.logListUpdate }
        case LOG_GENERAL_LIST_FILTER:
            return { ...state, filterGenelDataLog: action.payload, logListUpdate: !state.logListUpdate }
        case LOGLIST_VISIBLE:
            return { ...state, logListVisible: action.open }
        case LOGLIST_LOADING:
            return { ...state, logListLoading: !state.logListLoading }
        case LOGLIST_UPDATE:
            return { ...state, filterDataLog: action.data, logListUpdate: !state.logListUpdate }
        default:
            return state;
    }
}

export default logReducer;
