import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../../CenterBrandPage/centerbrandpage-jss';
import {
    setDealerListVisible, setModalAdmin, setDealerAddVisible, getDealerSingle
} from '../../../../redux/modules/admin';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ModalInformation from '../../Ortak/ModalInformation';
import DealerAdd from './DealerAdd';

class DealerList extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.dealerListUpdate == this.props.dealerListUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() { }

    modalClose = () => {
        this.props.setModalAdmin().then(() => { })
    };


    render() {
        const { modalOpen, modalText, modalTitle, classes, dealerListVisible, dealerListData } = this.props;

        return (
            <Dialog
                open={dealerListVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'lg'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DealerAdd classes={classes} />
                {modalOpen ? <ModalInformation event={this.modalClose} open={modalOpen} title={modalTitle} text={modalText} /> : null}
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Bayi İşlemleri"}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto', height: '100vh' }}>
                    <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{'Bayi Kodu'}</TableCell>
                                <TableCell>{'İşletme Adı'}</TableCell>
                                <TableCell>{'İsim Soyisim'}</TableCell>
                                <TableCell>{'Telefon'}</TableCell>
                                <TableCell>{'Eposta'}</TableCell>
                                <TableCell align={"right"}>{'İşlemler'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dealerListData.map((n, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>{n.Code}</TableCell>
                                        <TableCell>{n.CompanyTitle}</TableCell>
                                        <TableCell>{n.NameSurname}</TableCell>
                                        <TableCell>{n.Phone}</TableCell>
                                        <TableCell>{n.Email}</TableCell>
                                        <TableCell align={"right"}>
                                            <Tooltip title={"Düzenle"}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                    onClick={() => this.props.getDealerSingle(n.Id).then(() => { })}>
                                                    <Icon>{"edit"}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => this.props.setDealerAddVisible(true).then(() => { })} color="primary">
                        {"Yeni BAYİ EKLE"}
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setDealerListVisible(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

DealerList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('adminReducer').dealerListUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    dealerListUpdate: value(state, props),
    dealerListVisible: state.get('adminReducer').dealerListVisible,
    dealerListData: state.get('adminReducer').dealerListData,

    modalOpen: state.get('adminReducer').form_dataAdmin.modalOpen,
    modalTitle: state.get('adminReducer').form_dataAdmin.modalTitle,
    modalText: state.get('adminReducer').form_dataAdmin.modalText,

});

const mapDispatchToProps = {
    setDealerListVisible,
    setModalAdmin,
    setDealerAddVisible,
    getDealerSingle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(DealerList)));
