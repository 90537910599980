import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Templates/Dashboard';
import {
  DashboardPage,
  TablePage,
  Form,
  Table,
  Error,
  NotFound,
  FetchData,
  SocketPage,
  DetailPage,
  Settings,
  PackagePage,
  CourierPIN,
  MainPage,
  CourierPackageList,
  CenterBrand,
  Report
} from '../pageListAsync';
import cookie from 'react-cookies';
import { getLicence } from '../../function/GeneralFunction';
import { setLicenceVisible } from '../../redux/modules/licence';
import teamwork from 'dan-images/team_work.png';
import Button from '@material-ui/core/Button';
import { reactLocalStorage } from 'reactjs-localstorage';
import LicencePage from '../Pages/SettingPage/Licence';
import Fab from '@material-ui/core/Fab';

class Application extends React.Component {
  constructor(props) {
    super(props);
    // Store the previous pathname and search strings
    this.currentPathname = null;
    this.currentSearch = null;
  }

  componentDidMount() {
    const { history } = this.props;

    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (
          newLocation.pathname !== this.currentPathname ||
          newLocation.search !== this.currentSearch
        ) {
          this.currentPathname = newLocation.pathname;
          this.currentSearch = newLocation.search;

          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search
          });
        }
      } else {
        history.go(1);
      }
    });
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  clickGo(day) {
    reactLocalStorage.set('remember', day);
    window.location.reload(true);
  }

  renderLicence() {
    if (cookie.load("LicenceNumber") != undefined) {
      var day = getLicence("date");
      if (day < 0) {
        return (
          <div style={{ zIndex: 9999, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.5)', bottom: 0, left: 0, right: 0, top: 0 }}>
            <div style={{ position: 'fixed', width: 400, backgroundColor: '#424242', zIndex: 9999, padding: 20, borderRadius: 10, textAlign: 'center', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <span style={{ color: 'white', fontWeight: 500 }}>{"Merhaba"}</span>
              <div style={{ display: 'grid' }}>
                <span style={{ color: 'white', fontWeight: 400 }}>{"Abonelik Süreniz Bitmiştir"}</span>
                <span style={{ color: 'white', fontWeight: 500, marginTop: 80 }}>{"Lütfen Aboneliğinizi Yenileyin!"}</span>
              </div>
              <div style={{ display: 'flex', marginTop: 20 }}>
                <Button
                  onClick={() => this.props.history.push('/')}
                  variant="contained"
                  style={{ textTransform: 'capitalize', backgroundColor: '#e74c3c', color: 'white', borderRadius: 3, width: '100%', margin: 3, width: '%50' }}
                >
                  {"Çıkış"}
                </Button>
                <Button
                  onClick={() => this.props.setLicenceVisible(true).then(() => { })}
                  variant="contained"
                  fullWidth
                  style={{ textTransform: 'capitalize', backgroundColor: '#27ae60', color: 'white', borderRadius: 3, width: '100%', margin: 3 }}
                >
                  {"Abonelik İşlemleri"}
                </Button>
              </div>
            </div>
          </div>
        );
      } else if (day <= 1 && reactLocalStorage.get('remember') != day) {
        return (
          <div style={{ zIndex: 9999, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.5)', bottom: 0, left: 0, right: 0, top: 0 }}>
            <div style={{ position: 'fixed', width: 400, backgroundColor: '#424242', zIndex: 9999, padding: 10, borderRadius: 10, textAlign: 'center', right:30, bottom:30 }}>
              <span style={{ color: 'white', fontWeight: 500, fontSize: 25 }}>{"HATIRLATMA!"}</span>
              <div style={{ display: 'grid', marginTop: 10 }}>
                <span style={{ color: 'white', fontWeight: 400 }}>{"Abonelik Süreniz: "}{(day == 1 ? "Son 1 Gün" : "Son Gün")}</span>
                <span style={{ color: 'white', fontWeight: 500, marginTop: 15, fontSize: 13 }}>{"Bu bir hatırlatma mesajıdır. Abonelik işlemlerinde sistem otomatik olarak kredi kartınızdan çekim yapıp abonelik sürenizi uzatacaktır. Herhangi bir aksaklık olmaması için lütfen kart limit ve bilgilerinizi kontrol ediniz!"}</span>
              </div>
              <div style={{ display: 'flex', marginTop: 20 }}>
                <Button
                  onClick={() => this.clickGo(day)}
                  variant="contained"
                  style={{ textTransform: 'capitalize', backgroundColor: '#27ae60', color: 'white', borderRadius: 3, width: '100%', margin: 3 }}
                >
                  {"Tamam"}
                </Button>
              </div>
            </div>
          </div>
        )
      }
    }
    return null;
  }

  render() {
    const { changeMode, history, classes } = this.props;
    return (
      <div>
        {this.renderLicence()}
        {getLicence("date") < 0 ? <LicencePage classes={classes} /> : null}
        <Dashboard history={history} changeMode={changeMode}>
          <Switch>
            <Route exact path="/app" component={TablePage} />
            <Route exact path="/app/fetchdata" component={FetchData} />
            <Route exact path="/app/socketpage" component={SocketPage} />
            <Route exact path="/app/detailpage" component={DetailPage} />
            <Route exact path="/app/packagepage" component={PackagePage} />
            <Route path="/courier/packages" component={CourierPackageList} />
            <Route path="/app/dashboard" component={DashboardPage} />
            <Route path="/app/form" component={Form} />
            <Route path="/app/table" component={Table} />
            <Route path="/app/pages/not-found" component={NotFound} />
            <Route path="/app/pages/error" component={Error} />
            <Route path="/app/settings" component={Settings} />
            <Route component={NotFound} />
          </Switch>
        </Dashboard>
      </div>
    );
  }
}

Application.propTypes = {
  changeMode: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  setLicenceVisible
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(Application);
