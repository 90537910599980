import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../Settings/settings-jss';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {
  subscriberExtensionModalVisibleChange,
  subscriberExtensionMonthChange,
  getSubscriberExtensionsMonth,
} from '../../../../redux/modules/admin';
import { createSelector } from 'reselect';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SubscriberExtensionUpdateModal extends React.Component {
  handleChangeInvoice = (Name) => (event) => {
    if (Name == 'Price') {
      this.props.subscriberPlanUpdateAmountChange(event.target.value);
    }
  };

  closeModalButton() {
    this.props.subscriberExtensionModalVisibleChange(false, '', '');
    this.props.subscriberExtensionMonthChange(1);
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.subscriberExtensionUpdateModalVisible}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.dialogPaperAbonelik } }}>
        <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {'Abonelik Süresi Uzatma'}
        </DialogTitle>
        <DialogContent style={{ width: 400 }}>
          {this.props.modalsLoading == true ? (
            <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
              <CircularProgress
                style={{ color: '#fafafa' }}
                thickness={4}
                className={classes.progress}
              />
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                <div style={{ textAlign: 'left', fontSize: 14, color: 'white', paddingLeft: 10 }}>
                  {'İşletme: ' + '\n' + this.props.subscriberExtensionBrandName}
                </div>
                <Grid item md={12}>
                  <FormControl style={{ width: '100%' }} className={classes.formControl}>
                    <InputLabel htmlFor="age-helper">{'Abonelik Durumu'}</InputLabel>
                    <Select
                      value={this.props.subscriberExtensionUpdateMonth}
                      onChange={(event) =>
                        this.props.subscriberExtensionMonthChange(event.target.value)
                      }
                      input={<Input name="Aylar" id="age-helper" />}>
                      <MenuItem value={1}>
                        <em>{'1 Aylık'}</em>
                      </MenuItem>
                      <MenuItem value={2}>{'2 Aylık'}</MenuItem>
                      <MenuItem value={3}>{'3 Aylık'}</MenuItem>
                      <MenuItem value={4}>{'4 Aylık'}</MenuItem>
                      <MenuItem value={5}>{'5 Aylık'}</MenuItem>
                      <MenuItem value={6}>{'6 Aylık'}</MenuItem>
                      <MenuItem value={7}>{'7 Aylık'}</MenuItem>
                      <MenuItem value={8}>{'8 Aylık'}</MenuItem>
                      <MenuItem value={9}>{'9 Aylık'}</MenuItem>
                      <MenuItem value={10}>{'10 Aylık'}</MenuItem>
                      <MenuItem value={11}>{'11 Aylık'}</MenuItem>
                      <MenuItem value={12}>{'12 Aylık'}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContentText>
          )}
        </DialogContent>
        {this.props.modalsLoading == false ? (
          <DialogActions>
            <Button
              style={{ backgroundColor: '#e74c3c', color: 'white' }}
              onClick={() => this.closeModalButton()}
              color="primary">
              {'İptal'}
            </Button>
            <Button
              onClick={() => this.props.getSubscriberExtensionsMonth()}
              color="secondary"
              variant="contained"
              autoFocus>
              {'Kaydet'}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    );
  }
}

SubscriberExtensionUpdateModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) =>
  state.get('adminReducer').subscriberExtensionUpdateModalIsupdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  subscriberExtensionUpdateModalIsupdate: value(state, props),
  subscriberExtensionUpdateModalVisible: state.get('adminReducer')
    .subscriberExtensionUpdateModalVisible,
  modalsLoading: state.get('adminReducer').modalsLoading,
  subscriberExtensionUpdateMonth: state.get('adminReducer').subscriberExtensionUpdateMonth,
  subscriberExtensionSubId: state.get('adminReducer').subscriberExtensionSubId,
  subscriberExtensionBrandName: state.get('adminReducer').subscriberExtensionBrandName,
});

const mapDispatchToProps = {
  subscriberExtensionModalVisibleChange,
  subscriberExtensionMonthChange,
  getSubscriberExtensionsMonth,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(SubscriberExtensionUpdateModal)));
