import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import styles from './cardStyle-jss';
import Icon from '@material-ui/core/Icon';

class PricingCard extends React.Component {
  render() {
    const {
      classes,
      title,
      price,
      feature,
      tier,
      isStatus
    } = this.props;

    const getTier = lv => {
      switch (lv) {
        case 'bronz':
          return classes.cheap;
        case 'silver':
          return classes.expensive;
        case 'gold':
          return classes.moreExpensive;
        default:
          return classes.free;
      }
    };

    return (
      <Card className={classNames(classes.priceCard, getTier(tier))} style={{ minHeight:580, borderRadius:5, maxWidth:'100%', position:'relative' }}>
        <div className={classes.priceHead}>
          <Typography variant="h5" style={{ fontSize:50, fontWeight:700 }}>{title}</Typography>
          <Typography style={{ marginTop:15, backgroundColor:'white', color :'#424242' }} component="h4" variant="h2">{price} <span style={{ fontSize:17 }}>{"/ Ay"}</span></Typography>
        </div>
        <CardContent className={classes.featureList} style={{ marginTop:40 }}>
          <ul>
            {feature.map((item, index) => (
              <li style={{ lineHeight:2, marginLeft:10, marginRight:10, fontWeight:600 }} key={index.toString()}>{item}</li>
            ))}
          </ul>
        </CardContent>
        {(isStatus ? (<Icon style={{ zIndex:1, fontSize:50, color:'white', position:'absolute', top:5, right:5, borderRadius:3 }}>{"check_box"}</Icon>) : (<Icon style={{ zIndex:1, fontSize:50, color:'white', position:'absolute', top:5, right:5, borderRadius:3 }}>{"check_box_outline_blank"}</Icon>))}
        <div style={{ position:'absolute', width:'100%', left:0, bottom:0, right:0, zIndex:0 }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill={"white"} fill-opacity="1" d="M0,64L34.3,96C68.6,128,137,192,206,213.3C274.3,235,343,213,411,218.7C480,224,549,256,617,245.3C685.7,235,754,181,823,160C891.4,139,960,149,1029,133.3C1097.1,117,1166,75,1234,64C1302.9,53,1371,75,1406,85.3L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
        </div>
      </Card>
    );
  }
}

PricingCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
  feature: PropTypes.array.isRequired,
};

export default withStyles(styles)(PricingCard);
