import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../../SettingPage/datepicker.css';
import tr from 'date-fns/locale/tr';
import 'leaflet/dist/leaflet.css';
import cookie from 'react-cookies';
import Select from '@material-ui/core/Select';
import MapClusterReport from '../../SettingPage/ReportPage/Maps/MapClusterReport';
import {
    getGeneralTotalReport, setFranchiseReportOrderHeatFilter, setFranchiseReportBrandSelected
} from '../../../../redux/modules/centerbrand';
import { getParameter } from '../../../../function/GeneralFunction';
registerLocale('tr', tr);
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
});

class GeneralTotal extends React.Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.franchiseReportGeneralTotalUpdate == this.props.franchiseReportGeneralTotalUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    handleChangeFilterOrderHeat = name => event => {
        const { filterOrderHeat } = this.props;
        filterOrderHeat[name] = (name == "StartDatetime" || name == "EndDatetime" ? moment.utc(event, 'MM-DD-YYYY').local().format("YYYY-MM-DD") : event);
        this.props.setFranchiseReportOrderHeatFilter(filterOrderHeat).then(() => { });
    };

    handleChange = async (event) => {
        this.props.setFranchiseReportBrandSelected(event.target.value).then(() => { });
    };

    renderTotal() {
        const { franchiseReportGeneralTotalData } = this.props;
        var total = 0;
        franchiseReportGeneralTotalData.map((n, i) => {
            total = total + n.TotalAmount;
        })
        return total.toFixed(2);
    }

    renderCount() {
        const { franchiseReportGeneralTotalData } = this.props;
        var total = 0;
        franchiseReportGeneralTotalData.map((n, i) => {
            total = total + n.TotalCount;
        })
        return total;
    }

    renderDetailOne() {
        const { franchiseReportGeneralTotalData } = this.props;
        var list = { IcSales: 0, DisSales: 0 };
        franchiseReportGeneralTotalData.map((n, i) => {
            n.TotalDetail.map((x, s) => {
                if (x.SalesType == 0) {
                    list.IcSales = list.IcSales + x.TotalAmount;
                } else {
                    list.DisSales = list.DisSales + x.TotalAmount;
                }
            })
        })
        return list;
    }

    renderDetailTwo(salestype) {
        const { franchiseReportGeneralTotalData } = this.props;
        var list = [];
        franchiseReportGeneralTotalData.map((n, i) => {
            n.TotalDetail.filter(x => x.SalesType == salestype).map((x, s) => {
                x.generalPaymentTotal.map((y, i) => {
                    if (list.filter(e => e.PaymentId == y.PaymentId).length == 0) {
                        list.push({ PaymentId: y.PaymentId, PaymentName: y.PaymentName, PaymentAmount: y.TotalAmount });
                    } else {
                        list.find(e => e.PaymentId == y.PaymentId).PaymentAmount += y.TotalAmount;
                    }
                })
            })
        })
        
        return list;
    }

    render() {
        const {
            franchiseReportName, franchiseReportGeneralTotalData, filterOrderHeat, isCenterReportLoading, classes,
            franchiseReportBrandSelected, centerBrandData
        } = this.props;

        if (franchiseReportName != 'GeneralTotal') {
            return null;
        }

        return (
            <Paper className={classes.root} elevation={0} style={{ height: '100%', borderRadius: 0, overflowX: 'hidden', overflowY: 'auto' }}>
                <div style={{
                    backgroundColor: '#424242', padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}
                >
                    {"Genel ve Şube Bazında Genel Toplamlar"}
                </div>
                <Grid container spacing={1} style={{ padding: 10 }}>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Başlangıç Tarihi"}
                        </Typography>
                        <DatePicker
                            selected={new Date(filterOrderHeat.StartDatetime)}
                            onChange={this.handleChangeFilterOrderHeat('StartDatetime')}
                            dateFormat="dd.MM.yyyy"
                            locale="tr"
                            className="inputDate"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Bitiş Tarihi"}
                        </Typography>
                        <DatePicker
                            selected={new Date(filterOrderHeat.EndDatetime)}
                            onChange={this.handleChangeFilterOrderHeat('EndDatetime')}
                            dateFormat="dd.MM.yyyy"
                            locale="tr"
                            className="inputDate"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Şube Seçimi"}
                        </Typography>
                        <Select
                            fullWidth
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            style={{ height: 38 }}
                            value={(franchiseReportBrandSelected.length == 0 ? "00000000-0000-0000-0000-000000000000" : franchiseReportBrandSelected)}
                            onChange={this.handleChange}
                        >
                            <MenuItem value={"00000000-0000-0000-0000-000000000000"}>
                                <ListItemText primary={"Tüm Şubeler"} />
                            </MenuItem>
                            {centerBrandData.map((n, i) => (
                                <MenuItem lat={n.Latitude} lng={n.Longitude} key={i} value={n.Id}>
                                    <ListItemText primary={n.Name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item md={3}>
                        <Button fullWidth disabled={isCenterReportLoading} onClick={() => this.props.getGeneralTotalReport().then(() => { })} style={{ marginTop: 18, borderRadius: 5, height: 37, padding: '6px 24px' }} size="large" variant="contained" color="secondary" type="submit">
                            {'FİLTRELE'}
                        </Button>
                    </Grid>
                </Grid>
                {(isCenterReportLoading ? <div style={{ width: '100%', textAlign: 'center', marginBottom: 15 }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
                <div style={{
                    padding: 10, width: '100%', height: '100%'
                }}
                >
                    <div style={{ marginTop: 10, display: 'block', width: '100%', backgroundColor: '#313131', borderRadius: 5, padding: 10 }}>
                        <div
                            className="printTitle"
                            style={{
                                marginTop: 10, backgroundColor: '#f0932b', width: '100%', padding: 5, textAlign: 'center', color: 'white'
                            }}
                        >
                            {"Toplam Rakamlar"}
                        </div>
                        <div className={classes.rootTable}>
                            <div style={{ display: 'flex', padding: 3 }}>
                                <div className="printLeft" style={{ color: 'white', width: '50%', fontWeight:600 }}>{"Toplam Ciro"}</div>
                                <div className="printLeft" style={{ color: 'white', width: '50%', fontWeight:600, textAlign: 'right' }}>{this.renderTotal()}</div>
                            </div>
                        </div>
                        <div className={classes.rootTable}>
                            <div style={{ display: 'flex', padding: 3 }}>
                                <div className="printLeft" style={{ color: 'white', width: '50%', fontWeight:600 }}>{"Adisyon Adeti"}</div>
                                <div className="printLeft" style={{ color: 'white', width: '50%', fontWeight:600, textAlign: 'right' }}>{this.renderCount()}</div>
                            </div>
                        </div>
                        <div style={{ display: 'block', width: '100%' }}>
                            <div
                                className="printTitle"
                                style={{
                                    width: '100%',  padding:3, textAlign: 'left', color: 'white', display: 'flex', fontSize:15
                                }}
                            >
                                <div className="printLeft" style={{ color: '#16a085', width: '50%', fontSize:18, fontWeight:600 }}>{"İç Satış"}</div>
                                <div className="printLeft" style={{ color: '#16a085', width: '50%', textAlign: 'right', fontSize:18, fontWeight:600 }}>{this.renderDetailOne().IcSales.toFixed(2)}</div>
                            </div>
                        </div>
                        {
                            this.renderDetailTwo(0).map((n, i) => {
                                return (
                                    <div style={{ display: 'block', width: '100%' }}>
                                        <div style={{ display: 'flex', padding: 3 }}>
                                            <div className="printLeft" style={{ color: 'white', width: '50%' }}>{n.PaymentName}</div>
                                            <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{n.PaymentAmount.toFixed(2)}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div style={{ display: 'block', width: '100%' }}>
                            <div
                                className="printTitle"
                                style={{
                                    width: '100%', padding:3, textAlign: 'left', color: 'white', display: 'flex', fontSize:15
                                }}
                            >
                                <div className="printLeft" style={{ color: '#16a085', width: '50%', fontSize:18, fontWeight:600 }}>{"Paket Satış"}</div>
                                <div className="printLeft" style={{ color: '#16a085', width: '50%', textAlign: 'right', fontSize:18, fontWeight:600 }}>{this.renderDetailOne().DisSales.toFixed(2)}</div>
                            </div>
                        </div>
                        {
                            this.renderDetailTwo(1).map((n, i) => {
                                return (
                                    <div style={{ display: 'block', width: '100%' }}>
                                        <div style={{ display: 'flex', padding: 3 }}>
                                            <div className="printLeft" style={{ color: 'white', width: '50%' }}>{n.PaymentName}</div>
                                            <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{n.PaymentAmount.toFixed(2)}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {(
                        franchiseReportGeneralTotalData.map((n, i) => {
                            return (
                                <div style={{ marginTop: 10, display: 'block', width: '100%', backgroundColor: '#313131', borderRadius: 5, padding: 10 }}>
                                    <div
                                        className="printTitle"
                                        style={{
                                            marginTop: 10, backgroundColor: '#0fb9b1', width: '100%', padding: 5, textAlign: 'center', color: 'white'
                                        }}
                                    >
                                        {n.BrandName}
                                    </div>
                                    <div className={classes.rootTable}>
                                        <div style={{ display: 'flex', padding: 3 }}>
                                            <div className="printLeft" style={{ color: 'white', width: '50%', fontWeight:600 }}>{"Toplam Ciro"}</div>
                                            <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right', fontWeight:600 }}>{n.TotalAmount.toFixed(2)}</div>
                                        </div>
                                    </div>
                                    <div className={classes.rootTable}>
                                        <div style={{ display: 'flex', padding: 3 }}>
                                            <div className="printLeft" style={{ color: 'white', width: '50%', fontWeight:600 }}>{"Adisyon Adeti"}</div>
                                            <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right', fontWeight:600 }}>{n.TotalCount}</div>
                                        </div>
                                    </div>
                                    <Divider />
                                    {
                                        n.TotalDetail.map((x, i) => (
                                            <div style={{ display: 'block', width: '100%' }}>
                                                <div
                                                    className="printTitle"
                                                    style={{
                                                        width: '100%', padding: 3, textAlign: 'left', color: 'white', display: 'flex', fontSize:15
                                                    }}
                                                >
                                                    <div className="printLeft" style={{ color: '#16a085', width: '50%' }}>{(x.SalesType == 0 ? "İç Satış" : "Paket Satış")}</div>
                                                    <div className="printLeft" style={{ color: '#16a085', width: '50%', textAlign: 'right' }}>{x.TotalAmount.toFixed(2)}</div>
                                                </div>
                                                {
                                                    x.generalPaymentTotal.map((y, i) => (
                                                        <div style={{ display: 'block', width: '100%' }}>
                                                            <div style={{ display: 'flex', padding: 3 }}>
                                                                <div className="printLeft" style={{ color: '#f39c12', width: '33.33%', fontSize:13 }}>{y.PaymentName}</div>
                                                                <div className="printLeft" style={{ color: '#f39c12', width: '33.33%', textAlign:'center', fontSize:13 }}>{y.TotalCount}</div>
                                                                <div className="printLeft" style={{ color: '#f39c12', width: '33.33%', textAlign: 'right', fontSize:13 }}>{y.TotalAmount.toFixed(2)}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )
                                        )
                                    }
                                </div>
                            )
                        })
                    )}
                </div>
            </Paper>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').franchiseReportGeneralTotalUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    franchiseReportGeneralTotalUpdate: value(state, props),
    franchiseReportName: state.get('centerBrandReducer').franchiseReportName,
    isCenterReportLoading: state.get('centerBrandReducer').isCenterReportLoading,
    filterOrderHeat: state.get('centerBrandReducer').filterOrderHeat,
    franchiseReportGeneralTotalData: state.get('centerBrandReducer').franchiseReportGeneralTotalData,
    centerBrandData: state.get('centerBrandReducer').centerBrandData,
    franchiseReportBrandSelected: state.get('centerBrandReducer').franchiseReportBrandSelected
});

const mapDispatchToProps = {
    getGeneralTotalReport,
    setFranchiseReportOrderHeatFilter,
    setFranchiseReportBrandSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(GeneralTotal)));
