import React from 'react';
import { connect } from 'react-redux';
import {
  withStyles
} from '@material-ui/core/styles';
import {
  createSelector
} from 'reselect';
import { reactLocalStorage } from 'reactjs-localstorage';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import cookie from 'react-cookies';
import TimerIcon from '@material-ui/icons/Timer';
import Close from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Ionicon from 'react-ionicons';
import { setNotificationUpdate, getNotification, setNotificationListUpdate } from '../../redux/modules/settings';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import * as workerTimers from 'worker-timers';
import { isEmpty } from '../../function/GeneralFunction';

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = { countOrder: 0 };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.IsNotificationUpdate == this.props.IsNotificationUpdate) {
      return false;
    }
    return true;
  }

  async componentDidMount() {
    this.timerYD = workerTimers.setInterval(() => this.tickYD(), 1000);
  }

  async tickYD() {
    const timerCount = this.state.countOrder + 1;
    this.setState({
      countOrder: timerCount
    });
    if (timerCount > 60) {
      await this.clearYD();
      await this.props.getNotification(false).then(() => { });
    }
  }

  clearYD() {
    this.setState({
      countOrder: 0
    });
  }

  componentWillUnmount() {
    workerTimers.clearInterval(this.timerYD);
  }

  clickRemoveNotification(Id) {
    const { NotificationList } = this.props;
    var not = reactLocalStorage.getObject('NotificationList');
    var list = [];
    if (!isEmpty(not)) {
      list = not;
    }
    list.push(NotificationList.find(x => x.Id == Id).Id)
    reactLocalStorage.setObject('NotificationList', list);
    NotificationList.find(x => x.Id == Id).IsVisible = false;
    this.props.setNotificationListUpdate(NotificationList, true).then(() => { })
  }

  renderModal() {
    const { NotificationList } = this.props;
    var list = NotificationList.filter(x => x.IsVisible == true);
    return list.map((n, i) => {
      return (
        <div style={{ backgroundColor: 'white', position: 'fixed', top: '15%', left: '15%', right: '15%', bottom: '15%', zIndex: 999999, borderRadius: 10, padding: 20 }}>
          <div style={{ fontWeight: 600, textTransform: 'capitalize', borderBottom: '1px solid rgba(209, 204, 192,.5)', padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', color: '#424242' }}>
              <Icon style={{ fontSize: 35, color: 'rgba(170, 166, 157,1.0)', marginRight: 10 }}>{"info"}</Icon>
              {n.Title}
            </div>
          </div>
          <div style={{ overflowX:'auto', padding: 10, fontWeight: 500, height: 'calc(100% - 110px)', color: '#424242' }}>
            <div dangerouslySetInnerHTML={{ __html: n.Text }} />
          </div>
          <div style={{ fontWeight: 600, textTransform: 'capitalize', borderTop: '1px solid rgba(209, 204, 192,.5)', padding: 10, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button style={{ paddingRight: 25, paddingLeft: 25, color: 'white', backgroundColor: 'rgba(33, 140, 116,1.0)' }} aria-label="İnformation" onClick={() => this.clickRemoveNotification(n.Id)}>
              <Icon style={{ marginRight: 10, color: 'white' }}>{"done"}</Icon>
              {"Okudum"}
            </Button>
          </div>
        </div>
      )
    })
  }

  render() {
    const {
      classes
    } = this.props;
    return (

      <aside
        className={
          classNames(
            classes.notificationSidebar,
            this.props.IsNotificationVisible && classes.expanded
          )
        }
      >
        {this.renderModal()}
        <div style={{ padding: 10, justifyContent: 'space-between', alignItems: 'center', height: '50px', display: 'flex', position: 'relative', borderBottom: '1px solid #636363', display: (this.props.IsNotificationVisible ? 'flex' : 'none') }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon style={{ color: 'white', fontSize: 30 }}>{"notifications"}</Icon>
            <span style={{ lineHeight: 1, fontWeight: 600, color: 'white', fontSize: 15, alignSelf: 'center', paddingLeft: 5 }}>{"Bildirimler"}</span>
          </div>
          <div>
            <IconButton style={{ backgroundColor: 'rgba(255, 177, 66,1.0)', padding: 10, marginRight: 8 }} className={classes.button} onClick={() => this.props.getNotification().then(() => { })}>
              <Icon style={{ color: 'white', fontSize: 20 }}>{"refresh"}</Icon>
            </IconButton>
            <IconButton style={{ backgroundColor: 'rgba(179, 57, 57,1.0)', padding: 10 }} className={classes.button} onClick={() => this.props.setNotificationUpdate(false).then(() => { })}>
              <Icon style={{ color: 'white', fontSize: 20 }}>{"close"}</Icon>
            </IconButton>
          </div>
        </div>
        <Slide direction={'left'} in={this.props.IsNotificationVisible} mountOnEnter unmountOnExit>
          <div style={{ margin: 2, overflowY: 'auto', height: '100%', padding: 5, paddingBottom: 60 }}>
            {
              this.props.NotificationList.map((n, i) => {
                return (
                  <div key={i} style={{ position: 'relative' }}>
                    <Paper style={{ backgroundColor: '#303030', borderRadius: 3, marginBottom: 8, padding: 5, marginTop: 3, position: 'relative' }}>
                      <div style={{ borderBottom: ('2px solid ' + (n.NoticationType == 0 ? "#FFB300" : (n.NoticationType == 1 ? "#1E88E5" : (n.NoticationType == 2 ? "#00897B" : (n.NoticationType == 3 ? "#D81B60" : ""))))), backgroundColor: (n.NoticationType == 0 ? "#FFB300" : (n.NoticationType == 1 ? "#1E88E5" : (n.NoticationType == 2 ? "#00897B" : (n.NoticationType == 3 ? "#D81B60" : "")))), display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ color: 'white', width: '100%', padding: 5, textAlign: 'left', fontWeight: 600, fontSize: 14 }}>{(n.NoticationType == 0 ? "Uyarı" : (n.NoticationType == 1 ? "Bilgilendirme" : (n.NoticationType == 2 ? "Güncelleme" : (n.NoticationType == 3 ? "Kutlama" : ""))))}</div>
                      </div>
                      <div style={{ padding: 10 }}>
                        <Typography className={classes.buttonTitle} style={{ width: '100%', fontSize: 15, textAlign: 'center', marginTop: 5 }}>{n.Title}</Typography>
                        <Typography className={classes.buttonTitle} style={{ fontSize: 12, marginTop: 5 }}>
                          <div dangerouslySetInnerHTML={{ __html: n.Text }} />
                        </Typography>
                      </div>
                      <Typography className={classes.buttonTitle} style={{ textAlign: 'right', fontSize: 10, marginTop: 5 }}>{moment(n.CreationDatetime).format('DD.MM.YYYY HH:mm:ss')}</Typography>
                    </Paper>
                  </div>
                )
              })
            }
          </div>
        </Slide>
      </aside>
    );
  }
}

const valueSelector = (state, props) => state.get('settingsReducer').IsNotificationUpdate;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  IsNotificationUpdate: value(state, props),
  NotificationList: state.get('settingsReducer').NotificationList,
  IsNotificationVisible: state.get('settingsReducer').IsNotificationVisible
})

const mapDispatchToProps = {
  setNotificationUpdate,
  getNotification,
  setNotificationListUpdate
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Notification);
