import axios from 'axios';
import { HubConnectionBuilder, LogLevel, HttpTransportType, HubConnectionState } from "@microsoft/signalr";
var base64 = require('base-64');
const uuidv4 = require('uuid/v4');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import { soundPlay, getPaketYolda, notificationShow } from '../../function/GeneralFunction';
import moment from 'moment';
import * as workerTimers from "worker-timers";
import {
    getAddressList
} from './addition';
import { reactLocalStorage } from 'reactjs-localstorage';
import subSeconds from 'date-fns/subSeconds';

//PaketYoldaYeni
export const SET_PAKETYOLDA_CENTERLIST_VISIBLE = 'set_paketyolda_centerlist_visible';
export const SET_PAKETYOLDA_CENTERLIST_LOADING = 'set_paketyolda_centerlist_loading';
export const SET_PAKETYOLDA_CENTERLIST_DATA = 'set_paketyolda_centerlist_data';

export const SET_PAKETYOLDA_CENTER_ADD_VISIBLE = 'set_paketyolda_center_add_visible';
export const SET_PAKETYOLDA_CENTER_ADD_LOADING = 'set_paketyolda_center_add_loading';
export const SET_PAKETYOLDA_CENTER_ADD_DATA = 'set_paketyolda_center_add_data';

export const SET_PY_BRANDLIST_VISIBLE = 'set_py_brandlist_visible';
export const SET_PY_BRANDLIST_LOADING = 'set_py_brandlist_loading';
export const SET_PY_BRANDLIST_DATA = 'set_py_brandlist_data';

export const SET_PAKETYOLDA_BRAND_ADD_VISIBLE = 'set_paketyolda_brand_add_visible';
export const SET_PAKETYOLDA_BRAND_ADD_LOADING = 'set_paketyolda_brand_add_loading';
export const SET_PAKETYOLDA_BRAND_ADD_DATA = 'set_paketyolda_brand_add_data';

export const SET_PAKETYOLDA_USERLIST_VISIBLE = 'set_paketyolda_userlist_visible';
export const SET_PAKETYOLDA_USERLIST_LOADING = 'set_paketyolda_userlist_loading';
export const SET_PAKETYOLDA_USERLIST_DATA = 'set_paketyolda_userlist_data';

export const SET_PAKETYOLDA_USER_ADD_VISIBLE = 'set_paketyolda_user_add_visible';
export const SET_PAKETYOLDA_USER_ADD_LOADING = 'set_paketyolda_user_add_loading';
export const SET_PAKETYOLDA_USER_ADD_DATA = 'set_paketyolda_user_add_data';

export const SET_PAKETYOLDA_COURIERLIST_VISIBLE = 'set_paketyolda_courierlist_visible';
export const SET_PAKETYOLDA_COURIERLIST_LOADING = 'set_paketyolda_courierlist_loading';
export const SET_PAKETYOLDA_COURIERLIST_DATA = 'set_paketyolda_courierlist_data';

export const SET_PAKETYOLDA_NEWCOURIER_ADD_VISIBLE = 'set_paketyolda_newcourier_add_visible';
export const SET_PAKETYOLDA_NEWCOURIER_ADD_LOADING = 'set_paketyolda_newcourier_add_loading';
export const SET_PAKETYOLDA_NEWCOURIER_ADD_DATA = 'set_paketyolda_newcourier_add_data';

export const SET_PAKETYOLDA_SETTINGS_VISIBLE = 'set_paketyolda_settings_visible';
export const SET_PAKETYOLDA_SETTINGS_DATA = 'set_paketyolda_settings_data';

export const SET_PAKETYOLDA_REPORT_VISIBLE = 'set_paketyolda_report_visible';
export const SET_PAKETYOLDA_REPORT_DATA = 'set_paketyolda_report_data';

export const SET_PAKETYOLDA_GENERALSETTINGS_LOADING = 'set_paketyolda_generalsettings_loading';
export const SET_PAKETYOLDA_GENERALSETTINGS_DATA = 'set_paketyolda_generalsettings_data';

export const SET_PAKETYOLDA_REGIONLIST_LOADING = 'set_paketyolda_regionlist_loading';
export const SET_PAKETYOLDA_REGIONLIST_DATA = 'set_paketyolda_regionlist_data';

export const SET_PAKETYOLDA_REGION_ADD_VISIBLE = 'set_paketyolda_region_add_visible';
export const SET_PAKETYOLDA_REGION_ADD_LOADING = 'set_paketyolda_region_add_loading';
export const SET_PAKETYOLDA_REGION_ADD_DATA = 'set_paketyolda_region_add_data';

export const SET_PAKETYOLDA_BUSINESSLIST_VISIBLE = 'set_paketyolda_businesslist_visible';
export const SET_PAKETYOLDA_BUSINESSLIST_LOADING = 'set_paketyolda_businesslist_loading';
export const SET_PAKETYOLDA_BUSINESSLIST_DATA = 'set_paketyolda_businesslist_data';


export const PAKETYOLDA_GENERALREPORT_FILTER_DATA = 'paketyolda_generalreport_filter_data';
export const PAKETYOLDA_GENERALREPORT_SET_DATA = 'paketyolda_generalreport_set_data';
export const PAKETYOLDA_GENERALREPORT_SET_ORDERDATA = 'paketyolda_generalreport_set_orderdata';
export const PAKETYOLDA_ORDERMAPREPORT_SET_ORDERDATA = 'paketyolda_ordermapreport_set_orderdata';
export const PAKETYOLDA_GENERALREPORT_LOADING = 'paketyolda_generalreport_loading';

export const ANCHOR_ENTEGRATION = 'anchor_entegration';
//PaketYoldaSon

export const SET_PAKETYOLDA_VISIBLE = 'set_paketyolda_visible';
export const SET_PAKETYOLDA_UPDATE = 'set_paketyolda_update';
export const SET_PAKETYOLDA_LOADING = 'set_paketyolda_loading';
export const SET_PAKETYOLDA_DATA = 'set_paketyolda_data';

export const SET_PAKETYOLDA_ADD_VISIBLE = 'set_paketyolda_add_visible';
export const SET_PAKETYOLDA_ADD_LOADING = 'set_paketyolda_add_loading';
export const SET_PAKETYOLDA_ADD_DATA = 'set_paketyolda_add_data';

export const SET_PAKETYOLDA_BRAND_VISIBLE = 'set_paketyolda_brand_visible';
export const SET_PAKETYOLDA_BRAND_UPDATE = 'set_paketyolda_brand_update';
export const SET_PAKETYOLDA_BRAND_LIST = 'set_paketyolda_brand_list';
export const SET_PAKETYOLDA_COURIER_LIST = 'set_paketyolda_courier_list';

export const SET_AUTOCOMPLATED_BRANDLIST = 'set_autocomplated_brandlist';
export const SET_AUTOCOMPLATED_VALUE = 'set_autocomplated_value';
export const SET_AUTOCOMPLATED_SELECTED = 'set_autocomplated_selected';

export const SET_PAKETYOLDA_USERLOGIN_DATA = 'set_paketyolda_userlogin_data';
export const SET_PAKETYOLDA_USERLOGIN_LOADING = 'set_paketyolda_userlogin_loading';

export const PAKETYOLDA_ERROR = 'paketyolda_error';
export const PAKETYOLDA_MODALCLOSE = 'paketyolda_modalclose';

export const SET_PAKETYOLDA_USER_VISIBLE = 'set_paketyolda_user_visible';
export const SET_PAKETYOLDA_USER_UPDATE = 'set_paketyolda_user_update';
export const SET_PAKETYOLDA_USER_LIST = 'set_paketyolda_user_list';

export const SET_PAKETYOLDA_USER_ADD = 'set_paketyolda_user_add';

export const PAKETYOLDA_COURIER_HUB_CONNECT = 'paketyolda_courier_hub_connect';
export const PAKETYOLDA_COURIER_TRACK_LIST = 'paketyolda_courier_track_list';

export const PAKETYOLDA_COURIER_LIST = 'paketyolda_courier_list';
export const PAKETYOLDA_COURIER_VISIBLE = 'paketyolda_courier_visible';
export const PAKETYOLDA_ORDER_HUB_CONNECT = 'paketyolda_order_hub_connect';
export const PAKETYOLDA_ORDER_LIST = 'paketyolda_order_list';
export const PAKETYOLDA_ORDER_LIST_ADD = 'paketyolda_order_list_add';
export const PAKETYOLDA_ORDER_UPDATE = 'paketyolda_order_update';

export const PAKETYOLDA_ORDER_STATUS_VISIBLE = 'paketyolda_order_status_visible';
export const PAKETYOLDA_ORDER_STATUS_LOADING = 'paketyolda_order_status_loading';
export const PAKETYOLDA_ORDER_STATUS_DATA = 'paketyolda_order_status_data';

export const PAKETYOLDA_PANELPAGE_UPDATE = 'paketyolda_panelpage_update';

export const PAKETYOLDA_REPORT_VISIBLE = 'paketyolda_report_visible';
export const PAKETYOLDA_BRANDLIST_VISIBLE = 'paketyolda_brandlist_visible';
export const PAKETYOLDA_REPORT_DATA = 'paketyolda_report_data';
export const PAKETYOLDA_REPORT_LOADING = 'paketyolda_report_loading';

export const PAKETYOLDA_REPORT_FILTER_DATA = 'paketyolda_report_filter_data';

export const SET_PAKETYOLDA_COURIER_ADD_VISIBLE = 'set_paketyolda_courier_add_visible';
export const SET_COURIER_ADD_VISIBLE = 'set_courier_add_visible';
export const SET_PAKETYOLDA_COURIER_ADD_UPDATE = 'set_paketyolda_courier_add_update';
export const SET_PAKETYOLDA_COURIER_ADD_LIST = 'set_paketyolda_courier_add_list';
export const SET_PAKETYOLDA_COURIER_ADD = 'set_paketyolda_courier_add';

export const SET_PAKETYOLDA_BRAND_AUTOSEND = 'set_paketyolda_brand_autosend';
export const SET_PAKETYOLDA_ADDITION_REPORT = 'set_paketyolda_addition_report';

export const SET_PAKETYOLDA_PIN_LOGIN = 'set_paketyolda_pin_login';

export const SET_PAKETYOLDA_BRAND_DETAIL = 'set_paketyolda_brand_detail';

export const SET_PAKETYOLDA_MAINPAGE_UPDATE = 'set_paketyolda_mainpage_update';

export const PAKETYOLDA_LOCATION_VISIBLE = 'paketyolda_location_visible';
export const PAKETYOLDA_LOCATION_DATA = 'paketyolda_location_data';
export const PAKETYOLDA_COUNTER_UPDATE = 'paketyolda_counter_update';

export const HUB_USER_SYSTEM_STOP = 'hub_user_system_stop';

export const PAKETYOLDA_MILEAGECHARGE_VISIBLE = 'paketyolda_mileagecharge_visible';
export const PAKETYOLDA_MILEAGECHARGE_LOADING = 'paketyolda_mileagecharge_loading';
export const PAKETYOLDA_MILEAGECHARGE_DATA = 'paketyolda_mileagecharge_data';
export const PAKETYOLDA_MILEAGECHARGE_UPDATE = 'paketyolda_mileagecharge_update';

export const DEALER_LIST_VISIBLE = 'dealer_list_visible';
export const DEALER_LIST_DATA = 'dealer_list_data';
export const DEALER_LIST_UPDATE = 'dealer_list_update';

export const JAVIPOS_COURIER_TRACK_LIST = 'javipos_courier_track_list';
export const JAVIPOS_COURIER_HUB_CONNECT = 'javipos_courier_hub_connect';

export const PAKETYOLDA_BRAND_SETTINGS_VISIBLE = 'paketyolda_brand_settings_visible';
export const PAKETYOLDA_BRAND_SETTINGS_DATA = 'paketyolda_brand_settings_data';

var TimeData = getPaketYolda("BusinessWorkingHours");
let initialState = {
    form_data: {
        modalOpen: false,
        modalTitle: "",
        modalText: ""
    },
    //PaketYoldaYeni
    paketYoldaCenterList: [],
    paketYoldaCenterListVisible: false,
    paketYoldaCenterListUpdate: false,
    paketYoldaCenterListLoading: false,

    paketYoldaCenterAddVisible: false,
    paketYoldaCenterAddUpdate: false,
    paketYoldaCenterAddLoading: false,
    paketYoldaCenterAddData: {
        Id: "00000000-0000-0000-0000-000000000000",
        Email: "",
        Password: "",
        IsActive: true,
        AuthorizedPerson: "",
        Phone: ""
    },
    pyBrandList: [],
    pyBrandListVisible: false,
    pyBrandListUpdate: false,
    pyBrandListLoading: false,
    paketYoldaBrandAddVisible: false,
    paketYoldaBrandAddUpdate: false,
    paketYoldaBrandAddLoading: false,
    paketYoldaBrandAddData: {
        Id: "00000000-0000-0000-0000-000000000000",
        CompanyTitle: "",
        BrandName: "",
        AuthorizedPerson: "",
        Phone: "",
        Address: "",
        TaxNumber: "",
        TaxAdministration: "",
        Explanation: "",
        IsOfficialDistributor: true,
        BrandId: "",
        CenterId: "",
        IlId: "",
        IlceIds: [],
        IsActive: true,
        CreditToBeLoaded: 10000
    },
    paketYoldaNewUserList: [],
    paketYoldaNewUserListVisible: false,
    paketYoldaNewUserListUpdate: false,
    paketYoldaNewUserListLoading: false,
    paketYoldaUserAddVisible: false,
    paketYoldaUserAddUpdate: false,
    paketYoldaUserAddLoading: false,
    paketYoldaUserAddData: {
        Id: "00000000-0000-0000-0000-000000000000",
        IsActive: true,
        Username: "",
        Passsword: "",
        PIN: "",
        BrandId: "00000000-0000-0000-0000-000000000000",
        CenterId: "00000000-0000-0000-0000-000000000000"
    },
    paketYoldaUserLoginData: {
        Username: "",
        Password: "",
        IsRemember: false,
        IsCenterBrand: 'brand'
    },
    paketYoldaUserLoginUpdate: false,
    paketYoldaUserLoginLoading: false,
    pySubBrandList: [],
    pySubBrandListVisible: false,
    pySubBrandListUpdate: false,
    pySubBrandListLoading: false,

    paketYoldaCourierList: [],
    paketYoldaCourierListVisible: false,
    paketYoldaCourierListUpdate: false,
    paketYoldaCourierListLoading: false,
    paketYoldaNewCourierAddVisible: false,
    paketYoldaNewCourierAddUpdate: false,
    paketYoldaNewCourierAddLoading: false,
    paketYoldaNewCourierAddData: {
        Id: "00000000-0000-0000-0000-000000000000",
        IsActive: true,
        IsReceivingPackage: false,
        IsRevokeAuthorization: false,
        NameSurname: "",
        Phone: "",
        AllowedTime: "",
        Password: "",
        BrandId: "00000000-0000-0000-0000-000000000000",
        CenterId: "00000000-0000-0000-0000-000000000000",
        IspaymentTypeChange: false
    },
    paketYoldaSettingsMenu: '',
    paketYoldaSettingsVisible: false,
    paketYoldaSettingsUpdate: false,
    paketYoldaSettingsLoading: false,
    paketYoldaGeneralSettingsLoading: true,
    paketYoldaGeneralSettingsUpdate: false,
    paketYoldaGeneralSettingsData: null,
    paketYoldaRegionListLoading: true,
    paketYoldaRegionListUpdate: false,
    paketYoldaBusinessListUpdate: false,
    paketYoldaRegionListData: null,

    paketYoldaRegionAddVisible: false,
    paketYoldaRegionAddUpdate: false,
    paketYoldaRegionAddLoading: false,
    paketYoldaRegionAddData: {
        Id: "00000000-0000-0000-0000-000000000000",
        BrandId: "00000000-0000-0000-0000-000000000000",
        PolygonJson: "",
        CreationTime: new Date(),
        RegionName: "",
        RegionCourier: [],
        RegionBusiness: []
    },

    paketYoldaBusinessList: [],
    paketYoldaBusinessListVisible: false,
    paketYoldaBusinessListUpdate: false,
    paketYoldaBusinessListLoading: false,

    paketYoldaReportMenu: '',
    paketYoldaReportVisible: false,
    paketYoldaReportUpdate: false,

    paketYoldaGeneralReportfilter: {
        StartDatetime: (TimeData == null ? new Date() : moment.utc(TimeData.start, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm")),
        EndDatetime: (TimeData == null ? new Date() : moment.utc(TimeData.end, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm")),
        BrandId: "00000000-0000-0000-0000-000000000000",
        JaviPosBrandId: "00000000-0000-0000-0000-000000000000",
        CourierId: "00000000-0000-0000-0000-000000000000",
        page: 1,
        IsDelete: "Active"
    },
    paketYoldaGeneralReportUpdate: false,
    paketYoldaGeneralReportData: {
        PaymentData: [],
        HourDatas: [],
        CourierData: [],
        OrderCount: 0,
        TotalPrice: 0,
        AvgAdditionPrice: 0,
        SumPacketDistance: 0,
        AvgPacketDistance: 0,
        C_Delivery: 0,
        C_Idle: 0,
        C_OnBreak: 0,
        P_Waiting: 0,
        P_Assignment: 0,
        P_Delivery: 0,
        P_MileageChargeCourierSum: 0,
        P_MileageChargeRestSum: 0,
        AvgDeliveredTime: 0
    },
    paketYoldaNewReportLoading: false,
    generalReportOrderList: null,

    paketYoldaOrderMapReportfilter: {
        StartDatetime: (TimeData == null ? new Date() : moment.utc(TimeData.start, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm")),
        EndDatetime: (TimeData == null ? new Date() : moment.utc(TimeData.end, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm")),
        BrandId: "00000000-0000-0000-0000-000000000000",
        JaviPosBrandId: "00000000-0000-0000-0000-000000000000",
        CourierId: "00000000-0000-0000-0000-000000000000",
    },
    paketYoldaOrderMapReportUpdate: false,
    paketYoldaOrderMapReportData: [],
    //PaketYoldaSon
    paketYoldaList: [],
    paketYoldaVisible: false,
    paketYoldaUpdate: false,
    paketYoldaLoading: false,

    paketYoldaAddVisible: false,
    paketYoldaAddUpdate: false,
    paketYoldaAddLoading: false,
    paketYoldaAddData: {
        CompanyTitle: "",
        BrandName: "",
        AuthorizedPerson: "",
        Phone: "",
        Address: "",
        TaxNumber: "",
        TaxAdministration: "",
        IsActive: true,
        WorkingHourStart: new Date(),
        WorkingHourEnd: new Date()
    },
    paketYoldaBrandList: [],
    paketYoldaCourierListData: [],
    paketYoldaBrandVisible: false,
    paketYoldaBrandUpdate: false,
    autoComplatedBrandList: [],
    autoComplatedValue: "",
    autoComplatedSelected: null,
    PaketYoldaId: "",
    paketYoldaUserList: [],
    paketYoldaUserVisible: false,
    paketYoldaUserUpdate: false,

    paketYoldaUserAdd: {
        Id: "00000000-0000-0000-0000-000000000000",
        Username: "",
        Passsword: "",
        PaketYoldaId: "",
        IsActive: true,
        PIN: ""
    },

    paketYoldaCourierHubConnection: null,
    IsPaketYoldaPanelUpdate: false,
    paketYoldaCourierTrack: null,
    paketYoldaOrderHubConnection: null,
    paketYoldaOrderList: [],
    paketYoldaOrderListUpdate: false,
    singleOrderData: null,
    singleOrderDataUpdate: false,
    paketYoldaCourierListVisible: false,

    paketYoldaOrderStatusVisible: false,
    paketYoldaOrderStatusLoading: false,
    paketYoldaOrderStatusData: [],
    paketYoldaOrderCourierSelected: null,

    paketYoldaReportUpdate: false,
    paketYoldaReportVisible: false,
    paketYoldaBrandListVisible: false,
    paketYoldaBrandListUpdate: false,
    paketYoldaReportLoading: false,
    paketYoldaReportData: [],
    paketYoldaReportDataPayment: [],
    paketYoldaFilter: {
        StartDatetime: new Date(),
        EndDatetime: new Date(),
        BrandId: "00000000-0000-0000-0000-000000000000",
        CourierId: "00000000-0000-0000-0000-000000000000",
        IsList: false,
        ReportType: "",
        IsCompare: false
    },
    paketYoldaCourierAddVisible: false,
    courierAddVisible: false,
    paketYoldaCourierAddUpdate: false,
    paketYoldaCourierAddData: {
        PaketYoldaId: "",
        Phone: "",
        Password: "",
        NameSurname: "",
        IsActive: true
    },
    paketYoldaCourierAddList: [],
    IsAutomaticSubmission: true,
    paketYoldaOrderStatus: 0,
    paketYoldaAdditionReportData: [],
    paketYoldaPINLogin: false,
    paketYoldaPINLoginType: "",
    paketYoldaBrandDetail: null,
    IsPaketYoldaMainPageUpdate: false,
    anchorElEntegrationDetail: null,
    paketYoldaLocationVisible: false,
    paketYoldaLocationData: [],
    IsPaketYoldaCounterUpdate: false,
    systemUserHubConnection: null,

    mileageChargeType: 0,
    mileageChargeVisible: false,
    mileageChargeUpdate: false,
    mileageChargeLoading: false,
    mileageChargeData: [],

    dealerListVisible: false,
    dealerListUpdate: false,
    dealerListData: [],

    javiPosCourierHubConnection: null,
    javiPosCourierTrack: null,
    javiPosCourierTrackUpdate: false,

    brandSettingsVisible: false,
    brandSettingsData: null
}

export async function setSystemUserHubConnection() {
    return async (dispatch, getState) => {
        var BrandId = cookie.load('PaketYoldaId');

        const systemUserHubConnection = new HubConnectionBuilder()
            .configureLogging(LogLevel.None)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 300000) {
                        // If we've been reconnecting for less than 60 seconds so far,
                        // wait between 0 and 10 seconds before the next reconnect attempt.
                        return Math.random() * 3000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            })
            .withUrl(brand.urlSocketPaketYolda + "system?PaketYoldaBrandId=" + BrandId, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .build();

        await connectUserSystem(systemUserHubConnection, BrandId, dispatch);

        dispatch({ type: HUB_USER_SYSTEM_STOP, payload: systemUserHubConnection });
    }
}

async function connectUserSystem(conn, BrandId, dispatch) {
    await conn.start().then(() => {
        conn.on('ReceiveUserMessageHandler', (message) => {
            var text = JSON.parse(message);
            var date = cookie.load('PaketYoldaLoginDate');
            if (date < moment.utc(subSeconds(new Date(), 5), 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm:ss") && text.UserId == cookie.load("PaketYoldaUserId")) {
                window.location.href = "/javikurye";
            }
        });
    }).catch(e => {
        sleepSystem(5000);
        connectUserSystem(conn, BrandId, dispatch);
    })
}

async function sleepSystem(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
}

//Yeni PaketYolda
export async function getPaketYoldaUserLogin() {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_USERLOGIN_LOADING, payload: true });
        const { paketYoldaUserLoginData } = getState().get('paketYoldaReducer');
        await axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + (paketYoldaUserLoginData.IsCenterBrand == "brand" ? 'api/User/getUserLoginControl' : 'api/Brand/getCenterLoginControl'),
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaUserLoginData,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    reactLocalStorage.remove('py_string');

                    var json = JSON.parse(response.data.responseData);
                    cookie.save('PaketYoldaLogin', true, { path: '/' });
                    cookie.save('PaketYoldaLoginDate', moment.utc(new Date(), 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm:ss"), { path: '/' });
                    cookie.save('PaketYoldaId', json.BrandId, { path: '/' });
                    cookie.save('PaketYoldaCenterId', json.CenterId, { path: '/' });
                    cookie.save('PaketYoldaUsername', json.Username, { path: '/' });
                    cookie.save('PaketYoldaUserId', json.Id, { path: '/' });
                    cookie.save('Password', paketYoldaUserLoginData.Password, { path: '/' });
                    cookie.save('IsRemember', paketYoldaUserLoginData.IsRemember, { path: '/' });
                    reactLocalStorage.setObject('py_string', base64.encode(response.data.responseData));
                    window.location.href = (paketYoldaUserLoginData.IsCenterBrand == "brand" ? '/paketyoldamainpage' : '/paketyoldacenter');
                } else {
                    dispatch(setModalPaketYolda(response.data.responseData));
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: SET_PAKETYOLDA_USERLOGIN_LOADING, payload: false });
    }
}

export async function setAddBrand() {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_ADD_LOADING, payload: true });
        const { paketYoldaBrandAddData } = getState().get('paketYoldaReducer');

        var IlceIds = [];
        if (!Array.isArray(paketYoldaBrandAddData.IlceIds)) {
            paketYoldaBrandAddData.IlceIds.split(',').map((n, i) => {
                IlceIds.push(n);
            })
            paketYoldaBrandAddData.IlceIds = IlceIds;
        }

        axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/Brand/addBrand',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaBrandAddData,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPyBrandList(paketYoldaBrandAddData.BrandId, paketYoldaBrandAddData.CenterId));
                    dispatch(setPaketYoldaBrandAddVisible(false));
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: SET_PAKETYOLDA_ADD_LOADING, payload: false });
    }
}

export async function getPaketYoldaNewUserList(BrandId) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_USERLIST_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/User/getUserList?BrandId=' + BrandId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_PAKETYOLDA_USERLIST_DATA,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_USERLIST_LOADING, payload: false });
    };
}

export async function getPaketYoldaCenterList() {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_CENTERLIST_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Brand/getCenterList',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_PAKETYOLDA_CENTERLIST_DATA,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_CENTERLIST_LOADING, payload: false });
    };
}

export async function setCenterInactive(CenterId) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_CENTER_ADD_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Brand/setCenterInactive?CenterId=' + CenterId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                dispatch(getPaketYoldaCenterList());
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_CENTER_ADD_LOADING, payload: false });
    };
}

export async function getCenter(CenterId) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_CENTER_ADD_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Brand/getCenter?CenterId=' + CenterId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch(setPaketYoldaCenterAddData(json, true));
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_CENTER_ADD_LOADING, payload: false });
    };
}

export async function setBrandPaymentOk(BrandId) {
    return async (dispatch, getState) => {

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Brand/setBrandPaymentOk?BrandId=' + BrandId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                dispatch(setModalPaketYolda(response.data.responseData))
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function getBrand(BrandId) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_BRAND_ADD_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Brand/getBrand?BrandId=' + BrandId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch(setPaketYoldaBrandAddData(json, true));
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_BRAND_ADD_LOADING, payload: false });
    };
}

export async function getUser(UserId) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_USER_ADD_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/User/getUser?UserId=' + UserId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch(setPaketYoldaUserAddData(json, true));
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_USER_ADD_LOADING, payload: false });
    };
}

export async function setPaketYoldaNewUser() {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_USER_ADD_LOADING, payload: true });
        const { paketYoldaUserAddData } = getState().get('paketYoldaReducer');

        axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/User/setUserAdd',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaUserAddData,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPaketYoldaNewUserList(paketYoldaUserAddData.BrandId));
                    dispatch(setPaketYoldaUserAddVisible(false));
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: SET_PAKETYOLDA_USER_ADD_LOADING, payload: false });
    }
}

export async function setPaketYoldaCenter() {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_CENTER_ADD_LOADING, payload: true });
        const { paketYoldaCenterAddData } = getState().get('paketYoldaReducer');

        axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/Brand/addCenter',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaCenterAddData,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPaketYoldaCenterList());
                    dispatch(setPaketYoldaCenterAddVisible(false));
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: SET_PAKETYOLDA_CENTER_ADD_LOADING, payload: false });
    }
}

export async function getPyBrandList(BrandId, CenterId) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PY_BRANDLIST_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Brand/getBrandList?BrandId=' + BrandId + '&CenterId=' + CenterId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_PY_BRANDLIST_DATA,
                        payload: json,
                        topbrandid: BrandId,
                        centerid: CenterId
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PY_BRANDLIST_LOADING, payload: false });
    };
}

export async function getNewCourierList() {
    return async (dispatch, getState) => {
        var BrandId = cookie.load('PaketYoldaId');

        dispatch({ type: SET_PAKETYOLDA_COURIERLIST_LOADING, payload: true });
        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Courier/getCourierList?BrandId=' + BrandId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_PAKETYOLDA_COURIERLIST_DATA,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_COURIERLIST_LOADING, payload: false });
    };
}

export async function getCourier(CourierId) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_NEWCOURIER_ADD_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Courier/getCourier?CourierId=' + CourierId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch(setPaketYoldaNewCourierAddData(json, true));
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_NEWCOURIER_ADD_LOADING, payload: false });
    };
}

export async function setCourierAdd() {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_NEWCOURIER_ADD_LOADING, payload: true });
        const { paketYoldaNewCourierAddData } = getState().get('paketYoldaReducer');
        paketYoldaNewCourierAddData.BrandId = cookie.load('PaketYoldaId');
        paketYoldaNewCourierAddData.CenterId = cookie.load('PaketYoldaCenterId');

        axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/Courier/setCourierAdd',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaNewCourierAddData,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getNewCourierList());
                    dispatch(setPaketYoldaNewCourierAddVisible(false));
                } else {
                    dispatch(setModalPaketYolda(response.data.responseData));
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: SET_PAKETYOLDA_NEWCOURIER_ADD_LOADING, payload: false });
    }
}

export async function setCourierRemove(CourierId) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_NEWCOURIER_ADD_LOADING, payload: true });

        axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Courier/setCourierRemove?CourierId=' + CourierId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getNewCourierList());
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: SET_PAKETYOLDA_NEWCOURIER_ADD_LOADING, payload: false });
    }
}

export async function setCourierInActive(CourierId, IsActive) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_NEWCOURIER_ADD_LOADING, payload: true });

        axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Courier/setCourierInActive?CourierId=' + CourierId + '&IsActive=' + IsActive,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getNewCourierList());
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: SET_PAKETYOLDA_NEWCOURIER_ADD_LOADING, payload: false });
    }
}

export async function getSettings() {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_GENERALSETTINGS_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Settings/getSettings?BrandId=' + cookie.load('PaketYoldaId'),
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({ type: SET_PAKETYOLDA_GENERALSETTINGS_DATA, payload: json });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_GENERALSETTINGS_LOADING, payload: false });
    };
}

export async function setSettings() {
    return async (dispatch, getState) => {
        const { paketYoldaGeneralSettingsData } = getState().get('paketYoldaReducer');

        dispatch({ type: SET_PAKETYOLDA_GENERALSETTINGS_LOADING, payload: true });

        let aa = await axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/Settings/setSettings',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaGeneralSettingsData
        })
            .then(function (response) {
                dispatch(setModalPaketYolda(response.data.responseData));
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_GENERALSETTINGS_LOADING, payload: false });
    };
}

export async function getRegionList() {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_REGIONLIST_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Settings/getRegionList?BrandId=' + cookie.load('PaketYoldaId'),
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({ type: SET_PAKETYOLDA_REGIONLIST_DATA, payload: json });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_REGIONLIST_LOADING, payload: false });
    };
}

export async function getPaketYoldaBusinessList(PaketYoldaId) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_BUSINESSLIST_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/getPaketYoldaBrandList?PaketYoldaId=' + PaketYoldaId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_PAKETYOLDA_BUSINESSLIST_DATA,
                        payload: json,
                        brandid: PaketYoldaId,
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_BUSINESSLIST_LOADING, payload: false });
    };
}

export async function setBusinessIsAutomaticSubmission(BusinessId, PaketYoldaId, IsAutomaticSubmission) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_BUSINESSLIST_LOADING, payload: true });

        axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/setBusinessIsAutomaticSubmission?BusinessId=' + BusinessId + '&IsAutomaticSubmission=' + IsAutomaticSubmission,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPaketYoldaBusinessList(PaketYoldaId));
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: SET_PAKETYOLDA_BUSINESSLIST_LOADING, payload: false });
    }
}

export async function setPaketYoldaBrandRemove(BrandId, PaketYoldaId) {
    return async (dispatch, getState) => {

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/setPaketYoldaBrandRemove?BrandId=' + BrandId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPaketYoldaBusinessList(PaketYoldaId));
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setIsPaketYoldaBrand() {
    return async (dispatch, getState) => {
        const { IsAutomaticSubmission, autoComplatedSelected, PaketYoldaId } = getState().get('paketYoldaReducer');

        var data = {
            "PaketYoldaId": PaketYoldaId,
            "BrandId": autoComplatedSelected.Id,
            "IsAutomaticSubmission": IsAutomaticSubmission
        };

        let aa = await axios({
            method: 'POST',
            url: brand.urlSocket + 'api/PaketYolda/setIsPaketYoldaBrand',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: data
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPaketYoldaBusinessList(PaketYoldaId));
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setRegion(data) {
    return async (dispatch, getState) => {
        const { paketYoldaRegionAddData } = getState().get('paketYoldaReducer');

        paketYoldaRegionAddData.BrandId = cookie.load('PaketYoldaId');
        paketYoldaRegionAddData.PolygonJson = JSON.stringify(data);

        let aa = await axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/Settings/setRegion',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaRegionAddData
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(setPaketYoldaRegionAddVisible(false));
                    dispatch(getRegionList());
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function deleteRegion(RegionId) {
    return async (dispatch, getState) => {

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Settings/deleteRegion?RegionId=' + RegionId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getRegionList());
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function getOrderGeneralReport() {
    return async (dispatch, getState) => {
        dispatch({ type: PAKETYOLDA_GENERALREPORT_LOADING, payload: true });
        const { paketYoldaGeneralReportfilter } = getState().get('paketYoldaReducer');
        paketYoldaGeneralReportfilter.BrandId = cookie.load('PaketYoldaId');
        axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/Report/getOrderGeneralReport',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaGeneralReportfilter,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: PAKETYOLDA_GENERALREPORT_SET_DATA,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: PAKETYOLDA_GENERALREPORT_LOADING, payload: false });
    }
}

export async function getOrderGeneralReportList(paketYoldaGeneralReportfilter) {
    return async (dispatch, getState) => {
        dispatch({ type: PAKETYOLDA_GENERALREPORT_LOADING, payload: true });
        paketYoldaGeneralReportfilter.BrandId = cookie.load('PaketYoldaId');

        axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/Report/getOrderGeneralReportList',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaGeneralReportfilter,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: PAKETYOLDA_GENERALREPORT_SET_ORDERDATA,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: PAKETYOLDA_GENERALREPORT_LOADING, payload: false });
    }
}

export async function getLatLngList(paketYoldaOrderMapReportfilter) {
    return async (dispatch, getState) => {
        dispatch({ type: PAKETYOLDA_GENERALREPORT_LOADING, payload: true });
        paketYoldaOrderMapReportfilter.BrandId = cookie.load('PaketYoldaId');
        axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/Report/getLatLngList',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaOrderMapReportfilter,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    json.map((n, i) => {
                        n["TableType"] = (n.EntegrationType == 0 ? "GetirYemek" : (n.EntegrationType == 1 ? "YemekSepetiGlobal" : (n.EntegrationType == 2 ? "TrendyolYemek" : (n.EntegrationType == 3 ? "GoFody" : (n.EntegrationType == 4 ? "MigrosYemek" : "Telefon")))))
                    })
                    dispatch({
                        type: PAKETYOLDA_ORDERMAPREPORT_SET_ORDERDATA,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: PAKETYOLDA_GENERALREPORT_LOADING, payload: false });
    }
}

export async function setJaviPosCourierHubConnection() {
    return async (dispatch, getState) => {
        var BrandId = cookie.load('BrandId');
        const javiPosCourierHubConnection = new HubConnectionBuilder()
            .configureLogging(LogLevel.None)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 300000) {
                        // If we've been reconnecting for less than 60 seconds so far,
                        // wait between 0 and 10 seconds before the next reconnect attempt.
                        return Math.random() * 3000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            })
            .withUrl(brand.urlSocket + "track?BrandId=" + BrandId, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .build();

        await connectJaviPosCourier(javiPosCourierHubConnection, dispatch);

        dispatch({ type: JAVIPOS_COURIER_HUB_CONNECT, payload: javiPosCourierHubConnection });
    }
}

async function connectJaviPosCourier(conn, dispatch) {
    await conn.start().then(() => {
        conn.on('ReceiveMessageHandler', (message) => {
            var json = JSON.parse(message);
            dispatch({
                type: JAVIPOS_COURIER_TRACK_LIST,
                payload: json
            });
        });
    }).catch(e => {
        sleepJaviPosCourier(3000);
        connectJaviPosCourier(conn, dispatch);
    })
}

async function sleepJaviPosCourier(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
}

//Yeni PaketYoldaSon

export async function setPaketYoldaCourierHubConnection() {
    return async (dispatch, getState) => {
        var PaketYoldaId = cookie.load('PaketYoldaId');
        const paketYoldaCourierHubConnection = new HubConnectionBuilder()
            .configureLogging(LogLevel.None)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 300000) {
                        // If we've been reconnecting for less than 60 seconds so far,
                        // wait between 0 and 10 seconds before the next reconnect attempt.
                        return Math.random() * 3000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            })
            .withUrl(brand.urlSocketPaketYolda + "paketyolda?PaketYoldaId=" + PaketYoldaId, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .build();

        await connectPaketYoldaCourier(paketYoldaCourierHubConnection, dispatch);

        dispatch({ type: PAKETYOLDA_COURIER_HUB_CONNECT, payload: paketYoldaCourierHubConnection });
    }
}

async function connectPaketYoldaCourier(conn, dispatch) {
    await conn.start().then(() => {
        conn.on('ReceiveMessageHandler', (message) => {
            var json = JSON.parse(message);
            dispatch({
                type: PAKETYOLDA_COURIER_TRACK_LIST,
                payload: json
            });
        });
    }).catch(e => {
        sleepPendingPackage(3000);
        connectPaketYoldaCourier(conn, dispatch);
    })
}

async function sleepPendingPackage(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
}

export async function setPaketYoldaOrderHubConnection() {
    return async (dispatch, getState) => {
        var PaketYoldaId = cookie.load('PaketYoldaId');
        const paketYoldaOrderHubConnection = new HubConnectionBuilder()
            .configureLogging(LogLevel.None)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 300000) {
                        // If we've been reconnecting for less than 60 seconds so far,
                        // wait between 0 and 10 seconds before the next reconnect attempt.
                        return Math.random() * 3000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            })
            .withUrl(brand.urlSocketPaketYolda + "order?PaketYoldaId=" + PaketYoldaId, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .build();

        await connectPaketYoldaOrder(paketYoldaOrderHubConnection, dispatch);
        dispatch({ type: PAKETYOLDA_ORDER_HUB_CONNECT, payload: paketYoldaOrderHubConnection });
    }
}

async function connectPaketYoldaOrder(conn, dispatch) {
    await conn.start().then(() => {
        conn.on('ReceiveMessageHandlerOrder', (message) => {
            var json = JSON.parse(message);
            dispatch({
                type: PAKETYOLDA_ORDER_LIST_ADD,
                payload: json
            });
        });
    }).catch(e => {
        sleepPendingOrder(3000);
        connectPaketYoldaOrder(conn, dispatch);
    })
}

async function sleepPendingOrder(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
}

export async function getPaketYoldaUserPIN(PIN, type) {
    return async (dispatch, getState) => {
        var data = {
            "PIN": PIN,
            "BrandId": cookie.load("PaketYoldaId")
        };

        if (PIN.length == 0) {
            dispatch(setModalPaketYolda("PIN kodu boş geçilemez"));
            return;
        }

        axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/User/getPaketYoldaUserPIN',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: data,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    if (type == "settings") {
                        dispatch(setPaketYoldaSettingsVisible(true));
                    } else if (type == "courier") {
                        dispatch(setPaketYoldaCourierListVisible(true));
                    } else if (type == "report") {
                        dispatch(setPaketYoldaNewReportVisible(true))
                    }
                } else {
                    dispatch(setModalPaketYolda(response.data.responseData));
                }
                dispatch(setPaketYoldaPinVisible(false, ""));
            })
            .catch(function (error) {
            });
    }
}

export async function getPaketYoldaList() {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/getPaketYoldaList',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_PAKETYOLDA_DATA,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_LOADING, payload: false });
    };
}

export async function getCourierList() {
    return async (dispatch, getState) => {
        var BrandId = cookie.load("PaketYoldaId");

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Courier/getCourierInfoList?PaketYoldaId=' + BrandId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: PAKETYOLDA_COURIER_LIST,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setPaketYoldaCourierRemove(CourierId) {
    return async (dispatch, getState) => {
        var PaketYoldaId = cookie.load("PaketYoldaId");
        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/setPaketYoldaCourierRemove?CourierId=' + CourierId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPaketYoldaCourierList(PaketYoldaId));
                }
                dispatch(setModalPaketYolda(response.data.responseData));
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function getPaketYoldaBrandList(PaketYoldaId) {
    return async (dispatch, getState) => {

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/getPaketYoldaBrandList?PaketYoldaId=' + PaketYoldaId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_PAKETYOLDA_BRAND_LIST,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function getPaketYoldaCourierList(PaketYoldaId) {
    return async (dispatch, getState) => {

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/getPaketYoldaCourierList?PaketYoldaId=' + PaketYoldaId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_PAKETYOLDA_COURIER_LIST,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function getPaketYoldaUserList(PaketYoldaId) {
    return async (dispatch, getState) => {

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/getPaketYoldaUserList?PaketYoldaId=' + PaketYoldaId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_PAKETYOLDA_USER_LIST,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function paketYoldaOrderStatusLoading(loading) {
    return async (dispatch, getState) => {
        await dispatch({
            type: PAKETYOLDA_ORDER_STATUS_LOADING,
            payload: loading
        });
    };
}

export async function SetOrderStatus(paketYoldaOrderList, courier, status) {
    return async (dispatch, getState) => {

        var list = [];
        paketYoldaOrderList.filter(x => x.IsSelected == true && x.OrderStatus == 0).map((n, i) => {
            list.push({
                "OrderId": n.OrderId,
                "PackageStatus": 7,
                "IsStatus": false,
                "ResponseData": "",
                "CourierId": courier.CourierId,
                "CourierName": courier.CourierName,
                "OrderStatus": status,
                "IsPaketYolda": true,
                "AssignmentType": 2,
                "PaketYoldaId": cookie.load("PaketYoldaId")
            })
        })

        axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/Order/SetOrderStatus',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: list,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var jsonData = JSON.parse(response.data.responseData);
                    if (jsonData.filter(x => x.IsStatus == false).length > 0) {
                        dispatch({ type: PAKETYOLDA_ORDER_STATUS_DATA, payload: jsonData });
                    } else {
                        dispatch(setPaketYoldaOrderStatusVisible(false, null))
                    }
                    dispatch(getCourierList());
                }
            })
            .catch(function (error) {
            });
    }
}

export async function setPaketYoldaUser() {
    return async (dispatch, getState) => {
        const { paketYoldaUserAdd, PaketYoldaId } = getState().get('paketYoldaReducer');
        paketYoldaUserAdd.PaketYoldaId = PaketYoldaId;
        axios({
            method: 'POST',
            url: brand.urlSocket + 'api/PaketYolda/setPaketYoldaUser',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaUserAdd,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPaketYoldaUserList(PaketYoldaId));
                }
            })
            .catch(function (error) {
            });
    }
}

export async function setPaketYoldaCourier() {
    return async (dispatch, getState) => {
        const { paketYoldaCourierAddData } = getState().get('paketYoldaReducer');
        var PaketYoldaId = cookie.load("PaketYoldaId");
        paketYoldaCourierAddData.PaketYoldaId = PaketYoldaId;
        axios({
            method: 'POST',
            url: brand.urlSocket + 'api/PaketYolda/setPaketYoldaCourier',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaCourierAddData,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPaketYoldaCourierList(PaketYoldaId));
                }
            })
            .catch(function (error) {
            });
    }
}

export async function getBrandList(value) {
    return async (dispatch, getState) => {

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/getBrandList?value=' + value,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_AUTOCOMPLATED_BRANDLIST,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setPaketYoldaBrand() {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_ADD_LOADING, payload: true });
        const { paketYoldaAddData } = getState().get('paketYoldaReducer');

        axios({
            method: 'POST',
            url: brand.urlSocket + 'api/PaketYolda/setPaketYoldaBrand',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaAddData,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getPaketYoldaList());
                    dispatch(setPaketYoldaAddVisible(false));
                }
            })
            .catch(function (error) {
            });

        dispatch({ type: SET_PAKETYOLDA_ADD_LOADING, payload: false });
    }
}

export async function setOrderCanceled(OrderId) {
    return async (dispatch, getState) => {
        const { paketYoldaOrderStatus } = getState().get('paketYoldaReducer');
        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Order/setOrderCanceled?Id=' + OrderId,
            dataType: 'json'
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getOrderList(paketYoldaOrderStatus));
                }
                dispatch(setModalPaketYolda(response.data.responseData));
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setCreditToBeLoaded(CreditToBeLoaded) {
    return async (dispatch, getState) => {
        var PaketYoldaId = cookie.load("PaketYoldaId");
        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/setCreditToBeLoaded?PaketYoldaId=' + PaketYoldaId + '&CreditToBeLoaded=' + CreditToBeLoaded,
            dataType: 'json'
        })
            .then(function (response) {
                dispatch(setModalPaketYolda(response.data.responseData));
                dispatch(getPaketYoldaBrand());
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function getPaketYoldaBrand() {
    return async (dispatch, getState) => {
        var PaketYoldaId = cookie.load("PaketYoldaId");
        let aa = await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/PaketYolda/getPaketYoldaBrand?PaketYoldaId=' + PaketYoldaId,
            dataType: 'json'
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: SET_PAKETYOLDA_BRAND_DETAIL,
                        payload: json
                    })
                } else {
                    dispatch(setModalPaketYolda(response.data.responseData));
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setOrderUnLoad(OrderId) {
    return async (dispatch, getState) => {
        const { paketYoldaOrderStatus } = getState().get('paketYoldaReducer');
        var PaketYoldaId = cookie.load("PaketYoldaId");
        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Order/setOrderUnLoad?Id=' + OrderId,
            dataType: 'json'
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    dispatch(getOrderList(paketYoldaOrderStatus));
                }
                dispatch(setModalPaketYolda(response.data.responseData));
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function getOrderList(paketYoldaOrderStatus) {
    return async (dispatch, getState) => {
        var PaketYoldaId = cookie.load("PaketYoldaId");
        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Order/GetOrderList?PaketYoldaId=' + PaketYoldaId + '&orderStatus=' + paketYoldaOrderStatus,
            dataType: 'json'
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: PAKETYOLDA_ORDER_LIST,
                        payload: json,
                        paketYoldaOrderStatus: paketYoldaOrderStatus
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function getOrderReport() {
    return async (dispatch, getState) => {
        dispatch({ type: PAKETYOLDA_REPORT_LOADING, payload: true });
        const { paketYoldaFilter } = getState().get('paketYoldaReducer');
        paketYoldaFilter.PaketYoldaId = cookie.load("PaketYoldaId");
        axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + (paketYoldaFilter.IsList ? 'api/Report/getOrderListReport' : 'api/Report/getOrderReport'),
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: paketYoldaFilter,
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    if (paketYoldaFilter.IsList) {
                        dispatch({ type: PAKETYOLDA_REPORT_DATA, payload: json.Orders, payment: json.PaymentData });
                    } else {
                        dispatch({ type: PAKETYOLDA_REPORT_DATA, payload: json, payment: [] });
                    }
                }
            })
            .catch(function (error) {
            });
        dispatch({ type: PAKETYOLDA_REPORT_LOADING, payload: false });
    }
}

export async function setPaketYoldaData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_REPORT_DATA,
            payload: data
        });
    };
}

export async function setPaketYoldaVisible(visible) {
    return async (dispatch, getState) => {
        if (visible) {
            await dispatch(getPaketYoldaList());
        }
        dispatch({
            type: SET_PAKETYOLDA_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaAddVisible(visible) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_ADD_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaAddData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_ADD_DATA,
            payload: data
        });
    };
}

export async function setPaketYoldaUserLoginData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_USERLOGIN_DATA,
            payload: data
        });
    };
}

export async function setAutoComplatedValue(value) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_AUTOCOMPLATED_VALUE,
            payload: value
        });
    };
}

export async function setAutoComplatedSelected(value) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_AUTOCOMPLATED_SELECTED,
            payload: value
        });
        dispatch({
            type: SET_AUTOCOMPLATED_VALUE,
            payload: value.Name
        });
    };
}

export async function setPaketYoldaBrandVisible(visible, PaketYoldaId = "") {
    return async (dispatch, getState) => {
        if (visible) {
            await dispatch(getPaketYoldaBrandList(PaketYoldaId));
        }
        dispatch({
            type: SET_PAKETYOLDA_BRAND_VISIBLE,
            payload: visible,
            paketyoldaid: PaketYoldaId
        });
    };
}

export async function setPaketYoldaUserVisible(visible, PaketYoldaId = "") {
    return async (dispatch, getState) => {
        if (visible) {
            await dispatch(getPaketYoldaUserList(PaketYoldaId));
        }
        dispatch({
            type: SET_PAKETYOLDA_USER_VISIBLE,
            payload: visible,
            paketyoldaid: PaketYoldaId
        });
    };
}

export async function modalClosePaketYolda() {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_MODALCLOSE
        });
    };
}

export async function setModalPaketYolda(text) {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_ERROR,
            payload: text
        });
    };
}

export async function setPaketYoldaUserAdd(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_USER_ADD,
            payload: data
        });
    };
}

export async function setPaketYoldaOrderUpdate(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_ORDER_UPDATE
        });
    };
}

export async function setPaketYoldaCourierVisible(visible) {
    return async (dispatch, getState) => {
        if (visible) {
            await dispatch(getCourierList());
        }
        dispatch({
            type: PAKETYOLDA_COURIER_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaOrderStatusVisible(visible, courier) {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_ORDER_STATUS_VISIBLE,
            payload: visible,
            courier: courier
        });
    };
}

export async function setPaketYoldaPanelPageUpdate() {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_PANELPAGE_UPDATE
        });
    };
}

export async function setPaketYoldaBrandListVisible(visible) {
    return async (dispatch, getState) => {
        if (visible) {
            dispatch(getPaketYoldaBrand());
        }
        dispatch({
            type: PAKETYOLDA_BRANDLIST_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaReportVisible(visible, type) {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_REPORT_VISIBLE,
            payload: visible,
            reporttype: type
        });
    };
}

export async function setPaketYoldaReportFilterData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_REPORT_FILTER_DATA,
            payload: data
        });
    };
}

export async function setPaketYoldaCourierAddVisible(visible, PaketYoldaId = "") {
    return async (dispatch, getState) => {
        if (visible) {
            await dispatch(getPaketYoldaCourierList(PaketYoldaId));
        }
        dispatch({
            type: SET_PAKETYOLDA_COURIER_ADD_VISIBLE,
            payload: visible,
            paketyoldaid: PaketYoldaId
        });
    };
}

export async function setCourierAddVisible(visible, PaketYoldaId = "") {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_COURIER_ADD_VISIBLE,
            payload: visible,
            paketyoldaid: PaketYoldaId
        });
    };
}

export async function setPaketYoldaCourierAddUpdate() {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_COURIER_ADD_UPDATE
        });
    };
}

export async function setPaketYoldaCourierAdd(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_COURIER_ADD,
            payload: data
        });
    };
}

export async function setOrderCompleted(CourierId) {
    return async (dispatch, getState) => {

        await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Order/setOrderCompleted?CourierId=' + CourierId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                dispatch(getOrderReport());
                dispatch(setModalPaketYolda(response.data.responseData));

            })
            .catch(function (error) {
                alert(error);
            });
    };
}

export async function setPaketYoldaAutoSend(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_BRAND_AUTOSEND,
            payload: data
        });
    };
}

export async function setPaketYoldaBrandListUpdate() {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_BRAND_UPDATE
        });
    };
}

export async function setPaketYoldaPinVisible(visible, pintype) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_PIN_LOGIN,
            payload: visible,
            pintype: (visible ? pintype : "")
        });
    };
}

//PaketYoldaYeni
export async function setPyBrandListVisible(visible, BrandId = "00000000-0000-0000-0000-000000000000", CenterId = "00000000-0000-0000-0000-000000000000", IsCenter = true) {
    return async (dispatch, getState) => {
        if (visible) {
            await dispatch(getPyBrandList(BrandId, CenterId));
        }
        dispatch({
            type: SET_PY_BRANDLIST_VISIBLE,
            payload: visible,
            centerid: CenterId,
            topbrandid: BrandId,
            iscenter: IsCenter
        });
    };
}

export async function setPaketYoldaCenterListVisible(visible) {
    return async (dispatch, getState) => {
        if (visible) {
            await dispatch(getPaketYoldaCenterList());
        }
        dispatch({
            type: SET_PAKETYOLDA_CENTERLIST_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaUserListVisible(visible, BrandId = "", CenterId = "") {
    return async (dispatch, getState) => {
        if (visible) {
            await dispatch(getPaketYoldaNewUserList(BrandId));
        }
        dispatch({
            type: SET_PAKETYOLDA_USERLIST_VISIBLE,
            payload: visible,
            brandid: BrandId,
            centerid: CenterId
        });
    };
}

export async function setPaketYoldaCenterAddVisible(visible) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_CENTER_ADD_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaCenterAddData(data, IsVisible = false) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_CENTER_ADD_DATA,
            payload: data,
            isvisible: IsVisible
        });
    };
}

export async function setPaketYoldaBrandAddData(data, IsVisible = false) {
    return async (dispatch, getState) => {
        if (IsVisible) {
            await dispatch(getAddressList("Il", ""));
            await dispatch(getAddressList("Ilce", data.IlId));
        }
        dispatch({
            type: SET_PAKETYOLDA_BRAND_ADD_DATA,
            payload: data,
            isvisible: IsVisible
        });
    };
}

export async function setPaketYoldaUserAddData(data, IsVisible = false) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_USER_ADD_DATA,
            payload: data,
            isvisible: IsVisible
        });
    };
}

export async function setPaketYoldaBrandAddVisible(visible) {
    return async (dispatch, getState) => {
        await dispatch(getAddressList("Il", ""));
        dispatch({
            type: SET_PAKETYOLDA_BRAND_ADD_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaUserAddVisible(visible) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_USER_ADD_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaCourierListVisible(visible) {
    return async (dispatch, getState) => {
        if (visible) {
            dispatch(getNewCourierList())
        }
        dispatch({
            type: SET_PAKETYOLDA_COURIERLIST_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaNewCourierAddVisible(visible) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_NEWCOURIER_ADD_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaRegionAddVisible(visible) {
    return async (dispatch, getState) => {
        if (visible) {
            var PaketYoldaId = cookie.load('PaketYoldaId');
            await dispatch(getNewCourierList());
            await dispatch(getPaketYoldaBusinessList(PaketYoldaId));
        }
        dispatch({
            type: SET_PAKETYOLDA_REGION_ADD_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaNewCourierAddData(data, IsVisible = false) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_NEWCOURIER_ADD_DATA,
            payload: data,
            isvisible: IsVisible
        });
    };
}

export async function setPaketYoldaSettingsVisible(visible) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_SETTINGS_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaSettingsData(data) {
    return async (dispatch, getState) => {
        const { paketYoldaRegionListData } = getState().get('paketYoldaReducer');
        if (data == "GeneralSetttings" || data == "AssignmentSettings") {
            dispatch(getSettings());
        } else if (data == "RegionSettings") {
            if (paketYoldaRegionListData == null) {
                await dispatch(getRegionList());
            }
        } else if (data == "BusinessSettings") {
            var PaketYoldaId = cookie.load('PaketYoldaId');
            dispatch(getPaketYoldaBusinessList(PaketYoldaId));
        }
        dispatch({
            type: SET_PAKETYOLDA_SETTINGS_DATA,
            payload: data
        });
    };
}

export async function setPaketYoldaGeneralSettingsData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_GENERALSETTINGS_DATA,
            payload: data
        });
    };
}

export async function setPaketYoldaBusinessListVisible(visible, BrandId = "00000000-0000-0000-0000-000000000000") {
    return async (dispatch, getState) => {
        if (visible) {
            await dispatch(getPaketYoldaBusinessList(BrandId));
        }
        dispatch({
            type: SET_PAKETYOLDA_BUSINESSLIST_VISIBLE,
            payload: visible,
            brandid: BrandId
        });
    };
}

export async function setPaketYoldaRegionAddData(data, IsVisible = false) {
    return async (dispatch, getState) => {
        if (IsVisible) {
            var PaketYoldaId = cookie.load('PaketYoldaId');
            await dispatch(getNewCourierList());
            await dispatch(getPaketYoldaBusinessList(PaketYoldaId));
        }
        dispatch({
            type: SET_PAKETYOLDA_REGION_ADD_DATA,
            payload: data,
            isvisible: IsVisible
        });
    };
}

export async function setPaketYoldaNewReportVisible(visible) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_REPORT_VISIBLE,
            payload: visible
        });
    };
}

export async function setPaketYoldaReportData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_REPORT_DATA,
            payload: data
        });
    };
}

export async function setPaketYoldaGeneralReportFilterData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_GENERALREPORT_FILTER_DATA,
            payload: data
        });
    };
}

export async function setPaketYoldaMainPageUpdate() {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_MAINPAGE_UPDATE
        });
    };
}

export async function setRegionListClear() {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAKETYOLDA_REGIONLIST_DATA,
            payload: null
        });
    };
}

export async function setAnchorEntegration(anchor) {
    return async (dispatch, getState) => {
        dispatch({
            type: ANCHOR_ENTEGRATION,
            payload: anchor
        });
    };
}

export async function setPaketYoldaLocationVisible(visible, CourierId) {
    return async (dispatch, getState) => {
        if (visible) {
            dispatch(GetCourierAllOrderList(CourierId))
        }
        dispatch({
            type: PAKETYOLDA_LOCATION_VISIBLE,
            payload: visible
        });
    };
}

export async function automaticAssignmentControl() {
    return async (dispatch, getState) => {
        var BrandId = cookie.load('PaketYoldaId');

        await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Order/automaticAssignmentControl?BrandId=' + BrandId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {

            })
            .catch(function (error) {
            });
    };
}

export async function setCourierInfoRemove(CourierId) {
    return async (dispatch, getState) => {

        await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Courier/setCourierInfoRemove?CourierId=' + CourierId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                dispatch(getCourierList())
                dispatch(setModalPaketYolda(response.data.responseData));
            })
            .catch(function (error) {
            });
    };
}

export async function GetCourierAllOrderList(CourierId) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_PAKETYOLDA_USERLIST_LOADING, payload: true });

        let aa = await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Order/GetCourierAllOrderList?CourierId=' + CourierId,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: PAKETYOLDA_LOCATION_DATA,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
                alert(error);
            });
        dispatch({ type: SET_PAKETYOLDA_USERLIST_LOADING, payload: false });
    };
}

export async function setPaketYoldaCounterUpdate() {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_COUNTER_UPDATE
        });
    };
}

export async function getMileageCharge(ProcessId, ProcessType) {
    return async (dispatch, getState) => {
        var BrandId = cookie.load('PaketYoldaId');

        await axios({
            method: 'GET',
            url: brand.urlSocketPaketYolda + 'api/Settings/getMileageCharge?BrandId=' + BrandId + '&ProcessId=' + ProcessId + '&ProcessType=' + ProcessType,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                if (response.data.isStatus) {
                    var json = JSON.parse(response.data.responseData);
                    dispatch({
                        type: PAKETYOLDA_MILEAGECHARGE_DATA,
                        payload: json
                    });
                }
            })
            .catch(function (error) {
            });
    };
}

export async function setMileageCharge(ProcessId, ProcessType) {
    return async (dispatch, getState) => {
        const { mileageChargeData } = getState().get('paketYoldaReducer');

        await axios({
            method: 'POST',
            url: brand.urlSocketPaketYolda + 'api/Settings/setMileageCharge?BrandId=',
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
            data: mileageChargeData
        })
            .then(function (response) {
                dispatch(setModalPaketYolda(response.data.responseData));

            })
            .catch(function (error) {
            });
    };
}

export async function setBrandPreparationTime() {
    return async (dispatch, getState) => {
        const { brandSettingsData } = getState().get('paketYoldaReducer');

        await axios({
            method: 'GET',
            url: brand.urlSocket + 'api/Settings/setBrandPreparationTime?BrandId='+brandSettingsData.BrandId + '&preparationTime=' + brandSettingsData.PreparationTime,
            dataType: 'json',
            headers: {
                'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
            },
        })
            .then(function (response) {
                dispatch(setPaketYoldaBrandSettingsVisible(false,null))
                dispatch(getPaketYoldaBusinessList(cookie.load("PaketYoldaId")));
                dispatch(setModalPaketYolda(response.data.responseData));

            })
            .catch(function (error) {
            });
    };
}

export async function setMileageChargeVisible(visible, ProcessId, ProcessType) {
    return async (dispatch, getState) => {
        if (visible) {
            dispatch(getMileageCharge(ProcessId, ProcessType))
        }
        dispatch({
            type: PAKETYOLDA_MILEAGECHARGE_VISIBLE,
            payload: visible,
            process: ProcessType
        });
    };
}

export async function setPaketYoldaBrandSettingsVisible(visible, data) {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_BRAND_SETTINGS_VISIBLE,
            payload: visible,
            brand: JSON.parse(JSON.stringify(data))
        });
    };
}

export async function setPaketYoldaBrandSettingsData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_BRAND_SETTINGS_DATA,
            payload: data
        });
    };
}


export async function setMileageChargeData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: PAKETYOLDA_MILEAGECHARGE_DATA,
            payload: data
        });
    };
}
//PaketYoldaSon



const paketYoldaReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAKETYOLDA_BRAND_SETTINGS_DATA:
            return { ...state, brandSettingsData: action.payload, paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate }
        case PAKETYOLDA_BRAND_SETTINGS_VISIBLE:
            return { ...state, brandSettingsData: action.brand, brandSettingsVisible: action.payload, paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate }
        case PAKETYOLDA_MILEAGECHARGE_DATA:
            return { ...state, mileageChargeData: action.payload, mileageChargeUpdate: !state.mileageChargeUpdate }
        case PAKETYOLDA_MILEAGECHARGE_VISIBLE:
            return { ...state, mileageChargeType: action.process, mileageChargeVisible: action.payload, mileageChargeUpdate: !state.mileageChargeUpdate }
        case HUB_USER_SYSTEM_STOP:
            return { ...state, loading: false, systemUserHubConnection: action.payload }
        //PaketYoldaYeni
        case PAKETYOLDA_COUNTER_UPDATE:
            return { ...state, IsPaketYoldaCounterUpdate: !state.IsPaketYoldaCounterUpdate }
        case PAKETYOLDA_LOCATION_VISIBLE:
            return { ...state, paketYoldaLocationVisible: action.payload, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case PAKETYOLDA_LOCATION_DATA:
            return { ...state, paketYoldaLocationData: action.payload, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case ANCHOR_ENTEGRATION:
            return { ...state, anchorElEntegrationDetail: action.payload, paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate }
        case PAKETYOLDA_ORDERMAPREPORT_SET_ORDERDATA:
            return { ...state, paketYoldaOrderMapReportData: action.payload, paketYoldaOrderMapReportUpdate: !state.paketYoldaOrderMapReportUpdate }
        case PAKETYOLDA_GENERALREPORT_SET_ORDERDATA:
            return { ...state, generalReportOrderList: action.payload, paketYoldaGeneralReportUpdate: !state.paketYoldaGeneralReportUpdate }
        case PAKETYOLDA_GENERALREPORT_SET_DATA:
            return { ...state, paketYoldaGeneralReportData: action.payload, paketYoldaGeneralReportUpdate: !state.paketYoldaGeneralReportUpdate, IsPaketYoldaMainPageUpdate: !state.IsPaketYoldaMainPageUpdate }
        case PAKETYOLDA_GENERALREPORT_LOADING:
            return {
                ...state, paketYoldaNewReportLoading: action.payload,
                paketYoldaGeneralReportUpdate: !state.paketYoldaGeneralReportUpdate,
                paketYoldaOrderMapReportUpdate: !state.paketYoldaOrderMapReportUpdate
            }
        case SET_PAKETYOLDA_MAINPAGE_UPDATE:
            return { ...state, IsPaketYoldaMainPageUpdate: !state.IsPaketYoldaMainPageUpdate }
        case PAKETYOLDA_GENERALREPORT_FILTER_DATA:
            return { ...state, paketYoldaGeneralReportfilter: action.payload, paketYoldaGeneralReportUpdate: !state.paketYoldaGeneralReportUpdate }
        case SET_PAKETYOLDA_REPORT_DATA:
            return {
                ...state, paketYoldaReportMenu: action.payload, paketYoldaReportUpdate: !state.paketYoldaReportUpdate,
                paketYoldaGeneralReportUpdate: !state.paketYoldaGeneralReportUpdate,
                paketYoldaOrderMapReportUpdate: !state.paketYoldaOrderMapReportUpdate
            }
        case SET_PAKETYOLDA_REPORT_VISIBLE:
            return { ...state, paketYoldaReportVisible: action.payload, paketYoldaReportUpdate: !state.paketYoldaReportUpdate }
        case SET_PAKETYOLDA_REGION_ADD_DATA:
            return { ...state, paketYoldaRegionAddVisible: (action.isvisible == true ? true : state.paketYoldaUserAddVisible), paketYoldaRegionAddData: action.payload, paketYoldaRegionAddUpdate: !state.paketYoldaRegionAddUpdate }
        case SET_PAKETYOLDA_BRAND_AUTOSEND:
            return { ...state, IsAutomaticSubmission: action.payload, paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate }
        case SET_AUTOCOMPLATED_SELECTED:
            return { ...state, autoComplatedSelected: action.payload, paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate }
        case SET_AUTOCOMPLATED_VALUE:
            return { ...state, autoComplatedValue: action.payload, paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate }
        case SET_AUTOCOMPLATED_BRANDLIST:
            return { ...state, autoComplatedBrandList: action.payload, paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate }
        case SET_PAKETYOLDA_BUSINESSLIST_LOADING:
            return { ...state, paketYoldaBusinessListLoading: action.payload, paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate }
        case SET_PAKETYOLDA_BUSINESSLIST_DATA:
            return { ...state, paketYoldaBusinessList: action.payload, paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate }
        case SET_PAKETYOLDA_BUSINESSLIST_VISIBLE:
            return {
                ...state,
                autoComplatedSelected: null, autoComplatedValue: "", PaketYoldaId: action.brandid, paketYoldaBusinessListVisible: action.payload, paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate
            }
        case SET_PAKETYOLDA_REGION_ADD_VISIBLE:
            return {
                ...state, paketYoldaRegionAddData: {
                    Id: "00000000-0000-0000-0000-000000000000",
                    BrandId: "00000000-0000-0000-0000-000000000000",
                    PolygonJson: "",
                    CreationTime: new Date(),
                    RegionName: "",
                    RegionCourier: [],
                    RegionBusiness: []
                }, paketYoldaRegionAddVisible: action.payload, paketYoldaRegionAddUpdate: !state.paketYoldaRegionAddUpdate
            }
        case SET_PAKETYOLDA_REGIONLIST_LOADING:
            return { ...state, paketYoldaRegionListLoading: action.payload, paketYoldaRegionListUpdate: !state.paketYoldaRegionListUpdate }
        case SET_PAKETYOLDA_REGIONLIST_DATA:
            return { ...state, paketYoldaRegionListData: action.payload, paketYoldaRegionListUpdate: !state.paketYoldaRegionListUpdate, IsPaketYoldaPanelUpdate: !state.IsPaketYoldaPanelUpdate }
        case SET_PAKETYOLDA_GENERALSETTINGS_LOADING:
            return { ...state, paketYoldaGeneralSettingsLoading: action.payload, paketYoldaGeneralSettingsUpdate: !state.paketYoldaGeneralSettingsUpdate }
        case SET_PAKETYOLDA_GENERALSETTINGS_DATA:
            return { ...state, paketYoldaGeneralSettingsData: action.payload, paketYoldaGeneralSettingsUpdate: !state.paketYoldaGeneralSettingsUpdate }
        case SET_PAKETYOLDA_SETTINGS_DATA:
            return {
                ...state, paketYoldaSettingsMenu: action.payload, paketYoldaSettingsUpdate: !state.paketYoldaSettingsUpdate,
                paketYoldaGeneralSettingsUpdate: !state.paketYoldaGeneralSettingsUpdate,
                paketYoldaRegionListUpdate: !state.paketYoldaRegionListUpdate,
                paketYoldaBusinessListUpdate: !state.paketYoldaBusinessListUpdate
            }
        case SET_PAKETYOLDA_SETTINGS_VISIBLE:
            return { ...state, paketYoldaSettingsMenu: '', paketYoldaSettingsVisible: action.payload, paketYoldaSettingsUpdate: !state.paketYoldaSettingsUpdate }
        case SET_PAKETYOLDA_CENTERLIST_LOADING:
            return { ...state, paketYoldaCenterListLoading: action.payload, paketYoldaCenterListUpdate: !state.paketYoldaCenterListUpdate }
        case SET_PAKETYOLDA_CENTERLIST_DATA:
            return { ...state, paketYoldaCenterList: action.payload, paketYoldaCenterListUpdate: !state.paketYoldaCenterListUpdate }
        case SET_PAKETYOLDA_CENTERLIST_VISIBLE:
            return { ...state, paketYoldaCenterListVisible: action.payload, paketYoldaCenterListUpdate: !state.paketYoldaCenterListUpdate }
        case SET_PAKETYOLDA_CENTER_ADD_VISIBLE:
            return {
                ...state, paketYoldaCenterAddData: {
                    Id: "00000000-0000-0000-0000-000000000000",
                    Email: "",
                    Password: "",
                    IsActive: true,
                    AuthorizedPerson: "",
                    Phone: ""
                }, paketYoldaCenterAddVisible: action.payload, paketYoldaCenterAddUpdate: !state.paketYoldaCenterAddUpdate
            }
        case SET_PAKETYOLDA_CENTER_ADD_LOADING:
            return { ...state, paketYoldaCenterAddLoading: action.payload, paketYoldaCenterAddUpdate: !state.paketYoldaCenterAddUpdate }
        case SET_PAKETYOLDA_CENTER_ADD_DATA:
            return { ...state, paketYoldaCenterAddVisible: (action.isvisible == true ? true : state.paketYoldaCenterAddVisible), paketYoldaCenterAddData: action.payload, paketYoldaCenterAddUpdate: !state.paketYoldaCenterAddUpdate }
        case SET_PY_BRANDLIST_LOADING:
            return { ...state, pyBrandListLoading: action.payload, pyBrandListUpdate: !state.pyBrandListUpdate }
        case SET_PY_BRANDLIST_DATA:
            return { ...state, pySubBrandList: (action.topbrandid != "00000000-0000-0000-0000-000000000000" ? action.payload : state.pyBrandList), pyBrandList: (action.topbrandid == "00000000-0000-0000-0000-000000000000" ? action.payload : state.pyBrandList), pyBrandListUpdate: !state.pyBrandListUpdate, pySubBrandListUpdate: !state.pySubBrandListUpdate }
        case SET_PY_BRANDLIST_VISIBLE:
            return {
                ...state,
                paketYoldaBrandAddData: {
                    Id: "00000000-0000-0000-0000-000000000000",
                    CompanyTitle: "",
                    BrandName: "",
                    AuthorizedPerson: "",
                    Phone: "",
                    Address: "",
                    TaxNumber: "",
                    TaxAdministration: "",
                    Explanation: "",
                    IsOfficialDistributor: true,
                    BrandId: action.topbrandid,
                    CenterId: action.centerid,
                    IlId: "",
                    IlceIds: [],
                    IsActive: true,
                    CreditToBeLoaded: 10000
                }, pyBrandListVisible: (action.iscenter ? action.payload : state.pyBrandListVisible), pyBrandListUpdate: !state.pyBrandListUpdate,
                pySubBrandListVisible: (!action.iscenter ? action.payload : state.pySubBrandListVisible), pySubBrandListUpdate: !state.pySubBrandListUpdate
            }
        case SET_PAKETYOLDA_BRAND_ADD_VISIBLE:
            return {
                ...state, paketYoldaBrandAddData: {
                    Id: "00000000-0000-0000-0000-000000000000",
                    CompanyTitle: "",
                    BrandName: "",
                    AuthorizedPerson: "",
                    Phone: "",
                    Address: "",
                    TaxNumber: "",
                    TaxAdministration: "",
                    Explanation: "",
                    IsOfficialDistributor: true,
                    BrandId: state.paketYoldaBrandAddData.BrandId,
                    CenterId: state.paketYoldaBrandAddData.CenterId,
                    IlId: "",
                    IlceIds: [],
                    IsActive: true,
                    CreditToBeLoaded: 10000
                }, paketYoldaBrandAddVisible: action.payload, paketYoldaBrandAddUpdate: !state.paketYoldaBrandAddUpdate
            }
        case SET_PAKETYOLDA_BRAND_ADD_LOADING:
            return { ...state, paketYoldaBrandAddLoading: action.payload, paketYoldaBrandAddUpdate: !state.paketYoldaBrandAddUpdate }
        case SET_PAKETYOLDA_BRAND_ADD_DATA:
            return { ...state, paketYoldaBrandAddVisible: (action.isvisible == true ? true : state.paketYoldaBrandAddVisible), paketYoldaBrandAddData: action.payload, paketYoldaBrandAddUpdate: !state.paketYoldaBrandAddUpdate }
        case SET_PAKETYOLDA_USERLIST_LOADING:
            return { ...state, paketYoldaNewUserListLoading: action.payload, paketYoldaNewUserListUpdate: !state.paketYoldaNewUserListUpdate }
        case SET_PAKETYOLDA_USERLIST_DATA:
            return { ...state, paketYoldaNewUserList: action.payload, paketYoldaNewUserListUpdate: !state.paketYoldaNewUserListUpdate }
        case SET_PAKETYOLDA_USERLIST_VISIBLE:
            return {
                ...state, paketYoldaUserAddData: {
                    Id: "00000000-0000-0000-0000-000000000000",
                    IsActive: true,
                    Username: "",
                    Password: "",
                    PIN: "",
                    BrandId: action.brandid,
                    CenterId: action.centerid,
                }, paketYoldaNewUserListVisible: action.payload, paketYoldaNewUserListUpdate: !state.paketYoldaNewUserListUpdate
            }
        case SET_PAKETYOLDA_USER_ADD_VISIBLE:
            return {
                ...state, paketYoldaUserAddData: {
                    Id: "00000000-0000-0000-0000-000000000000",
                    Username: "",
                    Password: "",
                    PIN: "",
                    IsActive: true,
                    BrandId: state.paketYoldaUserAddData.BrandId,
                    CenterId: state.paketYoldaUserAddData.CenterId
                }, paketYoldaUserAddVisible: action.payload, paketYoldaUserAddUpdate: !state.paketYoldaUserAddUpdate
            }
        case SET_PAKETYOLDA_USER_ADD_LOADING:
            return { ...state, paketYoldaUserAddLoading: action.payload, paketYoldaUserAddUpdate: !state.paketYoldaUserAddUpdate }
        case SET_PAKETYOLDA_USER_ADD_DATA:
            return { ...state, paketYoldaUserAddVisible: (action.isvisible == true ? true : state.paketYoldaUserAddVisible), paketYoldaUserAddData: action.payload, paketYoldaUserAddUpdate: !state.paketYoldaUserAddUpdate }
        case SET_PAKETYOLDA_COURIERLIST_VISIBLE:
            return { ...state, paketYoldaCourierListVisible: action.payload, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case SET_PAKETYOLDA_COURIERLIST_DATA:
            return { ...state, paketYoldaCourierList: action.payload, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case SET_PAKETYOLDA_COURIERLIST_LOADING:
            return { ...state, paketYoldaCourierListLoading: action.payload, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case SET_PAKETYOLDA_NEWCOURIER_ADD_VISIBLE:
            return {
                ...state, paketYoldaNewCourierAddData: {
                    Id: "00000000-0000-0000-0000-000000000000",
                    IsActive: true,
                    IsReceivingPackage: false,
                    IsRevokeAuthorization: false,
                    NameSurname: "",
                    Phone: "",
                    AllowedTime: 30,
                    Password: "",
                    BrandId: "00000000-0000-0000-0000-000000000000",
                    CenterId: "00000000-0000-0000-0000-000000000000"
                }, paketYoldaNewCourierAddVisible: action.payload, paketYoldaNewCourierAddUpdate: !state.paketYoldaNewCourierAddUpdate
            }
        case SET_PAKETYOLDA_NEWCOURIER_ADD_LOADING:
            return { ...state, paketYoldaNewCourierAddLoading: action.payload, paketYoldaNewCourierAddUpdate: !state.paketYoldaNewCourierAddUpdate }
        case SET_PAKETYOLDA_NEWCOURIER_ADD_DATA:
            return { ...state, paketYoldaNewCourierAddVisible: (action.isvisible == true ? true : state.paketYoldaNewCourierAddVisible), paketYoldaNewCourierAddData: action.payload, paketYoldaNewCourierAddUpdate: !state.paketYoldaNewCourierAddUpdate }
        //PaketYoldaSon





        case SET_PAKETYOLDA_BRAND_DETAIL:
            return { ...state, paketYoldaBrandDetail: action.payload, paketYoldaBrandListUpdate: !state.paketYoldaBrandListUpdate }
        case SET_PAKETYOLDA_PIN_LOGIN:
            return { ...state, paketYoldaPINLoginType: action.pintype, paketYoldaPINLogin: action.payload, IsPaketYoldaMainPageUpdate: !state.IsPaketYoldaMainPageUpdate }
        case SET_PAKETYOLDA_ADDITION_REPORT:
            return { ...state, paketYoldaAdditionReportData: action.payload }
        case SET_PAKETYOLDA_COURIER_ADD_UPDATE:
            return { ...state, paketYoldaCourierAddUpdate: !state.paketYoldaCourierAddUpdate }
        case SET_PAKETYOLDA_BRAND_UPDATE:
            return { ...state, paketYoldaBrandUpdate: !state.paketYoldaBrandUpdate }
        case SET_PAKETYOLDA_COURIER_ADD_VISIBLE:
            return { ...state, PaketYoldaId: action.paketyoldaid, paketYoldaCourierAddVisible: action.payload, paketYoldaCourierAddUpdate: !state.paketYoldaCourierAddUpdate }
        case SET_COURIER_ADD_VISIBLE:
            return { ...state, PaketYoldaId: action.paketyoldaid, courierAddVisible: action.payload, paketYoldaCourierAddUpdate: !state.paketYoldaCourierAddUpdate }
        case SET_PAKETYOLDA_COURIER_ADD:
            return { ...state, paketYoldaCourierAddData: action.payload, paketYoldaCourierAddUpdate: !state.paketYoldaCourierAddUpdate }
        case PAKETYOLDA_REPORT_LOADING:
            return { ...state, paketYoldaReportLoading: action.payload, paketYoldaReportUpdate: !state.paketYoldaReportUpdate }
        case PAKETYOLDA_REPORT_DATA:
            return { ...state, paketYoldaReportDataPayment: action.payment, paketYoldaReportData: action.payload, paketYoldaReportUpdate: !state.paketYoldaReportUpdate }
        case PAKETYOLDA_REPORT_FILTER_DATA:
            return { ...state, paketYoldaFilter: action.payload, paketYoldaReportUpdate: !state.paketYoldaReportUpdate }
        case PAKETYOLDA_BRANDLIST_VISIBLE:
            return { ...state, paketYoldaBrandListVisible: action.payload, paketYoldaBrandListUpdate: !state.paketYoldaBrandListUpdate }
        case PAKETYOLDA_REPORT_VISIBLE:
            return {
                ...state, paketYoldaReportDataPayment: [], paketYoldaReportData: [], paketYoldaFilter: {
                    StartDatetime: new Date(),
                    EndDatetime: new Date(),
                    BrandId: "00000000-0000-0000-0000-000000000000",
                    CourierId: "00000000-0000-0000-0000-000000000000",
                    IsList: (action.reporttype == "report" ? false : true),
                    ReportType: action.reporttype,
                    OrderStatus: (action.reporttype == "completed" ? 5 : 2),
                    IsCompare: false
                }, paketYoldaReportVisible: action.payload, paketYoldaReportUpdate: !state.paketYoldaReportUpdate
            }
        case PAKETYOLDA_PANELPAGE_UPDATE:
            return { ...state, IsPaketYoldaPanelUpdate: !state.IsPaketYoldaPanelUpdate, paketYoldaOrderListUpdate: !state.paketYoldaOrderListUpdate, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case PAKETYOLDA_ORDER_STATUS_VISIBLE:
            return { ...state, paketYoldaOrderCourierSelected: action.courier, paketYoldaOrderStatusVisible: action.payload, paketYoldaOrderStatusData: [], paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case PAKETYOLDA_ORDER_STATUS_LOADING:
            return { ...state, paketYoldaOrderStatusLoading: action.payload, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case PAKETYOLDA_ORDER_STATUS_DATA:
            return { ...state, paketYoldaOrderStatusData: action.payload, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case PAKETYOLDA_COURIER_VISIBLE:
            return { ...state, paketYoldaCourierListVisible: action.payload, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case PAKETYOLDA_ORDER_LIST_ADD:
            {
                var orderList = state.paketYoldaOrderList;
                var order = action.payload;

                var paketYoldaOrderStatus = state.paketYoldaOrderStatus;
                if (!Array.isArray(order)) {
                    if (paketYoldaOrderStatus == 0 && order.PackageStatus != 5) {
                        if (orderList.filter(x => x.Id == order.Id).length == 0) {
                            order.CreationTime = order.CreationTime.replace('Z', '')
                            orderList.push(order);
                            soundPlay("play")
                            workerTimers.setTimeout(() => {
                                soundPlay("stop")
                            }, 2000);
                        } else {
                            orderList.find(x => x.Id == order.Id).IsStatusForAssignment = order.IsStatusForAssignment;
                        }
                    } else if (order.PackageStatus == 5) {
                        const list = [
                            {
                                ResponseData: order.RestName + ' işletmesinin ' + order.CustomerName + ' isimli siparişi işletme tarafından iptal edilmiştir!',
                                IsStatus: false
                            }
                        ];
                        notificationShow(list, 180000);

                        orderList.find(x => x.Id == order.Id).OrderStatus = order.OrderStatus;
                        orderList.find(x => x.Id == order.Id).PackageStatus = order.PackageStatus;
                        orderList.find(x => x.Id == order.Id).CourierId = order.CourierId;
                        orderList.find(x => x.Id == order.Id).CourierName = order.CourierName;
                    }
                } else {
                    order.map((n, i) => {
                        orderList.find(x => x.Id == n.Id).CourierId = n.CourierId;
                        orderList.find(x => x.Id == n.Id).CourierName = n.CourierName;
                        orderList.find(x => x.Id == n.Id).OrderStatus = n.OrderStatus;
                        orderList.find(x => x.Id == n.Id).PackageStatus = n.PackageStatus;
                        orderList.find(x => x.Id == n.Id).IsSelected = false;
                    })
                }

                orderList.map((n, i) => {
                    n["StandbyTime"] = moment.duration(moment(new Date()).diff(moment(n.CreationTime))).asMinutes().toFixed(0);
                })

                return { ...state, paketYoldaOrderList: orderList, paketYoldaOrderListUpdate: !state.paketYoldaOrderListUpdate, IsPaketYoldaPanelUpdate: !state.IsPaketYoldaPanelUpdate }
            }
        case PAKETYOLDA_ORDER_UPDATE:
            return { ...state, paketYoldaOrderListUpdate: !state.paketYoldaOrderListUpdate }
        case PAKETYOLDA_ORDER_LIST:
            {
                var orderList = action.payload;
                orderList.map((n, i) => {
                    n["StandbyTime"] = moment.duration(moment(new Date()).diff(moment(n.CreationTime))).asMinutes().toFixed(0);
                    n["IsSelected"] = false;
                })
                return { ...state, paketYoldaOrderStatus: action.paketYoldaOrderStatus, paketYoldaOrderList: orderList, paketYoldaOrderListUpdate: !state.paketYoldaOrderListUpdate, IsPaketYoldaPanelUpdate: !state.IsPaketYoldaPanelUpdate }
            }
        case PAKETYOLDA_COURIER_LIST:
            return { ...state, paketYoldaCourierList: action.payload, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate }
        case PAKETYOLDA_COURIER_TRACK_LIST:
            {
                var courierList = state.paketYoldaCourierList;
                var data = action.payload;

                if (courierList.filter(x => x.CourierId == data.CourierId).length == 0) {
                    courierList.push(data);
                } else {
                    courierList.splice(courierList.findIndex(x => x.CourierId == data.CourierId), 1);
                    courierList.push(data);
                }

                return { ...state, paketYoldaCourierListUpdate: !state.paketYoldaCourierListUpdate, paketYoldaCourierList: courierList, paketYoldaCourierTrack: data, IsPaketYoldaPanelUpdate: !state.IsPaketYoldaPanelUpdate }
            }
        case JAVIPOS_COURIER_TRACK_LIST:
            return { ...state, javiPosCourierTrack: action.payload, javiPosCourierTrackUpdate: !state.javiPosCourierTrackUpdate }
        case JAVIPOS_COURIER_HUB_CONNECT:
            return { ...state, javiPosOrderHubConnection: action.payload }
        case PAKETYOLDA_ORDER_HUB_CONNECT:
            return { ...state, paketYoldaOrderHubConnection: action.payload, IsPaketYoldaPanelUpdate: !state.IsPaketYoldaPanelUpdate }
        case PAKETYOLDA_COURIER_HUB_CONNECT:
            return { ...state, paketYoldaCourierHubConnection: action.payload, IsPaketYoldaPanelUpdate: !state.IsPaketYoldaPanelUpdate }
        case SET_PAKETYOLDA_USER_ADD:
            return { ...state, paketYoldaUserAdd: action.payload, paketYoldaUserUpdate: !state.paketYoldaUserUpdate }
        case SET_PAKETYOLDA_USER_LIST:
            return {
                ...state, paketYoldaUserAdd: {
                    Id: "00000000-0000-0000-0000-000000000000",
                    Username: "",
                    Passsword: "",
                    PaketYoldaId: "",
                    IsActive: true,
                    PIN: ""
                }, paketYoldaUserList: action.payload, paketYoldaUserUpdate: !state.paketYoldaUserUpdate
            }
        case SET_PAKETYOLDA_USER_VISIBLE:
            return { ...state, PaketYoldaId: action.paketyoldaid, paketYoldaUserVisible: action.payload, paketYoldaUserUpdate: !state.paketYoldaUserUpdate }
        case SET_PAKETYOLDA_USERLOGIN_LOADING:
            return { ...state, paketYoldaUserLoginLoading: action.payload, paketYoldaUserLoginUpdate: !state.paketYoldaUserLoginUpdate }
        case SET_PAKETYOLDA_USERLOGIN_DATA:
            return { ...state, paketYoldaUserLoginData: action.payload, paketYoldaUserLoginUpdate: !state.paketYoldaUserLoginUpdate }
        case SET_PAKETYOLDA_COURIER_LIST:
            return {
                ...state, paketYoldaCourierAddData: {
                    PaketYoldaId: "",
                    Phone: "",
                    Password: "",
                    NameSurname: "",
                    IsActive: true
                }, paketYoldaCourierListData: action.payload, paketYoldaCourierAddUpdate: !state.paketYoldaCourierAddUpdate
            }
        case SET_PAKETYOLDA_BRAND_LIST:
            return { ...state, autoComplatedSelected: null, autoComplatedValue: "", paketYoldaBrandList: action.payload, paketYoldaBrandUpdate: !state.paketYoldaBrandUpdate }
        case SET_PAKETYOLDA_BRAND_VISIBLE:
            return { ...state, PaketYoldaId: action.paketyoldaid, paketYoldaBrandVisible: action.payload, paketYoldaBrandUpdate: !state.paketYoldaBrandUpdate }
        case SET_PAKETYOLDA_ADD_LOADING:
            return { ...state, paketYoldaAddLoading: action.payload, paketYoldaAddUpdate: !state.paketYoldaAddUpdate }
        case SET_PAKETYOLDA_ADD_DATA:
            return { ...state, paketYoldaAddData: action.payload, paketYoldaAddUpdate: !state.paketYoldaAddUpdate }
        case SET_PAKETYOLDA_ADD_VISIBLE:
            return {
                ...state, paketYoldaAddData: {
                    CompanyTitle: "",
                    BrandName: "",
                    AuthorizedPerson: "",
                    Phone: "",
                    Address: "",
                    TaxNumber: "",
                    TaxAdministration: "",
                    IsActive: true,
                    WorkingHourStart: new Date(),
                    WorkingHourEnd: new Date()
                }, paketYoldaAddVisible: action.payload, paketYoldaAddUpdate: !state.paketYoldaAddUpdate
            }
        case SET_PAKETYOLDA_DATA:
            return { ...state, paketYoldaList: action.payload, paketYoldaUpdate: !state.paketYoldaUpdate }
        case SET_PAKETYOLDA_LOADING:
            return { ...state, paketYoldaLoading: action.payload, paketYoldaUpdate: !state.paketYoldaUpdate }
        case SET_PAKETYOLDA_VISIBLE:
            return { ...state, paketYoldaVisible: action.payload, paketYoldaUpdate: !state.paketYoldaUpdate }
        case PAKETYOLDA_ERROR:
            return {
                ...state, form_data: {
                    ...state.form_data,
                    modalOpen: true,
                    modalText: action.payload,
                    modalTitle: "Uyarı"
                }, paketYoldaUserLoginUpdate: !state.paketYoldaUserLoginUpdate, IsPaketYoldaPanelUpdate: !state.IsPaketYoldaPanelUpdate, IsPaketYoldaMainPageUpdate: !state.IsPaketYoldaMainPageUpdate,
                paketYoldaCenterListUpdate: !state.paketYoldaCenterListUpdate
            }
        case PAKETYOLDA_MODALCLOSE:
            return {
                ...state, form_data: {
                    ...state.form_data,
                    modalOpen: false,
                    modalText: "",
                    modalTitle: "Uyarı"
                }, IsReportPageUpdate: !state.IsReportPageUpdate, paketYoldaUserLoginUpdate: !state.paketYoldaUserLoginUpdate, IsPaketYoldaPanelUpdate: !state.IsPaketYoldaPanelUpdate, IsPaketYoldaMainPageUpdate: !state.IsPaketYoldaMainPageUpdate,
                paketYoldaCenterListUpdate: !state.paketYoldaCenterListUpdate
            }
        default:
            return state;
    }
}

export default paketYoldaReducer;
