import React from 'react';
import { connect } from 'react-redux';
import {
    withStyles
} from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    createSelector
} from 'reselect';
import Button from '@material-ui/core/Button';
import {
    setProductAllDiscountVisible, setProductAllDiscountData, setProductAllDiscount
} from '../../../../redux/modules/stock';
import Typography from '@material-ui/core/Typography';
import { CapitalizeText } from '../../../../function/GeneralFunction';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { CirclePicker } from 'react-color';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
const uuidv4 = require('uuid/v4');
import AlertDialog from '../../Ortak/AlertDialog';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    dropdownStyle: {
        border: "1px solid #ecf0f1",
        borderRadius: "5%",
    },
});

class ProductAllDiscount extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        return true;
    }

    handleChange = name => event => {
        const { productAllDiscountData } = this.props;
        if ((event.target.value > 100 || event.target.value < 0) && name == "Discount") {
            return;
        }

        productAllDiscountData[name] = event.target.value;
        this.props.setProductAllDiscountData(productAllDiscountData).then(() => { })
    };

    render() {
        const {
            classes,
            productAllDiscountData,
            productAllDiscountLoading,
            filterDataProduct
        } = this.props;
        return (
            <Dialog
                open={this.props.productAllDiscountVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'sm'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Toplu İndirim İşlemleri"}
                    <Typography className={classes.instructions} style={{ fontSize: 13, fontWeight: 500, color: 'white' }}>
                        {"Listedeki Ürünlere Toplu İndirim Uygulama İşlemleri"}
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={1} style={{ padding: '0 24px 24px', width: '100%' }}>
                            <Grid item md={12}>
                                <RadioGroup
                                    aria-label="radio"
                                    name="radio"
                                    className={classes.group}
                                    style={{ display: 'block', width: '100%', marginLeft: 10 }}
                                    value={productAllDiscountData.PriceType}
                                    onChange={this.handleChange("PriceType")}
                                >
                                    <FormControlLabel style={{ marginRight: 20 }} value="Normal" control={<Radio />} label={"Normal Fiyat"} />
                                    <FormControlLabel style={{ marginRight: 0 }} value="Package" control={<Radio />} label={"Paket Fiyatı"} />
                                </RadioGroup>
                            </Grid>
                            <Grid item md={6}>
                                <FormControl className={classes.formControl} style={{ minWidth: 300 }}>
                                    <InputLabel htmlFor="name-simple">{"İndirim Oranı"}</InputLabel>
                                    <Input min={1} max={100} type={"number"} id="Discount" value={productAllDiscountData.Discount} onChange={this.handleChange("Discount")} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    <div style={{ fontSize: 14, textAlign: 'center', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, backgroundColor: '#ecf0f1', color: '#313131' }}>
                        {"Toplu indirim filtrelediğiniz ürün listesine uygulanmaktadır. İndirimi geri almak için '0' olarak uygulamanız yeterlidir."}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={productAllDiscountLoading} variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setProductAllDiscountVisible(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                    <Button disabled={productAllDiscountLoading} variant="contained" onClick={() => this.props.setProductAllDiscount(filterDataProduct).then(() => { })} color="secondary">
                        {"İNDİRİMİ LİSTEYE UYGULA"}
                    </Button>
                </DialogActions>
            </Dialog >
        );
    }
}

const valueSelector = (state, props) => state.get('stockReducer').productAllDiscountUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    productAllDiscountUpdate: value(state, props),
    productAllDiscountVisible: state.get('stockReducer').productAllDiscountVisible,
    productAllDiscountData: state.get('stockReducer').productAllDiscountData,
    productAllDiscountLoading: state.get('stockReducer').productAllDiscountLoading,
    filterDataProduct: state.get('stockReducer').filterDataProduct,
})

const mapDispatchToProps = {
    setProductAllDiscountVisible,
    setProductAllDiscountData,
    setProductAllDiscount
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withStyles(styles)(injectIntl(ProductAllDiscount)));
