import React from 'react';
import {
  createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../../datepicker.css';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { EmptyData } from 'dan-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePagination from '@material-ui/core/TablePagination';
import Select from '@material-ui/core/Select';
import TbPaginationActions from '../../../Ortak/TbPaginationActions';
import ModalInformation from '../../../Ortak/ModalInformation';
import PackageAdditionDetail from '../../../PackagePage/PackageAdditionDetail';
import CustomerAdd from '../../Customer/CustomerAdd';
import {
  RequestAdditionReport, setReportPageAdditionReportData, setReportPageAdditionReportUpdate, modalCloseReport, getAdditionListExcel
} from '../../../../../redux/modules/report';
import {
  getPackageAdditionDetail, setPrinterHubConnection, sendMessagePackagePrinter, getManagerControl
} from '../../../../../redux/modules/addition';
import {
  customerEdit
} from '../../../../../redux/modules/customer';
import { getParameter, getLicenceModule, trMoneyConvert } from '../../../../../function/GeneralFunction';

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  }
});

class Addition extends React.Component {
  constructor() {
    super();
    this.state = { additionId: null, managerPasswordVisible: false, managerPasswordText: '' };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.IsAdditionReportUpdate == this.props.IsAdditionReportUpdate) {
      return false;
    }
    return true;
  }

  async componentDidMount() {
    const TimeData = getParameter('BusinessWorkingHours');
    await this.setState({ startDate: TimeData.start, endDate: TimeData.end });

    if (this.props.printerHubConnection == null) {
      await this.props.setPrinterHubConnection().then(() => { });
    }
  }

  handleChangeFilterAddition = name => event => {
    const { AdditionReportFilterData } = this.props;
    AdditionReportFilterData[name] = (name == 'OrderType' ? event.target.value : event);
    this.props.setReportPageAdditionReportData(AdditionReportFilterData).then(() => { });
  };

  handleChangeFilterAdditionSearch(event) {
    const { AdditionReportFilterData } = this.props;
    AdditionReportFilterData.searchText = event.target.value;
    this.props.setReportPageAdditionReportData(AdditionReportFilterData).then(() => { });
  }

  handleChangePage = (event, page) => {
    const { AdditionReportFilterData } = this.props;
    AdditionReportFilterData.currentPage = page + 1;
    this.props.RequestAdditionReport(AdditionReportFilterData.startDate, AdditionReportFilterData.endDate).then(() => { });
  };

  handleChangeRowsPerPage = event => {
    const { AdditionReportFilterData } = this.props;
    AdditionReportFilterData.rowsPerPage = event.target.value;
    this.props.RequestAdditionReport(AdditionReportFilterData.startDate, AdditionReportFilterData.endDate).then(() => { });
  };

  handleClickPopover = event => {
    this.props.getPackageAdditionDetail(event.currentTarget.id, event.currentTarget);
  };

  clickPrintedPackage(Id, BrandId, type) {
    const { printerHubConnection } = this.props;
    this.props.sendMessagePackagePrinter(printerHubConnection, type, Id, BrandId).then(() => { });
  }

  async clickManagerPassword(Id) {
    await this.setState({ managerPasswordVisible: true, managerPasswordText: '', additionId: Id });
    this.props.setReportPageAdditionReportUpdate().then(() => { });
  }

  async clickBackAddition() {
    const { AdditionReportFilterData } = this.props;
    await this.props.getManagerControl(this.state.managerPasswordText, this.state.additionId).then(() => {
      this.setState({ managerPasswordVisible: false, managerPasswordText: '', additionId: null });
      this.props.RequestAdditionReport(AdditionReportFilterData.startDate, AdditionReportFilterData.endDate).then(() => { });
    });
  }

  renderManagerPassword() {
    return (
      <Dialog
        open={this.state.managerPasswordVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {'Adisyonu geri almak için yönetici şifresi girmelisiniz!'}
          <Typography style={{
            float: 'left', fontSize: 12, fontWeight: 600, color: '#f39c12', padding: 5, borderRadius: 5
          }}
          >
            {'Silinmiş veya Ödenmiş bir adisyon sadece yönetici şifresi ile geri alınabilir.'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={1}>
              <Grid item md={12}>
                <Input type="password" autoFocus fullWidth value={this.state.managerPasswordText} onChange={e => { this.setState({ managerPasswordText: e.target.value }); this.props.setReportPageAdditionReportUpdate().then(() => { }); }} />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} onClick={() => { this.setState({ managerPasswordVisible: false }); this.props.setReportPageAdditionReportUpdate().then(() => { }); }} color="primary">
            {'İptal'}
          </Button>
          <Button onClick={() => this.clickBackAddition()} color="secondary" variant="contained" autoFocus>
            {'Onay'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  modalClose = () => {
    this.props.modalCloseReport().then(() => {
      this.props.setReportPageAdditionReportUpdate().then(() => { });
    });
  };

  render() {
    const {
      ReportName, AdditionReport, AdditionReportFilterData, AdditionReportLoading, classes
    } = this.props;

    if (ReportName != 'AdditionReport') {
      return null;
    }

    return (
      <Paper className={classes.root} elevation={0} style={{ borderRadius: 0, overflowX: 'hidden' }}>
        {this.renderManagerPassword()}
        <ModalInformation event={this.modalClose} open={this.props.modalOpen} title={this.props.modalTitle} text={this.props.modalText} />
        <PackageAdditionDetail classes={classes} />
        <CustomerAdd classes={classes} />
        <div style={{
          backgroundColor: '#424242', padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}
        >
          {"Açık / Kapalı Adisyon Listesi"}
        </div>
        <Grid container spacing={1} style={{ marginTop: 15 }}>
          <Grid item md={3}>
            <Typography
              style={{
                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
              }}
              className={classes.flex}
            >
              {"Başlangıç Tarihi"}
            </Typography>
            <DatePicker
              selected={AdditionReportFilterData.startDate}
              onChange={this.handleChangeFilterAddition('startDate')}
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeSelect
              timeIntervals={15}
              timeFormat="HH:mm"
              locale="tr"
              className="inputDate"
            />
          </Grid>
          <Grid item md={3}>
            <Typography
              style={{
                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
              }}
              className={classes.flex}
            >
              {"Bitiş Tarihi"}
            </Typography>
            <DatePicker
              selected={AdditionReportFilterData.endDate}
              onChange={this.handleChangeFilterAddition('endDate')}
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeSelect
              timeIntervals={15}
              timeFormat="HH:mm"
              locale="tr"
              className="inputDate"
            />
          </Grid>
          <Grid item md={2}>
            <FormControl style={{ marginTop: 17 }} fullWidth className={classNames(classes.textField)}>
              <Input
                id="search_filter"
                type="text"
                placeholder="Arama"
                value={AdditionReportFilterData.searchText}
                onChange={(event) => this.handleChangeFilterAdditionSearch(event)}
              />
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <Typography
              style={{
                marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
              }}
              className={classes.flex}
            >
              {"Sipariş Kanalı"}
            </Typography>
            <Select
              fullWidth
              value={AdditionReportFilterData.OrderType}
              onChange={this.handleChangeFilterAddition('OrderType')}
              input={<Input name="smsTitle" id="age-helper" />}
            >
              <MenuItem value={2}>Tümü</MenuItem>
              <MenuItem value={0}>Paket</MenuItem>
              <MenuItem value={1}>SelfSatış - Masa</MenuItem>
            </Select>
          </Grid>
          <Grid item md={2}>
            <Button
              fullWidth
              disabled={AdditionReportLoading}
              onClick={() => this.props.RequestAdditionReport(AdditionReportFilterData.startDate, AdditionReportFilterData.endDate).then(() => { })}
              style={{
                borderRadius: 5, height: 37, padding: '6px 24px', marginTop: 17
              }}
              size="large"
              variant="contained"
              color="secondary"
              type="submit"
            >
              {'FİLTRELE'}
            </Button>
          </Grid>
          <Grid item md={10}></Grid>
          <Grid item md={2}>
            <Button
              fullWidth
              disabled={AdditionReportLoading}
              onClick={() => this.props.getAdditionListExcel().then(() => { })}
              style={{
                borderRadius: 5, height: 37, padding: '6px 24px', backgroundColor:'#f39c12'
              }}
              size="large"
              variant="contained"
              color="secondary"
              type="submit"
            >
              {'EXCEL İNDİR'}
            </Button>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {(AdditionReportLoading ? <div style={{ width: '100%', textAlign: 'center', marginBottom: 15 }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
        <Table style={{ marginTop: 5 }}>
          <TableHead style={{ backgroundColor: '#212121' }}>
            <TableRow>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Tip</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">No</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Tarih/Saat</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Satış Kanalı</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Adı Soyadı</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Tutar</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Kullanıcı</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="left">Kurye</TableCell>
              <TableCell style={{ color: 'white', fontSize: 13 }} align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (AdditionReport != null ? AdditionReport.map((n, i) => {
                let colorTable = '#2980b9';
                if (n.TableName == 'YemekSepeti' || n.TableName == 'YemekSepetiGlobal') {
                  colorTable = '#fa0050';
                } else if (n.TableName == 'GetirYemek') {
                  colorTable = '#8e44ad';
                } else if (n.TableName == 'TrendyolYemek') {
                  colorTable = '#d35400';
                } else if (n.TableName == 'GoFody') {
                  colorTable = '#f1b62a';
                } else if (n.TableName == 'MigrosYemek') {
                  colorTable = '#27ae60';
                }

                let deleteText = (n.OrderType == 1 ? 'Paket' : 'Adisyon');
                if (n.IsDelete) {
                  if (n.CancellationReason == 0) {
                    deleteText = deleteText + ' ' + 'Silindi';
                  } else if (n.CancellationReason == 1) {
                    deleteText = deleteText + ' ' + 'Transfer';
                  } else if (n.CancellationReason == 2) {
                    deleteText = deleteText + ' ' + 'Gruplama';
                  }
                }

                return (
                  <TableRow hover key={i}>
                    <TableCell align="left" style={{ width: 75, padding: 5 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {(n.TotalDiscount > 0 ? <Tooltip title={'Bu adisyonda ' + n.TotalDiscount.toFixed(2) + ' tutarında indirim yapılmıştır'}><Icon style={{ fontSize: 20, marginRight: 5, color: '#f1c40f' }}>discount</Icon></Tooltip> : null)}
                        <div style={{
                          marginRight: 5, backgroundColor: colorTable, borderRadius: 50, height: 15, width: 15
                        }}
                        />
                        <span style={{ color: (n.IsDelete ? '#e74c3c' : 'white') }}>
                          {deleteText}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left" style={{ padding: 5, width: 100 }}>{n.AdditionCode}</TableCell>
                    <TableCell align="left" style={{ padding: 5, width: 100 }}>{moment(n.CreationTime).format('DD.MM.YYYY HH:mm')}</TableCell>
                    <TableCell align="left" style={{ padding: 5, width: 100 }}>{n.TableName}</TableCell>
                    <TableCell align="left" style={{ padding: 5 }}>{n.CustomerNameSurname}</TableCell>
                    <TableCell align="left" style={{ padding: 5 }}>{trMoneyConvert((n.TotalPrice - n.TotalDiscount),2)}</TableCell>
                    <TableCell align="left" style={{ padding: 5 }}>{n.UserName}</TableCell>
                    <TableCell align="left" style={{ padding: 5 }}>{n.Courrier}</TableCell>
                    <TableCell align="right" style={{ padding: 5 }}>
                      {
                        n.OrderType != 2 && window.location.pathname != "/centerbrand"
                          ? (
                            <Tooltip title="Mutfak Yazdır">
                              <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5, borderRadius: 5 }} disabled={(n.PackagePrinted ? 'disabled' : '')} onClick={() => this.clickPrintedPackage(n.Id, n.BrandId, 1)}>
                                <Icon style={{ color: (n.PackagePrinted ? '#27ae60' : '#c0392b') }}>print</Icon>
                                <span style={{ fontSize: 12, marginLeft: 5 }}>{"Mutfak"}</span>
                              </IconButton>
                            </Tooltip>
                          ) : null
                      }
                      {
                        n.OrderType != 1 && window.location.pathname != "/centerbrand"
                          ? (
                            <Tooltip title="Paket Yazdır">
                              <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5, borderRadius: 5 }} disabled={(n.KitchenPrinted ? 'disabled' : '')} onClick={() => this.clickPrintedPackage(n.Id, n.BrandId, 0)}>
                                <Icon style={{ color: (n.KitchenPrinted ? '#27ae60' : '#c0392b') }}>print</Icon>
                                <span style={{ fontSize: 12, marginLeft: 5 }}>{"Paket"}</span>
                              </IconButton>
                            </Tooltip>
                          ) : null
                      }
                      {
                        (n.IsDelete || n.IsPayment ) && window.location.pathname != "/centerbrand"
                          ? (
                            <Tooltip title="Adisyonu Geri Al">
                              <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5 }} onClick={() => this.clickManagerPassword(n.Id)}>
                                <Icon>{"compare_arrows"}</Icon>
                              </IconButton>
                            </Tooltip>
                          ) : null
                      }
                      <Tooltip title="Sipariş Detayı Git">
                        <IconButton name={n.Id} id={n.Id} onClick={this.handleClickPopover} style={{ marginRight: 5 }}>
                          <Icon>{"description"}</Icon>
                        </IconButton>
                      </Tooltip>
                      {
                       getLicenceModule('cd000041-ba77-418f-8902-4c9ce85aeac1')  && window.location.pathname != "/centerbrand"
                        ? (
                          <Tooltip title="BizimHesap'a Gönder">
                            <IconButton style={{ backgroundColor: '#28b29a', marginRight: 5, borderRadius: 5 }} onClick={() => this.props.customerEdit(n.CustomerId,n.Id).then(() => { })}>
                              <Icon>calculate</Icon>
                              <span style={{ fontSize: 12, marginLeft: 5 }}>{"Bizim Hesap"}</span>
                            </IconButton>
                          </Tooltip>
                        ) : null
                      }
                    </TableCell>
                  </TableRow>
                );
              }) : null)
            }
          </TableBody>
        </Table>
        {
          (AdditionReport == null ? <EmptyData text="Adiyon Kaydı Bulunamadı" /> : null)
        }
        {(AdditionReportLoading ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
        <div style={{ width: '100%', display: 'table' }}>
          <TablePagination
            labelRowsPerPage="Sayfa başına satır sayısı"
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={AdditionReportFilterData.rowsPerPage}
            count={AdditionReportFilterData.totalRecord}
            page={AdditionReportFilterData.currentPage - 1}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TbPaginationActions}
          />
        </div>
      </Paper>
    );
  }
}

const valueSelector = (state, props) => state.get('reportReducer').IsAdditionReportUpdate;
const value = createSelector([valueSelector],
  (value) => value);


const mapStateToProps = (state, props) => ({
  IsAdditionReportUpdate: value(state, props),
  ReportName: state.get('reportReducer').ReportName,
  AdditionReportLoading: state.get('reportReducer').AdditionReportLoading,
  AdditionReport: state.get('reportReducer').AdditionReport,
  AdditionReportFilterData: state.get('reportReducer').AdditionReportFilterData,
  printerHubConnection: state.get('additionReducer').form_data.printerHubConnection,

  modalOpen: state.get('reportReducer').form_data.modalOpen,
  modalText: state.get('reportReducer').form_data.modalText,
  modalTitle: state.get('reportReducer').form_data.modalTitle,
});

const mapDispatchToProps = {
  RequestAdditionReport,
  setReportPageAdditionReportData,
  setReportPageAdditionReportUpdate,
  getPackageAdditionDetail,
  setPrinterHubConnection,
  sendMessagePackagePrinter,
  getManagerControl,
  modalCloseReport,
  customerEdit,
  getAdditionListExcel
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(Addition)));
