import Loadable from 'react-loadable';
import Loading from 'dan-components/Loading';

export const TablePage = Loadable({
  loader: () => import('./Pages/TablePage'),
  loading: Loading,
});
export const DetailPage = Loadable({
  loader: () => import('./Pages/DetailPage'),
  loading: Loading
});
export const PackagePage = Loadable({
  loader: () => import('./Pages/PackagePage'),
  loading: Loading
});
export const CenterBrand = Loadable({
  loader: () => import('./Pages/CenterBrandPage/CenterMainPage'),
  loading: Loading
});
export const FetchData = Loadable({
  loader: () => import('./Pages/FetchData'),
  loading: Loading,
});
export const SocketPage = Loadable({
  loader: () => import('./Pages/SocketPage'),
  loading: Loading,
});

export const DashboardPage = Loadable({
  loader: () => import('./Pages/Dashboard'),
  loading: Loading,
});
export const Form = Loadable({
  loader: () => import('./Pages/Forms/ReduxForm'),
  loading: Loading,
});
export const Table = Loadable({
  loader: () => import('./Pages/Table/BasicTable'),
  loading: Loading,
});
export const Login = Loadable({
  loader: () => import('./Pages/Users/Login'),
  loading: Loading,
});
export const Register = Loadable({
  loader: () => import('./Pages/Users/Register'),
  loading: Loading,
});
export const NotFound = Loadable({
  loader: () => import('./NotFound/NotFound'),
  loading: Loading,
});
export const Error = Loadable({
  loader: () => import('./Pages/Error'),
  loading: Loading,
});
/*Admin Panel*/
export const Settings = Loadable({
  loader: () => import('./Pages/Settings'),
  loading: Loading,
});

export const ReportList = Loadable({
  loader: () => import('./Pages/SettingPage/ReportList'),
  loading: Loading,
});

export const CourierPIN = Loadable({
  loader: () => import('./Pages/CourierPage/CourierPIN'),
  loading: Loading,
});

export const CourierPackageList = Loadable({
  loader: () => import('./Pages/CourierPage/CourierPackageList'),
  loading: Loading,
});

export const Report = Loadable({
  loader: () => import('./Pages/SettingPage/ReportPage/Report'),
  loading: Loading,
});

export const AdminLogin = Loadable({
  loader: () => import('./Pages/AdminPage/AdminLogin'),
  loading: Loading,
});

export const Demo = Loadable({
  loader: () => import('./Pages/Users/Demo'),
  loading: Loading,
});

export const PaketYoldaLogin = Loadable({
  loader: () => import('./Pages/PaketYolda'),
  loading: Loading,
});

export const CustomerScreen = Loadable({
  loader: () => import('./Pages/Customer'),
  loading: Loading,
});

export const MainPage = Loadable({
  loader: () => import('./Pages/MainPage'),
  loading: Loading,
});