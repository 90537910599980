import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from 'dan-components/Tables/tableStyle-jss';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BusinessIcon from '@material-ui/icons/Business';
import cookie from 'react-cookies';
import Divider from '@material-ui/core/Divider';
import {
  getSubscriberListStatusChange,
  getPaynetSubscriber,
  selectedSubscriberChange,
  subscriberPlanAmountUpdateModalVisibleChange,
  subscriberExtensionModalVisibleChange,
  subscriberInvoiceModalVisibleChange,
  getSubscriberInvoiceHeader,
  alertModalChange,
  adminCustomerLoginControl,
  adminCenterBrandLogin,
  setBrandStatus, getLicenceModule
} from '../../../../redux/modules/admin';

class AdminSubscriberPage extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    anchorEl: null,
    openMenu: null
  };

  handleMenu = menu => (event) => {
    const { openMenu } = this.state;
    this.setState({
      openMenu: openMenu === menu ? null : menu,
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, openMenu: null });
  };

  async SubscriberFilter(value) {
    await this.props.getSubscriberListStatusChange(value);
    this.props.getPaynetSubscriber();
  }

  invoiceModalOpen(brandId) {
    this.setState({ anchorEl: null, openMenu: null });
    if (brandId != '00000000-0000-0000-0000-000000000000') {
      this.props.getSubscriberInvoiceHeader(brandId);
      this.props.subscriberInvoiceModalVisibleChange(true, brandId);
    } else {
      this.props.alertModalChange(true, 'Uyarı', 'Abonelik bulunamadı.');
    }
  }


  loginCustomer(brandId, tenantId) {
    this.setState({ anchorEl: null, openMenu: null });
    this.props.adminCustomerLoginControl(brandId, tenantId);
  }

  loginCenterBrand(centerBrandId, tenantId) {
    this.setState({ anchorEl: null, openMenu: null });
    this.props.adminCenterBrandLogin(centerBrandId, tenantId);
  }

  async componentDidMount() { }

  render() {
    const { classes } = this.props;
    const { anchorEl, openMenu } = this.state;

    return (
      <div className={classes.rootTable}>
        <Toolbar
          style={{
            marginBottom: 30,
            minHeight: 48,
            width: '100%',
            display: 'flex',
            backgroundColor: 'rgb(90, 96, 101)',
            borderRadius: 0,
          }}
        >
          <Grid style={{ alignItems: 'center', marginBottom: 10 }} container spacing={1}>
            <Grid item md={2}>
              <FormControl style={{ width: '100%' }} className={classes.formControl}>
                <InputLabel htmlFor="age-helper">Abonelik Durumu</InputLabel>
                <Select
                  value={this.props.getSubscriberListStatus}
                  onChange={(event) => this.SubscriberFilter(event.target.value)}
                  input={<Input name="Category" id="age-helper" />}
                >
                  <MenuItem value={1}>Aktif Müşteriler</MenuItem>
                  <MenuItem value={3}>Lite Paketler</MenuItem>
                  <MenuItem value={4}>JaviKurye İşletmeleri</MenuItem>
                  <MenuItem value={2}>Pasife Geçenler</MenuItem>
                  <MenuItem value={5}>Paycell Paketleri</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1}>
              <Button
                onClick={() => this.props.getPaynetSubscriber()}
                variant="outlined"
                style={{
                  marginLeft: 10, float: 'left', borderRadius: 5, height: 49, marginTop: 15
                }}
              >
                <Icon>refresh</Icon>
              </Button>
            </Grid>
            <Grid item md={5} />
            <Grid item md={4} style={{ textAlignLast: 'right' }}>
              <span style={{
                marginLeft: 7, fontSize: 12, fontWeight: 500, color: 'white'
              }}
              >
                {'Toplam '
                  + (this.props.getSubscriberListStatus == 1
                    ? 'Aktif'
                    : this.props.getSubscriberListStatus == 2
                      ? 'Pasif'
                      : this.props.getSubscriberListStatus == 0
                        ? 'Kart Bekleniyen'
                        : 'İptal Edilen')
                  + ' İşletme Sayısı: '
                  + this.props.paynetSubscriberList.length}
              </span>
            </Grid>
          </Grid>
        </Toolbar>
        <Table className={classNames(classes.table, classes.hover, classes.stripped)}>
          <TableHead style={{ backgroundColor: '#212121' }}>
            <TableRow>
              <TableCell style={{ width: '6%' }}>Durum</TableCell>
              <TableCell style={{ width: '12%' }}>Yetkili Adı</TableCell>
              <TableCell style={{ width: '12%' }}>İşletme</TableCell>
              <TableCell style={{ width: '12%' }}>Kalan Gün</TableCell>
              <TableCell style={{ width: '9%' }}>Telefon</TableCell>
              <TableCell style={{ width: '10%' }}>Başlangıç Tarihi</TableCell>
              <TableCell style={{ width: '5%' }}>Tutar</TableCell>
              <TableCell align="right" style={{ width: '34%' }}>
                {'İşlemler'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.paynetSubscriberList.map((n, i) => (
              <TableRow key={i}>
                <TableCell style={{ padding: 5, width: '6%' }}><Icon style={{ color: (n.status == 1 ? '#2ecc71' : '#e74c3c') }}>fiber_manual_record</Icon></TableCell>
                <TableCell style={{ padding: 5, width: '12%' }}>{n.name_surname}</TableCell>
                <TableCell style={{ padding: 5, width: '31%' }}>{n.agent_note}</TableCell>
                <TableCell style={{ padding: 5, width: '12%' }}>{n.dayleft}</TableCell>
                <TableCell style={{ padding: 5, width: '9%' }}>{n.end_user_gsm}</TableCell>
                <TableCell style={{ padding: 5, width: '10%' }}>
                  {moment(n.begin_date, 'DD/MM/YYYY').format('DD.MM.YYYY')}
                </TableCell>
                <TableCell style={{ padding: 5, width: '5%' }}>{n.amount}</TableCell>
                <TableCell align="right" style={{ padding: 5, width: '15%' }}>
                  <Button style={{ marginRight: 10, fontSize: 12 }} variant="contained" color="default" onClick={this.handleMenu(n.brandId)}>
                    {'İşlemler'}
                  </Button>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={openMenu === n.brandId}
                    onClose={this.handleClose}
                  >
                    <MenuItem disabled>
                      <ListItemIcon>
                        <Icon>home</Icon>
                      </ListItemIcon>
                      {n.agent_note}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => this.props.selectedSubscriberChange(n, 'SubscriberDetail', true).then(() => this.setState({ anchorEl: null, openMenu: null }))}>
                      <ListItemIcon>
                        <Icon>info</Icon>
                      </ListItemIcon>
                      {'Abonelik Detayı'}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => this.props.getLicenceModule(n.brandId).then(() => this.setState({ anchorEl: null, openMenu: null }))}>
                      <ListItemIcon>
                        <Icon>extension</Icon>
                      </ListItemIcon>
                      {'Modül Seçimlerini Güncelle'}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => this.props.selectedSubscriberChange(n, 'sendMessage', true).then(() => this.setState({ anchorEl: null, openMenu: null }))}>
                      <ListItemIcon>
                        <Icon>attach_email</Icon>
                      </ListItemIcon>
                      {'Ödeme Linki Gönder'}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => this.props.subscriberPlanAmountUpdateModalVisibleChange(
                      true,
                      n.subscription_id,
                      n.reference_no,
                      '00000000-0000-0000-0000-000000000000',
                      n.amount
                    ).then(() => this.setState({ anchorEl: null, openMenu: null }))}
                    >
                      <ListItemIcon>
                        <Icon>credit_score</Icon>
                      </ListItemIcon>
                      {'Abonelik Tutarı Güncelleme'}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => this.props.subscriberExtensionModalVisibleChange(
                      true,
                      n.brandId,
                      n.agent_note
                    ).then(() => this.setState({ anchorEl: null, openMenu: null }))}
                    >
                      <ListItemIcon>
                        <Icon>date_range</Icon>
                      </ListItemIcon>
                      {'Abonelik Uzat'}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => this.invoiceModalOpen(n.brandId)}>
                      <ListItemIcon>
                        <Icon>edit</Icon>
                      </ListItemIcon>
                      {'Fatura Bilgilerini Güncelle'}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => this.loginCustomer(n.brandId, n.tenantId)}>
                      <ListItemIcon>
                        <Icon>login</Icon>
                      </ListItemIcon>
                      {'Müşteri Hesabına Gir'}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => this.loginCenterBrand(n.centerBrandId, n.tenantId)}>
                      <ListItemIcon>
                        <Icon>account_tree</Icon>
                      </ListItemIcon>
                      {'Merkez Şubeye Giriş Yap'}
                    </MenuItem>
                  </Menu>
                  <Tooltip title={(this.props.getSubscriberListStatus == 2 ? 'Aboneliği Aktife Çek' : 'Aboneliği Pasife Çek')}>
                    <IconButton
                      style={{
                        backgroundColor: (this.props.getSubscriberListStatus == 2 ? '#2ecc71' : '#e74c3c'), marginRight: 5, fontSize: 12, padding: 8
                      }}
                      onClick={() => (confirm('İşletmeyi pasife çekmek istediğinize emin misiniz?') ? this.props.setBrandStatus(n.brandId, (this.props.getSubscriberListStatus == 2)).then(() => this.setState({ anchorEl: null, openMenu: null })) : null) }
                    >
                      <Icon>{(this.props.getSubscriberListStatus == 2 ? 'undo' : 'delete')}</Icon>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

AdminSubscriberPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('adminReducer').adminSubscriberPageIsupdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  adminSubscriberPageIsupdate: value(state, props),
  paynetSubscriberList: state.get('adminReducer').paynetSubscriberList,
  getSubscriberListStatus: state.get('adminReducer').getSubscriberListStatus,
});

const mapDispatchToProps = {
  getSubscriberListStatusChange,
  getPaynetSubscriber,
  selectedSubscriberChange,
  subscriberPlanAmountUpdateModalVisibleChange,
  subscriberExtensionModalVisibleChange,
  subscriberInvoiceModalVisibleChange,
  getSubscriberInvoiceHeader,
  alertModalChange,
  adminCustomerLoginControl,
  adminCenterBrandLogin,
  setBrandStatus,
  getLicenceModule
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(AdminSubscriberPage)));
