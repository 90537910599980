import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../../SettingPage/datepicker.css';
import tr from 'date-fns/locale/tr';
import 'leaflet/dist/leaflet.css';
import cookie from 'react-cookies';
import Select from '@material-ui/core/Select';
import MapClusterReport from '../../SettingPage/ReportPage/Maps/MapClusterReport';
import {
    getReportEntegration, setFranchiseReportProductSalesListFilter, setFranchiseReportBrandSelected
} from '../../../../redux/modules/centerbrand';
import { getParameter } from '../../../../function/GeneralFunction';
registerLocale('tr', tr);
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
});

class EntegrationList extends React.Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.franchiseReportEntegrationUpdate == this.props.franchiseReportEntegrationUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    handleChangeFilterProductSalesList = name => event => {
        const { filterReportProductList } = this.props;
        filterReportProductList[name] = (name == "StartDatetime" || name == "EndDatetime" ? moment.utc(event, 'MM-DD-YYYY').local().format("YYYY-MM-DD") : event);
        this.props.setFranchiseReportProductSalesListFilter(filterReportProductList).then(() => { });
    };

    handleChange = async (event) => {
        this.props.setFranchiseReportBrandSelected(event.target.value).then(() => { });
    };

    render() {
        const {
            franchiseReportName, franchiseReportEntegrationData, filterReportProductList, isCenterReportLoading, classes,
            franchiseReportBrandSelected, centerBrandData
        } = this.props;

        if (franchiseReportName != 'EntegrationReport') {
            return null;
        }

        return (
            <Paper className={classes.root} elevation={0} style={{ height: '100%', borderRadius: 0, overflowX: 'hidden', overflowY: 'auto' }}>
                <div style={{
                    backgroundColor: '#424242', padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}
                >
                    {"Genel ve Şube Bazında Entegrasyon Toplam Satış Raporu"}
                </div>
                <Grid container spacing={1} style={{ padding: 10 }}>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Başlangıç Tarihi"}
                        </Typography>
                        <DatePicker
                            selected={new Date(filterReportProductList.StartDatetime)}
                            onChange={this.handleChangeFilterProductSalesList('StartDatetime')}
                            dateFormat="dd.MM.yyyy"
                            locale="tr"
                            className="inputDate"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Bitiş Tarihi"}
                        </Typography>
                        <DatePicker
                            selected={new Date(filterReportProductList.EndDatetime)}
                            onChange={this.handleChangeFilterProductSalesList('EndDatetime')}
                            dateFormat="dd.MM.yyyy"
                            locale="tr"
                            className="inputDate"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Şube Seçimi"}
                        </Typography>
                        <Select
                            fullWidth
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            style={{ height: 38 }}
                            value={franchiseReportBrandSelected}
                            onChange={this.handleChange}
                        >
                            <MenuItem value={"00000000-0000-0000-0000-000000000000"}>
                                <ListItemText primary={"Tüm Şubeler"} />
                            </MenuItem>
                            {centerBrandData.map((n, i) => (
                                <MenuItem lat={n.Latitude} lng={n.Longitude} key={i} value={n.Id}>
                                    <ListItemText primary={n.Name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item md={3}>
                        <Button fullWidth disabled={isCenterReportLoading} onClick={() => this.props.getReportEntegration().then(() => { })} style={{ marginTop: 18, borderRadius: 5, height: 37, padding: '6px 24px' }} size="large" variant="contained" color="secondary" type="submit">
                            {'FİLTRELE'}
                        </Button>
                    </Grid>
                </Grid>
                {(isCenterReportLoading ? <div style={{ width: '100%', textAlign: 'center', marginBottom: 15 }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
                <div style={{
                    padding: 10, width: '100%', height: '100%'
                }}
                >
                    {
                        franchiseReportEntegrationData.generalEntegrationReportData.map((n, i) => (
                            <div style={{ display: 'block', marginTop: 10, backgroundColor: '#313131', padding: 3 }}>
                                <div style={{ backgroundColor: '#535c68', padding: 3, borderRadius: 5 }}>{(n.PlatformType == 0 ? "JaviPos" : (n.PlatformType == 0 ? "JaviPos" : (n.PlatformType == 1 ? "YemekSepeti" : (n.PlatformType == 2 ? "MigrosYemek" : (n.PlatformType == 3 ? "TrendyolYemek" : (n.PlatformType == 4 ? "GetirYemek" : (n.PlatformType == 5 ? "GoFody" : "Diğer")))))))}</div>
                                <div style={{ display: 'flex', padding: 3 }}>
                                    <div style={{ width: '50%', textAlign: 'left' }}>{n.TotalCount} {"Adisyon"}</div>
                                    <div style={{ width: '50%', textAlign: 'right' }}>{n.TotalAmount.toFixed(2)}</div>
                                </div>
                                <Divider />
                                {
                                    n.generalPaymentTotal.map((y, i) => (
                                        <div style={{ display: 'block', width: '100%' }}>
                                            <div style={{ display: 'flex', padding: 3 }}>
                                                <div className="printLeft" style={{ color: '#f39c12', width: '33.33%', fontSize: 13 }}>{y.PaymentName}</div>
                                                <div className="printLeft" style={{ color: '#f39c12', width: '33.33%', textAlign: 'center', fontSize: 13 }}>{y.TotalCount}</div>
                                                <div className="printLeft" style={{ color: '#f39c12', width: '33.33%', textAlign: 'right', fontSize: 13 }}>{y.TotalAmount.toFixed(2)}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                    <Divider style={{ backgroundColor:'grey', marginTop:10 }} />
                    {
                        franchiseReportEntegrationData.generalEntegrationReportDataBrand.map((n, i) => (
                            <div style={{ display: 'block', marginTop: 10, backgroundColor: '#424242', padding: 3 }}>
                                <div style={{ padding: 3, borderRadius: 5 }}>{n.BrandName}</div>
                                <Divider />
                                {
                                    n.generalEntegrationReportData.map((x, i) => (
                                        <div style={{ display: 'block', marginTop: 10, backgroundColor: '#313131', padding: 3 }}>
                                            <div style={{ backgroundColor: '#535c68', padding: 3, borderRadius: 5 }}>{(x.PlatformType == 0 ? "JaviPos" : (x.PlatformType == 0 ? "JaviPos" : (x.PlatformType == 1 ? "YemekSepeti" : (x.PlatformType == 2 ? "MigrosYemek" : (x.PlatformType == 3 ? "TrendyolYemek" : (x.PlatformType == 4 ? "GetirYemek" : (x.PlatformType == 5 ? "GoFody" : "Diğer")))))))}</div>
                                            <div style={{ display: 'flex', padding: 3 }}>
                                                <div style={{ width: '50%', textAlign: 'left' }}>{x.TotalCount} {"Adisyon"}</div>
                                                <div style={{ width: '50%', textAlign: 'right' }}>{x.TotalAmount.toFixed(2)}</div>
                                            </div>
                                            <Divider />
                                            {
                                                x.generalPaymentTotal.map((y, i) => (
                                                    <div style={{ display: 'block', width: '100%' }}>
                                                        <div style={{ display: 'flex', padding: 3 }}>
                                                            <div className="printLeft" style={{ color: '#f39c12', width: '33.33%', fontSize: 13 }}>{y.PaymentName}</div>
                                                            <div className="printLeft" style={{ color: '#f39c12', width: '33.33%', textAlign: 'center', fontSize: 13 }}>{y.TotalCount}</div>
                                                            <div className="printLeft" style={{ color: '#f39c12', width: '33.33%', textAlign: 'right', fontSize: 13 }}>{y.TotalAmount.toFixed(2)}</div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </Paper>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').franchiseReportEntegrationUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    franchiseReportEntegrationUpdate: value(state, props),
    franchiseReportName: state.get('centerBrandReducer').franchiseReportName,
    isCenterReportLoading: state.get('centerBrandReducer').isCenterReportLoading,
    filterReportProductList: state.get('centerBrandReducer').filterReportProductList,
    franchiseReportEntegrationData: state.get('centerBrandReducer').franchiseReportEntegrationData,
    centerBrandData: state.get('centerBrandReducer').centerBrandData,
    franchiseReportBrandSelected: state.get('centerBrandReducer').franchiseReportBrandSelected
});

const mapDispatchToProps = {
    getReportEntegration,
    setFranchiseReportProductSalesListFilter,
    setFranchiseReportBrandSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(EntegrationList)));
