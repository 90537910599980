import axios from 'axios';
var base64 = require('base-64');
const uuidv4 = require('uuid/v4');
import cookie from 'react-cookies';
import { HubConnectionBuilder, LogLevel, HttpTransportType, HubConnectionState } from "@microsoft/signalr";
import brand from 'dan-api/dummy/brand';
import { getParameter } from '../../function/GeneralFunction';
import {
  setModalReport
} from './report';
import {
  formDataSet
} from './addition';
import {
  setExpenseModal
} from './expense';
import moment from 'moment';

export const CUSTOMER_START = 'customer_start';
export const CUSTOMER_STOP = 'customer_stop';
export const CUSTOMER_ERROR = 'customer_error';
export const CUSTO_GLOBAL_FORM_DATA_SET = 'custo_global_form_data_set';
export const CUSTOMER_MODALCLOSE = 'customer_modalclose';
export const CUSTOMERLIST_VISIBLE = 'customerlist_visible';
export const CUSTOMERLIST_UPDATE = 'customerlist_update';
export const CUSTOMERLIST_LOADING = 'customerlist_loading';
export const CUSTOMER_ADDRESSLIST_SET = 'customer_addresslist_set';
export const CUSTOMER_NOTADDRESSLIST_SET = 'customer_notaddresslist_set';
export const CUSTOMER_ACCOUNTDETAIL_SET = 'customer_accountdetail_set';
export const CUSTOMER_ACCOUNTLIST_SET = 'customer_accountlist_set';

export const CUSTOMERACCOUNT_VISIBLE = 'customeraccount_visible';
export const CUSTOMERACCOUNT_UPDATE = 'customeraccount_update';

export const CUSTOMERADDACCOUNT_VISIBLE = 'customeraddaccount_visible';
export const CUSTOMERADDACCOUNT_UPDATE = 'customeraddaccount_update';
export const CUSTOMERADDACCOUNT_CLEAR = 'customeraddaccount_clear';

export const CUSTOMERADD_VISIBLE = 'customeradd_visible';
export const CUSTOMERADD_UPDATE = 'customeradd_update';
export const CUSTOMERADD_DATA = 'customeradd_data';
export const CUSTOMERADD_CLEAR = 'customeradd_clear';

export const CUSTOMERADD_STATUS = 'customeradd_status';

export const ACCOUNTLIST_UPDATE_REPORT = 'accountlist_update_report';
export const ACCOUNTLIST_LOADING = 'accountlist_loading';

export const STAFF_SHIFT_DATA = 'staff_shift_data';
export const STAFF_SHIFT_VISIBLE = 'staff_shift_visible';

export const STAFF_PARAMETERS_DATA = 'staff_parameters_data';
export const STAFF_PARAMETERS_VISIBLE = 'staff_parameters_visible';

let initialState = {
  form_data: {
    modalOpen: false,
    modalTitle: "",
    modalText: "",
    smsConfirmation: false,
    customerFilterModalOpen: false,
    customerFilterModalTitle: "",
    customerList: [],
    customerValue: "",

    customerSelected: null,
    customerNew: false,
    customerAddressNew: false,
    customerCallerIdNew: false,
    customerCallCenterNew: false,
    data: {
      Id: "00000000-0000-0000-0000-000000000000",
      Name: "",
      CustomerType: "0",
      TaxNumber: "",
      TaxAdministration: "",
      Email: "",
      IsEmailSend: true,
      IsSmsSend: true,
      CommercialTitle: "",
      IsNew: false
    },
    dataCallCenter: {
      Id: "00000000-0000-0000-0000-000000000000",
      Name: "",
      IsEmailSend: true,
      IsSmsSend: true,
      IsNew: false
    },
    prePhoneFilter: {
      StartDate: new Date(new Date().setHours(0, 0, 0, 0)),
      EndDate: new Date(new Date().setHours(23, 59, 59, 999)),
      BrandId: ""
    },
    prePhoneList: [],
    prePhoneModal: false
  },
  customerListUpdate: false,
  customerListVisible: false,
  filterData: {
    sortColumnName: "Name",
    sortOrder: "asc",
    totalRecord: 0,
    currentPage: 1,
    rowsPerPage: 50,
    searchText: "",
    BrandId: "",
    totalPage: 0,
    List: [],
    CustomerType: 0
  },
  customerListLoading: false,
  customerAddressList: [],
  customerNotAddressList: [],
  customerAddressListOpen: false,
  customerAccountDetail: null,
  customerAccountList: {
    Results: [],
    PageSize: 0,
    RowCount: 0,
    CurrentPage: 0
  },
  customerAccountUpdate: false,
  customerAccountVisible: false,

  customerAccountAddUpdate: false,
  customerAccountAddVisible: false,

  customerAccountAdd: {
    Amount: 0,
    Username: "",
    PaymentId: "",
    PaymentName: "",
    CustomerId: "",
    TenantId: "",
    BrandId: "",
    AccountAddType: 0
  },

  customerAddUpdate: false,
  customerAddVisible: false,
  customerAdd: null,
  customerAddStatus: false,

  customerAddIsUpdate: false,

  filterAccountListData: {
    totalRecord: 0,
    currentPage: 1,
    rowsPerPage: 100,
    BrandId: "",
    CustomerId: "",
    totalPage: 0,
    List: []
  },
  accountListLoading: false,
  accountCustomerId: null,
  staffShiftData: [],
  staffShiftVisible: false,
  staffShiftUpdate: false,
  staffCustomerId: "00000000-0000-0000-0000-000000000000",
  staffParametersData: {
    StartDateWork: new Date(),
    DepartmentWorks: "",
    PersonnelTitle: "",
    PaymentAdvanceLimit: 0,
    StaffFee: 0,
    OvertimePay: 0,
    AnnualLeaveDay: 0,
    PersonnelNote: ""
  },
  staffParametersVisible: false,
  staffParametersUpdate: false
}

export async function getAccountAdditionListExcel() {
  return async (dispatch, getState) => {
    const { customerAccountList } = getState().get('customerReducer');

    if (customerAccountList.Results.filter(x => x.IsSelected == true).length == 0) {
      dispatch(setModalCustomer("Excel'e aktarım yapmak için lütfen açık hesap adisyonu seçiniz!"));
      return;
    }

    var list = [];
    customerAccountList.Results.filter(x => x.IsSelected == true).map((n, i) => {
      list.push({ "Id": n.AdditionId });
    });
    axios({
      url: brand.urlSocket + 'api/Customer/getAccountAdditionListExcel',
      method: 'POST',
      responseType: 'blob',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: list,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'MusteriAdisyonListesi.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  };
}

export async function getAccountAdditionList(filterAccountListData, Id) {
  return async (dispatch, getState) => {
    dispatch({ type: ACCOUNTLIST_LOADING });
    filterAccountListData.BrandId = cookie.load("BrandId");
    filterAccountListData.CustomerId = Id;

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Customer/getAccountAdditionList',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: filterAccountListData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({
            type: ACCOUNTLIST_UPDATE_REPORT,
            data: json
          });
        }
      })
      .catch(function (error) {
        alert(error);
      });
    dispatch({ type: ACCOUNTLIST_LOADING });
  };
}

export async function filterPrePhone() {
  return async (dispatch, getState) => {
    const { prePhoneFilter } = getState().get('customerReducer').form_data;
    prePhoneFilter["BrandId"] = cookie.load('BrandId');

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Customer/getPrePhoneList',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: prePhoneFilter,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(formDataSetCustomer("prePhoneList", json));
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function getCustomer(type = -1) {
  return async (dispatch, getState) => {
    const { customerValue } = getState().get('customerReducer').form_data;
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/GetCustomer?value=' + customerValue + '&BrandId=' + cookie.load('BrandId') + '&type=' + type,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(formDataSetCustomer("customerList", json));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export async function formDataSetCustomer(key, val) {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTO_GLOBAL_FORM_DATA_SET,
      payload: { key, val }
    });
  };
}

export async function customerClear() {
  return async (dispatch, getState) => {
    var data = {
      Id: "00000000-0000-0000-0000-000000000000",
      Name: "",
      CustomerType: "0",
      TaxNumber: "",
      TaxAdministration: "",
      Email: "",
      IsEmailSend: true,
      IsSmsSend: true,
      CommercialTitle: ""
    };

    dispatch(formDataSetCustomer("data", data));
  }
}

export async function setCustomerListVisible(visible, type) {
  return async (dispatch, getState) => {
    if (visible) {
      var filterData = {
        sortColumnName: "Name",
        sortOrder: "asc",
        totalRecord: 0,
        currentPage: 1,
        rowsPerPage: 50,
        searchText: "",
        BrandId: "",
        totalPage: 0,
        List: [],
        CustomerType: type
      }
      dispatch(customerListUpdate(filterData));
    }
    dispatch({
      type: CUSTOMERLIST_VISIBLE,
      open: visible
    });
  };
}

export async function getCustomerList(filterData) {
  return async (dispatch, getState) => {
    dispatch({ type: CUSTOMERLIST_LOADING });
    filterData.BrandId = cookie.load("BrandId");

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Customer/getCustomerList',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: filterData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({
            type: CUSTOMERLIST_UPDATE,
            data: json
          });
        }
      })
      .catch(function (error) {
      });
    dispatch({ type: CUSTOMERLIST_LOADING });
  };
}

export async function setRemoveCustomerAccount(Id, CustomerId) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/setRemoveCustomerAccount?Id=' + Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          dispatch(getCustomerAccountDetail(CustomerId));
        }
      })
      .catch(function (error) {

      });
  }
}

export async function setCustomerAccountAdd(expense = "") {
  return async (dispatch, getState) => {
    const { customerAccountAdd, customerAccountDetail, filterData } = getState().get('customerReducer');
    if (customerAccountDetail == null) {
      dispatch(setExpenseModal("Personel veya Tedarikçi Seçimi yapmanız gerekmektedir!"));
      return;
    }

    customerAccountAdd.BrandId = cookie.load('BrandId');
    customerAccountAdd.TenantId = cookie.load('TenantId');
    customerAccountAdd.Username = cookie.load('UserName');
    customerAccountAdd.CustomerId = customerAccountDetail.CustomerId;

    if (filterData.CustomerType == 4 && customerAccountAdd.AccountAddType == 1) {
      customerAccountAdd.PaymentId = "00000000-0000-0000-0000-000000000000";
    } else {
      customerAccountAdd.PaymentName = getParameter("PaymentTypes").find(x => x.Id == customerAccountAdd.PaymentId).Name;
    }

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Customer/setCustomerAccountAdd',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: customerAccountAdd,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          dispatch(getCustomerAccountDetail(customerAccountDetail.CustomerId));
          dispatch(setCustomerAddAccountVisible(false));
        } else {
          if (expense != "") {
            dispatch(setExpenseModal(response.data.responseData));
          } else {
            dispatch(setModalCustomer(response.data.responseData));
          }
        }
      })
      .catch(function (error) {

      });
  }
}

export async function getNewCustomer(Id, OrderId = "00000000-0000-0000-0000-000000000000") {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getNewCustomer?Id=' + Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          json["OrderId"] = OrderId;
          dispatch({
            type: CUSTOMERADD_CLEAR,
            payload: json
          });
        }
      })
      .catch(function (error) {

      });
  }
}

export async function customerEdit(Id, OrderId = "00000000-0000-0000-0000-000000000000") {
  return async (dispatch, getState) => {
    await dispatch(getNewCustomer(Id, OrderId));
    dispatch({
      type: CUSTOMERADD_VISIBLE,
      open: true,
      customertype: "-1"
    });
  }
}

export async function setCustomer() {
  return async (dispatch, getState) => {
    dispatch({ type: CUSTOMERADD_STATUS });
    const { customerAdd, filterData } = getState().get('customerReducer');
    const { addition_data } = getState().get('additionReducer').form_data;
    customerAdd.BrandId = cookie.load("BrandId");
    customerAdd.TenantId = cookie.load("TenantId");
    customerAdd.CustomerType = Number(customerAdd.CustomerType);

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Customer/setCustomer',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: customerAdd,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          if (window.location.pathname.indexOf('detailpage') != -1) {
            addition_data.CustomerId = json.Id;
            addition_data.CustomerName = json.Name;
            dispatch(formDataSet('addition_data', addition_data));
            dispatch(setCustomerAddVisible(false));
          } else {
            dispatch(getNewCustomer(json.Id));
            dispatch(getCustomerList(filterData));
            dispatch(setModalCustomer(customerAdd.Id == "00000000-0000-0000-0000-000000000000" ? "Kayıt eklendi." : "Kayıt düzenlendi."));
          }
        } else {
          dispatch(setModalCustomer(response.data.responseData));
        }
      })
      .catch(function (error) {
        alert(error);
      });

    dispatch({ type: CUSTOMERADD_STATUS });
  };
}

export async function getCustomerAccountDetail(Id) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerAccountDetail?Id=' + Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setCustomerAccountList(json, true, Id));
          dispatch(getCustomerAccountList(Id, 1));
        }
      })
      .catch(function (error) {

      });
  }
}

export async function setRemoveCustomer(Id) {
  return async (dispatch, getState) => {
    const { filterData } = getState().get('customerReducer');
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/setRemoveCustomer?Id=' + Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          dispatch(getCustomerList(filterData));
        }
        dispatch(setModalCustomer(response.data.responseData));
      })
      .catch(function (error) {

      });
  }
}

export async function getCustomerAccountList(Id, page, type) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerAccountList?Id=' + Id + '&page=' + page,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({
            type: CUSTOMER_ACCOUNTLIST_SET,
            payload: json
          });
        }
      })
      .catch(function (error) {

      });
  }
}

export async function setBizimHesapInvoice(orderId) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/setBizimHesapInvoice?orderId=' + orderId,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        dispatch(setModalReport(response.data.responseData));
      })
      .catch(function (error) {

      });
  }
}

export async function getCustomerNotAddressList(Id) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerAddressList?Id=' + Id + '&BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setCustomerNotAddressList(json));
        } else {
          dispatch(setCustomerNotAddressList([]));
        }
      })
      .catch(function (error) {

      });
  }
}

export async function getCustomerAddressList(Id) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getCustomerAddressList?Id=' + Id + '&BrandId=' + cookie.load('BrandId'),
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setCustomerAddressList(json, true));
        } else {
          dispatch(setCustomerAddressList([], true));
        }
      })
      .catch(function (error) {

      });
  }
}

export async function setCustomerAddressList(data, open) {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTOMER_ADDRESSLIST_SET,
      payload: data,
      open: open
    });
  };
}

export async function setCustomerNotAddressList(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTOMER_NOTADDRESSLIST_SET,
      payload: data
    });
  };
}

export async function setCustomerAccountListData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTOMER_ACCOUNTLIST_SET,
      payload: data
    });
  };
}

export async function setCustomerAccountList(data, open, Id = "") {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTOMER_ACCOUNTDETAIL_SET,
      payload: data,
      open: open,
      Id: Id
    });
  };
}

export async function setCustomerAccountVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTOMERACCOUNT_VISIBLE,
      open: visible
    });
  };
}

export async function setCustomerAddAccountVisible(visible, price = 0, type = 0, expense = "") {
  return async (dispatch, getState) => {
    const { customerAccountDetail } = getState().get('customerReducer');
    if (visible) {
      if (expense == "expense") {
        var filterData = {
          sortColumnName: "Name",
          sortOrder: "asc",
          totalRecord: 0,
          currentPage: 1,
          rowsPerPage: 50,
          searchText: "",
          BrandId: expense,
          totalPage: 0,
          List: [],
          CustomerType: type
        }
        await dispatch(customerListUpdate(filterData));
        await dispatch(formDataSetCustomer('customerValue', ''));
        await dispatch(formDataSetCustomer('customerSelected', null));
        await dispatch({
          type: STAFF_PARAMETERS_DATA,
          payload: {
            StartDateWork: new Date(),
            DepartmentWorks: "",
            PersonnelTitle: "",
            PaymentAdvanceLimit: 0,
            StaffFee: 0,
            OvertimePay: 0,
            AnnualLeaveDay: 0,
            PersonnelNote: ""
          }
        });
      } else {
        await dispatch(getStaffParameters(customerAccountDetail.CustomerId, false))
      }
    } else {
      dispatch(setCustomerAccountList(null, false, ""))
    }

    await dispatch(setCustomerAddClear());
    dispatch({
      type: CUSTOMERADDACCOUNT_VISIBLE,
      open: visible,
      price: price
    });
  };
}

export async function setCustomerAddVisible(visible, type = "-1") {
  return async (dispatch, getState) => {
    await dispatch(getNewCustomer("00000000-0000-0000-0000-000000000000"));
    dispatch({
      type: CUSTOMERADD_VISIBLE,
      open: visible,
      customertype: type
    });
  };
}

export async function setCustomerAdd() {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTOMERADDACCOUNT_UPDATE
    });
  };
}

export async function setCustomerAddUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTOMERADD_UPDATE
    });
  };
}

export async function setCustomerAddClear() {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTOMERADDACCOUNT_CLEAR
    });
  };
}

export async function setModalCustomer(text) {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTOMER_ERROR,
      payload: text
    });
  };
}

export async function customerListUpdate(filterData) {
  return async (dispatch, getState) => {
    dispatch({
      type: CUSTOMERLIST_UPDATE,
      data: filterData
    });
  };
}

function stringToUuid(str) {
  str = str.replace('-', '');
  return 'xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, function (c, p) {
    return str[p % str.length];
  });
}

export async function setCustomerAddDataVisible(data) {
  return async (dispatch, getState) => {
    await dispatch(getNewCustomer("00000000-0000-0000-0000-000000000000"));
    const { customerAdd } = getState().get('customerReducer');
    customerAdd.CustomerType = "1";
    customerAdd.Name = data.CustomerName;
    customerAdd.Phone = data.Phone;
    customerAdd.Email = data.Email;
    customerAdd.TaxAdministration = data.VKN;
    customerAdd.TaxNumber = data.CustomerId;
    customerAdd.OrderId = "00000000-0000-0000-0000-000000000000";
    customerAdd.Id = uuidv4();

    await dispatch({
      type: CUSTOMERADD_DATA,
      payload: customerAdd
    });
  };
}

export async function setStaffShift() {
  return async (dispatch, getState) => {
    const { staffShiftData, staffCustomerId } = getState().get('customerReducer');
    var data = {
      ShiftStaff: staffShiftData,
      BrandId: cookie.load('BrandId'),
      TenantId: cookie.load('TenantId'),
      CustomerId: staffCustomerId
    };
    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Customer/setStaffShift',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: data
    })
      .then(function (response) {
        dispatch(setModalCustomer(response.data.responseData));
      })
      .catch(function (error) {

      });
  }
}

export async function setStaffParameters() {
  return async (dispatch, getState) => {
    const { staffParametersData, staffCustomerId } = getState().get('customerReducer');
    staffParametersData.CustomerId = staffCustomerId;
    staffParametersData.BrandId = cookie.load('BrandId');
    staffParametersData.TenantId = cookie.load('TenantId');

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Customer/setStaffParameters',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: staffParametersData
    })
      .then(function (response) {
        dispatch(setModalCustomer(response.data.responseData));
      })
      .catch(function (error) {

      });
  }
}


export async function getStaffParameters(Id, IsShift) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Customer/getStaffParameters?Id=' + Id + '&IsShift=' + IsShift,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          if (IsShift) {
            dispatch({
              type: STAFF_SHIFT_DATA,
              payload: json
            });
          } else {
            dispatch({
              type: STAFF_PARAMETERS_DATA,
              payload: json
            });
          }
        }
      })
      .catch(function (error) {

      });
  }
}

export async function setStaffShiftVisible(visible, Id) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getStaffParameters(Id, true));
    }
    dispatch({
      type: STAFF_SHIFT_VISIBLE,
      payload: visible,
      customerid: Id
    });
  };
}

export async function setStaffShiftData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: STAFF_SHIFT_DATA,
      payload: data
    });
  };
}

export async function setStaffParametersData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: STAFF_PARAMETERS_DATA,
      payload: data
    });
  };
}

export async function setStaffParametersVisible(visible, Id) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getStaffParameters(Id, false));
    }
    dispatch({
      type: STAFF_PARAMETERS_VISIBLE,
      payload: visible,
      customerid: Id
    });
  };
}

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAFF_PARAMETERS_VISIBLE:
      return { ...state, staffCustomerId: action.customerid, staffParametersVisible: action.payload, staffParametersUpdate: !state.staffParametersUpdate }
    case STAFF_PARAMETERS_DATA:
      return { ...state, staffParametersData: action.payload, staffParametersUpdate: !state.staffParametersUpdate }
    case STAFF_SHIFT_DATA:
      return { ...state, staffShiftData: action.payload, staffShiftUpdate: !state.staffShiftUpdate }
    case STAFF_SHIFT_VISIBLE:
      return { ...state, staffCustomerId: action.customerid, staffShiftVisible: action.payload, staffShiftUpdate: !state.staffShiftUpdate }
    case ACCOUNTLIST_UPDATE_REPORT:
      return { ...state, filterAccountListData: action.data }
    case ACCOUNTLIST_LOADING:
      return { ...state, accountListLoading: !state.accountListLoading }
    case CUSTOMER_START:
      return { ...state, loading: true }
    case CUSTOMERADD_CLEAR:
      return { ...state, customerAdd: action.payload }
    case CUSTOMERADD_STATUS:
      return { ...state, customerAddStatus: !state.customerAddStatus }
    case CUSTOMERADD_DATA:
      return { ...state, customerAdd: action.payload, customerAddUpdate: !state.customerAddUpdate, customerAddVisible: true }
    case CUSTOMERADD_UPDATE:
      return { ...state, customerAddUpdate: !state.customerAddUpdate }
    case CUSTOMERADD_VISIBLE:
      {
        return {
          ...state, customerAdd: {
            ...state.customerAdd,
            CustomerType: (action.customertype == "-1" ? state.customerAdd.CustomerType.toString() : action.customertype)
          }, customerAddVisible: action.open, customerAddUpdate: !state.customerAddUpdate
        }
      }
    case CUSTOMERACCOUNT_VISIBLE:
      return { ...state, customerAccountVisible: action.open, customerAccountUpdate: !state.customerAccountUpdate }
    case CUSTOMERADDACCOUNT_VISIBLE:
      return {
        ...state, customerAccountAdd: {
          ...state.customerAccountAdd,
          Amount: action.price,
          TransactionDate: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
        }, customerAccountAddVisible: action.open, customerAccountAddUpdate: !state.customerAccountAddUpdate
      }
    case CUSTOMERLIST_LOADING:
      return { ...state, customerListLoading: !state.customerListLoading }
    case CUSTOMER_ADDRESSLIST_SET:
      return { ...state, customerAddressList: action.payload, customerAddressListOpen: action.open }
    case CUSTOMER_NOTADDRESSLIST_SET:
      return { ...state, customerNotAddressList: action.payload }
    case CUSTOMER_ACCOUNTDETAIL_SET:
      return {
        ...state,
        customerAccountDetail: action.payload,
        customerAccountVisible: action.open,
        customerAccountUpdate: !state.customerAccountUpdate,
        accountCustomerId: action.Id
      }
    case CUSTOMER_ACCOUNTLIST_SET:
      return {
        ...state,
        customerAccountList: action.payload,
        customerAccountUpdate: !state.customerAccountUpdate
      }
    case CUSTOMERLIST_VISIBLE:
      return { ...state, customerListVisible: action.open }
    case CUSTOMERADDACCOUNT_CLEAR:
      return {
        ...state, customerAccountAdd: {
          TransactionDate: moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
          Amount: 0,
          Username: "",
          PaymentId: "",
          PaymentName: "",
          CustomerId: "",
          TenantId: "",
          BrandId: "",
          AccountAddType: 0
        }
      }
    case CUSTOMERADDACCOUNT_UPDATE:
      return { ...state, customerAccountAddUpdate: !state.customerAccountAddUpdate }
    case CUSTOMERLIST_UPDATE:
      return { ...state, filterData: action.data, customerListUpdate: !state.customerListUpdate }
    case CUSTOMER_ERROR:
      return {
        ...state, form_data: {
          ...state.form_data,
          modalOpen: true,
          modalText: action.payload,
          modalTitle: "Uyarı"
        }
      }
    case CUSTOMER_STOP:
      return { ...state, loading: false, data: action.payload }
    case CUSTO_GLOBAL_FORM_DATA_SET:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          [action.payload.key]: action.payload.val
        }
      }
    case CUSTOMER_MODALCLOSE:
      return { ...state, modalOpen: false }
    default:
      return state;
  }
}

export default customerReducer;
