import React from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Divider from '@material-ui/core/Divider';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import TablePagination from '@material-ui/core/TablePagination';
import TbPaginationActions from '../../Ortak/TbPaginationActions';
import { EmptyData } from 'dan-components';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import {
    setCustomerAccountVisible, setRemoveCustomerAccount, setCustomerAddAccountVisible,
    getAccountAdditionList, getAccountAdditionListExcel, setCustomerAccountListData
} from '../../../../redux/modules/customer';
import {
    getStockMovementDetail
} from '../../../../redux/modules/stock';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import messageStyles from 'dan-styles/Messages.scss';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DatePicker from "react-datepicker";
import "../datepicker.css";
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { PapperBlock } from 'dan-components';
import Avatar from '@material-ui/core/Avatar';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AddIcon from '@material-ui/icons/Add';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckIcon from '@material-ui/icons/Check';
import styles from './widget-jss';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PaymentIcon from '@material-ui/icons/Payment';
import HistoryIcon from '@material-ui/icons/History';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import CustomerAccountAdd from './CustomerAccountAdd';
import { getParameter, trMoneyConvert } from '../../../../function/GeneralFunction';
import { getAuthorization } from '../../../../components/Authorization';
import ProductQuantityAdd from '../Product/ProductQuantityAdd';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class CustomerAccount extends React.Component {
    constructor(props) {
        super(props);

        this.state = { value: 0, startDate: new Date(new Date().setHours(0, 0, 0, 0)), endDate: new Date(new Date().setHours(23, 59, 59, 999)) }
    }

    async componentDidMount() {
        var TimeData = getParameter("BusinessWorkingHours");
        await this.setState({ startDate: TimeData.start, endDate: TimeData.end })
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    renderBakiye() {
        const { classes, customerAccountDetail, filterData } = this.props;
        return (
            <Grid container  style={{ margin: 0 }}>
                <Grid item md={6} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar style={{ backgroundColor: 'white', margin: 10 }}>
                        <Icon>{"person"}</Icon>
                    </Avatar>
                    <Typography variant="h6" style={{ color: 'white', fontWeight: 500 }}>
                        {(customerAccountDetail != null ? customerAccountDetail.CustomerName : "")}
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ border: '1px solid rgba(52, 152, 219,.5)', borderRadius: 5, width: '100%', display: 'flex', padding: 8, margin: 5 }}>
                        <Avatar style={{ backgroundColor: '#3498db', margin: 10 }}>
                            <AccountBalanceWalletIcon />
                        </Avatar>
                        <Typography variant="h6">
                            <span style={{ color: '#3498db' }}>{(customerAccountDetail != null ? trMoneyConvert(customerAccountDetail.TotalAmount, 2) : 0)}</span>
                            <Typography style={{ color: 'white' }}>{"Borç"}</Typography>
                        </Typography>
                    </div>
                    <div style={{ border: '1px solid rgba(231, 76, 60,.5)', borderRadius: 5, width: '100%', display: 'flex', padding: 8, margin: 5 }}>
                        <Avatar style={{ backgroundColor: '#e74c3c', margin: 10 }}>
                            <AddIcon />
                        </Avatar>
                        <Typography variant="h6">
                            <span style={{ color: '#e74c3c' }}>{(customerAccountDetail != null ? trMoneyConvert(customerAccountDetail.AmountPaid, 2) : 0)}</span>
                            <Typography style={{ color: 'white' }}>{"Alacak"}</Typography>
                        </Typography>
                    </div>
                    <div style={{ border: '1px solid rgba(26, 188, 156,.5)', borderRadius: 5, width: '100%', display: 'flex', padding: 8, margin: 5 }}>
                        <Avatar style={{ backgroundColor: '#1abc9c', margin: 10 }}>
                            <AttachMoneyIcon />
                        </Avatar>
                        <Typography variant="h6">
                            <span style={{ color: '#16a085' }}>{(customerAccountDetail != null ? trMoneyConvert(customerAccountDetail.AvailableBalance, 2) : 0)}</span>
                            <Typography style={{ color: 'white' }}>{"Bakiye"}</Typography>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }

    handleChangePage = (event, page) => {
        const { accountCustomerId } = this.props;
        this.props.getCustomerAccountList(accountCustomerId, page + 1).then(() => { })
    };

    clickSelectInvoice(Id, status) {
        const { customerAccountList } = this.props;
        customerAccountList.Results.find(x => x.Id == Id).IsSelected = !status;
        this.props.setCustomerAccountListData(customerAccountList).then(() => { });
    }

    renderSelectBakiye() {
        const { customerAccountList } = this.props;
        var price = 0;
        customerAccountList.Results.map((n, i) => {
            if (n.IsSelected) {
                price += n.Amount;
            }
        })
        return price;
    }

    render() {
        const { classes, customerAccountDetail, customerAccountList, filterData, accountListLoading } = this.props;
        const { value } = this.state;
        return (
            <Dialog
                fullScreen
                open={this.props.customerAccountVisible}
                TransitionComponent={Transition}
            >
                <ProductQuantityAdd classes={classes} />
                <CustomerAccountAdd total={(customerAccountDetail != null ? customerAccountDetail.AvailableBalance : 0)} classes={classes} />
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%' }}>
                    <Toolbar>
                        <Button color="inherit" onClick={() => this.props.setCustomerAccountVisible(false).then(() => { })}>{"Geri"}</Button>
                        <Typography variant="h6" color="inherit" className={classes.flex}>{(filterData.CustomerType == 0 ? "Müşteri Açık Hesap İşlemleri" : (filterData.CustomerType == 1 ? "Tedarikçi Hesap İşlemleri" : (filterData.CustomerType == 4 ? "Personel Hesap İşlemleri" : "")))}</Typography>
                        <Button onClick={() => (confirm("Seçili adisyon listesini excel'e aktarmak istediğinize emin misiniz?") ? this.props.getAccountAdditionListExcel().then(() => { }) : null)} style={{ marginRight: 5 }} variant="contained" color="default">
                            <Icon style={{ marginRight: 10, color: '#424242' }}>{"file_open"}</Icon>
                            {"Excele Aktar"}
                            {(accountListLoading ? <CircularProgress style={{ color: 'white', marginLeft: 10 }} disableShrink thickness={3} size={15} /> : null)}
                        </Button>
                        <Button style={{ width: 250 }} color="secondary" variant="contained" onClick={() => this.props.setCustomerAddAccountVisible(true, this.renderSelectBakiye(), filterData.CustomerType).then(() => { })}>{(customerAccountList.Results.filter(x => x.IsSelected == true).length > 0 ? <span style={{ paddingLeft: 10, paddingRight: 10, marginRight: 10, borderRadius: 10, backgroundColor: 'grey' }}>{this.renderSelectBakiye().toFixed(2)} {"₺"}</span> : "")} {"HAREKET İŞLE"}</Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.detailWrap} style={{ overflow: 'hidden', height: '100%', backgroundColor: 'rgb(48, 48, 48)', marginTop: 64, padding: 20 }}>
                    {
                        this.renderBakiye()
                    }
                    <Divider />
                    <div style={{ height: '100%', marginTop: 5 }}>
                        <Grid container spacing={1} style={{ height: '100%', overflowY: 'auto', paddingBottom: 70 }}>
                            <Grid item md={12} xs={12}>
                                <Paper>
                                    <div className={classes.rootTable} style={{ marginTop: 15 }}>
                                        <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 0 }}>
                                            <TableHead style={{ backgroundColor: 'rgb(48, 48, 48)' }}>
                                                <TableRow>
                                                    {(filterData.CustomerType == 0 ? <TableCell>{"Seç"}</TableCell> : null)}
                                                    <TableCell>{"Adisyon No"}</TableCell>
                                                    <TableCell>{"Kullanıcı"}</TableCell>
                                                    <TableCell>{"Tarihi"}</TableCell>
                                                    <TableCell>{"Tutar"}</TableCell>
                                                    <TableCell>{"İndirim"}</TableCell>
                                                    <TableCell>{"Tipi"}</TableCell>
                                                    <TableCell>{"İşlem"}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    customerAccountList.Results.map((n, i) => {
                                                        return (
                                                            <TableRow style={{ height: 50 }} hover key={i}>
                                                                {(filterData.CustomerType == 0 ?
                                                                    <TableCell align="left" style={{ padding: '4px 10px 4px 15px' }}>
                                                                        {
                                                                            (n.OperationType == 0 ?
                                                                                <IconButton style={{ color: (n.IsSelected ? '#4CAF50' : '') }} onClick={() => this.clickSelectInvoice(n.Id, n.IsSelected)}>
                                                                                    <Icon>{(n.IsSelected ? 'check_box' : 'check_box_outline_blank')}</Icon>
                                                                                </IconButton> : null)
                                                                        }
                                                                    </TableCell> : null)}
                                                                <TableCell>{n.AdditionNo}</TableCell>
                                                                <TableCell>{n.Username}</TableCell>
                                                                <TableCell>{moment(n.CreationDatetime).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
                                                                <TableCell>{trMoneyConvert(n.Amount, 2)}</TableCell>
                                                                <TableCell>{trMoneyConvert(n.DiscountAmount, 2)}</TableCell>
                                                                <TableCell style={{ fontWeight: 600 }}>
                                                                    {
                                                                        (filterData.CustomerType == 0 && n.OperationType == 1 ? "Tahsilat" : ((filterData.CustomerType == 4 || filterData.CustomerType == 1) && n.OperationType == 0 && n.AdditionNo.length == 0 ? "Ödeme" : ((filterData.CustomerType == 0 || filterData.CustomerType == 1 || filterData.CustomerType == 4) && n.AdditionNo.length > 0 ? "Açık Hesap" : (filterData.CustomerType == 1 && n.OperationType == 1 ? "Alış Fişi" : (filterData.CustomerType == 4 && n.OperationType == 1 ? "Hakediş" : "İndirim")))))
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Tooltip title="Düzenle">
                                                                        <IconButton style={{ backgroundColor: '#3e3e3e', display: (filterData.CustomerType == 1 && n.OperationType == 1 ? '' : 'none') }} aria-label="Package" onClick={() => this.props.getStockMovementDetail(n.AdditionId).then(() => { })} >
                                                                            <Icon>{"edit"}</Icon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Sil">
                                                                        <IconButton disabled={!getAuthorization("DeleteCollectionAuthority")} style={{ backgroundColor: '#3e3e3e', display: (n.OperationType == 1 || n.OperationType == 3 ? (n.OperationType == 1 && n.AdditionNo.length == 0 ? '' : 'none') : '') }} aria-label="Package" onClick={() => (confirm('Hareketi silmek istediğinize emin misimiz?') ? this.props.setRemoveCustomerAccount(n.Id, n.CustomerId).then(() => { }) : null)} >
                                                                            <Icon>{"delete"}</Icon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                            </TableBody>
                                        </Table>
                                        <div style={{ width: '100%', display: 'table' }}>
                                            <TablePagination
                                                component="div"
                                                rowsPerPageOptions={[100]}
                                                rowsPerPage={customerAccountList.PageSize}
                                                count={customerAccountList.RowCount}
                                                page={customerAccountList.CurrentPage - 1}
                                                onChangePage={this.handleChangePage}
                                                ActionsComponent={TbPaginationActions}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('customerReducer').customerAccountUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    customerAccountUpdate: value(state, props),
    customerAccountVisible: state.get('customerReducer').customerAccountVisible,
    customerAccountDetail: state.get('customerReducer').customerAccountDetail,
    customerAccountList: state.get('customerReducer').customerAccountList,
    customerAccountAddUpdate: state.get('customerReducer').customerAccountAddUpdate,
    customerAccountAddVisible: state.get('customerReducer').customerAccountAddVisible,
    filterData: state.get('customerReducer').filterData,
    filterAccountListData: state.get('customerReducer').filterAccountListData,
    accountListLoading: state.get('customerReducer').accountListLoading,
    accountCustomerId: state.get('customerReducer').accountCustomerId
})

const mapDispatchToProps = {
    setCustomerAccountVisible,
    setRemoveCustomerAccount,
    setCustomerAddAccountVisible,
    getAccountAdditionList,
    getAccountAdditionListExcel,
    setCustomerAccountListData,
    getStockMovementDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(CustomerAccount)));