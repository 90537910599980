import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../../CenterBrandPage/centerbrandpage-jss';
import {
    setPaketYoldaNewReportVisible, setPaketYoldaReportData, getOrderGeneralReport
} from '../../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';

import GeneralReport from './GeneralReport';
import OrderMapReport from './OrderMapReport';

const fontSizeTextItem = {
    fontSize: 14
};

const fontSizeTextItemSelected = {
    fontSize: 15,
    fontWeight: 600,
    color: '#16a085'
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Report extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaReportUpdate == this.props.paketYoldaReportUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() { }

    async handleFilterGeneralBack() {
        const { paketYoldaGeneralReportfilter } = this.props;
        paketYoldaGeneralReportfilter.IsDay = true;
        await this.props.getOrderGeneralReport().then(() => { });
        this.props.setPaketYoldaNewReportVisible(false).then(() => { })
    }

    render() {
        const { classes, paketYoldaReportVisible, paketYoldaReportMenu } = this.props;

        return (
            <Dialog
                open={paketYoldaReportVisible}
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%', backgroundImage: 'linear-gradient(-45deg, rgb(239, 83, 80) 0%, rgb(239, 83, 80) 33%, rgb(96, 125, 139) 100%)' }}>
                    <Toolbar>
                        <Button variant="outlined" color="inherit" onClick={() => this.handleFilterGeneralBack()}>
                            <Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon>
                        </Button>
                        <Typography style={{ textAlign: 'center', width: '100%', fontWeight: 600 }} variant="h7" color="inherit" className={classes.flex}>{"Raporlar"}</Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64 }}>
                    <Grid container style={{ height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
                        <Grid item md={3} style={{ height: '100%', padding: 4, overflowY: 'auto' }}>
                            <div style={{ height: '100%', backgroundColor: '#424242' }}>
                                <List
                                    component="nav"
                                    subheader={<ListSubheader style={{ position: 'inherit' }} component="div">{"Raporlar"}</ListSubheader>}
                                >
                                    <Divider />
                                    <List component="div" disablePadding>
                                        <ListItem onClick={() => this.props.setPaketYoldaReportData("GeneralReport").then(() => { })} button className={classes.nested}>
                                            <ListItemIcon style={{ minWidth: 35 }}>
                                                <Icon>{"manage_accounts"}</Icon>
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ style: (paketYoldaReportMenu == "GeneralReport" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Hesap Raporları" />
                                            {(
                                                paketYoldaReportMenu == "GeneralReport" ?
                                                    <ListItemIcon style={{ minWidth: 35 }}>
                                                        <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                    </ListItemIcon> : null
                                            )}
                                        </ListItem>
                                    </List>
                                    <Divider />
                                    <List component="div" disablePadding>
                                        <ListItem onClick={() => this.props.setPaketYoldaReportData("HeatMapReport").then(() => { })} button className={classes.nested}>
                                            <ListItemIcon style={{ minWidth: 35 }}>
                                                <Icon>{"share_location"}</Icon>
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ style: (paketYoldaReportMenu == "HeatMapReport" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Sipariş Isı Haritası" />
                                            {(
                                                paketYoldaReportMenu == "HeatMapReport" ?
                                                    <ListItemIcon style={{ minWidth: 35 }}>
                                                        <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                    </ListItemIcon> : null
                                            )}
                                        </ListItem>
                                    </List>
                                </List>
                            </div>
                        </Grid>
                        <Grid item md={9} style={{ height: '100%', padding: 4 }}>
                            <div style={{ height: '100%', backgroundColor: '#424242', overflow: 'hidden' }}>
                                <GeneralReport classes={classes} />
                                <OrderMapReport classes={classes} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        );
    }
}

Report.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaReportUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaReportUpdate: value(state, props),
    paketYoldaReportVisible: state.get('paketYoldaReducer').paketYoldaReportVisible,
    paketYoldaReportMenu: state.get('paketYoldaReducer').paketYoldaReportMenu,

    paketYoldaGeneralReportfilter: state.get('paketYoldaReducer').paketYoldaGeneralReportfilter,
});

const mapDispatchToProps = {
    setPaketYoldaNewReportVisible,
    setPaketYoldaReportData,
    getOrderGeneralReport
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(Report)));
