module.exports = [
    {
      key: 'pages',
      name: 'LİSANS',
      icon: 'ios-paper-outline',
      child: [
        {
          key: 'other_page',
          name: 'LİSANS İŞLEMLERİ',
          title: true,
        },
        {
          key: 'allLicence',
          name: 'Tüm Lisanslar',
          link: '#'
        },
        {
          key: 'detailLicence',
          name: 'Süresi Dolan Lisanslar',
          link: '#'
        },
      ]
    },
    {
      key: 'user',
      name: 'Kullanıcılar',
      icon: 'ios-contact-outline',
      child: [
        {
          key: 'user_page',
          name: 'KULLANICI İŞLEMLERİ',
          title: true,
        },
        {
          key: 'alluser',
          name: 'Kullanıcı Listesi',
          link: '#'
        },
      ]
    },
    {
      key: 'report',
      name: 'RAPORLAR',
      icon: 'ios-paw-outline',
      child: [
        {
          key: 'report_page',
          name: 'RAPORLAMA İŞLEMLERİ',
          title: true,
        },
      ]
    },
  ];
  