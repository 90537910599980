import React, { Fragment } from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage, injectIntl } from 'react-intl';
import Icon from '@material-ui/core/Icon';
const uuidv4 = require('uuid/v4');
import {
    setFranchiseSadakatVisible, setFranchiseReportName, getBrandList, setFranchiseReportBrandSelected,
    getCenterSettings, setFranchiseSadakatData, setJaviAppSettings,
    setCampaignListData, setCampaignUpdate, setCampaign, setSaveImageApp, setModalCenterBrand
} from '../../../redux/modules/centerbrand';
import { getStock } from '../../../redux/modules/stock';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import StarBorder from '@material-ui/icons/StarBorder';
import Collapse from '@material-ui/core/Collapse';
import ModalInformation from '../Ortak/ModalInformation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import OrderHeat from './Report/OrderHeat';
import Reviews from './Report/Reviews';
import DailyMonthlyGraffic from './Report/DailyMonthlyGraffic';
import GeneralTotal from './Report/GeneralTotal';
import ProductSalesList from './Report/ProductSalesList';
import EntegrationList from './Report/EntegrationList';
import AdditionReportList from './Report/AdditionReportList';
import Logs from './Report/Logs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import DatePicker, { registerLocale } from "react-datepicker";
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            fixedDecimalScale={true}
            autocomplete="off"
            allowNegative={false}
            decimalSeparator=","
            thousandSeparator="."
            prefix="₺"
        />
    );
}

function renderInput(inputProps) {
    const { classes, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: ref,
                classes: {
                    input: classes.input,
                },
                ...other,
            }}
        />
    );
}

function renderSuggestionsContainer(options) {
    const { containerProps, children } = options;

    return (
        <Paper {...containerProps} square>
            {children}
        </Paper>
    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => (
                    part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </strong>
                    )
                ))}
            </div>
        </MenuItem>
    );
}


function NumberFormatCustomDiscount(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            decimalScale={0}
            allowNegative={true}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            prefix="%"
        />
    );
}


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Sadakat extends React.Component {
    constructor(props) {
        super(props);
        this.state = { search: "", value: '', suggestions: [], inputList: {} }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.franchiseSadakatUpdate == this.props.franchiseSadakatUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        await this.props.getBrandList().then(() => { });
        await this.props.getCenterSettings().then(() => { });
        await this.props.getStock(false).then(() => { });
    }

    handleChangeData = (name) => event => {
        const { franchiseSadakatData } = this.props;
        franchiseSadakatData[name] = (name == "IsApp" || name == "IsCreditCard" || name == "IsCash" ? event.target.checked : event.target.value);
        this.props.setFranchiseSadakatData(franchiseSadakatData).then(() => { });
    };

    handleChangeDataWellcome = (name) => event => {
        const { franchiseSadakatData } = this.props;
        franchiseSadakatData.WellcomeCam[name] = (name == "IsStatus" ? event.target.checked : event.target.value);
        this.props.setFranchiseSadakatData(franchiseSadakatData).then(() => { });
    };

    handleChangeDataFriends = (name) => event => {
        const { franchiseSadakatData } = this.props;
        franchiseSadakatData.FriendsInvite[name] = (name == "DiscountAmount" ? parseFloat(event.target.value) : (name == "IsStatus" ? event.target.checked : event.target.value));
        this.props.setFranchiseSadakatData(franchiseSadakatData).then(() => { });
    };

    handleChangeWorkingHours = (day, type) => event => {
        const { franchiseSadakatData } = this.props;
        franchiseSadakatData.WorkingHours.find(x => x.Day == day)[type] = (type == "StartHour" || type == "EndHour" ? moment.utc(event, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm") : event.target.checked);
        this.props.setFranchiseSadakatData(franchiseSadakatData).then(() => { });
    };

    changeAllDayHours() {
        const { franchiseSadakatData } = this.props;
        var pazartesi = franchiseSadakatData.WorkingHours.find(x => x.Day == "Pazartesi");
        franchiseSadakatData.WorkingHours.map((n, i) => {
            n.StartHour = pazartesi.StartHour;
            n.EndHour = pazartesi.EndHour;
            n.Hour24 = pazartesi.Hour24;
        })
        this.props.setFranchiseSadakatData(franchiseSadakatData).then(() => { });
    }

    productAutoComplate(props) {
        const { productListSearch } = this.props;
        const suggestionsApi = [];

        productListSearch.filter(x => x.IsList == true).map((pro, key) => {
            suggestionsApi.push({ label: pro.Name, value: pro.Id });
        });

        return suggestionsApi;
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0 ? [] : this.productAutoComplate().filter(suggestion => {
            //onst keep = count < 10 && suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;
            //console.log("suggestion.label.toLowerCase().indexOf(inputValue)",suggestion.label.toLowerCase().indexOf(inputValue))
            const keep = count < 15 && suggestion.label.toLowerCase().indexOf(inputValue) !== -1;
            if (keep) {
                count += 1;
            }

            return keep;
        });
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };


    handleChangeDiscountProductAddCart = (Id) => event => {
        const { CampaignListData } = this.props;
        CampaignListData.find(x => x.Id == Id).Limit = event.target.value;
        this.props.setCampaignListData(CampaignListData).then(() => { });
    };

    getSuggestionValue = Id => (suggestion) => {
        const { CampaignListData } = this.props;
        CampaignListData.find(x => x.Id == Id).ProductsToBeAdded[0].ProductId = suggestion.value;
        CampaignListData.find(x => x.Id == Id).ProductsToBeAdded[0].ProductName = suggestion.label;
        this.props.setCampaignListData(CampaignListData).then(() => { });
        return suggestion.label;
    }

    async clickAddDiscountProduct() {
        const { DiscountProduct, CampaignListData } = this.props;
        var addDiscount = JSON.parse(JSON.stringify(DiscountProduct));
        addDiscount.Id = uuidv4();
        CampaignListData.push(addDiscount);
        await this.props.setCampaignListData(CampaignListData).then(() => { });
    }

    async clickAddGeneralDiscount() {
        const { GeneralDiscount, CampaignListData } = this.props;
        var addDiscount = JSON.parse(JSON.stringify(GeneralDiscount));
        addDiscount.Id = uuidv4();
        CampaignListData.push(addDiscount);
        await this.props.setCampaignListData(CampaignListData).then(() => { });
    }

    handleChangeMenuSelect = Id => (event, { newValue }) => {
        this.state.inputList[Id] = newValue;
        this.setState({ inputList: this.state.inputList });
        this.props.setCampaignUpdate().then(() => { });
    };

    handleChangeDiscountProduct = (Id, Name) => event => {
        const { CampaignListData } = this.props;
        var data = CampaignListData.find(x => x.Id == Id);
        if (Name == "DiscountRate") {
            if (Name == "DiscountRate") {
                data.ProductsToBeAdded[0][Name] = event.target.value;
            }
        } else {
            data[Name] = (Name == "StartDate" || Name == "EndDate" ? moment.utc(event, 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm") : (Name == "IsStatus" ? !data.IsStatus : event.target.value));
        }
        this.props.setCampaignUpdate().then(() => { });
    };

    handleChangeGeneralDiscount = (Id, Name) => event => {
        const { CampaignListData } = this.props;
        var data = CampaignListData.find(x => x.Id == Id);
        if (Name == "DiscountRate") {
            data.ProductsToBeAdded[0][Name] = event.target.value
        } else {
            data[Name] = (Name == "StartDate" || Name == "EndDate" ? moment.utc(event, 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm") : (Name == "IsStatus" ? !data.IsStatus : event.target.value));
        }
        this.props.setCampaignUpdate().then(() => { });
    };

    handleChangeCampaignRemove(Id) {
        const { CampaignListData } = this.props;
        CampaignListData.find(x => x.Id == Id).IsDelete = true;
        this.props.setCampaignUpdate().then(() => { });
    };

    renderMenuItemAuto(Id, ProductId) {
        const { classes } = this.props;
        const { value, suggestions } = this.state;
        return (
            <Autosuggest
                theme={{
                    input: classes.inputText,
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderInputComponent={renderInput}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={this.getSuggestionValue(Id)}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    classes,
                    placeholder: this.getProductName(ProductId),
                    value: this.state.inputList[Id] || '',
                    onChange: this.handleChangeMenuSelect(Id)
                }}
            />
        )
    }

    getProductName(StockId) {
        const { productListSearch } = this.props;
        var proName = "Lütfen ürün seçiniz";
        if (productListSearch.filter(x => x.Id == StockId).length > 0) {
            proName = productListSearch.find(x => x.Id == StockId).Name;
        }
        return proName;
    }

    returnProductList() {
        const { productListSearch } = this.props;
        var list = [];
        productListSearch.map((n, i) => {
            list.push({ ProductName: n.Name, ProductId: n.Id });
        })
        return list;
    }

    handleChangeMultiProductAuto(Id, data) {
        const { CampaignListData } = this.props;
        CampaignListData.find(x => x.Id == Id).ProductsToBeAdded = data;
        this.props.setCampaignUpdate().then(() => { });
    };

    returnProductSelectList(product) {
        var list = [];
        product.filter(x => x.ProductId != '00000000-0000-0000-0000-000000000000').map((n, i) => {
            list.push({ "ProductId": n.ProductId, "ProductName": n.ProductName });
        })
        return list;
    }

    async handleSave() {
        await this.props.setJaviAppSettings().then(() => { })
        await this.props.setCampaign().then(() => { })
    };

    handleChangeMultiProductAutoSecond(Id, data) {
        const { CampaignListData } = this.props;
        CampaignListData.find(x => x.Id == Id).ProductsAdded = data;
        this.props.setCampaignUpdate().then(() => { });
    };

    addDefaultSrc(ev) {
        ev.target.src = 'https://cdn.javipos.com/broken.png';
    }

    onChangeImageFile = event => {
        var type = event.currentTarget.getAttribute('dataType');
        var campaId = event.currentTarget.getAttribute('campaid');
        var dataFile = event.target.files[0];

        const fileExtension = dataFile.name.split(".").at(-1);
        const allowedFileTypes = ["png"];
        if (!allowedFileTypes.includes(fileExtension)) {
            this.props.setModalCenterBrand("Sadece .png resim formatı desteklenmektedir.").then(() => { })
            return;
        }

        var filesize = ((dataFile.size / 1024) / 1024).toFixed(4); // MB
        if (filesize > 2) {
            this.props.setModalCenterBrand("Görsel dosya boyutu maksimum 2mb olabilir").then(() => { })
            return;
        }

        this.props.setSaveImageApp(dataFile, type, 1200, 600, campaId).then(() => { });
    };

    render() {
        const {
            classes,
            franchiseSadakatData,
            franchiseSadakatVisible,
            CampaignListData,
            franchiseSadakatLoading
        } = this.props;

        const { suggestions } = this.state;

        var DiscountProduct = [];
        var GeneralDiscount = [];
        var EarnAsYouEat = [];
        CampaignListData.filter(x => x.CampaignType == 0 && x.IsDelete == false).map((n, i) => {
            DiscountProduct.push({
                "Id": n.Id,
                "IsStatus": n.IsStatus,
                "SalesType": n.SalesType,
                "StartDate": n.StartDate,
                "EndDate": n.EndDate,
                "ProductsToBeAdded": n.ProductsToBeAdded,
                "ImageUrl": n.ImageUrl,
            })
        })

        CampaignListData.filter(x => x.CampaignType == 2 && x.IsDelete == false).map((n, i) => {
            GeneralDiscount.push({
                "Id": n.Id,
                "IsStatus": n.IsStatus,
                "SalesType": n.SalesType,
                "StartDate": n.StartDate,
                "EndDate": n.EndDate,
                "ProductsToBeAdded": n.ProductsToBeAdded,
                "ImageUrl": n.ImageUrl,
            })
        })

        CampaignListData.filter(x => x.CampaignType == 3 && x.IsDelete == false).map((n, i) => {
            EarnAsYouEat.push({
                "Id": n.Id,
                "IsStatus": n.IsStatus,
                "SalesType": 2,
                "StartDate": n.StartDate,
                "EndDate": n.EndDate,
                "ProductsAdded": n.ProductsAdded,
                "ProductsToBeAdded": n.ProductsToBeAdded,
                "Limit": n.Limit,
                "ImageUrl": n.ImageUrl,
            })
        })

        return (
            <Dialog
                fullScreen
                open={franchiseSadakatVisible}
                TransitionComponent={Transition}
            >
                {(franchiseSadakatLoading ?
                    <div style={{ justifyContent: 'center', zIndex: 99999, position: 'absolute', bottom: 0, left: 0, right: 0, top: 0, backgroundColor: 'rgba(45, 52, 54,.6)' }}>
                        <div style={{ justifyItems: 'center', display: 'inline-grid', transform: 'translate(-50%, -50%)', left: '50%', position: 'absolute', top: '50%' }}>
                            <CircularProgress color='#fff' disableShrink thickness={2} size={70} />
                            <span style={{ marginTop: 10 }}>{'Resim ölçeklendirilip kayıt ediliyor..'}</span>
                        </div>
                    </div> : null)}
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%' }}>
                    <Toolbar>
                        <Button variant='contained' onClick={() => this.props.setFranchiseSadakatVisible(false).then(() => { })}>
                            <Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon>
                        </Button>
                        <Typography variant="h6" color="inherit" className={classes.flex}>{"JaviSadakat Uygulaması Ayarları"}</Typography>
                        <Button color="inherit" variant="outlined" onClick={() => this.handleSave()}>{"BİLGİLERİ KAYDET"}</Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64 }}>
                        <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '2px solid #7f8c8d', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span>{"İşyerimPos Api Bilgileri"}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ marginRight: 10, fontSize: 13, textAlign: 'left', width: '33.33%' }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={franchiseSadakatData.MerchantId}
                                    onChange={this.handleChangeData("MerchantId")}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                    placeholder='Üye İşyeri ID'
                                />
                            </div>
                            <div style={{ marginRight: 10, fontSize: 13, textAlign: 'left', width: '33.33%' }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={franchiseSadakatData.UserId}
                                    onChange={this.handleChangeData("UserId")}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                    placeholder='Kullanıcı ID'
                                />
                            </div>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '33.33%' }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={franchiseSadakatData.ApiKey}
                                    onChange={this.handleChangeData("ApiKey")}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                    placeholder='Entegrasyon Api Key'
                                />
                            </div>
                        </div>
                        <div style={{ marginBottom: 10, width: '100%', padding: 4, borderBottom: '2px solid #7f8c8d', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '75%' }}>{"MobilApp Paket İsmi"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                <span>{franchiseSadakatData.MobilePackageName ?? "com.javisadakat"}</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '75%' }}>{"Marka Adı"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={franchiseSadakatData.BrandName}
                                    onChange={this.handleChangeData("BrandName")}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '75%' }}>{"JaviSadakat Sistemini"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={franchiseSadakatData.IsApp}
                                            onChange={this.handleChangeData("IsApp")}
                                        />
                                    )}
                                    label={"Kullan"}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '40%' }}>{"Birincil, İkincil ve Üçüncü Renk Seçenekleri (Hex)"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '20%', marginRight: 5 }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={franchiseSadakatData.PrimaryColor}
                                    onChange={this.handleChangeData("PrimaryColor")}
                                    endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end"><Icon style={{ color: franchiseSadakatData.PrimaryColor }}>{"circle"}</Icon></InputAdornment>}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                />
                            </div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '20%', marginRight: 5 }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={franchiseSadakatData.SecondaryColor}
                                    onChange={this.handleChangeData("SecondaryColor")}
                                    endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end"><Icon style={{ color: franchiseSadakatData.SecondaryColor }}>{"circle"}</Icon></InputAdornment>}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                />
                            </div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '20%' }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={franchiseSadakatData.ThirdColor}
                                    onChange={this.handleChangeData("ThirdColor")}
                                    endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end"><Icon style={{ color: franchiseSadakatData.ThirdColor }}>{"circle"}</Icon></InputAdornment>}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <Paper style={{ padding: 10, backgroundColor: '#424242', borderRadius: 0 }}>
                            <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '2px solid #7f8c8d', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span>{"Ödeme Yöntemleri"}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                                <div style={{ fontSize: 13, textAlign: 'left', width: '25%' }}>
                                    <TextField
                                        fullWidth
                                        label="Cüzdan Limiti"
                                        type="number"
                                        value={franchiseSadakatData.WalletLimit}
                                        onFocus={event => { event.target.select() }}
                                        onChange={this.handleChangeData("WalletLimit")}
                                        id="price_propertyitem"
                                        placeholder='Cüzdan Limiti'
                                        InputProps={{
                                            inputProps: {
                                                min: 200
                                            }
                                        }}
                                    />
                                </div>
                                <div style={{ fontSize: 13, textAlign: 'center', width: '25%' }}>
                                    <FormControlLabel
                                        control={(
                                            <Switch
                                                checked={franchiseSadakatData.IsCreditCard}
                                                onChange={this.handleChangeData("IsCreditCard")}
                                            />
                                        )}
                                        label={"Kredi Kartı Kullan"}
                                    />
                                </div>
                                <div style={{ fontSize: 13, textAlign: 'center', width: '25%' }}>
                                    <FormControlLabel
                                        control={(
                                            <Switch
                                                checked={franchiseSadakatData.IsCash}
                                                onChange={this.handleChangeData("IsCash")}
                                            />
                                        )}
                                        label={"Nakit Kullan"}
                                    />
                                </div>
                            </div>
                        </Paper>
                        <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid #7f8c8d', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>{"Şubelerin Çalışma Saatleri"}</span>
                            <Button onClick={() => this.changeAllDayHours()} className={classes.rootButton} style={{ float: 'right', display: 'flex', height: 30, width: 100, maxWidth: 100, backgroundColor: '#009688', margin: 0, textTransform: 'capitalize' }}>
                                {"Tümüne Uygula"}
                            </Button>
                        </div>
                        <Table style={{ marginTop: 5 }}>
                            <TableHead style={{ backgroundColor: '#212121' }}>
                                <TableRow>
                                    <TableCell align="left">{"Durum"}</TableCell>
                                    <TableCell align="center">{"Gün"}</TableCell>
                                    <TableCell align="center">{"Açılış Saati"}</TableCell>
                                    <TableCell align="center">{"Kapanış Saati"}</TableCell>
                                    <TableCell align="right">{"24 Saat"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    franchiseSadakatData.WorkingHours.map((n, i) => {
                                        return (
                                            <TableRow hover key={i}>
                                                <TableCell align="left" style={{ padding: '0px 15px 0px 24px' }}>
                                                    <FormControlLabel
                                                        key={i}
                                                        control={(
                                                            <Switch
                                                                checked={n.IsStatus}
                                                                onChange={this.handleChangeWorkingHours(n.Day, "IsStatus")}
                                                            />
                                                        )}
                                                        label={""}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px 15px 0px 24px' }}>{n.Day}</TableCell>
                                                <TableCell align="center" style={{ padding: '0px 15px 0px 24px' }}>
                                                    <DatePicker
                                                        selected={new Date(n.StartHour)}
                                                        onChange={this.handleChangeWorkingHours(n.Day, "StartHour")}
                                                        dateFormat="HH:mm"
                                                        withPortal
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        locale="tr"
                                                        className={"inputDate"}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px 15px 0px 24px' }}>
                                                    <DatePicker
                                                        selected={new Date(n.EndHour)}
                                                        onChange={this.handleChangeWorkingHours(n.Day, "EndHour")}
                                                        dateFormat="HH:mm"
                                                        withPortal
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        locale="tr"
                                                        className={"inputDate"}
                                                    />
                                                </TableCell>
                                                <TableCell align="right" style={{ padding: '0px 15px 0px 24px' }}>
                                                    <FormControlLabel
                                                        key={i}
                                                        control={(
                                                            <Switch
                                                                checked={n.Hour24}
                                                                onChange={this.handleChangeWorkingHours(n.Day, "Hour24")}
                                                            />
                                                        )}
                                                        label={""}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '80%' }}>
                                <input style={{ display: 'none' }} campaid={""} dataType={6} id={"f8bd9500-8a51-4641-a143-97a9aa2e952b"} type="file" onChange={this.onChangeImageFile} />
                                <label htmlFor={"f8bd9500-8a51-4641-a143-97a9aa2e952b"}>
                                    <Tooltip title="Resim boyutu 1200px*600px olarak otomatik boyutlandırılacaktır. Yüklemek istediğiniz resim çalışmalarını bu boyutta yapabilirsiniz!">
                                        <img onError={this.addDefaultSrc} style={{ height: 30, marginRight: 10 }} src={franchiseSadakatData.WellcomeCam.ImageUrl} />
                                    </Tooltip>
                                </label>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={franchiseSadakatData.WellcomeCam.IsStatus}
                                            onChange={this.handleChangeDataWellcome("IsStatus")}
                                        />
                                    )}
                                    label={"Hoşgeldin Kampanyasını Kullan"}
                                />
                            </div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '20%', marginRight: 5 }}>
                                <TextField
                                    fullWidth
                                    defaultValue={franchiseSadakatData.WellcomeCam.DiscountRate}
                                    value={franchiseSadakatData.WellcomeCam.DiscountRate}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangeDataWellcome("DiscountRate")}
                                    id="price_propertyitem"
                                    InputProps={{
                                        inputProps: {
                                            max: 100, min: 0
                                        },
                                        inputComponent: NumberFormatCustomDiscount
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '80%' }}>
                                <input style={{ display: 'none' }} campaid={""} dataType={7} id={"eebcb10e-0f96-4444-9279-b178053d97cd"} type="file" onChange={this.onChangeImageFile} />
                                <label htmlFor={"eebcb10e-0f96-4444-9279-b178053d97cd"}>
                                    <Tooltip title="Resim boyutu 1200px*600px olarak otomatik boyutlandırılacaktır. Yüklemek istediğiniz resim çalışmalarını bu boyutta yapabilirsiniz!">
                                        <img onError={this.addDefaultSrc} style={{ height: 30, marginRight: 10 }} src={franchiseSadakatData.FriendsInvite.ImageUrl} />
                                    </Tooltip>
                                </label>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={franchiseSadakatData.FriendsInvite.IsStatus}
                                            onChange={this.handleChangeDataFriends("IsStatus")}
                                        />
                                    )}
                                    label={"Arkadaşını Davet Et Kampanyası"}
                                />
                            </div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '20%', marginRight: 5 }}>
                                <TextField
                                    fullWidth
                                    value={franchiseSadakatData.FriendsInvite.DiscountAmount}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangeDataFriends("DiscountAmount")}
                                    id="price_propertyitem"
                                    InputProps={{
                                        inputComponent: NumberFormatCustom
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '2px solid #16a085', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span>{"Yedikçe Kazan Kampanyası"}</span>
                        </div>
                        <Paper style={{ padding: 10, backgroundColor: '#424242', borderRadius: 0 }}>
                            <Table style={{ margin: '0.5em 0', marginBottom: 20 }}>
                                <TableHead style={{ backgroundColor: '#212121' }}>
                                    <TableRow>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Görsel"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Limit"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5, width: '35%' }} align="left">{"Alması Gereken Ürünler"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5, width: '35%' }} align="left">{"Hediye Seçimi Ürün"}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        EarnAsYouEat.length == 0 ? null : <TableRow hover>
                                            <TableCell style={{ width: 100 }} align="left">
                                                <Tooltip title="Resim boyutu 1200px*600px olarak otomatik boyutlandırılacaktır. Yüklemek istediğiniz resim çalışmalarını bu boyutta yapabilirsiniz!">
                                                    <input style={{ display: 'none' }} dataType={5} campaid={EarnAsYouEat[0].Id} id={EarnAsYouEat[0].Id} type="file" onChange={this.onChangeImageFile} />
                                                </Tooltip>
                                                <label htmlFor={EarnAsYouEat[0].Id}>
                                                    <img onError={this.addDefaultSrc} style={{ height: 30 }} src={EarnAsYouEat[0].ImageUrl} />
                                                </label>
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        value={EarnAsYouEat[0].Limit}
                                                        onFocus={event => { event.target.select() }}
                                                        onChange={this.handleChangeDiscountProductAddCart(EarnAsYouEat[0].Id)}
                                                        id="price_propertyitem"
                                                        InputProps={{
                                                            inputProps: {
                                                                max: 10, min: 1
                                                            }
                                                        }}
                                                    />
                                                }
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    <Autocomplete
                                                        multiple
                                                        id="tags-standard"
                                                        options={this.returnProductList()}
                                                        value={this.returnProductSelectList(EarnAsYouEat[0].ProductsToBeAdded)}
                                                        getOptionSelected={(option, value) => option.ProductId === value.ProductId}
                                                        onChange={(event, value) => this.handleChangeMultiProductAuto(EarnAsYouEat[0].Id, value, "ProductId")}
                                                        getOptionLabel={(option) => this.getProductName(option.ProductId)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="filled"
                                                                style={{ padding: '15px 8px 0 !important' }}
                                                                classes={{ root: classes.MuiFormControlAuto }}
                                                                label="Ürün Seçimi"
                                                            />
                                                        )}
                                                    />
                                                }
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    <Autocomplete
                                                        multiple
                                                        id="tags-standard"
                                                        options={this.returnProductList()}
                                                        value={this.returnProductSelectList(EarnAsYouEat[0].ProductsAdded)}
                                                        getOptionSelected={(option, value) => option.ProductId === value.ProductId}
                                                        onChange={(event, value) => this.handleChangeMultiProductAutoSecond(EarnAsYouEat[0].Id, value, "ProductId")}
                                                        getOptionLabel={(option) => this.getProductName(option.ProductId)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="filled"
                                                                style={{ padding: '15px 8px 0 !important' }}
                                                                classes={{ root: classes.MuiFormControlAuto }}
                                                                label="Ürün Seçimi"
                                                            />
                                                        )}
                                                    />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Paper>
                        <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '2px solid #ffb142', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span>{"Kampanya Listesi"}</span>
                        </div>
                        <Paper style={{ padding: 10, backgroundColor: '#424242', borderRadius: 0, marginBottom: 20 }}>
                            <div style={{ marginBottom: 10, padding: 10, backgroundColor: '#313131', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Icon>{"restaurant"}</Icon>
                                {"İki Tarih Aralığında Ürüne Yüzdesel İndirim"}
                                <Tooltip title="Yeni Kampanya Ekle" placement="bottom">
                                    <IconButton style={{ backgroundColor: '#424242' }} onClick={() => this.clickAddDiscountProduct()}>
                                        <Icon>{"add"}</Icon>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Table style={{ margin: '0.5em 0', marginBottom: 20 }}>
                                <TableHead style={{ backgroundColor: '#212121' }}>
                                    <TableRow>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Görsel"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Durum"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Satış Tipi"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Başlangıç"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Bitiş"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5, width: '30%' }} align="left">{"Ürün"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"İndirim Oranı"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="right">{""}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        DiscountProduct.map((n, i) => {
                                            return (
                                                <TableRow hover key={i}>
                                                    <TableCell align="left">
                                                        <Tooltip title="Resim boyutu 1200px*600px olarak otomatik boyutlandırılacaktır. Yüklemek istediğiniz resim çalışmalarını bu boyutta yapabilirsiniz!">
                                                            <input style={{ display: 'none' }} dataType={5} campaid={n.Id} id={n.Id} type="file" onChange={this.onChangeImageFile} />
                                                        </Tooltip>
                                                        <label htmlFor={n.Id}>
                                                            <img onError={this.addDefaultSrc} style={{ height: 30 }} src={n.ImageUrl} />
                                                        </label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            <IconButton style={{ backgroundColor: '#424242' }} onClick={this.handleChangeDiscountProduct(n.Id, "IsStatus")}>
                                                                <Icon style={{ color: (n.IsStatus ? '#16a085' : '#c0392b') }}>{(n.IsStatus ? 'check_circle' : 'cancel')}</Icon>
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            <Select
                                                                fullWidth
                                                                labelId="demo-mutiple-checkbox-label"
                                                                id="demo-mutiple-checkbox"
                                                                style={{ height: 38 }}
                                                                value={n.SalesType}
                                                                onChange={this.handleChangeDiscountProduct(n.Id, "SalesType")}
                                                            >
                                                                <MenuItem value={0}><ListItemText style={{ margin: 0 }} primary={"Paket"} /></MenuItem>
                                                                <MenuItem value={1}><ListItemText style={{ margin: 0 }} primary={"Gel Al"} /></MenuItem>
                                                            </Select>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            <DatePicker
                                                                selected={new Date(n.StartDate)}
                                                                onChange={this.handleChangeDiscountProduct(n.Id, "StartDate")}
                                                                dateFormat="dd.MM.yyyy HH:mm"
                                                                showTimeSelect
                                                                timeIntervals={15}
                                                                timeFormat="HH:mm"
                                                                locale="tr"
                                                                className={"inputDate"}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            <DatePicker
                                                                selected={new Date(n.EndDate)}
                                                                onChange={this.handleChangeDiscountProduct(n.Id, "EndDate")}
                                                                dateFormat="dd.MM.yyyy HH:mm"
                                                                showTimeSelect
                                                                timeIntervals={15}
                                                                timeFormat="HH:mm"
                                                                locale="tr"
                                                                className={"inputDate"}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {this.renderMenuItemAuto(n.Id, n.ProductsToBeAdded[0].ProductId)}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            <TextField
                                                                fullWidth
                                                                defaultValue={n.ProductsToBeAdded[0].DiscountRate}
                                                                value={n.ProductsToBeAdded[0].DiscountRate}
                                                                onFocus={event => { event.target.select() }}
                                                                onChange={this.handleChangeDiscountProduct(n.Id, "DiscountRate")}
                                                                id="price_propertyitem"
                                                                InputProps={{
                                                                    inputComponent: NumberFormatCustomDiscount,
                                                                }}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="Kampanyayı Sil">
                                                            <IconButton
                                                                style={{ backgroundColor: '#3e3e3e', marginRight: 5 }}
                                                                aria-label="Kampanyayı Sil"
                                                                onClick={() => this.handleChangeCampaignRemove(n.Id)}
                                                            >
                                                                <Icon>{"delete"}</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Paper>
                        <Paper style={{ padding: 10, backgroundColor: '#424242', borderRadius: 0, marginBottom: 20 }}>
                            <div style={{ marginBottom: 10, padding: 10, backgroundColor: '#313131', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Icon>{"storefront"}</Icon>
                                {"İki Tarih Aralığında Genel Yüzdesel İndirim"}
                                <Tooltip title="Yeni Kampanya Ekle" placement="bottom">
                                    <IconButton style={{ backgroundColor: '#424242' }} onClick={() => this.clickAddGeneralDiscount()}>
                                        <Icon>{"add"}</Icon>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Table style={{ margin: '0.5em 0', marginBottom: 20 }}>
                                <TableHead style={{ backgroundColor: '#212121' }}>
                                    <TableRow>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Görsel"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Durum"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Satış Tipi"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Başlangıç"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"Bitiş"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="left">{"İndirim Oranı"}</TableCell>
                                        <TableCell style={{ color: 'white', fontSize: 12, padding: 5 }} align="right">{""}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        GeneralDiscount.map((n, i) => {
                                            return (
                                                <TableRow hover key={i}>
                                                    <TableCell align="left">
                                                        <Tooltip title="Resim boyutu 1200px*600px olarak otomatik boyutlandırılacaktır. Yüklemek istediğiniz resim çalışmalarını bu boyutta yapabilirsiniz!">
                                                            <input style={{ display: 'none' }} dataType={5} campaid={n.Id} id={n.Id} type="file" onChange={this.onChangeImageFile} />
                                                        </Tooltip>
                                                        <label htmlFor={n.Id}>
                                                            <img onError={this.addDefaultSrc} style={{ height: 30 }} src={n.ImageUrl} />
                                                        </label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            <IconButton style={{ backgroundColor: '#424242' }} onClick={this.handleChangeGeneralDiscount(n.Id, "IsStatus")}>
                                                                <Icon style={{ color: (n.IsStatus ? '#16a085' : '#c0392b') }}>{(n.IsStatus ? 'check_circle' : 'cancel')}</Icon>
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            <Select
                                                                fullWidth
                                                                labelId="demo-mutiple-checkbox-label"
                                                                id="demo-mutiple-checkbox"
                                                                style={{ height: 38 }}
                                                                value={n.SalesType}
                                                                onChange={this.handleChangeGeneralDiscount(n.Id, "SalesType")}
                                                            >
                                                                <MenuItem value={0}><ListItemText style={{ margin: 0 }} primary={"Paket"} /></MenuItem>
                                                                <MenuItem value={1}><ListItemText style={{ margin: 0 }} primary={"Gel Al"} /></MenuItem>
                                                            </Select>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            <DatePicker
                                                                selected={new Date(n.StartDate)}
                                                                onChange={this.handleChangeGeneralDiscount(n.Id, "StartDate")}
                                                                dateFormat="dd.MM.yyyy HH:mm"
                                                                showTimeSelect
                                                                timeIntervals={15}
                                                                timeFormat="HH:mm"
                                                                locale="tr"
                                                                className={"inputDate"}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            <DatePicker
                                                                selected={new Date(n.EndDate)}
                                                                onChange={this.handleChangeGeneralDiscount(n.Id, "EndDate")}
                                                                dateFormat="dd.MM.yyyy HH:mm"
                                                                showTimeSelect
                                                                timeIntervals={15}
                                                                timeFormat="HH:mm"
                                                                locale="tr"
                                                                className={"inputDate"}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            <TextField
                                                                fullWidth
                                                                defaultValue={n.ProductsToBeAdded[0].DiscountRate}
                                                                value={n.ProductsToBeAdded[0].DiscountRate}
                                                                onFocus={event => { event.target.select() }}
                                                                onChange={this.handleChangeGeneralDiscount(n.Id, "DiscountRate")}
                                                                id="price_propertyitem"
                                                                InputProps={{
                                                                    inputComponent: NumberFormatCustomDiscount,
                                                                }}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="Kampanyayı Sil">
                                                            <IconButton
                                                                style={{ backgroundColor: '#3e3e3e', marginRight: 5 }}
                                                                aria-label="Kampanyayı Sil"
                                                                onClick={() => this.handleChangeCampaignRemove(n.Id)}
                                                            >
                                                                <Icon>{"delete"}</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Paper>
                        <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '2px solid #7f8c8d', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span>{"Markanızın KVKK, Kullanıcı ve Gizlilik Sözleşmesi Metinleri"}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '100%' }}>
                                <TextField
                                    fullWidth
                                    style={{ marginTop: 0 }}
                                    multiline
                                    maxRows={5}
                                    value={franchiseSadakatData.Agreement}
                                    onChange={this.handleChangeData("Agreement")}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                        </div>

                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').franchiseSadakatUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    franchiseSadakatUpdate: value(state, props),
    franchiseSadakatLoading: state.get('centerBrandReducer').franchiseSadakatLoading,
    franchiseSadakatVisible: state.get('centerBrandReducer').franchiseSadakatVisible,
    franchiseReportName: state.get('centerBrandReducer').franchiseReportName,
    centerBrandData: state.get('centerBrandReducer').centerBrandData,
    franchiseReportBrandSelected: state.get('centerBrandReducer').franchiseReportBrandSelected,
    franchiseSadakatData: state.get('centerBrandReducer').franchiseSadakatData,

    IsCampaignVisible: state.get('centerBrandReducer').IsCampaignVisible,
    CampaignListData: state.get('centerBrandReducer').CampaignListData,

    DiscountProduct: state.get('centerBrandReducer').DiscountProduct,
    GeneralDiscount: state.get('centerBrandReducer').GeneralDiscount,
    EarnAsYouEat: state.get('centerBrandReducer').EarnAsYouEat,

    productListSearch: state.get('stockReducer').form_data.productListSearch,
})

const mapDispatchToProps = {
    setFranchiseSadakatVisible,
    setFranchiseReportName,
    getBrandList,
    setFranchiseReportBrandSelected,
    getCenterSettings,
    setFranchiseSadakatData,
    setJaviAppSettings,
    setSaveImageApp,
    setCampaignListData,
    setCampaignUpdate,
    getStock,
    setCampaign,
    setModalCenterBrand
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(Sadakat)));
