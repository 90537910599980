import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { PapperBlock } from 'dan-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import {
    setPaketYoldaOrderUpdate, setPaketYoldaCounterUpdate, automaticAssignmentControl
} from '../../../../redux/modules/paketyolda';
import cookie from 'react-cookies';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import {
    createSelector
} from 'reselect';

class PaketYoldaCounter extends React.Component {
    constructor(props) {
        super(props);
        this.state = { orderRefresh: 120 };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.IsPaketYoldaCounterUpdate == this.props.IsPaketYoldaCounterUpdate) {
            return false;
        }
        return true;
    }

    componentWillUnmount() {
        clearInterval(this.timerOrder);
    }

    tickOrder() {
        const { paketYoldaOrderList } = this.props;

        this.setState({
            orderRefresh: this.state.orderRefresh - 1
        });
        if (this.state.orderRefresh < 0) {
            this.clearOrder();
        }
        if (this.state.orderRefresh == 0 || this.state.orderRefresh == 60) {
            paketYoldaOrderList.map((n, i) => {
                n.StandbyTime = Number(n.StandbyTime) + 1;
            })
            this.props.setPaketYoldaOrderUpdate().then(() => { });
        }
        this.props.setPaketYoldaCounterUpdate().then(() => { });
    }

    clearOrder() {
        this.setState({
            orderRefresh: 120
        });

        this.props.automaticAssignmentControl().then(() => { });
    }

    async componentDidMount() {
        this.timerOrder = setInterval(() => this.tickOrder(), 1000);
    }

    render() {
        return (
            <Tooltip title={"Otomatik atama kontrol sayacı"}>
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50, height: 50, borderRadius: 3, zIndex: 999, right: 5, top: 118, fontSize: 20, position: 'absolute', backgroundColor: '#2f3640', color: 'white', fontWeight: 500, padding: 3 }}>
                {this.state.orderRefresh}
            </span>
        </Tooltip>
        );
    }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').IsPaketYoldaCounterUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    IsPaketYoldaCounterUpdate: value(state, props),
    paketYoldaOrderList: state.get('paketYoldaReducer').paketYoldaOrderList
})

const mapDispatchToProps = {
    setPaketYoldaOrderUpdate,
    setPaketYoldaCounterUpdate,
    automaticAssignmentControl
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaketYoldaCounter));
