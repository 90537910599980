import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import {
    setCenterBrandAddVisible, setCenterBrandAddData, setBrandUpdateOrAdd
} from '../../../redux/modules/centerbrand';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import { CapitalizeText, ComplateLowerText } from '../../../function/GeneralFunction';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';

class CenterBrandAdd extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.centerBrandAddUpdate == this.props.centerBrandAddUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeCenterBrandAdd = (Name) => (event) => {
        const pointer = event.target.selectionStart;
        const element = event.target;

        const { centerBrandAddData } = this.props;
        centerBrandAddData[Name] = (Name == "IsStatus" ? event.target.checked : (Name == "Name" ? CapitalizeText(event.target.value) : (Name == "Email" ? ComplateLowerText(event.target.value) : event.target.value)));
        this.props.setCenterBrandAddData(centerBrandAddData).then(() => { })

        window.requestAnimationFrame(() => {
            element.selectionStart = pointer;
            element.selectionEnd = pointer;
        });
    };

    handleSubmit(event) {
        event.preventDefault();
        this.props.setBrandUpdateOrAdd().then(() => { })
    }

    render() {
        const { classes, centerBrandAddVisible, centerBrandAddData, centerBrandAddLoading } = this.props;

        return (
            <Dialog
                open={centerBrandAddVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'sm'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <form autocomplete="off" style={{ display: 'contents' }} onSubmit={this.handleSubmit}>
                    <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                        {"Şube Düzenleme ve Kayıt"}
                    </DialogTitle>
                    <DialogContent style={{ overflow: 'hidden auto' }}>
                        {
                            (centerBrandAddData.Id == "00000000-0000-0000-0000-000000000000" ?
                                <SnackbarContent style={{ backgroundColor: '#ffa000', borderRadius: 5, maxWidth: '100%', width: '100%', marginBottom: 5 }} className={classNames(classes.snackbar, messageStyles.bgSuccess)} message="Bilgilendirme: Şube açılışında kullanacağım Email burada girmiş olduğunuz Email adresidir. Şifre ise girilen telefon numarasının son 4 hanesidir!" />
                                : null)
                        }
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={centerBrandAddData.IsStatus}
                                            onChange={this.handleChangeCenterBrandAdd("IsStatus")}
                                        />
                                    )}
                                    label={"Aktif"}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Şube Adı'}</InputLabel>
                                    <Input
                                        type="text"
                                        autoComplete="new-email"
                                        fullWidth
                                        required
                                        placeholder={'Şube Adı Giriniz'}
                                        defaultValue={centerBrandAddData.Name}
                                        onChange={this.handleChangeCenterBrandAdd('Name')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Email'}</InputLabel>
                                    <Input
                                        type="email"
                                        autoComplete="new-email"
                                        fullWidth
                                        required
                                        placeholder={'Email Giriniz'}
                                        defaultValue={centerBrandAddData.Email}
                                        onChange={this.handleChangeCenterBrandAdd('Email')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <InputMask
                                    mask="0 999 999 99 99"
                                    onFocus={event => { event.target.select() }}
                                    value={centerBrandAddData.MobilePhone}
                                    onChange={this.handleChangeCenterBrandAdd("MobilePhone")}
                                >
                                    {() => <TextField
                                        label={'Şube (Telefon)'}
                                        name={"Phone"}
                                        fullWidth
                                        required
                                        style={{ marginTop: 0 }}
                                        margin="normal"
                                        type="text"
                                    />}
                                </InputMask>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel htmlFor="name-simple">{"Ciro Payı"}</InputLabel>
                                    <Input
                                        id="TurnoverShare"
                                        defaultValue={centerBrandAddData.TurnoverShare || 0}
                                        onFocus={event => { event.target.select() }}
                                        onChange={this.handleChangeCenterBrandAdd('TurnoverShare')}
                                        type="number"
                                        fullWidth
                                        inputProps={{
                                            min: 0,
                                            max: 100,
                                            step: 1
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={centerBrandAddLoading} type={"submit"} variant="contained" color="secondary">
                            {"Kaydet"}
                        </Button>
                        <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setCenterBrandAddVisible(false).then(() => { })} color="primary">
                            {"Çıkış"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').centerBrandAddUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    centerBrandAddUpdate: value(state, props),
    centerBrandAddVisible: state.get('centerBrandReducer').centerBrandAddVisible,
    centerBrandAddLoading: state.get('centerBrandReducer').centerBrandAddLoading,
    centerBrandAddData: state.get('centerBrandReducer').centerBrandAddData
});

const mapDispatchToProps = {
    setCenterBrandAddVisible,
    setCenterBrandAddData,
    setBrandUpdateOrAdd
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(CenterBrandAdd)));
