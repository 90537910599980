import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DatePicker from 'react-datepicker';
import '../datepicker.css';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from 'recharts';
import { getLicenceModulePackage } from '../../../../function/GeneralFunction';


class UserReport extends React.Component {
  constructor() {
    super();
  }

  renderDataUser() {
    const { data } = this.props;
    if (data.length == 0) {
      return [];
    }

    const dataArray = [];
    data.UserData.map((n, i) => {
      dataArray.push({ UserName: n.UserName, TotalPrice: n.TotalPrice, amt: n.TotalPrice });
    });
    return dataArray;
  }

  renderColorfulLegendText(value, entry) {
    const { color } = entry;
    return <span style={{ color: 'white', fontSize: 12 }}>{value}</span>;
  }

  renderUserReport() {
    const {
      handleChangeFilter, classes, data, GeneralTotalType
    } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Grid container spacing={1} style={{ display: (getLicenceModulePackage() ? '' : 'none') }}>
            <Grid item md={4}>
              <CounterTrading
                color="#2980b9"
                unitBefore=""
                title="Javipos Paket"
                unitCountAfter="Adet"
                icon="bar_chart"
                position="down"
                start={0}
                orderCount={data.length != 0 ? data.CallCenterCount : 0}
                end={data.length != 0 ? data.CallCenterTotal : 0}
              />
            </Grid>
            <Grid item md={4}>
              <CounterTrading
                color="#9b59b6"
                unitBefore=""
                title="GetirYemek"
                unitCountAfter="Adet"
                icon="insert_chart_outlined"
                position="down"
                start={0}
                orderCount={data.length != 0 ? data.GetirYemekCount : 0}
                end={data.length != 0 ? data.GetirYemekTotal : 0}
              />
            </Grid>
            <Grid item md={4}>
              <CounterTrading
                color="#fa0050"
                unitBefore=""
                title="Yemeksepeti"
                unitCountAfter="Adet"
                icon="insert_chart_outlined"
                position="down"
                start={0}
                orderCount={data.length != 0 ? data.YemekSepetiCount : 0}
                end={data.length != 0 ? data.YemekSepetiTotal : 0}
              />
            </Grid>
            <Grid item md={4}>
              <CounterTrading
                color="#f27a1a"
                unitBefore=""
                title="TrendyolYemek"
                unitCountAfter="Adet"
                icon="insert_chart_outlined"
                position="down"
                start={0}
                orderCount={data.length != 0 ? data.TrendyolYemekCount : 0}
                end={data.length != 0 ? data.TrendyolYemekTotal : 0}
              />
            </Grid>
            <Grid item md={4}>
              <CounterTrading
                color="#f1b62a"
                unitBefore=""
                title="GoFody"
                unitCountAfter="Adet"
                icon="insert_chart_outlined"
                position="down"
                start={0}
                orderCount={data.length != 0 ? data.GoFodyCount : 0}
                end={data.length != 0 ? data.GoFodyTotal : 0}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <div className={classes.chartFluid} style={{ padding: 10, borderRadius: 5, marginTop: 30 }}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                height={400}
                data={this.renderDataUser()}
                margin={{
                  right: 10,
                  bottom: 100
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#c0392b" stopOpacity={1} />
                    <stop offset="95%" stopColor="#c0392b" stopOpacity={1} />
                  </linearGradient>
                </defs>
                <XAxis interval={0} angle={-45} textAnchor="end" dataKey="UserName" tickLine={false} label={{ size: 12 }} />
                <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
                <CartesianGrid vertical={false} strokeDasharray="3 3" />
                <CartesianAxis vertical={false} />
                <Tooltip />
                <Legend verticalAlign="top" formatter={this.renderColorfulLegendText} />
                <Bar name="Toplam" dataKey="TotalPrice" fillOpacity="1" fill="url(#colorUv)" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      StartDate, EndDate, handleChangeFilter, handleFilterUserReport, classes, data, loading
    } = this.props;
    return (
      <Paper
        className={classes.root}
        elevation={0}
        style={{
          borderRadius: 0, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 10
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
            <Typography
              style={{
                marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
              }}
              className={classes.flex}
            >
Başlangıç Tarihi
            </Typography>
            <DatePicker
              selected={StartDate}
              onChange={handleChangeFilter('StartDate')}
              timeInputLabel="Saat:"
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeInput
              className="inputDate"
            />
          </Grid>
          <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
            <Typography
              style={{
                marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
              }}
              className={classes.flex}
            >
Bitiş Tarihi
            </Typography>
            <DatePicker
              selected={EndDate}
              onChange={handleChangeFilter('EndDate')}
              timeInputLabel="Saat:"
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeInput
              className="inputDate"
            />
          </Grid>
          <Grid item md={3}>
            <Button
              disabled={loading}
              onClick={handleFilterUserReport}
              style={{
                borderRadius: 5, marginTop: 15, height: 37, padding: '6px 24px'
              }}
              size="large"
              variant="contained"
              color="secondary"
              type="submit"
            >
              {'Filtrele'}
            </Button>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {this.renderUserReport()}
      </Paper>
    );
  }
}

export default UserReport;
