import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../datepicker.css';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from 'recharts';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import tr from 'date-fns/locale/tr';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { getParameter } from '../../../../function/GeneralFunction';
registerLocale('tr', tr);

const pageStyle80 = `
  @media print {
    .pagebreak {
      page-break-before: always;
    }
    .printOpen {
        display:inline !important;
        color:black !important;
        font-size:15px !important;
        font-weight:700 !important;
        min-width:302.36px !important;
    }
    .printClose {
        display:none !important;
    }
    .printMain {
        color:black !important;
        font-size:15px !important;
        font-weight:700 !important;
        width:302.36px !important;
    }
    .printTitle {
        color:black !important;
        font-size:15px !important;
        font-weight:700 !important;
        min-width:302.36px !important;
        border-bottom: 2px solid black !important;
    }
    .printLeft {
        color:black !important;
        font-size:14px !important;
        font-weight:700 !important;
    }
    .printChild {
        color:black !important;
        font-size:11px !important;
        font-weight:700 !important;
    }
  }
`;

const pageStyle58 = `
  @media print {
    .pagebreak {
      page-break-before: always;
    }
    .printOpen {
        display:inline !important;
        color:black !important;
        font-size:15px !important;
        font-weight:700 !important;
        min-width:219.21px !important;
    }
    .printClose {
        display:none !important;
    }
    .printMain {
        color:black !important;
        font-size:15px !important;
        font-weight:700 !important;
        width:219.21px !important;
    }
    .printTitle {
        color:black !important;
        font-size:15px !important;
        font-weight:700 !important;
        min-width:219.21px !important;
        border-bottom: 2px solid black !important;
    }
    .printLeft {
        color:black !important;
        font-size:14px !important;
        font-weight:700 !important;
    }
    .printChild {
        color:black !important;
        font-size:11px !important;
        font-weight:700 !important;
    }
  }
`;

class EndOfDayPage extends React.Component {
  constructor() {
    super();
    this.state = { openMenu: false, menuMM: 80 };
  }

  async componentDidMount() {

  }

  async clickPrint(mm) {
    await this.setState({ menuMM: mm });
    await document.getElementById('print-button').click();
    this.setState({ openMenu: false });
  }

  render() {
    const {
      StartDate, EndDate, handleChangeFilter, handleFilterReport, classes, data, loading
    } = this.props;
    return (
      <Paper
        className={classes.root}
        elevation={0}
        style={{
          borderRadius: 0, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 10
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
            <Typography
              style={{
                marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
              }}
              className={classes.flex}
            >
Başlangıç Tarihi
            </Typography>
            <DatePicker
              selected={StartDate}
              onChange={handleChangeFilter('StartDate')}
              timeInputLabel="Saat:"
              dateFormat="dd.MM.yyyy HH:mm:ss"
              showTimeInput
              withPortal
              locale="tr"
              className="inputDate"
            />
          </Grid>
          <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
            <Typography
              style={{
                marginBottom: 3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1
              }}
              className={classes.flex}
            >
Bitiş Tarihi
            </Typography>
            <DatePicker
              selected={EndDate}
              onChange={handleChangeFilter('EndDate')}
              timeInputLabel="Saat:"
              dateFormat="dd.MM.yyyy HH:mm:ss"
              showTimeInput
              withPortal
              locale="tr"
              className="inputDate"
            />
          </Grid>
          <Grid item md={3}>
            <Button
              disabled={loading}
              onClick={handleFilterReport}
              style={{
                borderRadius: 5, marginTop: 15, height: 37, padding: '6px 24px'
              }}
              size="large"
              variant="contained"
              color="secondary"
              type="submit"
            >
              {'Filtrele'}
            </Button>
          </Grid>
          <Grid item md={(window.innerWidth <= 1024 ? 3 : 5)} style={{ textAlign: 'right' }}>
            <div>
              <Button
                style={{
                  borderRadius: 5, marginTop: 15, height: 37, padding: '6px 24px'
                }}
                variant="contained"
                color="primary"
                aria-controls="alpha-menu"
                aria-haspopup="true"
                onClick={() => this.setState({ openMenu: true })}
              >
                {'Yazdır'}
              </Button>
              <Menu
                id="alpha-menu"
                open={this.state.openMenu}
                onClose={() => this.setState({ openMenu: false })}
                TransitionComponent={Fade}
                style={{ top: -50 }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => this.clickPrint(80)}>80mm Çıktı</MenuItem>
                <MenuItem onClick={() => this.clickPrint(58)}>58mm Çıktı</MenuItem>
              </Menu>
            </div>
            <ReactToPrint
              trigger={() => <button style={{ height: 0, display: 'block' }} id="print-button" />}
              pageStyle={(this.state.menuMM == 80 ? pageStyle80 : pageStyle58)}
              content={() => this.componentRef}
            />
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {(loading ? <div style={{ width: '100%', textAlign: 'center', marginBottom: 15 }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
        {
          <div ref={el => (this.componentRef = el)} style={{ width: '100%', justifyContent: 'center', display: 'grid' }} className="printMain">
            <div
              className="printOpen"
              style={{
                display: 'none', marginTop: 20, backgroundColor: '#FFC107', width: '100%', minWidth: 500, padding: 5, textAlign: 'center', color: 'white'
              }}
            >
DETAYLI RAPOR
            </div>
            <div
              className="printOpen"
              style={{
                fontSize: 12, display: 'none', marginTop: 5, backgroundColor: '#FFC107', width: '100%', minWidth: 500, padding: 5, textAlign: 'center', color: 'white'
              }}
            >
              {moment(StartDate).format('DD.MM.YYYY HH:mm')}
              {' '}
              {'-'}
              {' '}
              {moment(EndDate).format('DD.MM.YYYY HH:mm')}
            </div>
            <div
              className="printClose"
              style={{
                marginTop: 20, backgroundColor: '#FFC107', width: '100%', minWidth: 500, padding: 5, textAlign: 'center', color: 'white'
              }}
            >
Açık İşlemler
            </div>
            {
              <div className="printClose" className={classes.rootTable}>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>Açık Masa</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.TableTotal.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>Açık Paket</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.OpenCourierTotal.toFixed(2) : 0}</div>
                </div>
              </div>
            }
            <div
              className="printTitle"
              style={{
                marginTop: 20, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: 'white'
              }}
            >
Kasa Toplamları
            </div>
            {
              <div className={classes.rootTable}>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>Gelir Toplamı</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.CashReport.TotalIncome.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>Gider Toplamı</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.CashReport.TotalExpense.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>Toplam Kasa</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.CashReport.TotalCash.toFixed(2) : 0}</div>
                </div>
              </div>
            }
            <div
              className="printTitle"
              style={{
                marginTop: 20, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: 'white'
              }}
            >
Satış Raporu
            </div>
            {
              <div className={classes.rootTable}>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>Satışlar</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.SalesReport.Sales.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>İndirim</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.SalesReport.Discount.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>İkram</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.SalesReport.Offer.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>İade</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.SalesReport.SalesReturn.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: '#00BCD4', width: '50%' }}>Ürün İptaller</div>
                  <div className="printLeft" style={{ color: '#00BCD4', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.CanceledProductTotal.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: '#00BCD4', width: '50%' }}>Adisyon İptaller</div>
                  <div className="printLeft" style={{ color: '#00BCD4', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.CanceledAdditionTotal.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>Toplam Satış</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.SalesReport.TotalSales.toFixed(2) : 0}</div>
                </div>
              </div>
            }
            <div
              className="printTitle"
              style={{
                marginTop: 20, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: 'white'
              }}
            >
Satış Kanalı Raporu
            </div>
            {
              <div className={classes.rootTable}>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>Masa</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.SalesChanelReport.ClosedTable.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>Self Satış</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.SalesChanelReport.ClosedSelfSales.toFixed(2) : 0}</div>
                </div>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%' }}>Paket</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{data.length != 0 ? data.SalesChanelReport.ClosedPackage.toFixed(2) : 0}</div>
                </div>

                <div style={{ display: 'grid', width: '100%', padding: 3 }}>
                  <div style={{ display: 'flex' }}>
                    <div
                      className="printChild"
                      style={{
                        marginLeft: 10, width: '50%', fontSize: 14, color: '#00BCD4'
                      }}
                    >
Telefon
                    </div>
                    <div
                      className="printChild"
                      style={{
                        marginLeft: 10, width: '50%', fontSize: 14, color: '#00BCD4', textAlign: 'right'
                      }}
                    >
                      {data.length != 0 ? data.SalesChanelReport.SalesChanelReportDetail.ClosedPhone.toFixed(2) : 0}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      className="printChild"
                      style={{
                        marginLeft: 10, width: '50%', fontSize: 14, color: '#00BCD4'
                      }}
                    >
GetirYemek
                    </div>
                    <div
                      className="printChild"
                      style={{
                        marginLeft: 10, width: '50%', fontSize: 14, color: '#00BCD4', textAlign: 'right'
                      }}
                    >
                      {data.length != 0 ? data.SalesChanelReport.SalesChanelReportDetail.ClosedGetirYemek.toFixed(2) : 0}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      className="printChild"
                      style={{
                        marginLeft: 10, width: '50%', fontSize: 14, color: '#00BCD4'
                      }}
                    >
TrendyolYemek
                    </div>
                    <div
                      className="printChild"
                      style={{
                        marginLeft: 10, width: '50%', fontSize: 14, color: '#00BCD4', textAlign: 'right'
                      }}
                    >
                      {data.length != 0 ? data.SalesChanelReport.SalesChanelReportDetail.ClosedTrendyolYemek.toFixed(2) : 0}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      className="printChild"
                      style={{
                        marginLeft: 10, width: '50%', fontSize: 14, color: '#00BCD4'
                      }}
                    >
YemekSepeti
                    </div>
                    <div
                      className="printChild"
                      style={{
                        marginLeft: 10, width: '50%', fontSize: 14, color: '#00BCD4', textAlign: 'right'
                      }}
                    >
                      {data.length != 0 ? data.SalesChanelReport.SalesChanelReportDetail.ClosedYemekSepeti.toFixed(2) : 0}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      className="printChild"
                      style={{
                        marginLeft: 10, width: '50%', fontSize: 14, color: '#00BCD4'
                      }}
                    >
GoFody
                    </div>
                    <div
                      className="printChild"
                      style={{
                        marginLeft: 10, width: '50%', fontSize: 14, color: '#00BCD4', textAlign: 'right'
                      }}
                    >
                      {data.length != 0 ? data.SalesChanelReport.SalesChanelReportDetail.ClosedGoFody.toFixed(2) : 0}
                    </div>
                  </div>
                </div>
              </div>
            }
            <div
              className="printTitle"
              style={{
                marginTop: 20, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: 'white'
              }}
            >
Ödeme Yöntemleri
            </div>
            {
              <div className={classes.rootTable}>
                {
                  data.length != 0
                    ? data.PaymentMethodReport.map((n, i) => (
                      <div style={{ display: 'flex', padding: 3 }}>
                        <div className="printLeft" style={{ color: 'white', width: '50%' }}>{n.PaymentName}</div>
                        <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{n.Total.toFixed(2)}</div>
                      </div>
                    )
                    ) : null
                }

              </div>
            }
            <div
              className="printTitle"
              style={{
                marginTop: 20, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: 'white'
              }}
            >
Açık Hesap Raporu
            </div>
            {
              <div className={classes.rootTable}>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'center' }}>Müşteri Adı</div>
                  <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'right' }}>Açık Hesap</div>
                  <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'right' }}>İndirim</div>
                  <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'right' }}>Ödeme</div>
                </div>
                <Divider />
                {
                  data.length != 0
                    ? data.OpenAccount.map((n, i) => (
                      <div style={{ display: 'flex', padding: 3 }}>
                        <div className="printLeft" style={{ color: 'white', width: '25%' }}>{n.NameSurname}</div>
                        <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'right' }}>{n.Total.toFixed(2)}</div>
                        <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'right' }}>{n.Discount.toFixed(2)}</div>
                        <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'right' }}>{n.Payment.toFixed(2)}</div>
                      </div>
                    )
                    ) : null
                }

              </div>
            }
            <div
              className="printTitle"
              style={{
                marginTop: 20, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: 'white'
              }}
            >
Kullanıcı Satış Raporu
            </div>
            {
              <div className={classes.rootTable}>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '33.3%', textAlign: 'center' }}>Kullanıcı Adı</div>
                  <div className="printLeft" style={{ color: 'white', width: '33.3%', textAlign: 'center' }}>Adisyon</div>
                  <div className="printLeft" style={{ color: 'white', width: '33.3%', textAlign: 'right' }}>Tutar</div>
                </div>
                <Divider />
                {
                  data.length != 0
                    ? data.UserSalesReport.map((n, i) => (
                      <div>
                        <div style={{ display: 'flex', padding: 3 }}>
                          <div className="printLeft" style={{ color: 'white', width: '33.3%' }}>{n.Username}</div>
                          <div className="printLeft" style={{ color: 'white', width: '33.3%', textAlign: 'center' }}>{n.AdditionCount}</div>
                          <div className="printLeft" style={{ color: 'white', width: '33.3%', textAlign: 'right' }}>{n.Total.toFixed(2)}</div>
                        </div>
                        <div style={{ display: 'grid', width: '100%', padding: 3 }}>
                          {
                            (n.PaymentTotal != null
                              ? (
                                <div style={{ display: 'flex', padding: 3 }}>
                                  <div
                                    className="printChild"
                                    style={{
                                      fontSize: 14, color: '#00BCD4', width: '33.3%', textAlign: 'left'
                                    }}
                                  >
Ödeme Tipi
                                  </div>
                                  <div
                                    className="printChild"
                                    style={{
                                      fontSize: 14, color: '#00BCD4', width: '33.3%', textAlign: 'left'
                                    }}
                                  >
İndirim
                                  </div>
                                  <div
                                    className="printChild"
                                    style={{
                                      fontSize: 14, color: '#00BCD4', width: '33.3%', textAlign: 'left'
                                    }}
                                  >
Tutar
                                  </div>
                                </div>
                              ) : null)
                          }
                          {
                            (n.PaymentTotal != null ? n.PaymentTotal.map((y, i) => (
                              <div style={{ display: 'flex' }}>
                                <div className="printChild" style={{ width: '33%', fontSize: 14, color: '#00BCD4' }}>{y.PaymentName}</div>
                                <div
                                  className="printChild"
                                  style={{
                                    width: '33%', fontSize: 14, color: '#00BCD4', textAlign: 'left'
                                  }}
                                >
                                  {y.Discount.toFixed(2)}
                                </div>
                                <div
                                  className="printChild"
                                  style={{
                                    width: '33%', fontSize: 14, color: '#00BCD4', textAlign: 'left'
                                  }}
                                >
                                  {y.Total.toFixed(2)}
                                </div>
                              </div>
                            )) : null)
                          }
                        </div>
                      </div>
                    )
                    ) : null
                }

              </div>
            }
            <div
              className="printTitle"
              style={{
                marginTop: 20, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: '#F44336'
              }}
            >
Kullanıcı İade Raporu
            </div>
            {
              <div className={classes.rootTable}>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '33.3%', textAlign: 'center' }}>Kullanıcı Adı</div>
                  <div className="printLeft" style={{ color: 'white', width: '33.3%', textAlign: 'center' }}>Adisyon</div>
                  <div className="printLeft" style={{ color: 'white', width: '33.3%', textAlign: 'right' }}>Tutar</div>
                </div>
                <Divider />
                {
                  data.length != 0
                    ? data.UserSalesReturnReport.map((n, i) => (
                      <div style={{ display: 'flex', padding: 3 }}>
                        <div className="printLeft" style={{ color: 'white', width: '33.3%' }}>{n.Username}</div>
                        <div className="printLeft" style={{ color: 'white', width: '33.3%', textAlign: 'center' }}>{n.AdditionCount}</div>
                        <div className="printLeft" style={{ color: 'white', width: '33.3%', textAlign: 'right' }}>{n.Total.toFixed(2)}</div>
                      </div>
                    )
                    ) : null
                }

              </div>
            }
            <div
              className="printTitle"
              style={{
                marginTop: 20, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: 'white'
              }}
            >
Kurye Teslimat Raporu
            </div>
            {
              <div className={classes.rootTable}>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'center' }}>Kurye</div>
                  <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'center' }}>Adisyon</div>
                  <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'right' }}>Online</div>
                  <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'right' }}>Tutar</div>
                </div>
                <Divider />
                {
                  data.length != 0
                    ? data.CourierDeliveryReport.map((n, i) => (
                      <div style={{ display: 'flex', padding: 3 }}>
                        <div className="printLeft" style={{ color: 'white', width: '25%' }}>{n.Username}</div>
                        <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'center' }}>{n.AdditionCount}</div>
                        <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'right' }}>{n.OnlineTotal.toFixed(2)}</div>
                        <div className="printLeft" style={{ color: 'white', width: '25%', textAlign: 'right' }}>{n.Total.toFixed(2)}</div>
                      </div>
                    )
                    ) : null
                }

              </div>
            }
            <div
              className="printTitle"
              style={{
                marginTop: 20, backgroundColor: '#2d3436', width: '100%', padding: 5, textAlign: 'center', color: 'white'
              }}
            >
Gider Raporu
            </div>
            {
              <div className={classes.rootTable}>
                <div style={{ display: 'flex', padding: 3 }}>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'center' }}>Gider Tipi</div>
                  <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>Tutar</div>
                </div>
                <Divider />
                {
                  data.length != 0
                    ? data.Expense.map((n, i) => (
                      <div>
                        <div style={{ display: 'flex', padding: 3 }}>
                          <div className="printLeft" style={{ color: 'white', width: '50%' }}>{n.ExpenseType}</div>
                          <div className="printLeft" style={{ color: 'white', width: '50%', textAlign: 'right' }}>{n.Total.toFixed(2)}</div>
                        </div>
                        <div style={{ display: 'grid', width: '100%', padding: 3 }}>
                          {
                            n.Detail.map((y, i) => (
                              <div style={{ display: 'flex' }}>
                                <div
                                  className="printChild"
                                  style={{
                                    width: '50%', fontSize: 14, marginLeft: 10, color: '#00BCD4'
                                  }}
                                >
                                  {y.Detail}
                                </div>
                                <div
                                  className="printChild"
                                  style={{
                                    width: '50%', fontSize: 14, marginLeft: 10, color: '#00BCD4', textAlign: 'right'
                                  }}
                                >
                                  {y.Total.toFixed(2)}
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    )
                    ) : null
                }

              </div>
            }
          </div>
        }
      </Paper>
    );
  }
}

export default EndOfDayPage;
