import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import {
    setPaketYoldaUserVisible, setPaketYoldaUserAdd, setPaketYoldaUser
} from '../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class PaketYoldaUserList extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaUserUpdate == this.props.paketYoldaUserUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        this.handleSubmitUserAdd = this.handleSubmitUserAdd.bind(this);
    }

    handleChangePaketYoldaUser = (Name) => (event) => {
        const caret = event.target.selectionStart
        const element = event.target
        window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
        })

        const { paketYoldaUserAdd } = this.props;
        paketYoldaUserAdd[Name] = (Name == "IsActive" ? event.target.checked : event.target.value);
        this.props.setPaketYoldaUserAdd(paketYoldaUserAdd).then(() => { })
    };

    handleSubmitUserAdd(event) {
        event.preventDefault();
        this.props.setPaketYoldaUser().then(() => { })
    }

    render() {
        const { classes, paketYoldaUserVisible, paketYoldaUserList, paketYoldaUserAdd } = this.props;

        return (
            <Dialog
                open={paketYoldaUserVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'lg'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"JaviKurye Kullanıcı Ekleme"}
                    <Typography className={classes.instructions} style={{ fontSize: 13, fontWeight: 500, color: 'white' }}>
                        {"PaketYolda sistemlerine erişebilecek kullanıcı ekleme"}
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto', height: '100vh' }}>
                    <form style={{ display: 'contents' }} onSubmit={this.handleSubmitUserAdd}>
                        <Grid container spacing={1}>
                            <Grid item md={2} style={{ marginTop: 19, display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={paketYoldaUserAdd.IsActive}
                                            onChange={this.handleChangePaketYoldaUser("IsActive")}
                                        />
                                    )}
                                    label={"Aktif"}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Kullanıcı Adı'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Kullanıcı Adı Giriniz'}
                                        value={paketYoldaUserAdd.Username}
                                        onChange={this.handleChangePaketYoldaUser('Username')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Şifre'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Şifre Giriniz'}
                                        value={paketYoldaUserAdd.Passsword}
                                        onChange={this.handleChangePaketYoldaUser('Passsword')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={2}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'PIN Giriniz'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'1-9999 arasında giriniz'}
                                        value={paketYoldaUserAdd.PIN}
                                        onChange={this.handleChangePaketYoldaUser('PIN')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={1}>
                                <Button type={"submit"} fullWidth style={{ marginTop: 19, borderRadius: 0 }} variant="contained" color="secondary">
                                    {(paketYoldaUserAdd.Id == "00000000-0000-0000-0000-000000000000" ? "Ekle" : "Düzenle")}
                                </Button>
                            </Grid>
                            <Grid item md={1}>
                                <Button onClick={() => this.props.setPaketYoldaUserAdd(JSON.parse(JSON.stringify({
                                    Id: "00000000-0000-0000-0000-000000000000",
                                    Username: "",
                                    Passsword: "",
                                    PaketYoldaId: "",
                                    IsActive: true,
                                    PIN:""
                                }))).then(() => { })} fullWidth style={{ color: 'white', backgroundColor: '#e74c3c', marginTop: 19, borderRadius: 0 }} variant="contained" color="danger">
                                    {"TEMİZLE"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '100px' }}>{'Aktif'}</TableCell>
                                <TableCell>{'Kullanıcı Adı'}</TableCell>
                                <TableCell>{'Şifre'}</TableCell>
                                <TableCell>{'PIN'}</TableCell>
                                <TableCell align='right'>{'İşlem'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paketYoldaUserList.map((n, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {<Icon>{n.IsActive ? 'check_box' : 'check_box_outline_blank'}</Icon>}
                                        </TableCell>
                                        <TableCell>{n.Username}</TableCell>
                                        <TableCell>{n.Passsword}</TableCell>
                                        <TableCell>{n.PIN}</TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title={'Kullanıcı Düzenleme'}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5 }}
                                                    aria-label="Kullanıcı Düzenleme"
                                                    onClick={() => this.props.setPaketYoldaUserAdd(JSON.parse(JSON.stringify(n))).then(() => { })}>
                                                    <Icon>{'edit'}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPaketYoldaUserVisible(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

PaketYoldaUserList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaUserUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaUserUpdate: value(state, props),
    paketYoldaUserVisible: state.get('paketYoldaReducer').paketYoldaUserVisible,
    paketYoldaUserList: state.get('paketYoldaReducer').paketYoldaUserList,
    PaketYoldaId: state.get('paketYoldaReducer').PaketYoldaId,
    paketYoldaUserAdd: state.get('paketYoldaReducer').paketYoldaUserAdd
});

const mapDispatchToProps = {
    setPaketYoldaUserVisible,
    setPaketYoldaUserAdd,
    setPaketYoldaUser
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(PaketYoldaUserList)));
