import React from 'react';
import {
  createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { PapperBlock } from 'dan-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import jarvis from 'dan-images/jarvis.png';
import moment from 'moment';
import ScrollContainer from 'react-indiana-drag-scroll';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import Ionicon from 'react-ionicons';
import {
  formDataSetKitchen, setKitchenHubConnection, getAllKitchenTable, setStatusKitchenTable, setAllKitchenOrderData,
  setKitchenUpdate, getKitchenStaffControl, setKitchenModal, setKitchenPin, orderControlTrendyol, setCookingTime
} from '../../../redux/modules/kitchen';
import styles from './kitchenpage-jss';
import Decoration from '../../Templates/Decoration';
import Outer from '../../Templates/Outer';
import ModalInformation from '../Ortak/ModalInformation';
import './kitchen.css';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import jarvis_orginal from 'dan-images/jarvis_orginal.png';
import { getParameter } from '../../../function/GeneralFunction';

const elem = document.documentElement;

class KitchenPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { countTimer: 0, fullScreen: false };
  }

  containerScrollAddition = React.createRef();
  containerScrollKitchen = React.createRef();

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.kitchenUpdate == this.props.kitchenUpdate) {
      return false;
    }
    return true;
  }

  async tickTimerKitchen() {
    this.setState({
      countTimer: this.state.countTimer + 1
    });
    if (this.state.countTimer > 60) {
      this.clearTimerKitchen();
      this.props.orderControlTrendyol().then(() => { });
    }
  }

  clearTimerKitchen() {
    this.setState({
      countTimer: 0
    });
  }

  async componentDidMount() {
    if (this.props.kitchenHubConnection == null) {
      await this.props.setKitchenHubConnection().then(() => { });
    }
    await this.props.getAllKitchenTable(1).then(() => { });

    this.timerTimerKitchen = setInterval(() => this.tickTimerKitchen(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.countTimer);
  }


  compareKitchenList(a, b) {
    const genreA = a.CreationDatetime;
    const genreB = b.CreationDatetime;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  renderUnitName(unit) {
    var unitname = "";
    if (unit == undefined) {
      return unitname;
    } else if (unit.length == 0) {
      return unitname;
    }
    return (unit.length == 1 ? unit[0].UnitName : unit.find(x => x.IsSelected == true).UnitName);
  }

  renderAdditionListPrice(item) {
    let price = item.Price;
    item.Unit.map(n => {
      if (n.IsSelected) {
        price = n.Price;
      }
    });
    item.Property.map(n => {
      n.Item.map(n => {
        if (n.Default && n.Quantity == 0) {
          price += n.Price;
        } else if (n.Default && n.Quantity > 0) {
          price += (n.Price * n.Quantity);
        }
      });
    });

    item.Menu.map(n => {
      n.Item.map(n => {
        if (n.Default) {
          price += n.Price;
        }
      });

      if (n.MenuDetailSelected != null) {
        if (n.MenuDetailSelected.length != 0) {
          n.MenuDetailSelected.map(x => {
            x.Item.map(n => {
              if (n.Default && n.Quantity == 0) {
                price += n.Price;
              } else if (n.Default && n.Quantity > 0) {
                price += (n.Price * n.Quantity);
              }
            });
          });
        }
      }
    });

    price *= item.Quantity;
    return price.toFixed(2).replace(/[.]/, ',');
  }

  renderPropertyDetailMenu(MenuDetail) {
    if (MenuDetail != null) {
      if (MenuDetail.length > 0) {
        return MenuDetail.map((n, x) => n.Item.map((y, i) => {
          if (y.Default) {
            return (
              <span
                key={i}
                style={{
                  fontSize: 13, fontWeight: 700, color: '#B71C1C', textTransform: 'capitalize'
                }}
              >
                {' '}
                {y.Quantity > 0 ? y.Quantity + 'x' : ''}
                {y.Name}
                {' , '}
              </span>
            );
          }
          return null;
        }));
      }
    }
  }

  renderProductDetail(property) {
    return property.map((n, x) => (
      <span
        key={x}
        style={{
          display: 'block', fontSize: 13, fontWeight: 600, textTransform: 'capitalize', whiteSpace: 'normal'
        }}
      >
        {this.renderPropertyDetail(n.Item)}
        {(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? '(' : '') : '')}
        {this.renderPropertyDetailMenu(n.MenuDetailSelected)}
        {(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? ')' : '') : '')}
      </span>
    ));
  }

  renderPropertyDetail(item) {
    return item.map((n, x) => {
      if (n.Default) {
        return (
          <span
            key={x}
            style={{
              fontSize: 13, fontWeight: 600, textTransform: 'capitalize'
            }}
          >{n.Quantity > 0 ? n.Quantity + 'x' : ''} {n.Name}{x === item.length - 1 ? "" : " - "}
          </span>
        );
      }
      return null;
    });
  }

  renderProductDetailUnit(unit) {
    if (unit.length == 1) {
      return null;
    }
    return unit.map((n, x) => {
      if (n.IsSelected) {
        return '(' + n.UnitName + ')';
      }
    });
  }

  handleCloseApp = () => {
    cookie.remove('UserName', { path: '/' });
    cookie.remove('RestaurantName', { path: '/' });
    cookie.save('AdminLogin', "false", { path: '/' });
    cookie.remove('UserId', { path: '/' });
    cookie.remove('BrandId', { path: '/' });
    cookie.remove('TenantId', { path: '/' });
    cookie.remove('CenterBrandId', { path: '/' });
    cookie.remove('UserType', { path: '/' });
    cookie.remove('Settings', { path: '/' });
    cookie.remove('LicenceNumber', { path: '/' });
    cookie.remove('NameSurname', { path: '/' });
    cookie.remove('Authorization', { path: '/' });
    cookie.remove('KitchenUserId', { path: '/' });
    cookie.remove('KitchenUserName', { path: '/' });
    reactLocalStorage.remove('l_string');
    window.location.href = '/';
  };

  openFullScreen = () => {
    this.setState({ fullScreen: true });
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }

    this.props.setKitchenUpdate().then(() => { });
  };

  closeFullScreen = () => {
    this.setState({ fullScreen: false });
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }

    this.props.setKitchenUpdate().then(() => { });
  };

  setNumberButton(number) {
    const { kitchenUserPIN } = this.props;
    if (kitchenUserPIN.length < 4) {
      this.props.setKitchenPin(kitchenUserPIN + number).then(() => { });
    }
  }

  setNumberButtonRemove() {
    this.props.setKitchenPin("").then(() => { });
  }

  setUserModalOpen() {
    cookie.remove('KitchenUserId', { path: '/' });
    cookie.remove('KitchenUserName', { path: '/' });
    this.props.setKitchenUpdate().then(() => { });
  }

  modalClose = () => {
    this.props.setKitchenModal("").then(() => { })
  };

  cookieUserIdControl() {
    var status = false;
    if (cookie.load("KitchenUserId") == undefined) {
      status = true;
    } else if (cookie.load("KitchenUserId").length == 0) {
      status = true;
    }
    return status;
  }

  renderPass(pass) {
    var val = "";
    for (let i = 0; i < pass.length; i++) {
      val += "*";
    }
    return val;
  }

  renderUserLogin() {
    const { classes, kitchenUserPIN } = this.props;

    return (
      <div style={{ display: (this.cookieUserIdControl() ? 'flex' : 'none'), position: 'absolute', top: -5, bottom: -5, right: -5, left: -5, zIndex: 1000000, backgroundColor: 'rgb(66, 66, 66, .98)' }}>
        <Grid
          container
          alignItems="center"
          style={{ minHeight: '100%', zIndex: 99 }}
          spacing={1}
        >
          <Grid item md={12} align="center">
            <div style={{ width: 450, backgroundColor: 'white', borderRadius: 10, padding: 10, position: 'relative' }}>
              <img style={{ height: 50 }} src={jarvis_orginal} />
              <div style={{ padding: 20, fontSize: 20, fontWeight: 600 }}>{"Mutfak Personeli Giriş Ekranı"}</div>
              <Grid container spacing={1}>
                <Grid item md={12} style={{ marginBottom: 10 }}>
                  <div style={{ fontSize: 32, fontWeight: 600, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid rgba(209, 204, 192,1.0)', borderRadius: 10, height: 50, color: '#424242' }}>
                    {this.renderPass(kitchenUserPIN)}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButton("1")} style={{ backgroundColor: 'rgba(34, 112, 147,1.0)', width: '100%', height: '100%', height: 80 }}>
                    <Typography style={{ fontSize: 35, color: 'white' }} variant="subtitle1">{"1"}</Typography>
                  </ButtonBase>
                </Grid>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButton("2")} style={{ backgroundColor: 'rgba(34, 112, 147,1.0)', width: '100%', height: '100%', color: 'white', height: 80 }}>
                    <Typography style={{ fontSize: 35 }} variant="subtitle1">{"2"}</Typography>
                  </ButtonBase>
                </Grid>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButton("3")} style={{ backgroundColor: 'rgba(34, 112, 147,1.0)', width: '100%', height: '100%', color: 'white', height: 80 }}>
                    <Typography style={{ fontSize: 35 }} variant="subtitle1">{"3"}</Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButton("4")} style={{ backgroundColor: 'rgba(34, 112, 147,1.0)', width: '100%', height: '100%', color: 'white', height: 80 }}>
                    <Typography style={{ fontSize: 35 }} variant="subtitle1">{"4"}</Typography>
                  </ButtonBase>
                </Grid>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButton("5")} style={{ backgroundColor: 'rgba(34, 112, 147,1.0)', width: '100%', height: '100%', color: 'white', height: 80 }}>
                    <Typography style={{ fontSize: 35 }} variant="subtitle1">{"5"}</Typography>
                  </ButtonBase>
                </Grid>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButton("6")} style={{ backgroundColor: 'rgba(34, 112, 147,1.0)', width: '100%', height: '100%', color: 'white', height: 80 }}>
                    <Typography style={{ fontSize: 35 }} variant="subtitle1">{"6"}</Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButton("7")} style={{ backgroundColor: 'rgba(34, 112, 147,1.0)', width: '100%', height: '100%', color: 'white', height: 80 }}>
                    <Typography style={{ fontSize: 35 }} variant="subtitle1">{"7"}</Typography>
                  </ButtonBase>
                </Grid>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButton("8")} style={{ backgroundColor: 'rgba(34, 112, 147,1.0)', width: '100%', height: '100%', color: 'white', height: 80 }}>
                    <Typography style={{ fontSize: 35 }} variant="subtitle1">{"8"}</Typography>
                  </ButtonBase>
                </Grid>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButton("9")} style={{ backgroundColor: 'rgba(34, 112, 147,1.0)', width: '100%', height: '100%', color: 'white', height: 80 }}>
                    <Typography style={{ fontSize: 35 }} variant="subtitle1">{"9"}</Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButtonRemove()} style={{ backgroundColor: 'rgba(179, 57, 57,1.0)', width: '100%', height: '100%', height: 80 }}>
                    <Icon style={{ fontSize: 45, color: 'white' }}>{"backspace"}</Icon>
                  </ButtonBase>
                </Grid>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.setNumberButton("0")} style={{ backgroundColor: 'rgba(34, 112, 147,1.0)', width: '100%', height: '100%', color: 'white', height: 80 }}>
                    <Typography style={{ fontSize: 35 }} variant="subtitle1">{"0"}</Typography>
                  </ButtonBase>
                </Grid>
                <Grid item md={4}>
                  <ButtonBase onClick={() => this.loginUser()} style={{ backgroundColor: 'rgba(33, 140, 116,1.0)', width: '100%', height: '100%', height: 80 }}>
                    <Icon style={{ fontSize: 45, color: 'white' }}>{"check"}</Icon>
                  </ButtonBase>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  async loginUser() {
    await this.props.getKitchenStaffControl().then(() => { })
  }

  render() {
    const { classes, kitchenLoading, kitchenStatusType, kitchenCookingTimeData } = this.props;

    return (
      <div style={{
        backgroundColor: '#eeeeee', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10000
      }}
      >
        {(kitchenLoading ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, zIndex: 1000001, backgroundColor: 'rgb(66, 66, 66, .7)' }}>
            <CircularProgress style={{ color: 'white' }} className={classes.circularProgress} size={70} thickness={1} color="secondary" />
          </div>
          : null)}
        <ModalInformation event={this.modalClose} open={this.props.modalOpen} title={this.props.modalTitle} text={this.props.modalText} />
        {this.renderUserLogin()}
        <div style={{ height: '100%', borderRadius: 3 }}>
          <Grid container spacing={1}>
            <Grid item md={8} style={{ paddingRight: 0 }}>
              <Button onClick={() => this.props.getAllKitchenTable(1).then(() => { })} fullWidth variant="contained" color="primary" style={{ opacity: (kitchenStatusType == 1 ? 1 : .6), borderRadius: 0, textTransform: 'capitalize', backgroundColor: '#218c74', height: 36.5 }}>
                {(kitchenStatusType == 1 ? <div style={{ display: 'flex' }} class={"shake"}><Icon style={{ marginRight: 10, fontSize: 20 }}>{"thumb_up"}</Icon></div> : null)}
                <span style={{ fontSize: 16 }}>{'İşlemde Olanlar'}</span>
              </Button>
            </Grid>
            <Grid item md={2} style={{ paddingRight: 0 }}>
              <Button onClick={() => this.props.getAllKitchenTable(2).then(() => { })} fullWidth variant="contained" color="primary" style={{ opacity: (kitchenStatusType == 2 ? 1 : .6), borderRadius: 0, textTransform: 'capitalize', backgroundColor: '#227093', height: 36.5 }}>
                {(kitchenStatusType == 2 ? <div style={{ display: 'flex' }} class={"shake"}><Icon style={{ marginRight: 10, fontSize: 20 }}>{"thumb_up"}</Icon></div> : null)}
                <span style={{ fontSize: 16 }}>{'Tamamlananlar'}</span>
              </Button>
            </Grid>
            <Grid item md={2} style={{ paddingRight: 0 }}>
              <Button onClick={() => this.props.getAllKitchenTable(3).then(() => { })} fullWidth variant="contained" color="primary" style={{ opacity: (kitchenStatusType == 3 ? 1 : .6), borderRadius: 0, textTransform: 'capitalize', backgroundColor: '#b33939', height: 36.5 }}>
                {(kitchenStatusType == 3 ? <div style={{ display: 'flex' }} class={"shake"}><Icon style={{ marginRight: 10, fontSize: 20 }}>{"thumb_up"}</Icon></div> : null)}
                <span style={{ fontSize: 16 }}>{'İptaller'}</span>
              </Button>
            </Grid>
          </Grid>
          <div style={{ height: 'calc(100vh - 72px)', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
            <ScrollContainer style={{ width: '100%', display: '-webkit-inline-box', padding: 8, paddingLeft: 5 }} className={classes.containerScrollAddition} ref={this.containerScrollAddition}>
              {getParameter("IsCookingTime") && kitchenStatusType == 1 ?
                <div style={{ marginRight: 4, width: '19%', height: 'calc(100vh - 88px)' }}>
                  <div style={{
                    height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'white', border: '1px solid rgba(189, 195, 199,.5)', boxShadow: '0px 0px 5px -2px rgba(0,0,0,0.75)', position: 'relative'
                  }}
                  >
                    <div
                      id="additionLabel"
                      style={{
                        justifyContent: 'center', borderRadius: 0, marginBottom: 5
                      }}
                    >
                      <div style={{
                        backgroundColor: '#84817a', display: 'flex', flexDirection: 'column', padding: 4, justifyContent: 'center', fontSize: 16, height: 35, color: 'white', fontWeight: 500, borderBottom: '1px solid white'
                      }}
                      >
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                          {"Pişirme Ünitesinde Bekleyenler"}
                        </div>
                      </div>
                    </div>
                    <ScrollContainer style={{ height: '100%' }} className={classes.containerScrollKitchen} ref={this.containerScrollKitchen}>
                      <Grid
                        container
                        style={{
                          flex: 1, flexDirection: 'column', display: 'inline-grid', overflowY: 'none', maxHeight: '100vh', flexWrap: 'nowrap', paddingBottom: 10,
                        }}
                      >
                        {
                          kitchenCookingTimeData.map((x, r) => {

                            var minutes = moment.duration(moment(x.CreationDatetime).add(x.CookingTime, 'minutes').diff()).asMinutes().toFixed(0);

                            return (
                              <Button
                                key={r}
                                onClick={() => this.props.setCookingTime(x.ImageUrl, x.ItemId, x.CategoryId, x.UserId, x.IsSelected).then(() => { })}
                                variant='contained'
                                fullWidth
                                style={{
                                  display: 'flex', borderRight: (minutes <= 0 && x.CookingTime > 0 ? '6px solid rgba(179, 57, 57,1.0)' : ''), borderLeft: (minutes <= 0 && x.CookingTime > 0 ? '6px solid rgba(179, 57, 57,1.0)' : ''), borderBottom: '1px solid rgba(209, 204, 192,.3)', paddingTop: 5, paddingBottom: 15, borderRadius: 0, justifyContent: 'flex-start', textAlign: 'left', backgroundColor: 'transparent', paddingLeft: 0, paddingRight: 0, marginBottom: 2
                                }}
                              >
                                <div style={{ width: '100%', padding: '0px 5px 0px 5px', paddingBottom: 5 }}>
                                  <div style={{
                                    display: 'flex', alignItems: 'center', fontSize: 18, height: 25, color: '#424242', fontWeight: 600
                                  }}
                                  >
                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                      <span style={{ textTransform:'capitalize', WebkitLineClamp: 2, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', whiteSpace: 'pre-wrap', fontSize: 15, textAlign: 'left', width: '100%', lineHeight: 1.3 }}>
                                        {x.Name}
                                      </span>
                                      <span style={{ backgroundColor: (minutes <= 0 ? 'rgba(179, 57, 57,1.0)' : '#227093'), marginLeft: 5, marginRight: 2, textTransform: 'capitalize', fontSize: 20, color: 'white', borderRadius: 5, paddingTop: 3, paddingBottom: 3, justifyContent: 'center', display: 'flex', textAlign: 'center', alignItems: 'center', lineHeight: 1, paddingLeft: 5, paddingRight: 5 }}>
                                        {minutes}<span style={{ fontSize: 10, marginLeft: 2, textTransform: 'lowercase' }}>{"dk"}</span>
                                      </span>
                                    </div>
                                  </div>
                                  <div style={{
                                    fontSize: 12, fontWeight: 600, color: 'rgba(33, 140, 116,1.0)', textTransform: 'capitalize', marginTop: 5
                                  }}
                                  >
                                    {this.renderProductDetail(x.Property)}
                                    {this.renderProductDetail(x.Menu)}
                                    {
                                      (x.Description != null ? (x.Description.length != 0 ? (
                                        <span style={{
                                          marginLeft: 7, fontSize: 11, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize'
                                        }}
                                        >
                                          {'('}{x.Description}{')'}
                                        </span>
                                      ) : null) : null)
                                    }
                                  </div>
                                </div>
                              </Button>
                            )
                          })
                        }
                      </Grid>
                    </ScrollContainer>
                  </div>
                </div>
                : null}

              {
                this.props.kitchenOrderData.map((n, i) => (
                  <div style={{ marginRight: 5, width: (getParameter("IsCookingTime") && kitchenStatusType == 1 ? '20%' : '25%'), height: 'calc(100vh - 88px)' }}>
                    <div style={{
                      height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'white', border: '1px solid rgba(189, 195, 199,.5)', boxShadow: '0px 0px 5px -2px rgba(0,0,0,0.75)', position: 'relative'
                    }}
                    >
                      <div
                        id="additionLabel"
                        style={{
                          justifyContent: 'center', borderRadius: 0, marginBottom: 5
                        }}
                      >
                        <div style={{
                          backgroundColor: (n.IsDeliveryTime ? '#7B1FA2' : (n.KitchenOrderType == 1 ? '#FB8C00' : 'rgba(255, 121, 63,1.0)')), display: 'flex', flexDirection: 'column', padding: 4, justifyContent: 'center', fontSize: 16, height: 75, color: 'white', fontWeight: 500, borderBottom: '1px solid white'
                        }}
                        >
                          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                            <span style={{
                              textAlign: 'left', width: '100%', display: 'flex', fontWeight: 600, fontSize: 17
                            }}
                            >
                              <Icon style={{ fontSize: 27, marginRight: 5 }}>{(n.KitchenOrderType == 0 || n.KitchenOrderType == 2 ? 'table_bar' : (n.KitchenOrderType == 1 ? 'moped' : 'storefront'))}</Icon>
                              {(n.KitchenOrderType == 0 ? 'Masa' : (n.KitchenOrderType == 1 ? 'Paket' : (n.KitchenOrderType == 2 ? 'Self Satış' : 'Gel Al')))}
                            </span>
                            <span style={{
                              display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%', fontSize: 14, fontWeight: 600
                            }}
                            >
                              <div style={{ paddingLeft: 8, paddingRight: 8, borderRadius: 5, height: 30, backgroundColor: 'white', color: '#313131', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {(n.PackageChannelType == 0 ? 'JaviPos' : (n.PackageChannelType == 1 ? 'GetirYemek' : (n.PackageChannelType == 2 ? 'TrendyolYemek' : (n.PackageChannelType == 3 ? 'YemekSepeti' : n.TableName))))}
                              </div>
                            </span>
                          </div>
                          <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: 5 }}>
                            <span style={{ textAlign: 'left', width: '100%', alignItems: 'center', display: 'flex', fontSize: 18 }}>
                              <Icon style={{ fontSize: 23, marginRight: 5 }}>{"receipt_long"}</Icon>
                              {n.AddiCode}
                            </span>
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%', fontSize: 18 }}>
                              <Icon style={{ fontSize: 23, marginRight: 5 }}>{"pending_actions"}</Icon>
                              {(n.IsDeliveryTime ? moment(n.DeliveryTime).format('HH:mm:ss') : moment(n.CreationDatetime).format('HH:mm:ss'))}
                            </span>
                          </div>
                        </div>
                      </div>
                      <ScrollContainer style={{ height: '100%' }} className={classes.containerScrollKitchen} ref={this.containerScrollKitchen}>
                        <Grid
                          container
                          style={{
                            flex: 1, flexDirection: 'column', display: 'inline-grid', overflowY: 'none', maxHeight: '100vh', flexWrap: 'nowrap', paddingBottom: 10,
                          }}
                        >
                          {(n.KitchenStatusType == 0 ? <div style={{ width: '100%', height: 'calc(100vh - 218px)', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <div style={{ display: 'inline-grid' }}>
                              <span style={{ fontWeight: 600 }}>{"Ürünleri Görmek için BAŞLA'ya Tıklayınız!"}</span>
                              <div style={{ display: 'flex', justifyContent: 'center' }}><Icon style={{ fontSize: 60, marginTop: 10 }}>{"arrow_downward"}</Icon></div>
                            </div>
                          </div> : "")}
                          {
                            n.ProductItems.map((x, r) => {
                              var minutes = (n.KitchenStatusType == 1 && x.PreparationComplated == false ? moment.duration(moment(n.StartDatetime).add(x.PreparationTime, 'minutes').diff()).asMinutes().toFixed(0) : x.PreparationFinishTime);

                              return (
                                <Button
                                  key={r}
                                  onClick={() => this.props.setStatusKitchenTable(n.Id, 1, x.ItemId, x.CategoryId, x.UserId, x.IsSelected).then(() => { })}
                                  variant='contained'
                                  fullWidth
                                  disabled={x.PreparationComplated ? true : false}
                                  style={{
                                    display: (n.KitchenStatusType == 0 ? 'none' : 'flex'), borderRight: (minutes <= 0 && x.PreparationTime > 0 ? '6px solid rgba(179, 57, 57,1.0)' : ''), borderLeft: (minutes <= 0 && x.PreparationTime > 0 ? '6px solid rgba(179, 57, 57,1.0)' : ''), borderBottom: '1px solid rgba(209, 204, 192,.3)', paddingTop: 5, paddingBottom: 15, borderRadius: 0, justifyContent: 'flex-start', textAlign: 'left', backgroundColor: 'transparent', paddingLeft: 0, paddingRight: 0, marginBottom: 2
                                  }}
                                >
                                  <div style={{ width: '100%', padding: '0px 5px 0px 5px', paddingBottom: 5 }}>
                                    <div style={{
                                      display: 'flex', alignItems: 'center', fontSize: 18, height: 25, color: '#424242', fontWeight: 600
                                    }}
                                    >
                                      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                        <span style={{ alignItems:'center', fontWeight: 700, textTransform: 'capitalize', WebkitLineClamp: 2, overflow: 'hidden', textOverflow: 'ellipsis', display: 'flex', WebkitBoxOrient: 'vertical', textDecoration: (x.PreparationComplated ? 'line-through' : ''), whiteSpace: 'pre-wrap', fontSize: 15, textAlign: 'left', width: '100%', lineHeight: 1.3 }}>
                                          {( x.IsSelected ? <Icon style={{ color: '#3498db', marginRight:5 }}>{"menu_book"}</Icon> : null )}
                                          {x.Name} <span style={{ color: '#84817a', fontSize: 13 }}>{this.renderUnitName(x.Unit)}</span>
                                        </span>
                                        <span style={{ fontWeight: 700, textTransform: 'capitalize', fontSize: 20, color: (minutes <= 0 ? 'white' : ''), borderRadius: 5, paddingTop: 3, paddingBottom: 3, justifyContent: 'center', display: (x.PreparationTime == 0 ? 'none' : 'flex'), textAlign: 'center', alignItems: 'center', lineHeight: 1, paddingLeft: 5, paddingRight: 5 }}>
                                          {minutes}<span style={{ fontSize: 10, marginLeft: 2, textTransform: 'lowercase' }}>{"dk"}</span>
                                        </span>
                                      </div>
                                    </div>
                                    <div style={{
                                      fontSize: 12, fontWeight: 600, color: 'rgba(33, 140, 116,1.0)', textTransform: 'capitalize', marginTop: 5
                                    }}
                                    >
                                      {this.renderProductDetail(x.Property)}
                                      {this.renderProductDetail(x.Menu)}
                                      {
                                        (x.Description != null ? (x.Description.length != 0 ? (
                                          <span style={{
                                            marginLeft: 7, fontSize: 11, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize'
                                          }}
                                          >
                                            {'('}{x.Description}{')'}
                                          </span>
                                        ) : null) : null)
                                      }
                                    </div>
                                  </div>
                                </Button>
                              )
                            })
                          }
                        </Grid>
                      </ScrollContainer>
                      {
                        (n.AddiDescription.length > 0 ?
                          <div style={{ display: 'block', borderTop: '1px solid rgba(132, 129, 122,.3)', padding: 5 }}>
                            <span style={{
                              width: '100%', display: 'flex', alignItems: 'center', fontWeight: 500
                            }}
                            >
                              <Icon style={{ fontSize: 22, marginRight: 5 }}>{"info"}</Icon>
                              {n.AddiDescription}
                            </span>
                          </div>
                          : null)
                      }
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {(n.KitchenStatusType == 1 ? <div style={{ display: 'flex', width: '100%' }}>
                          <Button
                            variant="contained"
                            onClick={() => this.props.setStatusKitchenTable(n.Id, 3).then(() => { })}
                            fullWidth
                            style={{
                              height: 40, backgroundColor: 'rgba(179, 57, 57,1.0)', color: 'white', borderRadius: 0, textTransform: 'capitalize', width: '100%', margin: 2
                            }}
                          >
                            <Icon style={{ fontSize: 20, marginRight: 10 }}>{"thumb_down"}</Icon>
                            <span style={{ fontSize: 16 }}>{"İptal Et"}</span>
                          </Button>
                        </div> :
                          (n.KitchenStatusType == 0 ?
                            <Button
                              variant="contained"
                              onClick={() => this.props.setStatusKitchenTable(n.Id, 1).then(() => { })}
                              fullWidth
                              style={{
                                height: 40, backgroundColor: '#cc8e35', color: 'white', borderRadius: 0, textTransform: 'capitalize', width: '100%', margin: 2
                              }}
                            >
                              <Icon style={{ fontSize: 24, marginRight: 10 }}>{"timer"}</Icon>
                              <span style={{ fontSize: 20 }}>{"Başla"}</span>
                            </Button>
                            : <div style={{ height: 40, justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', backgroundColor: (n.KitchenStatusType == 2 ? 'rgba(33, 140, 116,1.0)' : 'rgba(179, 57, 57,1.0)') }}>
                              <span style={{ color: 'white' }}>{(n.KitchenStatusType == 2 ? 'Tamamlandı' : (n.KitchenStatusType == 4 ? 'Kısmen Tamamlandı' : 'İptal Edildi'))}</span>
                            </div>)
                        )}
                      </div>
                    </div>
                  </div>
                ))
              }
            </ScrollContainer>
          </div>
          <Grid container spacing={1}>
            <Grid item md={9} style={{ paddingRight: 0 }}>
              <div style={{ justifyContent: 'space-between', backgroundColor: 'rgba(132, 129, 122,1.0)', height: '100%', width: '100%', display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center', borderRight: '1px solid rgba(209, 204, 192,.5)', paddingRight: 10 }}>
                  <Icon style={{ color: 'white', marginRight: 10, fontSize: 20 }}>{"restaurant"}</Icon>
                  <span style={{ color: 'white', fontWeight: 600, fontSize: 17 }}>{cookie.load('KitchenUserName')}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', borderLeft: '1px solid rgba(209, 204, 192,.5)', paddingLeft: 10 }}>
                  <Icon style={{ color: 'white', marginRight: 10, fontSize: 20 }}>{"storefront"}</Icon>
                  <span style={{ color: 'white', fontWeight: 600, fontSize: 17 }}>{cookie.load('NameSurname')}</span>
                </div>
              </div>
            </Grid>
            <Grid item md={1} style={{ paddingRight: 0 }}>
              <Button
                onClick={() => this.setUserModalOpen()}
                fullWidth
                variant="outlined"
                style={{
                  color: 'white', borderRadius: 0, textTransform: 'capitalize', backgroundColor: '#424242'
                }}
              >
                <Icon>{"key"}</Icon>
              </Button>
            </Grid>
            <Grid item md={1} style={{ paddingRight: 0 }}>
              {this.state.fullScreen ? (
                <Button fullWidth onClick={this.closeFullScreen} variant="outlined" style={{ backgroundColor: '#424242', borderRadius: 0 }}>
                  <Icon>{"fullscreen_exit"}</Icon>
                </Button>
              ) : (
                <Button fullWidth onClick={this.openFullScreen} variant="outlined" style={{ backgroundColor: '#424242', borderRadius: 0 }}>
                  <Icon>{"fullscreen"}</Icon>
                </Button>
              )}
            </Grid>
            <Grid item md={1}>
              <Button
                onClick={this.handleCloseApp}
                fullWidth
                variant="outlined"
                style={{
                  color: 'white', borderRadius: 0, textTransform: 'capitalize', backgroundColor: '#424242'
                }}
              >
                <Icon>{"logout"}</Icon>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}


const valueSelector = (state, props) => state.get('kitchenReducer').kitchenUpdate;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  kitchenUpdate: value(state, props),
  kitchenLoading: state.get('kitchenReducer').kitchenLoading,
  kitchenHubConnection: state.get('kitchenReducer').kitchenHubConnection,
  modalOpen: state.get('kitchenReducer').form_data.modalOpen,
  modalTitle: state.get('kitchenReducer').form_data.modalTitle,
  modalText: state.get('kitchenReducer').form_data.modalText,

  kitchenOrderData: state.get('kitchenReducer').kitchenOrderData,
  kitchenUserPIN: state.get('kitchenReducer').kitchenUserPIN,
  kitchenStatusType: state.get('kitchenReducer').kitchenStatusType,
  kitchenCookingTimeData: state.get('kitchenReducer').kitchenCookingTimeData
});

const mapDispatchToProps = {
  formDataSetKitchen,
  setKitchenHubConnection,
  getAllKitchenTable,
  setStatusKitchenTable,
  setAllKitchenOrderData,
  setKitchenUpdate,
  getKitchenStaffControl,
  setKitchenModal,
  setKitchenPin,
  orderControlTrendyol,
  setCookingTime
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(KitchenPage)));