const styles = theme => ({
    weatherIcon: {
        position: 'relative',
        width: 50,
        height: 50,
        top: 0,
        float: 'right',
        margin: '40px 30px 0 0',
        background: '#FFCD41',
        borderRadius: '50%',
        boxShadow: 'rgb(255 255 0 / 10%) 0 0 0 4px',
        transform: 'translateY(-8px)',
        boxShadow: 'rgb(255 255 0 / 20%) 0 0 0 17px'
    },
    weatherDerece: {
        left: 35,
        color: '#fff',
        bottom: 0,
        position: 'absolute',
        animation: 'title-appear 500ms ease-out 500ms forwards',
        fontSize: 80,
        fontWeight: 300
    },
    weatherText: {
        left: 35,
        color: '#fff',
        bottom: 0,
        position: 'absolute',
        animation: 'title-appear 1s ease-out 500ms forwards',
        fontSize: 28,
        fontWeight: 300,
    }
});

export default styles;
