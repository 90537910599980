import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import ModalInformation from '../../Pages/Ortak/ModalInformation';
import cookie from 'react-cookies';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import styles from './profile-jss';
import { getSettings, setModal } from '../../../redux/modules/settings';
import SelectSearch from 'react-select-search';
import { getCustomerNotAddressList, formDataSetCustomer } from '../../../redux/modules/customer';
import {
  formDataSet, setCallerIdHubConnection, clearAddition, getAddressList, getCustomerAddress,
  removeCustomerAddress, getCustomerNewAddress, setModalAddition, setCustomerAddress,
  getRepeatOrderList, setAdditionListUpdate, setCallerIdCustomerRemove, setCallerIdData,
  setPhoneChangeVisible, setPhoneChangeValue, setChangePhoneControl, getCallerIdPhoneControl
} from '../../../redux/modules/addition';
import Paper from '@material-ui/core/Paper';
import { getStockGroup, setCategoryUpdate } from '../../../redux/modules/stock';
import Stepper from '@material-ui/core/Stepper';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Step from '@material-ui/core/Step';
import Switch from '@material-ui/core/Switch';
import StepLabel from '@material-ui/core/StepLabel';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import MaskedInput from 'react-text-mask';
import { CapitalizeText } from '../../../function/GeneralFunction';
import RepeatOrder from '../../Pages/DetailPage/Component/RepeatOrder';
import { getParameter } from '../../../function/GeneralFunction';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}


function getSteps() {
  return ['Müşteri İşlemleri', 'Adres İşlemleri'];
}

class CallerId extends React.Component {
  constructor() {
    super();
  }

  isStepOptional = step => (step === 1);

  isStepSkipped(step) {
    const { skipped } = this.state;
    return skipped.has(step);
  }

  async componentDidMount() {
    var sanalsantral = getParameter("SanalSantral");
    if (this.props.additionReducer.callerIdHubConnection == null) {
      this.props.setCallerIdHubConnection().then(() => { });
    }

    if (sanalsantral.UserId != "00000000-0000-0000-0000-000000000000") {
      if (sanalsantral.IsStatus && sanalsantral.UserId == cookie.load("UserId")) {
        this.webSocket(sanalsantral)
      }
    }
  }

  webSocket(sanalsantral) {
    var javiPosRedux = this;
    var deneme = new WebSocket("wss://api.sanal.link/ws");
    deneme.onopen = function () {
      var mesaj = {};
      mesaj["komut"] = "giris";
      mesaj["api_key"] = sanalsantral.api_key;
      mesaj["santral_id"] = sanalsantral.santral_id;
      mesaj["dahili"] = sanalsantral.dahili;
      deneme.send(JSON.stringify(mesaj));
    };
    deneme.onmessage = function (msg) {
      var mesaj = JSON.parse(decodeURIComponent(msg.data));
      mesaj = JSON.parse(mesaj.message);
      if (mesaj.komut === "arayan" && mesaj.tipi == "gelen" && mesaj.olay == "ringing") {
        console.log(mesaj)
        javiPosRedux.props.getCallerIdPhoneControl(mesaj.arayan).then(() => {
          javiPosRedux.props.formDataSetCustomer("callerIdPhoneVisible", true).then(() => { })
        })
      }
    };
  }

  handleCloseCallCenter = () => {
    this.props.formDataSetCustomer("customerCallerIdNew", false).then(() => { })
  };

  handleGetIlSelected = (e) => {
    this.props.getAddressList("Ilce", e.value).then(() => { })
    this.props.additionReducer.callCenterAddress.IlId = e.value;
    this.props.additionReducer.callCenterAddress.IlText = e.name;

    this.props.additionReducer.callCenterAddress.IlceId = "";
    this.props.additionReducer.callCenterAddress.IlceText = "";

    this.props.additionReducer.callCenterAddress.MahalleId = "";
    this.props.additionReducer.callCenterAddress.MahalleText = "";

    this.props.additionReducer.callCenterAddress.SokakId = "";
    this.props.additionReducer.callCenterAddress.SokakText = "";

    this.props.formDataSet("callCenterAddress", this.props.additionReducer.callCenterAddress).then(() => { })
  };

  handleGetIlceSelected = (e) => {
    this.props.getAddressList("Mahalle", e.value).then(() => { })
    this.props.additionReducer.callCenterAddress.IlceId = e.value;
    this.props.additionReducer.callCenterAddress.IlceText = e.name;

    this.props.additionReducer.callCenterAddress.MahalleId = "";
    this.props.additionReducer.callCenterAddress.MahalleText = "";

    this.props.additionReducer.callCenterAddress.SokakId = "";
    this.props.additionReducer.callCenterAddress.SokakText = "";

    this.props.formDataSet("callCenterAddress", this.props.additionReducer.callCenterAddress).then(() => { })
  };

  handleGetMahalleSelected = (e) => {
    this.props.getAddressList("Sokak", e.value).then(() => { })
    this.props.additionReducer.callCenterAddress.MahalleId = e.value;
    this.props.additionReducer.callCenterAddress.MahalleText = e.name;

    this.props.additionReducer.callCenterAddress.SokakId = "";
    this.props.additionReducer.callCenterAddress.SokakText = "";

    this.props.formDataSet("callCenterAddress", this.props.additionReducer.callCenterAddress).then(() => { })
  };

  handleGetSokakSelected = (e) => {
    this.props.additionReducer.callCenterAddress.SokakId = e.value;
    this.props.additionReducer.callCenterAddress.SokakText = e.name;
    this.props.formDataSet("callCenterAddress", this.props.additionReducer.callCenterAddress).then(() => { })
  };

  handleFocus = (event) => event.target.select();

  handleClickPhoneChange = () => {
    this.props.setPhoneChangeVisible().then(() => { })
  };


  handlePhoneChangeControl = event => {
    this.props.setPhoneChangeValue(event.target.value).then(() => { })
  };

  returnPhoneChange() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.modalPhoneChangeVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {"Değiştirilecek Telefon Numarası"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={1}>
              <Grid item md={12}>
                <FormControl className={classes.formControl} style={{ width: '100%' }}>
                  <InputLabel htmlFor="formatted-text-mask-input">{"Telefon No *"}</InputLabel>
                  <Input
                    value={this.props.phoneChangeControlValue}
                    onChange={this.handlePhoneChangeControl}
                    id="getPhone"
                    inputComponent={TextMaskCustom}
                    onFocus={this.handleFocus}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} onClick={() => this.props.setPhoneChangeVisible().then(() => { })} color="primary">
            {"İptal"}
          </Button>
          <Button style={{ backgroundColor: '#FFC107', color: 'white' }} onClick={() => this.props.setChangePhoneControl(this.props.additionReducer.callCenterAddress.CustomerId).then(() => { })} color="primary" autoFocus>
            {"Kontrol Et ve Değiştir"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getStepContent() {
    const { classes } = this.props;
    const { callCenterAddress } = this.props.additionReducer;

    return (
      <div>
        <Paper style={{ backgroundColor: '#303030', display: 'flex', padding: 5, borderRadius: 5, marginTop: 0 }} className={classes.root}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="name-simple">{"Adı Soyadı*"}</InputLabel>
                <Input id="Name" defaultValue={callCenterAddress.Name} onChange={this.handleChangeCallCenter("Name")} />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className={classes.formControl} style={{ width: '100%' }}>
                <InputLabel htmlFor="formatted-text-mask-input">{"Telefon No (zorunlu)"}</InputLabel>
                <Input
                  fullWidth
                  id="Phone"
                  onChange={this.handleChangeCallCenter('Phone')}
                  disabled
                  value={callCenterAddress.Phone}
                  className={classes.textField}
                  inputComponent={TextMaskCustom}
                  onFocus={this.handleFocus}
                  margin="normal"
                  variant="outlined"
                  endAdornment={(
                    <InputAdornment style={{ pointerEvents: 'auto' }} position="end">
                      <IconButton
                        style={{ display: (callCenterAddress.IsNewUser ? 'none' : '') }}
                        onClick={this.handleClickPhoneChange}
                      >
                        <Icon>{"change_circle"}</Icon>
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="name-simple">{"İndirim Oranı *"}</InputLabel>
                <Input id="DiscountRate" defaultValue={callCenterAddress.DiscountRate} onChange={this.handleChangeCallCenter("DiscountRate")} />
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        <Paper style={{ backgroundColor: '#303030', display: 'flex', padding: 5, borderRadius: 5, marginTop: 0 }} className={classes.root}>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <InputLabel style={{ fontSize: 10 }}>{"Zorunlu *"}</InputLabel>
              <SelectSearch onChange={this.handleGetIlSelected} value={callCenterAddress.IlId} options={this.props.additionReducer.IlList} search={true} name="il" placeholder="İl Seçimi" />
            </Grid>
            <Grid item md={3}>
              <InputLabel style={{ fontSize: 10 }}>{"Zorunlu *"}</InputLabel>
              <SelectSearch onChange={this.handleGetIlceSelected} value={callCenterAddress.IlceId} options={this.props.additionReducer.IlceList} search={true} name="ilce" placeholder="İlçe Seçimi" />
            </Grid>
            <Grid item md={3}>
              <InputLabel style={{ fontSize: 10 }}>{"İsteğe Bağlı"}</InputLabel>
              <SelectSearch onChange={this.handleGetMahalleSelected} value={callCenterAddress.MahalleId} options={this.props.additionReducer.MahalleList} search={true} name="mahalle" placeholder="Mahalle Seçimi" />
            </Grid>
            <Grid item md={3}>
              <InputLabel style={{ fontSize: 10 }}>{"İsteğe Bağlı"}</InputLabel>
              <SelectSearch onChange={this.handleGetSokakSelected} options={this.props.additionReducer.SokakList} value={callCenterAddress.SokakId} search={true} name="sokak" placeholder="Sokak Seçimi" />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                style={{ marginTop: 0 }}
                multiline={true}
                minRows={5}
                rows={5}
                onChange={this.handleChangeCallCenter('AdresDetail')}
                id="AdresDetail"
                defaultValue={callCenterAddress.AdresDetail}
                className={classes.textField}
                label="Adres Detayı - Kapı / Daire No vb. (Zorunlu)"
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                style={{ marginTop: 0 }}
                multiline={true}
                minRows={5}
                rows={5}
                defaultValue={callCenterAddress.AdresDescription}
                onChange={this.handleChangeCallCenter('AdresDescription')}
                id="AdresDescription"
                className={classes.textField}
                label="Adres Açıklaması (İsteğe Bağlı)"
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6}>
              <Grid item md={12}>
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  control={(
                    <Switch
                      checked={callCenterAddress.IsEmailSend}
                      onChange={this.handleChangeCallCenter('IsEmailSend')}
                      value="checkedA"
                    />
                  )}
                  label="Email Gönderimi"
                />
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  control={(
                    <Switch
                      checked={callCenterAddress.IsSmsSend}
                      onChange={this.handleChangeCallCenter('IsSmsSend')}
                      value="checkedA"
                    />
                  )}
                  label="Sms Gönderimi"
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }

  handleChangeCallCenter = name => event => {
    this.props.additionReducer.callCenterAddress[name] = (name == "IsEmailSend" || name == "IsSmsSend" ? event.target.checked : (name == "Color" ? event.hex : CapitalizeText(event.target.value)));
    this.props.formDataSet("callCenterAddress", this.props.additionReducer.callCenterAddress).then(() => { })
  };

  async handleNext() {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    const { callCenterAddress } = this.props.additionReducer;

    if (callCenterAddress.Name.length == 0) {
      this.props.setModalAddition("Adı Soyadı boş geçilemez!").then(() => { })
      return;
    }

    if (callCenterAddress.IlId.length == 0 || callCenterAddress.IlceId.length == 0) {
      this.props.setModalAddition("İl-İlçe-Mahalle-Sokak bilgileri boş geçilemez!").then(() => { })
      return;
    } else if (callCenterAddress.AdresDetail.length == 0) {
      this.props.setModalAddition("Adres detayı boş geçilemez!").then(() => { })
      return;
    }

     if(getParameter("IsPaketYolda" && callCenterAddress.SokakId.length == 0)){
      this.props.setModalAddition("PaketYolda siparişlerinde sokak seçimi zorunludur!").then(() => { })
      return;
    }
    
    await this.props.setCustomerAddress().then(() => { })
    if (!this.props.additionReducer.customerAddressSave) {
      return;
    }

    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: 2,
      skipped,
    });
  };

  customerAddressRemove(Id) {
    this.props.removeCustomerAddress(Id).then(() => { })
  }

  async newPackageAddition() {
    await this.props.clearAddition().then(() => { })
    this.props.formDataSet("newPackage", true).then(() => { })
    var addition = this.props.additionReducer.addition_data;
    addition.TableName = "Paket Satış";
    addition.TableId = "00000000-0000-0000-0000-000000000000";
    addition.OrderType = 1;
    await this.props.formDataSet("addition_data", addition).then(() => { })
    this.props.history.push('/app/detailpage?' + Math.random());
  }


  async newPackageCallCenter(n) {
    await this.props.clearAddition().then(() => {
      this.props.formDataSet("newPackage", true).then(() => { })
      var addi = this.props.additionReducer.addition_data;
      addi.TableId = "00000000-0000-0000-0000-000000000000";
      addi.TableName = "Paket Satış";
      addi.CustomerId = n.CustomerId;
      addi.OrderType = 1;
      addi.CustomerName = n.CustomerName;
      addi.CustomerAddressId = n.AddressId;
      addi.CustomerAddressText = n.AddressText + (n.AdresDescription.length == 0 ? "" : " Açıklama : " + n.AdresDescription) + " " + n.Phone + "-" + n.SecondPhone;
      addi.CustomerPhone = n.Phone;
      this.props.formDataSet("addition_data", addi).then(() => { })
      this.props.getStockGroup(false).then(() => { });
      this.props.formDataSetCustomer("customerCallerIdNew", false).then(() => { })
      this.props.setCategoryUpdate().then(() => { });
    })
  }

  async customerAddressUpdate(Id,BrandId) {
    await this.props.getCustomerAddress(Id,BrandId).then(() => { })
    const { callCenterAddress } = this.props.additionReducer;

    await this.props.getAddressList("Il", "").then(() => { })
    await this.props.getAddressList("Ilce", callCenterAddress.IlId).then(() => { })
    await this.props.getAddressList("Mahalle", callCenterAddress.IlceId).then(() => { })
    await this.props.getAddressList("Sokak", callCenterAddress.MahalleId).then(() => { })

    const { activeStep } = this.state;
    let { skipped } = this.state;
    const steps = getSteps();
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: 0,
      skipped,
    });
  }

  async calcRegionReload(callCenterAddress) {
    if (getParameter("DefaultCity").IlId > 0) {
      callCenterAddress.IlId = getParameter("DefaultCity").IlId;
      if (getParameter("DefaultCity").IlceId > 0) {
        callCenterAddress.IlceId = getParameter("DefaultCity").IlceId;
        await this.props.getAddressList("Ilce", getParameter("DefaultCity").IlId).then(() => { })
        await this.props.getAddressList("Mahalle", getParameter("DefaultCity").IlceId).then(() => { })
      }
    }
  }

  renderAddressList() {
    const { classes } = this.props;

    if (this.props.additionReducer.AdresList == null) {
      return null;
    }

    return (
      this.props.additionReducer.AdresList.map((n, i) => {
        return (
          <div key={i} style={{ width: '100%', border: '1px solid #f39c12', padding: 10, color: 'white', display: 'inline-block', marginBottom: 10 }}>
            <Typography className={classes.instructions}>{"Adres : "}{n.AddressText}</Typography>
            <Typography className={classes.instructions}>{(n.AdresDescription.length == 0 ? "" : "Açıklama : " + n.AdresDescription)}</Typography>
            <Typography className={classes.instructions}>{"Telefon : "}{n.Phone}{(n.SecondPhone.length == 0 ? "" : " - İkinci Telefon : " + n.SecondPhone)}</Typography>
            <div style={{ float: 'right' }}>
              <Button
                onClick={() => this.newPackageCallCenter(n)}
                className={classes.button}
                variant="contained"
                style={{ backgroundColor: '#2980b9', color: 'white', marginRight: 5, paddingLeft: 35, paddingRight: 35 }}
              >
                {"Seç"}
              </Button>
              <Button
                onClick={() => this.customerAddressUpdate(n.AddressId,n.BrandId)}
                className={classes.button}
                variant="contained"
                style={{ backgroundColor: '#f39c12', color: 'white', marginRight: 5 }}
              >
                {"Düzenle"}
              </Button>
              <Button
                onClick={() => this.customerAddressRemove(n.AddressId)}
                className={classes.button}
                variant="contained"
                style={{ backgroundColor: '#c0392b', color: 'white', marginRight: 5 }}
              >
                {"Sil"}
              </Button>
            </div>
          </div>
        )
      })
    )
  }

  renderCallCenterNewCustomer() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep, altLabel } = this.state;

    return (
      <Dialog
        open={this.props.customerReducer.customerCallerIdNew}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <RepeatOrder classes={classes} />
        <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {"Müşteri İşlemleri"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    {
                      this.renderAddressList()
                    }
                  </Typography>

                </div>
              ) : (
                <div>
                  <Typography className={classes.instructions}>{this.getStepContent()}</Typography>
                </div>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {activeStep !== steps.length ? (
            <div style={{ float: 'right' }}>
              <Button
                onClick={this.handleCloseCallCenter}
                className={classes.button}
                variant="contained"
                style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }}
              >
                {"ÇIKIŞ"}
              </Button>
              <Button
                onClick={this.handleLast}
                className={classes.button}
                variant="contained"
                style={{ backgroundColor: '#f39c12', color: 'white', marginRight: 5 }}
              >
                {"Adres Listesi"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleNext()}
                className={classes.button}
                style={{ marginRight: 5 }}
              >
                {'Adresi Kaydet'}
              </Button>
            </div>
          ) : <div>
            <Button
              onClick={() => this.props.getRepeatOrderList(this.props.additionReducer.callCenterAddressData.CustomerId).then(() => { })}
              className={classes.button}
              variant="contained"
              style={{ backgroundColor: '#009688', color: 'white', marginRight: 5 }}
            >
              {"Paket Geçmişi"}
            </Button>
            <Button
              onClick={() => this.customerNewAddress()}
              className={classes.button}
              variant="contained"
              style={{ backgroundColor: '#2980b9', color: 'white', marginRight: 5 }}
            >
              {"Yeni Adres"}
            </Button>
            <Button
              onClick={this.handleCloseCallCenter}
              className={classes.button}
              variant="contained"
              style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }}
            >
              {"Çıkış"}
            </Button>
          </div>}
        </DialogActions>
      </Dialog>
    );
  }

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  state = {
    activeStep: 0,
    altLabel: false,
    skipped: new Set(),
  };

  handleLast = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    const steps = getSteps();
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: steps.length,
      skipped,
    });
  }

  async handleReset(newOpe, phone = "") {
    this.setState({
      activeStep: 0,
    });
    var IlId = "";
    var IlceId = "";
    if (newOpe) {
      if (getParameter("DefaultCity").IlId > 0) {
        IlId = getParameter("DefaultCity").IlId;
        if (getParameter("DefaultCity").IlceId > 0) {
          IlceId = getParameter("DefaultCity").IlceId;
          await this.props.getAddressList("Ilce", getParameter("DefaultCity").IlId).then(() => { })
          await this.props.getAddressList("Mahalle", getParameter("DefaultCity").IlceId).then(() => { })
        }
      }
    }

    await this.props.formDataSet("callCenterAddress",
      {
        CustomerId: "00000000-0000-0000-0000-000000000000",
        Name: "",
        IlId: IlId,
        IlText: "",
        IlceId: IlceId,
        IlceText: "",
        MahalleId: "",
        MahalleText: "",
        SokakId: "",
        SokakText: "",
        AdresDetail: "",
        AdresDescription: "",
        Phone: phone,
        SecondPhone: "",
        IsSmsSend: true,
        IsEmailSend: true,
        TenantId: "",
        BrandId: "",
        BrandName: "",
        IsAddressUpdate: false,
        IsNewUser: true,
        AddressId: "00000000-0000-0000-0000-000000000000",
        DiscountRate: 0
      }).then(() => { })
  };

  async handleCallerIdPhone(data, i) {
    await this.props.setCallerIdData(data).then(() => { });

    const { callCenterAddressData, callCenterAddress } = this.props.additionReducer;

    if (callCenterAddressData.NewCustomer) {
      await this.props.getAddressList("Il", "").then(() => { })
      callCenterAddress.BrandId = cookie.load('BrandId');
      await this.handleReset(true, data.Phone);
      await this.newPackageAddition();
    } else {
      this.props.getAddressList("Il", "").then(() => { })
      await this.handleReset(false);
      callCenterAddress.BrandId = cookie.load('BrandId');
      this.props.formDataSet("callCenterAddress", callCenterAddress).then(() => { })
      this.handleLast();
      await this.newPackageAddition();
    }
    this.props.formDataSet("callerIdPhoneVisible", false).then(() => { })
    await this.props.callerIdCustomer.splice(i, 1);
    await this.props.setCallerIdCustomerRemove(this.props.callerIdCustomer).then(() => { })
    await this.props.formDataSetCustomer("customerCallerIdNew", true).then(() => { })
  }

  async customerNewAddress() {
    await this.props.getCustomerNewAddress().then(() => { })

    const { callCenterAddress } = this.props.additionReducer;
    await this.calcRegionReload(callCenterAddress);

    const { activeStep } = this.state;
    let { skipped } = this.state;
    const steps = getSteps();
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: (cookie.load('UserType') == "CallCenter" ? 1 : 0),
      skipped,
    });
  }

  closeCallerId(i) {
    this.props.callerIdCustomer.splice(i, 1);
    this.props.setCallerIdCustomerRemove(this.props.callerIdCustomer).then(() => { })
  }

  async notAddress(name, phone, i) {
    this.props.callerIdCustomer.splice(i, 1);
    this.props.setCallerIdCustomerRemove(this.props.callerIdCustomer).then(() => { })

    await this.props.getCustomerNotAddressList(this.props.settingsReducer.Settings.DefaultCustomerId).then(() => { })
    if (this.props.customerNotAddressList.length != 0) {
      if (this.props.customerNotAddressList.CustomerAddress == null) {
        this.props.setModal("Varsayılan müşteriye adres kaydı oluşturmanız gerekmektedir!").then(() => { })
        return;
      }
    } else {
      this.props.setModal("Varsayılan müşteri bilgisine ulaşılamadı!").then(() => { })
      return;
    }

    var adress = this.props.customerNotAddressList.CustomerAddress[0];

    await this.props.clearAddition().then(() => {
      this.props.formDataSet("newPackage", true).then(() => { })
      var addi = this.props.additionReducer.addition_data;
      addi.TableId = "00000000-0000-0000-0000-000000000000";
      addi.TableName = "Paket Satış";
      addi.CustomerId = adress.CustomerId;
      addi.OrderType = 1;
      addi.CustomerName = adress.CustomerName;
      addi.CustomerAddressId = adress.AddressId;
      addi.CustomerAddressText = adress.AddressText + (adress.AdresDescription.length == 0 ? "" : " Açıklama : " + adress.AdresDescription) + " " + adress.Phone + "-" + adress.SecondPhone;
      addi.CustomerPhone = adress.Phone;
      this.props.formDataSet("addition_data", addi).then(() => { })
      this.props.getStockGroup(false).then(() => { });
      this.props.formDataSetCustomer("customerCallerIdNew", false).then(() => { })
      this.props.setCategoryUpdate().then(() => { });
    })

    var text = "HIZLI SİPARİŞ ( " + name + " " + phone + " ) - ";

    this.props.formDataSet("IsInputDialogTitle", "Paket Açıklama").then(() => {
      this.props.formDataSet("KeyboardText", text).then(() => {
        this.keyboard.setInput(text);
      })
      this.props.formDataSet("IsInputDialogVisible", true).then(() => { })
    })

    this.props.history.push('/app/detailpage?' + Math.random());
  }

  render() {
    
    return (
      <div>
        {this.renderCallCenterNewCustomer()}
        {this.returnPhoneChange()}
        {
          this.props.callerIdCustomer.map((n, i) => {
            return (
              <div style={{ position: 'absolute', width: 350, height: 100, backgroundColor: '#16a085', bottom: (i == 0 ? 0 : (i * 105)), right: 5, borderRadius: 5, textAlign: 'center', zIndex: 999 }}>
                <div style={{ fontSize: 17, color: 'white', lineHeight: 1, marginTop: 7, fontWeight: 600 }}>{n.CustomerName}</div>
                <div style={{ fontSize: 30, color: 'white', lineHeight: 1, marginTop: 3, fontWeight: 700 }}>{n.Phone}</div>
                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                  <Button onClick={() => this.handleCallerIdPhone(n, i)} style={{ borderRadius: 0, backgroundColor: '#2980b9', color: 'white', textTransform: 'capitalize', paddingLeft: 15, paddingRight: 15, width: '70%', fontSize: 13, lineHeight: 1.1 }} variant="contained">{"Sipariş"}<br />{"Oluştur"}</Button>
                  <Button onClick={() => this.notAddress(n.CustomerName, n.Phone, i)} style={{ borderRadius: 0, backgroundColor: '#f39c12', color: 'white', textTransform: 'capitalize', paddingLeft: 15, paddingRight: 15, width: '30%', fontSize: 13, lineHeight: 1.1 }} variant="contained">{"Adressiz"}<br />{"İşlem"}</Button>
                </div>
                <Button onClick={() => this.closeCallerId(i)} style={{ borderRadius: 0, backgroundColor: '#c0392b', color: 'white', textTransform: 'capitalize', position: 'absolute', right: 5, top: 5, borderRadius: 5, minWidth: 40, width: 40 }} variant="contained"><Icon style={{ fontSize: 20 }}>{"close"}</Icon></Button>
              </div>
            )
          })
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    settingsReducer: state.get('settingsReducer').form_data,
    additionReducer: state.get('additionReducer').form_data,
    customerReducer: state.get('customerReducer').form_data,
    stockReducer: state.get('stockReducer').form_data,
    IsUpdateAddition: state.get('additionReducer').IsAdditionListUpdate,
    callerIdCustomer: state.get('additionReducer').callerIdCustomer,
    customerNotAddressList: state.get('customerReducer').customerNotAddressList,
    modalPhoneChangeVisible: state.get('additionReducer').modalPhoneChangeVisible,
    phoneChangeControlValue: state.get('additionReducer').phoneChangeControlValue
  };
};

const mapDispatchToProps = {
  formDataSet,
  getSettings,
  formDataSetCustomer,
  setCallerIdHubConnection,
  clearAddition,
  getAddressList,
  removeCustomerAddress,
  getCustomerAddress,
  getCustomerNewAddress,
  setModalAddition,
  setCustomerAddress,
  getStockGroup,
  getRepeatOrderList,
  setAdditionListUpdate,
  setCategoryUpdate,
  setCallerIdCustomerRemove,
  setCallerIdData,
  getCustomerNotAddressList,
  setModal,

  setPhoneChangeVisible,
  setPhoneChangeValue,
  setChangePhoneControl,
  getCallerIdPhoneControl
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CallerId)));
