import React from 'react';
import {
    createSelector
} from 'reselect';
import cookie from 'react-cookies';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import Popover from '@material-ui/core/Popover';
import { EmptyData } from 'dan-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePagination from '@material-ui/core/TablePagination';
import {
    setPaketYoldaGeneralReportFilterData, getPaketYoldaBrandList, getPaketYoldaCourierList,
    getPaketYoldaBusinessList, getNewCourierList, getOrderGeneralReport, getLatLngList
} from '../../../../redux/modules/paketyolda';
import {
    getPackageAdditionDetail
} from '../../../../redux/modules/addition';
import { getPaketYolda } from '../../../../function/GeneralFunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker, { registerLocale } from "react-datepicker";
import "../datepicker.css";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TooltipMui from '@material-ui/core/Tooltip';
import TbPaginationActions from '../../Ortak/TbPaginationActions';
import MapClusterReport from '../../SettingPage/Report/SubReportPage/Maps/MapClusterReport';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
});

class OrderMapReport extends React.Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaOrderMapReportUpdate == this.props.paketYoldaOrderMapReportUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        var PaketYoldaId = cookie.load('PaketYoldaId');
        this.props.getPaketYoldaBusinessList(PaketYoldaId).then(() => { });
        this.props.getNewCourierList().then(() => { });
    }

    handleChangeFilterReport = name => event => {
        const { paketYoldaOrderMapReportfilter } = this.props;
        paketYoldaOrderMapReportfilter[name] = ((name == "StartDatetime" || name == "EndDatetime") ? moment.utc(event, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm") : (name == "IsList" || name == "IsCompare" ? event.target.checked : event.target.value));
        this.props.setPaketYoldaGeneralReportFilterData(paketYoldaOrderMapReportfilter).then(() => { });
    };

    async handleFilterGeneral() {
        const { paketYoldaOrderMapReportfilter } = this.props;
        await this.props.getLatLngList(paketYoldaOrderMapReportfilter).then(() => { });

    }

    render() {
        const { generalReportOrderList, paketYoldaOrderMapReportData, paketYoldaCourierList, paketYoldaBusinessList, paketYoldaOrderMapReportfilter, paketYoldaNewReportLoading, paketYoldaReportMenu, classes } = this.props;

        if (paketYoldaReportMenu != "HeatMapReport") {
            return null;
        }

        if (paketYoldaNewReportLoading) {
            return <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
                <CircularProgress
                    style={{ color: '#fafafa' }}
                    thickness={4}
                    className={classes.progress}
                />
            </div>
        }

        return (
            <Paper className={classes.root} elevation={0} style={{ backgroundColor: 'transparent', borderRadius: 0, height: '100%' }}>
                <div style={{ height: 'calc(100% - 43px)', padding: 5, overflowY: 'auto' }}>
                    <Grid container spacing={1} style={{ borderBottom: '1px solid #424242', padding: 5, borderRadius: 0, marginBottom: 5 }}>
                        <Grid item md={2}>
                            <Typography style={{ marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Başlangıç Tarihi"}</Typography>
                            <DatePicker
                                selected={new Date(paketYoldaOrderMapReportfilter.StartDatetime)}
                                onChange={this.handleChangeFilterReport("StartDatetime")}
                                dateFormat="dd.MM.yyyy HH:mm"
                                showTimeSelect
                                timeIntervals={15}
                                timeFormat="HH:mm"
                                locale="tr"
                                className={"inputDate"}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <Typography style={{ marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Bitiş Tarihi"}</Typography>
                            <DatePicker
                                selected={new Date(paketYoldaOrderMapReportfilter.EndDatetime)}
                                onChange={this.handleChangeFilterReport("EndDatetime")}
                                dateFormat="dd.MM.yyyy HH:mm"
                                showTimeSelect
                                timeIntervals={15}
                                timeFormat="HH:mm"
                                locale="tr"
                                className={"inputDate"}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <FormControl fullWidth className={classes.formControl} style={{ margin: 0 }}>
                                <Typography style={{ marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Restoran Seçimi"}</Typography>
                                <Select
                                    fullWidth
                                    style={{ width: '100%', borderRadius: 5, height: 38 }}
                                    value={paketYoldaOrderMapReportfilter.JaviPosBrandId}
                                    onChange={this.handleChangeFilterReport("JaviPosBrandId")}
                                    inputProps={{
                                        name: 'age',
                                        id: 'age-simple',
                                    }}
                                >
                                    <MenuItem value={"00000000-0000-0000-0000-000000000000"}>{"Boş"}</MenuItem>
                                    {
                                        paketYoldaBusinessList.map((n, i) => {
                                            return <MenuItem value={n.BrandId}>{n.BusinessName}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={3}>
                            <FormControl fullWidth className={classes.formControl} style={{ margin: 0 }}>
                                <Typography style={{ marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Kurye Seçimi"}</Typography>
                                <Select
                                    fullWidth
                                    style={{ width: '100%', borderRadius: 5, height: 38 }}
                                    value={paketYoldaOrderMapReportfilter.CourierId}
                                    onChange={this.handleChangeFilterReport("CourierId")}
                                    inputProps={{
                                        name: 'age',
                                        id: 'age-simple',
                                    }}
                                >
                                    <MenuItem value={"00000000-0000-0000-0000-000000000000"}>{"Boş"}</MenuItem>
                                    {
                                        paketYoldaCourierList.map((n, i) => {
                                            return <MenuItem value={n.Id}>{n.NameSurname}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <Button fullWidth disabled={paketYoldaNewReportLoading} onClick={() => this.handleFilterGeneral()} style={{ borderRadius: 5, height: 37, padding: '6px 24px', marginTop: 17 }} size="large" variant="contained" color="secondary" type="submit">
                                {"FİLTRELE"}
                            </Button>
                        </Grid>
                    </Grid>
                    <div style={{ backgroundColor: '#313131', marginTop: 10, marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {"Sipariş Bölge Dağılım Haritası"}
                    </div>
                    <MapClusterReport center={[37.7916823,29.0979707]} markersData={paketYoldaOrderMapReportData} />
                </div>
            </Paper >
        )
    }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaOrderMapReportUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    paketYoldaOrderMapReportUpdate: value(state, props),
    paketYoldaOrderMapReportData: state.get('paketYoldaReducer').paketYoldaOrderMapReportData,
    paketYoldaReportMenu: state.get('paketYoldaReducer').paketYoldaReportMenu,
    paketYoldaNewReportLoading: state.get('paketYoldaReducer').paketYoldaNewReportLoading,
    paketYoldaOrderMapReportfilter: state.get('paketYoldaReducer').paketYoldaOrderMapReportfilter,

    paketYoldaBusinessList: state.get('paketYoldaReducer').paketYoldaBusinessList,
    paketYoldaCourierList: state.get('paketYoldaReducer').paketYoldaCourierList
})

const mapDispatchToProps = {
    setPaketYoldaGeneralReportFilterData,
    getPaketYoldaBrandList,
    getPaketYoldaCourierList,
    getPaketYoldaBusinessList,
    getNewCourierList,
    getOrderGeneralReport,
    getPackageAdditionDetail,
    getLatLngList
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(OrderMapReport)));
