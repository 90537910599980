import React from 'react';
import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerIconRedPng from 'dan-images/marker-icon-red.png';
import markerIconGreenPng from 'dan-images/marker-icon-green.png';
import markerIconYellowPng from 'dan-images/marker-icon-yellow.png';
import markerIconVioletPng from 'dan-images/marker-icon-violet.png';

const style = {
  width: '100%',
  height: '90%'
};

const myIconBlue = L.icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
  shadowSize: [41, 41], // size of the shadow
  shadowAnchor: [13, 41], // the same for the shadow
  iconSize: [25, 41],
  iconAnchor: [12.5, 41]
});

const myIconGreen = L.icon({
  iconUrl: markerIconGreenPng,
  shadowUrl: markerShadowPng,
  shadowSize: [41, 41], // size of the shadow
  shadowAnchor: [13, 41], // the same for the shadow
  iconSize: [25, 41],
  iconAnchor: [12.5, 41]
});

const myIconYellow = L.icon({
  iconUrl: markerIconYellowPng,
  shadowUrl: markerShadowPng,
  shadowSize: [41, 41], // size of the shadow
  shadowAnchor: [13, 41], // the same for the shadow
  iconSize: [25, 41],
  iconAnchor: [12.5, 41]
});

const myIconRed = L.icon({
  iconUrl: markerIconRedPng,
  shadowUrl: markerShadowPng,
  shadowSize: [41, 41], // size of the shadow
  shadowAnchor: [13, 41], // the same for the shadow
  iconSize: [25, 41],
  iconAnchor: [12.5, 41]
});

const myIconViolet = L.icon({
  iconUrl: markerIconVioletPng,
  shadowUrl: markerShadowPng,
  shadowSize: [41, 41], // size of the shadow
  shadowAnchor: [13, 41], // the same for the shadow
  iconSize: [25, 41],
  iconAnchor: [12.5, 41]
});

class MapClusterReport extends React.Component {
  componentDidMount() {
    // create map
    this.map = L.map('map', {
      center: [38.9640252,34.5071903],
      zoom: 7,
      layers: [
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution:
                        '&copy; <a href="https://www.javipos.com">JaviPos Bulut Adisyon</a> Sistemleri'
        })
      ]
    });

    // add layer
    this.layer = L.markerClusterGroup().addTo(this.map);
    this.updateMarkers(this.props.markersData);
  }

  componentDidUpdate({ markersData, center }) {
    if (this.props.markersData !== markersData) {
      this.updateMarkers(this.props.markersData);
    }
    if (this.props.center !== center) {
      this.updateMap(this.props.center);
    }
  }

  updateMap(center) {
    this.map.setView(center, 14);
  }

  updateMarkers(markersData) {
    this.layer.clearLayers();
    markersData.forEach(marker => {
      L.marker([marker.Lat, marker.Lng], { icon: (marker.TableType == 'YemekSepeti' ? myIconRed : (marker.TableType == 'GetirYemek' ? myIconViolet : myIconBlue)) }, { title: marker.TableType }).addTo(this.layer);
    });

    // L.marker(brand, { icon: myIconGreen }, { title: "Merkez" }).addTo(this.layer).bindTooltip("Merkez").openTooltip();
  }

  render() {
    return <div id="map" style={style} />;
  }
}

export default MapClusterReport;
