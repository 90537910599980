import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import "../datepicker.css";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { compose } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    {...props}
    defaultZoom={8}
    defaultCenter={{ lat: 37.7435685, lng: 29.0965078 }}
  >
    <Marker
      position={{ lat: 37.7435685, lng: 29.0965078 }}
    />
  </GoogleMap>
));

class MapReport extends React.Component {
  constructor(){
    super();
  }

  renderGeneralType(){
    const { handleChangeFilter, handleFilterReport, classes, data } = this.props;
    return (
      <MapWithAMarker
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDpKGv_AAabzCd7Sp3xThIu3_LvX4-Z18A&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '400px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
      />
    )
  }

  render(){
    const { StartDate, EndDate, handleChangeFilter, handleFilterMapReport, classes, data } = this.props;
    return(
      <Paper className={classes.root} elevation={0} style={{ borderRadius:0, marginLeft:10, marginRight:10 , paddingTop:10, paddingBottom:10 }}>
            <Grid container spacing={1}>
              <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
              <Typography style={{ marginBottom:3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Başlangıç Tarihi"}</Typography>
              <DatePicker
                selected={StartDate}
                onChange={handleChangeFilter("StartDate")}
                timeInputLabel="Saat:"
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeInput
                className={"inputDate"}
              />
              </Grid>
              <Grid item md={(window.innerWidth <= 1024 ? 3 : 2)}>
              <Typography style={{ marginBottom:3, color: 'rgba(255, 255, 255, 0.7)', padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Bitiş Tarihi"}</Typography>
              <DatePicker
                selected={EndDate}
                onChange={handleChangeFilter("EndDate")}
                timeInputLabel="Saat:"
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeInput
                className={"inputDate"}
              />
              </Grid>
              <Grid item md={3}>
              <Button onClick={handleFilterMapReport} style={{ borderRadius: 5, marginTop:15, height:37, padding:'6px 24px' }} size="large" variant="contained" color="secondary" type="submit">
                  Filtrele
              </Button>
              </Grid>
            </Grid>
            <Divider style={{ marginTop:5, marginBottom:5 }} />
            { this.renderGeneralType() }
          </Paper>
    )
  }
}

export default MapReport;
