import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import AdminPanel from './AdminPanel';
import AdminSubscriberPage from './Pages/AdminSubscriberPage';
import Hidden from '@material-ui/core/Hidden';
import SendPaymentLinkModal from './Modals/SendPaymentLinkModal';
import ModalInformation from '../Ortak/ModalInformation';
import SubcriberDetailModal from './Modals/SubcriberDetailModal';
import LicenceModuleUpdate from './Modals/LicenceModuleUpdate';
import SubscriberPlanAmountUpdateModal from './Modals/SubscriberPlanAmountUpdateModal';
import SubscriberExtensionUpdateModal from './Modals/SubscriberExtensionUpdateModal';
import SubscriberInvoceDataModal from './Modals/SubscriberInvoceDataModal';
import CenterList from '../PaketYolda/CenterAndBrand/CenterList';
import DealerList from './Pages/DealerList';
import { getPaynetSubscriber, alertModalChange, setDealerListVisible } from '../../../redux/modules/admin';
import { setPaketYoldaCenterListVisible } from '../../../redux/modules/paketyolda';

import { reactLocalStorage } from 'reactjs-localstorage';
import cookie from 'react-cookies';

class AdminPanelPage extends React.Component {
  state = {
    fixed: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
    this.props.getPaynetSubscriber();
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  modalClose = () => {
    this.props.alertModalChange(false, '', '');
  };

  handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const newFlagFixedMenu = scroll > 61;
    if (this.flagFixedMenu !== newFlagFixedMenu) {
      this.setState({ fixed: newFlagFixedMenu });
    }
  };


  handleCloseApp = () => {
    cookie.remove('UserName', { path: '/' });
    cookie.save('AdminLogin', "false", { path: '/' });
    cookie.remove('RestaurantName', { path: '/' });
    cookie.remove('UserId', { path: '/' });
    cookie.remove('BrandId', { path: '/' });
    cookie.remove('TenantId', { path: '/' });
    cookie.remove('CenterBrandId', { path: '/' });
    cookie.remove('UserType', { path: '/' });
    cookie.remove('Settings', { path: '/' });
    cookie.remove('LicenceNumber', { path: '/' });
    cookie.remove('NameSurname', { path: '/' });
    cookie.remove('Authorization', { path: '/' });
    reactLocalStorage.remove('l_string');

    if (cookie.load("IsRemember") === "false") {
      cookie.remove('Email', { path: '/' });
      cookie.remove('Password', { path: '/' });
    }
    this.props.history.push('/');
  };

  handlePaketYoldaOpen = () => {
    this.props.setPaketYoldaCenterListVisible(true).then(() => { });
  };

  render() {
    const {
      classes,
      children,
      toggleDrawer,
      sidebarOpen,
      loadTransition,
      pageLoaded,
      mode,
      history,
      gradient,
      deco,
      bgPosition,
      layout,
      changeMode,
    } = this.props;
    const { fixed } = this.state;
    return (
      <AdminPanel
        history={history}
        toggleDrawer={toggleDrawer}
        loadTransition={loadTransition}
        changeMode={changeMode}
        sidebarOpen={sidebarOpen}
        pageLoaded={true}
        mode={mode}
        gradient={false}
        deco={deco}
        bgPosition={bgPosition}>
        <Fragment>
          <nav
            style={{
              position: fixed ? 'fixed' : 'relative',
              width: fixed ? '100%' : 'auto',
              top: fixed ? '0' : 'auto',
              backgroundColor: '#424242',
              padding: fixed ? 15 : 10,
              display: 'flex',
              justifyContent: 'center',
              zIndex: 1,
            }}>
            <div style={{ width: '100%' }}>
              <Button
                onClick={this.handleCloseApp}
                variant={'outlined'}
                style={{ marginLeft: 10, float: 'right', borderRadius: 5, backgroundColor: '#e74c3c' }}>
                <Icon>{'logout'}</Icon>
                <Typography
                  style={{
                    fontSize: 12,
                    marginLeft: 10,
                    marginRight: 10,
                    textTransform: 'capitalize',
                  }}
                  className={classes.buttonMenuTitle}
                  variant="subtitle1">
                  {'Çıkış Yap'}
                </Typography>
              </Button>
              <Button
                onClick={this.handlePaketYoldaOpen}
                variant={'outlined'}
                style={{ marginLeft: 10, float: 'left', borderRadius: 5 }}>
                <Icon>{'local_shipping'}</Icon>
                <Typography
                  style={{
                    fontSize: 12,
                    marginLeft: 10,
                    marginRight: 10,
                    textTransform: 'capitalize',
                  }}
                  className={classes.buttonMenuTitle}
                  variant="subtitle1">
                  {'JaviKurye Merkez Listesi'}
                </Typography>
              </Button>
              <Button
                onClick={() => this.props.setDealerListVisible(true).then(() => { })}
                variant={'outlined'}
                style={{ marginLeft: 10, float: 'left', borderRadius: 5 }}>
                <Icon>{'supervisor_account'}</Icon>
                <Typography
                  style={{
                    fontSize: 12,
                    marginLeft: 10,
                    marginRight: 10,
                    textTransform: 'capitalize',
                  }}
                  className={classes.buttonMenuTitle}
                  variant="subtitle1">
                  {'Bayi Listesi'}
                </Typography>
              </Button>
            </div>
          </nav>
        </Fragment>
        {this.props.paynetSubscriberListLoading == true ? (
          <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
            <CircularProgress
              style={{ color: '#fafafa' }}
              thickness={4}
              className={classes.progress}
            />
          </div>
        ) : (
          <Grid container style={{ marginTop: 10 }}>
            <Grid item md={12}>
              <Paper style={{ borderRadius: 5, margin: 5 }}>
                <AdminSubscriberPage classes={classes} />
              </Paper>
            </Grid>
          </Grid>
        )}
        <ModalInformation
          event={this.modalClose}
          open={this.props.alertModalVisible}
          title={this.props.alertModalTitle}
          text={this.props.alertModalText}
        />
        <SendPaymentLinkModal />
        <SubcriberDetailModal />
        <LicenceModuleUpdate />
        <SubscriberPlanAmountUpdateModal />
        <SubscriberExtensionUpdateModal />
        <SubscriberInvoceDataModal />
        <CenterList />
        <DealerList classes={classes} />
      </AdminPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getSubscriberListStatus: state.get('adminReducer').getSubscriberListStatus,
    paynetSubscriberListLoading: state.get('adminReducer').paynetSubscriberListLoading,
    alertModalVisible: state.get('adminReducer').alertModalVisible,
    alertModalTitle: state.get('adminReducer').alertModalTitle,
    alertModalText: state.get('adminReducer').alertModalText,
  };
};

const mapDispatchToProps = {
  getPaynetSubscriber,
  alertModalChange,
  setPaketYoldaCenterListVisible,
  setDealerListVisible
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(AdminPanelPage)));
