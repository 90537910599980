import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../../CenterBrandPage/centerbrandpage-jss';
import {
    setPaketYoldaUserListVisible, setPaketYoldaUserAddVisible, getUser
} from '../../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import UserAdd from './UserAdd';

class UserList extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaNewUserListUpdate == this.props.paketYoldaNewUserListUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() { }

    render() {
        const { classes, paketYoldaNewUserListVisible, paketYoldaNewUserList } = this.props;

        return (
            <Dialog
                open={paketYoldaNewUserListVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'lg'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <UserAdd classes={classes} />
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Kullanıcı İşlemleri"}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{'Aktif'}</TableCell>
                                <TableCell>{'Kullanıcı Adı'}</TableCell>
                                <TableCell>{'Şifre'}</TableCell>
                                <TableCell>{'Pin'}</TableCell>
                                <TableCell align={"right"}>{'İşlemler'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paketYoldaNewUserList.map((n, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {<Icon>{n.IsActive ? 'check_box' : 'check_box_outline_blank'}</Icon>}
                                        </TableCell>
                                        <TableCell>{n.Username}</TableCell>
                                        <TableCell>{n.Passsword}</TableCell>
                                        <TableCell>{n.PIN}</TableCell>
                                        <TableCell align={"right"}>
                                            <Tooltip title={"Düzenle"}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                    onClick={() => this.props.getUser(n.Id).then(() => { })}>
                                                    <Icon>{"edit"}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => this.props.setPaketYoldaUserAddVisible(true).then(() => { })} color="primary">
                        {"Kullanıcı Ekle"}
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPaketYoldaUserListVisible(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

UserList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaNewUserListUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaNewUserListUpdate: value(state, props),
    paketYoldaNewUserListVisible: state.get('paketYoldaReducer').paketYoldaNewUserListVisible,
    paketYoldaNewUserList: state.get('paketYoldaReducer').paketYoldaNewUserList
});

const mapDispatchToProps = {
    setPaketYoldaUserListVisible,
    setPaketYoldaUserAddVisible,
    getUser
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(UserList)));
