import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TrendingUp from '@material-ui/icons/TrendingUp';
import TrendingDown from '@material-ui/icons/TrendingDown';
import TrendingFlat from '@material-ui/icons/TrendingFlat';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-between',
    height: 120,
    overflow: 'hidden',
    marginBottom: 6,
    display: 'flex',
    flexDirection: 'column',
    background: "#4b4b4b",
    [theme.breakpoints.up('sm')]: {
      marginBottom: -1,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& > *': {
      padding: '0 5px'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    flex: 1,
    '& span': {
      fontWeight: 'bold',
      fontSize: 12,
      '& svg': {
        width: 16
      }
    }
  },
  extend: {
    color: theme.palette.common.white,
    fontSize: 12,
    position: 'relative',
    zIndex: 1,
    marginBottom: 4
  },
  counter: {
    color: theme.palette.text.secondary,
    fontSize: 26,
    fontWeight: 500
  },
  content: {
    textAlign: 'right',
    position: 'relative',
    height: 40
  },
  up: {
    color: green[500],
    '& svg': {
      fill: green[500],
    }
  },
  down: {
    color: red[500],
    '& svg': {
      fill: red[500],
    }
  },
  flat: {
    color: theme.palette.divider,
    '& svg': {
      fill: theme.palette.divider,
    }
  },
  avatar: {
    color: 'white',
    fontSize: 30,
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  decoration: {
    borderRadius: '50%',
    width: '200%',
    position: 'absolute',
    height: 260,
    left: '-50%',
    top: -20
  }
});

class CounterTrading extends PureComponent {
  render() {
    const {
      classes,
      color,
      start,
      end,
      duration,
      decimals,
      icon,
      title,
      children,
      unitBefore,
      unitAfter,
      position,
      orderCount,
      unitCountAfter
    } = this.props;

    return (
      <Paper className={classes.root}>
        <header className={classes.header}>
          <div style={{ backgroundColor: color, borderRadius: 50, padding: 5, width: 50, height: 50, marginRight: 10 }}>
            <Icon
              alt=""
              src={icon}
              className={classes.avatar}
            >{icon}</Icon>
          </div>
          <div className={classes.title}>
            <Typography className={classes.title} noWrap variant="subtitle1">{title}</Typography>
          </div>
          {
            (unitCountAfter != "" ?
              <div className={classes.extend}>
                <Typography className={classes.counter} style={{ fontSize: 15 }}>
                  <CountUp decimal="," decimals={0} start={start} end={orderCount} duration={duration} useEasing />
                  {" "}{unitCountAfter}
                </Typography>
              </div>
              : null
            )
          }

        </header>
        <div className={classes.content} style={{ backgroundColor: color }}>
          <span className={classes.decoration} style={{ backgroundColor: color }} />
          <div className={classes.extend}>
            <Typography className={classes.counter}>
              {unitBefore}
              <CountUp decimal="," decimals={decimals} start={start} end={end} duration={duration} useEasing />
              {" "}{unitAfter}
            </Typography>
          </div>
          {children}
        </div>
      </Paper>
    );
  }
}

CounterTrading.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  decimals: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  unitBefore: PropTypes.string,
  unitAfter: PropTypes.string,
  position: PropTypes.string.isRequired,
  orderCount: PropTypes.string.isRequired,
  unitCountAfter: PropTypes.string.isRequired,
};

CounterTrading.defaultProps = {
  unitBefore: '',
  unitAfter: '',
  orderCount: '',
  unitCountAfter: '',
  decimals:2
};

export default withStyles(styles)(CounterTrading);
