import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../../CenterBrandPage/centerbrandpage-jss';
import {
    setPaketYoldaSettingsVisible, setPaketYoldaSettingsData
} from '../../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import GeneralSettings from './GeneralSettings';
import AssignmentSettings from './AssignmentSettings';
import RegionSettingsList from './RegionSettingsList';
import BusinessSettingsList from './BusinessSettingsList';

const fontSizeTextItem = {
    fontSize: 14
};

const fontSizeTextItemSelected = {
    fontSize: 15,
    fontWeight: 600,
    color: '#16a085'
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Settings extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaSettingsUpdate == this.props.paketYoldaSettingsUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() { }

    render() {
        const { classes, paketYoldaSettingsVisible, paketYoldaSettingsMenu } = this.props;

        return (
            <Dialog
                open={paketYoldaSettingsVisible}
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%', backgroundImage: 'linear-gradient(-45deg, rgb(239, 83, 80) 0%, rgb(239, 83, 80) 33%, rgb(96, 125, 139) 100%)' }}>
                    <Toolbar>
                        <Button variant="outlined" color="inherit" onClick={() => this.props.setPaketYoldaSettingsVisible(false).then(() => { })}>
                            <Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon>
                        </Button>
                        <Typography style={{ textAlign: 'center', width: '100%', fontWeight: 600 }} variant="h7" color="inherit" className={classes.flex}>{"Ayarlar"}</Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64 }}>
                    <Grid container style={{ height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
                        <Grid item md={3} style={{ height: '100%', padding: 4, overflowY: 'auto' }}>
                            <div style={{ height: '100%', backgroundColor: '#424242' }}>
                                <List
                                    component="nav"
                                    subheader={<ListSubheader style={{ position: 'inherit' }} component="div">{"Ayarlar ve Tanımlamalar"}</ListSubheader>}
                                >
                                    <Divider />
                                    <List component="div" disablePadding>
                                        <ListItem onClick={() => this.props.setPaketYoldaSettingsData("GeneralSetttings").then(() => { })} button className={classes.nested}>
                                            <ListItemIcon style={{ minWidth: 35 }}>
                                                <Icon>{"manage_accounts"}</Icon>
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ style: (paketYoldaSettingsMenu == "GeneralSetttings" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Genel Ayarlar" />
                                            {(
                                                paketYoldaSettingsMenu == "GeneralSetttings" ?
                                                    <ListItemIcon style={{ minWidth: 35 }}>
                                                        <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                    </ListItemIcon> : null
                                            )}
                                        </ListItem>
                                    </List>
                                    <Divider />
                                    <List component="div" disablePadding>
                                        <ListItem onClick={() => this.props.setPaketYoldaSettingsData("AssignmentSettings").then(() => { })} button className={classes.nested}>
                                            <ListItemIcon style={{ minWidth: 35 }}>
                                                <Icon>{"local_shipping"}</Icon>
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ style: (paketYoldaSettingsMenu == "AssignmentSettings" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Atama Ayarları" />
                                            {(
                                                paketYoldaSettingsMenu == "AssignmentSettings" ?
                                                    <ListItemIcon style={{ minWidth: 35 }}>
                                                        <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                    </ListItemIcon> : null
                                            )}
                                        </ListItem>
                                    </List>
                                    <Divider />
                                    <List component="div" disablePadding>
                                        <ListItem onClick={() => this.props.setPaketYoldaSettingsData("RegionSettings").then(() => { })} button className={classes.nested}>
                                            <ListItemIcon style={{ minWidth: 35 }}>
                                                <Icon>{"share_location"}</Icon>
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ style: (paketYoldaSettingsMenu == "RegionSettings" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="Bölge Ayarları" />
                                            {(
                                                paketYoldaSettingsMenu == "RegionSettings" ?
                                                    <ListItemIcon style={{ minWidth: 35 }}>
                                                        <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                    </ListItemIcon> : null
                                            )}
                                        </ListItem>
                                    </List>
                                    <Divider />
                                    <List component="div" disablePadding>
                                        <ListItem onClick={() => this.props.setPaketYoldaSettingsData("BusinessSettings").then(() => { })} button className={classes.nested}>
                                            <ListItemIcon style={{ minWidth: 35 }}>
                                                <Icon>{"storefront"}</Icon>
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ style: (paketYoldaSettingsMenu == "BusinessSettings" ? fontSizeTextItemSelected : fontSizeTextItem) }} variant="inset" primary="İşletme Tanımları" />
                                            {(
                                                paketYoldaSettingsMenu == "BusinessSettings" ?
                                                    <ListItemIcon style={{ minWidth: 35 }}>
                                                        <Icon style={{ color: '#16a085' }}>{"done"}</Icon>
                                                    </ListItemIcon> : null
                                            )}
                                        </ListItem>
                                    </List>
                                </List>
                            </div>
                        </Grid>
                        <Grid item md={9} style={{ height: '100%', padding: 4 }}>
                            <div style={{ height: '100%', backgroundColor: '#424242', overflow: 'hidden' }}>
                                <GeneralSettings classes={classes} />
                                <AssignmentSettings classes={classes} />
                                <RegionSettingsList classes={classes} />
                                <BusinessSettingsList classes={classes} />
                                
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaSettingsUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaSettingsUpdate: value(state, props),
    paketYoldaSettingsVisible: state.get('paketYoldaReducer').paketYoldaSettingsVisible,
    paketYoldaSettingsMenu: state.get('paketYoldaReducer').paketYoldaSettingsMenu,
    paketYoldaGeneralSettingsUpdate: state.get('paketYoldaReducer').paketYoldaGeneralSettingsUpdate,
});

const mapDispatchToProps = {
    setPaketYoldaSettingsVisible,
    setPaketYoldaSettingsData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(Settings)));
