import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import brand from 'dan-api/dummy/brand';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from './user-jss';

import Slide from '@material-ui/core/Slide';
import { HeaderMenuAdmin } from 'dan-components';
import Decoration from '../../Templates/Decoration';
import adminMenu from 'dan-api/ui/admin';
import Fade from '@material-ui/core/Fade';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export class AdminPanel extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    render() {
        const {
            classes,
            children,
            toggleDrawer,
            sidebarOpen,
            loadTransition,
            pageLoaded,
            mode,
            history,
            gradient,
            deco,
            bgPosition,
            layout,
            changeMode
        } = this.props;
        const title = brand.name + ' - Admin Kontrol Paneli';
        const description = brand.desc;

        return (
            <Fragment>
                <HeaderMenuAdmin
                    type="mega-menu"
                    dataMenu={adminMenu}
                    mode={'dark'}
                    title={"JaviPos Admin Paneli"}
                    logoLink="/adminpanel"
                />
                <main
                    className={
                        classNames(
                            classes.content,
                            classes.highMargin
                        )
                    }
                    id="mainContent"
                >
                    <Decoration
                        mode={'dark'}
                        gradient={true}
                        decoration={true}
                        bgPosition={'half'}
                        horizontalMenu
                    />
                    <section className={classNames(classes.mainWrap, classes.topbarLayout)}>
                        {!pageLoaded && (<img src="/images/spinner.gif" alt="spinner" className={classes.circularProgress} />)}
                        <Fade
                            in={pageLoaded}
                            mountOnEnter
                            unmountOnExit
                            {...(pageLoaded ? { timeout: 200 } : {})}
                        >
                            <div className={!pageLoaded ? classes.hideApp : ''}>
                                {/* Application content will load here */}
                                {children}
                            </div>
                        </Fade>
                    </section>
                </main>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminPanel));
