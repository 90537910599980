import React from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import TablePagination from '@material-ui/core/TablePagination';
import TbPaginationActions from '../../Ortak/TbPaginationActions';
import { EmptyData } from 'dan-components';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import {
    setFranchiseOrderListVisible, setFranchiseOrderAddVisible, setFranchiseOrderListAddData,
    setFranchiseOrderStock
} from '../../../../redux/modules/centerbrand';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import messageStyles from 'dan-styles/Messages.scss';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DatePicker, { registerLocale } from "react-datepicker";
import "../datepicker.css";
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { getParameter } from '../../../../function/GeneralFunction';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import tr from "date-fns/locale/tr";
import FranchiseOrderAdd from './FranchiseOrderAdd';
registerLocale("tr", tr);

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class FranchiseOrderList extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.franchiseOrderListUpdate == this.props.franchiseOrderListUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    async clickOrderAddUpdate(n) {
        await this.props.setFranchiseOrderListAddData(n).then(() => { })
        this.props.setFranchiseOrderAddVisible(true).then(() => { })
    }


    render() {
        const { classes, franchiseOrderListVisible, franchiseOrderListData } = this.props;
        return (
            <Dialog
                fullScreen
                open={franchiseOrderListVisible}
                TransitionComponent={Transition}
            >
                <FranchiseOrderAdd classes={classes} />
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%' }}>
                    <Toolbar>
                        <Button variant='contained' onClick={() => this.props.setFranchiseOrderListVisible(false).then(() => { })}><Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon></Button>
                        <Typography variant="h6" color="inherit" className={classes.flex}>{"Franchise Sipariş Listesi"}</Typography>
                        <Button style={{ display: (window.location.pathname == "/centerbrand" ? 'none' : '') }} variant='contained' color="primary" onClick={() => this.props.setFranchiseOrderAddVisible(true).then(() => { })}>{"SİPARİŞ OLUŞTUR"}</Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64, padding: 20 }}>
                    <Table className={classNames(classes.table, classes.stripped)}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '30%' }} align="left">{"Bayi"}</TableCell>
                                <TableCell style={{ width: '20%' }} align="left">{"Sipariş Kodu"}</TableCell>
                                <TableCell style={{ width: '15%' }} align="left">{"Tarihi"}</TableCell>
                                <TableCell style={{ width: '15%' }} align="left">{"Durumu"}</TableCell>
                                <TableCell style={{ width: '20%' }} align="right">{"İşlem"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                franchiseOrderListData.map(n => ([
                                    <TableRow hover key={n.Id}>
                                        <TableCell align="left">{n.BrandName}</TableCell>
                                        <TableCell align="left">{n.OrderNo}</TableCell>
                                        <TableCell align="left">{moment(n.CreationTime).format('DD.MM.YYYY HH:mm')}</TableCell>
                                        <TableCell align="left">{(n.FranchiseOrderType == 0 ? "Yeni Sipariş" : (n.FranchiseOrderType == 1 ? "Tamamlandı" : (n.FranchiseOrderType == 2 ? "Şube Tarafından Teslim Alındı" : "İptal Edildi")))}</TableCell>
                                        <TableCell align="right">
                                            {(
                                                n.FranchiseOrderType == 2 ?
                                                    <span style={{ marginRight: 10 }}>{"İşlemleri Tamamlandı"}</span>
                                                    : null
                                            )}
                                            <Tooltip title={(n.FranchiseOrderType == 0 ? "Düzenle" : "Görüntüle")}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                    onClick={() => this.clickOrderAddUpdate(n.OrderList)}>
                                                    <Icon>{(n.FranchiseOrderType == 0 ? "edit_note" : "notes")}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                (n.FranchiseOrderType == 0 && window.location.pathname == "/centerbrand" ?
                                                    <Tooltip title={"Siparişi Onayla"}>
                                                        <IconButton
                                                            style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                            onClick={() => (confirm('Siparişi tamamlamak istediğinize emin misiniz? Bu işlemden sonra sipariş üzerinden değşiklik yapamazsınız!') ? this.props.setFranchiseOrderStock(n.OrderNo).then(() => { }) : null)}>
                                                            <Icon>{"fact_check"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    : "")
                                            }
                                            {(
                                                n.FranchiseOrderType == 1 && window.location.pathname != "/centerbrand" ?
                                                    <Tooltip title={"Siparişi Kabul Et"}>
                                                        <IconButton
                                                            style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                            onClick={() => (confirm('Siparişi kabul etmek istediğinize emin misiniz?') ? this.props.setFranchiseOrderStock(n.OrderNo).then(() => { }) : null)}>
                                                            <Icon>{"fact_check"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null
                                            )}
                                            {(
                                                window.location.pathname == "/centerbrand" ?
                                                    <Tooltip title={"Siparişi İptal Et"}>
                                                        <IconButton
                                                            style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                            onClick={() => (confirm('Siparişi iptal etmek istediğinize emin misiniz?') ? this.props.setFranchiseOrderStock(n.OrderNo, "3").then(() => { }) : null)}>
                                                            <Icon>{"delete"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null
                                            )}

                                        </TableCell>
                                    </TableRow>
                                ]))
                            }
                        </TableBody>
                    </Table>
                </div>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').franchiseOrderListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    franchiseOrderListUpdate: value(state, props),
    franchiseOrderListData: state.get('centerBrandReducer').franchiseOrderListData,
    franchiseOrderListVisible: state.get('centerBrandReducer').franchiseOrderListVisible
})

const mapDispatchToProps = {
    setFranchiseOrderListVisible,
    setFranchiseOrderAddVisible,
    setFranchiseOrderListAddData,
    setFranchiseOrderStock
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FranchiseOrderList));