import colorfull from 'dan-api/palette/colorfull';
import { darken, alpha, lighten } from '@material-ui/core/styles/colorManipulator';
import { gradientBgLight, gradientBgDark } from 'containers/Templates/appStyles-jss';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import images from 'dan-api/images/photos';

const styles = theme => ({
    containerScrollProduct:{
        height:'100vh',
        overflow:'auto !important',
        width:'100%',
        paddingLeft:5,
        paddingRight:5
      },
    dialogPaperPaketYoldaReport: {
        maxHeight: 'calc(100% - 20px)',
        maxWidth: '100%'
    },
    dialogPaperPaketYoldaCourierAdd: {
        maxHeight: 'calc(100% - 50px)',
        maxWidth: '70%'
    },
    pigeonFilters: {
        display: 'flex'
    },
    dialogPaperRating: {
        maxHeight: 'calc(100% - 10px)',
        margin: 10,
        backgroundColor: 'white'
    },
    dialogPaperTrack: {
        margin: 5,
        maxHeight: 'calc(100% - 20px)',
        maxWidth: 'calc(100% - 20px)'
    },
    stepperLabel: {
        color: '#424242 !important',
        fontSize: '13px !important',
        marginTop: '5px !important'
    },
    stepperLabelActive: {
        fontWeight: 'bold !important',
        fontSize: '16px !important',
        color: '#364699 !important',
        marginTop: '5px !important'
    },
    stepperIcon: {
        color: '#364699 !important',
        '& svg': {
            fontSize: '30px !important'
        }
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '1em',
            zIndex: 999
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.3)',
            outline: '1px solid slategrey'
        }
    },
});

export default styles;