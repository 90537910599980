export const TEST_START = 'test_start';
export const TEST_STOP  = 'test_stop';
export const TEST_ERROR = 'test_error';


let initialState = {
  data: [],
  loading: false,
  error: ''
}

export async  function testGetData(lang){
  return async (dispatch,getState) => {
    dispatch({type : TEST_START});
    fetch('https://facebook.github.io/react-native/movies.json')
                   .then((response) => response.json())
                   .then((responseJson) => {
                     dispatch({type:TEST_STOP, payload :responseJson.movies});
                   })
                   .catch((error) => {
                       dispatch({type: TEST_ERROR, payload : error})
                   });
    }
}


const testReducer = (state = initialState, action) => {
	switch (action.type) {
    case TEST_START:
      return {...state, loading : true}
    case TEST_ERROR:
      return {...state, loading : false, error : action.payload}
    case TEST_STOP:
      return {...state, loading : false, data : action.payload}
    default:
		return state;
  }
}

export default testReducer;
