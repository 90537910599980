import React from 'react';
import { connect } from 'react-redux';
import {
    createSelector
} from 'reselect';
var base64 = require('base-64');
import { withRouter } from "react-router";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import ModalInformation from '../../Pages/Ortak/ModalInformation';
import cookie from 'react-cookies';
import {
    setEntProSelectPlatform, setEntProSelectPlatformRest,
    setProductEntegrationData, setEntegrationProductStatus,
    setEntegrationData, setEntegrationProductPrice, setProductEntegrationDataState,
    setEntegrationProductSave
} from '../../../redux/modules/settings';
import {
    getStock
} from '../../../redux/modules/stock';
import TimerIcon from '@material-ui/icons/Timer';
import Close from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import styles from './SlidingMenu-jss';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { FormattedMessage, injectIntl } from 'react-intl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {
    getLicenceModule, getParameter, getLicence, getLicenceModulePackage, soundPlay, notificationShow
} from '../../../function/GeneralFunction';
import IconButton from '@material-ui/core/IconButton';
import Ionicon from 'react-ionicons';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import * as workerTimers from "worker-timers";
import Switch from '@material-ui/core/Switch';
import { purple } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';

const PurpleSwitch = withStyles({
    switchBase: {
        color: '#c0392b',
        '&$checked': {
            color: '#16a085',
        },
        '&$checked + $track': {
            backgroundColor: '#16a085',
        },
    },
    checked: {},
    track: {
        backgroundColor: '#c0392b',
    },
})(Switch);

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            prefix="₺"
        />
    );
}

class EntegrationProduct extends React.Component {
    constructor() {
        super();
    }

    async componentDidMount() {
        await this.props.getStock(false).then(() => { });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.IsEntegrationProductUpdate == this.props.IsEntegrationProductUpdate) {
            return false;
        }
        return true;
    }

    compareEntegrationProductList(a, b) {
        const genreA = a.Name;
        const genreB = b.Name;

        let comparison = 0;
        if (genreA > genreB) {
            comparison = 1;
        } else if (genreA < genreB) {
            comparison = -1;
        }
        return comparison;
    }

    handleFocus = (event) => event.target.select();

    returnEntegrationList(type) {
        const { GetirYemekRestaurantList, TrendyolYemekRestaurantList, MigrosYemekRestaurantList } = this.props.settingsReducer;
        var returnData = [];
        if (type == "GetirYemek") {
            if (GetirYemekRestaurantList != null) {
                returnData = GetirYemekRestaurantList;
            }
        } else if (type == "TrendyolYemek") {
            if (TrendyolYemekRestaurantList != null) {
                returnData = TrendyolYemekRestaurantList;
            }
        } else if (type == "MigrosYemek") {
            if (MigrosYemekRestaurantList != null) {
                var mirgosList = [];
                MigrosYemekRestaurantList.map((n, i) => {
                    n.mYemekRestaurants.map((x, y) => {
                        x["ApiKey"] = n.ApiKey;
                        mirgosList.push(x);
                    })
                })
                returnData = mirgosList;
            }
        }
        return returnData;
    }

    handleChangePlatformRest = async event => {
        const { entProSelectPlatform } = this.props;
        await this.props.setEntProSelectPlatformRest(event.target.value, event.currentTarget.getAttribute("data-storeId")).then(() => { })
        var type = 0;
        var platformid = event.target.value;
        if (entProSelectPlatform == "TrendyolYemek") {
            type = 2;
        } else if (entProSelectPlatform == "GetirYemek") {
            type = 0;
        } else if (entProSelectPlatform == "MigrosYemek") {
            type = 4;
            platformid = base64.encode(platformid);
        }

        await this.props.setProductEntegrationData(type, platformid).then(() => { })
    };

    handleChangePrice = (Id) => event => {
        const { productEntegrationData } = this.props;
        productEntegrationData.find(x => x.platProductId == Id)["newPrice"] = event.target.value;
        productEntegrationData.find(x => x.platProductId == Id)["isSelected"] = true;
        this.props.setEntegrationData(productEntegrationData).then(() => { })
    };

    fileHandler = (event) => {
        let fileObj = event.target.files[0];
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                console.log(resp.rows);
            }
        });
    }

    handleChangeEntegration = (Id, name) => event => {
        this.props.productEntegrationData.find(x => x.Id == Id)[name] = event.target.value;
        this.props.setProductEntegrationDataState(this.props.productEntegrationData).then(() => { });
    };

    render() {
        const { classes, entProSelectPlatform, entProSelectPlatformRest, orderMenuTab, entegrationLoadDataLoading } = this.props;

        return (
            <div style={{ display: 'block' }}>
                <div style={{ position: 'relative', display: 'block', overflow: 'hidden', height: '100%', padding: '0px 3px 0px 3px', }}>
                    <div style={{ display: 'flex', margin: 5, marginTop: 0 }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div onClick={() => this.props.setEntProSelectPlatform((orderMenuTab == 1 ? "active" : "price"), "GetirYemek").then(() => { })} style={{ cursor: 'grab', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25%', height: 50, backgroundColor: 'rgb(76, 51, 152)', opacity:(entProSelectPlatform == "GetirYemek" ? 1 : .5) }}>
                                <span style={{ fontWeight: (entProSelectPlatform == "GetirYemek" ? 600 : 500), fontSize: (entProSelectPlatform == "GetirYemek" ? 15 : 14), color: 'white' }}>{"GetirYemek"}</span>
                            </div>
                            <div onClick={() => this.props.setEntProSelectPlatform((orderMenuTab == 1 ? "active" : "price"), "TrendyolYemek").then(() => { })} style={{ cursor: 'grab', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25%', height: 50, backgroundColor: '#f27a1a', opacity:(entProSelectPlatform == "TrendyolYemek" ? 1 : .5) }}>
                                <span style={{ fontWeight: (entProSelectPlatform == "TrendyolYemek" ? 600 : 500), fontSize: (entProSelectPlatform == "TrendyolYemek" ? 16 : 14), color: 'white' }}>{"TrendyolYemek"}</span>
                            </div>
                            <div onClick={() => this.props.setEntProSelectPlatform((orderMenuTab == 1 ? "active" : "price"), "MigrosYemek").then(() => { })} style={{ cursor: 'grab', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25%', height: 50, backgroundColor: '#27ae60', opacity:(entProSelectPlatform == "MigrosYemek" ? 1 : .5) }}>
                                <span style={{ fontWeight: (entProSelectPlatform == "MigrosYemek" ? 600 : 500), fontSize: (entProSelectPlatform == "MigrosYemek" ? 16 : 14), color: 'white' }}>{"MigrosYemek"}</span>
                            </div>
                            <div onClick={() => this.props.setEntProSelectPlatform((orderMenuTab == 1 ? "active" : "price"), "YemekSepeti").then(() => { })} style={{ cursor: 'grab', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25%', height: 50, backgroundColor: '#fa0050', opacity:(entProSelectPlatform == "YemekSepeti" ? 1 : .5) }}>
                                <span style={{ fontWeight: (entProSelectPlatform == "YemekSepeti" ? 600 : 500), fontSize: (entProSelectPlatform == "YemekSepeti" ? 16 : 14), color: 'white' }}>{"YemekSepeti"}</span>
                            </div>
                        </div>
                    </div>
                    {(
                        orderMenuTab != 3 ? <div style={{ display: 'flex', margin: 5, marginTop: 0, backgroundColor: 'rgb(41, 41, 41)', borderRadius: 5, padding: 5 }}>
                            <FormControl style={{ width: '100%' }} className={classes.formControl}>
                                <Typography style={{ fontWeight: 400, color: 'rgba(255, 255, 255, 0.7)', marginBottom: 6, padding: 0, fontSize: 11, fontFamily: '"Open Sans", sans-serif', lineHeight: '1.2em' }} className={classes.flex}>{"İşlem yapmak istediğiniz restoranı seçiniz!"}</Typography>
                                <Select
                                    value={entProSelectPlatformRest}
                                    onChange={this.handleChangePlatformRest}
                                    input={<Input name="WebApiUserId" id="age-helper" />}
                                    style={{ width: '100%' }}
                                >
                                    {
                                        this.returnEntegrationList(entProSelectPlatform).map((n, i) => ([
                                            <MenuItem data-storeId={(entProSelectPlatform == "MigrosYemek" ? n.storeId : "")} key={i} value={(entProSelectPlatform == "GetirYemek" ? n.secretkey : (entProSelectPlatform == "TrendyolYemek" ? n.supplierId : (entProSelectPlatform == "MigrosYemek" ? n.ApiKey : "")))}><em>{n.name}</em></MenuItem>
                                        ]))
                                    }
                                </Select>
                            </FormControl>
                        </div> : null
                    )}
                </div>
                <div style={{ height: (orderMenuTab == 2 ? 'calc(100vh - 235px)' : (orderMenuTab == 1 ? 'calc(100vh - 235px)' : (orderMenuTab == 3 && entProSelectPlatform == "YemekSepeti" ? 'calc(100vh - 110px)' : 'calc(100vh - 165px)'))), overflow: 'auto', margin: 5, marginTop: 0, backgroundColor: 'rgb(41, 41, 41)', borderRadius: 5, padding: 5 }}>
                    {(orderMenuTab == 3 && entProSelectPlatform == "YemekSepeti" ?
                        <div style={{ padding: 5, border: '1px solid #636363', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: 12, color: 'white' }}>{"Önemli Not: YemekSepeti entegrasyonunda ürün listesi servisler aracılığı ile iletilmemektedir. Bu sebeple JaviPos teknik ekip ile irtibata geçip YemekSepeti'nden Excel formatında ürün listenizi talep edebilirsiniz! Teknik ekibimiz sizleri süreç ile ilgili bilgilendirecektir."}</span>
                        </div>
                        : null)}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>{(entegrationLoadDataLoading ? <CircularProgress className={classes.progress} /> : null)}</div>
                    <Table style={{ margin: 0 }} className={classNames(classes.table, classes.stripped, classes.hover)}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">{"Ürün Adı"}</TableCell>
                                {(orderMenuTab == 1 || orderMenuTab == 3 ? null : <TableCell align="left">{"Mevcut Fiyat"}</TableCell>)}
                                <TableCell align="right">{(orderMenuTab == 1 ? "Açma / Kapatma" : (orderMenuTab == 3 && entProSelectPlatform == "YemekSepeti" ? "Ürün Id'si" : (orderMenuTab == 3 && entProSelectPlatform != "YemekSepeti" ? "Eşleştirilecek Ürün" : "Yeni Fiyat")))}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (orderMenuTab == 3 && entProSelectPlatform == "YemekSepeti" ?
                                    this.props.productListSearch.sort(this.compareEntegrationProductList).map((n, i) => {
                                        return (
                                            <TableRow>
                                                <TableCell align="left">{n.Name}</TableCell>
                                                <TableCell align="right">{n.Id}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    this.props.productEntegrationData.sort(this.compareEntegrationProductList).map((n, i) => {
                                        return (
                                            <TableRow>
                                                <TableCell align="left">{n.Name}</TableCell>
                                                {(orderMenuTab == 1 || orderMenuTab == 3 ? null : <TableCell align="left">{n.price.toFixed(2)}</TableCell>)}
                                                <TableCell align="right">
                                                    {
                                                        (orderMenuTab == 1 ?
                                                            <PurpleSwitch
                                                                onChange={() => this.props.setEntegrationProductStatus(n.platProductId, !n.status).then(() => { })}
                                                                checked={n.status}
                                                            /> :
                                                            (orderMenuTab == 2 ?
                                                                <TextField
                                                                    value={n.newPrice}
                                                                    style={{ borderRadius: 8, border: (n.isSelected ? '1px solid #e74c3c' : '') }}
                                                                    onChange={this.handleChangePrice(n.platProductId)}
                                                                    fullWidth
                                                                    onFocus={this.handleFocus}
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom,
                                                                    }}
                                                                /> :
                                                                <FormControl fullWidth className={classes.formControl}>
                                                                    <InputLabel htmlFor="age-helper">{"Seçim"}</InputLabel>
                                                                    <Select
                                                                        fullWidth
                                                                        value={n.EntegrationId}
                                                                        onChange={this.handleChangeEntegration(n.Id, "EntegrationId")}
                                                                        input={<Input name={"Productentegration"} id="age-helper" />}
                                                                    >
                                                                        <MenuItem value={"00000000-0000-0000-0000-000000000000"}><em>{"Boş"}</em></MenuItem>
                                                                        {
                                                                            this.props.productListSearch.sort(this.compareEntegrationProductList).map((n, i) => {
                                                                                return <MenuItem key={i} value={n.Id}>{n.Name}</MenuItem>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            )
                                                        )
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }))
                            }
                        </TableBody>
                    </Table>
                </div>
                {
                    (orderMenuTab == 2 ? <div onClick={() => (confirm('Ürün fiyat değişikliklerini mümkün oldukça toplu yapmalısınız! Fiyat değişikliklerini göndermek istediğinize emin misiniz?') ? this.props.setEntegrationProductPrice().then(() => { }) : null)} style={{ margin: 5, cursor: 'grab', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, backgroundColor: '#34495e' }}>
                        <span style={{ fontSize: 14, color: 'white' }}>{"Fiyat Değişikliğini Gönder"}</span>
                    </div> : (orderMenuTab == 3  && entProSelectPlatform != "YemekSepeti" ? <div onClick={() => (confirm('Ürün eşleştirme bilgisi kayıt edilecektir. Onaylıyor musunuz?') ? this.props.setEntegrationProductSave().then(() => { }) : null)} style={{ margin: 5, cursor: 'grab', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, backgroundColor: '#34495e' }}>
                        <span style={{ fontSize: 14, color: 'white' }}>{"Eşleştirmeyi Kaydet"}</span>
                    </div> : null))
                }
            </div>
        );
    }
}

const valueSelector = (state, props) => state.get('settingsReducer').IsEntegrationProductUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    IsEntegrationProductUpdate: value(state, props),
    settingsReducer: state.get('settingsReducer').form_data,
    entProSelectPlatform: state.get('settingsReducer').entProSelectPlatform,
    entegrationLoadDataLoading: state.get('settingsReducer').entegrationLoadDataLoading,
    productEntegrationData: state.get('settingsReducer').productEntegrationData,
    entProSelectPlatformRest: state.get('settingsReducer').entProSelectPlatformRest,
    orderMenuTab: state.get('settingsReducer').orderMenuTab,
    productListSearch: state.get('stockReducer').form_data.productListSearch,
})

const mapDispatchToProps = {
    setEntProSelectPlatform,
    setEntProSelectPlatformRest,
    setProductEntegrationData,
    setEntegrationProductStatus,
    setEntegrationData,
    setEntegrationProductPrice,
    setProductEntegrationDataState,
    getStock,
    setEntegrationProductSave
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(EntegrationProduct)));
