const styles = theme => ({
  centerBrandButton: {
    paddingLeft: '30px !important',
    paddingRight: '30px !important'
  },
  appBar: {
    backgroundImage: 'linear-gradient(-45deg, rgb(96, 125, 139) 0%, rgb(96, 125, 139) 33%, rgb(117, 117, 117) 100%) !important',
  },
  flex: {
    width: '100%',
    textAlign: 'center'
  },
  rootButton: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 5,
    height: 190,
    width: 200,
    borderRadius: 3,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      height: 40,
      marginBottom: -1,
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& > *': {
      padding: '0 5px'
    }
  },
  OparationScroolPaper: {
    backgroundColor: 'rgb(48, 48, 48)'
  },
  rootButtonTitle: {
    color: theme.palette.common.white,
    fontSize: 18,
    fontWeight: 500
  },
  buttonStep: {

  },
  button: {
    display: 'table',
    width: '100%',
    height: '100%',
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    '&:hover': {
      background: theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.secondary.light
    },
    '& > span:first-child': {
      display: 'table-row',
    },
    '& $icon': {
      margin: '0 auto',
      display: 'table-cell',
      fontSize: 64,
    },
    '& $text': {
      width: 210,
      textAlign: 'left',
      paddingLeft: theme.spacing(1),
      verticalAlign: 'middle',
      display: 'table-cell'
    },
    '& $info': {
      display: 'block',
      textTransform: 'none',
      color: theme.palette.grey[500],
      whiteSpace: 'initial'
    }
  },
  text: {},
  info: {},
  icon: {},

});

export default styles;
