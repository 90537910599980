import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../Settings/settings-jss';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {
  subscriberDetailModalVisibleChange,
  subscriberPlanAmountUpdateModalVisibleChange, setExternalReceived
} from '../../../../redux/modules/admin';
import { createSelector } from 'reselect';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import MaskedInput from 'react-text-mask';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function TextMaskCustomPhone(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        ' ',
        /[1-9]/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class SubcriberDetailModal extends React.Component {
  handleChangeInvoice = (Name) => (event) => {
    if (Name == 'Phone') {
      this.props.sendPaymentLinkPhoneChange(event.target.value);
    } else if (Name == 'NameSurname') {
      this.props.sendPaymentLinkNameSurnameChange(event.target.value);
    } else {
      this.props.sendPaymentLinkPriceChange(event.target.value);
    }
  };

  closeModalButton() {
    this.props.subscriberDetailModalVisibleChange(false);
  }


  compare(a, b) {
    const genreA = a.val_date;
    const genreB = b.val_date;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.subscriberDetailModalVisible}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'lg'}>
        <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {'Abonelik Detayı'}
        </DialogTitle>
        <DialogContent style={{ width: '100%', height: 700 }}>
          {this.props.modalsLoading == true ? (
            <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
              <CircularProgress
                style={{ color: '#fafafa' }}
                thickness={4}
                className={classes.progress}
              />
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{'Ticari Ünvan'}</InputLabel>
                    <Input
                      fullWidth
                      disabled={true}
                      defaultValue={this.props.subscriberDetailList.company_title}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{'Vergi Dairesi'}</InputLabel>
                    <Input
                      fullWidth
                      disabled={true}
                      defaultValue={this.props.subscriberDetailList.company_tax_office}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{'Vergi No'}</InputLabel>
                    <Input
                      fullWidth
                      disabled={true}
                      defaultValue={this.props.subscriberDetailList.company_tax_no}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{'Telefon'}</InputLabel>
                    <Input
                      fullWidth
                      inputComponent={TextMaskCustomPhone}
                      disabled={true}
                      defaultValue={this.props.subscriberDetailList.company_phone}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{'E-posta'}</InputLabel>
                    <Input
                      fullWidth
                      disabled={true}
                      defaultValue={this.props.subscriberDetailList.company_eposta}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{'Adres'}</InputLabel>
                    <Input
                      fullWidth
                      disabled={true}
                      defaultValue={this.props.subscriberDetailList.company_adress}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Table
                className={classNames(classes.table, classes.bordered)}
                style={{ marginTop: 0 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{'Ödeme Tarihi'}</TableCell>
                    <TableCell align="left">{'Tutar'}</TableCell>
                    <TableCell align="left">{'Durumu'}</TableCell>
                    <TableCell align="right">{'Tutar Güncelle'}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.subscriberDetailList.plan !== undefined
                    ? this.props.subscriberDetailList.plan.sort((a, b) => b.plan_id - a.plan_id).map((n, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell align="left">{n.val_date}</TableCell>
                          <TableCell align="left">{n.amount.toFixed(2)}</TableCell>
                          <TableCell align="left">{n.status_desc}</TableCell>
                          <TableCell align="right">
                            {(n.status_desc != "Ödeme Alındı" ?
                              <Tooltip title="Faturayı Harici Alındı Yap">
                                <IconButton
                                  style={{
                                    backgroundColor: '#3e3e3e'
                                  }}
                                  onClick={() =>
                                    this.props.setExternalReceived(
                                      n.invoice_id,
                                      this.props.subscriberDetailList.subscription_id,
                                      this.props.subscriberDetailList.reference_no
                                    )
                                  }>
                                  <Icon>{'credit_score'}</Icon>
                                </IconButton>
                              </Tooltip> : null)}
                            <Tooltip title="Ödeme Tutarı Güncelle">
                              <IconButton
                                style={{
                                  backgroundColor: '#3e3e3e',
                                  marginLeft: 10,
                                  display: n.status == 1 || n.status == 4 ? '' : 'none',
                                }}
                                onClick={() =>
                                  this.props.subscriberPlanAmountUpdateModalVisibleChange(
                                    true,
                                    this.props.subscriberDetailList.subscription_id,
                                    this.props.subscriberDetailList.reference_no,
                                    n.plan_id,
                                    n.amount
                                  )
                                }>
                                <Icon>{'payments'}</Icon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                    : null}
                </TableBody>
              </Table>
            </DialogContentText>
          )}
        </DialogContent>
        {this.props.modalsLoading == false ? (
          <DialogActions>
            <Button
              style={{ backgroundColor: '#e74c3c', color: 'white' }}
              onClick={() => this.closeModalButton()}
              color="primary">
              {'Kapat'}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    );
  }
}

SubcriberDetailModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('adminReducer').subscriberDetailModalIsupdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  subscriberDetailModalIsupdate: value(state, props),
  subscriberDetailModalVisible: state.get('adminReducer').subscriberDetailModalVisible,
  modalsLoading: state.get('adminReducer').modalsLoading,
  subscriberDetailList: state.get('adminReducer').subscriberDetailList,
});

const mapDispatchToProps = {
  subscriberDetailModalVisibleChange,
  subscriberPlanAmountUpdateModalVisibleChange,
  setExternalReceived
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(SubcriberDetailModal)));
