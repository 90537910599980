import React from 'react';
import {
    createSelector
} from 'reselect';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import styles from './track-style-jss';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import BarcodeReader from 'react-barcode-reader';
import NumPad from 'react-numpad';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    setPaketYoldaBrandListVisible, getPaketYoldaBrandList, setCreditToBeLoaded
} from '../../../redux/modules/paketyolda';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import DatePicker, { registerLocale } from "react-datepicker";
import "./datepicker.css";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';

class PaketYoldaBrandList extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.getPaketYoldaBrandList(cookie.load("PaketYoldaId")).then(() => { });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaBrandListUpdate == this.props.paketYoldaBrandListUpdate) {
            return false;
        }
        return true;
    }

    render() {
        const { paketYoldaBrandDetail, paketYoldaBrandListVisible, paketYoldaBrandList, classes } = this.props;

        if (paketYoldaBrandDetail == null) {
            return null;
        }

        return (
            <Dialog
                open={paketYoldaBrandListVisible}
                aria-labelledby="simple-dialog-title"
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle style={{ marginBottom: 5 }} id="simple-dialog-title">
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Icon style={{ marginRight: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, backgroundColor: '#3498db', borderRadius: 5, padding: 3 }}>{"storefront"}</Icon>
                        {"İşletme Listesi ve Ayarlar"}
                    </div>
                </DialogTitle>
                <DialogContent style={{ height: '100vh', padding: '0 20px 0px' }}>
                    <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {"Kontör İşlemleri"}
                    </div>
                    <div style={{ fontSize: 40, borderRadius: 5, textAlign: 'center', padding: 5, backgroundColor: '#16a085', width: '100%' }}>
                        <div style={{ fontSize: 18 }}>{"Kalan Kontör Sayısı"}</div>
                        {paketYoldaBrandDetail.CreditAvailable}
                        <div style={{ fontSize: 12 }}>{"Bitiş Tarihi :"} {moment(paketYoldaBrandDetail.LoanExpiry).format('DD-MM-YYYY HH:mm:ss')}</div>
                    </div>
                    <Grid style={{ marginTop: 10 }} container spacing={1}>
                        <Grid item md={4}>
                            <div onClick={() => this.props.setCreditToBeLoaded(1000).then(() => { })} style={{ position: 'relative', fontSize: 40, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150, width: '100%', borderTopLeftRadius: 5, borderTopRightRadius: 5, backgroundColor: '#778ca3' }}>
                                {"1000"}
                                <Icon style={{ fontSize: 30, top: 5, left: 5, position: 'absolute', color: '#313131' }}>{(paketYoldaBrandDetail.CreditToBeLoaded == 1000 ? "radio_button_checked" : "radio_button_unchecked")}</Icon>
                            </div>
                            <div style={{ fontSize: 15, padding: 10, display: 'grid', textAlign: 'center', alignItems: 'center', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, backgroundColor: '#d1d8e0', color: '#424242', fontWeight: 600 }}>
                                <div style={{ width: '100%' }}>{"0.50krş / 500TL"}</div>
                                <div style={{ width: '100%', fontSize: 12 }}>{"Kullanım Süresi 1 Aydır"}</div>
                            </div>
                        </Grid>
                        <Grid item md={4}>
                            <div onClick={() => this.props.setCreditToBeLoaded(5000).then(() => { })} style={{ position: 'relative', fontSize: 40, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150, width: '100%', borderTopLeftRadius: 5, borderTopRightRadius: 5, backgroundColor: '#2d98da' }}>
                                {"5000"}
                                <Icon style={{ fontSize: 30, top: 5, left: 5, position: 'absolute', color: '#313131' }}>{(paketYoldaBrandDetail.CreditToBeLoaded == 5000 ? "radio_button_checked" : "radio_button_unchecked")}</Icon>
                            </div>
                            <div style={{ fontSize: 15, padding: 10, display: 'grid', textAlign: 'center', alignItems: 'center', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, backgroundColor: '#d1d8e0', color: '#424242', fontWeight: 600 }}>
                                <div style={{ width: '100%' }}>{"0.40krş / 2000TL"}</div>
                                <div style={{ width: '100%', fontSize: 12 }}>{"Kullanım Süresi 1 Aydır"}</div>
                            </div>
                        </Grid>
                        <Grid item md={4}>
                            <div onClick={() => this.props.setCreditToBeLoaded(10000).then(() => { })} style={{ position: 'relative', fontSize: 40, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150, width: '100%', borderTopLeftRadius: 5, borderTopRightRadius: 5, backgroundColor: '#f7b731' }}>
                                {"10000"}
                                <Icon style={{ fontSize: 30, top: 5, left: 5, position: 'absolute', color: '#313131' }}>{(paketYoldaBrandDetail.CreditToBeLoaded == 10000 ? "radio_button_checked" : "radio_button_unchecked")}</Icon>
                            </div>
                            <div style={{ fontSize: 15, padding: 10, display: 'grid', textAlign: 'center', alignItems: 'center', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, backgroundColor: '#d1d8e0', color: '#424242', fontWeight: 600 }}>
                                <div style={{ width: '100%' }}>{"0.30krş / 3000TL"}</div>
                                <div style={{ width: '100%', fontSize: 12 }}>{"Kullanım Süresi 1 Aydır"}</div>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {"İşletme Listesi"}
                    </div>
                    <Table style={{ marginTop: 10 }}>
                        <TableHead style={{ backgroundColor: '#212121' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Sırası"}</TableCell>
                                <TableCell style={{ color: 'white', fontSize: 13 }} align="left">{"Adı"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                paketYoldaBrandList.map((n, i) => {
                                    return (
                                        <TableRow hover key={i}>
                                            <TableCell align="left">{i + 1}</TableCell>
                                            <TableCell align="left">{n.Name}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: '#e74c3c', color: 'white', marginRight: 5 }} onClick={() => this.props.setPaketYoldaBrandListVisible(false).then(() => { })} color="primary" autoFocus>
                        {"ÇIKIŞ"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaBrandListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaBrandListUpdate: value(state, props),
    paketYoldaBrandListVisible: state.get('paketYoldaReducer').paketYoldaBrandListVisible,
    paketYoldaBrandList: state.get('paketYoldaReducer').paketYoldaBrandList,
    paketYoldaBrandDetail: state.get('paketYoldaReducer').paketYoldaBrandDetail
})

const mapDispatchToProps = {
    setPaketYoldaBrandListVisible,
    getPaketYoldaBrandList,
    setCreditToBeLoaded
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(PaketYoldaBrandList)));