import React from 'react';
import { connect } from 'react-redux';
import {
  withStyles
} from '@material-ui/core/styles';
import {
  createSelector
} from 'reselect';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import alpemixlogo from 'dan-images/alpemix.png';
import tawktologo from 'dan-images/TawktoLogo.png';
import cookie from 'react-cookies';

class JaviPosHelp extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    console.log("nextProps", nextProps);
    return true;
  }

  render() {
    const {
      handleCloseHelpDialog,
      open,
      title,
      classes
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleCloseHelpDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle style={{ padding: '24px 24px 10px', marginBottom: 0 }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <div className={classes.rootTable}>
            <Table style={{ marginBottom: 0 }} className={classNames(classes.table, classes.stripped)}>
              <TableBody>
                <TableRow>
                  <TableCell padding="default" style={{ paddingRight: 10, width: '50%' }}>{"Lisans Numarası"}
                    <span style={{ float: 'right' }}>{":"}</span>
                  </TableCell>
                  <TableCell align="left" style={{ paddingLeft: 0, width: '50%' }}>{cookie.load('LicenceNumber') || "Licence Number"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="default" style={{ paddingRight: 10, width: '50%' }}>{"Telefon Numarası"}
                    <span style={{ float: 'right' }}>{":"}</span>
                  </TableCell>
                  <TableCell align="left" style={{ paddingLeft: 0, width: '50%' }}>{"0532 393 09 31"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="default" style={{ paddingRight: 10, width: '50%' }}>{"Email"}
                    <span style={{ float: 'right' }}>{":"}</span>
                  </TableCell>
                  <TableCell align="left" style={{ paddingLeft: 0, width: '50%' }}>{"info@arkenyazilim.com"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="default" style={{ paddingRight: 10, width: '50%' }}>{"Mesai Saatleri"}
                    <span style={{ float: 'right' }}>{":"}</span>
                  </TableCell>
                  <TableCell align="left" style={{ paddingLeft: 0, width: '50%' }}>{"7/24 Hizmet"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div style={{ borderBottom: '1px solid #636363', paddingBottom: 15, paddingTop: 15 }}>
            <span style={{ color: 'white', fontSize: 16, paddingLeft: 24 }}>{"Uzak Masaüstü Bağlantısı"}</span>
            <a target={"_blank"} href={"https://www.alpemix.com/tr/alpemix-indir-Windows"}><img style={{ height: 30, float: 'right', paddingRight: 24 }} src={alpemixlogo} /></a>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} onClick={handleCloseHelpDialog} style={{ backgroundColor: '#F44336', color: 'white' }}>
            {"Çıkış"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const valueSelector = (state, props) => props.open;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  open: value(state, props),
})

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JaviPosHelp);
