module.exports = {
  name: 'JaviPos',
  desc: 'JaviPos - Saas Restoran',
  prefix: 'javipos',
  footerText: 'JaviPos All Rights Reserved 2019',
  logoText: 'JaviPos',
  urlSocket: 'https://testapi.javipos.com/',
  urlReport: 'https://report.javipos.com/',
  urlSocketPaketYolda: 'https://paketyoldatest.javipos.com/',
  qrImageUrlSocket: 'https://cdn.javipos.com/',
  urlWeb: 'https://test.javipos.com/',
};