import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from './track-style-jss';
import {
    setCourierAddVisible, setPaketYoldaCourierAdd, setPaketYoldaCourier
} from '../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { ComplateLowerText } from '../../../function/GeneralFunction';

class CourierAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = { courierDeleteVisible: false, courierDeleteId: "" }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaCourierAddUpdate == this.props.paketYoldaCourierAddUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        this.handleSubmitCourierAdd = this.handleSubmitCourierAdd.bind(this);
    }

    handleChangePaketYoldaCourier = (Name) => (event) => {
        const { paketYoldaCourierAddData } = this.props;
        paketYoldaCourierAddData[Name] = event.target.value;
        this.props.setPaketYoldaCourierAdd(paketYoldaCourierAddData).then(() => { })
    };

    handleSubmitCourierAdd(event) {
        event.preventDefault();
        this.props.setPaketYoldaCourier().then(() => { })
    }

    render() {
        const { classes, courierAddVisible, paketYoldaCourierAddData } = this.props;

        return (
            <Dialog
                open={courierAddVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'xs'}
            >
                <form style={{ display: 'contents' }} onSubmit={this.handleSubmitCourierAdd}>
                    <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                        {"JaviKurye Kurye Ekleme / Ödeme İşlemleri"}
                        <Typography className={classes.instructions} style={{ fontSize: 13, fontWeight: 500, color: 'white' }}>
                            {"JaviKurye sistemlerine erişebilecek kurye işlemleri"}
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflow: 'hidden auto', height: '100vh' }}>

                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'İsim Soyisim Adı'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'İsim Soyisim Giriniz'}
                                        defaultValue={paketYoldaCourierAddData.NameSurname}
                                        onChange={this.handleChangePaketYoldaCourier('NameSurname')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    label="Kullanıcı Adı"
                                    placeholder={'Kullanıcı Adı Giriniz'}
                                    id="outlined-end-adornment"
                                    fullWidth
                                    sx={{ m: 1, width: '25ch' }}
                                    defaultValue={paketYoldaCourierAddData.Username}
                                    onChange={this.handleChangePaketYoldaCourier('Username')}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">@paketyolda.com</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Şifre'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Şifre Giriniz'}
                                        defaultValue={paketYoldaCourierAddData.Password}
                                        onChange={this.handleChangePaketYoldaCourier('Password')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Telefon Numarası'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Telefon Numarası Giriniz'}
                                        defaultValue={paketYoldaCourierAddData.Phone}
                                        onChange={this.handleChangePaketYoldaCourier('Phone')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type={"submit"} variant="contained" style={{ backgroundColor: '##27ae60' }} onClick={() => this.props.setCourierAddVisible(false).then(() => { })} color="primary">
                            {"Kaydet"}
                        </Button>
                        <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setCourierAddVisible(false).then(() => { })} color="primary">
                            {"Çıkış"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

CourierAdd.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaCourierAddUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaCourierAddUpdate: value(state, props),
    courierAddVisible: state.get('paketYoldaReducer').courierAddVisible,
    PaketYoldaId: state.get('paketYoldaReducer').PaketYoldaId,
    paketYoldaCourierAddData: state.get('paketYoldaReducer').paketYoldaCourierAddData
});

const mapDispatchToProps = {
    setCourierAddVisible,
    setPaketYoldaCourierAdd,
    setPaketYoldaCourier
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(CourierAdd)));
