import React, { Fragment } from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage, injectIntl } from 'react-intl';
import Icon from '@material-ui/core/Icon';
const uuidv4 = require('uuid/v4');
import {
    setFranchiseSadakatVisible, setFranchiseReportName, getBrandList, setFranchiseReportBrandSelected,
    getCenterSettings, setFranchiseSadakatData, setJaviAppSettings
} from '../../../redux/modules/centerbrand';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import StarBorder from '@material-ui/icons/StarBorder';
import Collapse from '@material-ui/core/Collapse';
import ModalInformation from '../Ortak/ModalInformation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import OrderHeat from './Report/OrderHeat';
import Reviews from './Report/Reviews';
import DailyMonthlyGraffic from './Report/DailyMonthlyGraffic';
import GeneralTotal from './Report/GeneralTotal';
import ProductSalesList from './Report/ProductSalesList';
import EntegrationList from './Report/EntegrationList';
import AdditionReportList from './Report/AdditionReportList';
import Logs from './Report/Logs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import DatePicker, { registerLocale } from "react-datepicker";

import moment from 'moment';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Sadakat extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.franchiseSadakatUpdate == this.props.franchiseSadakatUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        await this.props.getBrandList().then(() => { });
        await this.props.getCenterSettings().then(() => { });
    }

    handleChangeData = (name) => event => {
        const { franchiseSadakatData } = this.props;
        franchiseSadakatData[name] = (name == "IsApp" ? event.target.checked : event.target.value);
        this.props.setFranchiseSadakatData(franchiseSadakatData).then(() => { });
    };

    handleChangeWorkingHours = (day, type) => event => {
        const { franchiseSadakatData } = this.props;
        franchiseSadakatData.WorkingHours.find(x => x.Day == day)[type] = (type == "StartHour" || type == "EndHour" ? moment.utc(event, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm") : event.target.checked);
        this.props.setFranchiseSadakatData(franchiseSadakatData).then(() => { });
    };

    changeAllDayHours() {
        const { franchiseSadakatData } = this.props;
        var pazartesi = franchiseSadakatData.WorkingHours.find(x => x.Day == "Pazartesi");
        franchiseSadakatData.WorkingHours.map((n, i) => {
            n.StartHour = pazartesi.StartHour;
            n.EndHour = pazartesi.EndHour;
            n.Hour24 = pazartesi.Hour24;
        })
        this.props.setFranchiseSadakatData(franchiseSadakatData).then(() => { });
    }

    render() {
        const {
            classes,
            franchiseSadakatData,
            franchiseSadakatVisible,
        } = this.props;

        return (
            <Dialog
                fullScreen
                open={franchiseSadakatVisible}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%' }}>
                    <Toolbar>
                        <Button variant='contained' onClick={() => this.props.setFranchiseSadakatVisible(false).then(() => { })}>
                            <Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon>
                        </Button>
                        <Typography variant="h6" color="inherit" className={classes.flex}>{"JaviSadakat Uygulaması Ayarları"}</Typography>
                        <Button color="inherit" variant="outlined" onClick={() => this.props.setJaviAppSettings().then(() => { })}>Ayarları Kaydet</Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64 }}>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '75%' }}>{"JaviSadakat Sistemini"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={franchiseSadakatData.IsApp}
                                            onChange={this.handleChangeData("IsApp")}
                                        />
                                    )}
                                    label={"Kullan"}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '50%' }}>{"Birincil ve İkincil Renk Seçenekleri (Hex)"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%', marginRight: 5 }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={franchiseSadakatData.PrimaryColor}
                                    onChange={this.handleChangeData("PrimaryColor")}
                                    endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end"><Icon style={{ color: '#ffa801' }}>{"circle"}</Icon></InputAdornment>}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                />
                            </div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={franchiseSadakatData.SecondaryColor}
                                    onChange={this.handleChangeData("SecondaryColor")}
                                    endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end"><Icon style={{ color: '#485460' }}>{"circle"}</Icon></InputAdornment>}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>{"Şubelerin Çalışma Saatleri"}</span>
                            <Button onClick={() => this.changeAllDayHours()} className={classes.rootButton} style={{ float:'right', display: 'flex', height: 30, width: 100, maxWidth: 100, backgroundColor: '#009688', margin: 0, textTransform: 'capitalize' }}>
                                {"Tümüne Uygula"}
                            </Button>
                        </div>
                        <Table style={{ marginTop: 5 }}>
                            <TableHead style={{ backgroundColor: '#212121' }}>
                                <TableRow>
                                    <TableCell align="left">{"Durum"}</TableCell>
                                    <TableCell align="center">{"Gün"}</TableCell>
                                    <TableCell align="center">{"Açılış Saati"}</TableCell>
                                    <TableCell align="center">{"Kapanış Saati"}</TableCell>
                                    <TableCell align="right">{"24 Saat"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    franchiseSadakatData.WorkingHours.map((n, i) => {
                                        return (
                                            <TableRow hover key={i}>
                                                <TableCell align="left" style={{ padding: '0px 15px 0px 24px' }}>
                                                    <FormControlLabel
                                                        key={i}
                                                        control={(
                                                            <Switch
                                                                checked={n.IsStatus}
                                                                onChange={this.handleChangeWorkingHours(n.Day, "IsStatus")}
                                                            />
                                                        )}
                                                        label={""}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px 15px 0px 24px' }}>{n.Day}</TableCell>
                                                <TableCell align="center" style={{ padding: '0px 15px 0px 24px' }}>
                                                    <DatePicker
                                                        selected={new Date(n.StartHour)}
                                                        onChange={this.handleChangeWorkingHours(n.Day, "StartHour")}
                                                        dateFormat="HH:mm"
                                                        withPortal
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        locale="tr"
                                                        className={"inputDate"}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px 15px 0px 24px' }}>
                                                    <DatePicker
                                                        selected={new Date(n.EndHour)}
                                                        onChange={this.handleChangeWorkingHours(n.Day, "EndHour")}
                                                        dateFormat="HH:mm"
                                                        withPortal
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        locale="tr"
                                                        className={"inputDate"}
                                                    />
                                                </TableCell>
                                                <TableCell align="right" style={{ padding: '0px 15px 0px 24px' }}>
                                                    <FormControlLabel
                                                        key={i}
                                                        control={(
                                                            <Switch
                                                                checked={n.Hour24}
                                                                onChange={this.handleChangeWorkingHours(n.Day, "Hour24")}
                                                            />
                                                        )}
                                                        label={""}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').franchiseSadakatUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    franchiseSadakatUpdate: value(state, props),
    franchiseSadakatVisible: state.get('centerBrandReducer').franchiseSadakatVisible,
    franchiseReportName: state.get('centerBrandReducer').franchiseReportName,
    centerBrandData: state.get('centerBrandReducer').centerBrandData,
    franchiseReportBrandSelected: state.get('centerBrandReducer').franchiseReportBrandSelected,
    franchiseSadakatData: state.get('centerBrandReducer').franchiseSadakatData
})

const mapDispatchToProps = {
    setFranchiseSadakatVisible,
    setFranchiseReportName,
    getBrandList,
    setFranchiseReportBrandSelected,
    getCenterSettings,
    setFranchiseSadakatData,
    setJaviAppSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(Sadakat)));
