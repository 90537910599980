import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { EmptyData } from 'dan-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePagination from '@material-ui/core/TablePagination';
import {
    setPaketYoldaGeneralSettingsData, setSettings
} from '../../../../redux/modules/paketyolda';
import { getPaketYolda } from '../../../../function/GeneralFunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';

function NumberFormatGroupingTime(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            prefix="Dk"
        />
    );
}

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
});

class AssignmentSettings extends React.Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaGeneralSettingsUpdate == this.props.paketYoldaGeneralSettingsUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    handleChangePaketYoldaGeneral = (Name) => (event) => {
        const { paketYoldaGeneralSettingsData } = this.props;
        paketYoldaGeneralSettingsData[Name] = (Name == "IsAutomaticRouting" ? event.target.checked : (Name == "WorkingHourStart" || Name == "WorkingHourEnd" ? moment.utc(event, 'MM-DD-YYYY').local().format("YYYY-MM-DDTHH:mm") : event.target.value));
        this.props.setPaketYoldaGeneralSettingsData(paketYoldaGeneralSettingsData).then(() => { })
    };

    render() {
        const { paketYoldaGeneralSettingsData, paketYoldaGeneralSettingsLoading, paketYoldaSettingsMenu, classes } = this.props;

        if (paketYoldaSettingsMenu != "AssignmentSettings") {
            return null;
        }

        if (paketYoldaGeneralSettingsLoading) {
            return <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
                <CircularProgress
                    style={{ color: '#fafafa' }}
                    thickness={4}
                    className={classes.progress}
                />
            </div>
        }

        return (
            <Paper className={classes.root} elevation={0} style={{ backgroundColor: 'transparent', borderRadius: 0, height: '100%' }}>
                <div style={{ height: 'calc(100% - 43px)', padding: 5, overflowY: 'auto' }}>
                    <div style={{ backgroundColor: 'rgb(49, 49, 49)', padding: 5 }}>
                        <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {"Akıllı Algoritma ile Otomatik Paket Atama Ayarları"}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '75%' }}>{"Otomatik Atama Kullan"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={paketYoldaGeneralSettingsData.IsAutomaticRouting}
                                            onChange={this.handleChangePaketYoldaGeneral("IsAutomaticRouting")}
                                        />
                                    )}
                                    label={"Kullan"}
                                />
                            </div>
                        </div>
                        <div style={{ paddingLeft: 10, fontSize: 12, display: 'flex', alignItems: 'center', backgroundColor: '#d1d8e0', color: '#424242', fontWeight: 600 }}>
                            <Icon style={{ fontSize: 20, marginRight: 5 }}>{"info"}</Icon>
                            <div style={{ width: '100%', fontSize: 12 }}>
                                {"Bu sürenin altında kalan paketler kurye max. paket atama sınırına ulaşılıncaya kadar gruplanarak kuryeye otomatik atanır. Süre olarak üzerinde kalan paketler tek olarak gönderilir!"}
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '75%' }}>{"Paketleri Gruplama Süresi"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                <Input
                                    type="number"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={paketYoldaGeneralSettingsData.GroupingTime}
                                    onChange={this.handleChangePaketYoldaGeneral("GroupingTime")}
                                    endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end">Dk</InputAdornment>}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                    inputProps={{
                                        min: 0, max: 60
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ paddingLeft: 10, fontSize: 12, display: 'flex', alignItems: 'center', backgroundColor: '#d1d8e0', color: '#424242', fontWeight: 600 }}>
                            <Icon style={{ fontSize: 20, marginRight: 5 }}>{"info"}</Icon>
                            <div style={{ width: '100%', fontSize: 12 }}>
                                {"Atama için sırası gelen paketler gruplama yapılırken bir veya birden fazla paket bu mesafenin içerisinde ise otomatik olarak gruplanacaktır!"}
                            </div>
                        </div>
                        <div style={{  display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '75%' }}>{"Atanacak Paketler Arası Yarıçap Mesafesi"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                <Input
                                    type="number"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={paketYoldaGeneralSettingsData.KmDistance}
                                    onChange={this.handleChangePaketYoldaGeneral("KmDistance")}
                                    endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end">Metre</InputAdornment>}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                    inputProps={{
                                        min: 0
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ paddingLeft: 10, fontSize: 12, display: 'flex', alignItems: 'center', backgroundColor: '#d1d8e0', color: '#424242', fontWeight: 600 }}>
                            <Icon style={{ fontSize: 20, marginRight: 5 }}>{"info"}</Icon>
                            <div style={{ width: '100%', fontSize: 12 }}>
                                {"Kurye üzerine otomatik olarak max. bu adet kadar atama yapılır!"}
                            </div>
                        </div>
                        <div style={{  display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '75%' }}>{"Kuryenin Aynı Anda Max. Taşıyabileceği Paket"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                <Input
                                    type="number"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={paketYoldaGeneralSettingsData.CourierMaxPackage}
                                    onChange={this.handleChangePaketYoldaGeneral("CourierMaxPackage")}
                                    endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end">Adet</InputAdornment>}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                    inputProps={{
                                        min: 1
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ paddingLeft: 10, fontSize: 12, display: 'flex', alignItems: 'center', backgroundColor: '#d1d8e0', color: '#424242', fontWeight: 600 }}>
                            <Icon style={{ fontSize: 20, marginRight: 5 }}>{"info"}</Icon>
                            <div style={{ width: '100%', fontSize: 12 }}>
                                {"Kurye ilk paketi teslim etmeye giderken bu mesafe içerisinde kalan paketler sistem üzerinden otomatik ataması yapılır!"}
                            </div>
                        </div>
                        <div style={{  display: 'flex', alignItems: 'center', padding: 10, border: '1px solid rgb(99, 99, 99)', marginBottom: 5 }}>
                            <div style={{ fontSize: 13, textAlign: 'left', width: '75%' }}>{"İlk Sipariş Teslimatına Yakın Paket Mesafesi"}</div>
                            <div style={{ fontSize: 13, textAlign: 'right', width: '25%' }}>
                                <Input
                                    type="number"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={paketYoldaGeneralSettingsData.LineKmDistance}
                                    onChange={this.handleChangePaketYoldaGeneral("LineKmDistance")}
                                    endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end">Metre</InputAdornment>}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                    inputProps={{
                                        min: 100
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: 'rgb(66, 66, 66)', width: '100%', padding: 5, textAlign: 'right' }}>
                    <Button onClick={() => this.props.setSettings().then(() => { })} variant="contained" color="secondary">
                        {"Kaydet"}
                    </Button>
                </div>
            </Paper>
        )
    }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaGeneralSettingsUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    paketYoldaGeneralSettingsUpdate: value(state, props),
    paketYoldaSettingsMenu: state.get('paketYoldaReducer').paketYoldaSettingsMenu,
    paketYoldaGeneralSettingsLoading: state.get('paketYoldaReducer').paketYoldaGeneralSettingsLoading,
    paketYoldaGeneralSettingsData: state.get('paketYoldaReducer').paketYoldaGeneralSettingsData
})

const mapDispatchToProps = {
    setPaketYoldaGeneralSettingsData,
    setSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(AssignmentSettings)));
