import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../../CenterBrandPage/centerbrandpage-jss';
import {
    setPyBrandListVisible, setPaketYoldaBrandAddVisible, getBrand, setPaketYoldaUserListVisible,
    setPaketYoldaBusinessListVisible, setBrandPaymentOk
} from '../../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BrandAdd from './BrandAdd';
import UserList from '../Users/UserList';
import SubBrandList from './SubBrandList';
import BusinessList from './BusinessList';

class BrandList extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.pyBrandListUpdate == this.props.pyBrandListUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() { }

    render() {
        const { classes, pyBrandListVisible, pyBrandList } = this.props;

        return (
            <Dialog
                open={pyBrandListVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'lg'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <BrandAdd classes={classes} />
                <BusinessList classes={classes} />
                <SubBrandList classes={classes} />
                <UserList classes={classes} />
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Ana Bayi İşlemleri"}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{'Aktif'}</TableCell>
                                <TableCell>{'Markası'}</TableCell>
                                <TableCell>{'Yetkili'}</TableCell>
                                <TableCell>{'Telefon'}</TableCell>
                                <TableCell>{'Açıklama'}</TableCell>
                                <TableCell>{'İl'}</TableCell>
                                <TableCell>{'İlçeler'}</TableCell>
                                <TableCell align={"right"}>{'İşlemler'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pyBrandList.map((n, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {<Icon>{n.IsActive ? 'check_box' : 'check_box_outline_blank'}</Icon>}
                                        </TableCell>
                                        <TableCell>{n.BrandName}</TableCell>
                                        <TableCell>{n.AuthorizedPerson}</TableCell>
                                        <TableCell>{n.Phone}</TableCell>
                                        <TableCell>{n.Explanation}</TableCell>
                                        <TableCell>{n.IlId}</TableCell>
                                        <TableCell>{n.IlceIds}</TableCell>
                                        <TableCell align={"right"}>
                                            {
                                                (n.IsPendingPayment ?
                                                    <Tooltip title={moment(n.FinalyPaymentDate).format('YYYY-MM-DD / HH:mm:ss') + " tarihine kadar ödeme onayı bekleniyor!"}>
                                                        <IconButton
                                                            style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                            onClick={() => (confirm('Ödeme onayını vermek istediğinize emin misiniz?') ? this.props.setBrandPaymentOk(n.Id).then(() => { }) : null)}>
                                                            <Icon>{"payment"}</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null)
                                            }
                                            <Tooltip title={"Düzenle"}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                    onClick={() => this.props.getBrand(n.Id).then(() => { })}>
                                                    <Icon>{"edit"}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Kullanıcı Listesi"}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                    onClick={() => this.props.setPaketYoldaUserListVisible(true, n.Id, n.CenterId).then(() => { })}>
                                                    <Icon>{"group"}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Alt Bayiler Listesi"}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                    onClick={() => this.props.setPyBrandListVisible(true, n.Id, n.CenterId, false).then(() => { })}>
                                                    <Icon>{"dvr"}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Tanımlı Olan İşletmeler"}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                    onClick={() => this.props.setPaketYoldaBusinessListVisible(true, n.Id).then(() => { })}>
                                                    <Icon>{"storefront"}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => this.props.setPaketYoldaBrandAddVisible(true).then(() => { })} color="primary">
                        {"Ana BAYİ Ekle"}
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPyBrandListVisible(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

BrandList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').pyBrandListUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    pyBrandListUpdate: value(state, props),
    pyBrandListVisible: state.get('paketYoldaReducer').pyBrandListVisible,
    pyBrandList: state.get('paketYoldaReducer').pyBrandList
});

const mapDispatchToProps = {
    setPyBrandListVisible,
    setPaketYoldaBrandAddVisible,
    getBrand,
    setPaketYoldaUserListVisible,
    setPaketYoldaBusinessListVisible,
    setBrandPaymentOk
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(BrandList)));
