import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getParameter } from '../../../../../function/GeneralFunction';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import {
    setAdditionListModalVisible
} from '../../../../../redux/modules/report';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import DatePicker, { registerLocale } from "react-datepicker";
import "../../datepicker.css";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { EmptyData } from 'dan-components';
import Addition from './Addition';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePagination from '@material-ui/core/TablePagination';
import TbPaginationActions from '../../../Ortak/TbPaginationActions';
import ModalInformation from '../../../Ortak/ModalInformation';
import Select from '@material-ui/core/Select';


class AdditionListModal extends React.Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.IsAdditionListModalUpdate == this.props.IsAdditionListModalUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    render() {
        const { IsAdditionListModalVisible, classes } = this.props;

        return (
            <Dialog
                open={IsAdditionListModalVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'lg'}
                PaperProps={{ classes: { root: classes.dialogAdditionListModal } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Açık / Kapalı Adisyon Listesi"}
                </DialogTitle>
                <DialogContent style={{ height:'100vh' }}>
                    <DialogContentText id="alert-dialog-description">
                        <Addition classes={classes} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} onClick={() => this.props.setAdditionListModalVisible(false).then(() => { })} color="primary">
                        {"İptal"}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const valueSelector = (state, props) => state.get('reportReducer').IsAdditionListModalUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    IsAdditionListModalUpdate: value(state, props),
    IsAdditionListModalVisible: state.get('reportReducer').IsAdditionListModalVisible
})

const mapDispatchToProps = {
    setAdditionListModalVisible
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdditionListModal));
