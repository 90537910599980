import React from "react";
import 'leaflet';
import 'leaflet-draw';
import "leaflet-draw/dist/leaflet.draw-src.css";
import Button from '@material-ui/core/Button';
import './stylesPopup.css';

const style = {
    width: "100%",
    height: "100%",
    borderRadius: 0
};

class AppRegionMap extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.map = null
    }
    componentDidMount() {
        this.initMap()

        this.renderObjectOnMap(this.props.PolygonJson)
    }

    componentDidUpdate({ IsUpdate }) {
        if (this.props.IsUpdate !== IsUpdate) {
            this.renderObjectOnMap(this.props.PolygonJson)
        }
    }

    renderObjectOnMap = (obj) => {
        this.featureGroup.clearLayers()
        if (obj.length > 0) {
            var geoJsonObject = obj;

            
            var listRender = [];
            geoJsonObject.map((n, i) => {
                var list = [];
                n.map((x,y) => {
                    list.push([x.Lat, x.Lng])
                })
                listRender.push(list);
            })
            
            if (Array.isArray(listRender)) {
                var coordinates = { type: "Polygon", coordinates: listRender };

                const myStyle = {
                    "color": "#3388ff",
                    "weight": 4,
                    "opacity": 0.5
                }

                // 1. render in featureGroup
                L.geoJson(coordinates, {
                    style: myStyle,
                    onEachFeature: (feature, layer) => {
                        this.featureGroup.addLayer(layer)
                        this.map.fitBounds(layer.getBounds());
                        layer.myTag = "myGeoJSON";
                    }
                })

            }
        } else {
            this.map.eachLayer(function (layerData) {
                if (layerData.myTag === "myGeoJSON") {
                    layerData.remove();
                }
            });
        }
    }

    initMap = () => {
        this.map = L.map('map').setView([38.6389114, 35.691706], 6)
        this.featureGroup = L.featureGroup().addTo(this.map)
        this.drawControl = new L.Control.Draw({
            draw: {
                polygon: true,
                polyline: false,
                rectangle: true,
                circle: false,
                marker: false,
                circlemarker: false
            },
            edit: {
                featureGroup: this.featureGroup,
                edit: true,
                remove: true
            },
        }).addTo(this.map)

        L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 18,
            attribution: '',
            id: 'mapbox.streets'
        }).addTo(this.map)

        this.map.on('draw:created', (e) => {
            this.featureGroup.addLayer(e.layer)
        })
        this.map.on('draw:drawstart', (e) => {
            //this.featureGroup.clearLayers()
        })
    }

    onSaveLocation = () => {
        const polygons = []
        this.featureGroup.eachLayer(layer => polygons.push(layer.toGeoJSON().geometry.coordinates))
        this.props.onClickData(polygons);
    }


    render() {
        return (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <div id="map" className="search__map" style={style} />
                <Button style={{ borderRadius: 0, minWidth: 300, zIndex: 999, position: 'absolute', bottom: 5, right: 5, left: 5 }} onClick={this.onSaveLocation} variant="contained" color="secondary">
                    {"Kaydet"}
                </Button>
            </div>
        )
    }
}

export default AppRegionMap;
