import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  Header,
  Sidebar,
  BreadCrumb,
} from 'dan-components';
import cookie from 'react-cookies';
import dataMenu from 'dan-api/ui/menu';
import Decoration from '../Decoration';
import styles from '../appStyles-jss';
import OrderControl from './OrderControl';
import AdditionListModal from '../../Pages/SettingPage/Report/SubReportPage/AdditionListModal';
import CallerId from './CallerId';
import System from './System';
var base64 = require('base-64');
import { getLicenceModulePackage, getParameter } from '../../../function/GeneralFunction';
import petalLight from 'dan-images/petal_bg.svg';

class LeftSidebarLayout extends React.Component {
  getStyle() {
    if (window.location.pathname == "/app/detailpage" || window.location.pathname.indexOf("/setting") != -1 || window.location.pathname == "/app/packagepage" || window.location.pathname == "/app/trackmaps") {
      return { paddingTop: 0 }
    }
    return { paddingTop: 59, paddingLeft: 64 }
  }

  render() {
    const {
      classes,
      children,
      toggleDrawer,
      sidebarOpen,
      loadTransition,
      pageLoaded,
      mode,
      gradient,
      deco,
      history,
      bgPosition,
      changeMode,
      place,
      titleException,
      handleOpenGuide
    } = this.props;
    return (
      <Fragment>
        {
          (window.location.pathname != "/app/detailpage" && window.location.pathname != "/app/packagepage" && window.location.pathname != "/app/trackmaps" && window.location.pathname.indexOf("/setting") == -1) ? (
            <Header
              toggleDrawerOpen={toggleDrawer}
              margin={false}
              gradient={gradient}
              position="left-sidebar"
              changeMode={changeMode}
              mode={mode}
              title={place}
              history={history}
              openGuide={handleOpenGuide}
            />
          ) : (null)
        }

        {(cookie.load('UserType') == "CallCenter" ? null : <System />)}
        <main className={classNames(classes.content)} id="mainContent" style={{ padding: (window.location.pathname == "/app/packagepage" || window.location.pathname == "/app/detailpage" || window.location.pathname == "/app/settings" ? 4 : (window.location.pathname == "/app/trackmaps" ? 0 : 0)) }}>
          <Decoration
            mode={mode}
            gradient={gradient}
            decoration={deco}
            bgPosition={bgPosition}
            horizontalMenu={false}
          />
          <img style={{ backgroundSize: 'cover', backgroundPosition: 'bottom center', position: 'fixed' }} src={petalLight} alt="decoration" />
          <section className={classNames(classes.mainWrap, classes.sidebarLayout)} style={this.getStyle()} >
            {children}
          </section>
          {(cookie.load('UserType') == "CallCenter" ? null : (getLicenceModulePackage() ? <OrderControl /> : null))}
          {(cookie.load('UserType') == "CallCenter" || !getParameter("CallerId") ? null : <CallerId />)}
          <AdditionListModal classes={classes} />
          <audio autoplay="true" muted="true" loop="true" id="audioCourier">
            <source src={"https://cdn.javipos.com/paketyolda.mp3"} type="audio/mp3" />
          </audio>
          <audio autoplay="true" muted="true" loop="true" id="audio">
            <source src={"https://cdn.javipos.com/audio.mp3"} type="audio/mp3" />
          </audio>
        </main>
      </Fragment>
    );
  }
}

LeftSidebarLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  changeMode: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  gradient: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
  bgPosition: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  titleException: PropTypes.array.isRequired,
  handleOpenGuide: PropTypes.func.isRequired
};

export default (withStyles(styles)(LeftSidebarLayout));
