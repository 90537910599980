import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import {
    setCenterAuthorityVisible, setBrandSettings, setCenterAuthorityData
} from '../../../redux/modules/centerbrand';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import { CapitalizeText, ComplateLowerText } from '../../../function/GeneralFunction';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import NumberFormat from 'react-number-format';

class CenterAuthority extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.centerAuthorityUpdate == this.props.centerAuthorityUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    handleChangeCenterAuthority = (Name) => (event) => {
        const { centerAuthorityData } = this.props;
        centerAuthorityData.Franchise[Name] = event.target.checked;
        this.props.setCenterAuthorityData(centerAuthorityData).then(() => { })
    };

    render() {
        const { classes, centerAuthorityVisible, centerAuthorityData } = this.props;

        if (centerAuthorityData == null) {
            return null;
        }

        return (
            <Dialog
                open={centerAuthorityVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'md'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Şube Yetkilendirme"}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={centerAuthorityData.Franchise.IsEntVisible}
                                        onChange={this.handleChangeCenterAuthority("IsEntVisible")}
                                    />
                                )}
                                label={"Entegrasyon Bilgilerine Müdahale Edebilsin"}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={centerAuthorityData.Franchise.IsPriceChange}
                                        onChange={this.handleChangeCenterAuthority("IsPriceChange")}
                                    />
                                )}
                                label={"Fiyat Değişikliği Yapabilsin"}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={centerAuthorityData.Franchise.IsAffectedPriceUpdates}
                                        onChange={this.handleChangeCenterAuthority("IsAffectedPriceUpdates")}
                                    />
                                )}
                                label={"Şube Toplu Fiyat Değişiklerinden Etkilenmesin"}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.setBrandSettings().then(() => { })} variant="contained" color="secondary">
                        {"Kaydet"}
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setCenterAuthorityVisible(false, "", "").then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').centerAuthorityUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    centerAuthorityUpdate: value(state, props),
    centerAuthorityVisible: state.get('centerBrandReducer').centerAuthorityVisible,
    centerAuthorityData: state.get('centerBrandReducer').centerAuthorityData
});

const mapDispatchToProps = {
    setCenterAuthorityVisible,
    setBrandSettings,
    setCenterAuthorityData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(CenterAuthority)));
