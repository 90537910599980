import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from './track-style-jss';
import {
    setPaketYoldaCourierAddVisible,
    setPaketYoldaCourierRemove, setPaketYoldaCourierAddUpdate, setCourierAddVisible
} from '../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { ComplateLowerText } from '../../../function/GeneralFunction';
import CourierAdd from './CourierAdd';

class PaketYoldaCourierAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = { courierDeleteVisible: false, courierDeleteId: "" }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaCourierAddUpdate == this.props.paketYoldaCourierAddUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
    }

    renderCourierDelete() {
        const { courierDeleteVisible } = this.state;
        return (
            <Dialog
                open={courierDeleteVisible}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disabled={true}
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Kurye Pasif İşlemleri"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"Kurye pasife çekilecektir. Onaylıyor musunuz?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.setPaketYoldaCourierRemove(this.state.courierDeleteId).then(() => {
                        this.setState({ courierDeleteVisible: false, courierDeleteId: "" });
                    })} color="secondary" variant="contained" autoFocus>
                        {"Tamam"}
                    </Button>
                    <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} color="primary" onClick={() => { this.setState({ courierDeleteVisible: false, courierDeleteId: "" }); this.props.setPaketYoldaCourierAddUpdate().then(() => { }); }}>
                        {"İptal"}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        const { classes, paketYoldaCourierAddVisible, paketYoldaCourierListData } = this.props;

        return (
            <Dialog
                open={paketYoldaCourierAddVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'md'}
                PaperProps={{ classes: { root: classes.dialogPaperPaketYoldaCourierAdd } }}
            >
                <CourierAdd />
                {this.renderCourierDelete()}
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"JaviKurye Kurye Ekleme / Ödeme İşlemleri"}
                    <Typography className={classes.instructions} style={{ fontSize: 13, fontWeight: 500, color: 'white' }}>
                        {"JaviKurye sistemlerine erişebilecek kurye işlemleri"}
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto', height: '100vh' }}>
                    <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '100px' }}>{'Aktif'}</TableCell>
                                <TableCell>{'İsim Soyisim'}</TableCell>
                                <TableCell>{'Kullanıcı Adı'}</TableCell>
                                <TableCell>{'Şifre'}</TableCell>
                                <TableCell>{'Telefon'}</TableCell>
                                <TableCell align='right'>{'İşlem'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paketYoldaCourierListData.map((n, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {<Icon>{n.IsActive ? 'check_box' : 'check_box_outline_blank'}</Icon>}
                                        </TableCell>
                                        <TableCell>{n.NameSurname}</TableCell>
                                        <TableCell>{n.Username}</TableCell>
                                        <TableCell>{n.Password}</TableCell>
                                        <TableCell>{n.Phone}</TableCell>
                                        <TableCell style={{ width: 200 }} align='right'>
                                            <Tooltip title={'Kuryeyi Pasife Çek'}>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 5 }}
                                                    aria-label="Kuryeyi Pasife Çek"
                                                    onClick={() => { this.setState({ courierDeleteVisible: true, courierDeleteId: n.Id }); this.props.setPaketYoldaCourierAddUpdate().then(() => { }); }}>
                                                    <Icon>{'person_remove'}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: '#27ae60' }} onClick={() => this.props.setCourierAddVisible(true).then(() => { })} color="primary">
                        {"Kurye Ekle"}
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPaketYoldaCourierAddVisible(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

PaketYoldaCourierAdd.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaCourierAddUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaCourierAddUpdate: value(state, props),
    paketYoldaCourierAddVisible: state.get('paketYoldaReducer').paketYoldaCourierAddVisible,
    paketYoldaCourierListData: state.get('paketYoldaReducer').paketYoldaCourierListData
});

const mapDispatchToProps = {
    setPaketYoldaCourierAddVisible,
    setPaketYoldaCourierRemove,
    setPaketYoldaCourierAddUpdate,
    setCourierAddVisible
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(PaketYoldaCourierAdd)));
