import axios from 'axios';
var base64 = require('base-64');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
const uuidv4 = require('uuid/v4');
import { reactLocalStorage } from 'reactjs-localstorage';
import { getLicence } from '../../function/GeneralFunction';

export const LICENCE_FORM_DATA_SET = 'licence_form_data_set';
export const LICENCE_SET_DATA = 'licence_set_data';
export const PAYMENT_NOTIFICATION_SET = 'payment_notification_set';

export const BANK_START = 'bank_start';
export const TIMER_BANK_START = 'timer_bank_start';
export const TIMER_BANK_SUCCESS = 'timer_bank_success';
export const TIMER_BANK_ERROR = 'timer_bank_error';
export const UPDATE_SUBSCRIPTIONS = 'update_subscriptions';

export const PAYMENT_SUCCESS = 'payment_success';
export const LICENCE_VISIBLE = 'licence_visible';
export const SET_SUBSCRIPTIONDATA = 'set_subscriptiondata';
export const SET_CREDITCARD = 'set_creditcard';

export const MODULE_OPEN = 'module_open';
export const PAYMENTDETAIL_OPEN = 'paymentdetail_open';
export const CARD_OPEN = 'card_open';

export const INVOICE_VISIBLE = 'invoice_visible';
export const INVOICE_DATA = 'invoice_data';

export const SEND_LINK_VISIBLE = 'send_link_visible';

let initialState = {
  form_dataLicence: {
    modalLicenceOpen: false,
    modalLicenceText: "",
    modalLicenceTitle: "Uyarı"
  },
  IsLicenceVisible: false,
  IsLicenceUpdate: false,
  licenceData: null,

  bankStart: false,

  IsModuleOpen: false,

  IsPaymentDetailOpen: false,
  cardList: [],

  invoiceVisible: false,
  invoiceData: null,

  sendLinkVisible: false,
  sendLinkData: null
}

export async function getPaymentUrl() {
  return async (dispatch, getState) => {
    dispatch({ type: BANK_START });
    const { licenceData } = getState().get('licenceReducer');

    let data = {
      "SubscriptionInfo": licenceData.SubscriptionInfo,
      "BrandId": cookie.load("BrandId"),
      "TenantId": cookie.load("TenantId")
    };

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Payment/getPaymentUrl',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
        'Content-Type': 'application/json'
      },
      data: data,
    })
      .then(function (response) {
        dispatch({ type: BANK_START });
        var json = response.data;
        if (json.code == "0") {
          var openUrl = window.open(json.url, "_blank");
          openUrl.focus();
          dispatch(getSubscription())
        } else {
          dispatch(setModalLicense("Güvenli bağlantı oluşturulamadı. JaviPos teknik ekibiyle iletişime geçin."));
        }
      })
      .catch(function (error) {

      });
  }
}

export async function setCardInfoArrange(card_id) {
  return async (dispatch, getState) => {
    const { cardList } = getState().get('licenceReducer');

    cardList.filter(x => x.card_id != card_id).map((n, i) => {
      n.order_no = i + 2;
    })
    cardList.find(x => x.card_id == card_id).order_no = 1;

    var list = [];
    cardList.map((n, i) => {
      list.push({ "subscription_card_id": 0, "card_id": n.card_id, "order_no": n.order_no });
    })

    var data = {
      "BrandId": cookie.load("BrandId"),
      "card_information": list
    };

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Payment/setCardInfoArrange',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: data
    })
      .then(function (response) {
        if (response.data.isStatus) {
          dispatch(getCreditCard());
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function setManuelCharge(invoice_id) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Payment/setManuelCharge?BrandId=' + cookie.load("BrandId") + '&invoice_id=' + invoice_id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setModalLicense(json.data[0].message))
          dispatch(getSubscription());
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function setCardInfoDelete(card_id) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Payment/setCardInfoDelete?BrandId=' + cookie.load("BrandId") + '&card_id=' + card_id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          dispatch(getCreditCard());
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function CardOpen(open) {
  return async (dispatch, getState) => {
    if (open) {
      await dispatch(getCreditCard());
    }
    dispatch({ type: CARD_OPEN, payload: open });
  }
}

export async function formDataSetLicence(key, val) {
  return async (dispatch, getState) => {
    dispatch({
      type: LICENCE_FORM_DATA_SET,
      payload: { key, val }
    });
  };
}

export async function setLicenceData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: LICENCE_SET_DATA,
      payload: data
    });
  };
}

export async function setPaymentNotification(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: PAYMENT_NOTIFICATION_SET,
      payload: data
    });
  };
}

export async function PaymentDetailOpen() {
  return async (dispatch, getState) => {
    dispatch({ type: PAYMENTDETAIL_OPEN });
  }
}

export async function setPaymentSuccess() {
  return async (dispatch, getState) => {
    dispatch({
      type: PAYMENT_SUCCESS
    });
  };
}

export async function getCreditCard() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Payment/getCreditCard?BrandId=' + cookie.load("BrandId"),
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setCreditCardData(json.Data))
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function getSubscription() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Payment/getSubscription?BrandId=' + cookie.load("BrandId") + '&TenantId=' + cookie.load("TenantId"),
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setSubscriptionData(json))
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function getInvoiceHeader() {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Payment/getInvoiceHeader?BrandId=' + cookie.load("BrandId"),
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({ type: INVOICE_DATA, payload: json });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function setInvoiceHeader(data) {
  return async (dispatch, getState) => {
    data["BrandId"] = cookie.load('BrandId');
    data["TenantId"] = cookie.load('TenantId');
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Payment/setInvoiceHeader',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: data
    })
      .then(function (response) {
        dispatch(setModalLicense(response.data.responseData));
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function setSendPaymentLink(amount,invoice_id) {
  return async (dispatch, getState) => {
    var BrandId = cookie.load('BrandId');
    var TenantId = cookie.load('TenantId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Payment/setSendPaymentLink?TenantId=' + TenantId + '&BrandId=' + BrandId + '&amount=' + amount+'&invoice_id='+ invoice_id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        dispatch(setSendLinkVisible(false))
        dispatch(setModalLicense(response.data.responseData));
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function setSubscriptionData(data) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_SUBSCRIPTIONDATA, payload: data });
  }
}

export async function setCreditCardData(data) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CREDITCARD, payload: data });
  }
}

export async function setLicenceVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getSubscription());
    }
    dispatch({ type: LICENCE_VISIBLE, payload: visible });
  }
}


export async function ModuleOpen() {
  return async (dispatch, getState) => {
    dispatch({ type: MODULE_OPEN });
  }
}

export async function setModalLicense(text) {
  return async (dispatch, getState) => {
    dispatch({
      type: TIMER_BANK_ERROR,
      payload: text
    });
  };
}

export async function InvoiceVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({ type: INVOICE_VISIBLE, payload: visible });
  }
}

export async function setInvoiceData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: INVOICE_DATA,
      payload: data
    });
  };
}

export async function setSendLinkVisible(visible, data = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: SEND_LINK_VISIBLE,
      payload: visible,
      data: data
    });
  };
}

const licenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_LINK_VISIBLE:
      return { ...state, sendLinkVisible: action.payload, sendLinkData: (action.payload ? action.data : null) }
    case INVOICE_VISIBLE:
      return { ...state, invoiceVisible: action.payload }
    case MODULE_OPEN:
      return { ...state, IsModuleOpen: !state.IsModuleOpen }
    case INVOICE_DATA:
      return { ...state, invoiceData: action.payload }
    case LICENCE_VISIBLE:
      return { ...state, IsLicenceVisible: action.payload }
    case PAYMENTDETAIL_OPEN:
      return { ...state, IsPaymentDetailOpen: !state.IsPaymentDetailOpen }
    case CARD_OPEN:
      return { ...state, IsCardOpen: action.payload }
    case SET_SUBSCRIPTIONDATA:
      return { ...state, licenceData: action.payload, IsLicenceUpdate: !state.IsLicenceUpdate }
    case SET_CREDITCARD:
      return { ...state, cardList: action.payload, IsLicenceUpdate: !state.IsLicenceUpdate }
    case LICENCE_FORM_DATA_SET:
      return {
        ...state,
        IsLicenceUpdate: !state.IsLicenceUpdate,
        form_dataLicence: {
          ...state.form_dataLicence,
          [action.payload.key]: action.payload.val
        }
      }
    case BANK_START:
      return {
        ...state,
        bankStart: !state.bankStart, IsLicenceUpdate: !state.IsLicenceUpdate
      }
    case PAYMENT_SUCCESS:
      return {
        ...state,
        paymentSuccess: true, IsLicenceUpdate: !state.IsLicenceUpdate
      }
    case TIMER_BANK_START:
      return {
        ...state,
        bankTimerStart: action.status, IsLicenceUpdate: !state.IsLicenceUpdate
      }
    case TIMER_BANK_SUCCESS:
      return {
        ...state,
        bankTimerStart: false, IsLicenceUpdate: !state.IsLicenceUpdate, paymentSuccess: true
      }
    case TIMER_BANK_ERROR:
      return {
        ...state,
        form_dataLicence: {
          ...state.form_dataLicence,
          modalLicenceOpen: true,
          modalLicenceText: action.payload,
          modalLicenceTitle: "Bilgilendirme"
        }
      }
    case LICENCE_SET_DATA:
      return {
        ...state,
        licenceData: action.payload, IsLicenceUpdate: !state.IsLicenceUpdate
      }
    case PAYMENT_NOTIFICATION_SET:
      return {
        ...state,
        PaymentNotification: action.payload, IsLicenceUpdate: !state.IsLicenceUpdate
      }
    default:
      return state;
  }
}

export default licenceReducer;
